import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const NoResults = ({ className, mode, title, description }) => {
  return (
    <div className={`${className || 'mt-5 mb-5 py-5'} text-center w-100`}>
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="4" width="48" height="48" rx="24" fill={`${mode === 'dark' ? '#222222' : '#ECE7FB'}`} />
        <path
          d="M37 37L32.65 32.65M35 27C35 31.4183 31.4183 35 27 35C22.5817 35 19 31.4183 19 27C19 22.5817 22.5817 19 27 19C31.4183 19 35 22.5817 35 27Z"
          stroke={`${mode === 'dark' ? '#ffffff' : '#1F2ECF'}`}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="4"
          y="4"
          width="48"
          height="48"
          rx="24"
          stroke={`${mode === 'dark' ? '#666666' : '#F4F1FD'}`}
          strokeWidth="8"
        />
      </svg>
      <h5 className="mt-1 mb-1">{title || 'No results'}</h5>
      <p className="noResultsDescription description w-100">
        {description || 'There are no results matching your filters.'}
      </p>
    </div>
  );
};

NoResults.defaultProps = {
  mode: 'light',
  className: '',
  title: '',
  description: '',
};

NoResults.propTypes = {
  mode: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

const mapStateToProps = ({ global }) => ({
  mode: global.mode,
});

export default connect(mapStateToProps, null)(NoResults);
