const handleRequestDuplication = {
  /* will hold data for tryToCallAgain fn */
  callAgainHolder: {},
  
  /* will hold requests that are currently pending */
  pendingRequestsList: [],

  /* handle request initiated */
  addToPending: (url) => {
    const pendingList = handleRequestDuplication.pendingRequestsList;
    const notInPending = pendingList.indexOf(url) === -1;

    /* push request to pending list */
    if (notInPending) {
      pendingList.push(url);
    }
  },

  /* handle request completed */
  removeFromPending: (url) => {
    const pendingList = handleRequestDuplication.pendingRequestsList;

    /* remove url from pending array */
    pendingList.splice(pendingList.indexOf(url), 1);

    /* init check for call again */
    handleRequestDuplication.tryToCallAgain(url);
  },

  /* request in progress */
  isPending: (url) => {

    /* true if request url already pending */
    return handleRequestDuplication.pendingRequestsList.indexOf(url) !== -1;
  },

  /* 'blocker' request finished; at the end, try to call one more time */
  tryToCallAgain: (url) => {
    const callAgainObject = handleRequestDuplication.callAgainHolder[url];

    /* no extra request needed */
    if (!callAgainObject) {
      return;
    }
    
    /* callback function */
    const callback = callAgainObject?.callbackFn;

    /* make sure that we have a callback fn to use */
    if (typeof callback === 'function') {
      /* dispatch callback */
      callAgainObject.dispatchFn(callback());
    }

    /* all tasks completed; remove request from call again list */
    delete handleRequestDuplication.callAgainHolder[url];
  },

};

export default handleRequestDuplication;
