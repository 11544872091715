import type { Permission } from 'rbac/types';
import type { ResourceMatcherContext, ResourceMatcherOptions } from 'rbac/util/matcher';
import type { BaseError } from 'app/services/api';

import { hasPermissions } from 'rbac/util/permissions';
import { hasResource } from 'rbac/util/matcher';
import { rbacRolesApi } from 'app/services/rbac/roles';

export type Allowed = {
    type: 'allowed',
};

export type Denied = {
    type: 'denied',
    reason: 'denied' | 'uninitialized' | 'loading' | 'fetching',
};

export type Error = {
    type: 'error',
    error: BaseError
};

export type Result = Allowed | Denied | Error;

export type OrganizationContext = {
    organization: {
        id: number
    }
};

export type ContextWithOrganization = OrganizationContext & ResourceMatcherContext;

export interface Options {
    allowFetching: boolean;

    matcher: ResourceMatcherOptions;

    skip?: boolean,
};

export function useACL(
    permission: Permission|Permission[],
    context: ContextWithOrganization,
    options: Options,
): Result {
    const permissions = Array.isArray(permission) ? permission : [permission];
    /**
     * JSLand code sometimes has id === null
     * TSLand code can forceskip on id === 0
     */
    if (!context.organization.id) {
        options.skip = true;
    }

    for (let page = 1;; page += 1) {
        const result = rbacRolesApi.useListQuery({
            organization: context.organization.id,
            page,
        }, {
            skip: options.skip,
        });

        if (result.isUninitialized) {
            return {
                type: 'denied',
                reason: 'uninitialized',
            };
        }

        if (result.isLoading) {
            return {
                type: 'denied',
                reason: 'loading',
            };
        }

        if (result.isFetching) {
            if (!options.allowFetching) {
                return {
                    type: 'denied',
                    reason: 'fetching',
                };
            }
        }

        if (result.isError) {
            return {
                type: 'error',
                error: result.error,
            };
        }

        for (let i = 0; i < result.data['hydra:member'].length; i += 1) {
            const role = result.data['hydra:member'][i];

            if (hasPermissions(permissions, role.permissions)) {
                if (hasResource(role.matchers, context, options.matcher)) {
                    return {
                        type: 'allowed',
                    };
                }
            }
        }

        if (!result.data['hydra:view'] || !result.data['hydra:view']['hydra:next']) {
            return {
                type: 'denied',
                reason: 'denied',
            };
        }
    }
}
