import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {  useHistory, useParams, withRouter } from 'react-router-dom';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { Tooltip } from 'antd';
import compose from 'hocs';
import DialogCreateProject from 'modules/projects/components/dialogCreateProject';
import Button from 'theme/uielements/button';
import ApiPagination from 'components/apiPagination/ApiPagination';
import { InputSearch } from 'theme/uielements/input';
import NoResults from 'modules/templates/components/noResults/NoResults';
import { setProjectName } from 'modules/projects/actions';
import { showDialog, hideDialog } from 'actions/global';
import { setCurrentProject } from 'modules/userManager/actions';
import { setCurrentEnvironmentsNo } from 'modules/environments/actions';
import filterTable from './helpers';
import StyledProjectListing from './ProjectListing.style';
import { SVG } from '../../../../components';
import ProjectCard from './ProjectCard';

const ProjectListing = ({ isLoading, list, projectsNo, actions, email, mode, aclProjectCreateDisabled }) => {
  const history = useHistory();
  const { organizationId } = useParams();
  const [searchTerm, setSearchTerm] = useState('');

  const updateQueryParams = (paramsObj) => {
    const { pathname, search: queryParams } = history.location;

    /* available parameters */
    const { page } = paramsObj;
    const params = new URLSearchParams(queryParams);

    /* page */
    if (page !== undefined) {
      params.set('page', page);
    }

    history.replace({ pathname, search: params.toString() });
  };

  const filteredProjects = R.filter(filterTable(searchTerm))(list);

  return (
    <StyledProjectListing fluid>
      <Grid>
        <Row className="mb-5">
          <Col xs={12} md={6} lg={6}>
            <InputSearch
              className="input-search"
              addonAfter={<SVG name="bicon-search" noHover defaultColor="#808080" />}
              size="small"
              placeholder="Search project"
              disabled={isLoading}
              onChange={({ target }) => setSearchTerm(target.value)}
            />
          </Col>
          <Col xs={12} md={6} lg={6} className="text-left text-md-right">
            <Tooltip
              placement="top"
              title={aclProjectCreateDisabled ? 'Denied' : ''}
              mouseEnterDelay={0.4}
              mouseLeaveDelay={0.3}
            >
              <span>
                <Button
                  type="primary"
                  size="default"
                  data-value="create-project-button-modal"
                  disabled={aclProjectCreateDisabled}
                  onClick={() => {
                    actions.showDialog({
                      options: {
                        size: 'small',
                      },
                      header: 'Create project',
                      body: <DialogCreateProject organizationId={organizationId} />,
                    });
                  }}
                >
                  Create project
                </Button>
              </span>
            </Tooltip>
          </Col>
        </Row>
        <Row>
          {filteredProjects.length > 0 ? (
            filteredProjects.map((project) => <ProjectCard project={project} email={email} />)
          ) : (
            <NoResults mode={mode} />
          )}
        </Row>
        <Row>
          {filteredProjects.length > 0 && (
            <Col xs={12}>{projectsNo > 30 && <ApiPagination callback={updateQueryParams} total={projectsNo} />}</Col>
          )}
        </Row>
      </Grid>
    </StyledProjectListing>
  );
};

ProjectListing.defaultProps = {
  isLoading: false,
  list: [],
};

ProjectListing.propTypes = {
  isLoading: PropTypes.bool,
  email: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  projectsNo: PropTypes.number.isRequired,
  aclProjectCreateDisabled: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      createdAt: PropTypes.string,
      accessibleEnvironmentsCount: PropTypes.number,
    })
  ),
  actions: PropTypes.shape({
    showDialog: PropTypes.func,
    hideDialog: PropTypes.func,
    setCurrentEnvironmentsNo: PropTypes.func,
    setCurrentProject: PropTypes.func,
    setProjectName: PropTypes.func,
  }).isRequired,
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showDialog,
      hideDialog,
      setCurrentEnvironmentsNo,
      setCurrentProject,
      setProjectName,
    },
    dispatch
  ),
});
export default compose(connect(null, mapDispatchToProps), withRouter)(ProjectListing);
