import React, { useEffect, useState } from 'react';

let dir = 'ltr';

if (typeof window !== 'undefined') {
  dir = document.getElementsByTagName('html')[0].getAttribute('dir');
}

// The useitful logic needs to be plucked into a dedicated wrapper if we plan on keeping it
function isDropdown(Component) {
  return [
    'Styled(Dropdown)',
  ].includes(Component.displayName);
}

const withDirection = (Component) => (props) => {

  // start - [i] this effect use for usetiful tutorial; keeps dropdown open when clicking on usetiful bubble
  const [forceOpenDropdown, setForceOpenDropdown] = useState(false);

  useEffect(() => {
    const onDocumentClick = (e) => {
      const notBubbleContent = !e.target.closest('.uf-bubble-content') && !e.target.closest('.uf-title');

      // close dropdown when clicking outside of usetiful bubble
      if (notBubbleContent) {
        setForceOpenDropdown(false);
      }
    };

    if (forceOpenDropdown) {
      // add event listener when forceOpenModal is true to prevent closing modal when clicking on usetiful bubble
      document.addEventListener('click', onDocumentClick);
    }

    return () => {
      // cleanup
      document.removeEventListener('click', onDocumentClick);
    };
  }, [forceOpenDropdown]);
  // end @to be removed when usetiful tutorial is removed

  if (isDropdown(Component)) {
    return <Component
      open={forceOpenDropdown ? true : undefined}
      onOpenChange={(open) => {
        if (!forceOpenDropdown) {
          // wait for usetiful bubble to be rendered
          setTimeout(() => {
            // update forceOpenDropdown after 500ms
            setForceOpenDropdown(!!(document.querySelector('.uf-visible') && open));
          }, 500);
        }
      }}

      {...props}
      data-rtl={dir}
    />;
  }

  return <Component
    {...props}
    data-rtl={dir}
  />
};

const direction = dir;

export default withDirection;
export { direction };
