import * as R from 'ramda';
import { showNotification } from 'actions/global';
import { API, GET, POST, PUT, DELETE } from 'config';
import { TOAST_TYPES } from 'components';
import { log, getErrorMessage } from 'utils';
import {updateUserOrganizations} from 'modules/userManager/actions'
import { createPrefixedAction } from '../config';
import { getOrganizationPayload, getUserPayload } from './helpers';

import actionTypes from './actionTypes';
import {ERROR_MAP} from "../../userManager/constants";

export const getOrganizations = createPrefixedAction(actionTypes.GET_ORGANIZATIONS);
export const getUpdatedOrganizations = createPrefixedAction(actionTypes.GET_UPDATED_ORGANIZATIONS);
export const getOrganization = createPrefixedAction(actionTypes.GET_ORGANIZATION);
export const getOrganizationDetails = createPrefixedAction(actionTypes.GET_ORGANIZATION_DETAILS);
export const getOrganizationUsers = createPrefixedAction(actionTypes.GET_ORGANIZATION_USERS);
export const addOrganization = createPrefixedAction(actionTypes.ADD_ORGANIZATION);
export const addOrganizationMember = createPrefixedAction(actionTypes.ADD_ORGANIZATION_MEMBER);
export const editOrganization = createPrefixedAction(actionTypes.EDIT_ORGANIZATION);
export const deleteOrganization = createPrefixedAction(actionTypes.DELETE_ORGANIZATION);
export const deactivateOrganizationMember = createPrefixedAction(
  actionTypes.DEACTIVATE_ORGANIZATION_MEMBER
);
export const getTimezones = createPrefixedAction(actionTypes.GET_TIMEZONES);
export const getUsers = createPrefixedAction(actionTypes.GET_USERS);
export const getUpdatedUsers = createPrefixedAction(actionTypes.GET_UPDATED_USERS);
export const addUser = createPrefixedAction(actionTypes.ADD_USER);
export const editUser = createPrefixedAction(actionTypes.EDIT_USER);
export const deleteUser = createPrefixedAction(actionTypes.DELETE_USER);

export const handleGetOrganizations = (page, searchTerm) => async (dispatch) => {
  try {
    await dispatch(getOrganizations(GET(`${API.organizations.base}?page=${page}&search=${searchTerm}`).then(({ data }) => data)));
  } catch (exception) {
    log.error('Organizations', exception);
  }
};
export const handleGetUpdatesOrganizations = (page, searchTerm) => async (dispatch) => {
  try {
    await dispatch(getUpdatedOrganizations(GET(`${API.organizations.base}?page=${page}&search=${searchTerm}`).then(({ data }) => data)));
  } catch (exception) {
    log.error('Organizations', exception);
  }
};
export const handleGetOrganizationDetails = (organizationId, history) => async (dispatch) => {
  try {
    await dispatch(getOrganizationDetails(GET(`${API.organizations.base}/${organizationId}`).then(({ data }) => data)));
  } catch (exception) {
    log.error('Organizations', exception);
    const errorCode = getErrorMessage(exception);

    if (errorCode in ERROR_MAP) {
      history.push(ERROR_MAP[errorCode]);
    }
  }
};
export const handleGetOrganization = (organizationId) => async (dispatch) => {
  return dispatch(
    getOrganization(
      Promise.all([
        GET(`${API.organizations.base}/${organizationId}`),
        GET(API.organizations.users(organizationId)),
        GET(API.users.base),
      ])
        .then((body) => {
          const organisationData = R.path([0, 'data'], body) || {};
          const organisationUsersData = R.path([1, 'data'], body) || [];
          const usersData = R.path([2, 'data'], body) || [];
          return {
            organization: organisationData,
            organizationUsers: organisationUsersData,
            users: usersData,
          };
        })
        .catch((exception) => {
          log.error('Organization', exception);
          dispatch(showNotification(TOAST_TYPES.error, 'Organization', getErrorMessage(exception)));
        })
    )
  );
};

export const handleGetOrganizationUsers = (organizationId) => async (dispatch) => {
  try {
    await dispatch(
      getOrganizationUsers(GET(`${API.organizations.members}?organization=${organizationId}`).then(({ data }) => data))
    );
  } catch (exception) {
    log.error('Organization Users', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Organization Users', getErrorMessage(exception)));
  }
};

export const handleAddOrganization = (values, isGated) => async (dispatch) => {
  try {
    await dispatch(
      addOrganization(
        POST(API.organizations.base, getOrganizationPayload(values, isGated)).then(({ data }) => {
          dispatch(
            showNotification(TOAST_TYPES.success, 'Add organization', 'Organization saved!')
          );
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Add organization', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Add organization', getErrorMessage(exception)));
  }
};

export const handleAddOrganizationMember = (organizationId, userEmail) => async (dispatch) => {
  try {
    await dispatch(
      addOrganizationMember(POST(API.organizations.inviteMember(organizationId), { email: userEmail }))
    );
    await dispatch(handleGetOrganizationUsers(organizationId));
    dispatch(
      showNotification(TOAST_TYPES.success, 'Add organization member', 'Organization member saved!')
    );
  } catch (exception) {
    log.error('Add organization member', exception);
    dispatch(
      showNotification(TOAST_TYPES.error, 'Add organization member', getErrorMessage(exception))
    );
  }
};

export const handleDeactivateOrganizationMember = (userEmail) => async (dispatch, getState) => {
  const {
    organization: { id },
  } = getState().admin;
  try {
    await dispatch(
      deactivateOrganizationMember(
        POST(API.organizations.deactivate(id), { email: userEmail }).then(() => {
          dispatch(
            showNotification(
              TOAST_TYPES.success,
              'Remove organization member',
              'Organization member removed!'
            )
          );
          return userEmail;
        })
      )
    );
  } catch (exception) {
    log.error('Remove organization member', exception);
    dispatch(
      showNotification(TOAST_TYPES.error, 'Remove organization member', getErrorMessage(exception))
    );
  }
};

export const handleEditOrganization = (organizationId, values, isGated, billingMode) => async (dispatch, getState) => {
  const {organizations} = getState().userManager;
  try {
    await dispatch(
      editOrganization(
        PUT(`${API.organizations.base}/${organizationId}`, getOrganizationPayload(values, isGated, billingMode)).then(
          ({ data }) => {
            const index = R.findIndex(R.propEq('id', data.id))(organizations);
            const newOrganizations = R.adjust(
              index,
              () => ({
                ...data,
              }),
              organizations
            );
            dispatch(updateUserOrganizations(newOrganizations))
            dispatch(
              showNotification(
                TOAST_TYPES.success,
                'Edit organization',
                'Organization details saved!'
              )
            );
            return data;
          }
        )
      )
    );
  } catch (exception) {
    log.error('Edit organization', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Edit organization', getErrorMessage(exception)));
  }
};

export const handleDeleteOrganization = (organizationId) => async (dispatch) => {
  try {
    await dispatch(
      deleteOrganization(
        DELETE(`${API.organizations.base}/${organizationId}`).then(() => {
          dispatch(
            showNotification(
              TOAST_TYPES.success,
              'Delete organization',
              'Organization successfully deleted!'
            )
          );
          return organizationId;
        })
      )
    );
  } catch (exception) {
    log.error('Delete organization', exception);
    dispatch(
      showNotification(TOAST_TYPES.error, 'Delete organization', getErrorMessage(exception))
    );
  }
};
export const handleGetTimezones = () => async (dispatch) => {
  try {
    await dispatch(getTimezones(GET(API.organizations.timezones).then(({ data }) => data)));
  } catch (exception) {
    dispatch(showNotification(TOAST_TYPES.error, 'Error', 'Something went wrong'));
    log.error('Organizations', exception);
  }
};
export const handleGetUsers = (page, searchTerm) => async (dispatch) => {
  try {
    await dispatch(getUsers(GET(`${API.users.base}?page=${page}&search=${searchTerm}`).then(({ data }) => data)));
  } catch (exception) {
    log.error('Users', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Users', getErrorMessage(exception)));
  }
};
export const handleGetUpdatedUsers = (page, searchTerm,) => async (dispatch) => {
  try {
    await dispatch(getUpdatedUsers(GET(`${API.users.base}?page=${page}&search=${searchTerm}`).then(({ data }) => data)));
  } catch (exception) {
    log.error('Users', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Users', getErrorMessage(exception)));
  }
};
export const handleAddUser = (values) => async (dispatch) => {
  try {
    await dispatch(
      addUser(
        POST(API.users.base, getUserPayload(values)).then(({ data }) => {
          dispatch(showNotification(TOAST_TYPES.success, 'Add User', 'User saved!'));
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Add User', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Add User', getErrorMessage(exception)));
  }
};

export const handleEditUser = (userId, values) => async (dispatch) => {
  try {
    await dispatch(
      editUser(
        PUT(`${API.users.base}/${userId}`, getUserPayload(values)).then(({ data }) => {
          dispatch(showNotification(TOAST_TYPES.success, 'Edit User', 'User details saved!'));
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Edit User', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Edit User', getErrorMessage(exception)));
  }
};

export const handleDeleteUser = (userId) => async (dispatch) => {
  try {
    await dispatch(
      deleteUser(
        DELETE(`${API.users.base}/${userId}`).then(() => {
          dispatch(showNotification(TOAST_TYPES.success, 'Delete User', 'User deleted!'));
          return userId;
        })
      )
    );
  } catch (exception) {
    log.error('Delete User', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Delete User', getErrorMessage(exception)));
  }
};
