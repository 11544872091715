import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import { updateClusterResource } from 'actions/global';
import {
  getKubernetesCluster,
  detachKubernetes,
  editCluster,
  getCloudProviders,
  resetClustersState,
  connectKubernetes,
  resetErrorMessage,
  verifyKubernetes,
} from '../actions';
import initialState from './initialState';

const getCloudProvidersHandler = [
  getCloudProviders,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoadingClusters: true,
        updateResourceId: '',
      };
    }

    if (error) {
      return {
        ...state,
        isLoadingClusters: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    const kubernetes = payload.kubernetes || [];

    return {
      ...state,
      isLoadingClusters: false,
      /* set "subscribe" clusters data to mercure */
      clusterTopics: kubernetes.map((item) => {
        return `/api/kubernetes_integrations/${item.id}`;
      }),
      cloudProviders: payload.cloudProviders || [],
      kubernetes,
    };
  },
];

const connectKubernetesHandler = [
  connectKubernetes,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
        isSavingCluster: true,
        errorMessage: '',
        updateResourceId: '',
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        isSavingCluster: false,
        errorMessage: R.path(['response', 'data'], payload),
      };
    }

    const kubernetes = R.append(payload, state.kubernetes);

    return {
      ...state,
      isLoading: false,
      isSavingCluster: false,
      kubernetes,
      /* set "subscribe" clusters data to mercure */
      clusterTopics: kubernetes.map((item) => {
        return `/api/kubernetes_integrations/${item.id}`;
      }),
    };
  },
];

const verifyKubernetesHandler = [
  verifyKubernetes,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    const { id } = payload;
    const index = R.findIndex(R.propEq('id', id))(state.kubernetes);

    const updatedKubernetes =
      index !== -1 ? R.adjust(index, (item) => ({ ...item, ...payload }), state.kubernetes) : state.kubernetes;

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      kubernetes: updatedKubernetes,
    };
  },
];

const editClusterHandler = [
  editCluster,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isSavingCluster: true,
        updateResourceId: '',
      };
    }

    if (error) {
      return {
        ...state,
        isSavingCluster: false,
        errorMessage: R.path(['response', 'data'], payload),
      };
    }

    const { id } = payload;
    const index = R.findIndex(R.propEq('id', id))(state.kubernetes);
    const kubernetes = R.adjust(index, (item) => ({ ...item, ...payload }), state.kubernetes);

    return {
      ...state,
      isSavingCluster: false,
      errorMessage: '',
      kubernetes,
      /* set "subscribe" clusters data to mercure */
      clusterTopics: kubernetes.map((item) => {
        return `/api/kubernetes_integrations/${item.id}`;
      }),
    };
  },
];

const detachKubernetesHandler = [
  detachKubernetes,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isRemovingCluster: true,
        updateResourceId: '',
      };
    }

    if (error) {
      return {
        ...state,
        isRemovingCluster: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    const { id } = payload;
    const kubernetes = state.kubernetes.filter((item) => {
      return item.id !== id;
    });

    return {
      ...state,
      isRemovingCluster: false,
      errorMessage: '',
      kubernetes,
      /* set "subscribe" clusters data to mercure */
      clusterTopics: kubernetes.map((item) => {
        return `/api/kubernetes_integrations/${item.id}`;
      }),
    };
  },
];

const getKubernetesClusterHandler = [
  getKubernetesCluster,
  (state, actions) => {
    const { ready, error, payload } = actions;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
        updateResourceId: '',
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: true,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    const { id } = payload;
    const index = R.findIndex(R.propEq('id', id))(state.kubernetes);
    const kubernetes =
      index === -1
        ? R.append(payload, state.kubernetes)
        : R.adjust(index, (item) => ({ ...item, ...payload }), state.kubernetes);

    return {
      ...state,
      isLoading: false,
      kubernetes,
      /* set "subscribe" clusters data to mercure */
      clusterTopics: [`/api/kubernetes_integrations/${id}`],
    };
  },
];

const updateClusterResourceHandler = [
  updateClusterResource,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      updateResourceId: payload,
    };
  },
];

const resetErrorMessageHandler = [
  resetErrorMessage,
  (state) => {
    return {
      ...state,
      errorMessage: '',
      isLoading: false,
      isRemovingCluster: false,
    };
  },
];

const resetClustersStateHandler = [
  resetClustersState,
  (state) => {
    return {
      ...state,
      isLoadingClusters: false,
      isRemovingCluster: false,
      isLoading: false,
      updateResourceId: '',
      errorMessage: '',
      kubernetes: [],
    };
  },
];

const reducer = handleActions(
  new Map([
    getCloudProvidersHandler,
    connectKubernetesHandler,
    resetClustersStateHandler,
    verifyKubernetesHandler,
    detachKubernetesHandler,
    editClusterHandler,
    getKubernetesClusterHandler,
    resetErrorMessageHandler,
    updateClusterResourceHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
