import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'hocs';
import Tooltip from 'theme/uielements/tooltip';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { handleGetPersonalAccessToken, handleRenewPersonalAccessToken } from 'modules/settings/actions';
import buttonLoaderWhite from 'assets/images/buttonLoaderWhite.svg';
import Input from 'theme/uielements/input';
import Form from 'theme/uielements/form';
import Skeleton from 'react-loading-skeleton';
import Button from 'theme/uielements/button';
import 'react-loading-skeleton/dist/skeleton.css';
import StyledAccessTokenForm from './AccessTokenForm.style';
import StyledActionButton from './StyledActionButton.style';

const EnvironmentForm = ({
  actions,
  isLoadingToken,
  isGeneratingToken,
  accessToken,
  organizationUserIds,
  organizationId,
}) => {
  const [envName, setEnvName] = useState(accessToken);
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
  const [copyText, setCopyText] = useState('Copy');
  const [newTimeout, setNewTimeout] = useState(null);

  const handleCopiedTooltip = (text) => {
    clearTimeout(newTimeout);
    setShowCopiedTooltip(true);
    setCopyText(text);

    if (text === 'Copied to clipboard') {
      setNewTimeout(
        setTimeout(() => {
          setShowCopiedTooltip(false);
        }, 1000)
      );
    }
  };

  useEffect(() => {
    setEnvName(accessToken);
  }, [accessToken]);

  useEffect(() => {
    actions.handleGetPersonalAccessToken(organizationUserIds[organizationId]);
  }, [actions, organizationUserIds, organizationId]);

  return (
    <>
      <StyledAccessTokenForm>
        <p>Use this token to access Bunnyshell’s API.</p>
        <Form.Item className="mb-3">
          <Input className="access-token-input" readOnly value={envName} />
          {(isLoadingToken || isGeneratingToken) && (
            <Skeleton sx={{ bgcolor: 'white' }} width={310} height={14} containerClassName="input-skeleton" />
          )}
          <Tooltip
            placement="top"
            title={copyText}
            visible={showCopiedTooltip}
            mouseEnterDelay={0.4}
            mouseLeaveDelay={0.3}
            onMouseEnter={() => {}}
            onMouseLeave={() => {}}
          >
            <span
              className="copy"
              role="button"
              tabIndex={0}
              onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(envName).then(() => {
                  handleCopiedTooltip('Copied to clipboard');
                });
              }}
              onKeyDown={() => {
                navigator.clipboard.writeText(envName);
              }}
            >
              Copy
            </span>
          </Tooltip>
        </Form.Item>
      </StyledAccessTokenForm>
      <StyledActionButton>
        <Button
          className={`d-inline-block ant-btn mt-1 ${isLoadingToken || isGeneratingToken ? ' loading-anim' : ''}`}
          type="primary"
          size="medium"
          disabled={isLoadingToken || isGeneratingToken}
          onClick={() => {
            actions.handleRenewPersonalAccessToken(organizationUserIds[organizationId]);
          }}
        >
          {(isLoadingToken || isGeneratingToken) && (
            <object className="button-loader" type="image/svg+xml" data={buttonLoaderWhite}>
              svg-animation
            </object>
          )}
          <span>Renew Token</span>
        </Button>
      </StyledActionButton>
    </>
  );
};

EnvironmentForm.defaultProps = {
  accessToken: '',
  organizationUserIds: { id: '' },
};

EnvironmentForm.propTypes = {
  organizationId: PropTypes.string.isRequired,
  accessToken: PropTypes.string,
  isLoadingToken: PropTypes.bool.isRequired,
  isGeneratingToken: PropTypes.bool.isRequired,
  organizationUserIds: PropTypes.shape({}),
  match: PropTypes.shape({
    params: PropTypes.shape({
      organizationId: PropTypes.string,
    }),
  }).isRequired,
  actions: PropTypes.shape({
    handleGetPersonalAccessToken: PropTypes.func,
    handleRenewPersonalAccessToken: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = (state) => {
  const { isLoadingToken, isGeneratingToken, accessToken } = state.organization;
  const { organizationUserIds, currentOrganization, lastUsedOrganization } = state.userManager;

  return {
    isLoadingToken,
    isGeneratingToken,
    accessToken,
    organizationUserIds,
    organizationId: currentOrganization || lastUsedOrganization?.id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      handleGetPersonalAccessToken,
      handleRenewPersonalAccessToken,
    },
    dispatch
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(EnvironmentForm);
