import { adminHashIDsApi } from 'app/services/admin/hashids';
import { adminOrganizationsApi } from 'app/services/admin/organizations';
import { adminUsersApi } from 'app/services/admin/users';

export const adminApi = {
    adminHashIDsApi,
    adminOrganizationsApi,
    adminUsersApi,
};

export const adminApiReducers = {
    [adminHashIDsApi.reducerPath]: adminHashIDsApi.reducer,
    [adminOrganizationsApi.reducerPath]: adminOrganizationsApi.reducer,
    [adminUsersApi.reducerPath]: adminUsersApi.reducer,
}

export const adminApiMiddlewares = [
    adminHashIDsApi.middleware,
    adminOrganizationsApi.middleware,
    adminUsersApi.middleware,
];
