import React, { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import PrivateRoute from './PrivateRoute';
import { privateRoutes, publicRoutes } from './config';
import NotFoundPage from './static/NotFound';

// Enable this in local and stage to see storybook examples
// if we're not worried of increasing FE bundle size, we could enable this under an env variable
// uncertain if we can make this conditional with a build argument
// publicRoutes.push({
//     id: 'examples',
//     path: '/examples',
//     title: 'Example',
//     component: React.lazy(() => import('./examples/ExamplePage').then(module => ({
//       default: module.ExamplePage,
//     }))),
// })

const AppRoutes = ({ toggleErrorScreen }) => {
  const history = useHistory();

  /* remove critical error screen on route change */
  useEffect(() => {
    const unlisten = history.listen(() => {
      /* reset criticalError state from globalReducer */
      toggleErrorScreen({
        isActive: false,
        details: { title: '', description: '', useOnlyReloadButton: false, showEmptyPage: false },
      });
    });
    return function cleanup() {
      unlisten();
    };
  }, [history, toggleErrorScreen]);

  return (
    <Suspense fallback={null}>
      <Switch>
        {privateRoutes.map((route) => (
          <PrivateRoute key={route.id} {...route} />
        ))}

        {publicRoutes.map((route) => (
          <Route key={route.id} {...route} />
        ))}
        {/* redirect from organization to projects */}
        <Redirect exact from="/:organizationId" to="/:organizationId/projects" />
        {/* redirect from clusters to integrations/clusters */}
        <Redirect exact from="/:organizationId/clusters" to="/:organizationId/integrations/clusters" />
        {/* external links, should we make a general external component? */}
        <Route path="/external/contact" component={() => {
            window.location.href = 'https://www.bunnyshell.com/contact/';
            return null;
        }} />
        <Route path="/external/help/introduction" component={() => {
            window.location.href = 'https://documentation.bunnyshell.com/docs/introduction-to-bunnyshell';
            return null;
        }} />
        <Route key="404-page" id="page-404" component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
};

AppRoutes.propTypes = {
  toggleErrorScreen: PropTypes.func.isRequired,
};

export default AppRoutes;
