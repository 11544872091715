import styled, { css } from 'styled-components';

const StyledGeneralSettings = styled.div`
  ${({ theme }) => {
    const { palette, fontWeight } = theme;

    return css`
      .card-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        min-height: 61px;
        border-bottom: 1px solid ${palette.border[2]};
        #dark & {
          border-bottom: 1px solid ${palette.border[4]};
        }
        .page-title {
          font-weight: ${fontWeight[0]};
        }
      }
      .divider {
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid ${palette.border[2]};
        #dark & {
          border-bottom: 1px solid ${palette.border[4]};
        }
      }
      .heading {
        color: ${palette.text[0]};
      }
      .save-button.ant-btn.ant-btn-primary[disabled]{
        width: 63px;
        opacity: 1;
        background-color: ${palette.primary[0]} !important;
      }
      .description {
        color: ${palette.color[4]};
      }

      .name-input {
        max-width: 380px;
      }

      .form-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    `;
  }}
`;

StyledGeneralSettings.displayName = 'StyledGeneralSettings';
export default StyledGeneralSettings;
