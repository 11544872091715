/* eslint-disable max-params,max-lines,no-unused-vars */
import * as R from 'ramda';
import React from 'react';
import {
  hideDialog,
  showDialog,
  showNotification,
  handleHasUnSavedChanges
} from 'actions/global';
import mixpanel from 'mixpanel-browser';
import handleRequestDuplication from 'config/http/handleRequestDuplication';
import { TOAST_TYPES } from 'components/notificationsContainer/helpers';
import { API, GET, POST, DELETE, PUT } from 'config';
import { handleGetProjects } from 'modules/projects/actions';
import {toLower, log, getErrorMessage, setOpenWindowLocation, includes, isBlank} from 'utils';
import PublishErrorModal from "modules/environments/components/details/components/components/PublishErrorModal";
import { createPrefixedAction } from '../config';
import { getTimeframesToAdd, getTimeframesToDelete } from './helpers';
import actionTypes from './actionTypes';

export const getEnvironments = createPrefixedAction(actionTypes.GET_ENVIRONMENTS);
export const createEnvironment = createPrefixedAction(actionTypes.CREATE_ENVIRONMENT);
export const deleteEnvironment = createPrefixedAction(actionTypes.DELETE_ENVIRONMENT);
export const forceDeleteEnvironment = createPrefixedAction(actionTypes.FORCE_DELETE_ENVIRONMENT);
export const deployEnvironment = createPrefixedAction(actionTypes.DEPLOY_ENVIRONMENT);
export const getEnvironmentDetails = createPrefixedAction(actionTypes.GET_ENVIRONMENT_DETAILS);
export const updateEnvironment = createPrefixedAction(actionTypes.UPDATE_ENVIRONMENT);
export const manuallyUpdateEnvironment = createPrefixedAction(actionTypes.MANUALLY_UPDATE_ENVIRONMENT);
export const getEnvironmentVariables = createPrefixedAction(actionTypes.GET_ENV_VARIABLES);
export const createVariable = createPrefixedAction(actionTypes.CREATE_VARIABLE);
export const deleteVariable = createPrefixedAction(actionTypes.DELETE_VARIABLE);
export const updateVariable = createPrefixedAction(actionTypes.UPDATE_VARIABLE);
export const editComponent = createPrefixedAction(actionTypes.EDIT_COMPONENT)
export const getComponents = createPrefixedAction(actionTypes.GET_COMPONENTS);
export const getComponentsYaml = createPrefixedAction(actionTypes.GET_COMPONENTS_YAML);
export const getDraftComponents = createPrefixedAction(actionTypes.GET_DRAFT_COMPONENTS);
export const addComponent = createPrefixedAction(actionTypes.ADD_COMPONENT);
export const removeComponent = createPrefixedAction(actionTypes.REMOVE_COMPONENT);
export const saveConfiguration = createPrefixedAction(actionTypes.SAVE_CONFIGURATION);
export const publishConfiguration = createPrefixedAction(actionTypes.PUBLISH_CONFIGURATION);
export const getTimeframes = createPrefixedAction(actionTypes.GET_TIMEFRAMES);
export const updateEnvironmentTimeframes = createPrefixedAction(actionTypes.UPDATE_ENVIRONMENT_TIMEFRAMES);
export const createTimeframe = createPrefixedAction(actionTypes.CREATE_TIMEFRAME);
export const editTimeframe = createPrefixedAction(actionTypes.EDIT_TIMEFRAME);
export const removeTimeframe = createPrefixedAction(actionTypes.REMOVE_TIMEFRAME);
export const revertTimeframes = createPrefixedAction(actionTypes.REVERT_TIMEFRAME);
export const startEnvironment = createPrefixedAction(actionTypes.START_ENVIRONMENT);
export const stopEnvironment = createPrefixedAction(actionTypes.STOP_ENVIRONMENT);
export const resetState = createPrefixedAction(actionTypes.RESET_STATE);
export const getKubernetesClusters = createPrefixedAction(actionTypes.GET_KUBERNETES_CLUSTERS);
export const cloneEnvironment = createPrefixedAction(actionTypes.CLONE_ENVIRONMENT);
export const getEnvironmentEvents = createPrefixedAction(actionTypes.GET_ENVIRONMENT_EVENTS);
export const getEnvironmentPipelines = createPrefixedAction(actionTypes.GET_ENVIRONMENT_PIPELINES);
export const getEnvironmentPipelineDetails = createPrefixedAction(actionTypes.GET_ENVIRONMENT_PIPELINE_DETAIL);
export const getEnvironmentPipelinesUsers = createPrefixedAction(actionTypes.GET_ENVIRONMENT_PIPELINES_USERS);
export const getSourceEvents = createPrefixedAction(actionTypes.GET_SOURCE_EVENTS);
export const updateEnvironmentEventsList = createPrefixedAction(actionTypes.UPDATE_ENVIRONMENT_EVENTS_LIST);
export const getEnvironmentEventLogs = createPrefixedAction(actionTypes.GET_ENVIRONMENT_EVENT_LOGS);
export const updateEnvironmentEventLogs = createPrefixedAction(actionTypes.UPDATE_ENVIRONMENT_EVENT_LOGS);
export const getLogLink = createPrefixedAction(actionTypes.GET_LOG_LINK);
export const setCurrentEnvironmentsNo = createPrefixedAction(actionTypes.SET_CURRENT_ENVIRONMENTS_NO);
export const getManifests = createPrefixedAction(actionTypes.GET_MANIFESTS);
export const changeStatus = createPrefixedAction(actionTypes.CHANGE_STATUS);
export const resetEnvErrors = createPrefixedAction(actionTypes.RESET_ENV_ERRORS);
export const resetEnvComponents = createPrefixedAction(actionTypes.RESET_ENV_COMPONENTS);
export const resetEnvDetailsCosts = createPrefixedAction(actionTypes.RESET_ENV_DTL_COSTS);
export const convertToPrimary = createPrefixedAction(actionTypes.CONVERT_TO_PRIMARY);
export const abortEnvironment = createPrefixedAction(actionTypes.ABORT_ENVIRONMENT);
export const toggleAutoUpdate = createPrefixedAction(actionTypes.TOGGLE_AUTO_UPDATE);
export const applyModule = createPrefixedAction(actionTypes.APPLY_MODULE);
export const getModuleResources = createPrefixedAction(actionTypes.GET_MODULE_RESOURCES);
export const detachModule = createPrefixedAction(actionTypes.DETACH_MODULE);
export const syncModule = createPrefixedAction(actionTypes.SYNC_MODULE);
export const saveBuildSettings = createPrefixedAction(actionTypes.SAVE_BUILD_SETTINGS);
export const getEnvSecretValue = createPrefixedAction(actionTypes.GET_ENV_SECRET_VALUE);
export const hideEnvSecretValue = createPrefixedAction(actionTypes.HIDE_ENV_SECRET_VALUE);
export const getComponentSecretValue = createPrefixedAction(actionTypes.GET_COMPONENT_SECRET_VALUE);
export const hideComponentSecretValue = createPrefixedAction(actionTypes.HIDE_COMPONENT_SECRET_VALUE);
export const resetSecretValues = createPrefixedAction(actionTypes.RESET_SECRET_VALUES);
export const resetSecretValue = createPrefixedAction(actionTypes.RESET_SECRET_VALUE);
export const resetResourceId = createPrefixedAction(actionTypes.RESET_RESOURCE_ID);
export const resetEnvDetails = createPrefixedAction(actionTypes.RESET_ENV_DETAILS);
export const checkClusterCapability = createPrefixedAction(actionTypes.CHECK_CLUSTER_CAPABILITY);
export const showEnvListSkeletons = createPrefixedAction(actionTypes.SHOW_ENV_LIST_SKELETONS);
export const getKubernetesResource = createPrefixedAction(actionTypes.GET_KUBERNETES_RESOURCES);
export const getCompPipelineData = createPrefixedAction(actionTypes.GET_COMP_PIPELINE_DATA);
export const resetCompPipelineState = createPrefixedAction(actionTypes.RESET_COMP_PIPELINE_STATE);
export const getComponentDefinition = createPrefixedAction(actionTypes.GET_COMPONENT_DEFINITION);
export const getK8sResDefinition = createPrefixedAction(actionTypes.GET_K8s_RES_DEFINITION);
export const getStreamId = createPrefixedAction(actionTypes.GET_STREAM_ID);
export const resetAllComponentsVariable = createPrefixedAction(actionTypes.RESET_ALL_COMPONENTS_VARIABLE);
export const handleDeployedIds = createPrefixedAction(actionTypes.HANDLE_DEPLOYED_IDS)
export const saveUrlHandle = createPrefixedAction(actionTypes.SAVE_URL_HANDLE);
export const resetEnvViolations = createPrefixedAction(actionTypes.RESET_ENV_VIOLATIONS);
export const enrichComponents = createPrefixedAction(actionTypes.ENRICH_COMPONENTS);
export const resetPublishError = createPrefixedAction(actionTypes.RESET_PUBLISH_ERROR);
export const getEnvironmentsMtd = createPrefixedAction(actionTypes.GET_ENVIRONMENTS_MTD);
export const applyClusterConfiguration = createPrefixedAction(actionTypes.APPLY_CLUSTER_CONFIGURATION);
export const getModuleOutputValues = createPrefixedAction(actionTypes.GET_MODULE_OUTPUT_VALUES);
export const updateCheckedComponentsList = createPrefixedAction(actionTypes.UPDATE_CHECKED_COMPONENTS_LIST);

let maxTriesHandleGetDraftComponents = 2;

/* headers */
const ldJsonHeader = { 'Content-Type': 'application/ld+json', Accept: 'application/ld+json' };
const appJsonHeader = { Accept: 'application/json' };

export const handleGetEnvironments =
  // eslint-disable-next-line consistent-return
    ({organizationId, projectId, history, optionalArgsObj = {}, extraDataObject}) => async (dispatch) => {
      let requestURL = API.projects.environments;
      const isEnvironmentsListingPage = window.location?.pathname.endsWith('/environments');
      let queryParams = new URLSearchParams(history.location.search);
      
      let statusFilters = queryParams.getAll('status[]');

      // Start of "also search for deployed status if running is selected"
      if (statusFilters.length > 0) {
        if (statusFilters.includes('running')) {
          statusFilters = [...statusFilters, 'deployed'];
        }
        
        if (statusFilters.includes('partially_running') ) {
          statusFilters = [...statusFilters, 'partially_deployed'];
        } 
        
        if (statusFilters.includes('running_with_errors') ) {
          statusFilters = [...statusFilters, 'deployed_with_errors'];
        } 
        
        if (statusFilters.includes('partially_running_with_errors')) {
          statusFilters = [...statusFilters, 'partially_deployed_with_errors'];
        }
  
        queryParams.delete('status[]')
  
        statusFilters.forEach((status) => {
          queryParams.append('status[]', status);
        });
      }
      // End of "also search for deployed status if running is selected"
      
      queryParams = queryParams.toString();

      const { withQueryParams = false, withSkeletons = false } = optionalArgsObj;

      /** true if has search / filters active */
      if (withQueryParams && queryParams && isEnvironmentsListingPage) {
        requestURL = `${requestURL}?project[]=${projectId}&${queryParams}&order[createdAt]=desc`;
      } else {
        requestURL = `${requestURL}?project[]=${projectId}&order[createdAt]=desc`;
      }

      /**
       * if true: Request is duplicated (another is already pending);
       */
      if (handleRequestDuplication.isPending(requestURL)) {
        /**
         * callback object: stores this duplicated requests
         * it will be used after the pending request finished
         */
        handleRequestDuplication.callAgainHolder[requestURL] = {
          dispatchFn: dispatch,
          callbackFn: handleGetEnvironments.bind(null, {
            organizationId,
            projectId,
            history,
            optionalArgsObj,
            extraDataObject
          }
          ),
        };

        /**
         * prevent_then is a flag that prevents .then() to be executed (!add the check inside the targeted .then()) inside the component
         * simply rejecting the promise will result in an unwanted console error
         */
        return 'prevent_then';
      }

      /**
       * the request will be sent and the url will be added to the pending list
       */
      handleRequestDuplication.addToPending(requestURL);

      try {
        if (withSkeletons) {
          dispatch(showEnvListSkeletons(true));
        }

        await dispatch(
          getEnvironments(GET(requestURL, ldJsonHeader, extraDataObject).then(({ data }) => {
              /** remove from pending list */
              handleRequestDuplication.removeFromPending(requestURL);
              return data;
            }), 
          )
        );
      } catch (exception) {
        log.error('Error', exception);

        /** remove from pending list */
        handleRequestDuplication.removeFromPending(requestURL);

        if (exception.message !== 'canceled') {          
          dispatch(showNotification(TOAST_TYPES.error, 'Error', 'The list of environments could not be loaded'));
        }

        return 'failed';
      }
    };

export const handleDeployEnvironment =
  ({ environmentId, environmentName, arrOfComponentNames, withAllDependencies, cleanupComponentName }) =>
  async (dispatch) => {
    let withSuccess = false;
    let payload =  null;

    // deploy marked for deployment components
    if (arrOfComponentNames?.length > 0) {
      payload = { components: arrOfComponentNames };
    } 
    
    // clean marked for deletion components
    else if (cleanupComponentName) {
      payload = {
        cleanupComponents: [cleanupComponentName],
      };
    }

    const deployURL = `${API.environments.deploy(environmentId)}${
      withAllDependencies ? '?includedDependencies=all' : ''
    }`;

    try {
      await dispatch(
        deployEnvironment(
          POST(deployURL, payload).then(() => {
            withSuccess = true;

            if (environmentName) {
              dispatch(
                showNotification(
                  TOAST_TYPES.success,
                  'Environment updated successfully',
                  `${environmentName} environment has been successfully updated.`
                )
              );
            }
            return environmentId;
          })
        )
      );
    } catch (exception) {
      log.error('Environment Deploy.', exception);

      if (environmentName) {
        dispatch(
          showNotification(
            TOAST_TYPES.error,
            ' Environment updating failed',
            `${environmentName} environment update has failed. Check logs to identify and correct the issues that caused the updating failure.`
          )
        );
      } else {
        dispatch(showNotification(TOAST_TYPES.error, 'Environment Deploy', getErrorMessage(exception)));
      }
    }

    return withSuccess;
  };

export const handleCreateEnvironment = ({
  projectId,
  environmentName,
  organizationId,
  fromTemplateId,
  templateVariables,
  autoDeployClusterID,
}, history) => async (dispatch) => {
    const payload = {
      name: environmentName,
      type: 'primary',
      project: `api/projects/${projectId}`,
    };

    /* add from template id prop */
    if (fromTemplateId) {
      payload.fromTemplateId = fromTemplateId;
      payload.templateVariables = templateVariables;
    }

    if (autoDeployClusterID) {
      payload.kubernetesIntegration = `/api/kubernetes_integrations/${autoDeployClusterID}`;
    }

    try {
      await dispatch(
        createEnvironment(
          POST(API.environments.base, payload).then(({ data }) => {
            dispatch(hideDialog());
            dispatch(handleGetProjects({ organizationId }, history));

            if (autoDeployClusterID) {
              dispatch(handleDeployEnvironment({ environmentId: data.id, environmentName }));
            }

            let forcedState = {
              type: 'primary',
              hasList: false,
              count: 0,
              name: environmentName,
              status: 'ready',
              clusterName: null,
              createdAt: data.createdAt,
              updatedAt: data.updatedAt,
              unique: data.unique,
              parentName: null,
            };
          
            if (fromTemplateId) {
              /* fromTemplate will trigger main loader on env detail page */
              forcedState = {
                ...forcedState,
                fromTemplate: true,
              }
            }

            history.push(`/${organizationId}/projects/${projectId}/environments/${data.id}`, forcedState);

            return data;
          })
        )
      );
    } catch (exception) {
      log.error('Environment not created', exception);
      if (exception.response.status === 422) {
        return;
      }  
      dispatch(showNotification(TOAST_TYPES.error, 'Environment not created', getErrorMessage(exception)));
    }
  };

export const handleDeleteEnvironment =
  ({environmentId, organizationId, projectId, environmentsNo, history, noRedirect}) => async (dispatch) => {
    const queryParams = window.location?.href.split('?')[1] || '';
    try {
      await dispatch(
        deleteEnvironment(
          DELETE(`${API.environments.base}/${environmentId}`).then(() => {
            if (!noRedirect) {
              history.push(`/${organizationId}/projects/${projectId}/environments${queryParams && `?${queryParams}`}`, { envCount: environmentsNo - 1 });
            } 
            return parseInt(environmentId, 10);
          })
        )
      );
    } catch (exception) {
      dispatch(
        showNotification(TOAST_TYPES.error, 'Error', exception?.response?.data?.detail ||  exception?.response?.data?.message  || 'Environment not deleted')
      );
      log.error('Environment could not be deleted', exception);
    }
  };

export const handleForceDeleteEnvironment =
  (environmentId, organizationId, projectId, environmentsNo, history) => async (dispatch) => {
    const queryParams = window.location?.href.split('?')[1] || '';
    try {
      await dispatch(
        forceDeleteEnvironment(
          DELETE(`${API.environments.base}/${environmentId}?deleteFromDatabaseOnly=true`).then(() => {
            history.push(`/${organizationId}/projects/${projectId}/environments${queryParams && `?${queryParams}`}`, { envCount: environmentsNo - 1 });
            return parseInt(environmentId, 10);
          })
        )
      );
    } catch (exception) {
      dispatch(
        showNotification(TOAST_TYPES.error, 'Error', exception?.response?.data?.detail ||  exception?.response?.data?.message || 'Environment not deleted')
      );
      log.error('Environment could not be deleted', exception);
    }
  };

export const handlePublishDeployEnvironment = (
  environmentId, envName, organizationId, projectId, mode, arrOfComponentNames, withAllDependencies
) => async (dispatch) => {
  try {
    await dispatch(
      publishConfiguration(
        POST(`${API.applications.publish(environmentId)}`).then(({ data }) => {
          dispatch(handleDeployEnvironment({ 
            environmentId, environmentName: envName, arrOfComponentNames, withAllDependencies }));
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Environment Deploy.', exception);

    const violations = exception.response.data?.violations || [];

    if (violations.length === 1 && violations[0].propertyPath === 'urlHandle') {
      let { message } = violations[0];
      const isAlreadyUsed = message.indexOf('handle is already used') !== -1;

      if (isAlreadyUsed) {
        message = `${
          message.indexOf('by environment') !== -1
            ? `This URL handle setup for this environment is already used by environment ${message.split('by environment')[1]} Change the URL handle and try again.`
            : 'This URL handle setup for this environment is already used. Change the URL handle and try again.'
        } `;
      }

      dispatch(
        showDialog({
          options: {
            size: 'small',
            className: 'handleCannotDeployUrlHandle',
          },
          header: (
            <>
              <svg className="dialog-icon" width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="56" height="56" rx="28" fill={`${mode === 'dark' ? '#E32525' : '#FFE9E9'}`}/>
                <rect x={`${mode === 'dark'  ? '2' : '8'}`} y={`${mode === 'dark'  ? '2' : '8'}`} width={`${mode === 'dark'  ? '52' : '40'}`} height={`${mode === 'dark'  ? '52' : '40'}`} rx={`${mode === 'dark'  ? '52' : '20'}`} fill={`${mode === 'dark' ? '#222222' : '#FFCACE"'}`}/>
                <path d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z" stroke="#E32525" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              <br />
              Cannot deploy environment
            </>
          ),
          body: message,
          actions: <div />,
        })
      );
    } else {
      // dispatch(showNotification(TOAST_TYPES.error, 'Environment Deploy', getErrorMessage(exception)));
      dispatch(
        showDialog({
          options: {
            size: 'large',
            className: 'handleCannotDeployMfd',
            onCancel: () => {dispatch(resetPublishError()); dispatch(hideDialog())},
          },
          header: (
            <>
              <svg className="dialog-icon" width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="56" height="56" rx="28" fill={`${mode === 'dark' ? '#E32525' : '#FFE9E9'}`}/>
                <rect x={`${mode === 'dark'  ? '2' : '8'}`} y={`${mode === 'dark'  ? '2' : '8'}`} width={`${mode === 'dark'  ? '52' : '40'}`} height={`${mode === 'dark'  ? '52' : '40'}`} rx={`${mode === 'dark'  ? '52' : '20'}`} fill={`${mode === 'dark' ? '#222222' : '#FFCACE"'}`}/>
                <path d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z" stroke="#E32525" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            <br />
              Cannot deploy environment
            </>
          ),
          body: (<PublishErrorModal
            environmentId={environmentId}
            organizationId={organizationId}
            projectId={projectId}
            exception={exception}
          />),
          actions: <div />
        })
      );
    }
  }
};
export const handleRestartEnvironment = (environmentId) => async (dispatch) => {
  try {
    await dispatch(deployEnvironment(POST(API.environments.deploy(environmentId))));
  } catch (exception) {
    log.error('Environment Deploy.', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Environment Deploy', getErrorMessage(exception)));
  }
};
export const handleStopEnvironment = ({ environmentId, arrOfComponentNames }) => async (dispatch) => {
  // if arrOfComponentNames - stop only components from arrOfComponentNames
  const payload = arrOfComponentNames?.length > 0 ? { components: arrOfComponentNames }: null;
  try {
    await dispatch(
      stopEnvironment(
        POST(API.environments.stop(environmentId), payload).then(() => {
          return environmentId;
        })
      )
    );
  } catch (exception) {
    const errorMessage = getErrorMessage(exception);
    dispatch(showNotification(TOAST_TYPES.error, 'An error has occurred', errorMessage));
    log.error('Environment Stop.', exception);
  }
};
export const handleStartEnvironment = ({ environmentId, arrOfComponentNames }) => async (dispatch) => {
  // if arrOfComponentNames - start only components from arrOfComponentNames
  const payload = arrOfComponentNames?.length > 0 ? { components: arrOfComponentNames }: null;
  try {
    await dispatch(
      startEnvironment(
        POST(API.environments.start(environmentId), payload).then(() => {
          return environmentId;
        })
      )
    );
  } catch (exception) {
    const errorMessage = getErrorMessage(exception);
    dispatch(showNotification(TOAST_TYPES.error, 'An error has occurred', errorMessage));
    log.error('Environment Start.', exception);
  }
};
export const handleGetTimeframes = (environmentId) => async (dispatch) => {
  try {
    await dispatch(
      getTimeframes(
        GET(API.environments.timeframes(environmentId)).then(({data}) => {
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Get timeframes: ', exception);
  }
};

export const handleGetEnvironmentVariables = (id, searchTerm, hasDraft) => async (dispatch) => {
  const url = hasDraft
    ? `${API.environments.base}/${id}/draft/environment_variables?q=${searchTerm}`
    : `${API.environments.base}/${id}/environment_variables?q=${searchTerm}`;
  try {
    await dispatch(
      getEnvironmentVariables(
        GET(url).then(({ data }) => {
          return { data, isSearch: searchTerm !== '' };
        })
      )
    );
  } catch (exception) {
    log.error(`Environment unavailable.`, exception);
  }
};

export const handleGetEnvironmentDetails =
  ({ organizationId, projectId, environmentId, history, extraDataObject }) => async (dispatch) => {
    let localEnvState = null;
    const requestURL = `${API.environments.base}/${environmentId}`;

    if (handleRequestDuplication.isPending(requestURL)) {
      handleRequestDuplication.callAgainHolder[requestURL] = {
        dispatchFn: dispatch,
        callbackFn: handleGetEnvironmentDetails.bind(null, {
          organizationId, projectId, environmentId, history, extraDataObject}),
      };
      return 'prevent_then';
    }
  
    handleRequestDuplication.addToPending(requestURL);
  
    try {
      await dispatch(
        getEnvironmentDetails(
          GET(requestURL, appJsonHeader, extraDataObject).then((body) => {
            const environment = body.data;
            const queryParams = window.location?.href.split('?')[1] || '';

            localEnvState = environment;

            handleRequestDuplication.removeFromPending(requestURL);

            /* redirect user is env is open in another tab */
            if (environment.status === 'deleted' && history) {
              history.push(`/${organizationId}/projects/${projectId}/environments${queryParams && `?${queryParams}`}`);
            }

            return environment;
          })
        )
      );
    } catch (exception) {
      log.error(`Environment unavailable.`, exception);
      handleRequestDuplication.removeFromPending(requestURL);
    }

    return localEnvState;
  };

export const handleUpdateEnvironment = (
  payload, history, source, mode, arrOfComponentNames, withAllDependencies) => async (dispatch, getState) => {
  let withSuccess = true;

  try {
    const { environmentId, autoDeployEphemeral, kubernetesIntegration, ephemeralKubernetesIntegration } = payload;
    const { environment } = getState().environments;
    const { name, autoUpdate, createEphemeralOnPrCreate, destroyEphemeralOnPrClose } = environment;

    await dispatch(
      updateEnvironment(
        PUT(`${API.environments.base}/${environmentId}`, {
          name,
          autoUpdate,
          autoDeployEphemeral,
          createEphemeralOnPrCreate,
          destroyEphemeralOnPrClose,
          kubernetesIntegration,
          ephemeralKubernetesIntegration,
          ...R.omit(['environmentId', 'kubernetesIntegration'], payload),
        }).then(({ data }) => {
          if (source === 'details' || source === 'configuration') {
            if (data.hasDraft) {
              dispatch(handlePublishDeployEnvironment(data.id, '', null, null, mode, arrOfComponentNames, withAllDependencies));
            } else {
              dispatch(handleDeployEnvironment({ 
                environmentId: data.id, environmentName: null, arrOfComponentNames, withAllDependencies }));
            }
          }
          if (history.location.pathname.includes('settings/general')) {
            dispatch(
              showNotification(TOAST_TYPES.success, 'Environment updated', 'The environment was successfully updated.')
            );
          } else if (history.location.pathname.includes('settings/ephemeral-environments')) {
            dispatch(
              showNotification(
                TOAST_TYPES.success,
                'Changes saved',
                'Your changes to the environment configuration have been saved.'
              )
            );
          }
          return { data, source };
        })
      )
    );
  } catch (exception) {
    log.error(`Environment unavailable.`, exception);
    const errorMessage = getErrorMessage(exception);
    dispatch(showNotification(TOAST_TYPES.error, 'An error has occurred', errorMessage));

    withSuccess = false;
  }

  return { withSuccess };
};

export const handleUpdateEmptyEnvironment = ({environmentId, payload}) => async (dispatch) => {
  let withSuccess = true;
  let localException = null;

  try {
    await dispatch(updateEnvironment(PUT(`${API.environments.base}/${environmentId}`, payload).then((data)=>{
      return data;
    })));
  } catch (exception) {
    withSuccess = false;
    localException = exception;

    log.error(`Environment update failed.`, exception);
  }

  return { withSuccess, localException };
};

export const handleUpdateEnvironmentLabels = ({ environmentId, payload }) => async (dispatch) => {
  try {
    await dispatch(updateEnvironment(PUT(`${API.environments.base}/${environmentId}`, payload).then((data)=>{
      dispatch(showNotification(TOAST_TYPES.success, 'Success', 'Labels updated successfully.'));
      return data;
    })));
  } catch (exception) {
    dispatch(showNotification(TOAST_TYPES.error, 'Error', getErrorMessage(exception)));
    log.error(`Environment update failed.`, exception);
  }

  return 'done';
};


export const handleCreateVariable =
  (varName, varValue, varEnvironmentId, secret, organizationId, projectId) => async (dispatch, getState) => {
    const { mode } = getState().global;
    try {
      await dispatch(
        createVariable(
          POST(`${API.environments.base}/${varEnvironmentId}/draft/environment_variables/add`, {
            key: varName,
            value: varValue,
            scope: 'environment',
            //environment: `/api/environments/${varEnvironmentId}`,
            isSecret: secret,
          }).then(({ data }) => {
              dispatch(handleGetEnvironmentDetails(
                { organizationId, projectId, environmentId: varEnvironmentId})).then(() => {
                dispatch(handleGetEnvironmentVariables(varEnvironmentId, '', true));
              });
            dispatch(hideDialog());
            dispatch(
              showNotification(
                TOAST_TYPES.success,
                'Variable created successfully ',
                `The ${data.key} variable has been created.`
              )
            );
            return data;
          })
        )
      );
    } catch (exception) {
      log.error(`Error.`, exception);

      if (exception.response.status === 422) {
        // TODO: handle error 
      } else if (exception.response.status === 423) {
        dispatch(showDialog({
          options: {
            size: 'large',
            className: 'handleCannotDeployMfd',
            onCancel: () => {
              dispatch(hideDialog());
            },
          },
          header: (
            <>
              <svg
                className="dialog-icon"
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill={`${mode === 'dark' ? '#E32525' : '#FFE9E9'}`} />
                <rect
                  x={`${mode === 'dark' ? '2' : '8'}`}
                  y={`${mode === 'dark' ? '2' : '8'}`}
                  width={`${mode === 'dark' ? '52' : '40'}`}
                  height={`${mode === 'dark' ? '52' : '40'}`}
                  rx={`${mode === 'dark' ? '52' : '20'}`}
                  fill={`${mode === 'dark' ? '#222222' : '#FFCACE"'}`}
                />
                <path
                  d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z"
                  stroke="#E32525"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <br />
              Cannot deploy environment
            </>
          ),
          body: (
            <PublishErrorModal
              environmentId={varEnvironmentId}
              organizationId={organizationId}
              projectId={projectId}
              exception={exception}
            />
          ),
          actions: <div />,
        }));
      } else {
        dispatch(showNotification(TOAST_TYPES.error, 'Error', getErrorMessage(exception))); 
      }
    }

  };

export const handleUpdateVariable =
  (oldVarName, newKey, varValue, varEnvironmentId, secret, organizationId, projectId) => async (dispatch, getState) => {
    const { environment } = getState().environments;
    let payload;
    if (newKey === oldVarName) {
      payload = {
        key: oldVarName,
        value: varValue,
        isSecret: secret,
      };
    } else {
      payload = {
        newKey,
        key: oldVarName,
        value: varValue,
        isSecret: secret,
      };
    }
    try {
      await dispatch(
        updateVariable(
          PUT(`${API.environments.base}/${varEnvironmentId}/draft/environment_variables/edit`, payload).then(
            ({ data }) => {
              dispatch(hideDialog());
              if (!environment.hasDraft) {
                dispatch(handleGetEnvironmentDetails(
                  {organizationId, projectId, environmentId: varEnvironmentId})).then(() => {
                  dispatch(handleGetEnvironmentVariables(varEnvironmentId, '', true));
                });
              } else {
                dispatch(handleGetEnvironmentVariables(varEnvironmentId, '', true));
              }

              dispatch(
                showNotification(
                  TOAST_TYPES.success,
                  'Variable saved successfully ',
                  `The  ${data.key} variable has been updated.`
                )
              );
              return data;
            }
          )
        )
      );
    } catch (exception) {
      log.error(`Error.`, exception);
    }
  };

export const handleDeleteEnvVariable =
  (key, environmentId, organizationId, projectId) => async (dispatch, getState) => {
    const { environment } = getState().environments;

    try {
      await dispatch(
        deleteVariable(
          DELETE(`${API.environments.base}/${environmentId}/draft/environment_variables/delete`, {}, { key }).then(
            () => {
              if (!environment.hasDraft) {
                dispatch(handleGetEnvironmentDetails({organizationId, projectId, environmentId})).then(() => {
                  dispatch(handleGetEnvironmentVariables(environmentId, '', true));
                });
              } else {
                dispatch(handleGetEnvironmentVariables(environmentId, '', true));
              }
              dispatch(resetSecretValues());
              dispatch(hideDialog());
              dispatch(showNotification(TOAST_TYPES.success, 'Variable deleted', 'The variable has been deleted.'));
              return key;
            }
          )
        )
      );
    } catch (exception) {
      log.error(`Error.`, exception);
    }
  };

// eslint-disable-next-line consistent-return
export const handleGetComponents = (environmentId, from) => async (dispatch, getState) => {
  const { environment } = getState().environments;
  const { hasDraft } = environment;
  const requestURL = hasDraft
    ? `${API.environments.draftComponents(environmentId)}`
    : `${API.environments.components(environmentId)}`;
  
  /**
   * if true: Request is duplicated (another is already pending);
   */
   if (handleRequestDuplication.isPending(requestURL)) {
    /**
     * callback object: stores this duplicated requests
     * it will be used after the pending request finished
     */
    handleRequestDuplication.callAgainHolder[requestURL] = {
      dispatchFn: dispatch,
      callbackFn: handleGetComponents.bind(null, environmentId),
    };

    /**
     * prevent_then is a flag that prevents .then() to be executed (!add the check inside the targeted .then()) inside the component
     * simply rejecting the promise will result in an unwanted console error
     */
    return 'prevent_then';
  }

  /**
   * the request will be sent and the url will be added to the pending list
   */
  handleRequestDuplication.addToPending(requestURL);

  try {
    await dispatch(
      getComponents(
        GET(requestURL).then((data) => {
          /** remove from pending list */
          handleRequestDuplication.removeFromPending(requestURL);
          return data;
        })
      )
    );
  } catch (exception) {
    log.error(`Error.`, exception);
    // dispatch(showNotification(TOAST_TYPES.error, 'Error', getErrorMessage(exception)));
    /** remove from pending list */
    handleRequestDuplication.removeFromPending(requestURL);
  }
};

export const handleEditComponent = (environmentId,appName, component, branch) => async(dispatch) => {
  try {
    await dispatch(
      editComponent(
        PUT(`${API.applications.saveComponent(environmentId, appName)}`, {...component, gitBranch: branch}).then((data) => {
          dispatch(hideDialog())
          dispatch(showNotification(TOAST_TYPES.success, 'Component branch has been changed', `The branch for the ${appName} component has been changed successfully.`));
          return data;
        })
      )
    );
  } catch (exception) {
    log.error(`Error.`, exception);

  }
}

export const handlePublishConfiguration =
  (environmentId, organizationId, projectId, history, actionLocation, extraDataObject) => async (dispatch) => {
    let templateQueryParams = '';

    if (extraDataObject?.templateBunnyshellYamlGitSha) {
      const { templateReadmeYamlGitSha, templateGitSha, templateBunnyshellYamlGitSha } = extraDataObject;
      templateQueryParams = templateGitSha || templateBunnyshellYamlGitSha || templateReadmeYamlGitSha
        ? `?templateReadmeYamlGitSha=${templateReadmeYamlGitSha}&templateGitSha=${templateGitSha}&templateBunnyshellYamlGitSha=${templateBunnyshellYamlGitSha}`
        : '';
    }

    try {
      await dispatch(
        publishConfiguration(
          POST(`${API.applications.publish(environmentId)}${templateQueryParams}`).then(() => {
            /** keep user in env config editor and show widget message */
            if (actionLocation === 'from_env_configuration') {
              dispatch(handleGetEnvironmentDetails({organizationId, projectId, environmentId}));
              dispatch(resetEnvErrors());
              dispatch(
                showNotification(
                  TOAST_TYPES.success,
                  'Changes saved',
                  'Your changes to the environment configuration have been saved.'
                )
              );
            }
          })
        )
      );
    } catch (exception) {
      log.error(`Error.`, exception);
      if (includes(getErrorMessage(exception), 'Internal Server Error') || exception?.response?.status >= 500) {
        dispatch(
          showDialog({
            options: { size: 'small' },
            header: '',
            body: (
              <div className="text-center pb-5">
                <svg
                  width="100"
                  height="100"
                  viewBox="0 0 100 100"
                  className="mb-5 mt-5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100C77.6142 100 100 77.6142 100 50Z"
                    fill="#E44848"
                    fillOpacity="0.15"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M43.0485 29.1846C45.8806 29.0804 48.6998 29.6193 51.2937 30.7614C53.8877 31.9033 56.1889 33.6185 58.0246 35.7779C59.5069 37.5214 60.6544 39.5181 61.4146 41.6668H62.5C65.2771 41.6687 67.9758 42.5952 70.1679 44.3004C72.36 46.0056 73.9218 48.3923 74.6068 51.0835C75.2918 53.775 75.061 56.6177 73.951 59.1633C72.8408 61.7089 70.9146 63.8125 68.4762 65.1418C67.466 65.6925 66.2006 65.3202 65.65 64.3098C65.0991 63.2995 65.4716 62.0341 66.4819 61.4835C68.1075 60.5973 69.3916 59.195 70.1316 57.4979C70.8716 55.8008 71.0256 53.9056 70.5689 52.1112C70.1123 50.317 69.071 48.726 67.6096 47.5891C66.1483 46.4525 64.35 45.8348 62.4985 45.8335H59.875C58.9252 45.8335 58.0956 45.1912 57.8579 44.2716C57.306 42.1375 56.2777 40.156 54.85 38.4766C53.4223 36.797 51.6323 35.4631 49.6148 34.5748C47.5973 33.6866 45.4048 33.2673 43.2019 33.3485C40.9989 33.4296 38.8431 34.0091 36.8964 35.0433C34.9498 36.0777 33.2629 37.5398 31.9627 39.3198C30.6625 41.0998 29.7825 43.1514 29.3894 45.3206C28.9962 47.4895 29.0998 49.7195 29.6925 51.8427C30.285 53.9658 31.3512 55.927 32.811 57.5789C33.5729 58.441 33.4916 59.7577 32.6296 60.5195C31.7673 61.2814 30.4506 61.2002 29.6887 60.3381C27.8121 58.2143 26.441 55.6927 25.6791 52.9629C24.9171 50.2331 24.7839 47.366 25.2896 44.5773C25.7952 41.7885 26.9262 39.1506 28.5981 36.8621C30.2698 34.5735 32.4387 32.6935 34.9416 31.3639C37.4444 30.0341 40.2162 29.2889 43.0485 29.1846ZM48.5269 48.5271C49.3404 47.7135 50.6594 47.7135 51.4731 48.5271L59.8064 56.8604C60.62 57.6739 60.62 58.9931 59.8064 59.8066C58.9927 60.6202 57.6737 60.6202 56.8602 59.8066L52.0833 55.0298V68.7502C52.0833 69.9008 51.1504 70.8335 50 70.8335C48.8494 70.8335 47.9166 69.9008 47.9166 68.7502V55.0298L43.1398 59.8066C42.326 60.6202 41.0071 60.6202 40.1935 59.8066C39.3798 58.9931 39.3798 57.6739 40.1935 56.8604L48.5269 48.5271Z"
                    fill="#E44848"
                  />
                </svg>
                <h4 className="mb-2 text-center">Connection Error</h4>
                <p className="mb-3 text-center">We’ve encountered an error while trying to connect to our servers.</p>
              </div>
            ),
          })
        );
      }
    }
  };

export const handleAddComponent = (environmentId, organizationId, projectId, 
  { kind, imageName, tagName, publicPort, privatePort }) => async (dispatch, getState) => {
  const { mode } = getState().global;
    try {
      const body = {
        kind,
        version: 'v1',
        name: toLower(imageName).replace(/[^a-z0-9-]/g,'-'),
        dockerCompose: {
          image: `${imageName}:${tagName || 'latest'}`,
        },
      };
      if (publicPort || privatePort) {
        body.dockerComposeShort = {
          image: `${imageName}:${tagName || 'latest'}`, // bug-ish in BE, we need to send the image in dockerComposeShort too
          ports: [{
            public: publicPort || null,
            target: privatePort || null,
          }],
        };
      }

      await dispatch(
        addComponent(
          POST(API.environments.addComponent(environmentId), body).then(({ data }) => {

            dispatch(hideDialog());
            return data;
          })
        )
      );
    } catch (exception) {
      log.error('Add Component', exception);
      if (exception.response.status === 422) {
        // TODO: handle error 
      } else if (exception.response.status === 423) {
        dispatch(showDialog({
          options: {
            size: 'large',
            className: 'handleCannotDeployMfd',
            onCancel: () => {
              dispatch(hideDialog());
            },
          },
          header: (
            <>
              <svg
                className="dialog-icon"
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill={`${mode === 'dark' ? '#E32525' : '#FFE9E9'}`} />
                <rect
                  x={`${mode === 'dark' ? '2' : '8'}`}
                  y={`${mode === 'dark' ? '2' : '8'}`}
                  width={`${mode === 'dark' ? '52' : '40'}`}
                  height={`${mode === 'dark' ? '52' : '40'}`}
                  rx={`${mode === 'dark' ? '52' : '20'}`}
                  fill={`${mode === 'dark' ? '#222222' : '#FFCACE"'}`}
                />
                <path
                  d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z"
                  stroke="#E32525"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <br />
              Cannot deploy environment
            </>
          ),
          body: (
            <PublishErrorModal
              environmentId={environmentId}
              organizationId={organizationId}
              projectId={projectId}
              exception={exception}
            />
          ),
          actions: <div />,
        }));
      } else {
        dispatch(showNotification(TOAST_TYPES.error, 'Error', getErrorMessage(exception))); 
      }
    }
  };

export const handleGetComponentsYaml = (environmentId) => async (dispatch, getState) => {
  try {
    const { environment } = getState().environments;
    const { hasDraft } = environment;
    const url = hasDraft
      ? `${API.environments.draftComponents(environmentId)}`
      : `${API.environments.components(environmentId)}`;

    await dispatch(
      getComponentsYaml(
        GET(url, { 'Content-Type': 'application/json', Accept: 'text/yaml' }).then((data) => {
          return data;
        })
      )
    );
  } catch (exception) {
    // dispatch(showNotification(TOAST_TYPES.error, 'Error', 'Components could not be loaded'));
  }
};


export const handleSaveConfiguration =
  (environmentId, yaml, organizationId, projectId, history, 
    actionLocation, capabilitySettings, extraDataObject) => async (dispatch) => {
    /**
     * ?validateCapabilities=true it's a flag that will lead to cluster volumes capability check
     * if k8s_volume_cap_invalid, the draft won't be saved */
    const { triggerCheck: triggerCompatibilityCheck } = capabilitySettings;
    const capabilityTag = triggerCompatibilityCheck ? '?validateCapabilities=true' : '';

    try {
      await dispatch(
        saveConfiguration(
          PUT(`${API.environments.draftComponents(environmentId)}${capabilityTag}`, yaml, {
            'Content-Type': 'text/yaml',
            Accept: 'text/yaml',
          }).then((data) => {
            dispatch(handlePublishConfiguration(
              environmentId, organizationId, projectId, history, actionLocation, extraDataObject
            ));
            return data;
          })
        )
      );
    } catch (exception) {
      log.error(`Error.`, exception);
    }
  };

export const handleEnrichComponents = (environmentId, env) => async (dispatch, getState) => {
  try {
    const { environment } = getState().environments;
    const { hasDraft } = env || environment;
    const url = hasDraft
      ? `${API.environments.enrichDraftComponents(environmentId)}?extras=remote_development_status`
      : `${API.environments.enrichComponents(environmentId)}?extras=remote_development_status`;
    await dispatch(enrichComponents(GET(url).then((data) => {
      return data
    })));
  } catch (exception) {
    log.error(`Error.`, exception);
  }
}

export const handleGetDraftComponents = (environmentId, environmentState, from) => async (dispatch, getState) => {
  try {
    const { environment } = getState().environments;
    const { hasDraft } = environmentState || environment;
    const url = hasDraft
      ? `${API.environments.draftComponents(environmentId)}`
      : `${API.environments.components(environmentId)}`;

    await dispatch(getDraftComponents(GET(url).then((data) => {
      return { data };
    })));

    if(from === 'from_env_details') {
      dispatch(handleEnrichComponents(environmentId, environmentState));
    }

    maxTriesHandleGetDraftComponents = 2;

  } catch (exception) {
    // try again
    if (maxTriesHandleGetDraftComponents > 0) {
      maxTriesHandleGetDraftComponents -= 1;

      const { projectId, organizationId } = getState().projects;

      dispatch(handleGetEnvironmentDetails({organizationId, projectId, environmentId})).then((localEnvState)=>{
        dispatch(handleGetDraftComponents(environmentId, localEnvState, from));
      });
    }

    log.error(`Error.`, exception);
  }
};

export const handleGetManifests = (environmentId) => async (dispatch, getState) => {
  try {
    const { environment } = getState().environments;
    const { hasDraft } = environment;
    const url = hasDraft
      ? `${API.environments.draftManifests(environmentId)}`
      : `${API.environments.manifests(environmentId)}`;
    await dispatch(
      getManifests(
        GET(url).then((data) => {
          const fileName = R.split('=', R.path(['headers', 'content-disposition'], data))[1];
          return { data, fileName };
        })
      )
    );
  } catch (exception) {
    dispatch(showNotification(TOAST_TYPES.error, 'Error', getErrorMessage(exception)));
    log.error(`Error.`, exception);
  }
};

export const handleRemoveComponent =
  ({ environmentId, componentName, organizationId, projectId, history, arrOfComponentNames }) =>
  async (dispatch) => {
    let errorMessage = '';
    
    let payload = { componentNames: [componentName] };

    // if arrOfComponentNames is not empty, it means that the user wants to remove multiple components
    if (arrOfComponentNames?.length > 0) {
      payload = {
        componentNames: arrOfComponentNames
      };
    }

    try {
      await dispatch(
        removeComponent(
          POST(API.environments.deleteComponent(environmentId), payload).then(({ data }) => {
            dispatch(handlePublishConfiguration(environmentId, organizationId, projectId, history)).then(() => {
              dispatch(hideDialog());
              dispatch(handleGetEnvironmentDetails({ organizationId, projectId, environmentId })).then(() => {
                dispatch(handleGetComponents(environmentId, 'from_details'));
              });
            });
            return data;
          })
        )
      );
    } catch (exception) {
      errorMessage = getErrorMessage(exception);
      log.error('Error', exception);
    }

    return { errorMessage };
  };

export const handleUpdateEnvironmentTimeframes =
  (organizationId, projectId, environmentId) => async (dispatch, getState) => {
    const { addedTimeframes, markedToDeleteTimeframes } = R.path(['environments', 'environment'], getState());
    const addPromiseArray = getTimeframesToAdd(environmentId, addedTimeframes);
    const deletePromiseArray = getTimeframesToDelete(markedToDeleteTimeframes);
    const promiseArray = R.concat(deletePromiseArray, addPromiseArray);

    try {
      await dispatch(
        updateEnvironmentTimeframes(
          Promise.all(promiseArray).then(() => {
            dispatch(
              showNotification(
                TOAST_TYPES.success,
                'Changes saved.',
                'The changes made on the availability timeframes have been successfully updated.'
              )
            );
            dispatch(handleGetTimeframes(environmentId));
            dispatch(handleGetEnvironmentDetails({organizationId, projectId, environmentId}));
          })
        )
      );
    } catch (exception) {
      log.error(`Timeframes.`, exception);
      const errorMessage = getErrorMessage(exception);

      dispatch(showNotification(TOAST_TYPES.error, 'Cannot save availability timeframes', errorMessage));
      dispatch(handleGetEnvironmentDetails({organizationId, projectId, environmentId}));
    }
  };

export const handleGetKubernetesClusters = (organizationId) => async (dispatch) => {
  try {
    await dispatch(
      getKubernetesClusters(GET(`${API.organizations.kubernetesIntegrations(organizationId)}`).then((data) => data))
    );
  } catch (exception) {
    log.error(`Error.`, exception);
  }
};
export const handleGetEnvironmentEvents =
  (organizationId, projectId, environmentId, page, searchTerm, startDate, endDate, typeFilter, sourceFilter, history) =>
  async (dispatch) => {
    try {
      const status = typeFilter === 'all' ? null : typeFilter;
      const user = sourceFilter;
      const createdAtAfter = startDate === undefined ? null : startDate;
      const createdAtBefore = endDate === undefined ? null : endDate;
      // &createdAt[after]=${startDate}&createdAt[before]=${endDate}
      await dispatch(
        getEnvironmentEvents(
          // GET(`${API.environments.events(organizationId)}?page=${page}&environment=${environmentId}&eCustomSearch=${searchTerm}&status=${type}&user=`)
          GET(`${API.environments.events(organizationId)}`, null, {
            params: {
              page,
              environment: environmentId,
              eCustomSearch: searchTerm,
              status,
              user,
              'createdAt[after]': createdAtAfter,
              'createdAt[before]': createdAtBefore,
            },
          }).then((response) => {
            dispatch(
              getSourceEvents(
                GET(API.environments.sources(organizationId), null, {
                  params: {
                    environment: environmentId,
                    eCustomSearch: searchTerm,
                    status,
                    'createdAt[after]': createdAtAfter,
                    'createdAt[before]': createdAtBefore,
                  },
                }).then((data) => {
                  return data.data;
                })
              )
            );
            return response.data;
          })
        )
      );
    } catch (exception) {
      log.error('Error', exception);
      history.push(`/${organizationId}/projects/${projectId}/environments/${environmentId}`);
    }
  };

// eslint-disable-next-line consistent-return
export const handleGetPipelineDetails = ({pipelineId, extraDataObject}) => async (dispatch) => {
  const requestURL = API.environments.pipeline(pipelineId);

  if (handleRequestDuplication.isPending(requestURL)) {
    handleRequestDuplication.callAgainHolder[requestURL] = {
      dispatchFn: dispatch,
      callbackFn: handleGetPipelineDetails.bind(null, { pipelineId, extraDataObject}),
    };
    return 'prevent_then';
  }

  handleRequestDuplication.addToPending(requestURL);

  try { 
    await dispatch(
      getEnvironmentPipelineDetails(GET(requestURL, null, extraDataObject).then((data) => {
        handleRequestDuplication.removeFromPending(requestURL);
        return data;
      }))
    );
  } catch (exception) {
    log.error(`Error.`, exception);
    handleRequestDuplication.removeFromPending(requestURL);
  }
};

export const handleGetEnvironmentPipelines = ({ 
  organizationId, environmentId, currentPage, searchTerm,
  startDate, endDate, typeFilter, sourceFilter
}) =>
  // eslint-disable-next-line consistent-return
  async (dispatch) => {
    let requestURL = API.environments.pipelines(organizationId);

    const queryParams = window.location?.href.split('?')[1] || '';

    /** true if has search / filters active */
    if (queryParams) {
      requestURL = `${requestURL}?${queryParams}`;
    }

    try {
      const status = typeFilter === 'all' ? null : typeFilter;
      const user = sourceFilter;
      const createdAtAfter = startDate === undefined ? null : startDate;
      const createdAtBefore = endDate === undefined ? null : endDate;

      if (handleRequestDuplication.isPending(requestURL)) {
        handleRequestDuplication.callAgainHolder[requestURL] = {
          dispatchFn: dispatch,
          callbackFn: handleGetEnvironmentPipelines.bind(null, {
            organizationId,
            environmentId,
            currentPage,
            searchTerm,
            startDate,
            endDate,
            typeFilter,
            sourceFilter,
          }),
        };
        return 'prevent_then';
      }

      handleRequestDuplication.addToPending(requestURL);

      await dispatch(
        getEnvironmentPipelines(
          GET(requestURL, ldJsonHeader, {
            params: {
              'event.environment': environmentId,
              'page': currentPage,
              'eCustomSearch': searchTerm,
              status,
              'event.user': user,
              'createdAt[after]': createdAtAfter,
              'createdAt[before]': createdAtBefore,
            },
          }).then((response) => {
            handleRequestDuplication.removeFromPending(requestURL);
            dispatch(
              getEnvironmentPipelinesUsers(
                GET(API.environments.pipelines_users(organizationId), null, {
                  params: {
                    'event.environment': environmentId,
                    'eCustomSearch': searchTerm,
                    status,
                    'createdAt[after]': createdAtAfter,
                    'createdAt[before]': createdAtBefore,
                  },
                }).then((data) => {
                  return data.data;
                })
              )
            );
            return response.data;
          })
        )
      );
    } catch (exception) {
      log.error('Error', exception);
      handleRequestDuplication.removeFromPending(requestURL);
    }
  };

export const handleUpdateEnvironmentEventsList =
  (organizationId, projectId, environmentId, page, searchTerm, startDate, endDate, type, sourceFilter, history) =>
  async (dispatch) => {
    try {
      await dispatch(
        updateEnvironmentEventsList(
          GET(`${API.environments.events(organizationId)}?page=${page}&type=${type}&environment=${environmentId}`).then(
            (response) => {
              return response.data;
            }
          )
        )
      );
    } catch (exception) {
      history.push(`/${organizationId}/projects/${projectId}/environments/${environmentId}`);
      log.error('Error', exception);
      dispatch(showNotification(TOAST_TYPES.error, 'Error', 'The list of events could not be loaded'));
    }
  };
export const handleGetEnvironmentEventLogs =
  (organizationId, projectId, environmentId, eventId, history) => async (dispatch) => {
    try {
      await dispatch(
        getEnvironmentEventLogs(
          GET(`${API.environments.logs(eventId)}`).then((response) => {
            return response.data;
          })
        )
        // eslint-disable-next-line max-lines
      );
    } catch (exception) {
      history.push(`/${organizationId}/projects/${projectId}/environments/${environmentId}/events`);
      log.error('Error', exception);
    }
  };
export const handleUpdateEnvironmentEventLogs =
  (organizationId, projectId, environmentId, eventId, page, history) => async (dispatch) => {
    try {
      await dispatch(
        updateEnvironmentEventLogs(
          GET(`${API.environments.logs(eventId)}?page=${page}`).then((response) => {
            return response.data;
          })
        )
      );
    } catch (exception) {
      history.push(`/${organizationId}/projects/${projectId}/environments/${environmentId}/events`);
      log.error('Error', exception);
      dispatch(showNotification(TOAST_TYPES.error, 'Error', 'The list of event logs could not be loaded.'));
    }
  };

export const handleGetLogLink = (eventId, logId) => async (dispatch) => {
  try {
    await dispatch(
      getLogLink(
        GET(`${API.environments.logUrl(eventId, logId)}`).then((response) => {
          setOpenWindowLocation(response.data.url);
        })
      )
    );
  } catch (exception) {
    log.error('Error', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Error', 'Could not get the log file'));
  }
};

export const handleCloneEnvironment = ({
  environmentId, environmentName, organizationId, projectId, history, source}, payload) => async (dispatch) => {
  try {
    await dispatch(
      cloneEnvironment(
        POST(API.environments.clone(environmentId), payload).then(({ data }) => {
          dispatch(hideDialog());
          dispatch(
            showNotification(
              TOAST_TYPES.success,
              'Environment cloned successfully',
              `${environmentName} environment has been successfully cloned.`
            )
          );

          history.push(`/${organizationId}/projects/${projectId}/environments/${data.id}`, {forceLoader: source === 'isEnvDetailsPage'});
          
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Environment not cloned', exception);
    if (exception.response.status === 422) {
      return;
    }

    dispatch(
      showNotification(
        TOAST_TYPES.error,
        'Environment cloning failed',
        `${environmentName} environment cloning has failed. Check logs to identify and correct the issues that caused the cloning failure.`
      )
    );
  }
};

export const handleConvertToPrimary = (environmentId, payload) => async (dispatch) => {
  try {
    await dispatch(
      convertToPrimary(
        POST(API.environments.convertToPrimary(environmentId), payload).then(({ data }) => {
          // eslint-disable-next-line max-lines
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Environment not created', exception);
  }
};

export const handleAbortEnvironmentAction = (environmentId) => async (dispatch) => {
  try {
    await dispatch(
      abortEnvironment(
        POST(`${API.environments.base}/${environmentId}/abort`).then(({ data }) => {
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Action not aborted', exception);
    const errorMessage = getErrorMessage(exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Error', errorMessage));
  }
};

export const handleToggleAutoUpdate = (moduleId, value) => async (dispatch) => {
  try {
    await dispatch(
      toggleAutoUpdate(
        POST(`${API.environments.modulesUsesBase(moduleId)}/toggle-auto-update`, { autoUpdate: value }).then(
          ({ data }) => {
            return data;
          }
        )
      )
    );
  } catch (exception) {
    log.error('Get module uses', exception);
  }
};
export const handleApplyModule = (moduleId) => async (dispatch) => {
  try {
    await dispatch(
      applyModule(
        POST(`${API.environments.modulesUsesBase(moduleId)}/apply`, {}).then(() => {
          return moduleId;
        })
      )
    );
  } catch (exception) {
    log.error('Get module uses', exception);
  }
};

export const handleGetModulePlanOutput = (moduleId) => async (dispatch) => {
  try {
    await dispatch(
      getModuleResources(
        GET(`${API.environments.modulesUsesBase(moduleId)}/plan_output`, {}).then(({ data }) => {
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Get module uses', exception);
  }
};

export const handleDetachModule = (moduleId, option) => async (dispatch) => {
  try {
    await dispatch(
      detachModule(
        DELETE(
          `${API.environments.modulesUsesBase(moduleId)}?deleteManagedResources=${option === 'destroy-resources'}`,
          {},
          { moduleId }
        ).then((response) => {
          mixpanel.track('Module Detach', { source: 'Environment detail' });
          dispatch(hideDialog());
          return { response, moduleId };
        })
      )
    );
  } catch (exception) {
    dispatch(showNotification(TOAST_TYPES.error, 'Error', getErrorMessage(exception)));
    log.error('Get module uses', exception);
  }
};

/** save build settings for environment */
export const handleSaveBuildSettings = (environmentId, payload) => async (dispatch) => {
  try {
    await dispatch(
      saveBuildSettings(
        POST(API.environments.saveBuildSettings(environmentId), payload, { Accept: 'application/json' }).then(
          (data) => {
            const settings = data?.data?.buildSettings;

            /** managed builder does not have to wait for validation */
            if (settings?.lastStatus === 'success') {
              dispatch(showNotification(TOAST_TYPES.success, 'Success', 'Build settings has been saved successfully.'));
            }
            if (settings?.lastStatus === 'error') {
              dispatch(showNotification(TOAST_TYPES.error, 'Error', settings?.lastError));
            }
            dispatch(handleHasUnSavedChanges(false))
            return data;
          }
        )
      )
    );
  } catch (exception) {
    dispatch(showNotification(TOAST_TYPES.error, 'Error', 'Could not save the build settings.'));
    log.error(`Error.`, exception);
  }
};
export const handleGetSecretEnvValue = (environmentId, id, isEdit) => async (dispatch, getState) => {
  let secret = null;

  const { environment } = getState().environments;
  const { hasDraft } = environment;
  const url = hasDraft
    ? `${API.environments.base}/${environmentId}/draft/environment_variables/get_item?key=${id}`
    : `${API.environments.base}/${environmentId}/environment_variables/get_item?key=${id}`
  try {
    await dispatch(
      getEnvSecretValue(
        GET(url).then(
          (response) => {
            secret = response.data;

            if (isEdit) {
              return {
                data: response.data,
                isEdit,
              };
            }

            return response.data;
          }
        )
      )
    );
  } catch (exception) {
    log.error('Error.', exception);
  }

  return secret;
};

export const handleHideEnvVarSecret = (id) => async (dispatch) => {
  dispatch(hideEnvSecretValue(id));
};

export const handleGetSecretCompValue = (environmentId, id, componentName, isEdit) => async (dispatch, getState) => {
  let secret = null;

  const { environment } = getState().environments;
  const { hasDraft } = environment;
  const url = hasDraft
    ? `${API.environments.base}/${environmentId}/draft/components/application_variables/get_item?key=${id}&component=${componentName}`
    : `${API.environments.base}/${environmentId}/components/application_variables/get_item?key=${id}&component=${componentName}`
  try {
    await dispatch(
      getComponentSecretValue(
        GET(url).then((response) => {
          secret = response.data;

          if (isEdit) {
            return {
              data: response.data,
              isEdit,
            };
          }
          return response.data;
        })
      )
    );
  } catch (exception) {
    log.error('Error.', exception);
  }

  return secret;
};

export const handleHideCompVarSecret = (id) => async (dispatch) => {
  dispatch(hideComponentSecretValue(id));
};

export const handleClusterCapability =
  (environmentId, envType = 'primary') =>
  async (dispatch) => {
    try {
      await dispatch(
        checkClusterCapability(
          POST(`${API.environments.base}/${environmentId}/check-capability?type=${envType}`,  { Accept: 'application/json' }).then((data) => {
            return data;
          })
        )
      );
    } catch (exception) {
      log.error('Check cluster capability', exception);
      dispatch(showNotification(TOAST_TYPES.error, 'Error', 'Could not check cluster capability'));
    }
  };

export const handleGetKubernetesResources = ({environmentId, componentName, source}) => async (dispatch) => {
  const query = `?componentName=${componentName}`
  try {
    if (source === 'outputValues') {
      await dispatch(
        getModuleOutputValues(
          GET(`${API.environments.base}/${environmentId}/components/resources${query}`).then(({data}) => {
            return data;
          })
        )
      );
    } else {
      await dispatch(
        getKubernetesResource(
          GET(`${API.environments.base}/${environmentId}/components/resources${query}`).then(({data}) => {
            return data;
          })
        )
      );
    }

  } catch (exception) {
    log.error('Get resources', exception);

  }
}

export const handleGetCompPipelineData = ({envId, compType, compId}) => async (dispatch) => {
  try {
    await dispatch(
      getCompPipelineData(
        GET(`${API.environments.compPipeline(envId)}?${compType}=${compId}&withWorkflowSteps=false`).then(({ data }) => {
          return {...data, compName: compId};
        })
      )
    );
  } catch (exception) {
    log.error('Get component pipeline data', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Error', `Could not get pipeline events for "${compId}" component`));
  }
};

export const handleGetComponentYamlDefinition = ({envId, compName, hasDraft}) => async (dispatch) => {
  const url = hasDraft
  ? API.environments.draftComponentDefinition(envId, compName)
  : API.environments.componentDefinition(envId, compName);
  try {
    await dispatch(
      getComponentDefinition(
        GET(url, {Accept: 'text/yaml'}).then(({ data }) => {
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Get component component data', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Error', 'Could not get component yaml'));
  }
};
export const handleGetK8sResYamlDefinition = ({envId, resKind, resName, resNameSpace}) => async (dispatch) => {
  try {
    await dispatch(
      getK8sResDefinition(
        GET(`${API.environments.k8sResDefinition(envId)}?resourceKind=${resKind}&resourceName=${resName}&resourceNamespace=${resNameSpace}`, 
        {Accept: 'text/yaml'}).then(({ data }) => {
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Could not get k8s resource yaml', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Error', 'Could not get k8s resource yaml'));
  }
};

export const handleGetStreamId = (endpoint) => async (dispatch) => {
  let response = {};

  try {
    await dispatch(
      getStreamId(
        POST(`${API.environments.nakedBase}/streamer${endpoint}`).then(({ data }) => {
          response = data;
          return data;
        })
      )
    );
  } catch (exception) {
    response = {status: 'failed'};
    log.error('Could not get k8s resource stream id', exception);
  }

  return response;
};
export const handleSaveUrlHandle = (environmentId, payload) => async (dispatch) => {
  let returnedData = {};
  try {
    await dispatch(
      saveUrlHandle(
        PUT(API.environments.draftComponents(environmentId), payload).then((data) => {
          dispatch(
            showNotification(
              TOAST_TYPES.success,
              'Changes saved', 'Your changes to the environment handle have been saved.'
            )
          );
          returnedData = {...data, success: true};
          return returnedData;
        })
      )
    );
  } catch (exception) {
    log.error('Could not save environment handle', exception);
    returnedData = {...exception, success: false};
  }

  return returnedData;
};

export const handleGetEnvironmentsMtd = (organizationId, ids) => async (dispatch) => {
  const queryParam = !isBlank(ids) ? `?filters[environmentId]=${ids}` : '';
  try {
    await dispatch(
      getEnvironmentsMtd(
        GET(`${API.organizations.environmentsCosts(organizationId)}${queryParam}`).then(({data}) => {

          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Could not save environment handle', exception);
  }
};

export const handleApplyClusterConfiguration = (id) => async (dispatch) => {
  try {
    await dispatch(
      applyClusterConfiguration(
        POST(API.clusters.applyConfiguration(id)).then(({data}) => {
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Could not save environment handle', exception);
  }
};

export const handleTerminateWorkflow = (id) => async () => {
  try {
    await POST(API.environments.terminateWorkflow(id)).then(({ data }) => {
      return data;
    });
  } catch (exception) {
    log.error('Could not terminate workflow', exception);
  }
};

export const handleUpdateCheckedComponentsList = (value) => async (dispatch) => {
  dispatch(updateCheckedComponentsList(value));
};