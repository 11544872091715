import type { InferEndpointResult } from 'app/services/types';

import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { defaultBaseQuery } from 'app/services/api';
import { DraftableRoute } from 'types';

type ID = string|number;

export interface GroupItem {
    environment: number;
    name: string;
}

export interface ListParams extends DraftableRoute {
    environment: ID;
}

export interface ListItem {
    groups: GroupItem[]
};


export const TAG = 'environGroups';

export const environGroupsApi = createApi({
    reducerPath: `api:neo:${TAG}`,

    baseQuery: defaultBaseQuery,

    tagTypes: [TAG],

    endpoints: (build) => ({
        list: build.query<ListItem, ListParams>({
            query: (params) => {
                const {environment, draft, ...queryParams} = params;
                return {
                    url: draft
                        ? `/environments/${environment}/draft/environment_variables/groups`
                        : `/environments/${environment}/environment_variables/groups`,
                    queryParams,
                };
            },
            providesTags: (result) => {
                if (!result) {
                    return [];
                }

                return result.groups.map(item => ({
                    type: TAG,
                    id: item.name,
                }));
            },
        }),
    }),
});

export type AsyncListResult = InferEndpointResult<typeof environGroupsApi.endpoints.list>;
