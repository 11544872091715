const actionTypes = {
  GET_TEMPLATES_REPOSITORIES: 'GET_TEMPLATES_REPOSITORIES',
  GET_PUBLIC_TEMPLATES: 'GET_PUBLIC_TEMPLATES',
  GET_CUSTOM_TEMPLATES: 'GET_CUSTOM_TEMPLATES',
  ADD_TEMPLATES_REPOSITORY: 'ADD_TEMPLATES_REPOSITORY',
  SYNC_TEMPLATE_REPOSITORY: 'SYNC_TEMPLATE_REPOSITORY',
  DELETE_TEMPLATE_REPOSITORY: 'DELETE_TEMPLATE_REPOSITORY',
  GET_TEMPLATES_FOR_REPOSITORY: 'GET_TEMPLATES_FOR_REPOSITORY',
  GET_TEMPLATE_DETAILS: 'GET_TEMPLATE_DETAILS',
  GET_TEMPLATE_DEFINITION: 'GET_TEMPLATE_DEFINITION',
  RESET_VIOLATIONS: 'RESET_VIOLATIONS',
  RESET_UPDATED_RESOURCE: 'RESET_UPDATED_RESOURCE',
  RESET_TEMPLATES_STATE: 'RESET_TEMPLATES_STATE',
  UPDATE_CUSTOM_REPO_NUMBER: 'UPDATE_CUSTOM_REPO_NUMBER',
};

export default actionTypes;
