import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const Loader = styled.div`
  .ant-spin-spinning {
    line-height: 1;
  }
  .ant-spin-dot-item {
    background-color: #fff;
  }

  &.gray {
    .ant-spin-dot-item {
      background-color: #b3b3b3;
    }
  }
`;

const AclLoader = ({
  className,
  size,
}: {
  className?: string;
  size?: 'small' | 'default' | 'large';
}) => {
  return (
    <Loader className={`aclLoader preventHidden ${className}`} style={{
      minWidth: size === 'small' ? '20px' : '24px',
      minHeight: size === 'small' ? '20px' : '24px',
    }}>
      <Spin size={size} />
    </Loader>
  );
};

AclLoader.defaultProps = {
  size: 'small',
  className: '',
};

export default AclLoader;
