import React from 'react';
import * as R from 'ramda';
import { LogoutButton, ReloadButton } from 'components';

export const genericError = {
  title: 'Error',
  description: 'Something went wrong',
  children: (
    <>
      <ReloadButton />
      <LogoutButton />
    </>
  ),
};

export const unauthorizedError = {
  title: '401 - Unauthorized',
  description: 'Your authorization failed. Please fill in the correct login details.',
  children: <LogoutButton />,
};

export const notFoundError = {
  title: '404 - Not Found',
  description: "The resource you are looking for doesn't exist or you don't have access to it.",
  children: <ReloadButton />,
};

const ERRORS = {
  generic: genericError,
  401: unauthorizedError,
  404: notFoundError,
};

const getError = (match) => R.prop(R.path(['params', 'code'], match), ERRORS) || ERRORS.generic;

export default getError;
