/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Route, Redirect, useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import userManager from 'modules/userManager';
import UserManager from 'modules/userManager/UserManager';
import ErrorPage from 'components/errorPage';
import { logOut } from 'modules/userManager/actions';
import { connect } from 'react-redux';
import { LoaderDialog } from 'components';
import ROUTE_PATHS from './routePaths';
import { MAX_EXPIRY_INTERVAL_MS } from '../constants';

const PrivateRoute = ({ component: Component, componentProps, isGated, criticalError, ...rest }) => {
  /** critical error details */
  const { title, description, useOnlyReloadButton, showEmptyPage } = criticalError.details;

  const waitTime = UserManager.getExpireTimestamp() - new Date();
  const timeout = MAX_EXPIRY_INTERVAL_MS < waitTime ? MAX_EXPIRY_INTERVAL_MS : waitTime;
  const location = useLocation();

  useEffect(() => {
    if (!R.includes('/templates/create/', location.pathname))
      setTimeout(() => {
        userManager.logout(logOut);
        window.location.href = '/login';
      }, timeout);
  }, []);

  useEffect(() => {
    if (!(userManager.isLoggedIn() && userManager.isTokenValid())) {
      sessionStorage.setItem('redirectUrl', JSON.stringify(location));
    }
  }, []);
  return (
    <Route
      {...rest}
      render={(props) => {
        return userManager.isLoggedIn() && userManager.isTokenValid() ? (
          <>
            <Helmet>
              {/* eslint-disable-next-line react/prop-types */}
              <title>{rest.title}</title>
              <meta name="description" content={rest.title} />
            </Helmet>
            {criticalError.isActive ? (
              <ErrorPage
                title={title}
                description={description}
                useOnlyReloadButton={useOnlyReloadButton}
                showEmptyPage={showEmptyPage}
              />
            ) : (
              <>
                {isGated ? (
                  <LoaderDialog
                    loader={false}
                    className="gated-iframe"
                    content={
                      <iframe
                        title="meetings hubspot"
                        src="https://meetings.hubspot.com/shani-shoham/free-trial?embed=true"
                      />
                    }
                  />
                ) : (
                  <Component {...props} {...componentProps} />
                )}
              </>
            )}
          </>
        ) : (
          <>
            {R.includes('/templates/create/', location.pathname) ? (
              <Redirect to={{ pathname: ROUTE_PATHS.REGISTER }} />
            ) : (
              <Redirect to={{ pathname: ROUTE_PATHS.LOGIN, state: { from: props.location } }} />
            )}
          </>
        );
      }}
    />
  );
};

PrivateRoute.defaultProps = {
  criticalError: {
    isActive: false,
    details: {
      title: '',
      description: '',
      useOnlyReloadButton: false,
    },
  },
  location: {},
  isGated: false,
  componentProps: {},
};

PrivateRoute.propTypes = {
  isGated: PropTypes.bool,
  criticalError: PropTypes.shape({
    isActive: PropTypes.bool,
    details: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      useOnlyReloadButton: PropTypes.bool,
      showEmptyPage: PropTypes.bool,
    }),
  }),
  location: PropTypes.shape({}),
  componentProps: PropTypes.shape({}),
  component: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired,
};

const mapStateToProps = (state) => ({
  criticalError: state.global.criticalError,
  isGated: state.organization.isGated,
});

export default connect(mapStateToProps, null)(PrivateRoute);
