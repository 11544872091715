import * as R from 'ramda';

function isFunctionalComponent(Component) {
  return (
    typeof Component === 'function' && // can be various things
    !(Component.prototype && Component.prototype.isReactComponent) // native arrows don't have prototypes // special property
  );
}

function isClassComponent(Component) {
  return typeof Component === 'function' && Component.prototype && Component.prototype.isReactComponent;
}

const compose =
  (...functions) =>
  (WrappedComponent) => {
    if (!isFunctionalComponent(WrappedComponent) && !isClassComponent(WrappedComponent)) {
      throw new Error('[compose]: The passed component should be a valid React Component.');
    }
    functions.forEach((func) => {
      if (typeof func !== 'function') {
        throw new Error('[compose]: One of the passed arguments is not a function.');
      }
    });
    return R.compose(...functions)(WrappedComponent);
  };

export default compose;
