import React from "react";
import { NEVER_LOADED_ID } from "modules/settings/reducer/initialState";
import { useBillingInfo } from "components/header/components/billing/utils";
import { StyledFreemiumPage } from "components/gate/FreemiumPage";
import Loader from "components/loader";

import type { BillingInfo } from "components/header/components/billing/utils";

export interface IProps {
    children: React.ReactElement;
    loader: React.ReactElement;
}

function shouldGate(info: BillingInfo): null|boolean {
    if (info.id === NEVER_LOADED_ID) {
        return null;
    }

    return info.billingPlanType === 'free';
}

export function FreemiumGate(props: IProps): React.ReactElement {
    const billingInfo = useBillingInfo();
    const gated = shouldGate(billingInfo);

    if (gated === null) {
        return props.loader ?? <Loader />;
    }

    return gated ? <StyledFreemiumPage organizationId={billingInfo.id} /> : props.children;
}
