import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import {
  getOrganizations,
  getUpdatedOrganizations,
  getOrganization,
  getOrganizationDetails,
  getOrganizationUsers,
  addOrganization,
  addOrganizationMember,
  editOrganization,
  deleteOrganization,
  deactivateOrganizationMember,
  getTimezones,
  getUsers,
  getUpdatedUsers,
  addUser,
  editUser,
  deleteUser,
} from '../actions';
import initialState from './initialState';

const getOrganizationsHandler = [
  getOrganizations,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      organizations: payload,
    };
  },
];
const getUpdatedOrganizationsHandler = [
  getUpdatedOrganizations,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      organizations: R.concat(state.organizations, payload),
    };
  },
];
const getOrganizationDetailsHandler = [
  getOrganizationDetails,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      organization: payload,
    };
  },
];
const getOrganizationHandler = [
  getOrganization,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      organization: {
        ...payload.organization,
        users: payload.organizationUsers,
      },
      users: payload.users,
    };
  },
];

const getOrganizationUsersHandler = [
  getOrganizationUsers,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      organization: {
        ...state.organization,
        users: payload,
      },
    };
  },
];

const addOrganizationHandler = [
  addOrganization,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      organizations: R.append(payload, state.organizations),
    };
  },
];

const addOrganizationMemberHandler = [
  addOrganizationMember,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
    };
  },
];

const deactivateOrganizationMemberHandler = [
  deactivateOrganizationMember,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    const index = R.findIndex(R.propEq('email', payload))(state.organization.users);

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      organization: {
        ...state.organization,
        users: R.remove(index, 1, state.organization.users),
      },
    };
  },
];

const editOrganizationHandler = [
  editOrganization,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    const index = R.findIndex(R.propEq('id', payload.id))(state.organizations);

    const newState = {
      ...state,
      isLoading: false,
      errorMessage: '',
      organization: payload,
    };

    /** if from the admin list */
    if (index > -1) {
      const updateName = R.map(R.ifElse(R.propEq('id', state.organizations[index]?.id), R.assoc('name', payload.name), (item) => item))
      const updateIsGated = R.map(R.ifElse(R.propEq('id', state.organizations[index]?.id), R.assoc('isGated', payload.isGated), (item) => item))
      const updatedList = updateIsGated(updateName(state.organizations))
    
      newState.organizations = updatedList;
    }

    return newState;
  },
];

const deleteOrganizationHandler = [
  deleteOrganization,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    const index = R.findIndex(R.propEq('id', payload))(state.organizations);

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      organizations: R.remove(index, 1, state.organizations),
    };
  },
];
const getTimezonesHandler = [
  getTimezones,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: error,
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      timezones: payload,
    };
  },
];
const getUsersHandler = [
  getUsers,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      users: payload,
    };
  },
];
const getUpdatedUsersHandler = [
  getUpdatedUsers,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      users: R.concat(state.users, payload),
    };
  },
];
const addUserHandler = [
  addUser,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      users: R.append(payload, state.users),
    };
  },
];

const editUserHandler = [
  editUser,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    const index = R.findIndex(R.propEq('id', payload.id))(state.users);
    const users = R.adjust(
      index,
      (user) => ({
        ...user,
        ...payload,
      }),
      state.users
    );

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      users,
    };
  },
];

const deleteUserHandler = [
  deleteUser,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    const index = R.findIndex(R.propEq('id', payload))(state.users);

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      users: R.remove(index, 1, state.users),
    };
  },
];

const reducer = handleActions(
  new Map([
    getOrganizationsHandler,
    getUpdatedOrganizationsHandler,
    getOrganizationHandler,
    getOrganizationDetailsHandler,
    getOrganizationUsersHandler,
    addOrganizationHandler,
    addOrganizationMemberHandler,
    editOrganizationHandler,
    deleteOrganizationHandler,
    deactivateOrganizationMemberHandler,
    getTimezonesHandler,
    getUsersHandler,
    getUpdatedUsersHandler,
    addUserHandler,
    editUserHandler,
    deleteUserHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
