import styled, { css } from 'styled-components';
import STATUS_TYPES from './constants';

const StyledStatusBullet = styled.div`
  ${({ theme, status }) => {
    const { palette } = theme;

    const STATUS_COLORS_MAP = {
      [STATUS_TYPES.pending]: palette.color[4],
      [STATUS_TYPES.success]: palette.success[0],
      [STATUS_TYPES.warning]: palette.color[4],
      [STATUS_TYPES.aborted]: palette.warning[0],
      [STATUS_TYPES.error]: palette.error[0],
      [STATUS_TYPES.detached]: palette.color[4],
      [STATUS_TYPES.draft]: palette.color[4],
      [STATUS_TYPES.disconnected]: palette.error[0],
      [STATUS_TYPES.locked]: palette.color[4],
      [STATUS_TYPES.invalid]: palette.error[0],
    };

    return css`
      font-size: ${palette.fontSize[1]};
      line-height: 18px;
      display: inline-flex;
      align-items: center;
      position: relative;
      border-radius: 4px;
      padding: 0;
      background-color: transparent;
      color: ${palette.grays[4]};
      #dark & {
        color: ${palette.grays[7]}
      }
      &.pending{
        padding-left: 15px;
      }
      .loader{
        height: 16px;
        width: 16px;
        text-align: center;
        padding: 0;
        margin: 0 6px 0 0;
        display: inline-block;
        vertical-align: top;
        position: absolute;
        left: -7px;
        svg{
          width: 16px;
          height: 16px;
        }
      }
      
      &:before {
        content: '';
        display: ${() => status === 'pending' ? 'none' : 'inline-block'};
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: ${STATUS_COLORS_MAP[status]};
        margin-right: 6px;
      }
      // &.pending{
      //   &:before {
      //     border-radius: 2px;
      //     transition: all 0.5s;
      //     animation-iteration-count: infinite;
      //     animation-name: rotate;
      //     animation-duration: 0.3s;
      //   }
      // }
      span{
        display: inline-block;
        animation-iteration-count: infinite;
        animation-direction: normal;
        &:nth-child(2){
          animation-name: blink;
            animation-duration: 2.5s;
        }
        &:nth-child(3){
            animation-name: blink2;
            animation-duration: 2.5s;
        }
        &:nth-child(4){
            animation-name: blink3;
            animation-duration: 2.5s;
        }
      }
      @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        25% {
          transform: rotate(72deg);
        }
        50% {
            transform: rotate(144deg);
        }
        75% {
            transform: rotate(216deg);
        }
        100% {
            transform: rotate(288deg);;
        }
      }
      @keyframes blink {
        0% {
            opacity: 0;
        }
        24% {
          opacity: 0;
        }
        25% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
      }
      @keyframes blink2 {
        0% {
            opacity: 0;
        }
        49% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
      }
      @keyframes blink3 {
        0% {
            opacity: 0;
        }
        74% {
          opacity: 0;
        }
        75% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
      }
    `;
  }}
`;

StyledStatusBullet.displayName = 'StyledStatusBullet';
export default StyledStatusBullet;
