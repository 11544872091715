import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import StyledLoader from "./Loader.style"
import { LoaderIcon } from './LoaderIcon';

const Loader = ({bgColor, isFixed, mode}) => {
  return (
    <StyledLoader bgColor={bgColor} isFixed={isFixed}>
      <LoaderIcon mode={mode} />
    </StyledLoader>
  );
};
Loader.defaultProps = {
  bgColor: null,
  isFixed: false,
  mode: 'system',
};

Loader.propTypes = {
  mode: PropTypes.string,
  bgColor: PropTypes.string,
  isFixed: PropTypes.bool,

};
const mapStateToProps = ({ global }) => {
  const { mode } = global;

  return {
    mode
  };
};

export const PageLoader = connect(mapStateToProps, null)(Loader);

export function SideContentLoader() {
  return <div style={{
    position: 'relative',
    height: 'calc(100vh - 132px)',
    padding: '0px'
  }}><PageLoader /></div>
}

export default PageLoader;
