import type { EnvironmentPermission, OrganizationPermission, Permission, PermissionMap, ProjectPermission } from 'rbac/types';

export function hasPermission(permission: Permission, permissionMap: PermissionMap): boolean {
    return false
        || permissionMap.organization.includes(permission as OrganizationPermission)
        || permissionMap.project.includes(permission as ProjectPermission)
        || permissionMap.environment.includes(permission as EnvironmentPermission)
    ;
}

export function hasPermissions(permissions: Permission[], permissionMap: PermissionMap): boolean {
    return permissions.every(permission => hasPermission(permission, permissionMap));
}
