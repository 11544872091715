/* eslint-disable max-lines-per-function */
import styled from 'styled-components';

const StyledCreateOrganizationForm = styled.div`
  .loading {
    .button-loader {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 50%;
      transform: translateY(-50%);
    }
    span {
      visibility: hidden;
    }
  }

  .ant-select-selection-placeholder {
    color: #737376;
  }
`;

StyledCreateOrganizationForm.displayName = 'StyledCreateOrganizationForm';
export default StyledCreateOrganizationForm;
