
export const getLastUsedOrganization = (lastUsedOrganization) => {
  return {
    id:  parseInt(lastUsedOrganization.split('/').filter((x) => x)[2], 10),
    iri: lastUsedOrganization,
  }
};
export const getLastUsedProject = (lastUsedProject) => ({
  id: parseInt(lastUsedProject.split('/').filter((x) => x)[2], 10),
  iri: lastUsedProject,
});
export const getFormattedPrice = (price, envNo) => (
  (envNo*parseFloat(price/100)).toFixed(2)
);
export const getCurrency = () => ("$");
export const getTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}
export default {
  getLastUsedOrganization,
  getLastUsedProject,
  getFormattedPrice,
  getCurrency
};
