import {includes, toLower} from "utils";

export const filterTable = (searchTerm) => (project) => {
  const { name } = project;
  if(searchTerm.length < 3) return project;
  if (searchTerm === '') return project;
  if (includes(toLower(name), toLower(searchTerm))) return project;

  return null;
};
export default filterTable;