import React from 'react';
import PropTypes from "prop-types";
import { SVG } from 'components';

const SelectArrow = ({designType, className}) => {
  return (
    <div className={className}>
      {
        designType === 'new'
          ? <SVG name="bicon-chevron-down" width={20} height={20} />
          : (
            <svg className="icon rotate180 f10">
              <use xlinkHref="#icon-arrowup" />
            </svg>
          )
      }
    </div>
  )
}
SelectArrow.defaultProps = {
  designType: null,
  className: null,
};

SelectArrow.propTypes = {
  designType: PropTypes.string,
  className: PropTypes.string,
}
export default SelectArrow;
