/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useReducer, useRef } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

import styled from 'styled-components';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const Wrapper = styled.div`
  color: #808080;
  #dark & {
    color: #ccc;
  }
`;

interface Props {
  createdAt: number;
}

export default function TimeTracker({ createdAt }: Props) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const intervalRef = useRef<NodeJS.Timeout>();

  // refresh value of `createdAt` every ~ 1 minute
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      forceUpdate();
    }, 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <Wrapper className="mt-1">
      <span>{dayjs(createdAt).fromNow()}</span>
    </Wrapper>
  );
}
