const actionTypes = {
  GET_BILLING_PROFILE: 'GET_BILLING_PROFILE',
  GET_BILLING_DATA: 'GET_BILLING_DATA',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
  ACTIVATE_SUBSCRIPTION: 'ACTIVATE_SUBSCRIPTION',
  CHANGE_SUBSCRIPTION: 'CHANGE_SUBSCRIPTION',
  ADD_CARD: 'ADD_CARD',
  EDIT_CARD: 'EDIT_CARD',
  REMOVE_CARD: 'REMOVE_CARD',
  MAKE_CARD_PRIMARY: 'MAKE_CARD_PRIMARY',
  EDIT_BILLING_ADDRESS: 'EDIT_BILLING_ADDRESS',
  PAY_INVOICE: 'PAY_INVOICE'
};

export default actionTypes;
