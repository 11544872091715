import React from 'react';
import * as R from 'ramda';
import { isBlank } from 'utils';
import { EVENTSOURCE_READY_STATES, MESSAGE_TYPES } from './constants';

export const EventSourceContext = React.createContext(null);

export const handleMessage = (callback, eventType) => (message) => {
  const parsedMessageData = JSON.parse(message.data);
  const { notification } = parsedMessageData;
  const messageType = R.prop('notificationType', notification);

  if (R.prop(messageType, MESSAGE_TYPES) === eventType) {
    callback(notification, message?.lastEventId || '');
  }
};

export const handleSignalUpdate = (callback) => (message) => {
  const parsedMessageData = JSON.parse(message.data);
  const messageType = R.prop('notificationType', parsedMessageData);

  /* set deploy message texts */
  if (messageType === 'SIGNAL') {
    callback({
      notificationLevel: 'info',
      title: 'Bunnyshell application update available',
      description: 'A new version of the Bunnyshell application is now available. Reload the page to use it.',
    }, message?.lastEventId || '');
  }
};

export const handleResourceUpdate = (callback) => (message) => {
  const parsedMessageData = JSON.parse(message.data);
  const { resourceUpdate} = parsedMessageData;
  if(!isBlank(resourceUpdate)) {
    callback(parsedMessageData)
  }
};

export const getResourceUpdate = (resource) => {
  return resource.split('/');
};

export const isESConnectionOpen = (eventSource) =>
  !isBlank(eventSource) && R.prop('readyState', eventSource) === EVENTSOURCE_READY_STATES.OPEN;

/* subscribe to mercure topics */
export const getTopics = ({
  /* [!!] don't change the order; keep it as in EventSourceProvider.js */
  componentTopics,
  environmentId,
  projectId,
  pipeTopic,
  pipeCompTopic,
  pipeJobTopic,
  organizationId,
  clusterTopics,
  userId,
  pipelineCompTopics,
  templateRepositoryTopic,
}) => {
  /* signals = subscribe to deploy messages */
  const topics = ['/signals'];

  if (userId) topics.push(`/api/users/${userId}`);
  if (organizationId) topics.push(`/api/organizations/${organizationId}`);
  if (projectId) topics.push(`/api/projects/${projectId}`);
  if (environmentId) topics.push(`/api/environments/${environmentId}`);

  /** listen for each environment component topic */
  if (componentTopics) componentTopics.forEach((topic)=>{ topics.push(topic); });
  /** listen for pipeline updates */
  if (pipeTopic) topics.push(pipeTopic);
  /** listen for pipeline updates form environment details */
  if (pipeCompTopic) topics.push(pipeCompTopic);
  /** listen for pipeline job updates */
  if (pipeJobTopic) topics.push(pipeJobTopic);
  /** env details pipeline */
  if (pipelineCompTopics) pipelineCompTopics.forEach((topic)=>{ topics.push(topic); });
  /** templates repositories topic */
  if (templateRepositoryTopic) templateRepositoryTopic.forEach((topic)=>{ topics.push(topic); });
  /** cluster topics */
  if (clusterTopics) clusterTopics.forEach((topic)=>{ topics.push(topic); });
  
  return topics;
};
