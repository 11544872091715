import userManager from '../UserManager';

const initialState = {
  isLoading: false,
  emptyOrganizationsList: null,
  isWaitingForAutoLogin: false,
  isLoginLoading: false,
  isUpdatingPreferences: false,
  hasAccessToken: userManager.hasAccessToken(),
  isLoggedIn: userManager.isLoggedIn(),
  isAdmin: false,
  isSSOLoading: false,
  userId: null,
  firstName: null,
  lastName: null,
  email: '',
  organizations: [],
  projects: [],
  organizationRoles: [],
  organizationUserIds: {},
  lastUsedOrganization: null,
  lastUsedProject: null,
  errorMessage: '',
  currentOrganization: null,
  currentProject: null,
  billingPlans: [],
  initialBillingPlan: '',
  hasAccount: false,
  setupIntent: {},
  intercomExternalId: null,
  intercomUserHash: null,
  recoverPasswordSteps: 0,
  isCheckingRecoverToken: false,
  isCreatingOrganization: false,
  emailErrorData: {violations: []},
  invalidPasswordError: false,
  gitErrorMessage: '',
  ssoErrorCode: null,
  ssoViolations: [],
  environmentListingColumns: null,
};

export default initialState;
