const theme = {};

theme.palette = {
  primary: [
    '#1F2ECF', // 0: Default
    '#001CBF', // 1: Hover
    '#CEC4F4', // 2: Disabled
    '#F4F1FD', // 3: Light background
    '#ECE7FB', // 4: Status label background
    '#8A75E9', // 5: Fade 20%
    '#4C8AFF', // 6: Lighten 3%
    'rgba(68, 130, 255, 0.75)', // 7: Fade 75%
    '#6AA8FF', // 8: Lighten 15%
    '#63A1FF', // 9: Lighten 12%
    '#3F7DFA', // 10: Darken 2%
    '#3369e7', // 11: Algolia color
    '#5896FF', // 12: Lighten 8%
    '#2b69e6', // 13:
    '#236cfe', // 14: darken 10%
    '#4d88ff', // 16: Lighten 5%
  ],
  secondary: [
    '#ebebeb', // 0: Secondary button
    '#F4F5FD', // 1: Secondary button hover
    '#788195', // 2: LightBlue
    '#E4E6E9', // 3: LightBluish Darken 5%
    '#364d79', // 4:
    '#202739', // 5: DarkBlue Darken 5%
    '#f5f6f8', // 6: LighterBluish
    '#e9ebf1', // 7: DarkBluish
    '#F6F8FB', // 8: LighterBluish Lighten 2%
    '#E9EBEE', // 9: LighterBluish Darken 3%
    '#1a1a1a', // 10: Sidebar submenu select
    '#03B364', // 11: Trial Ribbon button
  ],
  color: [
    '#000000', // 0: Black
    '#222222', // 1: text
    '#333333', // 2: Almost black
    '#595959', // 3: gray-paragraph
    '#737376', // 4: Border color/texts
    '#CCCCCC', // 5: Borders
    '#EBEBEB', // 6: Background
    '#FFFFFF', // 7: White
    '#344e86', // 8: Facebook Darken 5%
    '#dd4b39', // 9: Google Plus
    '#d73925', // 10: Google Plus Darken 5%
    '#e14615', // 11: Auth0
    '#ca3f13', // 12: Auth0
    '#e0364c', // 13: themeColor--AlizarinCrimson
  ],
  warning: [
    '#FFB300', // 0: Warning
    '#FF6F00', // 1: Warning Dark
    '#FFFAEB', // 2: Warning Background
  ],
  success: [
    '#03B364', // 0: Success
    '#01723D', // 1: Success Dark
    '#E5F6EC', // 2: Success Background
  ],
  error: [
    '#E32525', // 0: Error
    '#B60009', // 1: Darken 4%
    '#FFE9E9', // 2: Lighten 8%
    '#ffcace', // 3: Error color text dark mode
  ],
  grays: [
    '#222222', // 0: Gray900 - Text
    '#333333', // 1: Gray800 - Label status text
    '#4C4C4C', // 2: Gray700
    '#666666', // 3: Gray600
    '#808080', // 4: Gray500 - Bullet
    '#999999', // 5: Gray400
    '#B3B3B3', // 6: Gray300
    '#CCCCCC', // 7: Gray200 - Border
    '#E5E5E5', // 8: Gray100
    '#F2F2F2', // 9: Gray50
    '#F9F9F9', // 10: Gray25
    '#FFFFFF', // 11 White
  ],
  grayscale: [
    '#737376', // 0: GreyShade
    '#c1c1c1', // 1: GreyDark
    '#D8D8D8', // 2: Grey
    '#f1f1f1', // 3: GreyAlt
    '#F3F3F3', // 4: GreyLight
    '#fafafa', // 5: DarkWhite
    '#F9F9F9', // 6: DarkerWhite
    '#fcfcfc', // 7: #fff Darken 1%
    '#eeeeee', // 8:
    '#fbfbfb', // 9:
    '#f5f5f5', // 10:
    '#f7f8f9', // 11: today-highlight-bg
  ],
  text: [
    '#333333', // 0: Heading
    '#808080', // 1: HeadingLight
    '#595959', // 2: Text
    '#FFFFFF', // 3: TextDark
    '#CCCCCC', // 4: Disabled
  ],
  background: [
    '#222222', // 0: Main background Dark Mode
    '#333333', // 1: Main background Dark Mode hover
    '#FFFFFF', // 2: Main background light mode
    '#F2F2F2', // 3: Sidebar background
    '#4C4C4C', // 4: Tab filters background
  ],
  border: [
    '#CCCCCC', // 0: Border
    '#737376', // 1: Secondary button
    '#e5e5e5', // 2: BorderLight
    '#000000', // 3: Border focus
    '#666666', // 4: Border Dark mode
    '#1F2ECF', // 5: Border Primary
    'rgba(228, 228, 228, 0.65)', // 5:
  ],
  fonts: ['"Circular", sans-serif', '"Circular-Bold", sans-serif', '"Circular-Medium", sans-serif'],
  fontSize: [
    '16px', // 0  base
    '14px', // 1 buttons
    '12px', // 2 input fields
    '18px', // 3 h5
    '20px', // 4 h4
    '9px', // 5 form field errors
  ],
};

theme.fonts = {
  primary: '"Circular", sans-serif',
  bold: '"Circular-Bold", sans-serif',
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
};

theme.fontWeight = [
  300, // 0 thin
  400, // 1 normal
  500, // 2 thick
  600, // 3 semiBold
  700, // 4 bold
  800, // 5 xBold
  900, // 6 strong
];

export default theme;
