import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { SVG } from "components";
import { Menu } from "antd";

import type { MenuItemProps } from "modules/sidebar/items/menu";

export interface IMenuTitleProps {
    theme: string;
    icon: string;
    name: string;
}

export type IPassthroughLinkItemProps = Omit<MenuItemProps,'title'|'key'|'icon'>;

export interface ILinkItemProps extends IPassthroughLinkItemProps {
    title: string;
    icon: string|React.ReactElement;

    key: string;
    currentKey: string;

    linkTo: LinkProps['to'];
}

export function SubMenuTitle(props: IMenuTitleProps): React.ReactElement {
    return <div className="isoMenuHolder d-flex">
        <div className="d-flex justify-content-start align-items-center flex-nowrap">
            <SVG name={props.icon} width={24} height={24} />
            <span className="nav-text org-name ellipsis mr-05">{props.name}</span>
            <span className="nav-text arrow-right">
                <svg className="icon default">
                    <use xlinkHref="#icon-arrowup" />
                </svg>
                <svg className="icon active">
                    <use xlinkHref={`#${props.theme === 'light' ? 'icon-arrowup-active' : 'icon-arrowup-active-dark'}`} />
                </svg>
            </span>
        </div>
    </div>;
}

export function LinkItem({
    title,
    icon,
    key,
    currentKey,
    linkTo,
    ...props
}: ILinkItemProps): React.ReactElement {
    // AntDesign does not have expandable menus on hover
    // when a menu is collapsed it automatically shows the content for the menu item in a tooltip
    // setting title to "" will force the tooltip to not appear

    if (key === "admin") {
        return <Menu.Item
            {...props}

            key={key}
            title=""
        >
            <Link
                to={linkTo}
                className={key === currentKey ? 'active admin-item' : 'admin-item'}
            >
                <div className="isoMenuHolder">
                    {typeof icon === 'string'
                        ? <SVG name={icon} width={24} height={24} className="admin-icon" />
                        : icon
                    }
                    <span className="nav-text admin">{title}</span>
                </div>
            </Link>
        </Menu.Item>
    }

    return <Menu.Item
        {...props}

        key={key}
        title=""
    >
        <Link
            to={linkTo}
            className={key === currentKey ? 'active' : ''}
        >
            <div className="isoMenuHolder">
                {typeof icon === 'string'
                    ? <SVG name={icon} width={24} height={24} />
                    : icon
                }
                <span className="nav-text">{title}</span>
            </div>
        </Link>
    </Menu.Item>
}
