import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import { rootReducers } from 'reducers';
import { promiseActionMiddleware, promisePayloadMiddleware } from 'app/middleware';
import { adminApiReducers, adminApiMiddlewares } from 'app/services/admin';
import { rbacApiReducers, rbacApiMiddlewares } from 'app/services/rbac';
import { neoApiReducers, neoApiMiddlewares } from 'app/services/neo';
import { intercomReducer } from 'modules/thirdparty/intercom/store';

import type { ConfigureStoreOptions } from '@reduxjs/toolkit';

export function createStore(
    options?: ConfigureStoreOptions['preloadedState'] | undefined,
) {
    const store = configureStore({
        reducer: {
            ...adminApiReducers,
            ...rbacApiReducers,
            ...neoApiReducers,

            intercom: intercomReducer,

            ...rootReducers,
        },

        middleware: getDefaultMiddleware => {
            const middlewares = getDefaultMiddleware({
                // @see https://redux-toolkit.js.org/api/getDefaultMiddleware for default middleware options
            }).concat(
                ...adminApiMiddlewares,
                ...rbacApiMiddlewares,
                ...neoApiMiddlewares,
            );

            // set promise before serializer middleware
            middlewares.unshift(promiseActionMiddleware, promisePayloadMiddleware);

            return middlewares;
        },

        ...options,
    });

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        // @review why isn't this hot reloading for any change?
        module.hot.accept('../reducers', () => {
            // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
            const nextRootReducer = require('../reducers/index').default();

            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}

export const store = createStore();

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
