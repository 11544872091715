import React from 'react';
import Menu from 'theme/uielements/menu';
import { Link, useLocation } from 'react-router-dom';
import { useOrganizationACL } from 'rbac/hooks/useOrganizationACL';
import { SVG } from 'components';

const SecurityMenuItem = ({ organizationId }: { organizationId: number }) => {
  const location = useLocation();

  // [i] USE title="" on Menu.Item to prevent tooltip in collapsed mode
  const { pathname } = location;

  // RBAC
  const fetchingOption = { allowFetching: true };
  const aclContext = {
    organization: { id: organizationId },
  };
  const aclOrgSecurityData = useOrganizationACL('organization:security', aclContext, fetchingOption);
  const aclOrgSecurityDenied = aclOrgSecurityData.type === 'denied';
  // End of RBAC

  return (
    aclOrgSecurityDenied ? <></> :
    <Menu.Item title="" disabled={aclOrgSecurityDenied}>
      <Link
        to={{ pathname: `/${organizationId}/security` }}
        className={pathname.includes(`/${organizationId}/security`) ? 'active' : ''}
      >
        <div className="isoMenuHolder">
          <SVG name="bicon-security" width={24} height={24} />
          <span className="nav-text">Security</span>
        </div>
      </Link>
    </Menu.Item>
  );
};

export default SecurityMenuItem;
