/* eslint-disable max-lines-per-function */
import styled from 'styled-components';
import { palette } from 'styled-theme';
import { transition } from '../../helpers/style_utils';

const Buttons = (ComponentName) => styled(ComponentName)`
  &.cliButton.ant-btn-icon-only {
    vertical-align: 0;
    width: inherit;
  }

  &.ant-btn {
    display: inline-flex;
    margin-bottom: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    line-height: 20px; 
    padding-top: 7px !important;
    padding: 7px 15px;
    font-size: ${palette('fontSize', 1)};
    border-radius: 4px;
    height: 36px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    font-family: ${palette('fonts', 2)};
    ${transition()};
    &:hover {
      border-color: ${palette('primary', 0)};
      color: ${palette('primary', 0)};
    }
    > span {
      font-size: ${palette('fontSize', 1)};
      line-height: 20px;
      font-family: ${palette('fonts', 2)} !important;
      flex-wrap: nowrap;
    }
    > span > span {
        font-size: ${palette('fontSize', 1)};
        line-height: 20px;
        font-family: ${palette('fonts', 2)} !important;
        flex-wrap: nowrap;
    }

    > .anticon + span,
    > span + .anticon {
      margin: ${(props) => (props['data-rtl'] === 'rtl' ? '0 0.5em 0 0' : '0 0 0 0.5em')};
    }

    .anticon-right {
      transform: ${(props) => (props['data-rtl'] === 'rtl' ? 'rotate(180deg)' : 'rotate(0)')};
    }

    .anticon-left {
      transform: ${(props) => (props['data-rtl'] === 'rtl' ? 'rotate(180deg)' : 'rotate(0)')};
    }

    &.ant-btn-primary {
      background-color: ${palette('primary', 0)};
      border-color: ${palette('primary', 0)};
      box-shadow: 0 0 0;
      #dark & {
        border-color: ${palette('border', 4)}; 
      }
      &:hover {
        background-color: ${palette('primary', 1)};
        border-color: ${palette('primary', 1)};
        color: #fff;
      }
    }
    &.ant-btn-primary[disabled] {
      background-color: ${palette('primary', 2)} !important;
      color: #fff !important;
      border-color: ${palette('primary', 2)};
      cursor: not-allowed;
      svg {
        opacity: 0.6;
      }
      &:hover{
        border-color: ${palette('primary', 2)};
      }
      #dark & {
        background-color: ${palette('primary', 0)} !important;
        color: ${palette('grays', 6)} !important;
        span{
          color: ${palette('grays', 6)} !important;
        }

        svg {
          opacity: 0.6;
        }
      }
    }
    &.ant-btn-info {
      box-shadow: 0 0 0;
      background-color: ${palette('grays', 11)};
      border-color: ${palette('border', 0)};
      color: ${palette('grays',1)};

      svg {
        use{
          stroke: ${palette('grays', 1)}
        }
      }
      &:focus{
        color: ${palette('grays',1)};
      }
      &:hover {
        background-color: ${palette('grays', 10)}; 
        border-color: ${palette('border', 0)};
        color: ${palette('grays', 1)};
        #dark & {
            background-color: ${palette('background',1)};
        }
        svg{
          use{
            stroke: ${palette('grays', 1)}
          }
        }
      }
      #dark & {
        background-color: ${palette('background', 0)};
        color: ${palette('grays', 7)};
        border-color: ${palette('border',4)}; 
        svg{
          use{
            stroke: ${palette('grays', 6)}
          }
        }
      }
    }
    &.ant-btn-info[disabled] {
      background-color: ${palette('color', 7)};
      border-color: #E4E7EC;
      color: #B3B3B3;
      svg{
        use{
          stroke: #D0D5DD;
        }
      }
      #dark & {
        color: ${palette('grays', 4)};
        svg{
          use{
            stroke: ${palette('grays', 4)};
          }
        }
      }
    }
    &.ant-btn-sm {
      padding: 5px 12px;
      height: 28px;
      font-size: 12px;
      line-height: 16px;
      span {
        font-size: 12px;
        line-height: 16px;
      }
      &.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
        padding: ${(props) => (props['data-rtl'] === 'rtl' ? '0 24px 0 15px' : '0 15px 0 24px')};
        .anticon {
          margin: ${(props) => (props['data-rtl'] === 'rtl' ? '0 -17px 0 0' : '0 0 0 -17px')};
        }
      }
    }

    &.ant-btn-lg {
      padding: 9px 20px;
      font-size: 14px;
      line-height: 20px;
      height: 40px;
    }

    &.ant-btn-primary {
      color: #ffffff;
    }

    &.ant-btn-dashed {
      border-style: dashed;
      border-color: ${palette('border', 1)};

      &:hover {
        color: ${palette('primary', 0)};
        border-color: ${palette('primary', 0)};
      }
    }

    &.ant-btn-danger {
      background-color: #fff;
      border-color: ${palette('error', 0)};
      color: ${palette('error', 0)};
      &.filled {
        background-color: ${palette('error', 0)};
        color: ${palette('color', 7)};
        &:hover:not([disabled]) {
          border-color: ${palette('error', 1)};
          background-color: ${palette('error', 1)};
        }
        &[disabled] {
          opacity: 0.5;
        }
      }
      
      &.ant-btn-background-ghost {
        color: ${palette('error', 0)};
        background-color: transparent;
        border-color: ${palette('error', 0)};

        &:hover {
          color: ${palette('error', 2)};
          border-color: ${palette('error', 2)};
        }
      }
    }

    &.ant-btn-circle,
    &.ant-btn-circle-outline {
      width: 35px;
      padding: 0;
      font-size: 14px;
      border-radius: 50%;
      height: 35px;

      &.ant-btn-sm {
        padding: 0;
        height: 28px;
        width: 28px;
        font-size: 12px;
      }

      &.ant-btn-lg {
        padding: 0;
        font-size: 14px;
        line-height: 20px;
        height: 40px;
        width: 42px;
      }
    }

    &.ant-btn.disabled,
    &.ant-btn[disabled],
    &.ant-btn.disabled:hover,
    &.ant-btn[disabled]:hover,
    &.ant-btn.disabled:focus,
    &.ant-btn[disabled]:focus,
    &.ant-btn.disabled:active,
    &.ant-btn[disabled]:active,
    &.ant-btn.disabled.active,
    &.ant-btn[disabled].active {
      cursor: not-allowed;
    }

    &.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) .anticon {
      margin: ${(props) => (props['data-rtl'] === 'rtl' ? '0 -14px 0 0' : '0 0 0 -14px')};
    }
    &.ant-btn-link {
      color: ${palette('primary', 0)};
      background-color: transparent;
      #dark & {
        color: ${palette('grays', 8)}; 
      }
      &:hover {
        color: ${palette('primary', 0)};
        border: none;
        background-color: transparent;
      }
      span{
        font-family: ${palette('fonts', 0)} !important; 
      }
    }
    &.isoButton {
      display: inline-block;
      margin-bottom: 0;
      font-weight: 500;
      text-align: center;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 0;
      white-space: nowrap;
      line-height: 1.5;
      padding: 0 25px;
      font-size: 13px;
      border-radius: 4px;
      height: 35px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      position: relative;
      color: #ffffff;
      background-color: ${palette('primary', 0)};
      ${transition()};

      &:hover {
        background-color: ${palette('primary', 3)};
      }

      &.isoBtnSm {
        padding: 0 15px;
        height: 28px;
        font-size: 12px;
      }

      &.isoBtnLg {
        padding: 0 35px;
        font-size: 14px;
        height: 42px;
      }
    }
    
    &.success {
      color: #fff;
      border: none;
      background-color: ${palette('success', 0)};
      &.ant-btn-link {
        color: ${palette('success', 0)};
        background-color: transparent;
        &:hover {
          opacity: 0.8;
          color: ${palette('success', 0)};
          border: none;
          background-color: transparent;
        }
      }

      
      &:hover {
        opacity: 0.8;
        color: #fff;
        border: none;
        background-color: ${palette('success', 0)};
      }
    }
    &.success[disabled] {
      opacity: 0.65;
      color: #fff;
      background-color: ${palette('success', 0)};
      border-color: none;
      #dark & {
        background-color: ${palette('success', 0)} !important;
      }
      &.ant-btn-primary[disabled] {
        background-color: ${palette('success', 0)} !important;
        #dark & {
          background-color: ${palette('success', 0)} !important;
          span{
            color: #FFF !important;
          }
          
        }
      }
    }
  }
  &.ant-btn-secondary {
    background-color: ${palette('primary', 3)};
    border: 1px solid ${palette('primary', 3)};
    color: ${palette('primary', 0)};
    #dark & {
      background-color: ${palette('background',0)};
      border: 1px solid ${palette('border', 4)};
      color: ${palette('grays', 7)};
    }
    &[disabled]{
      color: ${palette('primary', 2)};
      #dark & {
        background-color: ${palette('background',1)};
        border: 1px solid ${palette('border', 4)};
        color: ${palette('grays', 4)};
      }
      &:hover {
        border: 1px solid ${palette('primary', 4)};
        background-color: ${palette('primary', 4)};
        color: ${palette('primary', 0)};
      }
    }
    &:hover {
      border: 1px solid ${palette('primary', 4)};
      background-color: ${palette('primary', 4)};
      color: ${palette('primary', 0)};
      #dark & {
        background-color: ${palette('background',1)};
        border: 1px solid ${palette('border', 4)};
        color: ${palette('grays', 7)};
      }
    }
  }
  + .ant-btn-group {
    margin-left: ${(props) => (props['data-rtl'] === 'rtl' ? '0' : '-1px')} !important;
    margin-right: ${(props) => (props['data-rtl'] === 'rtl' ? '-1px' : '0')} !important;
  }
`;

const RadioButtons = (ComponentName) => styled(ComponentName)`
  .ant-radio-button-wrapper {
    height: 35px;
    line-height: 33px;
    color: ${palette('text', 1)};
    border: 1px solid ${palette('border', 0)};
    border-left: 0;
    background: #fff;
    padding: 0 20px;

    &:hover,
    &.ant-radio-button-wrapper-focused {
      color: ${palette('primary', 0)};
    }

    &.ant-radio-button-wrapper-checked {
      background: #fff;
      border-color: ${palette('primary', 0)};
      color: ${palette('primary', 0)};
      box-shadow: -1px 0 0 0 ${palette('primary', 0)};
    }
  }
`;

const ButtonsGroup = (ComponentName) => styled(ComponentName)`
  &.ant-btn-group {
    .ant-btn {
      margin: 0;
      margin-right: 0;
      display: inline-block;
      margin-bottom: 0;
      font-weight: 500;
      text-align: center;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 1px solid transparent;
      border-color: ${palette('border', 1)};
      white-space: nowrap;
      line-height: 1.5;
      padding: 0 8px;
      font-size: 14px;
      border-radius: 0;
      height: 36px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      position: relative;
      ${transition()};

      &:hover {
        border-color: ${palette('primary', 0)};
      }

      &.ant-btn-dashed {
        border-style: dashed;

        &:hover {
          border-color: ${palette('primary', 0)};
        }
      }

      &.ant-btn-primary {
        border-color: ${palette('primary', 0)};

        &:hover {
          border-color: ${palette('primary', 10)};
        }
      }
    }

    > .ant-btn:first-child:not(:last-child) {
      border-radius: ${(props) => (props['data-rtl'] === 'rtl' ? '0 4px 4px 0' : '4px 0 0 4px')};
    }

    > .ant-btn:last-child:not(:first-child) {
      border-radius: ${(props) => (props['data-rtl'] === 'rtl' ? '4px 0 0 4px' : '0 4px 4px 0')};
    }

    .ant-btn-primary:last-child:not(:first-child),
    .ant-btn-primary + .ant-btn-primary {
      border-left-color: ${(props) =>
        props['data-rtl'] === 'rtl' ? palette('primary', 0) : palette('primary', 2)};
      border-right-color: ${(props) =>
        props['data-rtl'] === 'rtl' ? palette('primary', 2) : palette('primary', 0)};
    }

    .ant-btn-primary:first-child:not(:last-child) {
      border-left-color: ${(props) =>
        props['data-rtl'] === 'rtl' ? palette('primary', 2) : palette('primary', 0)};
      border-right-color: ${(props) =>
        props['data-rtl'] === 'rtl' ? palette('primary', 0) : palette('primary', 2)};
    }

    .ant-btn + .ant-btn,
    + .ant-btn {
      margin-left: ${(props) => (props['data-rtl'] === 'rtl' ? '0' : '-1px')} !important;
      margin-right: ${(props) => (props['data-rtl'] === 'rtl' ? '-1px' : '0')} !important;
    }

    &.ant-btn-group-lg {
      > .ant-btn {
        padding: 0 35px;
        font-size: 14px;
        height: 42px;
      }
    }

    &.ant-btn-group-sm {
      > .ant-btn {
        padding: 0 15px;
        height: 28px;
        font-size: 12px;
      }
    }
  }

  &.ant-btn-group + &.ant-btn-group {
    margin-left: ${(props) => (props['data-rtl'] === 'rtl' ? '0' : '-1px')} !important;
    margin-right: ${(props) => (props['data-rtl'] === 'rtl' ? '-1px' : '0')} !important;
  }
`;

const GhostButtons = (ComponentName) => styled(ComponentName)`
  .ant-btn-background-ghost {
    background: transparent !important;
    border-color: #fff;
    color: #fff;

    &.ant-btn-primary {
      color: ${palette('primary', 0)};
      background-color: transparent;
      border-color: ${palette('primary', 0)};
    }
  }
`;

export { Buttons, ButtonsGroup, RadioButtons, GhostButtons };
