import styled, { css } from 'styled-components';

const StyledGeneric = styled.div`
  ${() => {
    return css`
      .timeframe-select {
        width: 150px;
      }
    `;
  }}
`;

StyledGeneric.displayName = 'StyledGeneric';
export default StyledGeneric;
