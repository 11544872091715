import React from 'react';
import SVG from 'components/svg/SVG';
import Dropdown from 'theme/uielements/dropdown';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonsProjectSelector = () => {
  return (
    <>
      <div id="project-selector" className="project-selector">
        <Dropdown
          overlay={() => {}}
          trigger={['none']}
          placement="bottomRight"
          className="project-dropdown"
          getPopupContainer={() => document.getElementById('project-selector')}
        >
          <div className="d-flex justify-content-start align-items-center">
            <div className="mr-1">
              <p className="name mb-05">
                <Skeleton width={100} height={14} containerClassName="skeleton-container" />
              </p>
              <p className="description m-0">
                <Skeleton width={100} height={14} containerClassName="skeleton-container" />
              </p>
            </div>
            <SVG name="bicon-chevron-up" width={20} height={20} />
          </div>
        </Dropdown>
      </div>
      <div className="navDivider" />
    </>
  );
};

export default SkeletonsProjectSelector;
