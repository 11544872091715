import React, {lazy, Suspense} from 'react';
import * as R from 'ramda';
import { Redirect, Route, Switch } from 'react-router-dom';
import LabelsPage from '../components/settings/labelsPage/LabelsPage';
import Projects from '../components';
import ROUTE_PATHS from './routePaths';

const GeneralSettings = lazy(() => import('modules/projects/components/settings/settings'));
const BuildSettings = lazy(() => import('modules/projects/components/settings/buildSettings'));
const MetricsSettings = lazy(() => import('modules/projects/components/settings/metricsSettings'));
const ProjectVariables = lazy(() => import('modules/projects/components/settings/projectvariables'));
const Dashboard = lazy(() => import('modules/projects/components/Dashboard'));
const ProjectEnvironmentRules = lazy(() =>
  import('modules/projects/components/settings/environmentRules')
);

const Root = (props) => {
  return (
    <Suspense  fallback={null}>
      <Switch>
        <Redirect exact from={ROUTE_PATHS.PROJECTS_LANDING} to={ROUTE_PATHS.PROJECTS} />
        <Route key="projects" path={ROUTE_PATHS.PROJECTS} exact component={Projects} />
        <Route
          key={ROUTE_PATHS.LANDING}
          path={ROUTE_PATHS.LANDING}
          exact
          render={() => <Dashboard {...props} />}
        />
        <Route
          key="project"
          path="/:organizationId/projects/:projectId"
          exact
          render={() => {
            const projectId = R.path(['match', 'params', 'projectId'], props);
            const organizationId = R.path(['match', 'params', 'organizationId'], props);
            return <Redirect {...props} exact to={`/${organizationId}/projects/${projectId}/environments`} />;
          }}
        />
        <Route
          key={ROUTE_PATHS.SETTINGS}
          path={ROUTE_PATHS.SETTINGS}
          exact
          render={({ match }) => {
            const projectId = R.path(['params', 'projectId'], match);
            const organizationId = R.path(['params', 'organizationId'], match);
            return <Redirect exact to={`/${organizationId}/projects/${projectId}/settings/general`} />;
          }}
        />
        <Route
          key={ROUTE_PATHS.SETTINGS_GENERAL}
          path={ROUTE_PATHS.SETTINGS_GENERAL}
          render={() => <GeneralSettings {...props} />}
          exact
        />
        <Route
          key={ROUTE_PATHS.BUILD_SETTINGS}
          path={ROUTE_PATHS.BUILD_SETTINGS}
          render={() => <BuildSettings {...props} />}
          title="Project Build Settings"
          // exact
        />
        <Route
          key={ROUTE_PATHS.METRICS_SETTINGS}
          path={ROUTE_PATHS.METRICS_SETTINGS}
          render={() => <MetricsSettings {...props} />}
          title="Engineering Metrics Settings"
          // exact
        />
        <Route
          key={ROUTE_PATHS.PROJECT_VARIABLES}
          path={ROUTE_PATHS.PROJECT_VARIABLES}
          render={() => <ProjectVariables {...props} />}
          title="Project variables"
          // exact
        />
        <Route
          key={ROUTE_PATHS.SETTINGS_ENVIRONMENT_RULES}
          path={ROUTE_PATHS.SETTINGS_ENVIRONMENT_RULES}
          render={() => <ProjectEnvironmentRules {...props} />}
          title="Project environment rules"
          // exact
        />
        <Route
          key={ROUTE_PATHS.PROJECT_LABELS}
          path={ROUTE_PATHS.PROJECT_LABELS}
          render={() => <LabelsPage {...props} />}
          title="Project Labels"
          // exact
        />
      </Switch>
    </Suspense>
  );
};

export default Root;
