import React, { memo } from 'react';
import PropTypes from 'prop-types';
import STATUS_TYPES from './constants';
import StyledStatusBullet from './StatusBullet.style';

const StatusBullet = ({ children, status, type }) => {
  return <StyledStatusBullet status={status} className={status} type={type}>
    {
      status === 'pending' &&
      <div className="loader loader--style3" title="2">
        <svg
          version="1.1"
          id="loader-1"
          xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          width="40px" height="40px" viewBox="0 0 50 50" enableBackground="new 0 0 50 50;"
          xmlSpace="preserve"
        >
          <path
            fill="#1F2DCF"
            shapeRendering="geometricPrecision"
            d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 25 25"
              to="360 25 25"
              dur="0.6s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </div>
    }
    {children}
    {
      status === 'pending'
        ? (<><span>.</span><span>.</span><span>.</span></>)
        : ''
    }
  </StyledStatusBullet>;
};

StatusBullet.defaultProps = {
  children: null,
  status: STATUS_TYPES.pending,
  type: null,
};

StatusBullet.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  status: PropTypes.oneOf([
    STATUS_TYPES.pending,
    STATUS_TYPES.success,
    STATUS_TYPES.warning,
    STATUS_TYPES.aborted,
    STATUS_TYPES.error,
    STATUS_TYPES.detached,
    STATUS_TYPES.disconnected,
    STATUS_TYPES.locked,
    STATUS_TYPES.invalid,
    STATUS_TYPES.draft
  ]),
};

export default memo(StatusBullet);
