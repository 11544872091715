import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routing } from "modules/meeting/Router";
import { RouterState } from "modules/billing/components/billingV2/components/paymentMethods/PaymentMethodsSection";
import { useTrialInfo } from "components/header/components/billing/utils";
import { useOrganizationACL } from "rbac/hooks/useOrganizationACL";

export interface IProps {
    organizationId: number;
}

export function TrialRibbon(props: IProps): React.ReactElement {
    const trial = useTrialInfo();
    const [daysLeft, setDaysLeft] = useState(-1);

    const orgManageACL = useOrganizationACL('organization:billing', {
        organization: {
            id: props.organizationId,
        }
    }, {
        allowFetching: true,
    });

    useEffect(() => {
        if (!trial || trial.remainingDays === -1) {
            return undefined;
        }

        if (daysLeft !== trial.remainingDays) {
            setDaysLeft(trial.remainingDays);
            return undefined;
        }

        const timeoutId = setTimeout(() => {
            setDaysLeft(trial.remainingDays - 1);
        }, trial.secondsToUpdate * 1000);

        return () => {
            clearTimeout(timeoutId)
        };
    }, [trial, daysLeft]);

    if (!trial || daysLeft === -1) {
        return <></>;
    }

    const unit = daysLeft === 1 ? 'day' : 'days';
    const billingState: RouterState = {
        addCard: orgManageACL.type === 'allowed',
    };

    return <div className="trial ribbon">
        <span title={`${trial.end.format()}`}><strong>{daysLeft} {unit} left</strong> in your trial.&nbsp;</span>
        <Link
            to={{
                pathname: `/${props.organizationId}/billing`,
                state: billingState,
            }}
        >
            Add billing details</Link>
        &nbsp;or&nbsp;
        <Link
            to={{
                pathname: routing.billing(props.organizationId),
            }}
        >
            Request more time</Link>
    </div>
}
