/* eslint-disable max-lines */
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { connect } from 'react-redux';
import { isBlank } from 'utils';
import SVG from 'components/svg';
import Link from '../link';
import StyledBreadcrumb from './Breadcrumb.style';
import BreadcrumbSkeleton from './BreadcrumbSkeleton';

const getListingBreadcrumbs = ({ organizationId, name }) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: name,
  },
];

const getSettingsBreadcrumbs = ({
  organizationId,
  projectId,
  environmentId,
  environmentName,
  projectName,
  pageName,
  hasList,
  count,
  type,
  status,
  clusterName,
  createdAt,
  updatedAt,
  parentName,
  unique,
  urlHandle,
}) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: projectName,
    href: `/${organizationId}/projects/${projectId}/environments`,
  },
  {
    label: environmentName,
    href: `/${organizationId}/projects/${projectId}/environments/${environmentId}`,
    state: {
      hasList,
      count,
      name: environmentName,
      type,
      status,
      clusterName,
      createdAt,
      updatedAt,
      parentName,
      unique,
      urlHandle,
    },
  },
  {
    label: pageName,
  },
];

const getEventsBreadcrumbs = ({
  organizationId,
  projectId,
  environmentId,
  environmentName,
  hasList,
  count,
  type,
  status,
  clusterName,
  createdAt,
  updatedAt,
  parentName,
  unique,
}) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: 'Environments',
    href: `/${organizationId}/projects/${projectId}/environments`,
  },
  {
    label: environmentName,
    href: `/${organizationId}/projects/${projectId}/environments/${environmentId}`,
    state: {
      hasList,
      count,
      name: environmentName,
      type,
      status,
      clusterName,
      createdAt,
      updatedAt,
      parentName,
      unique,
    },
  },
  {
    label: 'Events',
  },
];

const getPipelinesListingBreadcrumbs = ({
  organizationId,
  projectId,
  environmentId,
  environmentName,
  projectName,
  hasList,
  count,
  type,
  status,
  clusterName,
  createdAt,
  updatedAt,
  parentName,
  unique,
  urlHandle,
}) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: projectName,
    href: `/${organizationId}/projects/${projectId}/environments`,
  },
  {
    label: environmentName,
    href: `/${organizationId}/projects/${projectId}/environments/${environmentId}`,
    state: {
      hasList,
      count,
      name: environmentName,
      type,
      status,
      clusterName,
      createdAt,
      updatedAt,
      parentName,
      unique,
      urlHandle,
    },
  },
  {
    label: 'Pipelines',
  },
];

const getPipelineDetailsBreadcrumbs = ({
  organizationId,
  projectId,
  environmentId,
  environmentName,
  projectName,
  pipelineName,
  hasList,
  count,
  type,
  status,
  clusterName,
  createdAt,
  updatedAt,
  parentName,
  unique,
  urlHandle,
}) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: projectName,
    href: `/${organizationId}/projects/${projectId}/environments`,
  },
  {
    label: environmentName,
    href: `/${organizationId}/projects/${projectId}/environments/${environmentId}`,
    state: {
      hasList,
      count,
      name: environmentName,
      type,
      status,
      clusterName,
      createdAt,
      updatedAt,
      parentName,
      unique,
      urlHandle,
    },
  },
  {
    label: 'Pipelines',
    href: `/${organizationId}/projects/${projectId}/environments/${environmentId}/pipelines`,
  },
  {
    label: pipelineName,
  },
];

const getDetailsBreadcrumbs = ({ organizationId, projectId, projectName, name }) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: projectName,
    href: `/${organizationId}/projects/${projectId}/environments`,
  },
  {
    label: name,
  },
];

const getTemplateDetailsBreadcrumbs = ({ organizationId, name }) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: 'Templates',
    href: `/${organizationId}/templates`,
  },
  {
    label: name,
  },
];
const getClusterDetailsBreadcrumbs = ({ organizationId, label }) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: 'Clusters',
    href: `/${organizationId}/clusters`,
  },
  {
    label,
  },
];
const getClusterPipelineBreadcrumbs = ({ organizationId, clusterId, clusterName, label }) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: 'Clusters',
    href: `/${organizationId}/clusters`,
  },
  {
    label: clusterName,
    href: `/${organizationId}/clusters/${clusterId}`,
  },
  {
    label,
  },
];

const getClustersListingBreadcrumbs = ({ organizationId, name }) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: 'Templates',
    href: `/${organizationId}/clusters`,
  },
  {
    label: name,
  },
];

/**
 * @param {object} params
 * @param {string} params.organizationId
 * @param {string} params.label
 */
const getRepositoryDetailsBreadcrumbs = ({ organizationId, label }) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/`,
  },
  {
    label: 'Git Integrations',
    href: `/${organizationId}/integrations/repositories`,
  },
  {
    label,
  },
];

const getBillingBreadcrumbs = ({ organizationId }) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: 'Billing',
  },
];

/**
 * @param {object} params
 * @param {string} params.organizationId
 */
const getAdminBreadcrumbs = ({ organizationId }) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: 'Admin',
  },
];

/**
 * @param {object} params
 * @param {string} params.organizationId
 */
const getAdminOrganizationsBreadcrumbs = ({ organizationId }) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: 'Admin',
    href: `/${organizationId}/admin/`,
  },
  {
    label: 'Organizations',
  },
];

/**
 * @param {object} params
 * @param {string} params.organizationId
 */
const getAdminUsersBreadcrumbs = ({ organizationId }) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: 'Admin',
    href: `/${organizationId}/admin/`,
  },
  {
    label: 'Users',
  },
];

/**
 * @param {object} params
 * @param {string} params.organizationId
 */
const getAdminIDTransformerBreadcrumbs = ({ organizationId }) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: 'Admin',
    href: `/${organizationId}/admin/`,
  },
  {
    label: 'ID Transformer',
  },
];

const getBillingAdminBreadcrumbs = ({ organizationId, organizationName }) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: 'Admin',
    href: `/${organizationId}/admin/`,
  },
  {
    label: 'Organizations',
    href: `/${organizationId}/admin/organizations`,
  },
  {
    label: organizationName,
    href: `/${organizationId}/admin/organizations`,
  },
  {
    label: 'Billing',
  },
];

const getBillingAdminUsagesListBreadcrumbs = ({ organizationId, organizationName, selectedAdminOrganizationId }) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: 'Admin',
    href: `/${organizationId}/admin`,
  },
  {
    label: 'Organizations',
    href: `/${organizationId}/admin/organizations`,
  },
  {
    label: organizationName,
    href: `/${organizationId}/admin/organizations`,
  },
  {
    label: 'Billing',
    href: `/${organizationId}/admin/billing/${selectedAdminOrganizationId}`,
  },
  {
    label: 'Uninvoiced subscriptions',
  },
];
const getBillingUsagesListBreadcrumbs = ({ organizationId, organizationName }) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: organizationName,
    href: `/${organizationId}/projects`,
  },
  {
    label: 'Billing',
    href: `/${organizationId}/billing`,
  },
  {
    label: 'Uninvoiced subscriptions',
  },
];

const getBillingInvoicesBreadcrumbs = ({ organizationId, organizationName }) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: organizationName,
    href: `/${organizationId}/projects`,
  },
  {
    label: 'Billing',
    href: `/${organizationId}/billing`,
  },
  {
    label: 'Invoice',
  },
];
const getBillingAdminInvoicesBreadcrumbs = ({ organizationId, organizationName, selectedAdminOrganizationId }) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: 'Admin',
    href: `/${organizationId}/admin`,
  },
  {
    label: 'Organizations',
    href: `/${organizationId}/admin/organizations`,
  },
  {
    label: organizationName,
    href: `/${organizationId}/admin/organizations`,
  },
  {
    label: 'Billing',
    href: `/${organizationId}/admin/billing/${selectedAdminOrganizationId}`,
  },
  {
    label: 'Invoice',
  },
];

const getBillingSubscriptionsBreadcrumbs = ({ organizationId, organizationName }) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: organizationName,
    href: `/${organizationId}/projects`,
  },
  {
    label: 'Billing',
    href: `/${organizationId}/billing`,
  },
  {
    label: 'Future subscriptions',
  },
];

const getBillingAdminSubscriptionsBreadcrumbs = ({ organizationId, organizationName, selectedAdminOrganizationId }) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: 'Admin',
    href: `/${organizationId}/admin`,
  },
  {
    label: 'Organizations',
    href: `/${organizationId}/admin/organizations`,
  },
  {
    label: organizationName,
    href: `/${organizationId}/admin/organizations`,
  },
  {
    label: 'Billing',
    href: `/${organizationId}/admin/billing/${selectedAdminOrganizationId}`,
  },
  {
    label: 'Future subscriptions',
  },
];

export const getSimpleBreadcrumb = ({ organizationId, projectName, projectId, project, name }) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: projectName,
    href: `/${organizationId}/projects/${projectId}/environments`,
    state: {
      accessibleEnvironmentsCount: project?.accessibleEnvironmentsCount,
    },
  },
  { label: name },
];

const getSecurityBreadcrumbs = ({ organizationId, projectId, name, source }) => {
  const securityPath =
    source === 'project' ? `/${organizationId}/projects/${projectId}/security` : `/${organizationId}/security`;
  const breadcrumbs = [
    {
      label: 'icon-home',
      href: `/${organizationId}/projects`,
    },
    {
      label: 'Security',
      href: securityPath,
    },
    {
      label: name,
    },
  ];

  if (source === 'project') {
    breadcrumbs.splice(1, 0, {
      label: 'project-name',
      href: `/${organizationId}/projects/${projectId}`,
    });
  }

  return breadcrumbs;
};

const getSecurityTeamNameBreadcrumbs = ({ organizationId, projectId, source, name }) => {
  const securityPath =
    source === 'project' ? `/${organizationId}/projects/${projectId}/security` : `/${organizationId}/security`;

  const breadcrumbs = [
    {
      label: 'icon-home',
      href: `/${organizationId}/projects`,
    },
    {
      label: 'Security',
      href: securityPath,
    },
    {
      label: 'Teams',
      href: `${securityPath}/teams`,
    },
    {
      label: name,
    },
  ];

  if (source === 'project') {
    breadcrumbs.splice(1, 0, {
      label: 'project-name',
      href: `/${organizationId}/projects/${projectId}`,
    });
  }

  return breadcrumbs;
};

const getSecurityPolicyNameBreadcrumbs = ({ organizationId, projectId, name, source }) => {
  const securityPath =
    source === 'project' ? `/${organizationId}/projects/${projectId}/security` : `/${organizationId}/security`;

  const breadcrumbs = [
    {
      label: 'icon-home',
      href: `/${organizationId}/projects`,
    },
    {
      label: 'Security',
      href: securityPath,
    },
    {
      label: 'Policies',
      href: `${securityPath}/policies`,
    },
    {
      label: name,
    },
  ];

  if (source === 'project') {
    breadcrumbs.splice(1, 0, {
      label: 'project-name',
      href: `/${organizationId}/projects/${projectId}`,
    });
  }

  return breadcrumbs;
};

const getSecurityResourceMatchersBreadcrumbsName = ({ organizationId, projectId, source, name }) => {
  const securityPath =
    source === 'project' ? `/${organizationId}/projects/${projectId}/security` : `/${organizationId}/security`;
  const breadcrumbs = [
    {
      label: 'icon-home',
      href: `/${organizationId}/projects`,
    },
    {
      label: 'Security',
      href: securityPath,
    },
    {
      label: 'Resource selectors',
      href: `${securityPath}/resource-selectors`,
    },
    {
      label: name,
    },
  ];

  if (source === 'project') {
    breadcrumbs.splice(1, 0, {
      label: 'project-name',
      href: `/${organizationId}/projects/${projectId}`,
    });
  }

  return breadcrumbs;
};

export const BreadcrumbMethodsMap = {
  admin: getAdminBreadcrumbs,
  adminOrganizations: getAdminOrganizationsBreadcrumbs,
  adminUsers: getAdminUsersBreadcrumbs,
  adminIDTransformer: getAdminIDTransformerBreadcrumbs,

  billing: getBillingBreadcrumbs,
  billingAdmin: getBillingAdminBreadcrumbs,
  billingAdminUsagesList: getBillingAdminUsagesListBreadcrumbs,
  billingUsagesList: getBillingUsagesListBreadcrumbs,
  billingInvoices: getBillingInvoicesBreadcrumbs,
  billingAdminInvoices: getBillingAdminInvoicesBreadcrumbs,
  billingSubscriptions: getBillingSubscriptionsBreadcrumbs,
  billingAdminSubscriptions: getBillingAdminSubscriptionsBreadcrumbs,

  clusters: getClustersListingBreadcrumbs,
  clusterDetails: getClusterDetailsBreadcrumbs,
  clusterPipeline: getClusterPipelineBreadcrumbs,

  repositoryDetails: getRepositoryDetailsBreadcrumbs,

  environmentDetails: getDetailsBreadcrumbs,
  events: getEventsBreadcrumbs,
  listing: getListingBreadcrumbs,
  pipelineDetails: getPipelineDetailsBreadcrumbs,
  pipelineListing: getPipelinesListingBreadcrumbs,
  settings: getSettingsBreadcrumbs,
  simpleBreadcrumb: getSimpleBreadcrumb,
  templatesDetails: getTemplateDetailsBreadcrumbs,
  security: getSecurityBreadcrumbs,
  securityPolicyName: getSecurityPolicyNameBreadcrumbs,
  securityResourceSelectorsName: getSecurityResourceMatchersBreadcrumbsName,
  securityTeamName: getSecurityTeamNameBreadcrumbs,
};

const Breadcrumb = ({ breadcrumb, organizationName, projectName }) => {
  const location = useLocation();
  const isAdminUrlPage = location.pathname.split('/')[2] === 'admin';

  let breadcrumbData = [];

  if (breadcrumb?.key && BreadcrumbMethodsMap[breadcrumb.key]) {
    breadcrumbData = BreadcrumbMethodsMap[breadcrumb.key](breadcrumb);
  }

  const isLoadingBreadcrumbData =
    breadcrumbData.filter((item) => {
      return typeof item.label === 'undefined' || item.projectName === null || item.label === null;
    }).length > 0;

  return (
    <StyledBreadcrumb>
      {isLoadingBreadcrumbData ? (
        <BreadcrumbSkeleton itemsNo={breadcrumbData.length - 2 || 0} />
      ) : (
        !isBlank(breadcrumbData) &&
        breadcrumbData.map((item) => {
          const { label, href, state } = item;
          return (
            <div key={label} className="breadcrumb-item-wrapper">
              {!isBlank(href) ? (
                <Link
                  className="breadcrumb-item breadcrumb-item-link"
                  to={{
                    pathname: href,
                    state,
                  }}
                >
                  {label === 'icon-home' && (
                    <div className="d-flex align-items-center flex-nowrap">
                      <SVG name="bicon-home" width={16} height={16} className="mr-1" />
                      {organizationName}
                    </div>
                  )}

                  {label === 'project-name' && <span className="ellipsis">{projectName}</span>}
                  {label !== 'project-name' && label !== 'icon-home' && <span className="ellipsis">{label}</span>}
                  <span className="breadcrumb-divider">
                    <SVG name="bicon-chevron-right" width={16} height={16} />
                  </span>
                </Link>
              ) : (
                <div className="breadcrumb-item current">
                  <span className={`ellipsis ${isAdminUrlPage ? 'admin-color' : ''}`}>{label}</span>
                </div>
              )}
            </div>
          );
        })
      )}
    </StyledBreadcrumb>
  );
};

Breadcrumb.defaultProps = {
  organizationName: '',
  projectName: '',
};

Breadcrumb.propTypes = {
  breadcrumb: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
  organizationName: PropTypes.string,
  projectName: PropTypes.string,
};

const mapStateToProps = ({ global, organization, environments, projects }) => {
  const { isLoading } = environments;
  const { breadcrumb } = global;
  const { name: projectName } = projects.project || {
    name: '',
  };
  const { name: organizationName } = organization;

  return {
    isLoading,
    breadcrumb,
    organizationName,
    projectName,
  };
};

export default connect(mapStateToProps, null)(Breadcrumb);
