import type { LabelMap, PermissionMap } from 'rbac/types';
import type { HydraListResponse, ListParams as BaseListParams } from 'types';
import type { InferEndpointResult } from 'app/services/types';

import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { mapListToTags } from 'app/services/api';
import { defaultBaseQueryWithRetry } from 'app/services/rbac/api';

export interface AnyMatcher {
    type: 'any';
}

export interface NoneMatcher {
    type: 'none';
}

export interface CollectionMatcher {
    type: 'collection';
    resources: number[];
}

export interface LabelMatcher {
    type: 'labels';
    labels: LabelMap;
}

export type ApiMatcherType = AnyMatcher | NoneMatcher | CollectionMatcher | LabelMatcher;

export interface ResourceMatcher {
    environmentMatcher: {
        matcher: ApiMatcherType;
    };

    projectMatcher: {
        matcher: ApiMatcherType;
    };
}

export interface ListParams extends BaseListParams {
    organization?: number;
}

export interface ListItem {
    id: string;

    organization: number;

    permissions: PermissionMap;
    matchers: ResourceMatcher[];
};

export interface Item {
    id: string;

    organization: number;

    permissions: PermissionMap;
    matchers: ResourceMatcher[];
};

export const TAG = 'roles';

export const rbacRolesApi = createApi({
    reducerPath: `api:rbac:${TAG}`,

    baseQuery: defaultBaseQueryWithRetry,

    tagTypes: [TAG],

    endpoints: (build) => ({
        list: build.query<HydraListResponse<ListItem>, ListParams>({
            query: (params) => {
                return {
                    url: '/roles',
                    params,
                };
            },
            providesTags: (result) => {
                return mapListToTags({
                    list: result ? result['hydra:member'] : [],
                    idKey: 'id',
                    tag: TAG,
                    includeTag: true,
                    includePartialId: true,
                });
            },
        }),

        get: build.query<Item, string>({
            query: (id) => `/roles/${id}`,
            providesTags: (result) => {
                return mapListToTags({
                    list: result ? [result] : [],
                    idKey: 'id',
                    tag: TAG,
                    includeTag: true,
                });
            },
        }),
    }),
});

export type AsyncListResult = InferEndpointResult<typeof rbacRolesApi.endpoints.list>;