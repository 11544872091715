import styled, { css } from 'styled-components';

const StyledBreadcrumb = styled.div`
  ${({ theme }) => {
    const { palette } = theme;

    return css`
      display: flex;
      align-items: center;
      height: 14px;
      @media (max-width: 991px){
        display: none;
      }
      .breadcrumb-item-wrapper {
        display: flex;
        align-items: center;
        .breadcrumb-item,
        .breadcrumb-divider {
          color: ${palette.color[4]};
          font-size: 12px;
          font-weight: 300;
          display: inline-flex;
          align-items: center;
          &.default{
            display: inline-block
          }
          &.active{
            display: none;
          }
        }
        .breadcrumb-item {
          font-size: 14px;
          line-height: 14px;
          font-weight: 400;
          .ellipsis {
            max-width: 220px;
            line-height: 20px;
          }
          &.current{
            color: ${palette.primary[0]};
            #dark & {
              color: ${palette.grays[11]};
            }
          }
          &.breadcrumb-item-link {
            color: ${palette.text[1]};
            display: inline-flex;
            align-items: center;
            #dark & {
              color: ${palette.grays[7]};
            }
            svg{
              use{
                stroke: #808080;
                stroke-width: 2px;
                stroke-linecap: round;
                stroke-linejoin: round;
              }
            }
            &:hover {
              color: ${palette.primary[0]};
              cursor: pointer;
              text-decoration: none;
              #dark & {
                color: ${palette.grays[11]};
              }
              svg{
                use{
                  stroke: ${palette.primary[0]};
                  stroke-width: 2px;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                }
              }
              .breadcrumb-divider {
                color: ${palette.color[4]};
                font-size: 14px;
                font-weight: 300;
                line-height: 14px;
                display: inline-flex;
                align-items: center;
                &.default{
                  display: none
                }
                &.active{
                  display: inline-block;
                }
              }
            }
          }
        }
        .breadcrumb-divider {
          margin-left: 8px;
          margin-right: 8px;
          font-size: 14px;
          line-height: 14px;
          font-weight: 400;
          svg{
            width: 16px;
            height: 16px;
          }
        }
      }
    `;
  }}
`;

StyledBreadcrumb.displayName = 'StyledBreadcrumb';
export default StyledBreadcrumb;
