import React from 'react';
import { Menu } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import Gravatar from 'react-gravatar';
import mixpanel from "mixpanel-browser";
import PropTypes from "prop-types";
import SVG from 'components/svg/SVG';
import Dropdown, { DropdownMenu, MenuItem } from 'theme/uielements/dropdown';
import userManager from 'modules/userManager/UserManager';
import { handleUpdateUserPreferences } from 'modules/userManager/actions';
import { handleToggleMode, handleUiTheme } from 'actions/global';
import AccessTokenButton from '../accessToken';
import { ThemeMenu } from './ThemeMenu';

function getUsername(firstName, lastName) {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  return firstName || lastName || "Account";
}

const ProfileDropdown = ({userId, currentOrganizationId, email, firstName, lastName}) => {
  const { history } = useHistory();
  const dispatch = useDispatch();

  const handleMenuClick = (e) => {
    // not the theme submenu
    if (!e.keyPath.includes('theme')) {
      return;
    }

    // the actual theme submenu title
    if (e.key === 'theme') {
      return;
    }

    // and select new theme
    const newTheme = e.key;
    // @review these dispatches require heavy refactoring, they require more parameters than provided
    dispatch(handleUpdateUserPreferences(userId, currentOrganizationId, null, null, history, false, newTheme));
    dispatch(handleUiTheme(newTheme));
    if (newTheme !== 'auto') {
      dispatch(handleToggleMode(newTheme));
    }
  };

  const content = (
    <DropdownMenu
      className="isoUserDropdown"
      onClick={handleMenuClick}
    ><Menu.ItemGroup
      title={<div className="userName profile-dropdown-action">{getUsername(firstName, lastName)}</div>}
    >
      <MenuItem key="0">
        <AccessTokenButton userManager={userManager} />
      </MenuItem>

      <ThemeMenu key="theme" />

      <MenuItem key="1">
        <div
          className="profile-dropdown-action mt-1"
          role="button"
          tabIndex={0}
          onClick={() => {userManager.logout(); mixpanel.reset()}}
          onKeyDown={() => {userManager.logout(); mixpanel.reset()}}
          data-value="logout-link"
        >
          Logout
        </div>
      </MenuItem>
    </Menu.ItemGroup></DropdownMenu>
  );

  return (
    <div id="profile-dropdown">
      <Dropdown
        overlay={content}
        trigger={['click']}
        placement="bottomRight"
        className="user-dropdown"
        getPopupContainer={() => document.getElementById( 'profile-dropdown')}
      >
        <div className="isoImgWrapper">
          <div className="userImage">
            <div className="userInitials">
              <SVG name="bicon-profile-icon" width={30} height={30} noHover noStroke />
            </div>
            <Gravatar email={email} size={32} default="blank" />
          </div>
        </div>
      </Dropdown>
    </div>

  );
};
ProfileDropdown.defaultProps = {
  firstName: null,
  lastName: null,
  userId: null,
  currentOrganizationId: null,
};
ProfileDropdown.propTypes = {
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  userId: PropTypes.number,
  currentOrganizationId: PropTypes.number,
};

export default ProfileDropdown;
