import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  showNotification,
  showDeployNotification,
  handleUpdateProjectResource,
  handleUpdateEnvironmentResource,
  handleUpdateIntegrationResource,
  handleUpdateClusterResource,
  handleUpdateOrganizationResource,
  handleUpdateComponentsResource,
  handleUpdatePipelineResource,
  handleUpdateComponentPipelineTopic,
  handleUpdateTemplatesRepoResource,
  handleConfigInterest
} from 'actions/global';
import { handleGetOrganizationOwner } from 'modules/settings/actions';
import compose from 'hocs';
import { isBlank, log } from 'utils';
import { EVENT_TYPE } from '../../constants';
import { getResourceUpdate, handleMessage, handleResourceUpdate, handleSignalUpdate } from '../../helpers';
import withEventSourceProvider from '../withEventSourceProvider';

class NotificationMessageConsumer extends Component {
  constructor(props) {
    super(props);

    this.handleNewNotification = handleMessage(this.onNewNotification, EVENT_TYPE.NOTIFICATION);
    this.handleNewContextUpdate = handleResourceUpdate(this.onNewContextUpdate);
    this.handleNewSignal = handleSignalUpdate(this.onNewSignalMessage);
  }

  componentDidMount() {
    this.addEventListeners(this.props.eventSource);
  }

  componentDidUpdate(prevProps) {
    if (this.props.eventSource !== prevProps.eventSource) {
      this.removeEventListeners(prevProps.eventSource);
      this.addEventListeners(this.props.eventSource);
    }
  }

  componentWillUnmount() {
    this.removeEventListeners(this.props.eventSource);
  }

  onNewNotification = (payload) => {
    const { actions } = this.props;
    const { notificationLevel, title, message } = payload;

    log.info('New Notification message received', payload);

    if (!isBlank(notificationLevel) && !isBlank(message)) {
      actions.showNotification(notificationLevel, title, message, 'fromMercure');
    }
  };

  onNewContextUpdate = (parsedMessageData) => {
    const { actions } = this.props;
    const { resourceUpdate, interest = '' } = parsedMessageData;
    const resourceUpdateObjectName = getResourceUpdate(resourceUpdate)[2]; // projects, environments etc.
    const id = getResourceUpdate(resourceUpdate)[3];

    /** this contains actions that will update the state of some resourceID */
    switch (resourceUpdateObjectName) {
      case 'environments':
        actions.handleUpdateEnvironmentResource(id);
        break;
      case 'projects':
        actions.handleUpdateProjectResource(id);
        break;
      case 'integrations':
        actions.handleUpdateIntegrationResource(id);
        break;
      case 'kubernetes_integrations':
        actions.handleUpdateClusterResource(id);
        break;
      case 'organizations':
        actions.handleUpdateOrganizationResource(id);
        actions.handleGetOrganizationOwner(id);
        break;
      /** case environment component updated */
      case 'applications':
      case 'databases':
      case 'services':
      case 'static_applications':
      case 'generic_components':
      case 'init_container_components':
      case 'sidecar_container_components':
        actions.handleUpdateComponentsResource(id);
        break;
      case 'apply_configuration_jobs':
        /* cloud configuration in progress */
        actions.handleUpdateClusterResource(id);
        break;
      case 'workflows':
        /* used in environment details in order to know when a new pipeline was created (also will subscribe) */
        actions.handleUpdateComponentPipelineTopic(id);
        actions.handleUpdatePipelineResource(id);
        break;
      case 'workflow_jobs':
        actions.handleUpdatePipelineResource(id);
        break;
      default:
        break;
    }

    switch (interest) {
      case 'environment_events_list_update':
        // store.dispatch(rbacRolesApi.util.resetApiState());
        break;
      case 'templates_repositories_list_update':
        actions.handleUpdateTemplatesRepoResource(new Date().getTime());
        break;
      case 'draft_created':
        actions.handleConfigInterest(interest);
        break;
      case 'draft_changed':
        actions.handleConfigInterest(interest);
        break;
      case 'draft_removed':
        actions.handleConfigInterest(interest);
        break;
      default:
        break;
    }
  };

  /* on new signal callback; will show deploy notification */
  onNewSignalMessage = (payload) => {
    const { actions } = this.props;
    const { notificationLevel, title, description, message } = payload;

    actions.showDeployNotification(notificationLevel, title, description, message); 
  };
  

  addEventListeners(eventSource) {
    if (isBlank(eventSource)) return;

    /* on new notification */
    eventSource.addEventListener('message', this.handleNewNotification);

    /* on resource update */
    eventSource.addEventListener('message', this.handleNewContextUpdate);

    /**
     * on signal message
     * [i] change widget texts from handleSignalUpdate */
    eventSource.addEventListener('message', this.handleNewSignal);

    log.info('Notification Consumer handler ready to receive messages', eventSource);
  }

  removeEventListeners(eventSource) {
    if (isBlank(eventSource)) return;

    eventSource.removeEventListener('message', this.handleNewNotification);
    eventSource.removeEventListener('message', this.handleNewContextUpdate);
    eventSource.removeEventListener('message', this.handleNewSignal);

    log.info('event listeners removed', eventSource);
  }

  render() {
    return null;
  }
}

NotificationMessageConsumer.defaultProps = {
  eventSource: null,
};

NotificationMessageConsumer.propTypes = {
  eventSource: PropTypes.shape({
    onmessage: PropTypes.func,
    addEventListener: PropTypes.func,
  }),
  actions: PropTypes.shape({
    showNotification: PropTypes.func,
    showDeployNotification: PropTypes.func,
    handleGetOrganizationOwner: PropTypes.func,
    handleUpdateEnvironmentResource: PropTypes.func,
    handleUpdateProjectResource: PropTypes.func,
    handleUpdateIntegrationResource: PropTypes.func,
    handleUpdateClusterResource: PropTypes.func,
    handleUpdateOrganizationResource: PropTypes.func,
    handleUpdateComponentsResource: PropTypes.func,
    handleUpdatePipelineResource: PropTypes.func,
    handleUpdateComponentPipelineTopic: PropTypes.func,
    handleUpdateTemplatesRepoResource: PropTypes.func,
    handleConfigInterest: PropTypes.func,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showNotification,
      showDeployNotification,
      handleGetOrganizationOwner,
      handleUpdateEnvironmentResource,
      handleUpdateProjectResource,
      handleUpdateIntegrationResource,
      handleUpdateClusterResource,
      handleUpdateOrganizationResource,
      handleUpdateComponentsResource,
      handleUpdateComponentPipelineTopic,
      handleUpdatePipelineResource,
      handleUpdateTemplatesRepoResource,
      handleConfigInterest,
    },
    dispatch
  ),
});

export default compose(connect(null, mapDispatchToProps), withEventSourceProvider)(NotificationMessageConsumer);
