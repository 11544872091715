export const actionTypes = {
  COLLPSE_CHANGE: 'COLLPSE_CHANGE',
  TOGGLE_ALL: 'TOGGLE_ALL',
  COLLPSE_OPEN_DRAWER: 'COLLPSE_OPEN_DRAWER',
  CHANGE_OPEN_KEYS: 'CHANGE_OPEN_KEYS',
  CHANGE_CURRENT: 'CHANGE_CURRENT',
  CLEAR_MENU: 'CLEAR_MENU',
};

export default actionTypes;
