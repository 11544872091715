/* eslint-disable max-lines,react/react-in-jsx-scope,max-params,camelcase */
import * as R from 'ramda';
import { API, GET, PUT, POST } from 'config';
import { handleGetProjects } from 'modules/projects/actions';
import {handleGetOrganizationOwner} from "modules/settings/actions";
import { createPrefixedAction } from 'modules/userManager/config';
import { ERROR_MAP } from 'modules/userManager/constants';
import userManager from 'modules/userManager/UserManager';
import {isBlank, log, getErrorMessage, includes, setOpenWindowLocation} from 'utils';
import mixpanel from "mixpanel-browser"
import {showNotification, showDialog, handleUiTheme} from "actions/global";
import actionTypes from './actionTypes';
import {getLastUsedOrganization, getLastUsedProject} from '../helpers';
import {TOAST_TYPES} from "../../../components";

export const createAccessToken = createPrefixedAction(actionTypes.CREATE_ACCESS_TOKEN);
export const renewAccessToken = createPrefixedAction(actionTypes.RENEW_ACCESS_TOKEN);
export const getAccountDetails = createPrefixedAction(actionTypes.GET_ACCOUNT_DETAILS);
export const getUserPreferences = createPrefixedAction(actionTypes.GET_USER_PREFERENCES);
export const setCurrentProject = createPrefixedAction(actionTypes.SET_CURRENT_PROJECT);
export const addProjectToList = createPrefixedAction(actionTypes.ADD_PROJECT_TO_LIST);
export const deleteProjectFromList = createPrefixedAction(actionTypes.DELETE_PROJECT_FROM_LIST);
export const updateProjectFromList = createPrefixedAction(actionTypes.UPDATE_PROJECT_FROM_LIST);
export const updateUserPreferences = createPrefixedAction(actionTypes.UPDATE_USER_PREFERENCES);
export const getAccountOwnerOrganizations = createPrefixedAction(
  actionTypes.GET_ACCOUNT_OWNER_ORGANIZATIONS
);
export const createOrganization = createPrefixedAction(actionTypes.CREATE_ORGANIZATION);
export const getCurrentOrganization = createPrefixedAction(actionTypes.GET_CURRENT_ORGANIZATION);
export const setUserOrganizations = createPrefixedAction(actionTypes.SET_USER_ORGANIZATIONS);
export const getBillingPlans = createPrefixedAction(actionTypes.GET_BILLING_PLANS);
export const createAccount = createPrefixedAction(actionTypes.CREATE_ACCOUNT);
export const createBillingProfile = createPrefixedAction(actionTypes.CREATE_BILLING_PROFILE);
export const confirmEmail = createPrefixedAction(actionTypes.CONFIRM_EMAIL);
export const resendConfirmEmail = createPrefixedAction(actionTypes.RESEND_CONFIRM_EMAIL);
export const getUnregisterUser = createPrefixedAction(actionTypes.GET_UNREGISTER_USER);
export const profileCreate = createPrefixedAction(actionTypes.PROFILE_CREATE);
export const setupIntent = createPrefixedAction(actionTypes.SETUP_INTENT);
export const logOut = createPrefixedAction(actionTypes.LOG_OUT);
export const registerInvite = createPrefixedAction(actionTypes.REGISTER_INVITE);
export const resetState = createPrefixedAction(actionTypes.RESET_STATE);

export const recoverPassword = createPrefixedAction(actionTypes.RECOVER_PASSWORD);
export const resetPassword = createPrefixedAction(actionTypes.RESET_PASSWORD);
export const checkRecoverToken = createPrefixedAction(actionTypes.CHECK_RECOVER_TOKEN);
export const updateUserOrganizations = createPrefixedAction(actionTypes.UPDATE_USER_ORGANIZATIONS);
export const setIsLogging = createPrefixedAction(actionTypes.SET_IS_LOGGING);

export const gitSignOn = createPrefixedAction(actionTypes.GIT_SIGN_ON);
export const gitSignOnResponse = createPrefixedAction(actionTypes.GIT_SIGN_ON_RESPONSE);
export const setInvalidPasswordError = createPrefixedAction(actionTypes.SET_INVALID_PASSWORD_ERROR);
export const setLoginErrorMessage = createPrefixedAction(actionTypes.SET_LOGIN_ERROR_MESSAGE);
export const setRegisterErrorMessage = createPrefixedAction(actionTypes.SET_REGISTER_ERROR_MESSAGE);

const alwaysShowCriticalErrors = { alwaysShowCriticalErrors: true };

const redirectToLanding = (history, organizationId, projectId) => {
  const loginPathRegex = /(\/login)(\/?)+(\??)+$/g;
  const gitLoginPathRegex = /(\/sso-response)(\/?)+(\??)+$/g;
  const gitRegisterPathRegex = /(\/register-git)(\/?)+(\??)+$/g;
  const redirectUrl = sessionStorage.getItem('redirectUrl') || '';

  if (!!history &&
    (loginPathRegex.test(history.location.pathname)
      || gitLoginPathRegex.test(history.location.pathname)
      || gitRegisterPathRegex.test(history.location.pathname)
    )
  ) {
    
    let parsedStoredLocation = { pathname: '/' };

    try {
      parsedStoredLocation = JSON.parse(redirectUrl);
    } catch {
      sessionStorage.removeItem('redirectUrl')
      log.error('Error parsing redirectUrl from sessionStorage');
    }
    
    if(redirectUrl && (parsedStoredLocation.pathname !== '/' && parsedStoredLocation.pathname !== '/add-workshop-cluster')){
      const finalUrl = `${parsedStoredLocation.pathname}${parsedStoredLocation.search || ''}`
      history.push(finalUrl);
      sessionStorage.removeItem('redirectUrl')
    } else if(projectId){
      history.push(`/${organizationId}/projects/${projectId}/environments`);
    } else {
        history.push(`/${organizationId}/projects`);
      }
    }
};

/**
 *
 * @param {Number} userId
 * @param {Number} organizationId
 * @param {Number} projectId - used to redirect to Environment listing
 * @param {Object} userData
 * @param {Object} history
 * @param {Boolean} withRedirect - used to avoid redirect when changing uiTheme
 * @param {String} uiTheme - theme
 * @param {Array} environmentListingColumns - Array of columns to display on environment listing
 * @param {Array} deploymentListingColumns - Array of columns to display on environment listing
 * @returns user info as an object
 */


  // @TODO: Reduce the number of parameter of this call !!!

export const handleUpdateUserPreferences =
  (userId,
   organizationId,
   projectId,
   userData,
   history,
   withRedirect,
   uiTheme,
   environmentListingColumns,
   deploymentListingColumns
  ) => async (dispatch, getState) => {
  const envListCol = getState().userManager
    try {
      const payload = {
        lastUsedOrganization: `/api/organizations/${organizationId}`,
        lastUsedProject: projectId ? `/api/projects/${projectId}` : null,
        uiTheme,
        environmentListingColumns:
          R.isNil(environmentListingColumns) ? envListCol.environmentListingColumns  : environmentListingColumns,
        deploymentListingColumns
      };
      await dispatch(
        updateUserPreferences(
          PUT(`${API.userPreferences.base}/${userId}`, payload).then(({ data }) => {
            if(withRedirect)
              redirectToLanding(history, organizationId, projectId);
            return {data, userData};
          })
        )
      );
    } catch (exception) {
      log.error('Error', exception);
    }
  };

export const handleLoadAccountOwnerOrganizations =
  (userId,userData, history) => async (dispatch, getState) => {
    try {
      const {userManager: { organizations },} = getState();
      await dispatch(
        getAccountOwnerOrganizations(
          GET(`${API.organizations.base}?accountOwner=${userId}`, null, alwaysShowCriticalErrors).then(({ data }) => {
            let lastUsedOrganization = null;
            if (!isBlank(data)) {
              lastUsedOrganization = R.prop('id', R.head(data));
            }
            if (!isBlank(organizations)) {
              lastUsedOrganization = R.prop('id', R.head(organizations));
            }
            dispatch(handleUpdateUserPreferences(
              userId,
              lastUsedOrganization,
              null,
              userData,
              history,
              true
            ));
          })
        )
      );
    } catch (exception) {
      log.error('Error', exception);
    }
  };

export const handleGetOrganizations =
  (userId) => async (dispatch) => {
    try {
      await dispatch(
        getAccountOwnerOrganizations(
          GET(`${API.organizations.base}?accountOwner=${userId}`).then(({ data }) => {
            return data
          })
        )
      );
    } catch (exception) {log.error('Error', exception);}
  };

/**
 * 
 * @param {Object} history 
 * @param {Boolean} [preventGetProjects] - used to prevent 2nd get project request when changing the organization
 * @param {String} action - retrieve from SSO response to determine if the user comes from register to trigger mixpanel
 * @returns user info as an object
 */
export const loadAccountDetails = (history, preventGetProjects, action) => async (dispatch) => {

  let organizationIdUrl = null;
  if(action === 'change_organization')
    // eslint-disable-next-line prefer-destructuring
    organizationIdUrl = window.location.pathname.split('/')[1];
  
  dispatch(setIsLogging(true));
  try {
    await dispatch(
      getAccountDetails(
        Promise.all([GET(`${API.users.base}/me`, null, alwaysShowCriticalErrors), GET(API.users.preferences('me'), null, alwaysShowCriticalErrors)]).then(
          (body) => {
            const userData = R.path([0, 'data'], body);
            const uiTheme = R.path([1, 'data', 'uiTheme'], body);
            const environmentColumn = R.path([1, 'data', 'environmentListingColumns'], body);
            const deploymentColumn = R.path([1, 'data', 'deploymentListingColumns'], body);
            dispatch(handleUiTheme(uiTheme))
            const lastUsedOrganization = R.path([1, 'data', 'lastUsedOrganization'], body);
            const lastUsedProject = R.path([1, 'data', 'lastUsedProject'], body);

            if(userData.registrationStep === 'confirm_email'){
              history.push(`/register-confirm-email`, { plan: userData.initialBillingPlan, email: userData.email })
            } else if(userData.registrationStep === 'build_trial' ){

              history.push(`/billing-registration`, { id: userData.id})
            }else {
              userManager.setUserId(userData.id);
              if(action === 'register' || action === 'invitation'){
                mixpanel.alias(userData.id);
                mixpanel.people.set({
                  "$name": `${userData.firstName} ${userData.lastName}`,
                  "$email": userData.email
                });
              }
              dispatch(setUserOrganizations(userData.organizations))
              /** user with no organizations; prevent requests that will fail */
              if (!body[0]?.data?.organizations?.length) {
                redirectToLanding(
                  history,
                  getLastUsedOrganization(lastUsedOrganization).id,
                  lastUsedProject ? getLastUsedProject(lastUsedProject).id : null
                );
                return {
                  userData,
                  lastUsedOrganization,
                  lastUsedProject: lastUsedProject || null,
                };
              }
              if (isBlank(lastUsedOrganization)) {
                //dispatch(handleLoadAccountOwnerOrganizations(R.prop('id', userData), userData, history));
                if (!isBlank(userData.organizations)) {
                  dispatch(handleUpdateUserPreferences(
                    userData.id,
                    R.prop('id', R.head(userData.organizations)),
                    null,
                    userData,
                    history,
                    true
                  ));
                }

              } else if (!preventGetProjects) {
                dispatch(handleGetOrganizationOwner(
                  getLastUsedOrganization(lastUsedOrganization).id), userData.id, 0
                )
                dispatch(handleGetProjects({
                    organizationId: organizationIdUrl || getLastUsedOrganization(lastUsedOrganization).id},
                  history)
                ).then(() => {
                  mixpanel.identify(userData.id)
                  redirectToLanding(
                    history,
                    getLastUsedOrganization(lastUsedOrganization).id || null,
                    lastUsedProject ? getLastUsedProject(lastUsedProject).id : null
                  );
                })
              }
            }
            return {
              userData,
              lastUsedOrganization,
              lastUsedProject: lastUsedProject || null,
              environmentColumn,
              deploymentColumn
            };
          }
        )
      )
    );
    //await ;
  } catch (exception) {
    log.error('Error', exception);
    const errorCode = getErrorMessage(exception);
    //localStorage.removeItem(STORE_KEYS.ACCESS_TOKEN);
    if (errorCode in ERROR_MAP) {
      history.push(ERROR_MAP[errorCode]);
    }
  }
};
export const handleCreateAccessToken =
  ({ email, password }, history) =>
    async (dispatch) => {
      try {
        await dispatch(
          createAccessToken(
            userManager.getOAuthClient().createAccessToken({ username: email, password }).then((payload) => {
                userManager.setOAuthToken(payload);
                dispatch(setIsLogging(true));
                dispatch(loadAccountDetails(history, null, 'login'))
                return payload;
              })
              .catch((error) => {
                if(includes(error.toString(), 'resource owner credentials') || includes(error.toString(), 'HTTP status 401')){
                  dispatch(setInvalidPasswordError(true));
                  // dispatch(
                  //   showNotification(TOAST_TYPES.error, 'Incorrect email or password', 'The email or password you entered is incorrect. Please try again or contact support if you are unable to access your account.')
                  // );
                } else if(includes(error.toString(), 'XHR aborted')){
                  dispatch(showDialog({
                    options: {size: "small"},
                    header: '',
                    body: (
                      <div className="text-center pb-5">
                        <svg width="100" height="100" viewBox="0 0 100 100" className="mb-5 mt-5" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100C77.6142 100 100 77.6142 100 50Z" fill="#E44848" fillOpacity="0.15"/>
                          <path fillRule="evenodd" clipRule="evenodd" d="M43.0485 29.1846C45.8806 29.0804 48.6998 29.6193 51.2937 30.7614C53.8877 31.9033 56.1889 33.6185 58.0246 35.7779C59.5069 37.5214 60.6544 39.5181 61.4146 41.6668H62.5C65.2771 41.6687 67.9758 42.5952 70.1679 44.3004C72.36 46.0056 73.9218 48.3923 74.6068 51.0835C75.2918 53.775 75.061 56.6177 73.951 59.1633C72.8408 61.7089 70.9146 63.8125 68.4762 65.1418C67.466 65.6925 66.2006 65.3202 65.65 64.3098C65.0991 63.2995 65.4716 62.0341 66.4819 61.4835C68.1075 60.5973 69.3916 59.195 70.1316 57.4979C70.8716 55.8008 71.0256 53.9056 70.5689 52.1112C70.1123 50.317 69.071 48.726 67.6096 47.5891C66.1483 46.4525 64.35 45.8348 62.4985 45.8335H59.875C58.9252 45.8335 58.0956 45.1912 57.8579 44.2716C57.306 42.1375 56.2777 40.156 54.85 38.4766C53.4223 36.797 51.6323 35.4631 49.6148 34.5748C47.5973 33.6866 45.4048 33.2673 43.2019 33.3485C40.9989 33.4296 38.8431 34.0091 36.8964 35.0433C34.9498 36.0777 33.2629 37.5398 31.9627 39.3198C30.6625 41.0998 29.7825 43.1514 29.3894 45.3206C28.9962 47.4895 29.0998 49.7195 29.6925 51.8427C30.285 53.9658 31.3512 55.927 32.811 57.5789C33.5729 58.441 33.4916 59.7577 32.6296 60.5195C31.7673 61.2814 30.4506 61.2002 29.6887 60.3381C27.8121 58.2143 26.441 55.6927 25.6791 52.9629C24.9171 50.2331 24.7839 47.366 25.2896 44.5773C25.7952 41.7885 26.9262 39.1506 28.5981 36.8621C30.2698 34.5735 32.4387 32.6935 34.9416 31.3639C37.4444 30.0341 40.2162 29.2889 43.0485 29.1846ZM48.5269 48.5271C49.3404 47.7135 50.6594 47.7135 51.4731 48.5271L59.8064 56.8604C60.62 57.6739 60.62 58.9931 59.8064 59.8066C58.9927 60.6202 57.6737 60.6202 56.8602 59.8066L52.0833 55.0298V68.7502C52.0833 69.9008 51.1504 70.8335 50 70.8335C48.8494 70.8335 47.9166 69.9008 47.9166 68.7502V55.0298L43.1398 59.8066C42.326 60.6202 41.0071 60.6202 40.1935 59.8066C39.3798 58.9931 39.3798 57.6739 40.1935 56.8604L48.5269 48.5271Z" fill="#E44848"/>
                        </svg>
                        <h4 className="mb-2 text-center">Connection Error</h4>
                        <p className="mb-3 text-center">We’ve encountered an error while trying to connect to our servers. </p>
                      </div>

                    )
                  }));
                }
              })
          )
        );
        return Promise.resolve();
      } catch (exception) {
        log.error('Error', exception);
        return Promise.reject(new Error(exception.message));
      }
    };
export const handleGetUnregisterUser = (id, history) => async (dispatch) => {
  try {
    await dispatch(
      getUnregisterUser(
        GET(`${API.users.base}/${id}`).then(
          (response) => {
            const userData = R.path(['data'], response);
            userManager.setUserId(userData.id);
            /* USED IN STEP 3 of register
            * @TODO please revise this endpoint if it is necessary anymore.
            *
            * */
            // dispatch(
            //   getAccountOwnerOrganizations(
            //     GET(`${API.organizations.base}?accountOwner=${userData.id}`)
            //       .then(({ data }) => { return data})
            //   )
            // )
            mixpanel.track('Confirm plan')
            return response.data;
          }
        )
      )
    );
    //await ;
  } catch (exception) {
    log.error('Error', exception);
    const errorCode = getErrorMessage(exception);
    if (errorCode in ERROR_MAP) {
      history.push(ERROR_MAP[errorCode]);
    }
  }
};

export const handleGetBillingPlans = () => async (dispatch) => {
    try {
      await dispatch(
        getBillingPlans(
          GET(`${API.BASE_URL}/billing/products?page=1`, {Accept: 'application/json'}, alwaysShowCriticalErrors ).then((response) => {
            return response.data
          })
        )
      );
    } catch (exception) {log.error('Error', exception);}
  };

export const handleCreateAccount = (userInfo, history) => async (dispatch) => {
  try {
    await dispatch(
      createAccount(
        POST(`${API.users.createAccount}`, userInfo, {Accept: 'application/json'}, alwaysShowCriticalErrors ).then(() => {
          history.push(`/register-confirm-email`, {plan: userInfo.preselectedPlan,  email: userInfo.workEmail, id: userInfo.id })
          return true
        })
      )
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};
export const handleRegisterInvite = (userInfo) => async (dispatch) => {
  try {
    await dispatch(
      registerInvite(
        POST(`${API.users.registerInvite}`, userInfo, {Accept: 'application/json'}, alwaysShowCriticalErrors ).then(() => {

          return true
        })
      )
    );
  } catch (exception) {
    log.error('Error', exception);
    const errorMessage = getErrorMessage(exception);
    if(errorMessage.includes('An account with this email already exists')){
      dispatch(showNotification(
        TOAST_TYPES.error,
        'Email address already used',
        'This email address has been already used to create an account. Use a different email address.')
      );
    }
  }
};
export const handleCreateBillingProfile = () => async (dispatch) => {
  try {
    await dispatch(
      createBillingProfile(
        POST(`${API.users.createBillingProfile}`, {Accept: 'application/json'}, alwaysShowCriticalErrors ).then((response) => {
          return response.data
        })
      )
    );
  } catch (exception) {log.error('Error', exception);}
};

export const handleConfirmEmail = (data, history) => async (dispatch) => {
  try {
    await dispatch(
      confirmEmail(
        POST(`${API.users.confirmEmail}`, data, {Accept: 'application/json'} ).then((response) => {
          userManager.setOAuthToken(response);
          dispatch(
            getAccountDetails(
              Promise.all([
                GET(`${API.users.base}/me`, null, alwaysShowCriticalErrors), 
                GET(API.users.preferences('me'), null, alwaysShowCriticalErrors)
              ]).then(
                (body) => {
                  const userData = R.path([0, 'data'], body);
                  userManager.setUserId(userData.id);
                  const organizationId = userData.organizations[0].id;
                  const lastUsedOrganization = `/api/organizations/${organizationId}`;
                  
                  dispatch(handleGetOrganizationOwner(organizationId, userData.id, 1));

                  window.dataLayer.push({"event":"trial_confirmed"})
                  history.push(`/${organizationId}/projects`,  {register: true});

                  return {userData,lastUsedOrganization,lastUsedProject:  null,};
                }
              )
            )
          )
          // history.push('/billing-registration', {id: data.userId})
          return response
        })
      )
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};

export const handleResendConfirmEmail = (payload) => async (dispatch) => {
  try {
    await dispatch(
      resendConfirmEmail(
        POST(API.users.resendEmail, payload, {Accept: 'application/json'} ).then((response) => {
          dispatch(showNotification(TOAST_TYPES.success, 'Confirmation email resent', 'A new confirmation email has been sent to you. Please check your inbox.'));
          return response;
        })
      )
    );
  } catch (exception) {
    const errorMessage = getErrorMessage(exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Error', errorMessage));
    log.error('Error', exception);
  }
};

export const handleProfileCreate = (data, history) => async (dispatch, getState) => {
  const {userId} = getState().userManager;
  try {
    await dispatch(
      profileCreate(
        POST(`${API.users.profileCreate}`, data, {Accept: 'application/json'} ).then((response) => {
          /* set lastUsedOrganization */
          dispatch(handleUpdateUserPreferences(userId, data.organizationId, null, null, history, true));
          history.push(`/${data.organizationId}/projects`)
          return response
        })
      )
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};

export const handleSetupIntent = () => async (dispatch) => {
  try {
    await dispatch(
      setupIntent(
        POST(`${API.users.setupIntent}`, {}, {Accept: 'application/json'} ).then((response) => {
          return response.data
        })
      )
    );
  } catch (exception) {log.error('Error', exception);}
};

export const login = (payload, history) => async (dispatch) => {
  await dispatch(handleCreateAccessToken(payload, history));
};

/**
 * This method is used in the forgot password flow
 * it will send an email with the reset URL if the address is in the DB
 */
export const handlePasswordRecoverEmail = (payload) => async (dispatch) => {
  try {
    await dispatch(
      recoverPassword(
        POST(`${API.users.resetPassword}`, payload).then((response) => {

          if (response.message && response.message.toLowerCase().indexOf('network error') !== -1) {
            throw new Error('A network error occurred.');
          }

          return response.data;
        })
      )
    );
  } catch (exception) {
    if (exception.response && exception.response.status === 422) {
      return;
    }
    dispatch(showNotification(TOAST_TYPES.error, 'Error', 'A network error occurred.'));
    log.error('Error', exception);
  }
};

/**
 * This method is used in the forgot password flow
 * it will check if the provided recovery token is eligible
 */
export const handleRecoverTokenEligibility = (payload) => async (dispatch) => {
  try {
    await dispatch(
      checkRecoverToken(
        GET(`${API.users.resetPassword}${payload.token}`).then((response) => {
          const isExpiredToken = response.data && response.data.expired;
          const isNetworkError =
            response.message && response.message.toLowerCase().indexOf('network error') !== -1;

          if (isNetworkError) {
            throw new Error('A network error occurred.');
          }

          if (isExpiredToken) {
            throw new Error('Token not found or expired');
          }

          return response.data;
        })
      )
    );
  } catch (exception) {log.error('Error', exception);}
};

/**
 * This method is used in the forgot password flow
 * it will replace the old password then the user will be logged automatically
 */
export const handlePasswordResetWithToken = (payload, token, history) => async (dispatch) => {
  try {
    await dispatch(
      resetPassword(
        POST(`${API.users.resetPassword}${token}`, payload, {
          Accept: 'application/json',
        }).then((response) => {
          const isExpiredToken = response.data && response.data.expired;
          const isNetworkError =
            response.message && response.message.toLowerCase().indexOf('network error') !== -1;

          if (isNetworkError) {
            throw new Error('A network error occurred.');
          }

          if (isExpiredToken) {
            throw new Error('Token not found or expired');
          }

          /** token valid, init login process */
          if (response.data && response.data.access_token) {
            userManager.setOAuthToken(response);
            dispatch(
              getAccountDetails(
                Promise.all([GET(`${API.users.base}/me`), GET(API.users.preferences('me'))]).then(
                  (body) => {
                    const userData = R.path([0, 'data'], body);
                    userManager.setUserId(userData.id);
                    const lastUsedOrganization = R.path([1, 'data', 'lastUsedOrganization'], body);
                    const lastUsedProject = R.path([1, 'data', 'lastUsedProject'], body);
                    dispatch(
                      handleGetOrganizationOwner(
                        getLastUsedOrganization(lastUsedOrganization).id, 0
                      )
                    );
                    history.push(`/${getLastUsedOrganization(lastUsedOrganization).id}/projects`);
                    /** dispatch password changed message */
                    dispatch(showNotification(TOAST_TYPES.success,'Password changed.','You have successfully changed the password for your account.')
                    );
                    return {
                      userData,
                      lastUsedOrganization,
                      lastUsedProject: lastUsedProject || null,
                    };
                  }
                )
              )
            );
          }
          return response.data;
        })
      )
    );
  } catch (exception) {
    let errorMessage = getErrorMessage(exception);
    errorMessage = errorMessage === 'Invalid token.' ? 'Token not found or expired' : 'Something went wrong.';
    dispatch(showNotification(TOAST_TYPES.error, 'Error', errorMessage));
    log.error('Error', exception);
  }
};
export const handleCreateOrganization =
  (name, companySize, timezone, userId, callback) => async (dispatch) => {
    const body = {
      name,
      timezone,
      companySize,
      accountOwner: `api/users/${userId}`,
    };

    try {
      await dispatch(
        createOrganization(
          POST(API.organizations.base, JSON.stringify(body)).then(({ data }) => {
            callback(data);
            return data;
          })
        )
      );
    } catch (exception) {
      log.error('Error', exception);
      dispatch(showNotification(TOAST_TYPES.error, 'Error', getErrorMessage(exception)));
    }
  };

export const handleSSO = (provider, action, token, id, utm) => async (dispatch) => {
  const data = {}
  data.provider = provider;
  data.action = action;

  if(token){
    data.token = token;
  }
  if(utm?.utm_source){
    data.utmSource = utm?.utm_source;
  }
  if(utm?.utm_medium){
    data.utmMedium = utm?.utm_medium;
  }
  if(utm?.utm_campaign){
    data.utmCampaign = utm?.utm_campaign;
  }

  if(id){
    data.organizationUserId = parseInt(id, 10)
  }
  try {
    await dispatch(
      gitSignOn(
        POST(API.users.gitSsoConnect, data ).then((response) => {
          setOpenWindowLocation(response.data, true);
        })
      )
    );
  } catch (exception) {
    log.error('Git Connect', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Git connection error', getErrorMessage(exception)));
  }
};
// eslint-disable-next-line max-params
export const handleSSOResponse = (payloadData, organizationUserId,  fields, history, userTracking) =>
  async (dispatch) => {
    const data = {}
    data.exchangeCode = payloadData.code;
    data.stateCode = payloadData.state;
    data.provider = payloadData.provider;
    data.action = payloadData.action;

    if (userTracking && userTracking.utm_params) {
      data.utmSource = userTracking.utm_params.utm_source;
      data.utmCampaign = userTracking.utm_params.utm_campaign;
      data.utmMedium = userTracking.utm_params.utm_medium;
    }
    if (userTracking && userTracking.tolt_referral) {
      data.toltReferral = userTracking.tolt_referral;
    }

    if (organizationUserId) {
      data.organizationUserId = parseInt(organizationUserId, 10);
      data.token = payloadData.token;
    }
    if (payloadData.action === 'register') {
      data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    try {
      await dispatch(
        gitSignOnResponse(
          POST(API.users.gitSsoResponse, data ).then((response) => {
            userManager.setOAuthToken(response);
            dispatch(setIsLogging(true));
            dispatch(loadAccountDetails(history, false, payloadData.action))
            return response;
          })
        )
      );
    } catch (exception) {
      log(exception);
      if(getErrorMessage(exception) === 'Unauthorized' && payloadData.action === 'login'){
        dispatch(setLoginErrorMessage('There is no account with this email address.'))
        history.push('/login');
      } else if(getErrorMessage(exception) === 'Unauthorized' && payloadData.action === 'register'){
        dispatch(setRegisterErrorMessage('An account with this email address already exists.'))
        history.push('/register');
      } else {
        // history.push('/login');
        // dispatch(showNotification(TOAST_TYPES.error, 'Connection error', 'We’ve encountered an error while trying to connect to our servers'));
      }

    }
  };
