const actionTypes = {
  GET_TIMEZONES: 'GET_TIMEZONES',
  GET_PERSONAL_TOKEN: 'GET_PERSONAL_TOKEN',
  GENERATE_PERSONAL_TOKEN: 'GENERATE_PERSONAL_TOKEN',
  REVOKE_PERSONAL_TOKEN: 'REVOKE_PERSONAL_TOKEN',
  GET_ORGANIZATION_DETAILS: 'GET_ORGANIZATION_DETAILS',
  UPDATE_ORGANIZATION: 'UPDATE_ORGANIZATION',
  UPDATE_USERS_LIST: 'UPDATE_USERS_LIST',
  RESET_STATE: 'RESET_STATE',
  INVITE_USER: 'INVITE_USER',
  REVOKE_USER: 'REVOKE_USER',
  RESEND_INVITE: 'RESEND_INVITE',
  ACCEPT_INVITE: 'ACCEPT_INVITE',
  REGISTER_INVITED: 'REGISTER_INVITED',
  GET_OWNER: 'GET_OWNER',
  RESET_ERRORS: 'RESET_ERRORS',
  DEFAULT_ECR_REGISTRY: 'DEFAULT_ECR_REGISTRY',
};

export default actionTypes;