import styled from 'styled-components';

const StyledAccessTokenForm = styled.div`
  input.ant-input.access-token-input[readonly] {
    font-family: Menlo-Regular;
    background-color: #f4f5fd;
  }

  .copy {
    position: absolute;
    color: #1f2dcf;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    user-select: none;
    #dark & {
      color: #8A75E9;
    }
  }

  .react-loading-skeleton {
    --base-color: #fff;
    --highlight-color: #a1a9ff;
    --animation-duration: .5s;
    --animation-direction: normal;
    --pseudo-element-display: block; /* Enable animation */

    background-color: var(--base-color);
}

  .input-skeleton {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

StyledAccessTokenForm.displayName = 'StyledAccessTokenForm';
export default StyledAccessTokenForm;
