import styled, { css } from 'styled-components';

const StyledLoader = styled.div`
  ${({ bgColor, isFixed, theme }) => {
    const { palette } = theme;
    return css`
      width: 100%;
      height: calc(100% - 70px);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0px;
      left: 0;
      background-color: ${bgColor || '#FFF'};

      ${isFixed ? 'position:fixed;height: 100%;top: 50%;transform: translateY(-50%);z-index: 9;' : ''}

      #dark & {
        background-color: ${palette.background[0]};
      }
    `;
  }}
`;

StyledLoader.displayName = 'StyledLoader';
export default StyledLoader;
