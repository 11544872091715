const actionTypes = {
  ABORT_ENVIRONMENT: 'ABORT_ENVIRONMENT',
  GET_ENVIRONMENTS: 'GET_ENVIRONMENTS',
  CREATE_ENVIRONMENT: 'CREATE_ENVIRONMENT',
  DELETE_ENVIRONMENT: 'DELETE_ENVIRONMENT',
  FORCE_DELETE_ENVIRONMENT: 'FORCE_DELETE_ENVIRONMENT',
  DEPLOY_ENVIRONMENT: 'DEPLOY_ENVIRONMENT',
  GET_ENVIRONMENT_DETAILS: 'GET_ENVIRONMENT_DETAILS',
  UPDATE_ENVIRONMENT: 'GET_ENVIRONMENT',
  MANUALLY_UPDATE_ENVIRONMENT: 'MANUALLY_UPDATE_ENVIRONMENT',
  GET_ENV_VARIABLES: 'GET_ENV_VARIABLES',
  GET_COMPONENTS: 'GET_COMPONENTS',
  GET_COMPONENTS_YAML: 'GET_COMPONENTS_YAML',
  EDIT_COMPONENT: 'EDIT_COMPONENT',
  ADD_COMPONENT: 'ADD_COMPONENT',
  REMOVE_COMPONENT: 'REMOVE_COMPONENT',
  GET_DRAFT_COMPONENTS: 'GET_DRAFT_COMPONENTS',
  CREATE_VARIABLE: 'CREATE_VARIABLE',
  UPDATE_VARIABLE: 'UPDATE_VARIABLE',
  DELETE_VARIABLE: 'DELETE_VARIABLE',
  SAVE_CONFIGURATION: 'SAVE_CONFIGURATION',
  PUBLISH_CONFIGURATION: 'PUBLISH_CONFIGURATION',
  UPDATE_ENVIRONMENT_TIMEFRAMES: 'UPDATE_ENVIRONMENT_TIMEFRAMES',
  GET_TIMEFRAMES: 'GET_TIMEFRAMES',
  CREATE_TIMEFRAME: 'CREATE_TIMEFRAME',
  EDIT_TIMEFRAME: 'EDIT_TIMEFRAME',
  REMOVE_TIMEFRAME: 'REMOVE_TIMEFRAME',
  REVERT_TIMEFRAME: 'REVERT_TIMEFRAME',
  RESET_STATE: 'RESET_STATE',
  RESET_ENV_COMPONENTS: 'RESET_ENV_COMPONENTS',
  RESET_ENV_DETAILS: 'RESET_ENV_DETAILS',
  GET_KUBERNETES_CLUSTERS: 'GET_KUBERNETES_CLUSTERS',
  GET_RESOURCE_UPDATE: 'GET_RESOURCE_UPDATE',
  START_ENVIRONMENT: 'START_ENVIRONMENT',
  STOP_ENVIRONMENT: 'STOP_ENVIRONMENT',
  CLONE_ENVIRONMENT: 'CLONE_ENVIRONMENT',
  GET_ENVIRONMENT_EVENTS: 'GET_ENVIRONMENT_EVENTS',
  GET_ENVIRONMENT_PIPELINES: 'GET_ENVIRONMENT_PIPELINES',
  GET_ENVIRONMENT_PIPELINE_DETAIL: 'GET_ENVIRONMENT_PIPELINE_DETAIL',
  GET_ENVIRONMENT_PIPELINES_USERS: 'GET_ENVIRONMENT_PIPELINES_USERS',
  GET_SOURCE_EVENTS: 'GET_SOURCE_EVENTS',
  GET_MODULE_OUTPUT_VALUES: 'GET_MODULE_OUTPUT_VALUES',
  UPDATE_ENVIRONMENT_EVENTS_LIST: 'GET_ENVIRONMENT_EVENTS_LIST',
  GET_ENVIRONMENT_EVENT_LOGS: 'GET_ENVIRONMENT_EVENT_LOGS',
  UPDATE_ENVIRONMENT_EVENT_LOGS: 'UPDATE_ENVIRONMENT_EVENT_LOGS',
  GET_LOG_LINK: 'GET_LOG_LINK',
  SET_CURRENT_ENVIRONMENTS_NO: 'SET_CURRENT_ENVIRONMENTS_NO',
  CHANGE_STATUS: 'CHANGE_STATUS',
  GET_MANIFESTS: 'GET_MANIFESTS',
  RESET_ENV_ERRORS: 'RESET_ENV_ERRORS',
  PUBLISH_AND_DEPLOY: 'PUBLISH_AND_DEPLOY',
  CONVERT_TO_PRIMARY: 'CONVERT_TO_PRIMARY',
  TOGGLE_AUTO_UPDATE: 'TOGGLE_AUTO_UPDATE',
  APPLY_MODULE: 'APPLY_MODULE',
  GET_MODULE_RESOURCES: 'GET_MODULE_RESOURCES',
  DETACH_MODULE: 'DETACH_MODULE',
  SYNC_MODULE: 'SYNC_MODULE',
  SAVE_BUILD_SETTINGS: 'SAVE_BUILD_SETTINGS',
  GET_ENV_SECRET_VALUE: 'GET_ENV_SECRET_VALUE',
  HIDE_ENV_SECRET_VALUE: 'HIDE_ENV_SECRET_VALUE',
  GET_COMPONENT_SECRET_VALUE: 'GET_COMPONENT_SECRET_VALUE',
  HIDE_COMPONENT_SECRET_VALUE: 'HIDE_COMPONENT_SECRET_VALUE',
  RESET_MODULE_SOURCE: 'RESET_MODULE_SOURCE',
  RESET_SECRET_VALUES: 'RESET_SECRET_VALUES',
  RESET_SECRET_VALUE: 'RESET_SECRET_VALUE',
  RESET_RESOURCE_ID: 'RESET_RESOURCE_ID',
  RESET_ENV_DTL_COSTS: 'RESET_ENV_DTL_COSTS',
  CHECK_CLUSTER_CAPABILITY: 'CHECK_CLUSTER_CAPABILITY',
  SHOW_ENV_LIST_SKELETONS: 'SHOW_ENV_LIST_SKELETONS',
  GET_KUBERNETES_RESOURCES: "GET_KUBERNETES_RESOURCES",
  GET_COMP_PIPELINE_DATA: "GET_COMP_PIPELINE_DATA",
  RESET_COMP_PIPELINE_STATE: "RESET_COMP_PIPELINE_STATE",
  GET_COMPONENT_DEFINITION: "GET_COMPONENT_DEFINITION",
  GET_K8s_RES_DEFINITION: "GET_K8s_RES_DEFINITION",
  GET_STREAM_ID: "GET_STREAM_ID",
  RESET_ALL_COMPONENTS_VARIABLE: "RESET_ALL_COMPONENTS_VARIABLE",
  HANDLE_DEPLOYED_IDS: "HANDLE_DEPLOYED_IDS",
  SAVE_URL_HANDLE: "SAVE_URL_HANDLE",
  RESET_ENV_VIOLATIONS: "RESET_ENV_VIOLATIONS",
  ENRICH_COMPONENTS: "ENRICH_COMPONENTS",
  RESET_PUBLISH_ERROR: "RESET_PUBLISH_ERROR",
  GET_ENVIRONMENTS_MTD: "GET_ENVIRONMENTS_MTD",
  APPLY_CLUSTER_CONFIGURATION: 'APPLY_CLUSTER_CONFIGURATION',
  UPDATE_CHECKED_COMPONENTS_LIST: 'UPDATE_CHECKED_COMPONENTS_LIST',
};

export default actionTypes;
