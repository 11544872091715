/* eslint-disable max-lines-per-function */
import styled from 'styled-components';

const StyledCreateOrganizationButton = styled.div`
  color: #1f2dcf;
  font-size: 14px;
  padding: 8px 16px;
  line-height: 20px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  #dark & {
    color: #FFF;
  }
`;

StyledCreateOrganizationButton.displayName = 'StyledCreateOrganizationButton';
export default StyledCreateOrganizationButton;
