import moment from "moment";
import { useAppSelector } from "app/hooks";

export interface BillingInfo {
    id: number;
    isGettingOrganization: boolean;

    billingMode: string;
    billingPlanType: 'trial'|'free'|unknown;
    billingShowTrialBanner: boolean;
    billingSubscriptionEnd: number;
}

export interface TrialInfo {
    end: moment.Moment,

    remainingDays: number;

    secondsToUpdate: number;
}

export function getTrialInfo(billingInfo: BillingInfo): null|TrialInfo {
    if (billingInfo.isGettingOrganization) {
        return null;
    }

    if (billingInfo.billingPlanType !== 'trial') {
        return null;
    }

    if (billingInfo.billingShowTrialBanner === false) {
        return null;
    }

    const now = moment();
    const trialEnd = moment.unix(billingInfo.billingSubscriptionEnd);

    if (trialEnd.isBefore(now)) {
        return null;
    }

    const midnight = moment().add(1, 'days').startOf('day');
    const nextUpdate = midnight.isAfter(trialEnd) ? trialEnd : midnight;

    return {
        end: trialEnd,
        remainingDays: Math.round(trialEnd.diff(now, 'days', true)),
        secondsToUpdate: nextUpdate.diff(now, 'seconds'),
    };
}

export function useBillingInfo(): BillingInfo {
    return useAppSelector(state => {
        return state.organization as BillingInfo;
    });
}

export function useTrialInfo() {
    const billingInfo = useBillingInfo();

    return getTrialInfo(billingInfo);
}
