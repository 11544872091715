import { createAction } from 'redux-actions';

export const MODULE_NAME = 'APPLICATIONS';

export default {
  MODULE_NAME,
};

export const createPrefixedAction = (type) => createAction(`${MODULE_NAME}/${type}`);

export const getSettingsBreadcrumbs = (
  {
    organizationId,
    projectId,
    environmentId,
    environmentName,
    projectName,
    pageName,
    hasList,
    count,
    type,
    status,
    clusterName,
    createdAt,
    updatedAt,
    parentName,
    unique, urlHandle
  }) => [
  {
    label: 'icon-home',
    href: `/${organizationId}/projects`,
  },
  {
    label: projectName,
    href: `/${organizationId}/projects/${projectId}/environments`,
  },
  {
    label: environmentName,
    href: `/${organizationId}/projects/${projectId}/environments/${environmentId}`,
    state : {
      hasList, count, name: environmentName, type, status,
      clusterName,
      createdAt,
      updatedAt,
      parentName,
      unique,
      urlHandle
    }
  },
  {
    label: pageName,
  },
];