const actionTypes = {
  GET_PROJECTS: 'GET_PROJECTS',
  ADD_PROJECT: 'ADD_PROJECT',
  GET_PROJECT_DETAILS: 'GET_PROJECT_DETAILS',
  UPDATE_PROJECT: 'UPDATE_PROJECT',
  GET_PROJECT_TIMEFRAMES: 'GET_PROJECT_TIMEFRAMES',
  UPDATE_PROJECT_TIMEFRAMES: 'UPDATE_PROJECT_TIMEFRAMES',
  DELETE_PROJECT: 'DELETE_PROJECT',
  CREATE_TIMEFRAME: 'CREATE_TIMEFRAME',
  EDIT_TIMEFRAME: 'EDIT_TIMEFRAME',
  REMOVE_TIMEFRAME: 'REMOVE_TIMEFRAME',
  REVERT_TIMEFRAME: 'REVERT_TIMEFRAME',
  RESET_STATE: 'RESET_STATE',
  GET_PRJ_VARIABLES: 'GET_PRJ_VARIABLES',
  RESET_PRJ_ERRORS: 'RESET_PRJ_ERRORS',
  CREATE_VARIABLE: 'CREATE_VARIABLE',
  UPDATE_VARIABLE: 'UPDATE_VARIABLE',
  DELETE_VARIABLE: 'DELETE_VARIABLE',
  SET_PROJECT_NAME: 'SET_PROJECT_NAME',
  SAVE_BUILD_SETTINGS: 'SAVE_BUILD_SETTINGS',
  GET_SECRET_VALUE: 'GET_SECRET_VALUE',
  HIDE_SECRET_VALUE: 'HIDE_SECRET_VALUE',
  RESET_SECRET_VALUES: 'RESET_SECRET_VALUES',
  RESET_SECRET_VALUE: 'RESET_SECRET_VALUE',
  RESET_RESOURCE_ID: 'RESET_RESOURCE_ID',
  GET_METRICS_CYCLE_TIME: 'GET_METRICS_CYCLE_TIME',
  GET_METRICS_DEPLOYMENT_FREQUENCY: 'GET_METRICS_DEPLOYMENT_FREQUENCY',
  GET_ALL_REPOSITORIES: 'GET_ALL_REPOSITORIES',
  STORE_REPOSITORIES: 'STORE_REPOSITORIES',
  SAVE_METRICS_SETTINGS: 'SAVE_METRICS_SETTINGS',
  SAVE_METRICS_HISTORY_RECOMPUTE: 'SAVE_METRICS_HISTORY_RECOMPUTE',
};

export default actionTypes;
