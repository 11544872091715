const initialState = {
  isLoading: false,
  isSavingCluster: false,
  isRemovingCluster: false,
  isLoadingClusters: true,
  isVerifying: false,
  isConnecting: false,
  errorMessage: '',
  updateResourceId: '',
  kubernetes: [],
  clusterTopics: [],
};

export default initialState;
