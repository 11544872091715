import {
  isValid,
  getHours,
  getMinutes,
  setHours,
  setMinutes,
  setSeconds,
  formatISO,
} from 'date-fns';
import { isBlank } from 'utils';

export const generateHours = () => {
  const x = 30; //minutes interval
  const times = []; // time array
  let tt = 0; // start time
  //const ap = ['AM', 'PM']; // AM-PM

  //loop to increment the time and push results in array
  for (let i = 0; tt < 24 * 60; i += 1) {
    const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    const mm = tt % 60; // getting minutes of the hour in 0-30 format
    const value = `${`0${hh % 24}`.slice(-2)}:${`0${mm}`.slice(-2)}:00`;
    times.push(value); // pushing data in array in [1:00 - 12:00 AM/PM format]
    tt += x;
  }

  return times;
};

export const HOURS = generateHours();

export const formatToHours = (date) => {
  if (isBlank(date) || !isValid(new Date(date))) return null;
  let hours = getHours(new Date(date));
  let minutes = getMinutes(new Date(date));
  let meridiam;
  if (hours < 12) {
    hours = hours === 0 ? hours + 12 : hours;
    meridiam = 'AM';
  } else {
    hours = hours > 12 ? hours - 12 : hours;
    meridiam = 'PM';
  }

  minutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${minutes} ${meridiam}`;
};
export const formatToMeridian = (date) => {
  if (isBlank(date)) return null;
  let [hours, minutes] = date.split(/:| /);
  let meridian;
  if (hours < 12) {
    hours = hours === 0 ? hours + 12 : hours;
    meridian = 'am';
  } else {
    hours = hours > 12 ? hours - 12 : hours;
    meridian = 'pm';
  }
  minutes = minutes < 10 ? `${minutes}` : minutes;

  return `${hours}:${minutes} ${meridian}`;
};
export const formatToDate = (value) => {
  const [hour, minutes, meridiam] = value.split(/:| /);
  let hours = Number(hour);

  if (meridiam === 'AM') {
    hours = hours === 12 ? 0 : hours;
  } else {
    hours = hours === 12 ? 12 : hours + 12;
  }

  let date = setHours(new Date(), hours);
  date = setMinutes(date, minutes);
  return formatISO(setSeconds(date, 0));
};
