import React, { ComponentProps } from "react";
import { InlineWidget } from "react-calendly";
import Loader from "theme/utility/loader";
import { useAppSelector } from "app/hooks";
import { getFullName } from "modules/thirdparty/intercom/utils";

import type { MeData } from "modules/thirdparty/intercom/utils";

export type WidgetProps = ComponentProps<typeof InlineWidget>

export interface IProps extends WidgetProps {
}

export function CalendlyWidget(props: IProps): React.ReactElement {
    return <InlineWidget
        LoadingSpinner={Loader}
        pageSettings={{
            hideGdprBanner: true,
            hideEventTypeDetails: true,
        }}

        {...props}
    />
}

export function BillingWidget(props: IProps): React.ReactElement {
    return <CalendlyWidget
        utm={{
            utmCampaign: 'platform',
            utmMedium: 'meeting',
            utmSource: 'billing',
        }}

        {...props}
    />
}

export function CustomerCalendlyWidget(props: IProps): React.ReactElement {
    const data = useAppSelector(state => state.userManager as MeData);

    return <BillingWidget
        prefill={{
            name: getFullName(data),
            email: data.email,
        }}

        {...props}
    />
}
