import React, { useState } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { hideDialog } from 'actions/global';
import { handleAddProject } from 'modules/projects/actions';
import Button from 'theme/uielements/button';
import Input from 'theme/uielements/input';
import Form from 'theme/uielements/form';
import StyledDialogCreateProject from './DialogCreateProject.style';


const DialogCreateProject = ({ organizationId, actions, projectsList, aclProjectCreateDisabled }) => {
  const [projectName, setProjectName] = useState('');
  const [formIsValid, setFormIsValid] = useState(false);
  const [projectNameError, setProjectNameError] = useState(false);
  const [form] = Form.useForm();

  const handleOnChange = (field, value) => {
    setProjectNameError(false);
    setProjectName(value.trim());
    setFormIsValid(value.length > 2 && value.length < 31 && value.trim().length > 0)
    form.setFieldsValue({ [field]: value });
  };

  const onSubmit = (values) => {
    const projectNames = R.map(R.compose(R.trim, R.toLower, R.prop('name')))(projectsList);
    const index = R.indexOf(projectName.toLowerCase(), projectNames);

    /** set project name already taken error */
    if (index !== -1) {
      setProjectNameError(true);
      setFormIsValid(false);
      return;
    }

    actions.hideDialog();
    actions.handleAddProject(values, organizationId);
  };

  return (
    <StyledDialogCreateProject>
      <Form name="aws" form={form} onFinish={onSubmit}>
        <Grid>
          <Row>
            <Col xs={12} md={12} xl={12} className="px-0">
              <p className="mb-2">Name your project.</p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={12} md={12} xl={12} className="px-0">
              <Form.Item
                className="mb-0"
                name="projectName"
                rules={[
                  {required: true, message: 'This field is required!' },
                  {min: 3, message: "The name must be between 3 and 30 characters and cannot contain white space."},
                  {max: 30, message: "The name must be between 3 and 30 characters and cannot contain white space."},
                  {whitespace: true, message: "The name must be between 3 and 30 characters and cannot contain white space."}
                ]}
              >
                <div>
                  {/* eslint-disable-next-line jsx-a11y/label-has-for,jsx-a11y/label-has-associated-control */}
                  <label className={projectName.length ? ' small filled' : 'small'}>
                    Project name
                  </label>
                  <Input
                    disabled={aclProjectCreateDisabled}
                    className={projectNameError ? 'error' : ''}
                    onChange={({ target }) => handleOnChange('projectName', target.value)}
                  />
                  {projectNameError && <p className="labelError">This name is already used by another project.</p>}
                </div>

              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} xl={12} className="pl-0 pr-0 mb-2 form-footer">
              <Form.Item className="mb-0">
                <Button key="back" type="info" onClick={actions.hideDialog}>
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item className="mb-0">
                <Button
                  key="submit"
                  type="primary"
                  htmlType="submit"
                  data-value="create-project-button"
                  className={formIsValid ? '' : 'disabled'}
                  disabled={!formIsValid || aclProjectCreateDisabled}
                >
                  Create project
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Grid>
      </Form>
    </StyledDialogCreateProject>
  );
};

DialogCreateProject.propTypes = {
  aclProjectCreateDisabled: PropTypes.bool.isRequired,
  projectsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  organizationId: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    hideDialog: PropTypes.func,
    handleAddProject: PropTypes.func,
  }).isRequired,
};


const mapStateToProps = ({ projects }) => ({
  projectsList: projects.list
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      hideDialog,
      handleAddProject,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DialogCreateProject);
