import { TOAST_TYPES } from 'components/notificationsContainer/helpers';
import {log} from "utils";
import { API, GET, POST, PUT } from 'config';
import {hideDialog, showNotification, updateOrganizationResource} from "actions/global";
import mixpanel from "mixpanel-browser";
import userManager from 'modules/userManager/UserManager';
import * as R from "ramda";
import {
  getAccountDetails,
  getCurrentOrganization,
  handleUpdateUserPreferences
} from "modules/userManager/actions";
import { createPrefixedAction } from '../config';
import actionTypes from './actionTypes';
import {getEnvironments} from "../../environments/actions";
import {getLastUsedOrganization} from "../../userManager/helpers";


export const getPersonalAccessToken = createPrefixedAction(actionTypes.GET_PERSONAL_TOKEN);
export const generatePersonalAccessToken = createPrefixedAction(
  actionTypes.GENERATE_PERSONAL_TOKEN);
export const revokePersonalAccessToken = createPrefixedAction(actionTypes.REVOKE_PERSONAL_TOKEN);
export const getOrganizationDetails = createPrefixedAction(actionTypes.GET_ORGANIZATION_DETAILS);
export const getTimezones = createPrefixedAction(actionTypes.GET_TIMEZONES);
export const updateOrganization = createPrefixedAction(actionTypes.UPDATE_ORGANIZATION);
export const updateUsersList = createPrefixedAction(actionTypes.UPDATE_USERS_LIST)
export const inviteUser = createPrefixedAction(actionTypes.INVITE_USER);
export const revokeUser = createPrefixedAction(actionTypes.REVOKE_USER);
export const resendInvitation = createPrefixedAction(actionTypes.RESEND_INVITE);
export const acceptInvitation = createPrefixedAction(actionTypes.ACCEPT_INVITE);
export const resetState = createPrefixedAction(actionTypes.RESET_STATE);
export const registerInvited = createPrefixedAction(actionTypes.REGISTER_INVITED)
export const getOwner = createPrefixedAction(actionTypes.GET_OWNER)
export const resetErrors = createPrefixedAction(actionTypes.RESET_ERRORS);
export const defaultEcrRegistry = createPrefixedAction(actionTypes.DEFAULT_ECR_REGISTRY);

const ldJsonHeader = { 'Content-Type': 'application/ld+json', Accept: 'application/ld+json' };

export const handleGeneratePersonalAccessToken = (id) => async (dispatch) => {
  try {
    await dispatch(
      generatePersonalAccessToken(
        PUT(API.organizations.generateAccessToken(id), {}, { Accept: 'application/json' }).then(
          ({ data }) => {
            return data;
          }
        )
      )
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};

export const handleGetPersonalAccessToken = (id) => async (dispatch) => {
  try {
    await dispatch(
      getPersonalAccessToken(
        GET(API.organizations.getAccessToken(id)).then(({ data }) => {
          if (data?.accessToken === null) {
            dispatch(handleGeneratePersonalAccessToken(id));
          }
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};

export const handleRenewPersonalAccessToken = (id) => async (dispatch) => {
  try {
    await dispatch(
      revokePersonalAccessToken(
        PUT(API.organizations.revokeAccessToken(id), {}, { Accept: 'application/json' }).then(
          ({ data }) => {
            dispatch(handleGeneratePersonalAccessToken(id));
            return data;
          }
        )
      )
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};

export const handleGetOrganizationDetails = (projectId) => async (dispatch) => {
  try {
    await dispatch(
      getEnvironments(GET(`${API.projects.environments}?project[]=${projectId}`).then(({ data }) => data))
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};

export const handleInviteUser = (organizationId, email) => async (dispatch) => {
  let user = null;
  try {
    await dispatch(
      inviteUser(POST(API.organizations.inviteMember(organizationId), {email}).then(({data}) => {
        user = data;

        dispatch(hideDialog())
        dispatch(
          showNotification(
            TOAST_TYPES.success,
            'Invitation sent',
            `The invitation to ${email} has been sent.`
          )
        );
        return data;
      }))
    );
  } catch (exception) {
    log.error('Error', exception);
  }
  return user;
}

export const handleRevokeUser = (user, isRegistered, organizationId) => async (dispatch) => {
  try {
    await dispatch(
      revokeUser(
        PUT(API.organizations.revokeUser(user.id), null, ldJsonHeader).then(() => {
          // revoke does not trigger a mercure update :|
          dispatch(updateOrganizationResource(organizationId));
          if (isRegistered)
            dispatch(
              showNotification(
                TOAST_TYPES.success,
                'User deleted',
                `User ${user?.fullName} has been deleted from this organization.`
              )
            );
          else
            dispatch(
              showNotification(
                TOAST_TYPES.success,
                'Invitation revoked',
                `The invitation to ${user.email} has been revoked.`
              )
            );
          return user.id;
        })
      )
    );
  } catch (exception) {
    log.error('Error', exception);
    dispatch(
      showNotification(
        TOAST_TYPES.error,
        'Action Failed',
        exception?.response?.data
          ? exception.response.data['hydra:description']
          : 'Something went wrong. Please try again later.'
      )
    );
  }
};
export const handleResendInvitation = (userId, email) => async (dispatch) => {
  try {
    await 
        POST(API.organizations.resendInvitation(userId), null, ldJsonHeader)
          .then((response) => {
            dispatch(
              showNotification(
                TOAST_TYPES.success,
                'Invitation resent',
                `You have resent the invitation to ${email}.`
              )
            );
        return response.data;
      }
    );
  } catch (exception) {
    log.error('Error', exception);
    dispatch(
      showNotification(
        TOAST_TYPES.error,
        'Action Failed',
        exception?.response?.data ? exception.response.data["hydra:description"] : 'Something went wrong. Please try again later.'
      )
    );
  }
}
export const handleGetOrganizationOwner = (
  organizationId,userId, isRegisterProcess
) => async (dispatch) =>{
  const extraDataObject = { alwaysShowCriticalErrors: true };

  try {
    await dispatch(
      getOwner(GET(API.organizations.getOwner(organizationId), null, extraDataObject).then(({ data }) => {
        if(isRegisterProcess === 1){
          // Registration process with owner
          mixpanel.alias(userId);
          mixpanel.people.set({
            "$name": `${data.accountOwner.firstName} ${data.accountOwner.lastName}`,
            "$email": data.accountOwner.email,
            "$company": `${data.name}_${data.id}`
          });
        } else if(isRegisterProcess === 2){
          // Registration process invited user with no account
          mixpanel.people.set({
            "$company": `${data.name}_${data.id}`
          });
        }
        return data;
      }))
    );
  } catch (exception) {
    log.error('Error', exception);
  }
}
export const handleAcceptInvitation = (token, id, history) => async (dispatch) => {
  try {
    await dispatch(
      acceptInvitation(POST(API.organizations.acceptInvitation(id), {token}, {Accept: 'application/json'})
        .then((response) => {
          if(response.data && response.data.access_token){
            userManager.setOAuthToken(response);
          } else {
            dispatch(getCurrentOrganization(getLastUsedOrganization(response.data.organization).id))
          }

          dispatch(
            getAccountDetails(
              Promise.all([GET(`${API.users.base}/me`), GET(API.users.preferences('me'))]).then(
                (body) => {
                  const userData = R.path([0, 'data'], body);
                  if(!(response.data && response.data.access_token)) {
                    dispatch(handleUpdateUserPreferences(
                      userData.id, getLastUsedOrganization(response.data.organization).id, null, null, history, true)
                    )
                  }
                  userManager.setUserId(userData.id);
                  const lastUsedOrganization = R.path([1, 'data', 'lastUsedOrganization'], body);
                  const lastUsedProject = R.path([1, 'data', 'lastUsedProject'], body);
                  mixpanel.identify(userData.id)

                  if(!(response.data && response.data.access_token)) {
                    dispatch(handleGetOrganizationOwner(
                      getLastUsedOrganization(response.data.organization).id), userData.id, 0
                    )
                    history.push(`/${getLastUsedOrganization(response.data.organization).id}/projects`);

                  }
                  else {
                    dispatch(handleGetOrganizationOwner(
                      getLastUsedOrganization(lastUsedOrganization).id), userData.id, 0
                    )
                    history.push(`/${getLastUsedOrganization(lastUsedOrganization).id}/projects`)
                  }

                  return {
                    userData,
                    lastUsedOrganization,
                    lastUsedProject: lastUsedProject || null,
                  };
                }
              )
            )
          )
          return response.data;

      }))
    );
  } catch (exception) {
    log.error('Error', exception);
  }
}
export const handleGetTimezones = (projectId) => async (dispatch) => {
  try {
    await dispatch(
      getEnvironments(GET(`${API.projects.environments}?project[]=${projectId}`).then(({ data }) => data))
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};

export const handleUpdateOrganization = (projectId) => async (dispatch) => {
  try {
    await dispatch(
      getEnvironments(GET(`${API.projects.environments}?project[]=${projectId}`).then(({ data }) => data))
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};

export const handleUpdateUsersList = (organizationId,page,searchTerm) => async (dispatch) => {
  try {
    await dispatch(
      updateUsersList(GET(`${API.organizations.members}?organization=${organizationId}&page=${page}&search=${searchTerm}`)
        .then(( response ) => {
        return response.data
      }))
    );
  } catch (exception) {
    log.error('Error', exception);
  }
}
export const handleDeleteOrganization = (projectId) => async (dispatch) => {
  try {
    await dispatch(
      getEnvironments(GET(`${API.projects.environments}?project[]=${projectId}`).then(({ data }) => data))
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};
export const handleRegisterInvited = (payload, organizationId, history) => async (dispatch) => {
  try {
    await dispatch(
      registerInvited(POST(API.organizations.registerInvite, payload).then(( response ) => {
        userManager.setOAuthToken(response);
        // dispatch(loadAccountDetails(history))
        dispatch(
          getAccountDetails(
            Promise.all([GET(`${API.users.base}/me`), GET(API.users.preferences('me'))]).then(
              (body) => {
                const userData = R.path([0, 'data'], body);
                userManager.setUserId(userData.id);
                const lastUsedOrganization = R.path([1, 'data', 'lastUsedOrganization'], body);
                const lastUsedProject = R.path([1, 'data', 'lastUsedProject'], body);
                mixpanel.identify(userData.id)
                mixpanel.people.set({
                  "$name": `${userData.firstName} ${userData.lastName}`,
                  "$email": userData.email,
                });
                dispatch(handleGetOrganizationOwner(getLastUsedOrganization(lastUsedOrganization).id, userData.id, 2));
                history.push(`/${getLastUsedOrganization(lastUsedOrganization).id}/projects`);
                return {
                  userData,
                  lastUsedOrganization,
                  lastUsedProject: lastUsedProject || null,
                };
              }
            )
          )
        )
        // history.push(`/${organizationId}/projects`);
        return response;
      }))
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};

/** make default ecr */
export const handleMakeDefaultRegistry = (id, payload) => async (dispatch) => {
  try {
    await dispatch(
      defaultEcrRegistry(
        PUT(API.registryIntegrations.default(id), payload).then((response) => {
          return response;
        })
      )
    );
  } catch (exception) {
    log.error('Registry Integration', exception);
    dispatch(
      showNotification(
        TOAST_TYPES.error, 'Error', 'Failed to set default registry.'
      )
    );
  }
};
