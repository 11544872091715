import { useEffect, useState } from 'react';

const useActiveTab = () => {
  const [isWindowFocused, setIsWindowFocused] = useState(document.hasFocus());
  const [tabIsFocused, setTabIsFocused] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setTabIsFocused(document.visibilityState === 'visible');
    };

    const handleFocus = () => {
      setIsWindowFocused(document.hasFocus());
    };

    const handleBlur = () => {
      setIsWindowFocused(document.hasFocus());
    };

    window.addEventListener('focus', handleFocus, true);
    window.addEventListener('blur', handleBlur, true);
    document.addEventListener('visibilitychange', handleVisibilityChange, true);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isWindowFocused, tabIsFocused };
};

export default useActiveTab;
