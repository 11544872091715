import React from 'react';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import { intercomSlice } from 'modules/thirdparty/intercom/store';
import { isFullyLoaded } from 'modules/thirdparty/intercom/utils';

import { useAppDispatch, useAppSelector } from 'app/hooks';

import type { MeData } from 'modules/thirdparty/intercom/utils';

type IntercomAction = 'noop'|'boot'|'shutdown';

export interface IProps {
    children: React.ReactElement;
}

export interface IProviderProps extends IProps {
    appId: string;
}

function getIntercomAction(booted: boolean, hasFullyLoadedData: boolean): IntercomAction {
    if (hasFullyLoadedData) {
        return booted ? 'noop' : 'boot';
    }

    return booted ? 'shutdown' : 'noop';
}

export function IntercomBoot(props: IProps): React.ReactElement {
    const dispatch = useAppDispatch();
    const intercom = useIntercom();

    const data = useAppSelector(state => state.userManager as MeData);
    const { booted } = useAppSelector(state => state.intercom);

    const intercomAction = getIntercomAction(booted, isFullyLoaded(data));

    switch (intercomAction) {
        case 'boot':
            intercom.boot({
                userId: data.intercomExternalId,
                userHash: data.intercomUserHash,
                phone: data.phoneNumber,
                name: `${data.firstName} ${data.lastName}`,
                email: data.email,
            });

            dispatch(intercomSlice.actions.boot());
            break;

        // you can trigger a non fully loaded boot by logging out
        case 'shutdown':
            intercom.shutdown();

            dispatch(intercomSlice.actions.shutdown());
            break;

        // you can trigger multiple boots by causing a full re-render
        // examples for bootedNoop: 4xx / 5xx / theme change / org change
        // examples for shutdownNoop: invalid email
        case 'noop':
        default:
            // noop
    }

    return <>{props.children}</>;
}

export function IntercomBootedProvider(props: IProviderProps): React.ReactElement {
    return <IntercomProvider appId={props.appId} autoBoot={false}>
        <IntercomBoot>
            {props.children}
        </IntercomBoot>
    </IntercomProvider>;
}
