const actionTypes = {
  CONNECT_KUBERNETES: 'CONNECT_KUBERNETES',
  DETACH_KUBERNETES: 'DETACH_KUBERNETES',
  EDIT_CLUSTER: 'EDIT_CLUSTER',
  RESET_STATE: 'RESET_STATE',
  GET_CLOUD_PROVIDERS: 'GET_CLOUD_PROVIDERS',
  GET_KUBERNETES_CLUSTER: 'GET_KUBERNETES_CLUSTER',
  RESET_ERROR_MESSAGE: 'RESET_ERROR_MESSAGE',
  VERIFY_KUBERNETES: 'VERIFY_KUBERNETES',
};

export default actionTypes;
