import React from 'react';
import PropTypes from 'prop-types';
import StyledSVG from './SVG.style';

const SVG = ({
  name,
  className,
  wrapClassName,
  width,
  height,
  defaultColor,
  forcedBgColor,
  hoverColor,
  noHover,
  noStroke,
}) => (
  <StyledSVG
    className={`svgStyle ${wrapClassName} ${name}`}
    width={width}
    height={height}
    defaultColor={defaultColor}
    forcedBgColor={forcedBgColor}
    noHover={noHover}
    hoverColor={hoverColor}
    noStroke={noStroke}
  >
    <svg className={`icon ${className}`}>
      <use xlinkHref={`#${name}`} />
    </svg>
  </StyledSVG>
);

SVG.defaultProps = {
  className: '',
  wrapClassName: '',
  width: null,
  height: null,
  defaultColor: null,
  forcedBgColor: null,
  hoverColor: null,
  noHover: false,
  noStroke: false,
};

SVG.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  wrapClassName: PropTypes.string,
  defaultColor: PropTypes.string,
  forcedBgColor: PropTypes.string,
  hoverColor: PropTypes.string,
  noHover: PropTypes.bool,
  noStroke: PropTypes.bool,
};

export default SVG;
