import React from "react";
import { Menu } from "antd";
import { LinkItem, SubMenuTitle } from "modules/sidebar/items/utils";

import type { MenuTheme } from "modules/sidebar/items/menu";

export interface ITitleProps {
    theme: MenuTheme;
}

export interface IProps {
    theme: MenuTheme;

    hasGitIntegrations: boolean;

    organizationId: number;
    currentProjectId: number;
}

export function ReportsTitle(props: ITitleProps): React.ReactElement {
    return <SubMenuTitle
        icon="bicon-metrics"
        name="Reports"
        theme={props.theme}
    />
}

export function isReportsActive(pathname: string, organizationId: number, currentProjectId: number): boolean {
    if (pathname.startsWith(`/${organizationId}/deploys`)) {
        return true;
    }

    if (pathname.startsWith(`/${organizationId}/cloud-costs`)) {
        return true;
    }

    if (pathname.startsWith(`/${organizationId}/projects/${currentProjectId}/metrics`)) {
        return true;
    }

    return false;
}

export function ReportsSubMenu(props: IProps): React.ReactElement {
    const { organizationId, currentProjectId } = props;

    // we don't have an activeKey for submenu
    // we don't show the highlighted submenu tree
    const activeKey = "";

    return <Menu.SubMenu
        title={<ReportsTitle theme={props.theme} />}
        key="reports-submenu"
        className="hasSubmenu"
    >
        <LinkItem
            currentKey={activeKey}
            key="reports-deploys"
            title="Deploys"
            icon="icon-sidebar-deploys"
            linkTo={{
                pathname: `/${organizationId}/deploys`
            }}
        />
        <LinkItem
            currentKey={activeKey}
            key="reports-cloud-costs"
            title="Cloud Costs"
            icon="icon-reports"
            linkTo={{
                pathname: `/${organizationId}/cloud-costs`
            }}
        />
        {currentProjectId && <LinkItem
            currentKey={activeKey}
            key="reports-engineering-metrics"
            title="Engineering Metrics"
            icon="bicon-metrics"
            linkTo={{
                pathname: `/${organizationId}/projects/${currentProjectId}/metrics`,
                state: {
                    hasGitIntegrations: props.hasGitIntegrations,
                },
            }}
        />}
    </Menu.SubMenu>
}
