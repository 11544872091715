import { API, GET, POST, DELETE } from 'config';
import { log, getErrorMessage } from 'utils';
import { showNotification } from 'actions/global';
import { TOAST_TYPES } from 'components';
import { createPrefixedAction } from 'modules/templates/config';
import actionTypes from './actionTypes';

const ldJsonHeader = { 'Content-Type': 'application/ld+json', Accept: 'application/ld+json' };
const textYamlHeader = { 'Content-Type': 'application/ld+json', Accept: 'text/yaml' };

export const getTemplatesRepositories = createPrefixedAction(actionTypes.GET_TEMPLATES_REPOSITORIES);
export const getPublicTemplates = createPrefixedAction(actionTypes.GET_PUBLIC_TEMPLATES);
export const getCustomTemplates = createPrefixedAction(actionTypes.GET_CUSTOM_TEMPLATES);
export const addTemplatesRepository = createPrefixedAction(actionTypes.ADD_TEMPLATES_REPOSITORY);
export const syncTemplateRepository = createPrefixedAction(actionTypes.SYNC_TEMPLATE_REPOSITORY);
export const deleteTemplateRepository = createPrefixedAction(actionTypes.DELETE_TEMPLATE_REPOSITORY);
export const getTemplatesForRepository = createPrefixedAction(actionTypes.GET_TEMPLATES_FOR_REPOSITORY);
export const getTemplateDetails = createPrefixedAction(actionTypes.GET_TEMPLATE_DETAILS);
export const getTemplateDefinition = createPrefixedAction(actionTypes.GET_TEMPLATE_DEFINITION);
export const resetViolations = createPrefixedAction(actionTypes.RESET_VIOLATIONS);
export const resetUpdatedResource = createPrefixedAction(actionTypes.RESET_UPDATED_RESOURCE);
export const resetTemplatesState = createPrefixedAction(actionTypes.RESET_TEMPLATES_STATE);
export const updateCustomRepositoriesNumber = createPrefixedAction(actionTypes.UPDATE_CUSTOM_REPO_NUMBER);

export const handleGetPublicTemplates = ({maxTemplatesOnPage}) => async (dispatch) => {
  let requestURL = API.templates.publicTemplates;
  const queryParams = window.location?.href.split('?')[1] || '';

  /** true if has search / filters active */
  if (queryParams) {
    requestURL = `${requestURL}?${queryParams}&itemsPerPage=${maxTemplatesOnPage}`;
  } else {
    requestURL = `${requestURL}?itemsPerPage=${maxTemplatesOnPage}`;
  }

  try {
    await dispatch(
      getPublicTemplates(
        GET(requestURL, ldJsonHeader).then(({ data }) => {
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Error', exception);

    if (exception.message !== 'canceled') {
      dispatch(
        showNotification(
          TOAST_TYPES.error,
          'Error',
          (exception.response && exception.response.data['hydra:description']) || getErrorMessage(exception)
        )
      );
    }
  }
};

/* used for searching custom templates */
export const handleGetCustomTemplates = (organizationId) => async (dispatch) => {
  let requestURL = API.templates.templatesOrganization(organizationId);
  const queryParams = window.location?.href.split('?')[1] || '';

  /** true if has search / filters active */
  if (queryParams) {
    requestURL = `${requestURL}?${queryParams}&itemsPerPage=50&source=private&order%5Bname%5D=asc`;
  } else {
    requestURL = `${requestURL}?itemsPerPage=50source=private&order%5Bname%5D=asc`;
  }

  try {
    await dispatch(
      getCustomTemplates(
        GET(requestURL, ldJsonHeader).then(({ data }) => {
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Error', exception);
    
    if (exception.message !== 'canceled') {
      dispatch(
        showNotification(
          TOAST_TYPES.error,
          'Error',
          (exception.response && exception.response.data['hydra:description']) || getErrorMessage(exception)
        )
      );
    }
  }
};

export const handleGetTemplatesForRepository = (id) => async (dispatch) => {
  let requestURL = API.templates.repositoryTemplates(id);
  const queryParams = window.location?.href.split('?')[1] || '';

  /** true if has search / filters active */
  if (queryParams) {
    requestURL = `${requestURL}?${queryParams}&itemsPerPage=50&source=private&order%5Bname%5D=asc`;
  } else {
    requestURL = `${requestURL}?itemsPerPage=50&source=private&order%5Bname%5D=asc`;
  }

  try {
    await dispatch(
      getTemplatesForRepository(
        GET(requestURL, ldJsonHeader).then(({ data }) => {
          return { repId: id, templates: data['hydra:member'] };
        })
      )
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};

export const handleGetTemplatesRepositories = ({organizationId, extraDataObject}) => async (dispatch) => {
  let requestURL = API.templates.templatesRepositoriesOrg(organizationId);

  requestURL = `${requestURL}?source=private`;

  try {
    await dispatch(
      getTemplatesRepositories(
        GET(requestURL, ldJsonHeader, extraDataObject).then(({ data }) => {
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Error', exception);

    if (exception.message !== 'canceled') {
      dispatch(
        showNotification(
          TOAST_TYPES.error,
          'Error',
          (exception.response && exception.response.data['hydra:description']) || getErrorMessage(exception) || 'Something went wrong'
          )
      );
    }
  }
};

export const handleAddTemplateRepository = (payload) => async (dispatch) => {
  let withSuccess = true;
  try {
    await dispatch(
      addTemplatesRepository(
        POST(API.templates.templatesRepositories, payload, ldJsonHeader).then(({ data }) => {
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Error', exception);
    withSuccess = false;
  }

  return withSuccess;
};

export const handleSyncTemplatesRepository = (organizationId) => async (dispatch) => {
  try {
    await dispatch(
      syncTemplateRepository(
        POST(API.templates.repositorySync(organizationId), ldJsonHeader).then(({ data }) => {
          if (data.status !== 'sync_failed') {
            dispatch(
              showNotification(
                TOAST_TYPES.success,
                'Templates Synced Successfully ',
                'The template repository has been successfully synchronized.'
              )
            );
          }
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Error', exception);
    dispatch(
      showNotification(
        TOAST_TYPES.error,
        'Error',
        (exception.response && exception.response.data['hydra:description']) || getErrorMessage(exception)
      )
    );
  }
};

export const handleDeleteTemplatesRepository = (id) => async (dispatch) => {
  try {
    await dispatch(
      deleteTemplateRepository(
        DELETE(API.templates.repository(id)).then(({ data }) => {
          return { ...data, deletedId: id };
        })
      )
    );
  } catch (exception) {
    log.error('Error', exception);
    dispatch(
      showNotification(
        TOAST_TYPES.error,
        'Error',
        (exception.response && exception.response.data['hydra:description']) || getErrorMessage(exception)
      )
    );
  }
};

export const handleGetTemplateDetails = (id, extraDataObject) => async (dispatch) => {
  try {
    await dispatch(
      getTemplateDetails(
        GET(API.templates.templateDetails(id), ldJsonHeader, extraDataObject).then(({ data }) => {
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};

export const handleGetTemplateDefinition = (id, extraDataObject) => async (dispatch) => {
  try {
    await dispatch(
      getTemplateDefinition(
        GET(API.templates.templateDefinition(id), textYamlHeader, extraDataObject).then(({ data }) => {
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Error', exception);
    dispatch(
      showNotification(
        TOAST_TYPES.error,
        'Error',
        (exception.response && exception.response.data['hydra:description']) || getErrorMessage(exception)
      )
    );
  }
};
