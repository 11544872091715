export const NEVER_LOADED_ID = -1;

const initialState = {
  // loading
  id: NEVER_LOADED_ID,

  // other data
  accessToken: '',
  isLoading: false,
  isDefaulting: false,
  isLoadingToken: false,
  isGeneratingToken: false,
  isGettingOrganization: false,
  customTemplatesRepositoriesCount: 0,
  users: [],
  errorCode: null,
  errorMessage: null,
  violations: [],
  inviteEmail:  null,
  inviteOrganization: null,
  inviteOrg: null,
  accountOwner: null,
  gitIntegrationsCount: null,
  updateResourceId: '',
  kubernetesIntegrationsCount: null,
  registryIntegration: null,
  isListLoading: false,

  // billing data
  billingMode: 'usage',
  billingPlanType: 'free',
  billingShowTrialBanner: false,
  billingSubscriptionEnd: 0,
};

export default initialState;
