import React from 'react';
import PropTypes from 'prop-types';
import StyledLink from './Link.style';

const Link = ({ children, className, to, target }) => (
  <StyledLink className={className} to={to} target={target}>
    {children}
  </StyledLink>
);

Link.defaultProps = {
  target: '',
  className: '',
  to: '',
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  target: PropTypes.string,
};

export default Link;
