/* eslint-disable max-lines */
import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import {
  updateIntegrationResource
} from 'actions/global'
import {
  getGitIntegrations, getGitIntegration,
  connectGit,
  gitResponse,
  gitDisconnect,
  getCloudProviders,
  connectKubernetes,
  verifyKubernetes,
  verifyRegistry,
  detachKubernetes,
  getOrganizationGitIntegrations,
  slackResponse,
  getOrgRegistryIntegrations,
  getIntegrationRepositories,
  editAmazonEcr,
  connectAmazonEcr,
  detachEcrRegistry,
  getOrgTeamsIntegrations,
  connectTeamsIntegration,
  editTeamsIntegration,
  removeTeamsIntegration,
  syncRepos,
  connectPrivateGit, getSlackIntegrations, connectSlackIntegration, getSlackChannels,
  getEnvUsage, resetErrorMessage, editCluster, setPrivateErrorMessage, saveChannel,
  deleteSlackIntegration, addWorkshopCluster,
} from '../actions';
import initialState from './initialState';

const getGitIntegrationsHandler = [
  getGitIntegrations,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      updateResourceId: '',
      repositories: payload,
    };
  },
];

const syncReposHandler = [
  syncRepos,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: false,
        isSyncing: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        isSyncing: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isSyncing: false,
      isLoading: false,
      errorMessage: '',
      updateResourceId: '',
    };
  }
]
const getOrganizationGitIntegrationsHandler = [
  getOrganizationGitIntegrations,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      integrations: payload.data,
    };
  },
];
const getIntegrationRepositoriesHandler = [
  getIntegrationRepositories,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
        isLoadingRepositories: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        isLoadingRepositories: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }
    return {
      ...state,
      isLoading: false,
      isLoadingRepositories: false,
      errorMessage: '',
      repositories: payload.data,
    };
  },
];
const connectGitHandler = [
  connectGit,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isConnecting: true,
      };
    }

    if (error) {
      return {
        ...state,
        isConnecting: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isConnecting: false,
      errorMessage: '',
    };
  },
];

const gitResponseHandler = [
  gitResponse,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isGitResponseLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isGitResponseLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isGitResponseLoading: false,
      errorMessage: '',
    };
  },
];
const slackResponseHandler = [
  slackResponse,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isGitResponseLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isGitResponseLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isGitResponseLoading: false,
      errorMessage: '',
    };
  },
];
const gitDisconnectHandler = [
  gitDisconnect,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }
    // const { id, status } = payload;
    const index = R.findIndex(R.propEq('id', payload))(state.repositories);

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      repositories: R.adjust(index, (item) => ({ ...item, status: 'detached' }), state.repositories),
    };
  },
];

const getCloudProvidersHandler = [
  getCloudProviders,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      cloudProviders: payload.cloudProviders || [],
      kubernetes: payload.kubernetes || [],
      updateResourceId: '',
    };
  },
];

const connectKubernetesHandler = [
  connectKubernetes,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      kubernetes: R.append(payload, state.kubernetes),
    };
  },
];

const verifyKubernetesHandler = [
  verifyKubernetes,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    const { id, status } = payload;
    const index = R.findIndex(R.propEq('id', id))(state.kubernetes);

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      kubernetes: R.adjust(index, (item) => ({ ...item, status }), state.kubernetes),
    };
  },
];

const editClusterHandler = [
  editCluster,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data'], payload),
      };
    }

    const { id, status } = payload;
    const index = R.findIndex(R.propEq('id', id))(state.kubernetes);

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      kubernetes: R.adjust(index, (item) => ({ ...item, status }), state.kubernetes),
    };
  },
];

const detachKubernetesHandler = [
  detachKubernetes,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    const { id, status } = payload;
    const index = R.findIndex(R.propEq('id', id))(state.kubernetes);

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      kubernetes: R.adjust(index, (item) => ({ ...item, status }), state.kubernetes),
    };
  },
];

/** get ECR registries */
const getOrgRegistryIntegrationsHandler = [
  getOrgRegistryIntegrations,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoadingRegistries: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoadingRegistries: false,
      };
    }

    return {
      ...state,
      isLoadingRegistries: false,
      registries: payload.data,
    };
  },
];

/** verify ECR */
const verifyRegistryHandler = [
  verifyRegistry,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
        isVerifying: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        isVerifying: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    const { id, status } = payload;
    const index = R.findIndex(R.propEq('id', id))(state.registries);

    return {
      ...state,
      isLoading: false,
      isVerifying: false,
      errorMessage: '',
      registries: R.adjust(index, (item) => ({ ...item, status }), state.registries),
    };
  },
];



/** connect ECR */
const connectAmazonEcrHandler = [
  connectAmazonEcr,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isConnectingEcr: true,
      };
    }
    if (error) {
      return {
        ...state,
        isConnectingEcr: false,
        errorMessage: R.path(['response', 'data'], payload),
      };
    }

    return {
      ...state,
      isConnectingEcr: false,
      errorMessage: '',
      registries: R.append(payload, state.registries),
    };
  },
];

/** edit ECR */
const editEcrRegistryHandler = [
  editAmazonEcr,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isConnectingEcr: true,
      };
    }

    if (error) {
      return {
        ...state,
        isConnectingEcr: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }
    
    const { id, status, name } = payload.data;
    const index = R.findIndex(R.propEq('id', id))(state.registries);
    const registries = R.adjust(index, (item) => ({ ...item, status, name}), state.registries);

    return {
      ...state,
      isConnectingEcr: false,
      errorMessage: '',
      registries,
    };
  },
];

/** detach ECR */
const detachEcrRegistryHandler = [
  detachEcrRegistry,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
      };
    }
    
    const { id } = payload.data;
    const index = R.findIndex(R.propEq('id', id))(state.registries);
    const registries = R.adjust(index, (item) => ({ ...item, status: 'detached' }), state.registries);

    return {
      ...state,
      isLoading: false,
      registries,
    };
  },
];

/** get Teams integrations */
const getOrgTeamsIntegrationsHandler = [
  getOrgTeamsIntegrations,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoadingTeamsIntegrations: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoadingTeamsIntegrations: false,
      };
    }

    return {
      ...state,
      isLoadingTeamsIntegrations: false,
      teamsIntegrations: payload.data,
    };
  },
];

/** connect Teams integration */
const connectTeamsIntegrationHandler = [
  connectTeamsIntegration,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isConnectingTeamsIntegration: true,
      };
    }
    if (error) {
      return {
        ...state,
        isConnectingTeamsIntegration: false,
        errorMessage: R.path(['response', 'data'], payload),
      };
    }

    return {
      ...state,
      isConnectingTeamsIntegration: false,
      errorMessage: '',
      teamsIntegrations: R.append(payload, state.teamsIntegrations),
    };
  },
];

/** edit Teams integration */
const editTeamsIntegrationHandler = [
  editTeamsIntegration,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isConnectingTeamsIntegration: true,
      };
    }

    if (error) {
      return {
        ...state,
        isConnectingTeamsIntegration: false,
        errorMessage: R.path(['response', 'data'], payload),
      };
    }

    const { id, status, name } = payload;
    const index = R.findIndex(R.propEq('id', id))(state.teamsIntegrations);
    const teamsIntegrations = R.adjust(index, (item) => ({ ...item, status, name}), state.teamsIntegrations);

    return {
      ...state,
      isConnectingTeamsIntegration: false,
      errorMessage: '',
      teamsIntegrations,
    };
  },
];

/** remove Teams integration */
const removeTeamsIntegrationHandler = [
  removeTeamsIntegration,
  (state, action) => {
    const { ready, error } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
      };
    }

    return {
      ...state,
      isLoading: false,
    };
  },
];

/** connect private git */
const connectPrivateGitHandler = [
  connectPrivateGit,
  (state, action) => {
    const { ready, error } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
      };
    }

    return {
      ...state,
      isLoading: false,
    };
  },
];

const getEnvUsageHandler = [
  getEnvUsage,
  (state,actions) => {
    const {ready, error, payload} = actions;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: true,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      envUsage: payload,
    };
  }
]
const updateIntegrationResourceHandler = [
  updateIntegrationResource,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      updateResourceId: payload,
    };
  },
];

const getGitIntegrationHandler = [
  getGitIntegration,
  (state,actions) => {
    const {ready, error, payload} = actions;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: true,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      currentIntegration: payload,
    };
  }
]

const getSlackIntegrationsHandler = [
  getSlackIntegrations,
  (state,actions) => {
    const {ready, error, payload} = actions;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      slackIntegrations: payload[0],
    };
  }
]
const saveChannelHandler = [
  saveChannel,
  (state,actions) => {
    const {ready, error, payload} = actions;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: true,
        errorMessage: R.path(['response'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      slackIntegrations: payload,
    };
  }
]
const deleteSlackIntegrationHandler = [
  deleteSlackIntegration,
  (state,actions) => {
    const {ready, error, payload} = actions;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: true,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
    };
  }
]
const getSlackChannelsHandler = [
  getSlackChannels,
  (state,actions) => {
    const {ready, error, payload} = actions;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: true,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }
    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      channels: payload.channels,
    };
  }
]
const connectSlackIntegrationHandler = [
  connectSlackIntegration,
  (state,actions) => {
    const {ready, error, payload} = actions;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: true,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: '',
    };
  }
]

const resetErrorMessageHandler = [
  resetErrorMessage,
  (state) => {
    return {
      ...state,
      errorMessage: null,
      privateGitErrorMessage: null,
    };
  },
];
const addWorkshopClusterHandler = [
  addWorkshopCluster,
  (state, actions) => {
    const {ready, error} = actions;
    if (!ready) {
      return {
        ...state,
        isAddingCluster: true,
      };
    }

    if (error) {
      return {
        ...state,
        isAddingCluster: false
      };
    }

    return {
      ...state,
      isAddingCluster: false,
    };
  }
]
const resetPrivateGitErrorMessage = [
  setPrivateErrorMessage,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      privateGitErrorMessage: payload.message,
    };
  },
]

const reducer = handleActions(
  new Map([
    resetPrivateGitErrorMessage,
    getGitIntegrationsHandler,
    getOrganizationGitIntegrationsHandler,
    getIntegrationRepositoriesHandler,
    connectGitHandler,
    gitResponseHandler,
    gitDisconnectHandler,
    getCloudProvidersHandler,
    connectKubernetesHandler,
    verifyKubernetesHandler,
    detachKubernetesHandler,
    updateIntegrationResourceHandler,
    syncReposHandler,
    getEnvUsageHandler,
    getOrgRegistryIntegrationsHandler,
    connectAmazonEcrHandler,
    editEcrRegistryHandler,
    verifyRegistryHandler,
    detachEcrRegistryHandler,
    getOrgTeamsIntegrationsHandler,
    connectTeamsIntegrationHandler,
    editTeamsIntegrationHandler,
    removeTeamsIntegrationHandler,
    resetErrorMessageHandler,
    editClusterHandler,
    connectPrivateGitHandler,
    getGitIntegrationHandler,
    getSlackIntegrationsHandler,
    connectSlackIntegrationHandler,
    slackResponseHandler,
    getSlackChannelsHandler,
    saveChannelHandler,
    deleteSlackIntegrationHandler,
    addWorkshopClusterHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
