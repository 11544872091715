export const actionTypes = {
  GET_ORGANIZATIONS: 'GET_ORGANIZATIONS',
  GET_UPDATED_ORGANIZATIONS: 'GET_UPDATED_ORGANIZATIONS',
  GET_ORGANIZATION: 'GET_ORGANIZATION',
  GET_ORGANIZATION_DETAILS: 'GET_ORGANIZATION_DETAILS',
  GET_ORGANIZATION_USERS: 'GET_ORGANIZATION_USERS',
  ADD_ORGANIZATION: 'ADD_ORGANIZATION',
  ADD_ORGANIZATION_MEMBER: 'ADD_ORGANIZATION_MEMBER',
  EDIT_ORGANIZATION: 'EDIT_ORGANIZATION',
  DELETE_ORGANIZATION: 'DELETE_ORGANIZATION',
  DEACTIVATE_ORGANIZATION_MEMBER: 'DEACTIVATE_ORGANIZATION_MEMBER',
  GET_TIMEZONES: 'GET_TIMEZONES',
  GET_USERS: 'GET_USERS',
  GET_UPDATED_USERS: 'GET_UPDATED_USERS',
  ADD_USER: 'ADD_USER',
  EDIT_USER: 'EDIT_USER',
  DELETE_USER: 'DELETE_USER',
  RESET_STATE: 'RESET_STATE',
};

export default actionTypes;
