import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'hocs';
import { connect } from 'react-redux';
import { logOut } from 'modules/userManager/actions';
import userManager from 'modules/userManager';
import buttonLoaderWhite from 'assets/images/buttonLoaderWhite.svg';
import SelectArrow from 'components/selectArrow';
import Select, { SelectOption } from 'theme/uielements/select';
import Form from 'theme/uielements/form';
import Input from 'theme/uielements/input';
import Button from 'theme/uielements/button';
import StyledCreateOrganizationForm from './CreateOrganizationForm.style';
import { getTimezone } from '../../../modules/userManager/helpers';

const Option = SelectOption;
const timezone = getTimezone();

const CreateOrganizationForm = ({
  actions,
  isCreatingOrganization,
  userId,
  changeOrganization,
  noOrganizationsFound,
}) => {
  const charLimit = { min: 3, max: 30 };
  const [orgName, setOrgName] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [orgNameError, setOrgNameError] = useState(false);
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);

  /** validate organization name length */
  const validateOrgName = () => {
    if (orgName.length < charLimit.min) {
      setOrgNameError(`Organization name cannot contain less than ${charLimit.min} characters.`);
    } else if (orgName.length > charLimit.max) {
      setOrgNameError(`Organization name cannot contain more than ${charLimit.max} characters.`);
    }

    return orgName.length >= charLimit.min && orgName.length <= charLimit.max;
  };

  const validateSelect = () => {
    return companySize !== '';
  };

  /** on organization name change */
  const handleInputChange = (value) => {
    setOrgName(value);
    setOrgNameError(false);
  };

  /** submit organization */
  const onFormSubmit = () => {
    const validForm = validateOrgName() && validateSelect();

    if (validForm) {
      actions
        .handleCreateOrganization(orgName, companySize, timezone, userId, changeOrganization)
        .then(actions.hideDialog);
    }
  };

  /** hide dialog */
  const onFormCancel = () => {
    if (noOrganizationsFound) {
      userManager.logout(logOut);
    } else {
      actions.hideDialog();
    }
  };

  /** listen for value change and toggle submit button visibility */
  useEffect(() => {
    setIsSubmitButtonEnabled(orgName.length >= 3 && companySize !== '');
  }, [orgName, companySize]);

  return (
    <StyledCreateOrganizationForm>
      <p className="mb-3">Enter the name of the Organization.</p>
      <Form.Item className="mb-3">
        <Input
          className={orgNameError ? 'error' : ''}
          name="variableName"
          id="variableName"
          value={orgName}
          onChange={({ target }) => handleInputChange(target.value)}
        />
        <label htmlFor="variableName" className={orgName && orgName.length ? 'small filled' : 'small'}>
          Organization name
        </label>
        {orgNameError.length > 0 && <p className="labelError">{orgNameError}</p>}
      </Form.Item>
      <div id='company-size'>
        <Select
          showSearch={false}
          dropdownAlign={{ offset: [0, -1] }}
          suffixIcon={<SelectArrow designType="new" />}
          className="w-100"
          optionFilterProp="children"
          placeholder="Select company size"
          onChange={setCompanySize}
          getPopupContainer={() => document.getElementById('company-size')}
        >
          <Option value="1-4">1-4 employees</Option>
          <Option value="5-9">5-9 employees</Option>
          <Option value="10-29">10-29 employees</Option>
          <Option value="30-49">30-49 employees</Option>
          <Option value="50-199">50-199 employees</Option>
          <Option value="200-499">200-499 employees</Option>
          <Option value="500-999">500-999 employees</Option>
          <Option value="1000+">1000+ employees</Option>
        </Select>
      </div>

      <div className="d-flex justify-content-end align-items-center mt-4">
        <Button key="back" type="info" className="mb-4" onClick={onFormCancel}>
          {noOrganizationsFound ? 'Log out' : 'Cancel'}
        </Button>
        <Button
          type="primary filled"
          className={isCreatingOrganization ? 'ml-2 mb-4 loading-anim' : 'ml-2 mb-4'}
          disabled={!isSubmitButtonEnabled || isCreatingOrganization}
          onClick={onFormSubmit}
        >
          {isCreatingOrganization && (
            <object className="button-loader" type="image/svg+xml" data={buttonLoaderWhite}>
              svg-animation
            </object>
          )}
          <span>Create Organization</span>
        </Button>
      </div>
    </StyledCreateOrganizationForm>
  );
};

CreateOrganizationForm.defaultProps = {
  noOrganizationsFound: false,
};

CreateOrganizationForm.propTypes = {
  userId: PropTypes.number.isRequired,
  isCreatingOrganization: PropTypes.bool.isRequired,
  noOrganizationsFound: PropTypes.bool,
  actions: PropTypes.shape({
    hideDialog: PropTypes.func.isRequired,
    handleCreateOrganization: PropTypes.func.isRequired,
  }).isRequired,
  changeOrganization: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { userId, isCreatingOrganization } = state.userManager;

  return {
    isCreatingOrganization,
    userId,
  };
};


export default compose(connect(mapStateToProps, null))(CreateOrganizationForm);
