import styled, { css } from 'styled-components';

const StyledLayout = styled.div`
  ${({ theme }) => {
    const { palette } = theme;
    return css`
    // navbar hardcoding sidebar width..
      &.static-sidebar {
        .environmentListPipelineDetailsHead {
          left: 272px;
        }

        .environmentPipelineDetailsHead {
          padding-left: 272px;
        }
      }

      .layout-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
      }

      .ant-layout {
        background: #fff;
        // ::-webkit-scrollbar{z-index: 1111; display: block;}
        // // &::-webkit-scrollbar-track-piece{z-index: 1111}
        // // &::-webkit-scrollbar-thumb:vertical{z-index: 1111}
        #dark & {
          background: ${palette.background[0]};
          &::-webkit-scrollbar {
            background-color: transparent;
          }
          &::-webkit-scrollbar-track-piece {
          }
          &::-webkit-scrollbar-thumb:vertical {
            background-color: ${palette.grays[4]};
            border: 4px solid transparent;
            border-radius: 9px;
            background-clip: content-box;
          }
        }
        &.layout-content {
          width: 100%;
          overflow: auto;
          overflow-x: hidden;
          @media only screen and (min-width: 768px) {
            width: auto;
          }
          .content {
            padding: 32px 0 20px;
            > div:not(.hasCustomPadding) {
              padding: 0 17px;
              @media (max-width: 768px) {
                padding: 0 4px;
              }
            }
          }
        }
      }
    `;
  }}
`;

StyledLayout.displayName = 'StyledLayout';
export default StyledLayout;
