import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { SVG } from 'components';
import compose from "hocs";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import STATUS_TYPES from './constants';
import StyledStatusLabel from './StatusLabel.style';



const StatusLabel = ({ children, status, size, type, className, mode }) => {
  return (
    <StyledStatusLabel status={status} className={`${status} ${className}`} size={size} type={type} mode={mode}>
      {type === 'arrows' && (
        <>
          <SVG className="f12" name={`bicon-${status === 'synced' ? 'up' : 'down'}-trend-icon`} />
        </>
      )}
      {type && type !== 'arrows' && (
        <>
          {status === 'synced' ? (
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6 9.5V2.5M6 2.5L2.5 6M6 2.5L9.5 6"
                stroke="#03B364"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6 2.5V9.5M6 9.5L9.5 6M6 9.5L2.5 6"
                stroke="#FFB300"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </>
      )}

      {children}
    </StyledStatusLabel>
  );
};

StatusLabel.defaultProps = {
  className: '',
  children: null,
  status: STATUS_TYPES.draft,
  size: null,
  type: null,
};

StatusLabel.propTypes = {
  mode: PropTypes.string.isRequired,
  children: PropTypes.node,
  size: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.oneOf([
    STATUS_TYPES.active,
    STATUS_TYPES.aborted,
    STATUS_TYPES.applied,
    STATUS_TYPES.disabled,
    STATUS_TYPES.running,
    STATUS_TYPES.deployed,
    STATUS_TYPES.in_progress,
    STATUS_TYPES.partially_running,
    STATUS_TYPES.partially_deployed,
    STATUS_TYPES.running_with_errors,
    STATUS_TYPES.deployed_with_errors,
    STATUS_TYPES.partially_running_with_errors,
    STATUS_TYPES.partially_deployed_with_errors,
    STATUS_TYPES.ready,
    STATUS_TYPES.impaired,
    STATUS_TYPES.detached,
    STATUS_TYPES.disconnected,
    STATUS_TYPES.draft,
    STATUS_TYPES.error,
    STATUS_TYPES.fail,
    STATUS_TYPES.failing,
    STATUS_TYPES.success,
    STATUS_TYPES.stopped,
    STATUS_TYPES.synced,
    STATUS_TYPES.out_of_sync,
    STATUS_TYPES.failed,
  ]),
};
const mapStateToProps = (state) => {
  const { mode } = state.global;
  return {
    mode
  };
};
export default compose(connect(mapStateToProps, null), withRouter, memo)(StatusLabel);
