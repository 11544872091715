const initialState = {
  isLoading: true,
  isLoadingComponents: true,
  isLoadingEnvList: true,
  isLoadingLogs: true,
  isConnecting: false,
  isLoadingClusters: false,
  isUpdatingTimeframes: false,
  isSavingBuildSettings: false,
  isLoadingPipeline: true,
  isCreatingEnv: false,
  isCloningEnv: false,
  isDataFetching: false,
  kubernetesIntegrations: [],
  environments: [],
  applications: [],
  components: [],
  componentTopics: [],
  componentsYaml: '',
  databases: [],
  services: [],
  environment: {
    id: null,
    labels: {},
    unique: '',
    name: '',
    type: '',
    autoUpdate: false,
    project: '',
    parentEnvironment: null,
    status: '',
    createdAt: '',
    updatedAt: '',
    hasDraft: true,
    hasComponents: false,
    organization: '',
    timeframes: [],
    addedTimeframes: [],
    markedToDeleteTimeframes: [],
    createEphemeralOnPrCreate: false,
    aggregatedOperationStatus: {},
    destroyEphemeralOnPrClose: false,
    componentDefinition: {},
    outputValues: [],
    checkedComponentsNames: [],
    checkedComponentsList: [],
    k8sResDefinition: {},
    /** component pipeline */
    isLoadingPipeline: true,
    pipelineData: {
      workflowJobs: [],
    },
    pipelineCompTopic: '',
    pipelineCompJobTopics: [],
    updatePipelineResourceId: '',
    updatePipelineJobResourceId: '',

    isLoadingDefinition: false,
    isLoadingK8sResDefinition: false,
    isSavingUrlHandle: false,
    draftComponentsResponse: {
      urlHandle: '',
    },
    templateDetails: null,
    violations: [],
  },
  environmentsNo: null,
  variables: [],
  updateResourceId: '',
  updateComponentResourceId: '',
  errorMessage: null,
  errorUpdateEnvironment: false,
  isUpdatingEnvironment: false,
  updatedEnvSuccess: true,
  events: [],
  sources: [],
  sourcesCount: 0,
  pipelines: [],
  pipeline: {},
  pipelinesNo: 0,
  pipelinesUsers: [],
  pipelinesUsersCount: 0,
  event: {},
  eventLogs: [],
  manifests: null,
  manifestFileName: 'manifest.yaml',
  isSearch: false,
  yamlPublished: false,
  isPublishing: false,
  isDeploying: false,
  isDeploySuccess: false,
  isComponentAdding: false,
  moduleUses: null,
  moduleResource: null,
  stopUpdate: false,
  secretValues: [],
  secretValue: null,
  violations: [],
  componentsResources: [],
  ownershipGroups: {},
  componentsResourcesIndex: [],
  isComponentResourceLoading: false,
  isLoadingOutputValues: false,
  isLoadingExportedVars: false,
  terraformResources: [],
  terraformResourcesIndex: [],
  isTerraformResourceLoading: false,
  moduleOutputValues: null,
  exportedVariables: null,
  isModuleValuesLoading: false,
  allComponents: null,
  deployingIds: [],
  isEnvVariablesLoading: false,
  deployPublishingError: false,
  publishingError: null,
  environmentHasUpdates: false,
  environmentsCosts: null,
  isLoadingCosts: true,
  isApplyingClusterConfig: false,
  interest: null,
  isRemovingComponent: false,
};

export default initialState;
