import styled, { css } from 'styled-components';

const StyledProjects = styled.div`
  ${() => {
    return css`
      padding: 10px 0;
      .page-title {
        margin-bottom: 48px;
        @media (max-width: 768px){
          margin-bottom: 24px;
        }
      }
    `;
  }}
`;

StyledProjects.displayName = 'StyledProjects';
export default StyledProjects;
