/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { NotificationCenterItem, useNotificationCenter } from 'react-toastify/addons/use-notification-center';
import styled from 'styled-components';
import Dropdown from 'theme/uielements/dropdown';
import { TypeOptions } from 'react-toastify';
import { SVG, Switch } from 'components';
import Button from 'theme/uielements/button';
import Trigger from './Trigger';
import TimeTracker from './TimeTracker';
import ItemActions from './ItemActions';
import useTabActive from './useActiveTab';

export type IsWindowFocused = boolean;

const variants = {
  container: {
    open: {
      y: 30,
      opacity: 1,
      display: 'block',
    },
    closed: {
      y: 10,
      opacity: 0,
      transitionEnd: {
        display: 'none',
      },
    },
  },
  // used to stagger item animation when switching from closed to open and vice versa
  content: {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2, staggerDirection: 0 },
    },
    closed: {
      transition: { staggerChildren: 0.05, delayChildren: 0, staggerDirection: -1 },
    },
  },
  item: {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000, velocity: 0 },
      },
    },
  },
};

const UnreadFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  label {
    color: #7f8080;
    cursor: pointer;

    #dark & {
      color: #acacac;
    }
  }
`;

const Container = styled(motion.aside)`
  position: relative;
  width: min(60ch, 100ch);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 16px rgba(0, 0, 0, 0.1);
  background: #fff;

  #dark & {
    background: #1c1c1c;
  }
`;

const Header = styled.header`
  color: #333333;
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;

  h3 {
    color: #333333;
  }

  #dark & {
    color: #fff;
    border-color: #333333;

    h3 {
      color: #fff;
    }
  }
`;

const Footer = styled.footer`
  color: #333333;
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #e8e8e8;

  #dark & {
    color: #fff;
    border-color: #333333;
  }
`;

const Content = styled(motion.section)`
  position: relative;
  height: 405px;
  overflow-y: auto;
  overflow-x: hidden;
  color: #000;
  padding: 0.2rem;

  h4 {
    margin: 0;
    text-align: center;
    padding: 2rem;
  }

  #dark & {
    /** Start of scroll bar */
    /* width */
    ::-webkit-scrollbar {
      width: 8px;
    }
    /* parent */
    ::-webkit-scrollbar-track {
      background: #222222;
    }
    /* normal */
    ::-webkit-scrollbar-thumb {
      background: #808080;
      border-radius: 10px;
    }
    /* on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #8f8f8f;
    }
    /* End of scroll bar */
  }

  .ant-empty {
    margin: 0 8px;
    font-size: 14px;
    line-height: 1.5715;
    text-align: center;
  }
  .ant-empty-image {
    height: 100px;
    opacity: 0.7;
    margin-bottom: 8px;
  }
  .ant-empty-image img {
    height: 100%;
  }
  .ant-empty-image svg {
    height: 100%;
    margin: auto;
  }
  .ant-empty-footer {
    margin-top: 16px;
  }
  .ant-empty-normal {
    margin: 32px 0;
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-empty-normal .ant-empty-image {
    height: 40px;
  }
  .ant-empty-small {
    margin: 8px 0;
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-empty-small .ant-empty-image {
    height: 35px;
  }
  .ant-empty-img-default-ellipse {
    fill: #f5f5f5;
    fill-opacity: 0.8;
  }
  .ant-empty-img-default-path-1 {
    fill: #aeb8c2;
  }
  .ant-empty-img-default-path-2 {
    fill: url('#linearGradient-1');
  }
  .ant-empty-img-default-path-3 {
    fill: #f5f5f7;
  }
  .ant-empty-img-default-path-4 {
    fill: #dce0e6;
  }
  .ant-empty-img-default-path-5 {
    fill: #dce0e6;
  }
  .ant-empty-img-default-g {
    fill: #fff;
  }
  .ant-empty-img-simple-ellipse {
    fill: #f5f5f5;
  }
  .ant-empty-img-simple-g {
    stroke: #d9d9d9;
  }
  .ant-empty-img-simple-path {
    fill: #fafafa;
  }
  .ant-empty-rtl {
    direction: rtl;
  }
`;

const Title = styled.div`
  margin-bottom: 12px;
  color: #333333;
  font-size: 14px;
  font-family: 'Circular-Bold', sans-serif;
  line-height: 20px;
  margin-top: 0px;
  letter-spacing: -0.02em;

  #dark & {
    color: #fff;
  }
`;

const Description = styled.div`
  color: #666;
  font-size: 14px;
  line-height: 18px;
  #dark & {
    color: #b3b3b3;
  }
`;

const IconWrapper = styled.div`
  display: flex;
`;

const Item = styled(motion.article)`
  display: grid;
  grid-template-columns: 25px 1fr 40px;
  gap: 8px;
  padding: 0.8rem;
  background: #fff;
  border-radius: 8px;
  transition: background 0.2s ease-in-out;
  border: 1px solid #e8e8e8;

  #dark & {
    background: #1c1c1c;
    border-color: #333334;
  }
`;

export default function NotificationCenter({
  userId,
  currentOrganizationId,
}: {
  userId: number;
  currentOrganizationId: number;
}) {
  const MAX_NOTIFICATIONS = 10;
  const STORAGE_KEY = `notifications:${currentOrganizationId}:${userId}`;

  // custom hooks
  const { isWindowFocused } = useTabActive();
  const {
    notifications: apiNotifications,
    add, update,
    clear, markAllAsRead,
    markAsRead,
    remove,
  } = useNotificationCenter();

  const isSyncing = useRef(false);
  const isFirstRender = useRef(true);

  const [showUnreadOnly, toggleFilter] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // limit the number of notifications to MAX_NOTIFICATIONS
  if (apiNotifications.length > MAX_NOTIFICATIONS) {
    apiNotifications.length = MAX_NOTIFICATIONS;
  }

  // used to avoid closure issues
  const toastifyNotifications = useRef(apiNotifications);

  // storedNotifications is used to keep track of the notifications that are currently being store in the localStorage
  const [latestStoredState, setLatestStoredState] = useState(JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]'));

  // unreadCount is used to keep track of the number of unread notifications
  const unreadCount = useMemo(
    () => latestStoredState.filter((item: NotificationCenterItem) => !item.read).length,
    [latestStoredState]
  );

  const storedLastFocusedAt = parseFloat(localStorage.getItem('lastFocusedAt') || '0');
  const [lastFocusedAt, setLastFocusedAt] = useState(Date.now());
  const isLastFocused = lastFocusedAt === storedLastFocusedAt;

  window.isWindowFocused = isWindowFocused;

  useEffect(() => {
    if (isWindowFocused) {
      const now = Date.now();
      setLastFocusedAt(Date.now());

      if (storedLastFocusedAt < now) {
        localStorage.setItem('lastFocusedAt', JSON.stringify(now));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWindowFocused]);

  useEffect(() => {
    // ** note: this will also run when ia add, update, remove a notification..
    if (!isFirstRender.current && !isSyncing.current) {
      let sourceOfNewItem = '';

      // create a new array of notifications with the correct shape
      const updatedNotifications = apiNotifications.map((item: NotificationCenterItem) => {
        const isNewItem = !latestStoredState.some((itemB: NotificationCenterItem) => item.id === itemB.id);
        const storedItem = latestStoredState.find((itemB: NotificationCenterItem) => item.id === itemB.id);
        const myItem = item;

        myItem.read = !!item.read;

        const getReadStatus = () => {

          if (item.type === 'info') {
            return true;
          }

          // @ts-expect-error Why is this not properly typed?
          if (isNewItem && item?.data?.source === 'self') {
            return true;
          }

          if (isNewItem) {
            return isWindowFocused;
          }

          return storedItem.read || myItem.read !== false;
        };

        const updatedItem = {
          ...myItem,
          content: {
            ...myItem.data,
            source: 'localStorage',
          },
          read: getReadStatus(),
        };

        if (isNewItem) {
          // @ts-expect-error Why is this not properly typed?
          sourceOfNewItem = myItem?.data?.source;
        }

        return updatedItem;
      });

      const toBeRemoved = apiNotifications.filter(
        (item) => !updatedNotifications.some((itemB) => item.id === itemB.id)
      );

      isSyncing.current = true;
      // remove items that are no longer needed from the toastify API
      toBeRemoved.forEach((item) => remove(item.id));

      isSyncing.current = false;

      if (isLastFocused || sourceOfNewItem === 'self') {
        // update the stored notifications (used to render the notifications)
        setLatestStoredState(updatedNotifications);
        try {
          window.localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedNotifications));
        } catch(error) {
          if (error instanceof TypeError) {
            if (error.message.startsWith('Converting circular structure to JSON')) {
              throw new Error('Converting circular structure to JSON... did you toast during component render? Try wrapping in useEffect()');
            }
          }
          throw error;
        }
      }

      const commonItems = apiNotifications.filter((item1) => {
        return updatedNotifications.some((item2) => item2.id === item1.id);
      });

      // avoid closure issues
      toastifyNotifications.current = commonItems;
    }

    isFirstRender.current = false;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiNotifications]);

  const syncAPI = (event: StorageEvent, KEY: string) => {
    if (event.key === KEY) {
      isSyncing.current = true;

      const eventValues = JSON.parse(event.newValue || '[]');

      // marked for deletion
      const itemsToBeRemoved = toastifyNotifications.current.filter(
        (item) => !eventValues.some((itemB: NotificationCenterItem) => itemB.id === item.id)
      );

      // marked for addition
      const itemsToBeAdded = eventValues.filter(
        (item: NotificationCenterItem) =>
          !toastifyNotifications.current.some((itemB: NotificationCenterItem) => itemB.id === item.id)
      );

      // marked for update
      const itemsToBeUpdated = eventValues.filter((item: NotificationCenterItem) =>
        toastifyNotifications.current.some((itemB: NotificationCenterItem) => itemB.id === item.id)
      );

      // update items that have changed
      itemsToBeUpdated.forEach((item: NotificationCenterItem) => {
        update(item.id, item);
      });

      // remove items that are no longer in the storage
      itemsToBeRemoved.forEach((item) => {
        remove(item.id);
      });

      // add items that are in the storage but not in the toastify API
      itemsToBeAdded.forEach(add);

      // update the stored notifications (used to render the notifications in current tab)
      setLatestStoredState(eventValues);
      toastifyNotifications.current = eventValues;
    }

    isSyncing.current = false;
  };

  useEffect(() => {
    // On first load, add all the notifications from the localStorage to the toastify API
    latestStoredState.forEach(add);

    // events are triggered on all tabs except the one that made the change
    window.addEventListener('storage', (event) => {
      syncAPI(event, STORAGE_KEY);
    });
    return () => {
      window.removeEventListener('storage', (event) => {
        syncAPI(event, STORAGE_KEY);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="p-relative d-flex mr-3"
      id="notificationsContainer"
      style={{
        padding: '8px 24px',
        borderRight: '1px solid #999',
      }}
    >
      <Dropdown
        overlay={() => (
          <Container initial={false} variants={variants.container} animate="open">
            <Header>
              <h3>Notifications</h3>
              <UnreadFilter>
                <label htmlFor="unread-filter">Only show unread</label>
                <Switch checked={showUnreadOnly} onChange={() => toggleFilter(!showUnreadOnly)} />
              </UnreadFilter>
            </Header>
            <AnimatePresence>
              <Content variants={variants.content} animate="open">
                {(!latestStoredState.length || (unreadCount === 0 && showUnreadOnly)) && (
                  <motion.div
                    className="text-center pt-4"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <div className="ant-empty-image mt-5">
                      <svg className="ant-empty-img-simple" width="184" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                        <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                          <ellipse className="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7" />
                          <g className="ant-empty-img-simple-g" fillRule="nonzero">
                            <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z" />
                            <path
                              d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                              className="ant-empty-img-simple-path"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <p className="mt-3">Your inbox is empty.</p>
                  </motion.div>
                )}
                <AnimatePresence>
                  {(showUnreadOnly
                    ? latestStoredState.filter((item: { read: boolean }) => !item.read)
                    : latestStoredState
                  ).map(
                    (notification: {
                      id: string;
                      data?: {
                        title?: string | undefined;
                        content?: string | undefined;
                      };
                      createdAt: number;
                      type: TypeOptions | undefined;
                      read: boolean;
                    }) => {
                      return (
                        <motion.div
                          key={notification.id}
                          layout
                          initial={{ scale: 0.4, opacity: 0, y: 50 }}
                          exit={{
                            scale: 0,
                            opacity: 0,
                            transition: { duration: 0.2 },
                          }}
                          animate={{ scale: 1, opacity: 1, y: 0 }}
                          style={{ padding: '0.8rem' }}
                        >
                          <Item key={notification.id} variants={variants.item}>
                            <IconWrapper>
                              <svg className="icon f20">
                                <use xlinkHref={`#icon-toast-${notification.type}`} />
                              </svg>
                            </IconWrapper>
                            <div>
                              <div>
                                <Title>{notification.data?.title || ''}</Title>
                                <Description>{notification.data?.content || ''}</Description>
                              </div>
                              <TimeTracker createdAt={notification.createdAt} />
                            </div>
                            <ItemActions notification={notification} markAsRead={markAsRead} remove={remove} />
                          </Item>
                        </motion.div>
                      );
                    }
                  )}
                </AnimatePresence>
              </Content>
            </AnimatePresence>
            <Footer>
              {unreadCount > 0 && (
                <Button type="info" onClick={markAllAsRead}>
                  <SVG name="bicon-check-square" className="f18 mr-1" defaultColor="#333" noHover />
                  Mark all as read {unreadCount > 0 && `(${unreadCount})`}
                </Button>
              )}
              <Button type="info" onClick={clear} disabled={apiNotifications.length <= 0} className="ml-1">
                <SVG name="bicon-delete" className="f18 mr-1" defaultColor="#333" noHover />
                Remove all
              </Button>
            </Footer>
          </Container>
        )}
        trigger={['click']}
        placement="bottomRight"
        getPopupContainer={() => document.getElementById('notificationsContainer')}
      >
        <Trigger onClick={() => setIsOpen(!isOpen)} count={unreadCount} />
      </Dropdown>
    </div>
  );
}
