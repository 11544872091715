/* eslint-disable max-lines */
import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import { isBlank } from "utils";
import {
  updateEnvironmentResource,
  updateComponentsResource,
  updatePipelineResource,
  manuallyUpdateComponentPipeTopic,
  updateConfigInterest
} from 'actions/global';
import {
  publishConfiguration,
  saveConfiguration,
  getEnvironments,
  createEnvironment,
  deleteEnvironment,
  forceDeleteEnvironment,
  deployEnvironment,
  getEnvironmentDetails,
  updateEnvironment,
  getEnvironmentVariables,
  createVariable,
  updateVariable,
  deleteVariable,
  getComponents,
  getComponentsYaml,
  getDraftComponents,
  addComponent,
  removeComponent,
  getTimeframes,
  updateEnvironmentTimeframes,
  createTimeframe,
  editTimeframe,
  removeTimeframe,
  revertTimeframes,
  startEnvironment,
  stopEnvironment,
  resetState,
  setCurrentEnvironmentsNo,
  changeStatus,
  resetEnvErrors,
  getKubernetesClusters,
  cloneEnvironment,
  getEnvironmentEvents,
  getSourceEvents,
  updateEnvironmentEventLogs,
  updateEnvironmentEventsList,
  getEnvironmentEventLogs,
  getManifests,
  convertToPrimary,
  toggleAutoUpdate,
  applyModule,
  getModuleResources,
  detachModule,
  syncModule,
  saveBuildSettings,
  getEnvSecretValue,
  hideEnvSecretValue,
  getComponentSecretValue,
  hideComponentSecretValue,
  resetSecretValues,
  resetSecretValue,
  resetResourceId,
  checkClusterCapability,
  editComponent,
  showEnvListSkeletons,
  getKubernetesResource,
  getCompPipelineData,
  resetCompPipelineState,
  getComponentDefinition,
  getK8sResDefinition,
  getEnvironmentPipelines,
  getEnvironmentPipelinesUsers,
  getEnvironmentPipelineDetails,
  resetEnvComponents, resetAllComponentsVariable, handleDeployedIds,
  saveUrlHandle,
  resetEnvViolations, enrichComponents,
  manuallyUpdateEnvironment,
  resetPublishError, getEnvironmentsMtd, applyClusterConfiguration,
  resetEnvDetailsCosts,
  resetEnvDetails,
  getModuleOutputValues,
  updateCheckedComponentsList,
} from '../actions';

import initialState from './initialState';

const showEnvListSkeletonsHandler = [
  showEnvListSkeletons,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isLoadingEnvList: payload,
    };
  },
];

const getEnvironmentsHandler = [
  getEnvironments,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
        isConnecting: true,
        // [i] isLoadingEnvList should not be set
      };
    }

    if (error && payload.response?.code === 'ERR_CANCELED') {
      return state;
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
        isLoadingEnvList: false,
      };
    }

    const environments = payload['hydra:member'];
    const totalItems = payload['hydra:totalItems'];

    return {
      ...state,
      isLoading: false,
      environments,
      environmentsNo: totalItems,
      isConnecting: false,
      isLoadingEnvList: false,
      updateResourceId: '',
    };
  },
];

const createEnvironmentHandler = [
  createEnvironment,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isConnecting: true,
        isCreatingEnv: true,
        violations: [],
      };
    }

    if (error) {
      return {
        ...state,
        isConnecting: false,
        isCreatingEnv: false,
        errorMessage: payload.response.message,
        violations: payload.response?.data?.violations || [],
      };
    }
    const newEnvList = R.append(payload, state.environments);
    return {
      ...state,
      isConnecting: false,
      isCreatingEnv: false,
      environmentsNo: newEnvList.length,
      environments: newEnvList,
    };
  },
];
const cloneEnvironmentHandler = [
  cloneEnvironment,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isConnecting: true,
        isCloningEnv: true,
        errorMessage: '',
        violations: [],
      };
    }

    if (error) {
      return {
        ...state,
        isConnecting: false,
        isCloningEnv: false,
        errorMessage: payload.response.message,
        violations: payload.response?.data?.violations || [],
      };
    }

    return {
      ...state,
      isConnecting: false,
      isCloningEnv: false,
      environments: R.prepend(payload, state.environments),
    };
  },
];
const deleteEnvironmentHandler = [
  deleteEnvironment,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
        updateResourceId: '',
        updatedProjectId: '',
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
      };
    }

    if(!isBlank(state.environments)){
      const index = R.findIndex(R.propEq('id', payload))(state.environments);
      const update = R.map(R.ifElse(R.propEq('id', state.environments[index].id), R.assoc('operationStatus', 'deleting'), (item) => item))
      return {
        ...state,
        isLoading: false,
        environmentsNo: update(state.environments).length,
        environments: update(state.environments),
      };
    }
    return {
      ...state,
      isLoading: false,
      environmentsNo: state.environments,
      environments: state.environments,
    };
  },
];

const forceDeleteEnvironmentHandler = [
  forceDeleteEnvironment,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
      };
    }

    if(!isBlank(state.environments)){
      const index = R.findIndex(R.propEq('id', payload))(state.environments);
      const update = R.map(R.ifElse(R.propEq('id', state.environments[index].id), R.assoc('operationStatus', 'deleting'), (item) => item))
      return {
        ...state,
        isLoading: false,
        environmentsNo: update(state.environments).length,
        environments: update(state.environments),
      };
    }
    return {
      ...state,
      isLoading: false,
      environmentsNo: state.environments,
      environments: state.environments,
    };
  },
];

const startEnvironmentHandler = [
  startEnvironment,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
      };
    }
    const index = R.findIndex(R.propEq('id', payload))(state.environments);
    if(index > -1 ){
      const update = R.map(R.ifElse(R.propEq('id', state.environments[index].id), R.assoc('operationStatus', 'starting'), (item) => item))
      return {
        ...state,
        isLoading: false,
        environments: update(state.environments),
      };
    }

    const newEnvironment = { ...state.environment };
    newEnvironment.operationStatus =  "starting"

    return {
      ...state,
      isLoading: false,
      environment: newEnvironment
    };
  },
];
const stopEnvironmentHandler = [
  stopEnvironment,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
      };
    }
    const index = R.findIndex(R.propEq('id', payload))(state.environments);
    if(index > -1) {
      const update = R.map(R.ifElse(R.propEq('id', state.environments[index].id), R.assoc('operationStatus', 'stopping'), (item) => item))
      return {
        ...state,
        isLoading: false,
        environments: update(state.environments),
      }
    }
    const newEnvironment = { ...state.environment };
    newEnvironment.operationStatus =  "stopping"

    return {
      ...state,
      isLoading: false,
      environment: newEnvironment
    };

  },
];

const changeStatusHandler = [
  changeStatus,
  (state, action) => {
    const {payload} = action;
    const index = R.findIndex(R.propEq('id', payload.environmentId))(state.environments);
    if(index > -1){
      const update = R.map(R.ifElse(R.propEq('id', state.environments[index].id), R.assoc('status', payload.status), (item) => item))
      return {
        ...state,
        environments: update(state.environments),
        environment: update(state.environments)[index]
      }
    }
    const newEnvironment = { ...state.environment };
    newEnvironment.status =  payload.status;
    return {
      ...state,
      environment: {...state.environment, 'status': payload.status}
    }
  }
]

const deployedIdsHandler = [
  handleDeployedIds, (state, action) => {
  const {payload} = action
    return{
      ...state,
      deployingIds: R.append(payload, state.deployingIds)
    }
  }
]
const deployEnvironmentHandler = [
  deployEnvironment,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
        return {
          ...state,
          stopUpdate: false,
          isLoading: true,
          isDeploying: true,
          isDeploySuccess: false,
        }
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        stopUpdate: false,
        isDeploying: false,
        isDeploySuccess: false,
        errorMessage: payload.response.message,
      };
    }
    const index = R.findIndex(R.propEq('id', payload))(state.deployingIds);
    return {
      ...state,
      isLoading: false,
      isDeploying: false,
      stopUpdate: false,
      isDeploySuccess: true,
      environments: state.environments,
      environment: state.environment,
      deployingIds: R.remove(index, 1, state.deployingIds)
    };
  },
];
const getTimeframesHandler = [
  getTimeframes, (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
        isDataFetching: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        isDataFetching: false,
        errorMessage: payload.response.message,
      };
    }

    return {
      ...state,
      isLoading: false,
      updateResourceId: '',
      isDataFetching: false,
      environment: {
        ...state.environment,
        timeframes: payload.reduce((acc, item) => {
          const { id, timeframe, startHour, endHour } = item;
          acc.push({
            ...item,
            id,
            timeframe: timeframe.id,
            startHour,
            endHour,
            isReadOnly: true,
          });
          return acc;
        }, []),
        addedTimeframes: [],
        markedToDeleteTimeframes: [],
      },
    };
  }
]

const getEnvironmentDetailsHandler = [
  getEnvironmentDetails,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
        isDataFetching: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        isDataFetching: false,
        errorMessage: payload.response.message,
      };
    }

    return {
      ...state,
      isLoading: false,
      isDataFetching: false,
      updateResourceId: '',
      environment: {
        ...state.environment,
        ...payload,
      },
    };
  },
];

const manuallyTriggeredUpdateEnvironmentHandler = [
  manuallyUpdateEnvironment,
  (state, action) => {
    let { payload } = action;

    if (payload.displayableComponentsCount === '+1') {
      payload = { ...payload, displayableComponentsCount: (state.environment?.displayableComponentsCount || 0) + 1 };
    }

    return {
      ...state,
      environment: {
        ...state.environment,
        ...payload,
      },
    };
  },
];

const updateEnvironmentHandler = [
  updateEnvironment,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
        isDataFetching: true,
        isUpdatingEnvironment: true,
        errorUpdateEnvironment: false,
        updatedEnvSuccess: false,
        violations: [],
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        isUpdatingEnvironment: false,
        updatedEnvSuccess: false,
        isDataFetching: false,
        errorMessage: payload.message,
        errorUpdateEnvironment: true,
        violations: payload.response?.data?.violations || [],
      };
    }
    const index = R.findIndex(R.propEq('id', payload.data.id))(state.environments);
    let update;
    if(index > -1){
      update = R.map(R.ifElse(R.propEq('id', state.environments[index].id), R.assoc('kubernetesIntegration', payload.data.kubernetesIntegration), (item) => item))
    }
    return {
      ...state,
      isLoading: false,
      isDataFetching: false,
      isUpdatingEnvironment: false,
      updatedEnvSuccess: true,
      errorUpdateEnvironment: false,
      environmentHasUpdates: true,
      environments: index > -1 ? update(state.environments) : state.environments,
      environment: {
        ...state.environment,
        ...payload.data,
      },
    };
  },
];

const createVariableHandler = [
  createVariable,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.data,
      };
    }
    const index = R.findIndex(R.propEq('key', payload.key))(state.variables);
    if(index > -1){
      const variables = R.adjust(
        index,
        () => ({
          ...payload,
        }),
        state.variables
      );
      return {
        ...state,
        isLoading: false,
        variables
      }
    }
    return {
      ...state,
      isLoading: false,
      errorMessage: null,
      variables: R.prepend(payload, state.variables),
    };
  },
];

const updateVariableHandler = [
  updateVariable,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.data,
      };
    }
    // const index = R.findIndex(R.propEq('key', payload.newKey))(state.variables);
    // const variables = R.adjust(
    //   index,
    //   () => ({
    //     ...payload,
    //   }),
    //   state.variables
    // );
    return {
      ...state,
      isLoading: false,
    };
  },
];

const getEnvironmentVariablesHandler = [
  getEnvironmentVariables,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isEnvVariablesLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isEnvVariablesLoading: false,
        errorMessage: payload.response.message,
      };
    }

    return {
      ...state,
      isEnvVariablesLoading: false,
      variables: payload.data,
      isSearch: payload.isSearch,
      updateResourceId: ''
    };
  },
];

const deleteVariableHandler = [
  deleteVariable,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
      };
    }
    const index = R.findIndex(R.propEq('key', payload))(state.variables);
    if(!state.variables[index].overridden){
      return {
        ...state,
        isLoading: false,
        variables: R.remove(index, 1, state.variables),
      };
    }
    return {
      ...state,
      isLoading: false,
      // variables: R.remove(index, 1, state.variables),
    };
  },
];
const enrichComponentsHandler = [
  enrichComponents, (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
      };
    }
    const updatedComponents = payload.data.components;
    const oldComponents = state.components

    if (!oldComponents.length) {
      /* if the user leaves the details page before the request is finished,
       * oldComponents will be empty */
      return {
        ...state,
        isLoading: false,
      };
    }

    const temp = oldComponents.map((comp, index) => {
      if(updatedComponents[index]){
        // future cluster status
        const component = {...comp};
        component.remoteDevelopment = updatedComponents[index].remoteDevelopment;
        return component;
      }

      return comp
    });

    return {
      ...state,
      isLoading: false,
      components: temp,
    };
  }
]

const getComponentsHandler = [
  getComponents,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
        /** reset components update */
        updateComponentResourceId: '',
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        isLoadingComponents: false,
        errorMessage: payload.response.message,
      };
    }
    return {
      ...state,
      isLoading: false,
      isLoadingComponents: false,
      components: payload.data.components,
    };
  },
];
const editComponentHandler = [
  editComponent,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
        isLoadingComponents: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        isLoadingComponents: false,
        errorMessage: payload.response.data,
      };
    }
    return {
      ...state,
      isLoading: false,
      isLoadingComponents: false,
      components: payload.data.components,
    };
  },
];

const getComponentsYamlHandler = [
  getComponentsYaml,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
      };
    }
    return {
      ...state,
      isLoading: false,
      componentsYaml: payload.data,
    };
  },
];

const getDraftComponentsHandler = [
  getDraftComponents,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
        isConnecting: true,
        /** reset components update */
        updateComponentResourceId: '',
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        isConnecting: false,
        isLoadingComponents: false,
        errorMessage: payload.response.message,
      };
    }

    return {
      ...state,
      environment: {
        ...state.environment,
        draftComponentsResponse: {...payload.data.data}
      },
      isLoading: false,
      isConnecting: false,
      components: payload.data.data.components,
      isLoadingComponents: false,
      updateResourceId: '',
    };
  },
];

const updateEnvironmentTimeframesHandler = [
  updateEnvironmentTimeframes,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
        isUpdatingTimeframes: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        isUpdatingTimeframes: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      isUpdatingTimeframes: false,
      errorMessage: '',
      addedTimeframes: [],
      markedToDeleteTimeframes: [],
    };
  },
];

const createTimeframeHandler = [
  createTimeframe,
  (state) => {
    const tempTimeframes = state.environment.addedTimeframes;
    let newTimeframe;
    if(tempTimeframes.length > 0
      && tempTimeframes[tempTimeframes.length - 1].endHour === null){
      newTimeframe = {
        id: Date.now(),
        timeframe: '',
        endHour: null,
        startHour: null,
        disableStart: true,
      };
    } else {
      newTimeframe = {
        id: Date.now(),
        timeframe: '',
        endHour: null,
        startHour: null,
        disableStart: false,
      };
    }
    return {
      ...state,
      environment: {
        ...state.environment,
        addedTimeframes: R.append(newTimeframe, state.environment.addedTimeframes),
      },
    };
  },
];

const editTimeframeHandler = [
  editTimeframe,
  (state, action) => {
    const { payload } = action;
    let adjustedTimeframes = state.environment.addedTimeframes;
    //let savedTimeframes = state.environment.timeframes;
    let index = R.findIndex(R.propEq('id', payload.id))(state.environment.addedTimeframes);
    if(state.environment.addedTimeframes[index+1]
      && state.environment.addedTimeframes[index+1].disableStart === true){
      const nextTimeframe = state.environment.addedTimeframes[index+1];
      nextTimeframe.disableStart = false;
      adjustedTimeframes = R.adjust(
        (index+1),
        () => ({
          ...nextTimeframe
        }),
        state.environment.addedTimeframes
      );
    }
    if(payload.timeframe !== state.environment.addedTimeframes[index].timeframe){
      payload.startHour = null
      payload.endHour = null
    }
    if(index < 0){
      index = R.findIndex(R.propEq('id', payload.id))(state.environment.timeframes);
      return {
        ...state,
        environment: {
          ...state.environment,
          timeframes: R.adjust(
            index,
            (timeframe) => ({
              ...timeframe,
              ...payload,
            }),
            state.environment.timeframes
          ),
        }

      };
    }
    return {
      ...state,
      environment: {
        ...state.environment,
        addedTimeframes: R.adjust(
          index,
          (startHour) => ({
            ...startHour,
            ...payload,
          }),
          adjustedTimeframes
        ),
      },
    };
  },
];

const removeTimeframeHandler = [
  removeTimeframe,
  (state, action) => {
    const { payload } = action;
    let adjustedTimeframes = state.environment.addedTimeframes;
    const addedIndex = R.findIndex(R.propEq('id', payload))(state.environment.addedTimeframes);
    if(state.environment.addedTimeframes[addedIndex+1] &&
      state.environment.addedTimeframes[addedIndex+1].disableStart === true){
      const nextTimeframe = state.environment.addedTimeframes[addedIndex+1];
      nextTimeframe.disableStart = false;
      adjustedTimeframes = R.adjust(
        (addedIndex+1),
        () => ({
          ...nextTimeframe
        }),
        state.environment.addedTimeframes
      );
    }
    if (addedIndex >= 0) {
      return {
        ...state,
        environment: {
          ...state.environment,
          addedTimeframes: R.remove(addedIndex, 1, adjustedTimeframes),
        },
      };
    }

    const index = R.findIndex(R.propEq('id', payload))(state.environment.timeframes);
    const timeframeToDelete = state.environment.timeframes[index];

    return {
      ...state,
      environment: {
        ...state.environment,
        timeframes: R.remove(index, 1, state.environment.timeframes),
        markedToDeleteTimeframes: R.append(
          timeframeToDelete,
          state.environment.markedToDeleteTimeframes
        ),
      },
    };
  },
];

const revertTimeframesHandler = [
  revertTimeframes,
  (state) => {
    return {
      ...state,
      environment: {
        ...state.environment,
        timeframes: R.concat(
          state.environment.timeframes,
          state.environment.markedToDeleteTimeframes
        ),
        addedTimeframes: [],
        markedToDeleteTimeframes: [],
      },
    };
  },
];

const addComponentHandler = [
  addComponent,
  (state, action) => {
    const { ready, error, payload, type } = action;

    if (!ready) {
      return {
        ...state,
        isComponentAdding: true,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        isComponentAdding: false,
        errorMessage: {...payload.response.data, source: type},
      };
    }

    return {
      ...state,
      isLoading: false,
      isComponentAdding: false,
      components: payload.components,
    };
  },
];

const saveConfigurationHandler = [
  saveConfiguration,  
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
        isPublishing: true,
        isDeploySuccess: false,
        errorMessage: null,
        violations: [],
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        isPublishing: false,
        errorMessage: R.path(['response', 'data'], payload),
        violations: R.path(['response', 'data', 'violations'], payload) || [],
      };
    }

    return {
      ...state,
      isLoading: false,
      componentsYaml: payload.data,
    };
  },
];

const publishConfigurationHandler = [
  publishConfiguration,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
        yamlPublished: false,
        isDeploySuccess: false,
        isPublishing: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.data,
        violations: R.path(['response', 'data', 'violations'], payload) || [],
        yamlPublished: false,
        isPublishing: false,
        deployPublishingError: true,
        publishingError: payload.response.data,
      };
    }
    return {
      ...state,
      isLoading: false,
      yamlPublished: true,
      isPublishing: false,
      isRemovingComponent: false,
      publishingError: null,
      deployPublishingError: false,
      environment: {
        ...state.environment,
        hasDraft: false
      }
    };
  },
];

const removeComponentHandler = [
  removeComponent,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
        isRemovingComponent: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
        isRemovingComponent: false,
      };
    }
    const componentsLength = state.environment.status === 'draft' ? payload.components.length : state.components.length;
    return {
      ...state,
      isLoading: false,
      allComponents: componentsLength + state.moduleUses.length,
      isRemovingComponent: false,
      // components: payload.components,
    };
  },
];
const getKubernetesClustersHandler = [
  getKubernetesClusters,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
        isLoadingClusters: true,
        // isDataFetching: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        isLoadingClusters: false,
        // isDataFetching: false,
        errorMessage: payload.response.message,
      };
    }
    return {
      ...state,
      isLoading: false,
      isLoadingClusters: false,
      // isDataFetching: false,
      kubernetesIntegrations: payload.data,
    };
  },
];
const resetStateHandler = [
  resetState,
  (state) => {
    const envNo = state.environmentsNo;
    return {
      ...initialState,
      environment: state.environment,
      environments: state.environments,
      environmentsNo: envNo,
      deployPublishingError: state.deployPublishingError,
      publishingError: state.publishingError,
    }
  }];

const updateComponentsResourceHandler =[
  updateComponentsResource, (state, action) => {
    const { payload } = action;
    return {
      ...state,
      updateComponentResourceId: payload,
    };
  },
];

const updateEnvironmentResourceHandler = [
  updateEnvironmentResource,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      updateResourceId: payload,
    };
  },
];

const getEnvironmentEventsHandler = [
  getEnvironmentEvents,
  (state, action) => {
    const { ready, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    return {
      ...state,
      events: payload,
      isLoading: false,
    }
  }
]
const getEnvironmentPipelinesHandler = [
  getEnvironmentPipelines,
  (state, action) => {
    const { ready, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    const totalItems = payload['hydra:totalItems'];
    return {
      ...state,
      pipelines: payload['hydra:member'],
      pipelinesNo: totalItems,
      isLoading: false,
    }
  }
]
const getEnvironmentPipelinesUsersHandler = [
  getEnvironmentPipelinesUsers,
  (state, action) => {
    const { ready, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    const count = R.length(payload);
    return {
      ...state,
      pipelinesUsers: payload,
      pipelinesUsersCount: count
    }
  }
]
const getSourceEventsHandler = [
  getSourceEvents,
  (state, action) => {
    const { ready, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    const count = R.length(payload);
    return {
      ...state,
      sources: payload,
      sourcesCount: count
    }
  }
]

const updateEnvironmentEventsListHandler = [
  updateEnvironmentEventsList,
  (state, action) => {
    const {ready, payload} = action;
    
    if (!ready) {
      return {
        ...state,
      };
    }

    return {
      ...state,
      events: R.concat(state?.events, payload),
    }
  }
]
const getEnvironmentPipelineDetailsHandler = [
  getEnvironmentPipelineDetails,
  (state, action) => {
    const {payload, ready, error} = action;

    if (!ready) {
      return {
        ...state,
        isLoadingPipeline: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoadingPipeline: false,
      };
    }
    return {
      ...state,
      isLoadingPipeline: false,
      pipeline: payload.data
    }
  }
];

const getEnvironmentEventLogsHandler = [
  getEnvironmentEventLogs,
  (state, action) => {
    const {payload} = action;
    return {
      ...state,
      eventLogs: payload
    }
  }
]

const updateEnvironmentEventLogsHandler = [
  updateEnvironmentEventLogs,
  (state, action) => {
    const {ready, payload} = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    return {
      ...state,
      eventLogs: R.concat(state.eventLogs, payload),
    }
  }
]

const setCurrentEnvironmentsNoHandler = [
  setCurrentEnvironmentsNo,
  (state, action) => {
  const {payload} = action;
    return {
      ...state,
      environmentsNo: payload,
    }
  }
]

const getManifestsHandler = [
  getManifests,
  (state, action) => {
    const {ready, payload} = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    const {data, fileName} = payload;
    return {
      ...state,
      manifests: data.data,
      manifestFileName: fileName,
      isLoading: false
    }
  }
]

const resetEnvErrorsHandler = [
  resetEnvErrors, (state) => {
    return {
      ...state,
      errorMessage: null,
      violations: [],
    }
  }
]
const convertToPrimaryHandler = [
  convertToPrimary, (state,action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
      };
    }
    return {
      ...state,
      isLoading: false,
      environment: {
        ...state.environment,
        ...payload
      }
    };
  }
]

const toggleAutoUpdateHandler = [
  toggleAutoUpdate, (state,action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
      };
    }
    const index = R.findIndex(R.propEq('id', payload.id))(state.moduleUses);
    return {
      ...state,
      isLoading: false,
      moduleUses: R.update(index, payload, state.moduleUses),
    };
  }
]

const applyModuleHandler = [
  applyModule, (state,action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
      };
    }
    const index = R.findIndex(R.propEq('id', payload))(state.moduleUses);
    const update = R.map(R.ifElse(R.propEq('id', state.moduleUses[index].id), R.assoc('aggregatedStatus', 'applying'), (item) => item))
    return {
      ...state,
      isLoading: false,
      moduleUses: update(state.moduleUses),
    };
  }
]

const getModuleResourcesHandler = [
  getModuleResources, (state,action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isConnecting: true,
        moduleResource: null,
      };
    }
    if (error) {
      return {
        ...state,
        isConnecting: false,
        errorMessage: payload.response.message,
      };
    }
    return {
      ...state,
      isConnecting: false,
      moduleResource: payload.full,
    };
  }
]
const detachModuleHandler = [
  detachModule, (state,action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isConnecting: true,
      };
    }
    if (error) {
      return {
        ...state,
        isConnecting: false,
        errorMessage: payload.response.message,
      };
    }

    const index = R.findIndex(R.propEq('id', payload.moduleId))(state.moduleUses);
    if(payload.response.status === 204){
      return {
        ...state,
        isConnecting: false,
        environment: {
          ...state.environment, displayableComponentsCount: state.environment.displayableComponentsCount - 1,
        },
        allComponents: state.components.length + (R.remove(index, 1, state.moduleUses).length),
        moduleUses: R.remove(index, 1, state.moduleUses)
      };
    }
    const update = R.map(R.ifElse(R.propEq('id', state.moduleUses[index].id), R.assoc('aggregatedStatus', 'deleting'), (item) => item))
    return {
      ...state,
      isConnecting: false,
      allComponents: state.components.length + update(state.moduleUses).length,
      moduleUses: update(state.moduleUses)
    };
  }
]

const syncModuleHandler = [
  syncModule, (state,action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isConnecting: true,
      };
    }
    if (error) {
      return {
        ...state,
        isConnecting: false,
        errorMessage: payload.response.message,
      };
    }
    const index = R.findIndex(R.propEq('id', payload.id))(state.moduleUses);
    return {
      ...state,
      isConnecting: false,
      moduleUses: R.update(index, payload, state.moduleUses)
    };
  }
]

/** save environment build settings */
const saveBuildSettingsHandler = [
    saveBuildSettings, (state, action) => {
      const { ready, error, payload } = action;

      if (!ready) {
        return {
          ...state,
          isSavingBuildSettings: true,
          updateResourceId: '',
        };
      }

      if (error) {
        return {
          ...state,
          isSavingBuildSettings: false,
          violations: payload?.response?.data?.violations || [],
          errorMessage: payload.response.message,
        };
      }

      return {
        ...state,
        isSavingBuildSettings: false,
        environment: {
          ...state.environment,
          ...payload.data,
        }
      };
    }
  ];

const getEnvSecretValueHandler = [
  getEnvSecretValue, (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
      };
    }
    if (error) {
      return {
        ...state,
        errorMessage: payload.response.message,
      };
    }
    if(payload.isEdit){
      return {
        ...state,
        secretValue: payload.data.value
      }
    }
    const secret = {
      name: payload.key,
      value: payload.value
    }
    return {
      ...state,
      secretValues: R.append(secret, state.secretValues),
    }
  }
]

const hideEnvSecretValueHandler = [
  hideEnvSecretValue, (state, action) => {
    const {payload} = action;

    const index = R.findIndex(R.propEq('name', payload))(state.secretValues);
    return {
      ...state,
      secretValues: R.remove(index, 1, state.secretValues),
    }
  }
]
const getComponentSecretValueHandler = [
  getComponentSecretValue, (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
      };
    }
    if (error) {
      return {
        ...state,
        errorMessage: payload.response.message,
      };
    }
    if(payload.isEdit){
      return {
        ...state,
        secretValue: payload.data.value
      }
    }
    const secret = {
      name: payload.key,
      value: payload.value
    }
    return {
      ...state,
      secretValues: R.append(secret, state.secretValues),
    }
  }
]

const hideComponentSecretValueHandler = [
  hideComponentSecretValue, (state, action) => {
    const {payload} = action;

    const index = R.findIndex(R.propEq('name', payload))(state.secretValues);
    return {
      ...state,
      secretValues: R.remove(index, 1, state.secretValues),
    }
  }
];

const checkClusterCapabilityHandler = [
  checkClusterCapability,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
      };
    }
    return {
      ...state,
      isLoading: false,
      environment: {
        ...state.environment,
        ...payload.data,
      },
      updateResourceId: '',
    };
  },
];


const resetSecretValuesHandler = [
  resetSecretValues, (state) => {
    return {
      ...state,
      secretValues: [],
    }
  }
]

const resetSecretValueHandler = [
  resetSecretValue, (state) => {
    return {
      ...state,
      secretValue: null,
    }
  }
]

const resetResourceIdHandler = [
  resetResourceId, (state) => {
    return {
      ...state,
      updateResourceId: '',
    }
  }
];

const getKubernetesResourceHandler = [
  getKubernetesResource, (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isComponentResourceLoading: true,
        isLoadingExportedVars: true,
      };
    }

    if (error) {
      return {
        ...state,
        isComponentResourceLoading: false,
        isLoadingExportedVars: false,
      };
    }

    if (!payload.components.length) {
      return {...state};
    }


    const isTfComponent = payload.components[0].kind === 'Terraform';
    const componentResources =  payload.components[0][isTfComponent ? 'terraformState' : 'kubernetesResources']?.resources || [];
    const exportedVariables =  payload.components[0]?.exportedVariables || [];

    const {name, kubernetesResources } = payload.components[0];

    const existingIndex = R.indexOf(name, state.componentsResourcesIndex)
    let tempResourcesIndex = state.componentsResourcesIndex;
    let tempResources = state.componentsResources;
    // Remove resources from array to avoid duplicates if user opens and closes the same component
    if(existingIndex !== -1){
      tempResourcesIndex = R.remove(existingIndex, 1, state.componentsResourcesIndex);
      tempResources = R.remove(existingIndex, 1, state.componentsResources)
    }

    const kubernetesResourcesWithParent = componentResources.map((resource)=>{
      return {...resource, parentName: name} ;
    });

    const componentsResourcesIndex = R.append(name, tempResourcesIndex)
    const updatedComponentsResources = R.append(kubernetesResourcesWithParent, tempResources);

    return {
      ...state,
      ownershipGroups: isTfComponent ? {} : {...state.ownershipGroups, [name]: kubernetesResources?.ownershipGroups},
      componentsResources: updatedComponentsResources,
      componentsResourcesIndex,
      exportedVariables,
      isComponentResourceLoading: false,
      isLoadingExportedVars: false,
    };
  }
]

const getModuleOutputValuesHandler = [
  getModuleOutputValues, (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoadingOutputValues: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoadingOutputValues: false,
        moduleOutputValues: [],
      };
    }

    if (!payload.components.length) {
      return {...state};
    }

    const outputValues =  payload.components[0]?.terraformState?.outputValues || [];

    return {
      ...state,
      moduleOutputValues: outputValues,
      isLoadingOutputValues: false,
    };
  }
]
/** @TODO: will i use this in pipelineLogs ? */
const updatePipelineResourceHandler = [
  updatePipelineResource,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      environment: {
        ...state.environment,
        updatePipelineResourceId: payload,
      },
    };
  },
];

const getCompPipelineDataHandler = [
  getCompPipelineData,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        environment: {
          ...state.environment,
          isLoadingPipeline: true,
          updatePipelineJobResourceId: '',
        },
      };
    }

    if (error) {
      return {
        ...state,
        environment: {
          ...state.environment,
          isLoadingPipeline: false,
          errorMessage: payload.response.message,
        },
      };
    }

    const pipelineCompJobTopics = [];

    payload.workflowJobs.forEach((job)=>{
      const jobID = job.id;

      pipelineCompJobTopics.push(`/api/workflow_jobs/${jobID}`);
    });

    return {
      ...state,
      environment: {
        ...state.environment,
        pipelineCompJobTopics,
        isLoadingPipeline: false,
        pipelineData: payload,
      },
    };
  },
];

const manuallyUpdatePipeTopicHandler = [
  manuallyUpdateComponentPipeTopic,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      environment: {
        ...state.environment,
        pipelineCompTopic: `/api/workflows/${payload}`,
      },
    };
  },
];

const resetLoadingStatesHandler = [
  resetCompPipelineState, (state) => {
    return {
      ...state,
      environment: {
        ...state.environment,
        isLoadingPipeline: true,
        pipelineData: {
          workflowJobs: [],
        },
        pipelineCompJobTopics: [],
        updatePipelineResourceId: '',
        updatePipelineJobResourceId: '',
      },
    }
  }
];
const resetEnvComponentsHandler = [
  resetEnvComponents, (state) => {
    return {
      ...state,
      isLoadingComponents: true,
      components: [],
    }
  }
];
const resetAllComponentsVariableHandler = [
  resetAllComponentsVariable, (state) => {
    return {
      ...state,
      allComponents: null,
    }
  }
];
const getK8sResDefinitionHandler = [
  getComponentDefinition,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        environment: {
          ...state.environment,
          isLoadingDefinition: true,
          componentDefinition: {},
        },
      };
    }

    if (error) {
      return {
        ...state,
        environment: {
          ...state.environment,
          isLoadingDefinition: false,
          errorMessage: payload.response.message,
        },
      };
    }

    return {
      ...state,
      environment: {
        ...state.environment,
        isLoadingDefinition: false,
        componentDefinition: payload,
      },
    };
  },
];
const getComponentDefinitionHandler = [
  getK8sResDefinition,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        environment: {
          ...state.environment,
          isLoadingK8sResDefinition: true,
          k8sResDefinition: {},
        },
      };
    }

    if (error) {
      return {
        ...state,
        environment: {
          ...state.environment,
          isLoadingK8sResDefinition: false,
          errorMessage: payload.response.message,
        },
      };
    }

    return {
      ...state,
      environment: {
        ...state.environment,
        isLoadingK8sResDefinition: false,
        k8sResDefinition: payload,
      },
    };
  },
];
const saveUrlHandleHandler = [
  saveUrlHandle,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        environment: {
          ...state.environment,
          isSavingUrlHandle: true,
          violations: [],
        },
      };
    }

    if (error) {
      return {
        ...state,
        environment: {
          ...state.environment,
          isSavingUrlHandle: false,
          violations: payload.response?.data?.violations || [],
          errorMessage: payload.response.message,
        },
      };
    }

    return {
      ...state,
      environment: {
        ...state.environment,
        isSavingUrlHandle: false,
        violations: [],
        draftComponentsResponse: {
          ...payload.data
        }
      },
    };
  },
];

const getEnvironmentsMtdHandler = [
  getEnvironmentsMtd,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoadingCosts: true
      };
    }

    if (error) {
      return {
        ...state,
        isLoadingCosts: false,
      };
    }

    return {
      ...state,
      environmentsCosts: payload.data,
      isLoadingCosts: false,
    };
  },
];

const applyClusterConfigurationHandler = [
  applyClusterConfiguration,
  (state, action) => {
    const { ready, error } = action;

    if (!ready) {
      return {
        ...state,
        isApplyingClusterConfig: true
      };
    }

    if (error) {
      return {
        ...state,
        isApplyingClusterConfig: false,
      };
    }

    return {
      ...state,
      isApplyingClusterConfig: false,
    };
  },
];

const updateCheckedComponentsListHandler = [
  updateCheckedComponentsList, (state, action) => {
    const { payload } = action;
    return {
      ...state,
      environment: {
        ...state.environment,
        ...payload,
      },
    };
  }
];

const resetEnvDetailsCostsHandler = [
  resetEnvDetailsCosts, (state) => {
    return {
      ...state,
      environmentsCosts: null,
    };
  }
];

const resetViolationsHandler = [
  resetEnvViolations, (state) => {
    return {
      ...state,
      environment: {
        ...state.environment,
        violations: [],
      },
    };
  }
];

const resetEnvDetailsHandler = [
  resetEnvDetails, (state) => {
    return {
      ...state,
      isLoading: true,
      isLoadingComponents: true,
      isLoadingEnvList: true,
      isLoadingLogs: true,
      isLoadingPipeline: true,
    };
  }
];

const resetPublishErrorHandler = [
  resetPublishError, (state) => {
    return {
      ...state,
      deployPublishingError: false,
      publishingError: null
    };
  }
];

// mercure
const updateConfigInterestHandler = [
  updateConfigInterest,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      interest: payload,
    };
  },
];


const reducer = handleActions(
  new Map([
    getEnvironmentsHandler, createEnvironmentHandler, deleteEnvironmentHandler,
    startEnvironmentHandler,updateEnvironmentResourceHandler,getEnvironmentEventsHandler,
    deployEnvironmentHandler, getEnvironmentDetailsHandler, updateEnvironmentHandler,
    getEnvironmentVariablesHandler, forceDeleteEnvironmentHandler,
    createVariableHandler, updateVariableHandler, deleteVariableHandler, editComponentHandler,
    getComponentsHandler, getComponentsYamlHandler, getDraftComponentsHandler,addComponentHandler,
    updateEnvironmentTimeframesHandler, createTimeframeHandler, editTimeframeHandler, getTimeframesHandler,
    removeTimeframeHandler, revertTimeframesHandler,
    publishConfigurationHandler,
    removeComponentHandler,
    resetStateHandler,
    getKubernetesClustersHandler,
    cloneEnvironmentHandler,
    stopEnvironmentHandler,
    getSourceEventsHandler, getEnvironmentPipelinesHandler, getEnvironmentPipelinesUsersHandler,
    updateEnvironmentEventsListHandler,
    getEnvironmentEventLogsHandler,
    updateEnvironmentEventLogsHandler,
    setCurrentEnvironmentsNoHandler,
    saveConfigurationHandler,
    updateComponentsResourceHandler,
    changeStatusHandler,
    getManifestsHandler,
    resetEnvErrorsHandler,
    convertToPrimaryHandler,
    toggleAutoUpdateHandler, applyModuleHandler, getModuleResourcesHandler,
    detachModuleHandler, syncModuleHandler,
    getEnvSecretValueHandler,hideEnvSecretValueHandler,getComponentSecretValueHandler,
    hideComponentSecretValueHandler, resetSecretValuesHandler, resetSecretValueHandler,
    saveBuildSettingsHandler,
    resetResourceIdHandler,
    checkClusterCapabilityHandler,
    showEnvListSkeletonsHandler,
    getKubernetesResourceHandler,
    updatePipelineResourceHandler,
    getCompPipelineDataHandler,
    resetLoadingStatesHandler,
    getComponentDefinitionHandler,
    getK8sResDefinitionHandler,
    getEnvironmentPipelineDetailsHandler,
    resetEnvComponentsHandler,
    manuallyUpdatePipeTopicHandler,
    resetAllComponentsVariableHandler,
    deployedIdsHandler,
    saveUrlHandleHandler,
    resetViolationsHandler,
    enrichComponentsHandler,
    manuallyTriggeredUpdateEnvironmentHandler,
    resetPublishErrorHandler,
    getEnvironmentsMtdHandler,
    applyClusterConfigurationHandler,
    resetEnvDetailsCostsHandler,
    resetEnvDetailsHandler,
    updateConfigInterestHandler,
    getModuleOutputValuesHandler,
    updateCheckedComponentsListHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
