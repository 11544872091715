import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CodeMirror from '@uiw/react-codemirror';
import * as yamlMode from '@codemirror/legacy-modes/mode/yaml';
import { StreamLanguage } from '@codemirror/language';
// eslint-disable-next-line import/no-extraneous-dependencies
import { EditorView } from 'codemirror';
import { StyledCmExtraCss, darkTheme, lightTheme } from './themes';

interface ComponentProps {
  mode: string;
  source?: string;
}

const CodeMirrorError = (props: ComponentProps) => {
  const { mode, source } = props;
  const yamlLanguage = StreamLanguage.define(yamlMode.yaml);

  return (
    <StyledCmExtraCss>
      <CodeMirror
        {...props}
        readOnly
        className="cmError readOnly"
        theme={mode === 'dark' ? darkTheme('cmError') : lightTheme('cmError')}
        basicSetup={{
          allowMultipleSelections: false,
          highlightSelectionMatches: false,
        }}
        extensions={[
          yamlLanguage,
          EditorView.theme({
            '&': {
              outline: 'none !important',
              border: '1px solid #ccc',
              borderRadius: '4px',
              maxHeight: '300px',
              overflow: 'hidden',
            },
            '.cm-line': {
              color: mode === 'dark' ? '#ff3737' : '#ff0000',
              wordBreak: 'break-word !important',
            },
            '.cm-content': {
              paddingBottom: '24px !important',
            },
            '.ͼ1d, .ͼ1n': {
              color: '#8a75e9',
            },
            '&.cm-editor .cm-activeLine': {
              backgroundColor: mode === 'dark' ? '#222222' : '#f9f9f9',
            },
            '&.cm-editor .cm-activeLineGutter': {
              backgroundColor: mode === 'dark' ? '#222222' : '#f2f2f2',
            },
            '&.cm-editor .cm-gutters.cm-gutters': {
              display: source  === 'cluster' ? 'none' : '',
            }
          }),
        ]}
      />
    </StyledCmExtraCss>
  );
};

CodeMirrorError.defaultProps = {
  source: '',
};

CodeMirrorError.propTypes = {
  source: PropTypes.string,
  mode: PropTypes.string.isRequired,
};

const mapStateToProps = ({ global }: {global: ComponentProps}) => ({
  mode: global.mode,
});

export default connect(mapStateToProps, null)(CodeMirrorError);
