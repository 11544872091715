import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import { Link, useLocation, useHistory } from 'react-router-dom';
import Menu from 'theme/uielements/menu';
import * as R from "ramda";
import {InputSearch} from "theme/uielements/input";
import {loadAccountDetails, handleUpdateUserPreferences, setCurrentProject, handleCreateOrganization} from "modules/userManager/actions";
import {showDialog, hideDialog} from "actions/global";
import Loader from 'theme/utility/loader';
import OrganizationTitle from "./OrganizationTitle";
import {filterTable} from "../../../../components/header/components/helpers";
import CreateOrganizationButton from '../../../../components/createOrganization';
import {SVG} from "../../../../components";
import SecurityMenuItem from './SecurityMenuItem';

const SidebarMenu = ({ item, organizationId, isLoadingOrganizations, organizations, userId, email, ownerEmail, actions }) => {
  const history = useHistory();  
  const { pathname } = useLocation();
  
  const { key, label, leftIcon } = item;
  const [searchTerm, setSearchTerm] = useState('')
  const [openKeys, setOpenKeys] = useState([]);

  let searchInputFocused = false;

  const onOpenChange = (e) => {
    if (searchInputFocused) {
      return;
    }

    setOpenKeys(e);
    setSearchTerm('');
  }

  const changeOrganization = (organization) => {
    actions.setCurrentProject(null);
    actions.handleUpdateUserPreferences(userId, organization.id, null, null, history, true).then(() => {
      /** 2nd argument "true" used to prevent another unnecessary get projects request */
      actions.loadAccountDetails(history, true, 'change_organization');
      history.push(`/${organization.id}/projects`);
    });
    setOpenKeys([]);
  };

  const orgMenu = () => {
    if (isLoadingOrganizations) {
      return <Loader />
    }

    const organizationsNo = organizations && organizations.length;
    const sortedOrganizations = organizations && R.sortBy(R.compose(R.toLower, R.prop('name')))(organizations)

    return (
      <div className={organizationsNo > 10 ? 'org-submenu with-search' : 'org-submenu'}>
        {
          organizationsNo > 10 &&
          <div className="search-org-container">
            <InputSearch
              className="input-search inSubMenu"
              size="small"
              addonAfter={<SVG name="bicon-search" noHover defaultColor="#808080"/>}
              value={searchTerm}
              placeholder="Search organization"
              onChange={({ target }) => setSearchTerm(target.value)}
              onFocus={() => {searchInputFocused = true}}
              onBlur={() => {searchInputFocused  = false}}
            />
            <CreateOrganizationButton 
              closeMenu={ ()=>{setOpenKeys([]);} }
              actions={ actions } 
              changeOrganization={ changeOrganization } />
          </div>
        }
        {organizations && (
            <>
            <div className="org-list-container">
              {
                R.compose(
                  R.map((organization) => {
                    return (
                      <div key={organization.id}
                           className={organization.id === organizationId ? "active link-org" : "link-org"}
                           role="button" tabIndex={0}
                           data-value="organization-link"
                           onClick={() => {
                            changeOrganization(organization);
                           }}
                      >
                        <span>{organization.name}</span>
                        <SVG name="bicon-check" className="icon f16" noHover defaultColor="#1F2ECF" />
                      </div>
                    )
                  }),
                  R.filter(filterTable(searchTerm))
                )(sortedOrganizations)
              }
            </div>
            {
              organizationsNo < 11 &&
              <CreateOrganizationButton
                closeMenu={ ()=>{setOpenKeys([]);} } 
                actions={ actions } 
                changeOrganization={ changeOrganization }
                isLinkType
              />
            }
            </>
          )
        }
      </div>
    )};
  if(key === 'documentation'){
    return (
      <Menu.Item key={key} className="mb-0" title="">
        <a href="https://documentation.bunnyshell.com/docs/introduction-to-bunnyshell" target="_blank" rel="noreferrer"
           data-value="documentation-link">
          <div className="isoMenuHolder">
            <SVG name={`bicon-${leftIcon}`} width={24} height={24}/>
            <span className={key === 'admin' ? "nav-text admin" : "nav-text"}>{label}</span>
          </div>
        </a>
      </Menu.Item>
    )
  }
  if(key === 'help-center'){
    return (
      <Menu.Item key={key} title="">
        <a href="https://documentation.bunnyshell.com/docs/" target="_blank" rel="noreferrer"
           data-value="help-center-link">
          <div className="isoMenuHolder">
            <SVG name={`bicon-${leftIcon}`} width={24} height={24}/>
            <span className={key === 'admin' ? "nav-text admin" : "nav-text"}>{label}</span>
          </div>
        </a>
      </Menu.Item>
    )
  }

  if (key === 'security' && organizationId) {
    return (
      <SecurityMenuItem key={key} organizationId={organizationId} />
    );
  }

  if(key === 'organization'){
    // This should actually be a Menu.Submenu directly, but styles need to be reworked
    return <Menu.Item key={key} title="" className="hasSubMenu">
      <Menu
        triggerSubMenuAction="click"
        className="org-menu"
        onOpenChange={onOpenChange}
        openKeys={openKeys}
      >
        <Menu.SubMenu title={<OrganizationTitle />} key={key} popupClassName={organizations && organizations.length > 10 ? 'full' : ''}>
          <Menu.Item
            key="organization-wizard"
            title=""
            style={{
              overflow: 'visible',
              height: '100%',
              padding: 0,
              margin: 0,
              // while loading
              minHeight: '100px',
              // active background color
              backgroundColor: 'transparent',
            }}
          >{orgMenu()}</Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </Menu.Item>
  }

  return (
    email === ownerEmail &&
    <Menu.Item key={key} title="">
      <Link to={`/${organizationId}/${key}`} className={pathname.includes(`/${organizationId}/${key}`) && !pathname.includes('environments')? 'active' : ''}
            data-value={`sidebar-${label.toLowerCase()}-link`}>
        <div className="isoMenuHolder">
          <SVG name={`bicon-${leftIcon}`} width={24} height={24}/>
          {/*<svg className="icon default"><use xlinkHref={`#${leftIcon}`} /></svg>*/}
          {/*<svg className="icon active"><use xlinkHref={`#${leftIcon}-active`} /></svg>*/}
          <span className={key === 'admin' ? "nav-text admin" : "nav-text"}>{label}</span>
        </div>
      </Link>
    </Menu.Item>
  );
};

SidebarMenu.defaultProps = {
  item: {},
  organizationId: null,
  userId: null,
  email: null,
  ownerEmail: null
};

SidebarMenu.propTypes = {
  email: PropTypes.string,
  ownerEmail: PropTypes.string,
  userId: PropTypes.number,
  organizationId: PropTypes.number,
  isLoadingOrganizations: PropTypes.bool.isRequired,
  organizations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  item: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    leftIcon: PropTypes.string,
  }),
  actions: PropTypes.shape({
    loadAccountDetails: PropTypes.func,
    handleUpdateUserPreferences: PropTypes.func,
    setCurrentProject: PropTypes.func,
    handleCreateOrganization: PropTypes.func,
  }).isRequired,
};
const mapStateToProps = (state) => {
  const {
    currentOrganization, lastUsedOrganization, organizations, userId, email, isLoading
  } =  state.userManager;
  const {accountOwner} = state.organization;

  const orgId = parseInt(lastUsedOrganization?.id || currentOrganization, 10);
  
  return {
    isLoadingOrganizations: isLoading,
    userId,
    organizations,
    email,
    ownerEmail: accountOwner && accountOwner.email,
    organizationId: orgId,
  }
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loadAccountDetails,
      handleUpdateUserPreferences,
      setCurrentProject,
      handleCreateOrganization,
      showDialog,
      hideDialog,
    },
    dispatch
  ),
})
export default memo(connect(mapStateToProps, mapDispatchToProps)(SidebarMenu));
