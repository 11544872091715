import React from 'react';
import Helmet from 'react-helmet';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { CustomerCalendlyWidget } from 'modules/thirdparty/calendly/CalendlyWidget';

export function BillingCalendlyWidget(): React.ReactElement {
    return <CustomerCalendlyWidget
        url="https://calendly.com/alindobra/45-minute-meeting"
        iframeTitle="Meeting"
        styles={{
            height: '700px'
        }}
    />
}

export function BillingPage(): React.ReactElement {
    const pStyle = {
        lineHeight: '30px',
        fontWeight: 400,
        fontSize: '20px',
    }
    return <>
        <Helmet>
            <title>45 Minute Meeting</title>
        </Helmet>
        <Grid className="container-fluid grid-container">
            <Row>
                <Col xs={6} md={5} lg={4} className="m-4">
                    <h1 className="text-left mb-4" style={{fontWeight: 700, fontSize: '48px', lineHeight: '60px'}}>
                        Need More Time to Explore Bunnyshell?
                    </h1>
                    <p style={pStyle}>
                        At Bunnyshell, we understand that every project has unique needs and timelines.
                        If you&apos;re finding value in our platform but need a bit more time to fully evaluate its benefits,
                        we&apos;re here to help.
                    </p>
                    <p style={pStyle}>
                        Connect with us to discuss how we can support the specific requirements of your projects.
                        Whether you&apos;re looking to extend your trial or need additional features to enhance your DevOps practices,
                        our team is ready to tailor a solution that works for you.
                    </p>
                </Col>
                <Col xs={6} md={7} lg={8} className="col-xl-auto-big ml-4" style={{marginTop: '-32px'}}>
                    <BillingCalendlyWidget />
                </Col>
            </Row>
        </Grid>
    </>
};

