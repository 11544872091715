import React, { memo, useState, useEffect } from 'react';
import PropTypes, {oneOfType} from 'prop-types';
import * as R from 'ramda';
import StyledGeneric from 'components/timeframes/Generic.style';
import SelectArrow from 'components/selectArrow';
import Select, { SelectOption } from 'theme/uielements/select';
import { noop } from 'utils';
import { formatToMeridian } from './helpers';

const Option = SelectOption;

const HourPicker = ({
  className,
  isReadOnly,
  value,
  startAt,
  placeholder,
  showSearch,
  onChange,
  disableStart,
  availableHours,
}) => {
  const [from, setFrom] = useState(null);
  useEffect(() => {
    const index = R.indexOf(startAt)(availableHours);
    setFrom(index);
  }, [startAt, availableHours]);

  const handleOnChange = (date) => {
    onChange(date);
  };

  const filteredHours = availableHours.filter((_1, index)=> index > from)
  return (
    <StyledGeneric className="timeframe-select-wrapper">
      <div id='hour-container'>
        <Select
          showSearch={showSearch}
          dropdownAlign={{ offset: [0, 4] }}
          suffixIcon={<SelectArrow designType="new" />}
          className={`timeframe-select ${className}`}
          value={disableStart !== true ? formatToMeridian(value) : null}
          placeholder={placeholder}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          disabled={disableStart === true ? true : isReadOnly}
          onChange={handleOnChange}
          getPopupContainer={() => document.getElementById('hour-container')}
        >
          {R.map((hour) => (
            <Option key={hour} value={hour}>
              {formatToMeridian(hour)}
            </Option>
          ))(filteredHours)}
        </Select>
      </div>
    </StyledGeneric>
  );
};

HourPicker.defaultProps = {
  availableHours: [],
  className: '',
  isReadOnly: false,
  value: null,
  startAt: null,
  placeholder: '',
  showSearch: false,
  disableStart: false,
  onChange: noop,
};

HourPicker.propTypes = {
  availableHours: PropTypes.arrayOf(
    oneOfType([PropTypes.shape({}), PropTypes.string])
  ),
  className: PropTypes.string,
  isReadOnly: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  startAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  placeholder: PropTypes.string,
  showSearch: PropTypes.bool,
  onChange: PropTypes.func,
  disableStart: PropTypes.bool,
};

export default memo(HourPicker);
