import type { HydraListResponse, ListParams as BaseListParams } from 'types';
import type { InferEndpointResult } from 'app/services/types';

import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { mapListToTags, defaultBaseQuery } from 'app/services/api';

export type RegistryStatus = 'new' | 'connected' | 'disconnected' | 'detached' | 'locked';

export interface RegistryProvider {
    id: number;
    type: string;
    slug: string;
}

export interface RegistryIntegration {
    id: number;
    name: string;
    status: RegistryStatus;
    url: string;
    /**
     * Organization URI
     */
    organization: string;

    gcpProjectId: string;
    region: string;
    username: string;
}

export interface Edit {
    name: string;
    region: string;
    username: string;

    /**
     * Provider IRI
     *
     * @review move to a slug, expanding on the different extras
     */
    provider: string;

    // different providers have various extras
    accessKey?: string;
    accessSecret?: string;
    repositoryName?: string;

    gcpTokenKey?: string;
    gcpProjectId?: string;
}

export interface Create extends Edit {
    /**
     * Organization IRI
     */
    organization: string;
}

export interface ListParams extends BaseListParams {
    organization: number;
}

export interface ListItem extends RegistryIntegration {
};

export interface Item extends RegistryIntegration {
};

export const TAG = 'registry_integrations';

export const registryIntegrationsApi = createApi({
    reducerPath: `api:neo:${TAG}`,

    baseQuery: defaultBaseQuery,

    tagTypes: [TAG],

    endpoints: (build) => ({
        list: build.query<HydraListResponse<ListItem>, ListParams>({
            query: (params) => {
                const {organization, ...queryParams} = params;
                return {
                    url: `/organizations/${organization}/registry_integrations`,
                    queryParams,
                };
            },
            providesTags: (result) => {
                return mapListToTags({
                    list: result ? result['hydra:member'] : [],
                    idKey: 'id',
                    tag: TAG,
                    includeTag: true,
                    includePartialId: true,
                });
            },
        }),

        get: build.query<Item, string|number>({
            query: (id) => `/registry_integrations/${id}`,
            providesTags: (result) => {
                return mapListToTags({
                    list: result ? [result] : [],
                    idKey: 'id',
                    tag: TAG,
                });
            },
        }),

        create: build.mutation<Item, Create>({
            query: (data) => {
                return {
                    url: '/registry_integrations',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: (result) => {
                return mapListToTags({
                    list: result ? [{id: result.id}] : [],
                    idKey: 'id',
                    tag: TAG,
                    includePartialId: true,
                });
            },
        }),

        edit: build.mutation<Item, {id: number, data: Edit}>({
            query: ({id, data}) => {
                return {
                    url: `/registry_integrations/${id}`,
                    method: 'PUT',
                    body: data,
                };
            },
            invalidatesTags: (result, error, args) => {
                return mapListToTags({
                    list: result ? [{id: args.id}] : [],
                    idKey: 'id',
                    tag: TAG,
                });
            },
        }),
    }),
});

export type AsyncListResult = InferEndpointResult<typeof registryIntegrationsApi.endpoints.list>;
