import React from "react";
import { Menu } from "antd";
import { useIntercom } from "react-use-intercom";
import { Link } from "react-router-dom";
import { SVG } from "components";
import { useAppSelector } from "app/hooks";

import type { IPassthroughLinkItemProps } from "modules/sidebar/items/utils";

export interface IProps extends IPassthroughLinkItemProps {
    key: string
}

export function IntercomItem({
    key,

    ...props
}: IProps): React.ReactElement {
    const intercom = useIntercom();
    const { booted } = useAppSelector(state => state.intercom);

    return <Menu.Item
        {...props}

        key={key}
        title=""
    >
        <Link
            to="/external/contact"
            onClick={(e) => {
                if (booted) {
                    e.preventDefault();
                    intercom.show();
                }
            }}
        >
            <div className="isoMenuHolder">
                <SVG name="bicon-chat-bubble" width={24} height={24} />
                <span className="nav-text">Chat with us</span>
            </div>
        </Link>
    </Menu.Item>
}
