/* eslint-disable */
import { createGlobalStyle, css } from 'styled-components';

const GlobalStyles = createGlobalStyle`
${({ theme, mode }) => {
  const { palette } = theme;

  return css`
    /* ********* Add Your Global CSS Here ********* */

    body {
      -webkit-overflow-scrolling: touch;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: ${palette.fonts[0]};
      #dark {
        color: #fff;
        background-color: ${palette.background[0]};
      }
    }
    body p,
    body a,
    body div,
    body span,
    input,
    textarea,
    li {
      font-family: ${palette.fonts[0]};
      font-variant: initial;
      font-feature-settings: normal;
    }
    html h1,
    html h2,
    html h3,
    html h4,
    html h5,
    html h6,
    b,
    strong {
      margin-bottom: 0;
      font-family: ${palette.fonts[1]};
      letter-spacing: -0.02em;
      font-variant: initial;
      font-weight: normal;
      font-feature-settings: normal;
    }

    div[role='button'] {
      outline: 0;
    }
    .container-fluid,
    .container {
      padding-left: 15px;
      padding-right: 15px;
    }

    /********** HEADING CLASSES  **********/
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      color: ${palette.text[0]};
      transition: all 0.5s;
      #dark & {
        color: ${palette.text[3]};
        transition: all 0.5s;
      }
    }
    h1 {
      font-size: 24px;
      line-height: 32px;
    }
    h4 {
      font-size: 20px;
      line-height: 30px;
    }
    h4.ant-typography {
      font-size: 20px;
      line-height: 30px;
      color: ${palette.text[0]};
      font-weight: normal;
      transition: all 0.5s;
      #dark & {
        color: ${palette.text[3]};
        transition: all 0.5s;
      }
    }
    h5 {
      font-size: ${palette.fontSize[0]};
      line-height: 1;
    }
    .ant-typography strong {
      font-weight: normal;
    }
    h6 {
      font-size: ${palette.fontSize[1]};
      line-height: 1;
    }

    .font-12 {
      font-size: 12px !important;
    }

    p {
      color: ${palette.grays[4]};
      &.description {
        line-height: 18px;
      }
      #dark & {
        color: ${palette.grays[7]};
      }
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
      .icon {
        &.default {
          display: block;
        }
        &.active {
          display: none;
        }
      }
      &:hover {
        .icon {
          &.default {
            display: none;
          }
          &.active {
            display: block;
          }
        }
      }
      &.ant-btn {
        height: 34px;
        border-radius: 4px;
        font-family: ${palette.fonts[1]};
      }
      &.ant-btn-secondary {
        background-color: ${palette.secondary[0]};
        border: 1px solid ${palette.secondary[0]};
        color: ${palette.text[0]};
        &[disabled] {
          color: ${palette.text[1]};
          &:hover {
            border: 1px solid ${palette.secondary[0]};
            background-color: ${palette.secondary[0]};
            color: ${palette.text[1]};
          }
        }
        &:hover {
          border: 1px solid ${palette.secondary[1]};
          background-color: ${palette.secondary[1]};
          color: ${palette.primary[0]};
        }
      }

      &.disabled, &:hover.disabled {
        color: ${palette.text[1]};
        cursor: not-allowed;
        #dark & {
          color: ${palette.grays[5]};
        }
      }
    }
    a {
      color: ${palette.primary[0]};
      &:hover {
        color: ${palette.primary[0]};
      }
      &.ant-btn-sm {
        padding-top: 5px !important;
      }
      #dark & {
        color: ${palette.grays[8]};
        &.ant-btn-primary {
          color: ${palette.grays[8]};
        }
      }
    }
    .link {
      color: ${palette.primary[0]} !important;
      cursor: pointer;
      text-decoration: none;
      font-family: ${palette.fonts[0]};
      &:hover {
        text-decoration: none;
        border: 0 !important;
      }
      &.small {
        font-size: ${palette.fontSize[2]};
        line-height: 15px;
      }
      &.disabled {
        color: ${palette.text[1]} !important;
        cursor: not-allowed;
        #dark & {
          color: ${palette.grays[5]} !important;
        }
      }
      #dark & {
        color: ${palette.grays[8]} !important;
      }
    }

    .bold {
      font-family: ${palette.fonts[1]} !important;
    }

    .medium {
      font-family: ${palette.fonts[2]} !important;
    }

    .light {
      font-family: ${palette.fonts[0]} !important;
    }

    .ellipsis {
      display: block !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .multiline-ellipsis-2 {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: pre-wrap;
    }

    /********** POSITIONING CLASSES  **********/
    .p-relative {
      position: relative !important;
    }
    .d-none {
      display: none !important;
    }
    .d-block {
      display: block !important;
    }
    .d-flex {
      display: flex !important;
      flex-wrap: wrap;
    }
    .d-grid {
      display: grid;
    }
    .d-inline-block {
      display: inline-block !important;
    }
    .d-inline-flex {
      display: inline-flex !important;
    }
    .justify-content-center {
      justify-content: center !important;
    }
    .justify-content-end {
      justify-content: flex-end !important;
    }
    .hidden {
      visibility: hidden;
    }
    @media (min-width: 768px){
        .d-md-block{
                display: block !important;
        }
        .d-md-flex{
                display: flex !important;
        }
    }
    .justify-content-start {
      justify-content: flex-start !important;
    }
    .justify-content-between {
      justify-content: space-between !important;
    }
    .justify-content-center {
      justify-content: center !important;
    }
    .align-items-center {
      align-items: center !important;
    }
    .align-items-start {
      align-items: start;
    }
    .align-items-end {
      align-items: end;
    }
    .flex-direction-column {
      flex-direction: column;
    }
    .flex-direction-row {
      flex-direction: row;
    }
    @media (min-width: 1200px){
      .justify-content-xl-end {
        justify-content: flex-end !important;
      }
    }
    .w-100 {
      width: 100%;
    }
    .h-100 {
      height: 100%;
    }
    .text-left {
      text-align: left;
    }
    .text-center {
      text-align: center;
    }
    .text-right {
      text-align: right;
    }
    .flex-nowrap {
      flex-wrap: nowrap;
    }
    .flex-wrap {
      flex-wrap: wrap;
    }
    .flex-grow-1 {
      flex-grow: 1;
    }
    @media (min-width: 768px) {
      .text-md-center {
        text-align: center !important;
      }
      .text-md-right {
        text-align: right !important;
      }
      .justify-content-md-end {
        justify-content: flex-end !important;
      }
    }
    @media (min-width: 992px) {
        .flex-lg-nowrap{
                flex-wrap: nowrap;
        }
    }
    /********** GRID SYSTEM CLASSES  **********/
    .container {
      margin-left: 0px;
      width: 100%;
    }
    .row {
      margin-right: -12px !important;
      margin-left: -12px !important;
    }

    .col-auto,
    .col-lg,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12 {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
    .col-xs-12,
    .col-xs-6,
    .col-md,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12 {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
    
    .ant-layout-has-sider .ant-layout.layout-content {
      padding-left: 72px;
      &.config-env {
        padding-left: 0;
      }
    }

    .ant-layout-content {
      padding: 70px 0 0;
      flexshrink: 0;
      background: #ffffff;
      position: relative;
      transition: all 0.5s;
      #dark & {
        background: ${palette.background[0]} !important;
        color: #fff;
        transition: all 0.5s;
      }
    }

    #dark .ant-collapse {
      background: transparent;

      > .ant-collapse-item {
        > .ant-collapse-content {
          background: transparent;
        }

        > .ant-collapse-header {
          color: #FFFFFF;
        }
      }
    }

    .flex-auto {
      flex: 1;
    }

    @media (min-width: 992px) {
      .col-lg-auto {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none;
      }
      .col-lg-auto-big {
        -webkit-flex: 1;
        flex: 1;
        width: 100%;
        min-width: 500px;
      }
    }
    @media (min-width: 1200px) {
      .col-xl-auto {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none;
      }
      .col-xl-auto-big {
        -webkit-flex: 1;
        flex: 1;
        width: 100%;
        max-width: 100%;
      }
      .col-xl-3{
        padding-left: 12px !important;
        padding-right: 12px !important;
      }
      .col-xl-9{
        padding-left: 12px !important;
        padding-right: 12px !important;
      }
    }

    /********** SPACING CLASSES  **********/
    .col-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .mb-18 {
      margin-bottom: 18px !important;
    }
    .mb-05 {
      margin-bottom: 4px !important;
    }
    .mt-05 {
      margin-top: 4px !important;
    }
    .ml-05 {
      margin-left: 4px;
    }
    .mr-05 {
      margin-right: 4px;
    }
    .overflow-x-only {
      overflow-x: auto;
      overflow-y: hidden;
    }
    .m-0 {
      margin: 0 !important;
    }
    .mt-0,
    .my-0 {
      margin-top: 0 !important;
    }
    .mr-0,
    .mx-0 {
      margin-right: 0 !important;
    }
    .mb-0,
    .my-0 {
      margin-bottom: 0 !important;
    }
    .ml-0,
    .mx-0 {
      margin-left: 0 !important;
    }
    .m-1 {
      margin: 8px !important;
    }
    .mt-1,
    .my-1 {
      margin-top: 8px !important;
    }
    .mr-1,
    .mx-1 {
      margin-right: 8px !important;
    }
    .mb-1,
    .my-1 {
      margin-bottom: 8px !important;
    }
    .ml-1,
    .mx-1 {
      margin-left: 8px !important;
    }
    .m-2 {
      margin: 16px !important;
    }
    .mt-2,
    .my-2 {
      margin-top: 16px !important;
    }
    .mr-2,
    .mx-2 {
      margin-right: 16px !important;
    }
    .ml-1-5 {
      margin-left: 12px !important;
    }
    .mr-1-5 {
      margin-right: 12px !important;
    }
    .mb-1-5 {
      margin-bottom: 12px !important;
    }
    .mt-1-5 {
      margin-top: 12px !important;
    }
    .mb-2,
    .my-2 {
      margin-bottom: 16px !important;
    }
    .ml-2,
    .mx-2 {
      margin-left: 16px !important;
    }
    .m-3 {
      margin: 24px !important;
    }
    .mt-3,
    .my-3 {
      margin-top: 24px !important;
    }
    .mr-3,
    .mx-3 {
      margin-right: 24px !important;
    }
    .mb-3,
    .my-3 {
      margin-bottom: 24px !important;
    }
    .ml-3,
    .mx-3 {
      margin-left: 24px !important;
    }
    .m-4 {
      margin: 32px !important;
    }
    .mt-4,
    .my-4 {
      margin-top: 32px !important;
    }
    .mr-4,
    .mx-4 {
      margin-right: 32px !important;
    }
    .mb-4,
    .my-4 {
      margin-bottom: 32px !important;
    }
    .ml-4,
    .mx-4 {
      margin-left: 32px !important;
    }
    .m-5 {
      margin: 40px !important;
    }
    .mt-5,
    .my-5 {
      margin-top: 40px !important;
    }
    .mr-5,
    .mx-5 {
      margin-right: 40px !important;
    }
    .mb-5,
    .my-5 {
      margin-bottom: 40px !important;
    }
    .ml-5,
    .mx-5 {
      margin-left: 40px !important;
    }
    .mb-6 {
      margin-bottom: 48px !important;
    }
    .mt-6 {
      margin-top: 48px !important;
    }
    .p-0 {
      padding: 0 !important;
    }
    .pt-0,
    .py-0 {
      padding-top: 0 !important;
    }
    .pr-0,
    .px-0 {
      padding-right: 0 !important;
    }
    .pb-0,
    .py-0 {
      padding-bottom: 0 !important;
    }
    .pl-0,
    .px-0 {
      padding-left: 0 !important;
    }
    .px-05{
        padding-left: 4px !important;
        padding-right: 4px !important;
    }
    .p-1 {
      padding: 8px !important;
    }
    .pt-1,
    .py-1 {
      padding-top: 8px !important;
    }
    .pr-1,
    .px-1 {
      padding-right: 8px !important;
    }
    .pb-1,
    .py-1 {
      padding-bottom: 8px !important;
    }
    .pl-1,
    .px-1 {
      padding-left: 8px !important;
    }
    .pl-1-5 {
      padding-left: 12px !important;
    }
    .p-2 {
      padding: 16px !important;
    }
    .pt-2,
    .py-2 {
      padding-top: 16px !important;
    }
    .pr-2,
    .px-2 {
      padding-right: 16px !important;
    }
    .pb-2,
    .py-2 {
      padding-bottom: 16px !important;
    }
    .pl-2,
    .px-2 {
      padding-left: 16px !important;
    }
    .p-3 {
      padding: 24px !important;
    }
    .pt-3,
    .py-3 {
      padding-top: 24px !important;
    }
    .pr-3,
    .px-3 {
      padding-right: 24px !important;
    }
    .pb-3,
    .py-3 {
      padding-bottom: 24px !important;
    }
    .pl-3,
    .px-3 {
      padding-left: 24px !important;
    }
    .p-4 {
      padding: 32px !important;
    }
    .pt-4,
    .py-4 {
      padding-top: 32px !important;
    }
    .pr-4,
    .px-4 {
      padding-right: 32px !important;
    }
    .pb-4,
    .py-4 {
      padding-bottom: 32px !important;
    }
    .pl-4,
    .px-4 {
      padding-left: 32px !important;
    }
    .p-5 {
      padding: 40px !important;
    }
    .pt-5,
    .py-5 {
      padding-top: 40px !important;
    }
    .pr-5,
    .px-5 {
      padding-right: 40px !important;
    }
    .pb-5,
    .py-5 {
      padding-bottom: 40px !important;
    }
    .pb-6,
    .py-6 {
      padding-bottom: 48px !important;
    }
    .pl-5,
    .px-5 {
      padding-left: 40px !important;
    }
    .m-auto {
      margin: auto !important;
    }
    .mt-auto,
    .my-auto {
      margin-top: auto !important;
    }
    .mr-auto,
    .mx-auto {
      margin-right: auto !important;
    }
    .mb-auto,
    .my-auto {
      margin-bottom: auto !important;
    }
    .ml-auto,
    .mx-auto {
      margin-left: auto !important;
    }
    @media (min-width: 768px) {
      .mb-md-0 {
        margin-bottom: 0 !important;
      }
      .mt-md-0 {
        margin-top: 0 !important;
      }
      .mb-md-4 {
        margin-bottom: 32px !important;
      }
      .mb-md-5{
        margin-bottom: 40px !important;
      }
      .mr-md-2 {
        margin-right: 16px !important;
      }
      .px-md-4{
        padding-left: 32px !important;
        padding-right: 32px !important;
      }
      .ml-md-3{
        margin-left: 24px !important;
      }
    }
    @media (min-width: 1200px){
      .mb-xl-0{
        margin-bottom: 0 !important;
      }
    }
    .overflow-x-only {
      overflow-x: auto;
      overflow-y: hidden;
      #dark & {
        &::-webkit-scrollbar {
          background-color: transparent;
        }
        &::-webkit-scrollbar-track-piece {
        }
        &::-webkit-scrollbar-thumb:horizontal {
          background-color: ${palette.grays[4]};
          border: 4px solid transparent;
          border-radius: 9px;
          background-clip: content-box;
        }
      }
    }
    .maxWidth100 {
      max-width: 100px;
    }
    .maxWidth200 {
      max-width: 200px;
    }
    .maxWidth250 {
      max-width: 250px;
    }
    .maxWidth350 {
      max-width: 350px;
    }
    .maxWidth500 {
      max-width: 500px;
    }
    .maxWidth650 {
      max-width: 650px;
    }
    .maxWidth700 {
      max-width: 700px;
    }
    .maxWidth900 {
      max-width: 900px;
    }
    .gap-1 {
      gap: 8px;
    }
    .gap-2 {
      gap: 16px;
    }
    /********** COLOR CLASSES  **********/
    .admin-color {
      color: ${palette.success[0]} !important;
    }
    .color-black {
      color: ${palette.grays[1]} !important;
    }
    .color-gray {
      color: ${palette.grays[4]} !important;
      #dark & {
        color: ${palette.grays[7]} !important;
      }
    }
    .color-error {
      color: ${palette.error[0]} !important;
    }
    .bg-white {
      background-color: ${palette.text[3]} !important;
    }
    .cursor-pointer {
      cursor: pointer;
    }

    .overflowAuto {
      overflow: auto;
    }

    /**************************************/
    /********** ELEMENTS CLASSES **********/
    /**************************************/

    /********** CARD CLASSES **********/
    .card {
      padding: 30px;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      #dark & {
        border: 1px solid ${palette.border[4]};
      }
      p.description {
        line-height: 18px;
      }
      .card-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 12px;
      }
      @media (max-width: 768px) {
        padding: 15px;
      }
    }
    .search-container {
      position: relative;
      .vertical-separator {
        height: 28px;
        width: 1px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: ${palette.border[0]};
        #dark & {
          background-color: ${palette.border[4]};
        }
        &.right {
          right: 16px;
        }
        &.left {
          left: 16px;
        }
      }
    }
    .input-search {
      &.no-max-width {
        &.ant-input-group-wrapper {
          width: auto;
        }
      }
      &:not(.no-max-width) {
        width: 256px;

        .ant-input-wrapper {
          max-width: 240px;
        }
      }

      @media (max-width: 768px){
          margin-bottom: 16px;
        }
      &.border-left {
        padding-left: 16px;
        margin-left: 16px;
      }
      &.border-right {
        padding-right: 16px;
        margin-right: 16px;
      }
      &.no-border {
      }
      .ant-input-wrapper {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        background: transparent;
        input {
          background: transparent;
          padding: 6px 12px 6px 44px;
          font-size: 14px;
          line-height: 1;
          height: 36px;
          border-radius: 4px;
          #dark & {
            color: ${palette.grays[7]};
            background-color: ${palette.background[1]};
            border: 1px solid ${palette.grays[4]};
          }
          &::-webkit-input-placeholder,
          &::placeholder {
            font-variant: inherit;
            font-feature-settings: normal;
            color: ${palette.text[2]};
            #dark & {
              color: ${palette.grays[4]};
            }
          }
          &:hover {
            border-color: ${palette.border[0]};
          }
          &:focus {
            border-color: ${palette.primary[0]};
            box-shadow: 0 0 4px 1px rgba(34, 5, 150, 0.2);
            color: ${palette.color[1]};
            #dark & {
              border-color: ${palette.border[0]};
              color: ${palette.grays[7]};
            }
          }
          &:first-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
        .ant-input-group-addon {
          width: 0px;
          left: 16px !important;
          z-index: 1;
          > div {
            width: 20px;
            height: 20px;
          }
          button {
            display: none;
            margin-left: 6px;
            border: 0px;
            cursor: default;
            background: transparent;
            &:after {
              display: none;
            }
            #dark & {
              svg {
                fill: ${palette.grays[4]};
              }
            }
          }
        }
      }
    }
    /********** LABEL TEXT CLASSES **********/
    .multiple-select {
      width: 100%;
      &.error {
        .ant-select-selector {
          border: 1px solid ${palette.error[0]} !important;
          #dark & {
            border: 1px solid ${palette.error[0]} !important;
          }
        }
      }
    }

    /********** TOOLTIP CLASSES **********/

    .ant-tooltip {
      max-width: 350px;
      white-space: break-spaces;
      &.ant-tooltip-placement-right {
        padding-left: 0 !important;
      }
      &.state-tooltip {
        max-width: 310px;
      }
      .ant-tooltip-content {
        .ant-tooltip-arrow {
          width: 16px;
          height: 16px;
          .ant-tooltip-arrow-content {
            background-color: ${palette.background[0]};
            width: 8px;
            height: 8px;
            transition: backgroundColor 0.5s;
            --antd-arrow-background-color: ${palette.background[0]};
            #dark & {
              transition: backgroundColor 0.5s;
              background-color: ${palette.background[1]};
              --antd-arrow-background-color: ${palette.background[1]};
            }
          }
        }
        .ant-tooltip-inner {
          background-color: #333;
          border-radius: 4px;
          color: ${palette.text[7]};
          box-shadow: 0px 5px 10px -1px rgba(0, 0, 0, 0.15);
          padding: 12px;
          font-size: ${palette.fontSize[2]};
          font-family: ${palette.fonts[2]};
          line-height: 14px;
          min-height: 26px;
          word-wrap: normal;
          a {
            text-decoration: none;
            .isoMenuHolder {
              .icon {
                display: none;
              }
              .nav-text {
                color: ${palette.text[3]};
                font-family: ${palette.fonts[2]};
              }
            }
          }
        }
      }
    }
    #dark {
      .ant-tooltip {
        margin-left: 3px;
        .ant-tooltip-arrow-content {
          background-color: #fff;
        }
        .ant-tooltip-inner {
          background-color: ${palette.background[1]};
          .nav-text {
            color: ${palette.grays[10]};
          }
          .isoMenuHolder > div {
            display: none;
          }
        }
      }
      input:-internal-autofill-selected,
      input:-webkit-autofill {
        background-color: ${palette.background[1]} !important;
        -webkit-box-shadow: 0 0 0 30px ${palette.background[1]} inset !important;
        -webkit-text-fill-color: ${palette.grays[7]} !important;
      }

      .react-loading-skeleton {
        background-color: #333;
      }
      .react-loading-skeleton::after {
        background-image: linear-gradient(90deg, #333333, #4c4c4c, #333333);
      }
    }
    /********** FILTER OPTIONS CLASSES **********/
    .filter-container {
      position: relative;
      padding-right: 16px;
      margin-right: 16px;
      @media (max-width: 768px){
        margin-bottom: 16px;
        padding-right: 4px;
        margin-right: 3px;
      }
      .vertical-separator {
        height: 28px;
        width: 1px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: ${palette.border[0]};
        #dark & {
          background-color: ${palette.border[4]};
        }
        &.right {
          right: 0px;
        }
        &.left {
          left: 0px;
        }
      }
    }
    .filter-repos {
      border: 1px solid ${palette.border[0]};
      padding: 4px;
      margin-left: 18px;
      display: inline-flex;
      justify-content: space-between;
      border-radius: 4px;
      #dark & {
        border: 1px solid ${palette.border[4]};
      }
      .filter {
        outline: 0;
        font-size: 14px;
        line-height: 18px;
        padding: 4px 8px;
        color: ${palette.text[0]};
        cursor: pointer;
        border-radius: 2px;
        @media (max-width: 767px){
          padding: 4px;
        }
        #dark & {
          color: ${palette.grays[6]};
        }
        &.mr-1:last-child {
          margin-right: 0 !important;
        }
        &:hover {
          background-color: #faf9ff;
          color: ${palette.primary[0]};
          #dark & {
            background-color: ${palette.background[4]};
            color: ${palette.grays[11]};
          }
        }
        &.active {
          background-color: ${palette.primary[3]};
          color: ${palette.primary[0]};
          #dark & {
            background-color: ${palette.background[4]};
            color: ${palette.grays[11]};
          }
          &:hover {
            background-color: ${palette.primary[3]};
            color: ${palette.primary[0]};
            #dark & {
              background-color: ${palette.background[4]};
              color: ${palette.grays[11]};
            }
          }
        }
      }
    }
    //line height
    .lh-20 {
      line-height: 20px;
    }
    svg {
      &.doc-icon {
        width: 20px;
        height: 18px;
      }
      &.icon {
        width: 16px;
        height: 16px;

        &.f10 {
          width: 10px;
          height: 10px;
        }
        &.f14 {
          width: 14px;
          height: 14px;
        }
        &.f12 {
          width: 12px;
          height: 12px;
        }
        &.f13 {
          width: 13px;
          height: 13px;
        }
        &.f18 {
          width: 18px;
          height: 18px;
        }
        &.f20 {
          width: 20px;
          height: 20px;
        }
        &.f22 {
          width: 22px;
          height: 22px;
        }
        &.f24 {
          width: 24px;
          height: 24px;
        }
        &.f28 {
          width: 28px;
          height: 28px;
        }
        &.f32 {
          width: 32px;
          height: 32px;
        }
        &.f40 {
          width: 40px;
          height: 40px;
        }
        &.rotate180 {
          transform: rotate(180deg);
        }
      }
    }

    .ant-btn-text {
      #dark & {
          color: ${palette.text[3]};
      }
    }

    .ant-input-group-addon {
      background-color: ${palette.primary[3]};

      #dark & {
          background-color: ${palette.background[1]};
          border-color: ${palette.border[4]};
          color: ${palette.text[3]};
      }
    }

    .ant-dropdown-menu {
      box-shadow: rgba(0, 0, 0, 0.2) 0 4px 14px 0, rgba(0, 0, 0, 0.0470588) 0 0 0 1px;
      #dark & {
        background-color: #1c1c1c;
        border: 1px solid ${palette.border[4]};
      }

      .ant-dropdown-menu-item-divider {
        #dark & {
          background-color: ${palette.grays[4]};
        }
      }

      .ant-dropdown-menu-item {
        #dark & {
          color: ${palette.grays[11]};
          &:hover {
            background-color: ${palette.background[1]} !important;
          }
          a,
          div.env-actions-item {
            color: ${palette.grays[11]};
            &.delete{
                &:hover:not(.disabled){
                  color: ${palette.error[0]};
                } 
            }
            &:hover:not(.disabled) {
              background-color: ${palette.background[1]};
            }
            svg {
              use {
                stroke: ${palette.background[2]};
              }
            }
          }
        }
      }
      &.cluster-select {
        box-shadow: 0 0 0;
        max-width: 380px;
        &.small {
          margin-top: 0;
          border-radius: 4px !important;
        }
        .ant-dropdown-menu-item.disconnected,
        .ant-dropdown-menu-item.locked {
          cursor: not-allowed;
          color: #ccc;
        }
        .link-menu-item {
          a {
            font-size: 12px;
          }
        }
        .ant-dropdown-menu-title-content {
          width: 100%;
          .disconnected,
          .locked {
            color: #ccc;
          }
        }
      }
    }
    .ant-dropdown-menu-title-content > a::after {
      display: none;
    }

    .terraform-input-variable-modal {
      .row-form {
        min-height: 255px;
        height: auto;
        margin-top: 12px;
      }
      textarea.ant-input {
        height: 125px !important;
        resize: none !important;
      }
    }

    .composer-error-code {
      padding: 20px 0;
      background: ${palette.background[0]};
      max-height: 432px;
      height: auto;
      overflow: auto;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      &::-webkit-scrollbar-track-piece {
        display: none;
      }
      &::-webkit-scrollbar-thumb:vertical {
        display: none;
      }
      #dark & {
        background: ${palette.background[1]};
        border: 1px solid ${palette.border[4]};
      }
    }
    .ant-menu.ant-menu-submenu-popup {
      .ant-menu.ant-menu-sub.ant-menu-vertical {
        margin-left: 9px;
        box-shadow: rgba(0, 0, 0, 0.2) 0 4px 14px 0, rgba(0, 0, 0, 0.0470588) 0 0 0 1px;
        scrollbar-width: none;
        #dark & {
          background-color: #1c1c1c;
          border: 1px solid ${palette.border[4]};
        }
        &::-webkit-scrollbar {
          display: none;
        }
        &::-webkit-scrollbar-track-piece {
          display: none;
        }
        &::-webkit-scrollbar-thumb:vertical {
          display: none;
        }
      }
      &.full {
        margin-top: 12px;
        height: calc(100vh - 24px);
        .ant-menu.ant-menu-sub.ant-menu-vertical {
          margin-top: 12px;
          height: 100%;
          max-height: 100%;
        }
      }
    }

    /** why is this global ? **/
    .ant-menu-submenu {
      .ant-menu-title-content {
        .isoMenuHolder {
          display: flex;
          align-items: center;
          width: 100%;

          svg {
            margin-right: 18px;
          }
        }
      }
    }

    .org-submenu {
      width: 250px;
      padding: 4px 0 !important;
      border-radius: 4px;
      height: 100% !important;
      &.with-search {
        min-width: 437px;
        height: 100%;
        max-width: 437px;
      }
      .org-list-container {
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
        &::-webkit-scrollbar-track-piece {
          display: none;
        }
        &::-webkit-scrollbar-thumb:vertical {
          display: none;
        }
        .link-org {
          cursor: pointer;
          display: flex;
          width: 100%;
          padding: 8px 16px;
          justify-content: space-between;
          align-items: center;
          color: ${palette.grays[2]};
          font-size: ${palette.fontSize[1]};
          line-height: 20px;
          #dark & {
            color: ${palette.grays[7]};
          }
          span {
            width: calc(100% - 20px);
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
            overflow: hidden;
          }
          .icon {
            display: none;
            margin-left: 10px;
          }
          &:hover {
            color: ${palette.grays[2]};
            background-color: ${palette.primary[3]};
            text-decoration: none;
            #dark & {
              background-color: ${palette.background[1]};
              color: ${palette.grays[10]};
            }
          }
          &.active {
            color: ${palette.grays[2]};
            background-color: ${palette.primary[3]};
            #dark & {
              background-color: ${palette.background[1]};
              color: ${palette.grays[10]};
            }
            .icon {
              display: block;
            }
          }
        }
      }
    }

    .loading-anim,
    .loading-anim.ant-btn[class][disabled] {
      background-color: #1f2dcf !important;
      border-color: #1f2dcf !important;
      cursor: not-allowed;

      .button-loader {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
      }

      span, .svgStyle {
        visibility: hidden;
      }

      .preventHidden {
        span {
          visibility: visible;
        }
      }
    }

    // acl loader
    .loading-acl.ant-btn[class][disabled] {
      .aclLoader {
        // position: absolute;
        // left: 0;
        // right: 0;
        // margin: 0 auto;
        // top: 50%;
        // transform: translateY(-50%);
      }

      span:not(.ant-spin-dot), .svgStyle {
        // visibility: hidden;
      }
    }

    .ant-popover {
      font-variant: inherit;
      font-feature-settings: normal;
    }
    .ant-input:focus,
    .ant-input-focused,
    .ant-input:hover {
      border: 1px solid ${palette.border[0]};
      box-shadow: 0 0 0;
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper:hover {
      border: 1px solid ${palette.border[0]};
      box-shadow: 0 0 0;
    }
    .ant-input {
      color: ${palette.text[0]};
      border: 1px solid ${palette.border[0]};
      font-variant: inherit;
      font-feature-settings: normal;
    }
    .ant-form-item:not(.customMargin) {
      margin-bottom: 18px !important;
      font-feature-settings: inherit !important;
    }
    .ant-form-item.large {
      .ant-input-affix-wrapper {
        padding: 0px;
        border-radius: 4px;
      }
      .ant-input {
        padding: 13px;
        font-size: ${palette.fontSize[2]};
        line-height: 15px;
        border-radius: 4px;
        outline: 0px;
        box-shadow: 0 0 0;
      }
    }

    .errorTextStyle {
      color: ${palette.error[0]} !important;
      font-size: 12px;
      line-height: 12px;
      margin-bottom: 0;

      &:not(.absolute) {
        margin-top: 4px;
      }

      &.absolute {
        position: absolute;
        bottom: -20px;
        left: 0;
      }

      &.toRight {
        left: auto;
        right: 0;
      }

      &.noBottom {
        bottom: 0;
        padding-bottom: 12px;
      }

      #dark & {
        color: ${palette.error[0]} !important;
      }
    }

    .ant-form-item-control-input-content {
      position: relative;
      .labelError {
        position: absolute;
        &.longErrorMessage {
          position: relative;
        }
        top: 100%;
        left: 0px;
        font-size: ${palette.fontSize[5]};
        color: ${palette.error[0]} !important;
        margin-bottom: 0px;
        #dark & {
          color: ${palette.error[0]} !important;
        }
      }
      input:-webkit-autofill ~ label {
        transform: translate(0, -20px);
        font-size: ${palette.fontSize[5]};
      }
      &:focus-within, &:has(input[value]:not([value=""]), input[placeholder]:not(:placeholder-shown), .ant-select-selection-item[title], textarea:not(:empty)) {
        label, div[role=definition] {
          transform: translate(0, -20px);
          font-size: ${palette.fontSize[5]} !important;
          line-height: 15px;
          &.small {
            transform: translate(0, -17px);
          }
        }
      }

      label, div[role=definition] {
        position: absolute !important;
        pointer-events: none;
        transform: translate(0, 0px) scale(1);
        transform-origin: top left;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        top: 1px;
        left: 6px;
        z-index: 1;
        margin: 12px 0;
        padding: 0 6px;
        font-size: ${palette.fontSize[2]};
        line-height: 15px;
        color: ${palette.text[1]};
        background-color: ${palette.color[7]};
        font-family: ${palette.fonts[0]};
        &.small {
          font-size: 12px;
          line-height: 12px;
          margin: 10px 0px;
          &.filled {
            transform: translate(0, -17px);
            font-size: ${palette.fontSize[5]};
          }
        }
        &.filled {
          transform: translate(0, -20px);
          font-size: ${palette.fontSize[5]};
        }
        #dark & {
          background-color: ${palette.background[1]};
          color: ${palette.grays[4]};
          border-radius: 1px;
        }
      }
    }

    // [ND-4943] Fix select (git repository) after antd version change
    .ant-form-horizontal .ant-form-item-control.ant-form-item-control {
      min-width: 100%;
      flex: auto;
    }
    // END [ND-4943] Fix select (git repository) after antd version change

    .ant-form-item-label {
      display: block;
      width: 100%;
      text-align: left;
    }
    .ant-form-item-label > label {
      font-size: ${palette.fontSize[2]};
      color: ${palette.grays[1]};
      height: 15px;
      margin-bottom: 6px;
      display: block;
      width: 100%;
      #dark & {
        color: ${palette.grays[10]};
      }
      &:after {
        display: none;
      }
    }

    .ant-select-item-option-content {
      display: flex;
      align-items: center;
    }

    .ant-select-dropdown {
      border: 1px solid ${palette.border[0]};
      box-shadow: 0 0 0 !important;
      border-radius: 4px;
      #dark & {
        border: 1px solid ${palette.border[4]};
        .rc-virtual-list-scrollbar {
          width: 6px !important;
        }
        .rc-virtual-list-scrollbar-thumb {
          background: ${palette.grays[4]} !important;
        }
      }
      .ant-select-item-empty {
        .ant-empty-description {
          #dark & {
            color: ${palette.grays[9]};
          }
        }
      }
      .connect-git-account {
        font-size: ${palette.fontSize[2]};
        padding: 10px 14px;
        line-height: 16px;
        display: block;
        &:hover {
          #dark & {
            //background-color: ${palette.background[1]};
          }
        }
      }
      .ant-select-item {
        padding: 10px 14px;
        color: ${palette.grays[4]};
        font-size: ${palette.fontSize[2]};
        line-height: 16px;
        min-height: 22px;
        #dark & {
          color: ${palette.grays[7]};
        }
        &.ant-select-item-option-grouped {
          padding: 10px 24px;
        }
        &.ant-select-item-option-active {
          color: ${palette.text[0]};
          background-color: ${palette.primary[3]};
          #dark & {
            background-color: ${palette.background[1]};
            color: ${palette.grays[9]};
          }
        }
        &.ant-select-item-option-disabled {
          color: ${palette.grays[7]} !important;
          #dark & {
            color: ${palette.grays[4]} !important;
          }
          span {
            #dark & {
              color: ${palette.grays[4]} !important;
            }
          }
        }
        &.ant-select-item-option-selected {
          color: ${palette.text[0]};
          background-color: #dddddd;
          font-weight: normal;
          #dark & {
            background-color: ${palette.background[1]};
            color: ${palette.grays[10]};
          }
        }
      }
      #dark & {
        background-color: #1c1c1c;
      }
    }
    .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
    .ant-form-item-has-error
      :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
    .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
    .ant-form-item-has-error
      :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
      box-shadow: 0 0 0;
    }
    .hasUserTokenId .ant-form-item-explain {
      // @see src/components/dialog/Dialog.style.js
      position: static;

      .ant-form-item-explain-error {
        position: static;
      }
    }
    .ant-form-item-explain {
      font-size: ${palette.fontSize[5]};
      position: absolute;
      top: 37px;
      min-height: 10px;
    }
    .ant-form-item-explain-error {
      position: absolute;
      font-size: ${palette.fontSize[5]};
      min-height: 10px;
      top: 0;
      transition: none;
      > div {
        display: none;
        &:first-child {
          display: block;
        }
      }
    }

    .search-org-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      margin-top: 6px;
      margin-bottom: 24px;
      .input-search .ant-input-wrapper {
        max-width: 100%;
      }
    }
    .ant-modal.port-forwarding-modal.large {
      width: 760px !important;
    }
    .modal-bash-container {
      padding: 24px;
      background-color: ${palette.grays[10]};
      border: 1px solid ${palette.border[0]};
      border-radius: 4px;
      color: ${palette.grays[0]};
      font-family: Menlo-Regular;
      margin-bottom: 30px;

      .accessKey {
        font-family: Menlo-Regular;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
            
      .accessValue {
        font-family: Menlo-Regular;
        font-size: 14px;
      }

      .costVal {
        font-family: Menlo-Regular;
        font-size: 14px;
      }

      .port-no {
        color: ${palette.warning[0]};
        font-family: Menlo-Regular;
      }
      .public-id {
        color: ${palette.primary[0]};
        font-family: Menlo-Regular;
        #dark & {
          color: ${palette.primary[5]};
        }
      }
      #dark & {
        background-color: ${palette.background[1]};
        color: ${palette.grays[11]};
        border: 1px solid ${palette.border[4]};
      }
    }
    @keyframes borders {
      0% {
        background-image: linear-gradient(
          0deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      1.6566666667% {
        background-image: linear-gradient(
          0deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      1.6666666667% {
        background-image: linear-gradient(
          6deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      3.3233333333% {
        background-image: linear-gradient(
          6deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      3.3333333333% {
        background-image: linear-gradient(
          12deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      4.99% {
        background-image: linear-gradient(
          12deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      5% {
        background-image: linear-gradient(
          18deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      6.6566666667% {
        background-image: linear-gradient(
          18deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      6.6666666667% {
        background-image: linear-gradient(
          24deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      8.3233333333% {
        background-image: linear-gradient(
          24deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      8.3333333333% {
        background-image: linear-gradient(
          30deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      9.99% {
        background-image: linear-gradient(
          30deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      10% {
        background-image: linear-gradient(
          36deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      11.6566666667% {
        background-image: linear-gradient(
          36deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      11.6666666667% {
        background-image: linear-gradient(
          42deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      13.3233333333% {
        background-image: linear-gradient(
          42deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      13.3333333333% {
        background-image: linear-gradient(
          48deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      14.99% {
        background-image: linear-gradient(
          48deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      15% {
        background-image: linear-gradient(
          54deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      16.6566666667% {
        background-image: linear-gradient(
          54deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      16.6666666667% {
        background-image: linear-gradient(
          60deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      18.3233333333% {
        background-image: linear-gradient(
          60deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      18.3333333333% {
        background-image: linear-gradient(
          66deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      19.99% {
        background-image: linear-gradient(
          66deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      20% {
        background-image: linear-gradient(
          72deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      21.6566666667% {
        background-image: linear-gradient(
          72deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      21.6666666667% {
        background-image: linear-gradient(
          78deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      23.3233333333% {
        background-image: linear-gradient(
          78deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      23.3333333333% {
        background-image: linear-gradient(
          84deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      24.99% {
        background-image: linear-gradient(
          84deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      25% {
        background-image: linear-gradient(
          90deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      26.6566666667% {
        background-image: linear-gradient(
          90deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      26.6666666667% {
        background-image: linear-gradient(
          96deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      28.3233333333% {
        background-image: linear-gradient(
          96deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      28.3333333333% {
        background-image: linear-gradient(
          102deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      29.99% {
        background-image: linear-gradient(
          102deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      30% {
        background-image: linear-gradient(
          108deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      31.6566666667% {
        background-image: linear-gradient(
          108deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      31.6666666667% {
        background-image: linear-gradient(
          114deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      33.3233333333% {
        background-image: linear-gradient(
          114deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
        // eslint-disable-next-line max-lines
      }
      33.3333333333% {
        background-image: linear-gradient(
          120deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      34.99% {
        background-image: linear-gradient(
          120deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      35% {
        background-image: linear-gradient(
          126deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      36.6566666667% {
        background-image: linear-gradient(
          126deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      36.6666666667% {
        background-image: linear-gradient(
          132deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      38.3233333333% {
        background-image: linear-gradient(
          132deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      38.3333333333% {
        background-image: linear-gradient(
          138deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      39.99% {
        background-image: linear-gradient(
          138deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      40% {
        background-image: linear-gradient(
          144deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      41.6566666667% {
        background-image: linear-gradient(
          144deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      41.6666666667% {
        background-image: linear-gradient(
          150deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      43.3233333333% {
        background-image: linear-gradient(
          150deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      43.3333333333% {
        background-image: linear-gradient(
          156deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      44.99% {
        background-image: linear-gradient(
          156deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      45% {
        background-image: linear-gradient(
          162deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      46.6566666667% {
        background-image: linear-gradient(
          162deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      46.6666666667% {
        background-image: linear-gradient(
          168deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      48.3233333333% {
        background-image: linear-gradient(
          168deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      48.3333333333% {
        background-image: linear-gradient(
          174deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      49.99% {
        background-image: linear-gradient(
          174deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      50% {
        background-image: linear-gradient(
          180deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      51.6566666667% {
        background-image: linear-gradient(
          180deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      51.6666666667% {
        background-image: linear-gradient(
          186deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      53.3233333333% {
        background-image: linear-gradient(
          186deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      53.3333333333% {
        background-image: linear-gradient(
          192deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      54.99% {
        background-image: linear-gradient(
          192deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      55% {
        background-image: linear-gradient(
          198deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      56.6566666667% {
        background-image: linear-gradient(
          198deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      56.6666666667% {
        background-image: linear-gradient(
          204deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      58.3233333333% {
        background-image: linear-gradient(
          204deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      58.3333333333% {
        background-image: linear-gradient(
          210deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      59.99% {
        background-image: linear-gradient(
          210deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      60% {
        background-image: linear-gradient(
          216deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      61.6566666667% {
        background-image: linear-gradient(
          216deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      61.6666666667% {
        background-image: linear-gradient(
          222deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      63.3233333333% {
        background-image: linear-gradient(
          222deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      63.3333333333% {
        background-image: linear-gradient(
          228deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      64.99% {
        background-image: linear-gradient(
          228deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      65% {
        background-image: linear-gradient(
          234deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      66.6566666667% {
        background-image: linear-gradient(
          234deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      66.6666666667% {
        background-image: linear-gradient(
          240deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      68.3233333333% {
        background-image: linear-gradient(
          240deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      68.3333333333% {
        background-image: linear-gradient(
          246deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      69.99% {
        background-image: linear-gradient(
          246deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      70% {
        background-image: linear-gradient(
          252deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      71.6566666667% {
        background-image: linear-gradient(
          252deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      71.6666666667% {
        background-image: linear-gradient(
          258deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      73.3233333333% {
        background-image: linear-gradient(
          258deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      73.3333333333% {
        background-image: linear-gradient(
          264deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      74.99% {
        background-image: linear-gradient(
          264deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      75% {
        background-image: linear-gradient(
          270deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      76.6566666667% {
        background-image: linear-gradient(
          270deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      76.6666666667% {
        background-image: linear-gradient(
          276deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      78.3233333333% {
        background-image: linear-gradient(
          276deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      78.3333333333% {
        background-image: linear-gradient(
          282deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      79.99% {
        background-image: linear-gradient(
          282deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      80% {
        background-image: linear-gradient(
          288deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      81.6566666667% {
        background-image: linear-gradient(
          288deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      81.6666666667% {
        background-image: linear-gradient(
          294deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      83.3233333333% {
        background-image: linear-gradient(
          294deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      83.3333333333% {
        background-image: linear-gradient(
          300deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      84.99% {
        background-image: linear-gradient(
          300deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      85% {
        background-image: linear-gradient(
          306deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      86.6566666667% {
        background-image: linear-gradient(
          306deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      86.6666666667% {
        background-image: linear-gradient(
          312deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      88.3233333333% {
        background-image: linear-gradient(
          312deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      88.3333333333% {
        background-image: linear-gradient(
          318deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      89.99% {
        background-image: linear-gradient(
          318deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      90% {
        background-image: linear-gradient(
          324deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      91.6566666667% {
        background-image: linear-gradient(
          324deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      91.6666666667% {
        background-image: linear-gradient(
          330deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      93.3233333333% {
        background-image: linear-gradient(
          330deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      93.3333333333% {
        background-image: linear-gradient(
          336deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      94.99% {
        background-image: linear-gradient(
          336deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      95% {
        background-image: linear-gradient(
          342deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      96.6566666667% {
        background-image: linear-gradient(
          342deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      96.6666666667% {
        background-image: linear-gradient(
          348deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      98.3233333333% {
        background-image: linear-gradient(
          348deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      98.3333333333% {
        background-image: linear-gradient(
          354deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      99.99% {
        background-image: linear-gradient(
          354deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      100% {
        background-image: linear-gradient(
          360deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
      101.6566666667% {
        background-image: linear-gradient(
          360deg,
          transparent,
          transparent,
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]},
          ${mode === 'dark' ? palette.background[2] : palette.primary[0]}
        );
      }
    }
    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    

    .uf-modal-inner,
    .uf-slideout-inner,
    .uf-bubble{
      #dark & {
        background-color: #333;
      }
      .uf-close-button{
        #dark & {
           svg {
             path {
                stroke: #FFF;
             }
           }
        }
      }
      .pointer::before{
        #dark & {
          background-color: ${palette.background[1]};
        }
      }
      .uf-content,
      .uf-bubble-content{
        #dark & {
          span, p{
            color: ${palette.grays[11]} !important;
          }
        }
      }
      .uf-progress{
        .uf-progress-bar{
          background-color: ${palette.primary[0]};
        }
      }
      .uf-actions{
        .uf-button-primary{
          background-color: ${palette.primary[0]};
          border: 1px solid ${palette.primary[0]};
          cursor: pointer;
        }
        .uf-button{
          cursor: pointer;
        }
      }
    }

    textarea.textSecurity {
      -webkit-text-security: disc;
    }

    .gs-separator {
      border-left: 1px solid #ccc;
      height: 14px;
    }

    .gs-actions {
      display: flex;
      column-gap: 14px;

      .ant-btn {
        background: transparent;
        border: none;
        box-shadow: none;

        padding: 0;
        margin: 0;
        margin-top: 0 !important;

        &.ant-btn-link {
          color: ${palette.primary[0]};
          #dark & {
            color: ${palette.primary[2]};
          }

          span {
            font-family: 'Circular-Medium' !important;
          }
        }

        &.ant-btn-ghost {
          color: ${palette.grays[4]};
          #dark & {
            color: ${palette.grays[7]};
          }
        }
      }
    }

    // hide Adroll iframe
    iframe#adroll_slg {
      display: none;
    }
  `;
}}
`;
export default GlobalStyles;
