import type { ApiMatcherType, LabelMatcher, ResourceMatcher } from 'app/services/rbac/roles';
import type { LabelMap } from 'rbac/types';

export interface Matchable {
    id: number;
}

export interface LabelMatchable extends Matchable {
    labels?: LabelMap;
}

export interface ResourceMatcherContext {
    environment?: LabelMatchable;
    project?: LabelMatchable;
}

export interface ResourceMatcherOptions {
    environmentAnyParent?: boolean;
}

export const DefaultOptions = {
    environmentAnyParent: false,
};

export function matchesLabels(matcher: LabelMatcher, labels: LabelMap): boolean {
    const keys = Object.keys(matcher.labels);

    for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];

        if (!(key in labels)) {
            return false;
        }

        const value = matcher.labels[key];
        if (value !== '') {
            if (labels[key] !== value) {
                return false;
            }
        }
    }

    return true;
}

export function matchResource(matcher: ApiMatcherType, resource: LabelMatchable): boolean {
    if (matcher.type === 'none') {
        return false;
    }

    if (matcher.type === 'any') {
        return true;
    }

    if (matcher.type === 'collection') {
        return matcher.resources.includes(resource.id);
    }

    if (matcher.type === 'labels') {
        if (resource.labels) {
            return matchesLabels(matcher, resource.labels);
        }
    }

    return false;
}

export function matchesEnvironmentAndAnyParent(matcher: ResourceMatcher, context: ResourceMatcherContext): boolean {
    if (!context.environment) {
        return false;
    }

    if (context.project && matchResource(matcher.projectMatcher.matcher, context.project)) {
        return matchResource(matcher.environmentMatcher.matcher, context.environment);
    }

    // rbac: future workstream
    // if (context.workstream && matchResource(matcher.workstreamMatcher.matcher, context.workstream)) {
    //     return matchResource(matcher.environmentMatcher.matcher, context.environment);
    // }

    return false;
}

export function matcherHasResource(
    matcher: ResourceMatcher,
    context: ResourceMatcherContext,
    options: ResourceMatcherOptions
): boolean {
    if (options.environmentAnyParent) {
        return matchesEnvironmentAndAnyParent(matcher, context);
    }

    if (context.project) {
        if (!matchResource(matcher.projectMatcher.matcher, context.project)) {
            return false;
        }
    }

    if (context.environment) {
        if (!matchResource(matcher.environmentMatcher.matcher, context.environment)) {
            return false;
        }
    }

    return true;
}

export function hasResource(
    matchers: ResourceMatcher[],
    context: ResourceMatcherContext,
    options: ResourceMatcherOptions,
): boolean {
    return matchers.some(matcher => matcherHasResource(matcher, context, options));
}
