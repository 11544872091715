import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import {
  toggleOpenDrawer,
  toggleAll,
} from 'modules/sidebar/actions';
import initialState from './initialState';

const toggleOpenDrawerHandler = [
  toggleOpenDrawer,
  (state) => {
    return {
      ...state,
      openDrawer: !state.openDrawer,
    };
  },
];

const toggleAllHandler = [
  toggleAll,
  (state, action) => {
    const { payload } = action;
    if (state.view !== payload.view || payload.height !== state.height) {
      const height = payload.height ? payload.height : state.height;
      return {
        ...state,
        collapsed: true, //payload.collapsed,
        view: payload.view,
        height,
      };
    }

    return {
      ...state,
    };
  },
];

const reducer = handleActions(
  new Map([
    toggleOpenDrawerHandler,
    toggleAllHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
