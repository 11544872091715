import styled, { css } from 'styled-components';
import Switch from 'theme/uielements/switch';

const StyledSwitch = styled(Switch)`
  ${({ theme }) => {
    const { palette } = theme;

    return css`
      &.ant-switch {
        width: 55px;
        height: 24px;
        border-radius: 3px;
        background-color: ${palette.color[4]};
        .ant-switch-handle {
          top: 3px;
          left: 3px;
          &:before {
            border-radius: 2px;
          }
        }
      }
      &.ant-switch-checked {
        background-color: ${palette.success[0]};
        :focus{
          box-shadow: 0 0 0;
        }
        .ant-switch-handle {
          left: calc(100% - 18px - 3px);
        }
      }
    `;
  }}
`;

StyledSwitch.displayName = 'StyledSwitch';
export default StyledSwitch;
