import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { useIntercom } from 'react-use-intercom';
import DialogCreateProject from 'modules/projects/components/dialogCreateProject';
import Tooltip from 'theme/uielements/tooltip';
import Button from 'theme/uielements/button';
import StyledProjectsEmptyState from './ProjectsEmptyState.style';

const ProjectsEmptyState = ({ organizationId, actions, aclProjectCreateDisabled }) => {
  return (
    <StyledProjectsEmptyState>
      <Grid>
        <Row>
          <Col xs={12} lg={12}>
            <h6 className="mb-1 title">Create your first project</h6>
            <p className="description mb-5">
              A Project enables you to group multiple sets of applications and their dependencies - services, and
              database.
            </p>
            <Tooltip
              placement="top"
              title={aclProjectCreateDisabled ? 'Denied' : ''}
              mouseEnterDelay={0.4}
              mouseLeaveDelay={0.3}
            >
              <span>
                <Button
                  type="primary"
                  size="middle"
                  data-value="create-project-button-modal"
                  disabled={aclProjectCreateDisabled}
                  onClick={() => {
                    actions.showDialog({
                      options: {
                        size: 'small',
                      },
                      header: 'Create project',
                      body: (
                        <DialogCreateProject
                          organizationId={organizationId}
                          aclProjectCreateDisabled={aclProjectCreateDisabled}
                        />
                      ),
                    });
                  }}
                >
                  Create project
                </Button>
              </span>
            </Tooltip>
          </Col>
          <Col xs={12} lg={12}>
            <div className="support-section">
              <h6 className="mb-1 title">Need help getting started?</h6>
              <p className="mb-5 description">
                Chat with us, and our technicians will help you to migrate your applications.
              </p>
              <Button
                className="chat-button"
                type="primary"
                size="middle"
                data-value="projects-chat-with-us-button"
                onClick={useIntercom().show}
                onKeyDown={useIntercom().show}
              >
                Chat with us
              </Button>
            </div>
          </Col>
        </Row>
      </Grid>
    </StyledProjectsEmptyState>
  );
};

ProjectsEmptyState.propTypes = {
  aclProjectCreateDisabled: PropTypes.bool.isRequired,
  organizationId: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    showDialog: PropTypes.func,
  }).isRequired,
};

export default ProjectsEmptyState;
