const options = [
  {
    key: 'my-profile',
    label: 'sidebar.profile',
    leftIcon: 'ion-person',
  },
  {
    key: 'scrum-board',
    label: 'sidebar.scrumboard',
    leftIcon: 'ion-android-checkbox-outline',
  },
  {
    key: 'invoice',
    label: 'sidebar.invoice',
    leftIcon: 'ion-clipboard',
  },

  {
    key: 'contacts',
    label: 'sidebar.contacts',
    leftIcon: 'ion-android-person-add',
  },
  {
    key: 'shuffle',
    label: 'sidebar.shuffle',
    leftIcon: 'ion-grid',
  },
  {
    key: 'charts',
    label: 'sidebar.charts',
    leftIcon: 'ion-arrow-graph-up-right',
    children: [
      {
        key: 'googleChart',
        label: 'sidebar.googleCharts',
      },
      {
        key: 'reecharts',
        label: 'sidebar.recharts',
      },
      {
        key: 'reactChart2',
        label: 'sidebar.reactChart2',
      },
      {
        key: 'frappeChart',
        label: 'sidebar.frappeChart',
      },
    ],
  },

  {
    key: 'uielements',
    label: 'sidebar.uiElements',
    leftIcon: 'ion-leaf',
    children: [
      {
        key: 'op_badge',
        label: 'sidebar.badge',
      },
      {
        key: 'op_card',
        label: 'sidebar.card2',
      },
      {
        key: 'op_carousel',
        label: 'sidebar.corusel',
      },
      {
        key: 'op_collapse',
        label: 'sidebar.collapse',
      },
      {
        key: 'op_popover',
        label: 'sidebar.popover',
      },
      {
        key: 'op_tooltip',
        label: 'sidebar.tooltip',
      },
      {
        key: 'op_tag',
        label: 'sidebar.tag',
      },
      {
        key: 'op_timeline',
        label: 'sidebar.timeline',
      },
      {
        key: 'dropdown',
        label: 'sidebar.dropdown',
      },
      {
        key: 'pagination',
        label: 'sidebar.pagination',
      },
      {
        key: 'rating',
        label: 'sidebar.rating',
      },
      {
        key: 'tree',
        label: 'sidebar.tree',
      },
      {
        key: 'swiperslider',
        label: 'sidebar.swiperslider',
      },
    ],
  },

  {
    key: 'table',
    label: 'sidebar.tables',
    leftIcon: 'ion-android-menu',
    children: [
      {
        key: 'table_ant',
        label: 'sidebar.antTables',
      },
    ],
  },

  {
    key: 'githubSearch',
    label: 'sidebar.githubSearch',
    leftIcon: 'ion-social-github',
  },
];
export default options;
