import { createSlice } from '@reduxjs/toolkit';

// Define a type for the slice state
interface IntercomState {
    booted: boolean;
}

// Define the initial state using that type
const initialState: IntercomState = {
  booted: false,
}

export const intercomSlice = createSlice({
  name: 'intercom',
  initialState,

  reducers: {
    boot: (state) => {
      state.booted = true;
    },
    shutdown: (state) => {
      state.booted = false;
    },
  }
})

export const intercomReducer = intercomSlice.reducer;
