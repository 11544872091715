import React, { lazy } from 'react';
import ProjectsRoot from 'modules/projects/routes/Root';
import Error from 'modules/error/components';
import { FreemiumGate } from 'components/gate/FreemiumGate';
import NotFoundPage from './static/NotFound';
import ROUTE_PATHS from './routePaths';

const AdminRoot = lazy(() => import('modules/admin/routes/Root'));
const ProjectSettings = lazy(() => import('modules/projects/components/settings'));
const ProjectMetrics = lazy(() => import('modules/projects/components/metrics'));
const Integrations = lazy(() => import('modules/integrations/components'));
const RepositoryPage = lazy(() => import('modules/integrations/components/repositories/RepositoryPage').then(module => ({
  default: module.StyledRepositoryPage,
})));
const AddWorkshopCluster = lazy(() => import('modules/integrations/components/workshop'));
const ApplicationCreate = lazy(() => import('modules/applications/routes/Root'));
/* clusters */
// const Clusters = lazy(() => import('modules/clusters'));
const ClusterDetails = lazy(() => import('modules/clusters/details/ClusterDetails'));
const ClusterPipelineDetails = lazy(() => import('modules/clusters/pipeline/ClusterPipelineDetails'));
/* costs */
const CloudCosts = lazy(() => import('modules/cloudCosts'));
/* costs */
const Deploys = lazy(() => import('modules/deploymentsList'));
/* environments */
const Environments = lazy(() => import('modules/environments/components'));
const EnvironmentSettings = lazy(() => import('modules/environments/components/EnvironmentSettings'));
const EnvironmentDetails = lazy(() => import('modules/environments/components/details'));
const EnvironmentConfiguration = lazy(() => import('modules/environments/components/configuration'));
const EnvironmentEvents = lazy(() => import('modules/environments/components/events'));
const EnvironmentPipelines = lazy(() => import('modules/environments/components/pipelines'));
const EnvironmentPipelineDetails = lazy(() => import('modules/environments/components/pipelineDetail'));
const EnvironmentEventLogs = lazy(() => import('modules/environments/components/eventLogs'));
const GithubProviderProxy = lazy(() => import('modules/userManager/components/login/components/GithubProviderProxy'));
const GitlabProviderProxy = lazy(() => import('modules/userManager/components/login/components/GitlabProviderProxy'));
const BitbucketProviderProxy = lazy(() =>
  import('modules/userManager/components/login/components/BitbucketProviderProxy')
);
const MicrosoftProviderProxy = lazy(() =>
  import('modules/userManager/components/login/components/MicrosoftProviderProxy')
);
const AzureProviderProxy = lazy(() => import('modules/userManager/components/login/components/AzureProviderProxy'));
const GoogleProviderProxy = lazy(() =>import('modules/userManager/components/login/components/GoogleProviderProxy'));
const GithubProvider = lazy(() => import('modules/integrations/components/repositories/GithubProvider'));
const BitbucketProvider = lazy(() => import('modules/integrations/components/repositories/BitbucketProvider'));
const GitlabProvider = lazy(() => import('modules/integrations/components/repositories/GitlabProvider'));
const AzureProvider = lazy(() => import('modules/integrations/components/repositories/AzureProvider'));
const SsoResponseProvider = lazy(() => import('modules/userManager/components/login/components/SsoResponseProvider'));
const SlackProvider = lazy(() => import('modules/integrations/components/slack/SlackProvider'));
const Settings = lazy(() => import('modules/settings/components'));
const Billing = lazy(() => import('modules/billing/routes/Root'));
const AccessToken = lazy(() => import('modules/settings/components/accessToken'));
const PipelineBridge = lazy(() => import('modules/bridges/pipeline/pipelineBridge/PipelineBridge'));
const EnvironmentBridge = lazy(() => import('modules/bridges/environments/environment/EnvBridge'));
const TemplatesPage = lazy(() => import('modules/templates/components/templatesPage/TemplatesPage'));
const TemplateDetails = lazy(() => import('modules/templates/components/templateDetails/TemplateDetails'));
const TemplateConfiguration = lazy(() => import('modules/templates/components/templateConfiguration'));
const TemplateCreateFromWebsite = lazy(() =>
  import('modules/templates/components/createFromWebsite/CreateFromWebsite')
);
const ApplicationSettings = lazy(() => import('modules/applications/components/ApplicationSettings'));
const GithubInstallationID = lazy(() => import('modules/userManager/components/login/components/GithubInstallationID'));
const Security = lazy(() => import('modules/security/Security'));

export const privateRoutes = [
  {
    id: ROUTE_PATHS.LANDING,
    path: ROUTE_PATHS.LANDING,
    component: ProjectsRoot,
    title: 'Projects',
    exact: true,
  },
  {
    id: ROUTE_PATHS.PROJECTS,
    path: ROUTE_PATHS.PROJECTS,
    component: ProjectsRoot,
    title: 'Projects',
    exact: true,
  },
  {
    id: `${ROUTE_PATHS.PROJECTS}/:projectId`,
    path: `${ROUTE_PATHS.PROJECTS}/:projectId`,
    component: ProjectsRoot,
    title: 'Environments overview',
    exact: true,
  },
  {
    id: `${ROUTE_PATHS.PROJECT_SETTINGS}`,
    path: `${ROUTE_PATHS.PROJECT_SETTINGS}`,
    component: ProjectSettings,
    title: 'Project Settings',
  },
  {
    id: `${ROUTE_PATHS.PROJECT_METRICS}`,
    path: `${ROUTE_PATHS.PROJECT_METRICS}`,
    component: (params) => <FreemiumGate><ProjectMetrics {...params} /></FreemiumGate>,
    title: 'Project Metrics',
  },
  {
    id: ROUTE_PATHS.PROJECT_SECURITY,
    path: ROUTE_PATHS.PROJECT_SECURITY,
    component: Security,
    title: 'Project Security',
  },
  /* cloud costs */
  {
    id: ROUTE_PATHS.CLOUD_COSTS,
    path: ROUTE_PATHS.CLOUD_COSTS,
    component: (params) => <FreemiumGate><CloudCosts {...params} /></FreemiumGate>,
    title: 'Cloud costs',
    exact: true,
  },
  /* deployments list */
  {
    id: ROUTE_PATHS.DEPLOYS,
    path: ROUTE_PATHS.DEPLOYS,
    component: (params) => <FreemiumGate><Deploys {...params} /></FreemiumGate>,
    title: 'Deployments list',
    exact: true,
  },
  /* clusters */
  // @see /:organizationid/integrations/clusters
  // {
  //   id: ROUTE_PATHS.CLUSTERS,
  //   path: ROUTE_PATHS.CLUSTERS,
  //   component: (params) => <FreemiumGate><Clusters {...params} /></FreemiumGate>,
  //   title: 'Clusters overview',
  //   exact: true,
  // },
  {
    id: ROUTE_PATHS.CLUSTERS_PIPELINE,
    path: ROUTE_PATHS.CLUSTERS_PIPELINE,
    component: (params) => <FreemiumGate><ClusterPipelineDetails {...params} /></FreemiumGate>,
    title: 'Cluster Pipeline',
    exact: true,
  },
  {
    id: ROUTE_PATHS.CLUSTERS_DETAILS,
    path: ROUTE_PATHS.CLUSTERS_DETAILS,
    component: (params) => <FreemiumGate><ClusterDetails {...params} /></FreemiumGate>,
    title: 'Cluster add-ons',
  },
  /* templates */
  {
    id: ROUTE_PATHS.TEMPLATES,
    path: ROUTE_PATHS.TEMPLATES,
    component: TemplatesPage,
    title: 'Templates',
    exact: true,
  },
  /* security */
  {
    id: ROUTE_PATHS.SECURITY,
    path: ROUTE_PATHS.SECURITY,
    component: Security,
    title: 'Security',
  },
  {
    id: ROUTE_PATHS.TEMPLATE_DETAILS,
    path: ROUTE_PATHS.TEMPLATE_DETAILS,
    component: TemplateDetails,
    title: 'Template details',
    exact: true,
  },
  {
    id: ROUTE_PATHS.TEMPLATE_CONFIGURATION,
    path: ROUTE_PATHS.TEMPLATE_CONFIGURATION,
    component: TemplateConfiguration,
    title: 'Template configuration',
    exact: true,
  },
  {
    id: ROUTE_PATHS.TEMPLATE_WEBSITE_CREATE,
    path: ROUTE_PATHS.TEMPLATE_WEBSITE_CREATE,
    component: TemplateCreateFromWebsite,
    title: 'Creating environment',
    exact: true,
  },
  {
    id: ROUTE_PATHS.ENVIRONMENTS,
    path: ROUTE_PATHS.ENVIRONMENTS,
    component: Environments,
    title: 'Environments overview',
    exact: true,
  },
  {
    id: ROUTE_PATHS.ENVIRONMENT_EVENTS_LOGS,
    path: ROUTE_PATHS.ENVIRONMENT_EVENTS_LOGS,
    component: EnvironmentEventLogs,
    title: 'Environment Event Logs',
  },
  {
    id: ROUTE_PATHS.ENVIRONMENT_EVENTS,
    path: ROUTE_PATHS.ENVIRONMENT_EVENTS,
    component: EnvironmentEvents,
    title: 'Environment Events',
    exact: true,
  },
  {
    id: ROUTE_PATHS.ENVIRONMENT_PIPELINES,
    path: ROUTE_PATHS.ENVIRONMENT_PIPELINES,
    component: EnvironmentPipelines,
    title: 'Environment Pipelines',
    exact: true,
  },
  {
    id: ROUTE_PATHS.ENVIRONMENT_PIPELINE_DETAILS,
    path: ROUTE_PATHS.ENVIRONMENT_PIPELINE_DETAILS,
    component: EnvironmentPipelineDetails,
    title: 'Environment Pipeline',
  },
  {
    id: ROUTE_PATHS.PIPELINE_BRIDGE,
    path: ROUTE_PATHS.PIPELINE_BRIDGE,
    component: PipelineBridge,
    title: 'Environment Pipeline',
  },
  {
    id: ROUTE_PATHS.ENVIRONMENT_BRIDGE,
    path: ROUTE_PATHS.ENVIRONMENT_BRIDGE,
    component: EnvironmentBridge,
    title: 'Environment',
  },
  {
    id: ROUTE_PATHS.ENVIRONMENT_DETAILS,
    path: ROUTE_PATHS.ENVIRONMENT_DETAILS,
    component: EnvironmentDetails,
    title: 'Environment Details',
    exact: true,
  },
  {
    id: ROUTE_PATHS.ENVIRONMENT_CONFIGURATION,
    path: ROUTE_PATHS.ENVIRONMENT_CONFIGURATION,
    component: EnvironmentConfiguration,
    title: 'Environment configuration',
    exact: true,
  },
  {
    id: ROUTE_PATHS.ENVIRONMENT_CONFIGURATION_DIFF,
    path: ROUTE_PATHS.ENVIRONMENT_CONFIGURATION_DIFF,
    component: EnvironmentConfiguration,
    title: 'Environment configuration',
    exact: true,
  },
  {
    id: ROUTE_PATHS.ENVIRONMENT_CONFIGURATION_EDIT_DIFF,
    path: ROUTE_PATHS.ENVIRONMENT_CONFIGURATION_EDIT_DIFF,
    component: EnvironmentConfiguration,
    title: 'Environment configuration',
    exact: true,
  },
  {
    id: ROUTE_PATHS.ENVIRONMENT_SETTINGS,
    path: ROUTE_PATHS.ENVIRONMENT_SETTINGS,
    component: EnvironmentSettings,
    title: 'Environment Settings',
  },
  {
    id: ROUTE_PATHS.APPLICATIONS_SETTINGS,
    path: ROUTE_PATHS.APPLICATIONS_SETTINGS,
    component: ApplicationSettings,
    title: 'Application Settings',
  },
  {
    id: 'application-create',
    path: ROUTE_PATHS.APPLICATIONS,
    component: ApplicationCreate,
    title: 'Application Create',
  },
  {
    id: ROUTE_PATHS.INTEGRATIONS,
    path: ROUTE_PATHS.INTEGRATIONS,
    component: Integrations,
    title: 'Integrations',
  },
  {
    id: ROUTE_PATHS.REPOSITORY_PAGE,
    path: ROUTE_PATHS.REPOSITORY_PAGE,
    component: RepositoryPage,
    title: 'Git integrations',
  },
  {
    id: ROUTE_PATHS.ADD_WORKSHOP_CLUSTER,
    path: ROUTE_PATHS.ADD_WORKSHOP_CLUSTER,
    component: AddWorkshopCluster,
    title: 'Add Workshop Cluster',
  },
  {
    id: ROUTE_PATHS.GITHUB_RESPONSE,
    path: ROUTE_PATHS.GITHUB_RESPONSE,
    component: GithubProvider,
    title: 'Github Provider',
  },
  {
    id: ROUTE_PATHS.BITBUCKET_RESPONSE,
    path: ROUTE_PATHS.BITBUCKET_RESPONSE,
    component: BitbucketProvider,
    title: 'Bitbucket Provider',
  },
  {
    id: ROUTE_PATHS.GITLAB_RESPONSE,
    path: ROUTE_PATHS.GITLAB_RESPONSE,
    component: GitlabProvider,
    title: 'Gitlab Provider',
  },
  {
    id: ROUTE_PATHS.AZURE_RESPONSE,
    path: ROUTE_PATHS.AZURE_RESPONSE,
    component: AzureProvider,
    title: 'Azure Provider',
  },
  {
    id: ROUTE_PATHS.SLACK_RESPONSE,
    path: ROUTE_PATHS.SLACK_RESPONSE,
    component: SlackProvider,
    title: 'Slack Integration',
  },
  {
    id: ROUTE_PATHS.SETTINGS,
    path: ROUTE_PATHS.SETTINGS,
    component: Settings,
    title: 'Organization Settings',
  },
  {
    id: ROUTE_PATHS.BILLING,
    path: ROUTE_PATHS.BILLING,
    component: Billing,
    title: 'Billing',
  },
  {
    id: ROUTE_PATHS.MEETINGS,
    path: ROUTE_PATHS.MEETINGS,
    component: lazy(() => import('modules/meeting/Router').then(module => ({
      default: module.Router,
    }))),
    title: 'Billing Meeting',
  },
  {
    id: ROUTE_PATHS.ADMIN,
    path: ROUTE_PATHS.ADMIN,
    component: AdminRoot,
    title: 'Admin',
  },
  {
    id: ROUTE_PATHS.ACCESS_TOKEN,
    path: ROUTE_PATHS.ACCESS_TOKEN,
    component: AccessToken,
    title: 'Access Token',
  },
];

export const publicRoutes = [
  {
    id: ROUTE_PATHS.GITHUB_RESPONSE_PROXY,
    path: ROUTE_PATHS.GITHUB_RESPONSE_PROXY,
    component: GithubProviderProxy,
    title: 'Github Provider',
  },
  {
    id: ROUTE_PATHS.BITBUCKET_RESPONSE_PROXY,
    path: ROUTE_PATHS.BITBUCKET_RESPONSE_PROXY,
    component: BitbucketProviderProxy,
    title: 'Bitbucket Provider',
  },
  {
    id: ROUTE_PATHS.GITLAB_RESPONSE_PROXY,
    path: ROUTE_PATHS.GITLAB_RESPONSE_PROXY,
    component: GitlabProviderProxy,
    title: 'Gitlab Provider',
  },
  {
    id: ROUTE_PATHS.MICROSOFT_RESPONSE_PROXY,
    path: ROUTE_PATHS.MICROSOFT_RESPONSE_PROXY,
    component: MicrosoftProviderProxy,
    title: 'Microsoft Provider',
  },
  {
    id: ROUTE_PATHS.AZURE_RESPONSE_PROXY,
    path: ROUTE_PATHS.AZURE_RESPONSE_PROXY,
    component: AzureProviderProxy,
    title: 'Azure Provider',
  },
  {
    id: ROUTE_PATHS.GOOGLE_RESPONSE_PROXY,
    path: ROUTE_PATHS.GOOGLE_RESPONSE_PROXY,
    component: GoogleProviderProxy,
    title: 'Google Provider',
  },
  {
    id: ROUTE_PATHS.LOGIN,
    path: ROUTE_PATHS.LOGIN,
    component: lazy(() => import('modules/userManager/components/login/Login')),
    title: 'Sign In',
  },
  {
    id: `${ROUTE_PATHS.REGISTER}/:planSlug`,
    path: `${ROUTE_PATHS.REGISTER}/:planSlug`,
    component: lazy(() => import('modules/userManager/components/register/Register')),
    title: 'Sign up',
    exact: true,
  },
  {
    id: ROUTE_PATHS.REGISTER,
    path: ROUTE_PATHS.REGISTER,
    component: lazy(() => import('modules/userManager/components/register/Register')),
    title: 'Sign up',
  },
  {
    id: ROUTE_PATHS.REGISTER_EMAIL,
    path: ROUTE_PATHS.REGISTER_EMAIL,
    component: lazy(() => import('modules/userManager/components/register/RegisterEmail')),
    title: 'Sign up',
  },
  {
    id: ROUTE_PATHS.REGISTER_CONFIRM_EMAIL,
    path: ROUTE_PATHS.REGISTER_CONFIRM_EMAIL,
    component: lazy(() => import('modules/userManager/components/confirmEmail/ConfirmEmail')),
    title: 'Confirm Email',
  },
  {
    id: ROUTE_PATHS.BILLING_REGISTRATION,
    path: ROUTE_PATHS.BILLING_REGISTRATION,
    component: lazy(() => import('modules/userManager/components/billingRegistration/BillingRegistration')),
    title: 'Register billing',
  },
  {
    id: ROUTE_PATHS.INVITE_ACCEPT,
    path: ROUTE_PATHS.INVITE_ACCEPT,
    component: lazy(() => import('modules/userManager/components/inviteAccept/InviteAccept')),
    title: 'Invite accept',
  },
  {
    id: ROUTE_PATHS.RECOVER_PASSWORD,
    path: ROUTE_PATHS.RECOVER_PASSWORD,
    component: lazy(() => import('modules/userManager/components/recoverPassword/RecoverPassword')),
    title: 'Password Reset',
  },
  {
    id: ROUTE_PATHS.SSO_RESPONSE,
    path: ROUTE_PATHS.SSO_RESPONSE,
    component: SsoResponseProvider,
    title: 'SSO Response Provider',
  },
  {
    id: ROUTE_PATHS.ERROR,
    path: ROUTE_PATHS.ERROR,
    component: Error,
    title: 'Error',
    exact: true,
  },
  {
    id: `${ROUTE_PATHS.ERROR}/:code`,
    path: `${ROUTE_PATHS.ERROR}/:code`,
    component: Error,
    title: 'Error',
  },
  {
    id: ROUTE_PATHS.ERROR_404,
    path: ROUTE_PATHS.ERROR_404,
    component: NotFoundPage,
    title: 'Error 404',
    exact: true,
  },
  {
    id: ROUTE_PATHS.GITHUB_INSTALL_ID,
    path: ROUTE_PATHS.GITHUB_INSTALL_ID,
    component: GithubInstallationID,
    title: 'Github Provider',
  },
];
