import React from "react";
import { Menu} from "antd";
import { LinkItem, SubMenuTitle } from "modules/sidebar/items/utils";
import {
    slackIcon,
    ecrIconBlue,
    ecrIconWhite,
    microsoftTeamsIcon,
} from "assets/images";

import type { MenuTheme } from "modules/sidebar/items/menu";

export interface ITitleProps {
    theme: MenuTheme;
}

export interface IProps {
    organizationId: number;
    theme: MenuTheme;
}

export function isIntegrationsActive(pathname: string, organizationId: number): boolean {
    if (pathname.startsWith(`/${organizationId}/clusters`)) {
        return true;
    }

    if (pathname.startsWith(`/${organizationId}/integrations`)) {
        return true;
    }

    return false;
}

export function IntegrationsTitle(props: ITitleProps): React.ReactElement {
    return <SubMenuTitle
        icon="bicon-integrations"
        name="Integrations"
        theme={props.theme}
    />
}

export function IntegrationsSubMenu(props: IProps): React.ReactElement {
    const { theme, organizationId } = props;

    // we don't have an activeKey for submenu
    // we don't show the highlighted submenu tree
    const activeKey = "";
    return <Menu.SubMenu
        title={<IntegrationsTitle theme={theme} />}
        key="integrations-submenu"
        className="hasSubmenu"
    >
        <LinkItem
            currentKey={activeKey}
            key="integrations-git"
            title="Git Integrations"
            icon="bicon-git-commit"
            linkTo={{
                pathname: `/${organizationId}/integrations/repositories`
            }}
        />
        <LinkItem
            currentKey={activeKey}
            key="integrations-clusters"
            title="Clusters"
            icon="icon-clusters"
            linkTo={{
                pathname: `/${organizationId}/integrations/clusters`
            }}
        />
        <LinkItem
            currentKey={activeKey}
            key="integrations-registries"
            title="Container Registries"
            icon={<img src={theme === 'dark' ? ecrIconWhite : ecrIconBlue} alt="Slack icon" width="24" height="24" className="mr-2" />}
            linkTo={{
                pathname: `/${organizationId}/integrations/registries`
            }}
        />
        <LinkItem
            currentKey={activeKey}
            key="integrations-slack"
            title="Slack"
            icon={<img src={slackIcon} alt="Slack icon" width="24" height="24" className="mr-2" />}
            linkTo={{
                pathname: `/${organizationId}/integrations/slack`
            }}
        />
        <LinkItem
            currentKey={activeKey}
            key="integrations-teams"
            title="Teams"
            icon={<img src={microsoftTeamsIcon} alt="Microsoft Teams icon" width="24" height="24" className="mr-2" />}
            linkTo={{
                pathname: `/${organizationId}/integrations/teams`
            }}
        />
    </Menu.SubMenu>
}
