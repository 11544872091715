import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { NAKED_BASE_URL } from 'config/api';
import { defaultPrepareHeaders } from 'app/services/api';

export const BASE_URL = `${NAKED_BASE_URL}/api/admin`;

export const defaultBaseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,

    prepareHeaders: defaultPrepareHeaders,
});
