import React from 'react';
import styled from 'styled-components';
import { SVG } from 'components';

interface Props {
  onClick: () => void;
  count: number;
}

const Wrapper = styled.div`
  position: relative;
  color: #fff;
  display: inline-block;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
  span {
    position: absolute;
    top: -6px;
    right: -9px;
    background: red;
    border-radius: 50px;
    font-size: 12px;
    font-family: 'Circular-Medium';
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default function Trigger({ count, onClick }: Props) {
  return (
    <Wrapper onClick={onClick}>
      <SVG name="bicon-bell" />
      {count > 0 && <span>{count}</span>}
    </Wrapper>
  );
}
