import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import ErrorPage from 'components/errorPage';
import getError from './helpers';

export const Error = ({ match }) => {
  const error = getError(match);
  return (
    <ErrorPage title={error.title} description={error.description}>
      {error.children}
    </ErrorPage>
  );
};

Error.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      code: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default withRouter(Error);
