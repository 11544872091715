/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import compose from 'hocs';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showDialog, hideDialog, handleCriticalErrorState } from 'actions/global';
import {
  loadAccountDetails,
  handleUpdateUserPreferences,
  setCurrentProject,
  handleCreateOrganization,
} from 'modules/userManager/actions';
import { noOrganizationError } from '../../../../config/http/helpers';
import CreateOrganizationForm from '../../../../components/createOrganization/components/CreateOrganizationForm';

const AccountDetailsLoader = ({ history, actions, userId, isLoggedIn, emptyOrganizationsList }) => {
  const isMounted = useRef(true);
  const isAppReloaded = !userId && isLoggedIn;

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  /** load account details */
  useEffect(() => {
    if (isMounted.current && isAppReloaded) {
      actions.loadAccountDetails(history, null, "account_detail_loader");
    }
  }, [isMounted, isAppReloaded]);

  const changeOrganization = useCallback(
    (organization) => {
      actions.setCurrentProject(null);
      actions.handleUpdateUserPreferences(userId, organization.id, null, null, history, true).then(() => {
        history.push(`/${organization.id}/projects`);
        actions.loadAccountDetails(history, null, 'change_organization');
      });
    },
    [actions, history, userId]
  );

  /** show create organizations */
  useEffect(() => {
    if (emptyOrganizationsList) {
      history.push(`/`);

      actions.showDialog({
        options: {
          size: 'small',
          maskClosable: false,
          closable: false,
        },
        header: 'Create Organization',
        body: <CreateOrganizationForm actions={actions} changeOrganization={changeOrganization} noOrganizationsFound />,
      });

      setTimeout(() => {
        actions.handleCriticalErrorState({ isActive: true, details: noOrganizationError });
      }, 100);
    }
  }, [emptyOrganizationsList]);

  return null;
};

AccountDetailsLoader.defaultProps = {
  userId: '',
  emptyOrganizationsList: null,
};

AccountDetailsLoader.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isLoggedIn: PropTypes.bool.isRequired,
  emptyOrganizationsList: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  actions: PropTypes.shape({
    loadAccountDetails: PropTypes.func.isRequired,
    showDialog: PropTypes.func.isRequired,
    hideDialog: PropTypes.func.isRequired,
    handleUpdateUserPreferences: PropTypes.func.isRequired,
    setCurrentProject: PropTypes.func.isRequired,
    handleCreateOrganization: PropTypes.func.isRequired,
    handleCriticalErrorState: PropTypes.func.isRequired,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loadAccountDetails,
      showDialog,
      hideDialog,
      handleUpdateUserPreferences,
      setCurrentProject,
      handleCreateOrganization,
      handleCriticalErrorState,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => {
  const { emptyOrganizationsList, userId, organizations, isLoggedIn } = state.userManager;
  return { emptyOrganizationsList, organizations, userId, isLoggedIn };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(AccountDetailsLoader);
