import type { EnvironmentPermission } from 'rbac/types';
import type { Result, Options as BaseOptions, OrganizationContext } from 'rbac/hooks/useACL';
import type { ResourceMatcherContext } from 'rbac/util/matcher';

import { useACL } from 'rbac/hooks/useACL';

export type Options = Pick<BaseOptions, 'allowFetching'|'skip'>;

export type Context = OrganizationContext & {
    environment: NonNullable<ResourceMatcherContext['environment']>;
} & (
    {
        project: NonNullable<ResourceMatcherContext['project']>;
    }
    // | { workstream: NonNullable<ContextWithOrganization['workstream']> }
);

export function useEnvironmentACL(permission: EnvironmentPermission, context: Context, options: Options): Result {
    return useACL(permission, context, {
        allowFetching: options.allowFetching,
        skip: options.skip,

        matcher: {
            environmentAnyParent: true,
        }
    });
}
