/* eslint-disable max-params,react/react-in-jsx-scope */
import {handleCriticalErrorState, hideDialog, showDialog, showNotification} from 'actions/global';
import { TOAST_TYPES } from 'components/notificationsContainer/helpers';
import {API, DELETE, GET, POST, PUT} from 'config';
import mixpanel from "mixpanel-browser"
import * as R from "ramda";
import { notFoundError } from 'config/http/helpers';
import {resetSecretValues} from 'modules/environments/actions'
import {log, getErrorMessage, includes} from 'utils';
import { createPrefixedAction } from '../config';
import actionTypes from './actionTypes';
import {
  manuallyUpdateEnvironment,
  handleGetEnvironmentDetails,
  handleGetEnvironmentVariables,
  handleGetDraftComponents
} from "../../environments/actions";
import PublishErrorModal from '../../environments/components/details/components/components/PublishErrorModal';

export const goBack = createPrefixedAction(actionTypes.GO_BACK);
export const getComponents = createPrefixedAction(actionTypes.GET_COMPONENTS);
export const saveComponent = createPrefixedAction(actionTypes.SAVE_COMPONENT);
export const saveTempComponent = createPrefixedAction(actionTypes.SAVE_TEMP_COMPONENT);
export const deleteComponent = createPrefixedAction(actionTypes.DELETE_COMPONENT);
export const deleteTempComponent = createPrefixedAction(actionTypes.DELETE_TEMP_COMPONENT);
export const reviewComponents = createPrefixedAction(actionTypes.REVIEW_COMPONENTS);
export const createApplication = createPrefixedAction(actionTypes.CREATE_APPLICATION);
export const publishApplication = createPrefixedAction(actionTypes.PUBLISH_APPLICATION);
export const resetState = createPrefixedAction(actionTypes.RESET_STATE);
export const setConfigError = createPrefixedAction(actionTypes.SET_CONFIG_ERROR);
export const getVariables = createPrefixedAction(actionTypes.GET_APPLICATION_VARIABLES);
export const createVariable = createPrefixedAction(actionTypes.CREATE_APPLICATION_VARIABLE);
export const editVariable = createPrefixedAction(actionTypes.EDIT_APPLICATION_VARIABLE);
export const deleteVariable = createPrefixedAction(actionTypes.DELETE_APPLICATION_VARIABLE);
export const resetAppErrors = createPrefixedAction(actionTypes.RESET_APP_ERRORS);

export const handleGoBack = (steps) => async (dispatch) => {
  return dispatch(goBack(steps));
};

export const handleGetComponents = (environmentId, params) => async (dispatch) => {
  try {
    await dispatch(
      getComponents(
        POST(API.applications.parseDocker(environmentId), params).then(({ data }) => {
          dispatch(resetAppErrors())
          return data
        })
      )
    );
  } catch (exception) {
    log.error('Error', exception);
    if(!R.path(['response', 'data', 'violations'], exception)){
      const errorMessage = getErrorMessage(exception);
      const recipePath = R.path(['response', 'data', 'recipePath'])(exception);

      dispatch(setConfigError({errorMessage, recipePath}));
    }

  }
};
export const handleSaveTempComponent =
  (environmentId, component) => async (dispatch, getState) => {
    const { components, tempEnvYml } = getState().applications;
    const index = R.findIndex(R.propEq('name', component.name))(components);
    const newComponents = R.adjust(
      index,
      () => ({
        ...component,
      }),
      components
    );
    const newEnvYaml = {
      envYaml: {
        ...tempEnvYml, components: newComponents
      },
      merge: false
    }
    try {
      await dispatch(
        saveTempComponent(
          POST(API.applications.validateComponents(environmentId), newEnvYaml)
            .then(({ data }) => {
              return data
            })
        )
      );
    } catch (exception) {
      log.error('Error', exception);
    }
  };
export const handleSaveComponent =
  (environmentId, appName, component, organizationId, projectId, history) => async (dispatch) => {
    let response = {};

    try {
      return await dispatch(
        saveComponent(
          PUT(API.applications.saveComponent(environmentId, appName), component).then(({ data }) => {
            // PARAMS: environmentId, environmentState
            dispatch(handleGetDraftComponents(environmentId, null))
            history.replace(`/${organizationId}/projects/${projectId}/environments/${environmentId}/applications/${component.name}/settings/general`);
            response = data;
            return data;
          })
        )
      );
    } catch (exception) {
      log.error('Error', exception);
      response = exception;

      if (exception.response.status === 422 || exception.response.status === 423) {
        return response; 
      }

      dispatch(showNotification(TOAST_TYPES.error, 'Error', getErrorMessage(exception)));

      return response;
    }

  };

export const handleDeleteComponent = (environmentId, name) => async (dispatch) => {
  try {
    await dispatch(
      deleteComponent(
        POST(API.applications.deleteComponent(environmentId), { componentName: name }).then(
          ({ data }) => {
            return data;
          }
        )
      )
    );
  } catch (exception) {
    log.error('Error', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Error', getErrorMessage(exception)));
  }
};

export const handleReviewComponents = (environmentId) => async (dispatch, getState) => {
  const {  tempEnvYml } = getState().applications;
  const newEnvYaml = {
    envYaml: tempEnvYml,
    dryRun: true
  }
  try {
    const url = `${API.applications.addComponents(environmentId)}`;
    await dispatch(
      reviewComponents(
        POST(url, newEnvYaml, {
          'Content-Type': 'application/json',
          Accept: 'text/yaml',
        } ).then(({ data }) => data)
      )
    );
  } catch (exception) {
    log.error('Error', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Error', getErrorMessage(exception)));
  }
};
export const handlePublishApplication = (
  environmentId, organizationId, projectId, history
) => async (dispatch) => {
  try {
    await dispatch(
      publishApplication(
        POST(API.applications.publish(environmentId)).then(({data}) => {
          // update env state with latest; will show env with components instead of empty
          dispatch(manuallyUpdateEnvironment(data));
          history.push(`/${organizationId}/projects/${projectId}/environments/${environmentId}`, {hasComponents: true, fromCreate: true});

        })
      )
    );
  } catch (exception) {
    log.error('Error', exception);
    if(includes(getErrorMessage(exception), 'Internal Server Error')){
      dispatch(showDialog({
        options: {size: "small"},
        header: '',
        body: (
          <div className="text-center pb-5">
            <svg width="100" height="100" viewBox="0 0 100 100" className="mb-5 mt-5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100C77.6142 100 100 77.6142 100 50Z" fill="#E44848" fillOpacity="0.15"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M43.0485 29.1846C45.8806 29.0804 48.6998 29.6193 51.2937 30.7614C53.8877 31.9033 56.1889 33.6185 58.0246 35.7779C59.5069 37.5214 60.6544 39.5181 61.4146 41.6668H62.5C65.2771 41.6687 67.9758 42.5952 70.1679 44.3004C72.36 46.0056 73.9218 48.3923 74.6068 51.0835C75.2918 53.775 75.061 56.6177 73.951 59.1633C72.8408 61.7089 70.9146 63.8125 68.4762 65.1418C67.466 65.6925 66.2006 65.3202 65.65 64.3098C65.0991 63.2995 65.4716 62.0341 66.4819 61.4835C68.1075 60.5973 69.3916 59.195 70.1316 57.4979C70.8716 55.8008 71.0256 53.9056 70.5689 52.1112C70.1123 50.317 69.071 48.726 67.6096 47.5891C66.1483 46.4525 64.35 45.8348 62.4985 45.8335H59.875C58.9252 45.8335 58.0956 45.1912 57.8579 44.2716C57.306 42.1375 56.2777 40.156 54.85 38.4766C53.4223 36.797 51.6323 35.4631 49.6148 34.5748C47.5973 33.6866 45.4048 33.2673 43.2019 33.3485C40.9989 33.4296 38.8431 34.0091 36.8964 35.0433C34.9498 36.0777 33.2629 37.5398 31.9627 39.3198C30.6625 41.0998 29.7825 43.1514 29.3894 45.3206C28.9962 47.4895 29.0998 49.7195 29.6925 51.8427C30.285 53.9658 31.3512 55.927 32.811 57.5789C33.5729 58.441 33.4916 59.7577 32.6296 60.5195C31.7673 61.2814 30.4506 61.2002 29.6887 60.3381C27.8121 58.2143 26.441 55.6927 25.6791 52.9629C24.9171 50.2331 24.7839 47.366 25.2896 44.5773C25.7952 41.7885 26.9262 39.1506 28.5981 36.8621C30.2698 34.5735 32.4387 32.6935 34.9416 31.3639C37.4444 30.0341 40.2162 29.2889 43.0485 29.1846ZM48.5269 48.5271C49.3404 47.7135 50.6594 47.7135 51.4731 48.5271L59.8064 56.8604C60.62 57.6739 60.62 58.9931 59.8064 59.8066C58.9927 60.6202 57.6737 60.6202 56.8602 59.8066L52.0833 55.0298V68.7502C52.0833 69.9008 51.1504 70.8335 50 70.8335C48.8494 70.8335 47.9166 69.9008 47.9166 68.7502V55.0298L43.1398 59.8066C42.326 60.6202 41.0071 60.6202 40.1935 59.8066C39.3798 58.9931 39.3798 57.6739 40.1935 56.8604L48.5269 48.5271Z" fill="#E44848"/>
            </svg>
            <h4 className="mb-2 text-center">Connection Error</h4>
            <p className="mb-3 text-center">We’ve encountered an error while trying to connect to our servers. </p>
          </div>

        )
      }));
    }
  }
};

export const handleCreateApplication = (
  environmentId, yaml, organizationId, projectId, history, capabilitySettings
) => async (dispatch) => {

  const { triggerCheck: triggerCompatibilityCheck } = capabilitySettings;
  const capabilityTag = triggerCompatibilityCheck ? '?validateCapabilities=true' : '';
  try {
    await dispatch(
      createApplication(
        PUT(`${API.applications.createApp(environmentId)}${capabilityTag}`, yaml, {
          'Content-Type': 'text/yaml',
          Accept: 'text/yaml',
        }).then(() => {
          mixpanel.track('Create application - Application Created');
          dispatch(handlePublishApplication(environmentId, organizationId, projectId, history))
            .then(() => {
              dispatch(resetAppErrors())
            })
        })
      )
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};

export const handleGetApplicationVariables = (id, appName, searchTerm) =>
  async (dispatch, getState) => {
  const { environment } = getState().environments;
  const { hasDraft } = environment;
  const url = hasDraft
    ? `${API.environments.base}/${id}/draft/components/${appName}/application_variables?q=${searchTerm}`
    : `${API.environments.base}/${id}/components/${appName}/application_variables?q=${searchTerm}`;
  try {
    await dispatch(
      getVariables(
        GET(url).then(({ data }) => {
          return { data, isSearch: searchTerm !== ''};
        })
      )
    );
  } catch (exception) {
    const message = getErrorMessage(exception);
    log.error(`Environment unavailable.`, exception);
    
    if (message === 'appName: Component not found') {
      dispatch(handleCriticalErrorState({ isActive: true, details: notFoundError }));
    }
  }
};

export const handleCreateVariable =
  (varName, varValue, varEnvironmentId, componentName, secret, type, organizationId, projectId) =>
    async (dispatch, getState) => {
  const {environment} = getState().environments;
  const { mode } = getState().global;
    try {
      await dispatch(
        createVariable(
          POST(`${API.environments.base}/${varEnvironmentId}/draft/components/application_variables/add`, {
            key: varName,
            value: varValue,
            scope: type,
            componentName,
            //environment: `/api/environments/${varEnvironmentId}`,
            isSecret: secret,
          }).then(({ data }) => {
            if(!environment.hasDraft){

              dispatch(handleGetEnvironmentDetails({organizationId, projectId, environmentId: varEnvironmentId}))
                .then(() => {
                  dispatch(handleGetEnvironmentVariables(varEnvironmentId, ''));
                })
            }
            dispatch(hideDialog());
            dispatch(
              showNotification(
                TOAST_TYPES.success,
                'Variable created successfully ',
                `The ${data.key} variable has been created.`
              )
            );
            return data;
          })
        )
      );
    } catch (exception) {
      log.error(`Error.`, exception);
  
      if (exception.response.status === 422) {
        // TODO: handle error 
      } else if (exception.response.status === 423) {
        dispatch(showDialog({
          options: {
            size: 'large',
            className: 'handleCannotDeployMfd',
            onCancel: () => {
              dispatch(hideDialog());
            },
          },
          header: (
            <>
              <svg
                className="dialog-icon"
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="56" height="56" rx="28" fill={`${mode === 'dark' ? '#E32525' : '#FFE9E9'}`} />
                <rect
                  x={`${mode === 'dark' ? '2' : '8'}`}
                  y={`${mode === 'dark' ? '2' : '8'}`}
                  width={`${mode === 'dark' ? '52' : '40'}`}
                  height={`${mode === 'dark' ? '52' : '40'}`}
                  rx={`${mode === 'dark' ? '52' : '20'}`}
                  fill={`${mode === 'dark' ? '#222222' : '#FFCACE"'}`}
                />
                <path
                  d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z"
                  stroke="#E32525"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <br />
              Cannot deploy environment
            </>
          ),
          body: (
            <PublishErrorModal
              environmentId={varEnvironmentId}
              organizationId={organizationId}
              projectId={projectId}
              exception={exception}
            />
          ),
          actions: <div />,
        }));
      } else {
        dispatch(showNotification(TOAST_TYPES.error, 'Error', getErrorMessage(exception))); 
      }
    }
  };

export const handleUpdateVariable =
  (oldVarName, newKey, varValue, varEnvironmentId,componentName,secret,organizationId,projectId) =>
    async (dispatch, getState) => {
  
  const {environment} = getState().environments
  let payload;
  if(newKey === oldVarName){
    payload ={
      key: oldVarName,
      value: varValue,
      componentName,
      isSecret: secret
    }
  }else {
    payload = {
      newKey,
      key: oldVarName,
      value: varValue,
      componentName,
      isSecret: secret
    }
  }
    try {
      await dispatch(
        editVariable(
          PUT(`${API.environments.base}/${varEnvironmentId}/draft/components/application_variables/edit`, payload).then(({ data }) => {
            dispatch(hideDialog())
            if(!environment.hasDraft){
              dispatch(handleGetEnvironmentDetails({organizationId, projectId, environmentId: varEnvironmentId}))
                .then(() => {
                  dispatch(handleGetApplicationVariables(varEnvironmentId, componentName, ''))
                })
            } else {
              dispatch(handleGetApplicationVariables(varEnvironmentId, componentName, ''))
            }

            dispatch(
              showNotification(
                TOAST_TYPES.success,
                'Variable saved successfully ',
                `The  ${data.key} variable has been updated.`
              )
            );
            return data;
          })
        )
      );
    } catch (exception) {
      log.error(`Error.`, exception);
      if (exception.response.status === 422) {
        return exception; 
      }

      dispatch(showNotification(TOAST_TYPES.error, 'Error', getErrorMessage(exception)));

      return exception;
    }

    return 'done';
  };

export const handleDeleteAppVariable = (key,environmentId,componentName,organizationId,projectId) =>
  async (dispatch, getState) => {
  const {environment} = getState().environments;
  try {
    await dispatch(
      deleteVariable(
        DELETE(`${API.environments.base}/${environmentId}/draft/components/application_variables/delete`, {}, {key, componentName}).then(() => {
          dispatch(hideDialog())
          if(!environment.hasDraft){
            dispatch(handleGetEnvironmentDetails({organizationId, projectId, environmentId}))
              .then(() => {
                dispatch(handleGetApplicationVariables(environmentId, componentName, ''))
              })
          } else {
            dispatch(handleGetApplicationVariables(environmentId, componentName, ''))
          }
          dispatch(resetSecretValues())
          dispatch(showNotification(TOAST_TYPES.success, 'Variable deleted', 'The variable has been deleted.'));
          return key;
        })
      )
    );
  } catch (exception) {
    log.error(`Error.`, exception);

  }
};