import differenceInSeconds from 'date-fns/differenceInSeconds';

if (typeof window !== 'undefined') window.internalCache = {};

export const clearInternalCache = (key = null) => {
  if (key) {
    window.internalCache[key] = undefined;
    return;
  }
  window.internalCache = {};
};

export const withCache = (promiseFn, expiryTime = 600, customKey = null) => {
  return (...args) => {
    const key = customKey || JSON.stringify(args);
    const now = Date.now();
    const cache = typeof window !== 'undefined' ? window.internalCache : {};

    if (cache[key]) {
      // request not yet finished, return the promise.
      if (!cache[key].timestamp) {
        return cache[key];
      }

      const diff = differenceInSeconds(now, cache[key].timestamp);

      if (diff < expiryTime) {
        return new Promise((resolve) => resolve(cache[key].value));
      }

      cache[key] = promiseFn(...args).then((result) => {
        if (!cache[key]) cache[key] = {};
        cache[key].value = result;
        cache[key].timestamp = now;

        return result;
      });

      return cache[key];
    }
    cache[key] = promiseFn(...args).then((result) => {
      if (!cache[key]) cache[key] = {};
      cache[key].value = result;
      cache[key].timestamp = now;
      return result;
    });
    return cache[key];
  };
};

export default withCache;
