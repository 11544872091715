import styled, { css } from 'styled-components';

const StyledChunkErrorPage = styled.div`
  ${() => {
    return css`
      display: flex;
      padding-top: 136px;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;

      .chunk-error-img {
        position: relative;
        margin-bottom: 30px;
        width: 100px;
        height: 100px;
        background: #ece7fa;
        border-radius: 50%;

        .chunk-svg {
          position: absolute;
          top: 50%;
          transform: translateY(-55%);
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }

      .chunk-error-title {
        color: #222222;
        font-family: 'Circular-Bold';
        font-size: 20px;
        line-height: 20px;
        letter-spacing: -0.02em;
      }

      .chunk-error-description {
        color: #8e8e90;
        margin-bottom: 30px;
        font-weight: 400;
        max-width: 380px;
        font-size: 16px;
        line-height: 22.4px;
        text-align: center;
      }

      .ant-btn.ant-btn-primary {
        padding: 7px 20px;
        width: 116px;
        height: 34px;
        line-height: 34px;
        > span {
          font-family: 'Circular-Bold' !important;
          line-height: 1;
        }
      }
    `;
  }}
`;

StyledChunkErrorPage.displayName = 'StyledChunkErrorPage';
export default StyledChunkErrorPage;
