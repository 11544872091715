import styled, { css } from 'styled-components';
import StyledGeneric from 'components/timeframes/Generic.style';

const StyledHourRange = styled(StyledGeneric)`
  ${({ theme }) => {
    const { palette } = theme;

    return css`
      display: flex;
      align-items: center;
      @media (max-width: 767px){
        width: 100%;
        margin-bottom: 16px;
      }
      .range-label {
        margin: 0 18px 0 0;
        color: ${palette.text[1]};
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        @media (max-width: 767px){
          width: 26px;
          margin: 0 8px;
          font-size: 12px;
        }
      }
    `;
  }}
`;

StyledHourRange.displayName = 'StyledHourRange';
export default StyledHourRange;
