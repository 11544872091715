import styled, { css } from 'styled-components';
import STATUS_TYPES from './constants';

const StyledStatusLabel = styled.div`
  ${({ theme, status, type, mode }) => {
  const { palette } = theme;
  
  const STATUS_BULLET_MAP = {
    [STATUS_TYPES.running]: palette.success[0],
    [STATUS_TYPES.deployed]: palette.success[0],
    [STATUS_TYPES.active]: palette.success[0],
    [STATUS_TYPES.applied]: palette.success[0],
    [STATUS_TYPES.success]: palette.success[0],
    [STATUS_TYPES.fail]: palette.error[0],
    [STATUS_TYPES.failed]: palette.error[0],
    [STATUS_TYPES.aborted]: palette.error[0],
    [STATUS_TYPES.applying_failed]: palette.error[0],
    [STATUS_TYPES.deleting_failed]: palette.error[0],
    [STATUS_TYPES.running_with_errors]: palette.error[0],
    [STATUS_TYPES.deployed_with_errors]: palette.error[0],
    [STATUS_TYPES.partially_running]: palette.success[0],
    [STATUS_TYPES.partially_deployed]: palette.success[0],
    [STATUS_TYPES.partially_deployed_with_errors]: palette.error[0],
    [STATUS_TYPES.partially_running_with_errors]: palette.error[0],
    [STATUS_TYPES.error]: palette.error[0],
    [STATUS_TYPES.draft]: mode === 'dark' ? palette.primary[5] : palette.primary[0],
    [STATUS_TYPES.deleting]: mode === 'dark' ? palette.primary[5] : palette.primary[0],
    [STATUS_TYPES.ready]: palette.success[0],
    [STATUS_TYPES.stopped]: palette.grays[4],
    [STATUS_TYPES.disabled]: palette.grays[4],
    [STATUS_TYPES.out_of_sync]: mode === 'dark' ? palette.warning[0] : palette.warning[1],
  }
  
  const STATUS_BG_MAP = {
    [STATUS_TYPES.running]: palette.success[2],
    [STATUS_TYPES.deployed]: palette.success[2],
    [STATUS_TYPES.partially_running]: palette.success[2],
    [STATUS_TYPES.applied]: palette.success[2],
    [STATUS_TYPES.active]: palette.success[2],
    [STATUS_TYPES.success]: palette.success[2],
    [STATUS_TYPES.fail]: palette.error[2],
    [STATUS_TYPES.failed]: palette.error[2],
    [STATUS_TYPES.aborted]: palette.error[2],
    [STATUS_TYPES.applying_failed]: palette.error[2],
    [STATUS_TYPES.deleting_failed]: palette.error[2],
    [STATUS_TYPES.running_with_errors]: palette.error[2],
    [STATUS_TYPES.deployed_with_errors]: palette.error[2],
    [STATUS_TYPES.partially_deployed]: palette.success[2],
    [STATUS_TYPES.partially_running_with_errors]: palette.error[2],
    [STATUS_TYPES.partially_deployed_with_errors]: palette.error[2],
    [STATUS_TYPES.error]: palette.error[2],
    [STATUS_TYPES.draft]: palette.primary[4],
    [STATUS_TYPES.deleting]: palette.primary[4],
    [STATUS_TYPES.ready]: palette.success[2],
    [STATUS_TYPES.stopped]: palette.grays[9],
    [STATUS_TYPES.disabled]: palette.grays[9],
    [STATUS_TYPES.synced]: palette.success[2],
    [STATUS_TYPES.out_of_sync]: palette.warning[2],
  }
  const STATUS_TEXT_MAP = {
    [STATUS_TYPES.running]: mode === 'dark' ? palette.success[0] : palette.success[1],
    [STATUS_TYPES.deployed]: mode === 'dark' ? palette.success[0] :  palette.success[1],
    [STATUS_TYPES.applied]: mode === 'dark' ? palette.success[0] :  palette.success[1],
    [STATUS_TYPES.active]: mode === 'dark' ? palette.success[0] :  palette.success[1],
    [STATUS_TYPES.success]: mode === 'dark' ? palette.success[0] :  palette.success[1], 
    [STATUS_TYPES.fail]: mode === 'dark' ? palette.error[0] :  palette.error[1],
    [STATUS_TYPES.failed]: mode === 'dark' ? palette.error[0] :  palette.error[1],
    [STATUS_TYPES.aborted]: mode === 'dark' ? palette.error[0] :  palette.error[1],
    [STATUS_TYPES.deleting_failed]: mode === 'dark' ? palette.error[0] :  palette.error[1],
    [STATUS_TYPES.applying_failed]: mode === 'dark' ? palette.error[0] :  palette.error[1],
    [STATUS_TYPES.partially_running]: mode === 'dark' ? palette.success[0] : palette.success[1],
    [STATUS_TYPES.partially_deployed]: mode === 'dark' ? palette.success[0] : palette.success[1],
    [STATUS_TYPES.running_with_errors]: mode === 'dark' ? palette.error[0] :  palette.error[1],
    [STATUS_TYPES.deployed_with_errors]: mode === 'dark' ? palette.error[0] :  palette.error[1],
    [STATUS_TYPES.partially_running_with_errors]: mode === 'dark' ? palette.error[0] :  palette.error[1],
    [STATUS_TYPES.partially_deployed_with_errors]: mode === 'dark' ? palette.error[0] :  palette.error[1],
    [STATUS_TYPES.error]: mode === 'dark' ? palette.error[0] :  palette.error[1],
    [STATUS_TYPES.draft]: mode === 'dark' ? palette.primary[5] : palette.primary[1],
    [STATUS_TYPES.deleting]: mode === 'dark' ? palette.primary[5] : palette.primary[1],
    [STATUS_TYPES.ready]: mode === 'dark' ? palette.success[0] :  palette.success[1],
    [STATUS_TYPES.stopped]: mode === 'dark' ? palette.grays[7] :  palette.grays[1],
    [STATUS_TYPES.disabled]: mode === 'dark' ? palette.grays[7] :  palette.grays[1],
    [STATUS_TYPES.synced]: mode === 'dark' ? palette.success[0] :  palette.success[1],
    [STATUS_TYPES.out_of_sync]: mode === 'dark' ? palette.warning[0] :  palette.warning[1],
  }
  
  return css`
      font-size: ${palette.fontSize[2]};
      line-height: 18px;
      display: inline-flex;
      align-items: center;
      position: relative;
      border-radius: 4px;
      padding: 2px 8px;
      cursor: default;
      background-color: ${mode === 'dark' ? 'transparent' : (STATUS_BG_MAP[status] || STATUS_BG_MAP.draft)};
      color: ${STATUS_TEXT_MAP[status] || STATUS_TEXT_MAP.draft};
      white-space: nowrap;

      &.draft,
      &.pending,
      &.in_progress,
      &.failing,
      &.deleting,
      &.applying,
      &.syncing,
      &.inactive{
        #dark & {
          border: 1px solid ${palette.primary[5]};
        }
      }
      &.running, &.deployed, &.applied,
      &.success, &.ready, &.active,
      &.partially_running,
      &.partially_deployed,
      &.synced{
        #dark & {
          border: 1px solid ${palette.success[0]};
        }
      }
      &.fail,
      &.failed,
      &.aborted,
      &.error,
      &.deleting_failed,
      &.applying_failed,
      &.running_with_errors,
      &.deployed_with_errors,
      &.partially_running_with_errors,
      &.partially_deployed_with_errors{
        #dark & {
          border: 1px solid ${palette.error[0]};
        }
      }
      &.stopped,
      &.disabled{
        #dark & {
          border: 1px solid ${palette.grays[7]};
        }
      }
      &.out_of_sync{
        #dark & {
          border: 1px solid ${palette.warning[0]}
        }
      }
      &.hidden {
        visibility: hidden;
      }

      svg{
        margin-right: 4px;
      }
      .loader{
        height: 16px;
        width: 16px;
        text-align: center;
        padding: 0;
        margin: 0 6px 0 0;
        display: inline-block;
        vertical-align: top;
        position: absolute;
        left: -7px;
        svg{
          width: 16px;
          height: 16px;
        }
      }
      
      &:before {
        content: '';
        display: ${type ? 'none' : 'inline-block'};
        width: 6px;
        height: 6px;
        min-width: 6px;
        border-radius: 50%;
        background-color: ${STATUS_BULLET_MAP[status] || STATUS_BULLET_MAP.draft};
        margin-right: 6px;
      }
    `;
}}
`;

StyledStatusLabel.displayName = 'StyledStatusLabel';
export default StyledStatusLabel;
