import React, { useEffect } from 'react';
import httpIndexer from "../../config/http/http_indexer";

function usePageLoaderVisibility() {
    const [loadingState, setLoadingState] = React.useState({status: false, value: 0});

    useEffect(() => {
        if (httpIndexer.makePageVisible) {
            //force another render if makePageVisible becomes true before the timeout executes
            if (loadingState.status === false) {
                setLoadingState({status: true, value: loadingState.value + 1})
            }
            return;
        }
        setTimeout(() => setLoadingState({status: false, value: loadingState.value + 1}), 10)

    }, [loadingState])

    return loadingState.status
}

export default usePageLoaderVisibility