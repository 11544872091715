import React from "react";
import { Space } from "antd";
import Button from 'theme/uielements/button';
import { Link } from "react-router-dom";
import styled from "styled-components";
import { RouterState } from "modules/billing/components/billingV2/components/billingAddress/BillingAddressSection";
import { routing } from "modules/meeting/Router";

export interface IProps {
    organizationId: number;
}

export function FreemiumPage({
    organizationId,

    ...props
}: IProps): React.ReactElement {
    return <div {...props} style={{paddingLeft: '96px', paddingRight: '96px'}}>
        <hgroup>
            <h3 className="mb-3">This Feature is Not Available in the Free Plan</h3>

            <Space direction="vertical">
                <p>Ready to take your projects to the next level? Add your billing details to unlock additional premium
                    features with Bunnyshell.
                    <br/>
                    Our pricing is consumption-based, calculated by the minute for running environments.
                    This ensures you only pay for what you truly use, giving you control over your costs without any
                    long-term commitments.
                </p>

                <Space className="mt-3">
                    <Link
                        to={{
                            pathname: `/${organizationId}/billing`,
                            state: {
                                addCard: true,
                            } as RouterState,
                        }}
                    ><Button
                        type="primary"
                    >Add billing details</Button></Link>
                    <Link
                        to={{
                            pathname: routing.billing(organizationId),
                        }}
                    ><Button
                        type="info"
                    >Request more time</Button></Link>
                </Space>
            </Space>
        </hgroup>
    </div>
}

export const StyledFreemiumPage = styled(FreemiumPage).withConfig({
    displayName: 'StyledFreemiumPage',
})`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 100px;

    hgroup {
        h3 {
            line-height: 60px;
            font-weight: 700;
            font-size: 48px;
        }

        p {
            line-height: 30px;
            font-weight: 400;
            font-size: 20px;

            flex-grow: 1;
        }
    }
`
