import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { SVG } from 'components';
import { connect } from 'react-redux';

const OrganizationTitle = ({ mode, organizationName }) => (
  <div className="isoMenuHolder d-flex">
    <div className="d-flex justify-content-start align-items-center flex-nowrap">
      <SVG name="bicon-organization" width={24} height={24} />
      <span className="nav-text org-name ellipsis mr-05">{organizationName}</span>
      <span className="nav-text arrow-right">
        <svg className="icon default">
          <use xlinkHref="#icon-arrowup" />
        </svg>
        <svg className="icon active">
          <use xlinkHref={`#${mode === 'light' ? 'icon-arrowup-active' : 'icon-arrowup-active-dark'}`} />
        </svg>
      </span>
    </div>
  </div>
);

OrganizationTitle.defaultProps = {
  organizationName: null,
};

OrganizationTitle.propTypes = {
  mode: PropTypes.string.isRequired,
  organizationName: PropTypes.string,
};
const mapStateToProps = (state) => {
  const { mode } = state.global;
  const { currentOrganization, lastUsedOrganization, organizations } = state.userManager;
  const organizationId =
    parseInt(currentOrganization, 10) || (lastUsedOrganization && parseInt(lastUsedOrganization.id, 10));
  const currentOrg = organizations && R.find(R.propEq('id', organizationId))(organizations);

  return {
    mode,
    organizationId,
    organizationName: currentOrg ? currentOrg.name : 'Organizations',
  };
};
export default connect(mapStateToProps, null)(OrganizationTitle);
