import { Select } from 'antd';
import WithDirection from 'theme/helpers/rtl';
import { AntSelect } from './styles/select.style';

const WDSelect = AntSelect(Select);
const isoSelect = WithDirection(WDSelect);
const SelectOption = Select.Option;

export default isoSelect;
export { SelectOption };
