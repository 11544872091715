import React from "react";
import { Space } from "antd";
import Button from 'theme/uielements/button';
import styled from "styled-components";
import { useBillingInfo } from "components/header/components/billing/utils";

import welcome from "assets/images/welcome/welcome_transparent.png";

export interface IProps {
}

export function WelcomeBanner(props: IProps): React.ReactElement {
    const info = useBillingInfo();

    if (info.billingPlanType !== 'trial') {
        return <></>
    }

    return <div {...props}>

        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-6">
            <img src={welcome} alt="Welcome" style={{ width: '200px', height: 'auto' }} />
        </div>
        <div className="col-xl-10 col-lg-9 col-md-8 col-sm-7 col-xs-6">
            <h3 className="mb-1">🚀 Get started with Bunnyshell</h3>

            <p>To get you started, we recommend going through our Bunnyshell Onboarding Guide.<br/>
                This guide will walk you through setting up your environment, integrating your systems,
                and taking full advantage of our platform&apos;s features.</p>

            <Space className="mt-1">
                <a
                    href="https://bunnyshell-web-static-assets.s3.eu-west-1.amazonaws.com/Bunnyshell_Onboarding_Guide-v1.166.pdf"
                    target="_blank"
                    rel="noreferrer"
                ><Button
                    type="primary"
                >Download the Onboarding Guide</Button></a>
            </Space>
        </div>
    </div>
}

export const StyledWelcomeBanner = styled(WelcomeBanner).withConfig({
    displayName: 'StyledWelcomeBanner',
}).attrs({
    className: 'row p-4 mb-4',
})`
    background-color: #ECE7FC;
    color: #333333;
    border-radius: 4px;

    padding: 15px 45px;
    margin-bottom: 32px;

    p {
        color: #737376;

        flex-grow: 1;
    }

    #dark & {
        background-color: #8A75E9;
        color: #CCCCCC;
    }
`