import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import loaderBlue from "assets/images/loaderBlue.svg";
import loaderWhite from 'assets/images/buttonLoaderWhite.svg';
import StyledLoaderDialog from "./LoaderDialog.style";

const LoaderDialog = ({bgColor, title, content, loader, className, mode}) => {
  return (
    <StyledLoaderDialog bgColor={bgColor}>
      <div className='backdrop'>
        <div className={`dialog-content ${className}`}>
          {loader && <object type="image/svg+xml" width="60" height="60" data={mode === 'dark' ? loaderWhite : loaderBlue}>svg-animation</object> }
          {title && <h4 className='mb-3'>{title}</h4> }
          <div className='mb-0 description'>
            {content}
          </div>
        </div>
      </div>
    </StyledLoaderDialog>
  );
};
LoaderDialog.defaultProps = {
  bgColor: null,
  title: null,
  content: null,
  loader: true,
  className: null
};

LoaderDialog.propTypes = {
  mode: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({})
  ]),
  loader: PropTypes.bool,

};
const mapStateToProps = (state) => {
  const { mode } = state.global;

  return {
    mode
  };
};
export default connect(mapStateToProps, null)(LoaderDialog);