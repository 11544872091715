import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import {
  goBack,
  getComponents,
  saveComponent,
  saveTempComponent,
  deleteComponent,
  reviewComponents,
  resetState,
  setConfigError,
  createApplication, publishApplication, deleteTempComponent,
  createVariable,
  deleteVariable,
  getVariables,
  editVariable,
  resetAppErrors
} from '../actions';
import initialState from './initialState';

const goBackHandler = [
  goBack,
  (state, action) => {
    return {
      ...state,
      steps: action.payload,
    };
  },
];
const getComponentsHandler = [
  getComponents,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        configErrorMessage: null,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'violations'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: null,
      steps: {
        step1: 'checked',
        step2: 'active',
        step3: '',
      },
      tempEnvYml: payload,
      components: payload.components,
    };
  },
];

const setConfigErrorHandler = [
  setConfigError,
  (state, action) => {
    const {payload} = action;
    return {
      ...state,
      configErrorMessage: payload
    }
  }
]
const saveComponentHandler = [
  saveComponent,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isEditLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isEditLoading: false,
        errorMessage: R.path(['response', 'data', 'violations'], payload),
      };
    }

    return {
      ...state,
      isEditLoading: false,
      errorMessage: null,
      components: payload.components,
    };
  },
];

const saveTempComponentHandler = [
  saveTempComponent,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isEditLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isEditLoading: false,
        errorMessage: R.path(['response', 'data', 'violations'], payload),
      };
    }

    return {
      ...state,
      isEditLoading: false,
      errorMessage: null,
      tempEnvYml: payload,
      components: payload.components,
    };
  },
];

const createApplicationHandler = [
  createApplication,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        errorMessage: null,
        isEditLoading: true,
        violations: [],
      };
    }
    if (error) {
      return {
        ...state,
        isEditLoading: false,
        errorMessage: R.path(['response', 'data'], payload),
        violations: R.path(['response', 'data', 'violations'], payload) || [],
      };
    }
    return {
      ...state,
      errorMessage: null,
      isEditLoading: true
    };
  },
];

const publishApplicationHandler = [
  publishApplication,
  (state, action) => {
    const { ready, error } = action;

    if (!ready) {
      return {
        ...state,
        isEditLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isEditLoading: false,
      };
    }
    return {
      ...state,
      isEditLoading: true,
      errorMessage: null,
    };
  },
];
const deleteComponentHandler = [
  deleteComponent,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
      };
    }

    return {
      ...state,
      isLoading: false,
      components: payload.components,
    };
  },
];
const deleteTempComponentHandler = [
  deleteTempComponent,
  (state, action) => {
    const { payload } = action;
    const newComponentList = R.filter(component => component.name !== payload)(state.components);
    const newTempEnvYml = {...state.tempEnvYml, components: newComponentList}
    return {
      ...state,
      isLoading: false,
      tempEnvYml: newTempEnvYml,
      components: newComponentList,
    };
  },
];

const getReviewComponentsHandler = [
  reviewComponents,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        errorMessage: null,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
      };
    }

    return {
      ...state,
      isLoading: false,
      errorMessage: null,
      steps: {
        step1: 'checked',
        step2: 'checked',
        step3: 'active',
      },
      componentsYaml: payload,
    };
  },
];
const createVariableHandler = [
  createVariable,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.data,
      };
    }
    const index = R.findIndex(R.propEq('key', payload.key))(state.variables);
    if(index > -1){
      const variables = R.adjust(
        index,
        () => ({
          ...payload,
        }),
        state.variables
      );
      return {
        ...state,
        isLoading: false,
        variables
      }
    }
    return {
      ...state,
      isLoading: false,
      errorMessage: null,
      variables: R.prepend(payload, state.variables),
    };
  },
];

const updateVariableHandler = [
  editVariable,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.data,
      };
    }
    // const index = R.findIndex(R.propEq('key', payload.key))(state.variables);
    // const variables = R.adjust(
    //   index,
    //   () => ({
    //     ...payload,
    //   }),
    //   state.variables
    // );
    return {
      ...state,
      isLoading: false,
      // variables,
    };
  },
];

const getApplicationVariablesHandler = [
  getVariables,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
      };
    }

    return {
      ...state,
      isLoading: false,
      variables: payload.data,
      isSearch: payload.isSearch,
      updateResourceId: ''
    };
  },
];

const deleteVariableHandler = [
  deleteVariable,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
      };
    }

    const index = R.findIndex(R.propEq('key', payload))(state.variables);
    if(!state.variables[index].overridden){
      return {
        ...state,
        isLoading: false,
        variables: R.remove(index, 1, state.variables),
      };
    }
    return {
      ...state,
      isLoading: false,
      // variables: R.remove(index, 1, state.variables),
    };
  },
];
const resetStateHandler = [
  resetState,
  (state) => {
    const yaml = state.componentsYaml;
    return {
      ...initialState,
      componentsYaml: yaml
    }
  }
  ];


const resetAppErrorsHandler = [
  resetAppErrors, (state) => {
    return {
      ...state,
      errorMessage: null,
      isEditLoading: false
    }
  }
]

const reducer = handleActions(
  new Map([
    goBackHandler,
    getComponentsHandler,
    getReviewComponentsHandler,
    saveComponentHandler,
    saveTempComponentHandler,
    deleteComponentHandler,
    deleteTempComponentHandler,
    resetStateHandler,
    setConfigErrorHandler,
    createApplicationHandler,
    publishApplicationHandler,
    createVariableHandler,
    updateVariableHandler,
    getApplicationVariablesHandler,
    deleteVariableHandler,
    resetAppErrorsHandler
  ]),
  R.clone(initialState)
);

export default reducer;
