import styled, { css } from 'styled-components';

const StyledProgressLoader = styled.div`
  ${({ theme, size }) => {
  const { palette } = theme;
    return css`
      display: flex;
      margin-right: 6px;
      align-items: center;
      .progress-loader{
        background-color: ${palette.primary[0]};
        border-radius: 3px;
        height: ${size === 'large' ? '22px': '16px'};
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: ${size === 'large' ? '7px 9px': '4px 6px'};
        svg{
          width: 17px;
          height: 8px;
        }
        #dark & {
          background-color: ${palette.background[3]};
          svg{
            path{
              stroke: ${palette.primary[0]};
            }
          }
        }
      }
      `;
  }}
`;

StyledProgressLoader.displayName = 'StyledProgressLoader';
export default StyledProgressLoader;
