export function capitalize(value: string = ''): string {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

// @todo humanize use on hashid table
export function humanize(value: string = ''): string {
    return value.split('_').map(capitalize).join(' ');
}

export function trimify(value: string = ''): string {
    return value.trim();
}
