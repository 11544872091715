/* eslint-disable max-lines-per-function,max-lines */
import styled, { css } from 'styled-components';
import { Modal } from 'antd';
import WithDirection from 'theme/helpers/rtl';
import { borderRadius } from 'theme/helpers/style_utils';
import { cvcIcon } from "assets/images"

const ModalWithDirection = WithDirection(Modal);

const StyledDialog = styled(ModalWithDirection)`
  ${(props) => {
    const { palette } = props.theme;
    const isRtlDirection = props['data-rtl'] === 'rtl';
    const isDrawer = props?.isDrawer;
    return isDrawer
      ? css`
          width: 0 !important;
          top: 0;
          display: inline;

          .ant-modal-header {
            display: none;
          }

          .ant-modal-body {
            padding: 0;
          }
        `
      : css`
      top: 50% !important;
      transform: translateY(-50%);
      @media (max-width: 767px){
        max-width: calc(100vw - 16px) !important;
      }
      .ant-modal-content {
        border: 1px solid #fff;
        #dark & {
          background-color: ${palette.background[0]};
          border: 1px solid ${palette.border[4]};
        }
        .ant-modal-header{
          #dark & {
            background-color: ${palette.background[0]};
          }
        }
      }
      &.handleCannotDeployMfd.large{
        width: 100% !important;
        max-width: 760px;
        .dialog-icon{
          margin-bottom: 20px;
        }
        p{
          font-size: 14px;
          line-height: 20px;
        }
        .ant-modal-content {
          .ant-modal-close {
            right: 32px;
            top: 32px;
            .ant-modal-close-x {
              display: flex;
              height: 36px;
              width: 36px;
              border: 0;
              background-color: #F2F2F2;
              border-radius: 4px;
              justify-content: center;
              #dark & {
                background-color: ${palette.background[1]};
              }
            }  
          }
        }
        .publishError{
          border: 1px solid #CCCCCC;
          border-radius: 4px; 
        }
      }

      &.isCLI {
        #dark & .ant-tabs {
          color: #fff;
        }
      }
      &.isDrawer {
        .ant-modal-content {
          #dark & {
            border: none;
          }
        }
      }

      &.handleLeaveWithoutSaving, 
      &.isVolumeCapInvalid,
      &.handleCannotDeployUrlHandle,
      &.port-forwarding-modal,
      &.remote-dev-modal,
      &.isNewModalStyle,
      &.handleCannotDeployUrlHandle {
        .ant-modal-header {
          padding: 32px 32px 0px 32px;
          .ant-modal-title {
            font-size: 18px;
            line-height: 28px;
            letter-spacing: -0.02em;
            .dialog-icon {
              margin-bottom: 20px;
            }
          }
        }
        .ant-modal-content {
          .ant-modal-close {
            right: 32px;
            top: 32px;
            .ant-modal-close-x {
              display: flex;
              height: 36px;
              width: 36px;
              border: 0;
              background-color: #F2F2F2;
              border-radius: 4px;
              justify-content: center;
              #dark & {
                background-color: ${palette.background[1]};
                &:hover{
                  svg{
                    use{
                      stroke: ${palette.grays[9]};
                    }
                  }
                }
                
              }
            }  
          }
        }

        .ant-modal-body {
          padding: 8px 32px 0 32px;
          .dialog-body {
            color: #808080;
            line-height: 20px;
          }
        }
        .ant-modal-footer {
          padding: 0 32px 32px 32px;
          .ant-btn-secondary {
            color: #333;
            background-color: #fff;
            border-color: #ccc;
            &:hover {
              color: #333;
              border-color: #ccc;
              background-color: #F9F9F9;
            }
          }
        }
      }

      &.hide-close {
        .ant-modal-close {
          display: none;
        }
      }

      .terraform-input-variable-modal{
        .ant-modal-content{
          min-height: 422px;
          height: auto;
        }
        textarea.ant-input{
          height: 125px !important;
          resize: none !important;
        }
      }
      #gcr-hostname .ant-select-selection-placeholder{
        color: ${palette.text[1]};
      }
      .ant-select{
        width: 100%;
        cursor: pointer;
      }
      &.small {
        max-width: 440px;
      }
      &.large {
        width: 640px !important;
      }
      &.large-700 {
        width: 700px !important;
      }
      &.extra-large {
        width: 844px !important;
      }
      .userTokenId{
        min-height: 138px;
        resize: none;
        &:focus{
          border-color: #737376;
        }
      }
      .hasUserTokenId .userTokenId {
        padding: 10px;
      }
      textarea.ant-input.description{
        resize: none;
        height: 64px;
        color: ${palette.text[0]};
        &:focus{
          border: 1px solid ${palette.border[1]};
        }
      }
      .google-address{
        span[class*="indicatorSeparator"],
        div[class*="indicatorContainer"]{display: none;}
        div[class*="-menu"]{
          z-index: 2;
          margin-top: 0px;
          border-radius: 0 0 4px 4px;
          padding: 0;
          >div{
            font-size: 12px;
            line-height: 16px;
          }
        }
        
        div[class*="-control"]{
          border: 1px solid ${palette.border[0]};
          box-shadow: 0 0 0;
          min-height: 34px;
          align-self: center;
          align-items: center;
          >div{padding: 5px 11px; max-height: 32px;    display: flex;
    flex-wrap: nowrap;}
          .css-1g6gooi{
            margin-top: 0px;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0px;
            padding-bottom: 0px;
          }
          div[class*="-placeholder"]{
            font-size: ${palette.fontSize[2]};
            line-height: 22px;
            margin-left: 0px;
            position: static;
            transform: translate(0);
            white-space: nowrap;
            margin-top: 0px;
            color: ${palette.text[0]};
          }
          div[class*="-singleValue"]{
            font-size: ${palette.fontSize[2]};
            line-height: 14px;
            color: ${palette.text[0]};
            margin-left: 0px;
            transform: translate(0);
            position: static;
            margin-top: 0px;
            white-space: nowrap;
          }
        }
      }
      .ant-modal-header {
        padding: 30px 30px 0px 30px;
        background: ${palette.color[7]};
        color: ${palette.text[0]};
        border-bottom: 0;
        ${borderRadius('4px 4px 0 0')};
      }
    .form-footer{
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .ant-btn-primary {
      background-color: ${palette.primary[0]};
      border-color: ${palette.primary[0]};
      display: block;
      padding: 7px 15px;
      height: 36px;
      border-radius: 4px;
      width: auto;
      margin-left: 12px;
      font-size: ${palette.fontSize[1]};
      line-height: 20px;
      span {
        font-family: ${palette.fonts[1]};
        color: ${palette.color[7]};
        letter-spacing: -0.02em;
      }
      &[disabled] {
        cursor: not-allowed;
        &:hover{
          background-color: ${palette.primary[0]};
          border-color: ${palette.primary[0]};
        }
      }
    }
    .ant-btn-secondary {
      background-color: ${palette.secondary[0]};
      border: 1px solid ${palette.secondary[0]};
      color: ${palette.text[0]};
      height: 36px;
      border-radius: 4px;
      font-size: ${palette.fontSize[1]};
      span {
        font-family: ${palette.fonts[1]};
        letter-spacing: -0.02em;
      }
      &:hover {
        border: 1px solid ${palette.secondary[1]};
        background-color: ${palette.secondary[1]};
        color: ${palette.primary[0]};
      }
    }

    #billingForm{
      .payment-label{
        font-size: ${palette.fontSize[2]};
        color: ${palette.text[0]};

        #dark & {
          color: ${palette.grays[7]};
        }
      }
      .ant-form-item-control-input-content:focus-within label,
      .ant-form-item-control-input-content label.filled{
        transform: translate(0, -20px);
      }
      .ant-form-item-control-input-content label{
        transform: translate(0, -4px);
      }
      .country{
        margin-bottom: 18px;
        width: 100%;
        .ant-select-selector{
          height: 36px;
          .ant-select-selection-search-input{
            height: 34px; 
          }
          .ant-select-selection-placeholder{
            line-height: 34px;
            color: ${palette.text[1]};
          }
          .ant-select-selection-item{
            line-height: 32px;
            color: ${palette.text[0]};
          }
        }
      }
      
      #cardNumber{
        border: 1px solid ${palette.border[0]};
        border-radius: 4px 4px 0 0;
        padding: 8px 12px;
        &.StripeElement--invalid{
          border: 1px solid ${palette.error[0]};
        }
      }
      .expiry-pl {
        padding-left: 12px;
      }
      .cvc-pr {
        padding-right: 12px;
      }
      #cvc{
        padding: 8px 12px;
        border-radius: 0 0 4px 0;
        border: 1px solid ${palette.border[0]};
        border-top: 0px;
        background-image: url(${cvcIcon});
        background-repeat: no-repeat;
        background-position: top 8px right 12px; 
        background-size: 25px 16px;
        &.StripeElement--invalid{
            border: 1px solid ${palette.error[0]};
            border-top: 0px
        }
      }
      #expiry{
        padding: 8px 12px;
        border-radius: 0 0 0 4px;
        border: 1px solid ${palette.border[0]};
        border-top: 0px;
        border-right: 0px;
        &.StripeElement--invalid{
          border: 1px solid ${palette.error[0]};
          border-top: 0px
        }
      }
    }
    &.edit-address-modal-container{
      .ant-modal-content{
        min-height: 585px;
      }
      .modal-action{
        position: absolute;
        right: 30px;
        bottom: 0px;
      }
    }
    &.edit-output-variable-dialog{
      .dialog-fields-container{
        min-height: 161px;
      }
    }
    .loading-anim[disabled]{
      &.ant-btn-primary {
        background-color: ${palette.primary[0]} !important;
        border-color: ${palette.primary[0]} !important;
        cursor: not-allowed;
        opacity: 1;
      }
      &:hover {
        background-color: ${palette.primary[0]} !important;
        border-color: ${palette.primary[0]} !important;
      }
    }
    #removeComponentButton[disabled]{
      width: 83px;
       padding: 5px 20px !important;
      height: 36px;
      cursor: not-allowed;
      opacity: 1 !important;
    }
    #detach-module-button.loading[disabled]{
      width: 127px;
       padding: 5px 20px !important;
      height: 36px;
      background-color: ${palette.primary[0]} !important;
      cursor: not-allowed;
      opacity: 1 !important;
    }
    #add-service.loading[disabled]{
      width: 116px;
      padding: 3px 20px;
      height: 36px;
      background-color: ${palette.primary[0]} !important;
      cursor: not-allowed;
      opacity: 1 !important;
    }
    #add-database.loading[disabled]{
      width: 128px;
      padding: 3px 20px;
      height: 34px;
      background-color: ${palette.primary[0]} !important;
      cursor: not-allowed;
      opacity: 1 !important;
    }
    #cancel-subscription.loading[disabled]{
      width: 172px;
      padding: 3px 20px;
      height: 34px;
      background-color: ${palette.error[0]};
      cursor: not-allowed;
    }
    #save-billing-address.loading[disabled]{
      width: 166px;
      padding: 3px 20px;
      height: 34px;
      background-color: ${palette.primary[0]};
      cursor: not-allowed;
    }
    #create-profile-button.loading[disabled]{
      width: 178px;
      padding: 3px 20px;
      height: 34px;
      background-color: ${palette.primary[0]};
      cursor: not-allowed;
    }
    #add-payment-button.loading[disabled]{
      width: 178px;
      padding: 3px 20px;
      height: 34px;
      background-color: ${palette.primary[0]};
      cursor: not-allowed;
    }
      .more-actions-dropdown.cluster-select-modal{
          border: 1px solid ${palette.border[0]};
          border-radius: 4px;
          width: 100%;
          padding: 9px 20px 9px 12px;
          position: relative;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: ${palette.fontSize[2]};
          line-height: 16px;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          #dark & {
            border: 1px solid ${palette.border[4]}; 
          }
          span{
            width: calc(100% - 22px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .icon{
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%) rotate(-180deg);
            transition: 0.5s all ease;
          }
          img{
            margin-right: 9px;
          }

          &[disabled] {
            background-color: ${palette.secondary[0]};
            cursor: not-allowed;
  
            #dark & {
              background-color: ${palette.grays[1]};
            }
          }
          
          &.ant-dropdown-open{
            .icon{
              transform: translateY(-50%) ;
            }
          }
          &.ant-dropdown-open{
            #dark & {
              border: 1px solid ${palette.border[0]};
            }
          }
        }
      .ant-modal-title {
        margin: 0;
        font-size: ${palette.fontSize[4]};
        line-height: 21px;
        font-weight: 500;
        color: ${palette.text[0]};
        font-family: ${palette.fonts[1]};
        letter-spacing: -0.02em;
        #dark & {
          color: ${palette.grays[11]};
        }
      }

      .ant-modal-close {
        right: ${isRtlDirection ? 'inherit' : '23px'};
        left: ${isRtlDirection ? '0' : 'inherit'};
        top: 29px;
        .ant-modal-close-x {
          width: 24px;	
          height: 24px;	
          line-height: 24px;
          align-items: center;
          .default{
            display: block;
          }
          .active{
            display: none;
          }
          &:hover{
            .default{
              display: none;
            }
            .active{
              display: block;
            }
          }
        }
      }

      .ant-modal-body {
        padding: 18px 32px 0 32px;
        font-size: 14px;
        color: ${palette.color[3]};
        line-height: 18px;
      }

      .ant-modal-footer {
        border: 0px;
        padding: 0 30px 30px 30px;
        text-align: right;
        ${borderRadius('0 0 4px 4px')};

        .ant-btn-lg {
          padding: 9px 20px;
          font-size: 14px;
          line-height: 20px;
          height: 40px;
        }

        button + button {
          margin-left: 12px !important;
          margin-top: 32px;
        }
      }

      .ant-confirm {
        .ant-modal-body {
          padding: 30px 35px;
        }
      }

      .ant-confirm-body {
        .ant-confirm-title {
          color: ${palette.text[0]};
          font-weight: 700;
          font-size: 15px;
        }

        .ant-confirm-content {
          margin-left: 42px;
          font-size: 13px;
          color: ${palette.text[3]};
          margin-top: 8px;
        }
      }
      #select-options{
        color: blue;
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
           .ant-select-item-option-state{
              #dark & {
                color: ${palette.grays[7]};
              }
           }
        }
        .ant-select-item-option-selected{
          background-color: ${palette.background[2]};
        }
        .ant-select-multiple { 
          .ant-select-selection-item{
            #dark & {
              background-color: ${palette.background[1]};
              border: 1px solid ${palette.border[6]};
            }
            
          }
          .ant-select-selection-item-remove > .anticon{
            #dark & {
              color: ${palette.grays[7]};
            }
          }
        }
      }
      #change-branch-button.loading{
        width: 138px;
        padding: 3px 20px;
        height: 34px;
        background-color: ${palette.primary[0]} !important;
        cursor: not-allowed;
        opacity: 1 !important;
      }
      .dialog-body {
        font-size: ${palette.fontSize[1]};
        color: ${palette.color[3]};
        line-height: 18px;
        #dark & {
            color: ${palette.grays[7]};
        }
      }
      p.warning {
        color: rgb(221, 98, 3);
      }
    `;
  }}
`;

StyledDialog.displayName = 'StyledDialog';
export default StyledDialog;
