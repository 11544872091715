import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isBlank } from 'utils';

const WithAppReloaded = (ChildComponent) => {
  if (isBlank(ChildComponent)) {
    throw new Error('No component provided to HOC');
  }

  class AppReloadedWrapper extends PureComponent {
    render() {
      const { isLoggedIn, userId } = this.props;
      const isAppReloaded = !userId && isLoggedIn;
      return isAppReloaded ? <ChildComponent {...this.props} /> : null;
    }
  }

  AppReloadedWrapper.defaultProps = {
    userId: null,
  };

  AppReloadedWrapper.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    userId: PropTypes.number,
  };

  const mapStateToProps = ({ userManager }) => ({
    isLoggedIn: userManager.isLoggedIn,
    userId: userManager.userId,
  });

  return connect(mapStateToProps)(AppReloadedWrapper);
};

export default WithAppReloaded;
