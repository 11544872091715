const httpIndexer = {
    count: 0,

    makePageVisible: false,

    increment() {
        httpIndexer.count += 1
    },

    decrement() {
        httpIndexer.count -= 1
    },

    tryToUnlock() {
        if (httpIndexer.count === 0) {
            httpIndexer.makePageVisible = true;
        }
    },

    delayTryToUnlock() {
        setTimeout(() => httpIndexer.tryToUnlock(), 10)
    },

    log() {
    },

    makeVisible() {
        httpIndexer.makeVisible = true
    }
}

export default httpIndexer