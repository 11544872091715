import type React from 'react';

import loaderBlue from 'assets/images/loaderBlue.svg';
import loaderWhite from 'assets/images/buttonLoaderWhite.svg';
import { store } from 'app/store';
import { isPlainObject } from '@reduxjs/toolkit';

export type ThemeMode = 'dark' | 'light';

export interface IProps {
    type?: ThemeMode | 'system' | 'selected';
}

function getSystemTheme(): ThemeMode {
    return window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light'
    ;
}
function getSelectedTheme(): ThemeMode {
    const { global } = store.getState();
    if (!isPlainObject(global)) {
        return getSystemTheme();
    }

    if (!('mode' in global)) {
        return getSystemTheme();
    }

    return global.mode === 'dark' ? 'dark' : 'light';
}

function getTheme(mode: NonNullable<IProps['type']>): ThemeMode {
    switch (mode) {
        case 'system':
            return getSystemTheme();
        case 'selected':
            return getSelectedTheme();
        default:
            return mode;
    }
}

export const DefaultProps = {
    type: 'selected',
} as const;

export const LoaderIcon: React.FunctionComponent<IProps> = ({
    type = DefaultProps.type,
}) => {
    return getTheme(type) === 'dark'
        ? <object type="image/svg+xml" width="60" height="60" data={loaderWhite}>svg-animation</object>
        : <object type="image/svg+xml" width="60" height="60" data={loaderBlue}>svg-animation</object>
    ;
};
LoaderIcon.defaultProps = DefaultProps;
