// eslint-disable-next-line no-underscore-dangle
const ENV = window._env_ || process.env;

const ROLE_TYPES = {
  user: 'ROLE_USER',
  admin: 'ROLE_ADMIN',
};

export * from './http';
export { default as API } from './api';
export default {
  ROLE_TYPES,
  userManager: {
    root: ENV.REACT_APP_APP_URL,
  },
  mixPanel: {
    token: ENV.REACT_APP_MIXPANEL_TOKEN,
    debug: ENV.REACT_APP_MIXPANEL_DEBUG,
  },
  gtm: {
    id: ENV.REACT_APP_GTM_ID,
  },
  intercom: {
    id: ENV.REACT_APP_INTERCOM_ID,
  },
};
