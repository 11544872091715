import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Button from 'theme/uielements/button';
import { noop, isBlank } from 'utils';
import AclLoader from 'components/loader/AclLoader';
import Tooltip from 'theme/uielements/tooltip';
import Frequency from './frequency';
import HourRange from './hourRange';
import StyledTimeframe from './Timeframes.style';
import SVG from '../svg';

const Timeframes = ({
  showSearch,
  timezone,
  timeframes,
  errors,
  onAdd,
  onChange,
  onRemove,
  aclLoading,
  aclManageDenied,
}) => {
  return (
    <StyledTimeframe>
      {!isBlank(timeframes) && (
        <>
          <div className="mb-3 mt-1 timeframe-list">
            <div className="mb-2 info">
              <SVG name="bicon-alert-triangle" className="mr-05" noHover defaultColor="#FFB300" />
              <span>{`Hours are set in your organization’s timezone, ${timezone}.`}</span>
            </div>
            {R.map(
              ({ id, timeframe, startHour, endHour, isReadOnly, disableStart }) => (
                <div key={id} className="timeframe-item">
                  <div className="timeframe-item-inputs">
                    <Frequency
                      isReadOnly={isReadOnly || aclManageDenied || aclLoading}
                      timeframe={timeframe}
                      timeframes={timeframes}
                      showSearch={showSearch}
                      onChange={(value) =>
                        onChange({
                          id,
                          timeframe: parseInt(value, 10),
                          startHour,
                          endHour,
                        })
                      }
                    />
                    <HourRange
                      isReadOnly={isReadOnly || aclManageDenied || aclLoading}
                      disableStart={disableStart}
                      timeframe={timeframe}
                      timeframes={timeframes}
                      startHour={startHour}
                      endHour={endHour}
                      showSearch={showSearch}
                      onChange={(values) => onChange({ id, timeframe, ...values })}
                    />
                    <Tooltip
                      placement="top"
                      title={aclManageDenied ? 'Denied' : ''}
                      mouseEnterDelay={0.4}
                      mouseLeaveDelay={0.3}
                    >
                      <span>
                        <Button
                          className=" remove-timeframe-btn"
                          type="secondary"
                          data-value="remove-timeframe-button"
                          onClick={() => onRemove(id)}
                          disabled={aclManageDenied || aclLoading}
                        >
                          <SVG name="bicon-minus" width="20" height="20" defaultColor="#1F2ECF" />
                        </Button>
                      </span>
                    </Tooltip>
                  </div>
                  {errors[id] && <div className="timeframe-item-error">Please insert hour range.</div>}
                </div>
              ),
              timeframes
            )}
          </div>
        </>
      )}

      <Tooltip placement="top" title={aclManageDenied ? 'Denied' : ''} mouseEnterDelay={0.4} mouseLeaveDelay={0.3}>
        <span>
          <Button
            type="primary"
            className="d-inline-flex"
            data-value="add-timeframe-link"
            onClick={onAdd}
            disabled={aclManageDenied || aclLoading}
          >
            {aclLoading ? (
              <AclLoader className="mr-1" />
            ) : (
              <SVG name="bicon-plus" className="mr-1" width={20} height={20} noHover defaultColor="#fff" />
            )}
            Add timeframe
          </Button>
        </span>
      </Tooltip>
    </StyledTimeframe>
  );
};

Timeframes.defaultProps = {
  showSearch: false,
  timeframes: [],
  timezone: '',
  errors: {},
  onAdd: noop,
  onChange: noop,
  onRemove: noop,
};

Timeframes.propTypes = {
  aclLoading: PropTypes.bool.isRequired,
  aclManageDenied: PropTypes.bool.isRequired,
  showSearch: PropTypes.bool,
  timezone: PropTypes.string,
  timeframes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      timeframe: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      startHour: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
      endHour: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
      disableStart: PropTypes.bool,
    })
  ),
  errors: PropTypes.shape({
    id: PropTypes.bool,
  }),
  onAdd: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

export default Timeframes;
