// eslint-disable-next-line no-underscore-dangle
const ENV = window._env_ || process.env;

export const NAKED_BASE_URL = ENV.REACT_APP_BASE_API;
export const BASE_URL = `${NAKED_BASE_URL}/api`;

const token = `${NAKED_BASE_URL}/token`;

const users = {
  base: `${BASE_URL}/users`,
  resetPassword: `${NAKED_BASE_URL}/forgot_password/`,
  token: `${NAKED_BASE_URL}/token`,
  preferences: (userId) => `${BASE_URL}/users/${userId}/user_preferences`,
  billingPlans: `${BASE_URL}/billing/products`,
  createAccount: `${BASE_URL}/register-user-pass`,
  createBillingProfile: `${BASE_URL}/billing/profile-create`,
  confirmEmail: `${BASE_URL}/register-confirm-email`,
  resendEmail: `${BASE_URL}/register-confirm-email-resend`,
  profileCreate: `${BASE_URL}/billing/profile-create`,
  setupIntent: `${BASE_URL}/billing/setup-intent`,
  registerInvite: `${BASE_URL}/register-invited`,
  gitSsoConnect: `${BASE_URL}/sso-connect`,
  gitSsoResponse: `${BASE_URL}/sso-response`,
};

const userPreferences = {
  base: `${BASE_URL}/user_preferences`,
};

const organizations = {
  base: `${BASE_URL}/organizations`,
  getOwner: (organizationId) => `${BASE_URL}/organizations/${organizationId}`,
  timezones: `${BASE_URL}/timezones`,
  deactivate: (organizationId) => `${BASE_URL}/organizations/${organizationId}/deactivate-member`,
  revokeUser: (id) => `${BASE_URL}/rbac/organization_members/${id}/revoke`,
  resendInvitation: (id) => `${BASE_URL}/rbac/organization_members/${id}/resend`,
  acceptInvitation: (orgUserId) => `${BASE_URL}/organization_users/${orgUserId}/accept`,
  registerInvite: `${BASE_URL}/register-invited`,
  gitIntegrations: (organizationId) => `${BASE_URL}/organizations/${organizationId}/git_integrations`,
  getSlackIntegrations: (organizationId) => `${BASE_URL}/organizations/${organizationId}/slack_integrations`,
  getSlackChannels: (slackId) => `${BASE_URL}/slack_integrations/${slackId}/channels`,
  slackBase: (slackId) => `${BASE_URL}/slack_integrations/${slackId}`,
  slackResponse: `${BASE_URL}/slack-response`,
  connectSlack: `${BASE_URL}/slack-connect`,
  kubernetesIntegrations: (organizationId) => `${BASE_URL}/organizations/${organizationId}/kubernetes_integrations`,
  users: (organizationId) => `${BASE_URL}/organizations/${organizationId}/organization_users`,
  projects: `${BASE_URL}/projects`,
  projectVariable: (projectId) => `${BASE_URL}/projects/${projectId}/project_variables`,
  timeframes: (projectId) => `${BASE_URL}/projects/${projectId}/timeframes`,
  getAccessToken: (id) => `${BASE_URL}/organization_users/${id}/access_token`,
  generateAccessToken: (id) => `${BASE_URL}/organization_users/${id}/access_token/create`,
  revokeAccessToken: (id) => `${BASE_URL}/organization_users/${id}/access_token/revoke`,
  environmentsCosts: (organizationId) => `${BASE_URL}/organizations/${organizationId}/cost-tracking/summary`,
  inviteMember: (organizationId) => `${BASE_URL}/organizations/${organizationId}/invite-member`,
  members: `${BASE_URL}/rbac/organization_members`,
};

const projects = {
  base: `${BASE_URL}/projects`,
  manageProjectVariable: `${BASE_URL}/project_variables`,
  projectVariables: (projectId) => `${BASE_URL}/projects/${projectId}/project_variables`,
  environments: `${BASE_URL}/environments`,
  cycleTime: (projectId) => `${BASE_URL}/projects/${projectId}/metrics_cycle_time`,
  deploymentFrequency: (projectId) => `${BASE_URL}/projects/${projectId}/metrics_deployment_frequency`,
  // timeframes: (projectId) => `${BASE_URL}/projects/${projectId}/timeframes`,
  saveBuildSettings: (projectId) => `${BASE_URL}/projects/${projectId}/build-settings`,
  saveMetricsSettings: (projectId) => `${BASE_URL}/projects/${projectId}/release-settings`,
  metricsHistoryRecompute: (projectId) => `${BASE_URL}/projects/${projectId}/git_history_recompute`,
  inviteMember: (projectId) => `${BASE_URL}/projects/${projectId}/invite-member`,
};

const projectTimeframes = {
  base: `${BASE_URL}/project_timeframes`,
};

const cloudProvider = {
  base: `${BASE_URL}/cloud_providers`,
};

const gitIntegrations = {
  base: `${BASE_URL}/git_integrations`,
  baseRepository: `${BASE_URL}/git_repositories`,
  connect: `${BASE_URL}/git-connect`,
  connectPrivate: `${BASE_URL}/git_providers`,
  editPrivate: (gitId) => `${BASE_URL}/git_providers/${gitId}`,
  response: `${BASE_URL}/git-response`,
  githubResponseMarketplace: `${BASE_URL}/github-response-marketplace`,
  remove: (gitId) => `${BASE_URL}/git_integrations/${gitId}`,
  getByInstallationId: (installationId) => `${BASE_URL}/git_integrations/github-app/${installationId}`,
  getRepositories: (gitId) => `${BASE_URL}/git_integrations/${gitId}/git_repositories`,
  syncRepos: (gitId) => `${BASE_URL}/git_integrations/${gitId}/sync`,
};

const kubernetesIntegration = {
  base: `${BASE_URL}/kubernetes_integrations`,
  detach: (kubernetesId) => `${BASE_URL}/kubernetes_integrations/${kubernetesId}/detach`,
  verify: (kubernetesId) => `${BASE_URL}/kubernetes_integrations/${kubernetesId}/verify`,
  organizations: (kubernetesId) => `${BASE_URL}/organizations/${kubernetesId}/kubernetes_integrations`,
  integration: (kubernetesId) => `${BASE_URL}/kubernetes_integrations/${kubernetesId}`,
  workshop: `${BASE_URL}/kubernetes_integrations/workshop`,
};

const registryIntegrations = {
  base: `${BASE_URL}/registry_integrations`,
  detach: (registryID) => `${BASE_URL}/registry_integrations/${registryID}/detach`,
  verify: (registryID) => `${BASE_URL}/registry_integrations/${registryID}/verify`,
  organizations: (organizationID) => `${BASE_URL}/organizations/${organizationID}/registry_integrations`,
  default: (registryID) => `${BASE_URL}/organizations/${registryID}/build-settings`,
  edit: (registryID) => `${BASE_URL}/registry_integrations/${registryID}`,
};

const teamsIntegrations = {
  base: `${BASE_URL}/teams_integrations`,
  remove: (teamsIntegrationID) => `${BASE_URL}/teams_integrations/${teamsIntegrationID}`,
  organizations: (organizationID) => `${BASE_URL}/organizations/${organizationID}/teams_integrations`,
  edit: (teamsIntegrationID) => `${BASE_URL}/teams_integrations/${teamsIntegrationID}`,
};

const environments = {
  baseUrl: BASE_URL,
  nakedBase: NAKED_BASE_URL,
  base: `${BASE_URL}/environments`,
  clone: (environmentId) => `${BASE_URL}/environments/${environmentId}/clone`,
  deploy: (environmentId) => `${BASE_URL}/environments/${environmentId}/deploy`,
  start: (environmentId) => `${BASE_URL}/environment/${environmentId}/start`,
  stop: (environmentId) => `${BASE_URL}/environment/${environmentId}/stop`,
  components: (environmentId) => `${BASE_URL}/environments/${environmentId}/components`,
  draftComponents: (environmentId) => `${BASE_URL}/environments/${environmentId}/draft/components`,
  enrichComponents: (environmentId) => `${BASE_URL}/environments/${environmentId}/components/enrich`,
  enrichDraftComponents: (environmentId) => `${BASE_URL}/environments/${environmentId}/draft/components/enrich`,
  manifests: (environmentId) => `${BASE_URL}/environments/${environmentId}/download-manifests`,
  draftManifests: (environmentId) => `${BASE_URL}/environments/${environmentId}/draft/download-manifests`,
  addComponent: (environmentId) => `${BASE_URL}/environments/${environmentId}/draft/components/add`,
  deleteComponent: (environmentId) => `${BASE_URL}/environments/${environmentId}/draft/components/delete`,

  timeframes: (environmentId) => `${BASE_URL}/environments/${environmentId}/timeframes`,
  events: (organizationId) => `${BASE_URL}/organizations/${organizationId}/events`,
  pipelines: (organizationId) => `${BASE_URL}/organizations/${organizationId}/workflows`,
  pipelines_users: (organizationId) => `${BASE_URL}/organizations/${organizationId}/workflows_users`,
  sources: (organizationId) => `${BASE_URL}/organizations/${organizationId}/events_users`,

  event: (eventId) => `${BASE_URL}/events/${eventId}`,
  logs: (eventId) => `${BASE_URL}/events/${eventId}/logs`,
  logUrl: (eventId, logId) => `${BASE_URL}/events/${eventId}/logs_entries/${logId}/download_external`,
  convertToPrimary: (environmentId) => `${BASE_URL}/environments/${environmentId}/convert/ephemeral/primary`,
  modulesUsesBase: (moduleId) => `${BASE_URL}/terraform_module_uses/${moduleId}`,
  saveBuildSettings: (environmentId) => `${BASE_URL}/environments/${environmentId}/build-settings`,
  pipeline: (id) => `${BASE_URL}/workflows/${id}`,
  pipelineJob: (id) => `${BASE_URL}/workflow_jobs/${id}`,
  workflowJobStreamHash: (id) => `${NAKED_BASE_URL}/streamer/workflow/job/${id}`,
  compPipeline: (id) => `${BASE_URL}/environments/${id}/component-workflow-logs`,
  terminateWorkflow: (id) => `${BASE_URL}/workflows/${id}/terminate`,
  takeover: (id) => `${BASE_URL}/environments/${id}/draft/takeover`,
  componentDefinition: (envId, componentName) =>
    `${BASE_URL}/environments/${envId}/components/${componentName}/definition`,
  draftComponentDefinition: (environmentId, componentName) =>
    `${BASE_URL}/environments/${environmentId}/draft/components/${componentName}/definition`,
  k8sResDefinition: (envId) => `${BASE_URL}/environments/${envId}/components/resources/manifest`,
};

const environmentTimeframes = {
  base: `${BASE_URL}/environment_timeframes`,
};

const billing = {
  // start of v2
  currentUsage: (organizationId) => `${BASE_URL}/organizations/${organizationId}/usage/current`,
  currentSubscriptions: (organizationId) => `${BASE_URL}/organizations/${organizationId}/subscriptions/current`,
  subscriptions: (organizationId) => `${BASE_URL}/organizations/${organizationId}/subscriptions`,
  editSubscriptions: (organizationId, subscriptionId) =>
    `${BASE_URL}/organizations/${organizationId}/subscriptions/${subscriptionId}`,
  changeSubscriptionPlanType: (organizationId) => `${BASE_URL}/organizations/${organizationId}/subscriptions/change`,
  prepaidUsages: (organizationId) => `${BASE_URL}/organizations/${organizationId}/prepaidusages`,
  prepaidUsagesCurrent: (organizationId) => `${BASE_URL}/organizations/${organizationId}/prepaidusages/current`,
  prepaidUsage: (organizationId, prepaidUsageId) => `${BASE_URL}/organizations/${organizationId}/prepaidusages/${prepaidUsageId}`,
  vouchers: (organizationId) => `${BASE_URL}/organizations/${organizationId}/vouchers`,
  invoices: (organizationId) => `${BASE_URL}/organizations/${organizationId}/invoices`,
  invoice: (organizationId, invoiceId) => `${BASE_URL}/organizations/${organizationId}/invoices/${invoiceId}`,

  billingProfile: (organizationId) => `${BASE_URL}/organizations/${organizationId}/billing/profile`,
  billingPaymentMethods: (organizationId) => `${BASE_URL}/organizations/${organizationId}/billing/cards`,
  billingSetupIntent: (organizationId) => `${BASE_URL}/organizations/${organizationId}/billing/setup-intent`,
  billingCardAttach: (organizationId) => `${BASE_URL}/organizations/${organizationId}/billing/card-attach`,
  billingCardDetach: (organizationId) => `${BASE_URL}/organizations/${organizationId}/billing/card-detach`,
  billingCardMakeDefault: (organizationId) => `${BASE_URL}/organizations/${organizationId}/billing/card-default`,
  // end of v2

  changeSubscription: (organizationId) => `${BASE_URL}/billing/${organizationId}/subscription-update`,
  activateSubscription: (organizationId) => `${BASE_URL}/billing/${organizationId}/subscription-reactivate`,
  cancelSubscription: (organizationId) => `${BASE_URL}/billing/${organizationId}/subscription-cancel-now`,
  addCard: (organizationId) => `${BASE_URL}/billing/${organizationId}/card-add`,
  editCard: (organizationId) => `${BASE_URL}/billing/${organizationId}/card-update`,
  makeCardPrimary: (organizationId) => `${BASE_URL}/billing/${organizationId}/card-make-primary`,
  removeCard: (organizationId) => `${BASE_URL}/billing/${organizationId}/card-remove`,
  editBilling: (organizationId) => `${BASE_URL}/billing/${organizationId}/address-update`,
  billingData: (organizationId) => `${BASE_URL}/billing/${organizationId}/data`,

  payInvoice: (organizationId) => `${BASE_URL}/billing/${organizationId}/pay-invoice`,
};

const variables = {
  base: `${BASE_URL}/environment_variables`,
};

const applications = {
  showComponents: (environmentId) => `${BASE_URL}/environments/${environmentId}/draft/components/add-env-yaml`,
  addDocker: (environmentId) => `${BASE_URL}/environments/${environmentId}/draft/components/add-docker-compose`,
  parseDocker: (environmentId) => `${BASE_URL}/environments/${environmentId}/draft/components/parse-docker-compose`,
  saveComponent: (environmentId, componentName) =>
    `${BASE_URL}/environments/${environmentId}/draft/components/${componentName}`,
  validateComponents: (environmentId) => `${BASE_URL}/environments/${environmentId}/draft/components/validate-env-yaml`,
  addComponents: (environmentId) => `${BASE_URL}/environments/${environmentId}/draft/components/add-env-yaml`,
  createApp: (environmentId) => `${BASE_URL}/environments/${environmentId}/draft/components`,
  deleteComponent: (environmentId) => `${BASE_URL}/environments/${environmentId}/draft/components/delete`,
  publish: (environmentId) => `${BASE_URL}/environments/${environmentId}/draft/publish`,
};

const bridges = {
  pipelineBridge: (id) => `${BASE_URL}/bridge/workflow/${id}`,
  environmentBridge: (id) => `${BASE_URL}/bridge/environment/${id}`,
};

const templates = {
  templates: `${BASE_URL}/templates`,
  publicTemplates: `${BASE_URL}/templates/public`,
  templatesOrganization: (organizationId) => `${BASE_URL}/organizations/${organizationId}/templates`,

  templateDetails: (id) => `${BASE_URL}/templates/${id}`,
  templateDefinition: (id) => `${BASE_URL}/templates/${id}/definition`,

  templatesRepositories: `${BASE_URL}/templates_repositories`,
  templatesRepositoriesOrg: (organizationId) => `${BASE_URL}/organizations/${organizationId}/templates_repositories`,

  repository: (id) => `${BASE_URL}/templates_repositories/${id}`,
  repositorySync: (id) => `${BASE_URL}/templates_repositories/${id}/sync`,
  repositoryTemplates: (id) => `${BASE_URL}/templates_repositories/${id}/templates`,
};

const clusters = {
  addIngressAddon: `${BASE_URL}/ingress_controller_addons`,
  addCostTrackingAddon: `${BASE_URL}/cost_tracking_addons`,
  addKubernetesDashboardAddon: `${BASE_URL}/kubernetes_dashboard_addons`,
  addBunnyshellVolumesAddon: `${BASE_URL}/bunnyshell_volumes_addons`,
  applyConfiguration: (clusterId) => `${BASE_URL}/kubernetes_integrations/${clusterId}/apply-configuration`,
  addonsList: (clusterId) => `${BASE_URL}/kubernetes_integrations/${clusterId}/addons`,
  costTrackingAddon: (id) => `${BASE_URL}/cost_tracking_addons/${id}`,
  kubernetesDashboardAddon: (id) => `${BASE_URL}/kubernetes_dashboard_addons/${id}`,
  bunnyshellVolumesAddon: (id) => `${BASE_URL}/bunnyshell_volumes_addons/${id}`,
  ingressAddon: (id) => `${BASE_URL}/ingress_controller_addons/${id}`,
  pipeline: (id) => `${BASE_URL}/workflows/${id}`,
};

const cloudCosts = {
  getReports: (id) => `${BASE_URL}/organizations/${id}/cost-tracking/report`,
};

const deploys = {
  getReports: (id) => `${BASE_URL}/organizations/${id}/deployments/report`,
};

const security = {
  policies: `${BASE_URL}/rbac/policies`,
  permissions: `${BASE_URL}/rbac/permissions`,
  resourceMatchers: `${BASE_URL}/rbac/resource_matchers`,
  teams: `${BASE_URL}/rbac/teams`,
  teamMembers: `${BASE_URL}/rbac/team_members`,
  organizationMembers: `${BASE_URL}/rbac/organization_members`,
  environments: `${BASE_URL}/environments/search`,
  projects: `${BASE_URL}/projects/search`,
};

export default {
  BASE_URL,
  token,
  users,
  userPreferences,
  organizations,
  projects,
  projectTimeframes,
  cloudProvider,
  gitIntegrations,
  registryIntegrations,
  teamsIntegrations,
  applications,
  kubernetesIntegration,
  environments,
  environmentTimeframes,
  variables,
  billing,
  bridges,
  templates,
  clusters,
  cloudCosts,
  deploys,
  security,
};
