import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import mixpanel from 'mixpanel-browser'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import {MixpanelProvider} from 'react-mixpanel-browser';
import TagManager from 'react-gtm-module'
import { configureRefreshInterceptor } from 'config/http';
import userManager from "modules/userManager";
import appHistory from 'config/appHistory';
import { store } from 'app/store';
import * as configs from 'config';
import { logOut } from 'modules/userManager/actions';
import ThemeProvider from 'modules/themeManager/components';
import { IntercomBootedProvider } from 'modules/thirdparty/intercom/IntercomBoot';
import {handleCriticalErrorState} from 'actions/global';
import App from './App';

// eslint-disable-next-line no-underscore-dangle
const ENV = window._env_ || process.env;

const history = appHistory.init();

// dist: 1 - You will also need to pass a distribution to dist for events to be attributed correctly to a release. Usually this value is the build number, for example 52. This value needs to be unique to only each release.
Sentry.init({
  dsn: ENV.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
  })],
  tracesSampleRate: parseFloat(ENV.REACT_APP_SENTRY_TRACE_SAMPLE_RATE),
  release: ENV.REACT_APP_SENTRY_RELEASE,
  dist: '1',
});

const tagManagerArgs = {
  gtmId: configs.default.gtm.id
}
TagManager.initialize(tagManagerArgs)
window.addEventListener('storage', e => {
  if(e.key === 'accessToken' && e.oldValue && !e.newValue) {
    userManager.logout(logOut)
    store.dispatch(logOut());
  }
});

/**
 * changes criticalError state from globalReducer 
 * @param {Object} value
 */
const handleErrorScreen = (value)=>{ store.dispatch(handleCriticalErrorState(value)); };

/** pass toggleErrorScreen to interceptor */
configureRefreshInterceptor(() => {store.dispatch(logOut()); mixpanel.reset()}, handleErrorScreen);

ReactDOM.render(
  <MixpanelProvider token={configs.default.mixPanel.token}>
    <Provider store={store}>
      <IntercomBootedProvider appId={configs.default.intercom.id}>
        <ThemeProvider>
          <Router history={history}>
            <IntlProvider locale="en">
              <App toggleErrorScreen={handleErrorScreen} />
            </IntlProvider>
          </Router>
        </ThemeProvider>
      </IntercomBootedProvider>
    </Provider>
  </MixpanelProvider>,
  document.getElementById('root')
);
