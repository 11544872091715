import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hideDialog } from 'actions/global';
import { Dialog } from 'components';
import { noop } from 'utils';

const GlobalDialog = ({ dialogData, actions }) => {
  return (
    <>
      {dialogData.visible ? (
        <Dialog
          header={dialogData.header}
          className="dialog-container"
          onClose={() => {
            if (dialogData.options.closable === false) {
              return;
            }

            R.compose(actions.hideDialog, dialogData.onClose || noop);
          }}
          onCancel={() => {
            if (dialogData.options.closable === false) {
              return;
            }
            actions.hideDialog();
          }}
          actions={dialogData.actions}
          size={dialogData.options.size}
          {...dialogData.options}
        >
          {dialogData.body}
        </Dialog>
      ) : null}
    </>
  );
};

GlobalDialog.defaultProps = {};

GlobalDialog.propTypes = {
  dialogData: PropTypes.shape({
    visible: PropTypes.bool,
    header: PropTypes.node,
    body: PropTypes.node,
    options: PropTypes.shape({
      size: PropTypes.string,
      closable: PropTypes.bool,
    }),
    onClose: PropTypes.func,
    actions: PropTypes.node,
  }).isRequired,
  actions: PropTypes.shape({
    hideDialog: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ global }) => ({
  dialogData: global.dialog,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      hideDialog,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalDialog);
