import styled, { css } from 'styled-components';

const StyledBackButton = styled.div`
  ${({ theme }) => {
    const { palette } = theme;
    return css`
      .back-button {
        height: auto;
        padding: 0;
        border: none;
        .copy{
          color: ${palette.color[4]};
        }
        .breadcrumb-divider {
          color: ${palette.color[4]};
          font-size: 12px;
          font-weight: 300;
          display: inline-flex;
          align-items: center;
        }
        &:hover {
          color: ${palette.primary[0]};
          #dark & {
            color: ${palette.grays[11]};
          }
          .copy{
            color: ${palette.primary[0]};
            #dark & {
              color: ${palette.grays[11]};
            }
          }
          .breadcrumb-divider {
            color: ${palette.color[4]};
            font-size: 14px;
            font-weight: 300;
            line-height: 14px;
            display: inline-flex;
            align-items: center;
          }
        }

        .breadcrumb-divider {
          margin-left: 6px;
          margin-right: 6px;
          font-size: 14px;
          line-height: 14px;
          font-weight: 400;
          svg{
            width: 8px;
            height: 8px;
            transform: rotate(-90deg);
          }
        }
      }
    `;
  }}
`;

StyledBackButton.displayName = 'StyledBackButton';
export default StyledBackButton;
