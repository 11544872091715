import styled, { css } from 'styled-components';

const StyledSVG = styled.div`
  ${({ theme, width, height, defaultColor, forcedBgColor, hoverColor, noHover, noStroke }) => {
    const { palette } = theme;
    return css`
      display: flex;
      align-items: center;

      .icon {
        width: ${width ? `${width}px` : '16px'};
        height: ${height ? `${height}px` : '16px'};
        use {
          ${noStroke ? 'stroke: none;' : `stroke: ${forcedBgColor || defaultColor || palette.text[1]};`}
        }
        ${!noHover &&
        `&:hover {
            use {
              ${noStroke ? 'stroke: none; !important;' : `stroke: ${forcedBgColor || hoverColor || palette.primary[0]};`}
            }
            #dark & {
              use {
                stroke: ${forcedBgColor || palette.grays[11]};
              }
            }
          }`}
      }

      #dark & {
        use {
          stroke: ${forcedBgColor || palette.grays[6]};
        }
      }
    `;
  }}
`;

StyledSVG.displayName = 'StyledSVG';
export default StyledSVG;
