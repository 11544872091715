/* eslint-disable react-hooks/exhaustive-deps */
/* eslint no-underscore-dangle: 0 */
import React, { memo, useState, useEffect } from 'react';
import { ErrorBoundary, Header, Layout, SpriteSVG } from 'components';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import NotificationsContainer from 'components/notificationsContainer/NotificationsContainer';
import PropTypes from 'prop-types';
import GlobalDialog from 'modules/globalDialog/components';
import EventSourceProvider from 'modules/messageService';
import NotificationMessageConsumer from 'modules/messageService/components/notificationMessageConsumer';
import Sidebar from 'modules/sidebar';
import {showNotification, handleHasUnSavedChanges, handleUiTheme, handleToggleMode, hideDialog, showDialog} from "actions/global";
import AccountDetailsLoader from 'modules/userManager/components/accountDetailsLoader';
import AppRoutes from 'routes';
import Button from 'theme/uielements/button';
import {bindActionCreators} from "redux";

// 10s timeout
let MAX_WOOPRA_TRIES = 100;

const woopraWaitAndTrack = (email) => {
  const { woopra } = window;

  if (MAX_WOOPRA_TRIES <= 0) {
    return;
  }

  // try again if woopra not loaded yet
  if (!woopra) {
    setTimeout(woopraWaitAndTrack, 100);
    MAX_WOOPRA_TRIES -= 1;
    return;
  }

  woopra.config({
    domain: 'environments.bunnyshell.com',
    outgoing_tracking: true,
    download_tracking: true,
    click_tracking: true,
  });

  woopra.identify({ email, name: email });

  // tracks only the first page view;
  // subsequent page views are tracked by history.listen
  woopra.track();
};

const Hubspot = require('hubspot');

const ENV = window._env_ || process.env;
// eslint-disable-next-line no-unused-vars
const hubspot = new Hubspot({ accessToken: ENV.REACT_APP_HUBSPOT_TOKEN });

const initUsetifulTags = ({ intercomExternalId:userId, firstName }) => {
  window.usetifulTags = {
    userId,
    firstName,
  };
  ((w, d, s) => {
    const a = d.getElementsByTagName('head')[0];
    const r = d.createElement('script');
    r.async = 1;
    r.src = s;
    r.setAttribute('id', 'usetifulScript');
    r.dataset.token = 'c20b94958926da88f98dff0a62780175';
    a.appendChild(r);
  })(window, document, 'https://www.usetiful.com/dist/usetiful.js');
};

const App = ({actions, toggleErrorScreen, hasUnsavedChanges, mode, uiTheme, intercomExternalId, firstName, email}) => {
  const history = useHistory();
  const [ locationKeys, setLocationKeys ] = useState([]);

  useEffect(() => {
    if (intercomExternalId) {
      // usetiful
      initUsetifulTags({intercomExternalId, firstName});
    }
  }, [intercomExternalId]);

  useEffect(() => {
    if (email) {
      // woopra
      woopraWaitAndTrack(email);
    }
  }, [email]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const unblock = history.block((location, action) => {
      if (hasUnsavedChanges) {
        actions.showDialog({
          options: {size: "small", className: 'handleLeaveWithoutSaving'},
          header: (<>
          <svg className="dialog-icon" width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="4" width="48" height="48" rx="24" fill={`${mode === 'dark' ? '#222222' : '#FEF0C7' }`} />
            <path
              d="M27.9988 24V28M27.9988 32H28.0088M26.2888 18.86L17.8188 33C17.6442 33.3024 17.5518 33.6453 17.5508 33.9945C17.5498 34.3437 17.6403 34.6871 17.8132 34.9905C17.9862 35.2939 18.2355 35.5467 18.5365 35.7238C18.8375 35.9009 19.1796 35.9961 19.5288 36H36.4688C36.818 35.9961 37.1601 35.9009 37.4611 35.7238C37.7621 35.5467 38.0114 35.2939 38.1844 34.9905C38.3573 34.6871 38.4478 34.3437 38.4468 33.9945C38.4458 33.6453 38.3534 33.3024 38.1788 33L29.7088 18.86C29.5305 18.5661 29.2795 18.3231 28.98 18.1544C28.6805 17.9858 28.3425 17.8972 27.9988 17.8972C27.6551 17.8972 27.3171 17.9858 27.0176 18.1544C26.7181 18.3231 26.4671 18.5661 26.2888 18.86Z"
              stroke="#FEB811" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            />
            <rect x="4" y="4" width="48" height="48" rx="24" stroke={`${mode === 'dark' ? '#FEB811' : '#FFFAEB' }`} strokeWidth={`${mode === 'dark' ? '2px' : '8px'}`} />
          </svg>
          <br />
            Leave without saving?
          </>),
          body: ('Are you sure you want to leave this page without saving it? The changes you made  will be lost.'),
          actions: (
            <div className="d-flex align-items-center justify-content-end">
              <Button type="info" className="mt-4" onClick={actions.hideDialog}>Cancel</Button>
              <Button
                type="primary"
                data-url={location.pathname}
                className='ml-2'
                onClick={() => {
                  actions.handleHasUnSavedChanges(false);
                  unblock();
                  actions.hideDialog();
                  history.push(`${location.pathname}${location.search ? location.search : ''}`); 
                  }}
              >
                Confirm
              </Button>
            </div>
          )
        })
        return  false
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [hasUnsavedChanges, history, mode]);

  useEffect(() => {
    const mediaTheme = window.matchMedia('(prefers-color-scheme: dark)');

    if(uiTheme === 'auto'){
      // eslint-disable-next-line func-names
      mediaTheme.addEventListener('change', function(event){
        const colorScheme = event.matches ? 'dark' : 'light';
        actions.handleToggleMode(colorScheme);
      })
      
      if(mediaTheme.matches)
        actions.handleToggleMode('dark')
      else
        actions.handleToggleMode('light')
    } else {
      actions.handleToggleMode(localStorage.getItem('themeMode') || mode)
    }

  }, [mode])

  useEffect(() => {
    actions.handleUiTheme(uiTheme);
  }, [uiTheme])

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'PUSH') {
        setLocationKeys([ location.key ])
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          // eslint-disable-next-line no-unused-vars
          setLocationKeys(([ _, ...keys ]) => keys)
          actions.hideDialog()
        } else {
          setLocationKeys((keys) => [ location.key, ...keys ])
          actions.hideDialog()
        }
      }
    })
  }, [actions, history, locationKeys])

  useEffect(() => {
    // send woopra page view on route change
    history.listen(() => {
      const woopraConfigured = window.woopra?.visitorData?.email;
      if (history.action === 'PUSH' && woopraConfigured) {
        window.woopra.track();
      }
    });
  }, []);

  return (
    <ErrorBoundary>
      <AccountDetailsLoader />
      <GlobalDialog />
      <SpriteSVG />
      <NotificationsContainer showNotification={actions.showNotification} />

      <EventSourceProvider>
        <NotificationMessageConsumer />
      </EventSourceProvider>

      <Layout toolbar={<Header />} sidebar={<Sidebar />}>
        <ErrorBoundary>
          <AppRoutes toggleErrorScreen={toggleErrorScreen} />
        </ErrorBoundary>
      </Layout>
    </ErrorBoundary>
  )

};

App.defaultProps = {
  email: '',
  firstName: null,
  intercomExternalId: '',
};

App.propTypes = {
  email: PropTypes.string,
  uiTheme: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  intercomExternalId: PropTypes.string,
  firstName: PropTypes.string,
  toggleErrorScreen: PropTypes.func.isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    hideDialog: PropTypes.func,
    showDialog: PropTypes.func,
    handleToggleMode: PropTypes.func,
    handleUiTheme: PropTypes.func,
    handleHasUnSavedChanges: PropTypes.func,

    showNotification: PropTypes.func,
  }).isRequired,
};
const mapStateToProps = ({ global, userManager }) => {
  const { hasUnsavedChanges, mode, uiTheme } = global;
  const { intercomExternalId, firstName, email } = userManager;

  return {
    hasUnsavedChanges,
    mode,
    uiTheme,
    intercomExternalId,
    firstName,
    email,
  }
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      hideDialog,
      showDialog,
      handleHasUnSavedChanges,
      showNotification,
      handleToggleMode,
      handleUiTheme,
    },
    dispatch
  ),
});
export default memo(connect(mapStateToProps, mapDispatchToProps)(App));
