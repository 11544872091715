/* messages for error critical error screen */
export const genericError = {
  title: '500 - Server Error',
  description: 'We’ve encountered an error while trying to connect to our servers.',
  useOnlyReloadButton: true,
};

export const notFoundError400 = {
  title: '400 - Not Found',
  description: "The resource you are looking for doesn't exist.",
};
export const unauthorizedError = {
  title: '401 - Unauthorized',
  description: 'Your authorization failed. Please fill in the correct login details.',
};
export const deniedError = {
  title: '403 - Access Denied',
  description: 'The resource server rejected the request.',
};

export const notFoundError = {
  title: '404 - Not Found',
  description: "The resource you are looking for doesn't exist or you don't have access to it.",
  useOnlyReloadButton: true,
};
export const networkError = {
  title: 'Connection Error',
  description: 'We’ve encountered an error while trying to connect to our servers.',
  useOnlyReloadButton: true,
};

export const ERROR_CODES = {
  NETWORK_ERROR_MESSAGE: 'Connection Error',
  ACCESS_DENIED: 'Access Denied.',
  UNAUTHORIZED_ACCESS: 'The resource server rejected the request.',
};

export const noOrganizationError = {
  title: '',
  description: '',
  showEmptyPage: true,
};

export const NETWORK_ERROR_CODE = 'network_error';
