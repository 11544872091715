import styled, { css } from 'styled-components';

const StyledLoaderDialog = styled.div`
  ${({theme}) => {
    const { palette } = theme;
  return css`
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0, 0.4);
    top: 0;
    left: 0;
    z-index: 9999; 
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    @media (max-height: 667px){
       align-items: flex-start;
    }
    .dialog-content{
      background-color: #FFF;
      text-align: center;
      padding: 48px 30px;
      border-radius: 4px;
      width: 440px;
      #dark & {
        background-color: ${palette.background[0]};
        border: 1px solid ${palette.border[4]};
      }
      .description{
        color: #737376;
        font-size: 14px;
        line-height: 18px;
        #dark & {
          color: ${palette.grays[7]};
        }
      }
      &.gated-iframe{
        padding: 0 !important;
        width: 750px !important;
        background-color: #FFF;
        border-radius: 0;
        border: 40px solid rgb(31, 45, 207) !important;
         #dark & {
          background-color: ${palette.background[0]};
        }
        iframe{
          width: 640px;
          height: 675px;
          border: 0;

        }
      }
      object{
        margin-bottom: 48px;
      }
    }
  `;
}}
`;

StyledLoaderDialog.displayName = 'StyledLoader';
export default StyledLoaderDialog;