import React, { ComponentProps } from "react";
import { Menu } from "antd";
import SVG from "components/svg/SVG";

// Global style, did not manage to style the submenu easily
import 'components/header/components/profileDropdown/ThemeMenu.css';
import { useUIThemeSelector } from 'components/header/components/profileDropdown/themeSelector';

import type { UITheme } from 'components/header/components/profileDropdown/themeSelector';

type ISubMenuProps = ComponentProps<typeof Menu['SubMenu']>

export interface IProps extends Omit<ISubMenuProps, 'key'> {
    key: string;
}

function getThemeName(uiTheme: UITheme): string {
    switch (uiTheme) {
        case 'light':
            return 'Light Mode';
        case 'dark':
            return 'Dark Mode';
        case 'auto':
        default:
            return 'OS Default';
    }
}

export function ThemeMenu(props: IProps): React.ReactElement {
    const uiTheme = useUIThemeSelector();

    return <Menu.SubMenu
        title={<div>{getThemeName(uiTheme)}</div>}
        className="theme-menu-item ant-dropdown-menu-item"
        popupClassName="theme-submenu"

        {...props}
    >
        <Menu.Item key="auto">
            <SVG name="bicon-auto-mode" width={20} height={20} />
            <span className="ml-1">{getThemeName('auto')}</span>
        </Menu.Item>
        <Menu.Item key="dark">
            <SVG name="bicon-dark-mode" width={20} height={20} />
            <span className="ml-1">{getThemeName('dark')}</span>
        </Menu.Item>
        <Menu.Item key="light">
            <SVG name="bicon-light-mode" width={20} height={20} />
            <span className="ml-1">{getThemeName('light')}</span>
        </Menu.Item>
    </Menu.SubMenu>
}
