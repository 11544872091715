import styled, { css } from 'styled-components';

const StyledApiPagination = styled.div`
  ${({ theme }) => {
    const {palette} = theme;
    return css`
      margin-top: 24px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .total-items {
        span {
          #dark & {
            color: ${palette.grays[11]};
          }
        }
      }

      li {
        margin-right: 0;
        border-color: #B3B3B3;
        border-radius: 0;
        border-right: 0;

        &:hover {
          border-color: #d9d9d9;
          a {
            color: #1f2ecf;
            #dark & {
              color: ${palette.grays[11]};
            }
          }
        }

        &.ant-pagination-item,
        &.ant-pagination-jump-next,
        &.ant-pagination-jump-prev {
          min-width: 40px;
          height: 40px;
          line-height: 40px;
          border: 1px solid #B3B3B3;
          border-right: 0;
          #dark & {
            border: 1px solid ${palette.border[4]};
            background: ${palette.background[0]};
          }
          .anticon {
            svg {
              fill: #1f2ecf;
            }
            #dark & {
              svg {
                fill: #FFF;
              }
            }
          }
        }

        &.ant-pagination-item-active {
          background: #f4f1fd;
          #dark & {
            background: ${palette.background[1]};
          }
          a {
            color: #1f2ecf;
            #dark & {
              color: ${palette.grays[11]};
            }
          }
        }

        &.ant-pagination-next {
          height: 40px;
          line-height: 40px;

          button.ant-btn {
            height: 40px;
            border-radius: 0 5px 5px 0;
            border-right: 1px solid #B3B3B3;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            #dark & {
              border-right: 1px solid ${palette.border[4]};
            }
            img {
              margin-left: 12px;
            }
          }
        }

        &.ant-pagination-prev {
          height: 40px;
          line-height: 40px;

          button.ant-btn {
            height: 40px;
            border-radius: 5px 0 0 5px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;

            img {
              margin-right: 12px;
              transform: rotate(180deg);
            }
          }
        }

        button.ant-btn {
          color: #333333;
          border-radius: 0;
          border-right: 0;
          border-color: #B3B3B3;
          #dark & {
            border-color: ${palette.border[4]};
            background-color: ${palette.background[0]};
            color: ${palette.grays[6]};
            border-right: 0;
          }
          &:hover:not(:disabled) {
            color: #1f2ecf;
            #dark & {
              color: ${palette.grays[11]};
            }
          }
          &:focus {
            color: #333333;
            background: #fff;
            border-color: #B3B3B3;
            #dark & {
              color: ${palette.grays[11]};
              background: ${palette.background[0]};
              border-color: ${palette.border[4]};
            }
          }
          &[disabled] {
            color: #B3B3B3;
            background-color: #fff;
            #dark & {
              background-color: ${palette.background[1]};
            }
            img {
              opacity: 0.3;
            }
          }
          &:hover {
            border-color: #B3B3B3;
          }
        }
      }
    `;
  }}
`;

StyledApiPagination.displayName = 'StyledApiPagination';
export default StyledApiPagination;
