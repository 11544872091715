/* eslint-disable max-lines-per-function */
import styled from 'styled-components';
import { palette } from 'styled-theme';

const DropdownWrapper = (ComponentName) => styled(ComponentName)`
  display: inline-block;
  &.ant-dropdown-button {
    &.ant-btn-group > .ant-btn:first-child:not(:last-child) {
      border-radius: ${(props) => (props['data-rtl'] === 'rtl' ? '0 4px 4px 0' : '4px 0  0 4px')};
    }
    &.ant-btn-group > .ant-btn:last-child:not(:first-child) {
      border-radius: ${(props) => (props['data-rtl'] === 'rtl' ? '4px 0 0 4px' : '0 4px 4px 0')};
    }
    &.ant-btn-group .ant-btn + .ant-btn {
      margin: ${(props) => (props['data-rtl'] === 'rtl' ? '0 -1px 0 0' : '0 0 0 -1px')};
    }
  }
  .ant-dropdown-link {
    font-size: 12px;
    color: ${palette('primary', 0)};

    &:hover {
      color: ${palette('primary', 3)};
    }
  }
`;
const DropdownMenus = (ComponentName) => styled(ComponentName)` 
  
  padding: 24px !important;
  border: 1px solid ${palette('border', 0)};
  border-radius: 4px !important;
  white-space: nowrap;
  &.isoUserDropdown{
    margin-top: 27px;
    min-width: 162px;
    .ant-dropdown-menu-item {
      padding: 6px 0 !important;
    }
    .userName{
      font-family: ${palette('fonts', 1)};
      font-size: ${palette('fontSize', 1)};
      line-height: 1;
      color: ${palette('text', 0)};
      margin: 0;
      line-height: 20px;
      padding: 8px 16px; 
      #dark & {
        color: ${palette('grays', 11)};
      }
    }
  }
  &.env-details-actions{
    transform: translateY(6px);
    border-radius: 4px !important;
    padding: 18px 0px !important;
    .separator{
      margin: 12px 0;
      border-top: 1px solid ${palette('border', 0)};
      #dark & {
        border-top: 1px solid ${palette('border', 4)};
      }
    }
    .ant-dropdown-menu-item {
      padding: 6px 24px !important;
      margin-bottom: 0px !important;
      .ant-dropdown-menu-title-content{
        line-height: 14px;
        a, div{
          font-size: 14px;
          line-height: 14px;
        }
      } 
      &:hover{background-color: transparent;}
    }
  }
  &.small{
    padding: 18px !important;
    &.dropdown-select{
      padding: 6px 0 !important;
      margin-top: -4px;
      border-radius: 0 0 4px 4px !important; 
      .ant-dropdown-menu-item{
        padding: 0 !important;
        font-size: ${palette("fontSize", 0)};
        line-height: 15px; 
        margin-bottom: 0px !important; 
        &:hover:not(.link-menu-item):not(.disconnected):not(.locked){
          background-color: ${palette('primary', 3)};
        }
        [role="button"],a {
          padding: 6px 12px;
          display: inline-block;
        }

        &.disconnected {
          span.ant-dropdown-menu-title-content {
            > div.disconnected {
              padding: 6px 12px;
            }
          }
        }
        &.locked {
          span.ant-dropdown-menu-title-content {
            > div.locked {
                padding: 6px 12px;
            }
          }
        }
        div{
          font-size: 12px;
          line-height: 15px;
          
          &.cluster-item{
            &:hover{color: ${palette('text', 0)};}
            span{
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: calc(100% - 30px);
            }
            &.disconnected{
              cursor: not-allowed;color: #CCC;
              &:hover{
                color: #CCC;
              }
            }
            &.locked{
              cursor: not-allowed;color: #CCC;
              &:hover{
                color: #CCC;
              }
            }
          }
        }
      }
    }
    &.env-actions{
      #dark & {
        background-color: #1c1c1c;
      }
      .ant-dropdown-menu-item {
        &:hover {
          background-color: ${palette('background', 2)};
          #dark & {
            background-color: ${palette('background', 0)};
            a, div {
              background-color: ${palette('background', 1)};
            }
          }
        }
      }
    }
    .ant-dropdown-menu-item {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 12px;
      div{
        line-height: 20px;
      }
    }
  }

  .ant-dropdown-menu-item {
    padding: 0px !important;
    border: 0px;
    margin-bottom: 18px;
    &:last-child {
      margin-bottom: 0px;
    }
    &:hover {
      background-color: ${palette('color', 7)};
      a {
        #dark & {
          background-color: ${palette('background', 1)};
        }
      }
    }
    &.ant-menu-item-selected{
      background-color: #FFF;
    }
    a,
    div {
      font-size: 14px;
      line-height: 16px;
      color: ${palette('text', 1)};
      font-family: ${palette('fonts', 0)};
      outline: 0;
      #dark & {
        color: ${palette('grays', 7)};
        background-color: #1c1c1c;
        &:hover{
          color: ${palette('grays', 11)};
          background-color: ${palette('background', 1)};
        }
      }
      .title {
        font-size: 14px;
        color: ${palette('text', 0)};
        margin-bottom: 3px;
      }
      .desc {
        font-size: 13px;
        line-height: 16px;
        color: ${palette('text', 1)};
      }
      &:hover {
        color: ${palette('primary', 0)};
        text-decoration: none;
        #dark & {
          div{
            background-color: ${palette('background', 1)};
          }
          background-color: ${palette('background', 1)};
          a {
            background-color: ${palette('background', 1)};
          }
        }
      }
    }
  }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    a {
      font-size: 14px;
      line-height: 20px;
      color: ${palette('text', 1)};
    }
  }
`;

export default DropdownWrapper;
export { DropdownMenus };
