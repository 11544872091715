import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import initialState from './initialState'
import {
  cancelSubscription,
  getBillingData,
  activateSubscription,
  addCard,
  // editCard,
  removeCard, makeCardPrimary,
  editBillingAddress, payInvoice,
  // changeSubscription
} from '../actions';

const getBillingDataHandler = [
  getBillingData,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
      };
    }

    return {
      ...state,
      isLoading: false,
      billingData: payload,
    };
  },
];

const cancelSubscriptionHandler = [
  cancelSubscription,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        buttonShouldLoad: true,
      };
    }

    if (error) {
      return {
        ...state,
        buttonShouldLoad: false,
        errorMessage: payload.response.message,
      };
    }

    return {
      ...state,
      buttonShouldLoad: false,
      billingData: payload,
    };
  },
];

const activateSubscriptionHandler = [
  activateSubscription,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        buttonShouldLoad: true,
      };
    }

    if (error) {
      return {
        ...state,
        buttonShouldLoad: false,
        errorMessage: payload.response.message,
      };
    }

    return {
      ...state,
      buttonShouldLoad: false,
      billingData: payload,
    };
  },
];
const addCardHandler = [
  addCard,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
      };
    }
    return {
      ...state,
      isLoading: false,
      billingData: payload,
    };
  },
];
const removeCardHandler = [
  removeCard,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
      };
    }
    return {
      ...state,
      isLoading: false,
      billingData: payload,
    };
  },
];
const makeCardPrimaryHandler = [
  makeCardPrimary,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
      };
    }
    return {
      ...state,
      isLoading: false,
      billingData: payload,
    };
  },
];

const editBillingAddressHandler = [
  editBillingAddress,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
      };
    }
    return {
      ...state,
      isLoading: false,
      billingData: payload,
    };
  },
];

const payInvoiceHandler = [
  payInvoice,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isInvoicePaying: true,
      };
    }
    if (error) {
      return {
        ...state,
        isInvoicePaying: false,
        errorMessage: payload.response.message,
      };
    }
    return {
      ...state,
      isInvoicePaying: false,
      billingData: payload,
    };
  },
];
const reducer = handleActions(
  new Map([
    getBillingDataHandler,
    cancelSubscriptionHandler,
    activateSubscriptionHandler,
    addCardHandler,
    makeCardPrimaryHandler,
    removeCardHandler,
    editBillingAddressHandler,
    payInvoiceHandler
  ]),
  R.clone(initialState)
);

export default reducer;