import styled, { css } from 'styled-components';

const StyledLabelText = styled.div`
  ${({ theme }) => {
  const { palette } = theme;
  return css`
      font-size: ${palette.fontSize[2]};
      line-height: 1;
      text-transform: capitalize;
      display: flex;
      >span{
        padding: 2px 6px;
        border-radius: 4px;
      }
      .gray{
        color: ${palette.text[1]};
        background-color: ${palette.secondary[0]};
        #dark & {
          background-color: transparent;
          color: ${palette.grays[7]};
          border: 1px solid ${palette.grays[7]};
        }
      }
      .red{
        color: ${palette.error[1]};
        background-color: ${palette.error[2]};
        #dark & {
          background-color: transparent;
          color: ${palette.error[0]};
          border: 1px solid ${palette.error[0]};
        }
      }
      .yellow{
        color: ${palette.text[1]};
        background-color: rgba(255, 230, 4, 0.4);
        #dark & {
          background-color: transparent;
          color: ${palette.warning[0]};
          border: 1px solid ${palette.warning[0]};
        }
      }
      .blue{
        color: ${palette.primary[0]};
        border: 1px solid ${palette.primary[0]};
        background-color: transparent;
        #dark & {
          background-color: transparent;
          color: ${palette.primary[5]};
          border: 1px solid ${palette.primary[5]};
        }
      }
      .green{
        color: ${palette.success[1]};
        background-color: ${palette.success[2]};
        #dark & {
          background-color: transparent;
          color: ${palette.success[0]};
          border: 1px solid ${palette.success[0]};
        }
      }
    `;
}}
`;

StyledLabelText.displayName = 'StyledLabelText';
export default StyledLabelText;

