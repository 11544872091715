import React from 'react';
import mixpanel from "mixpanel-browser";
import Button from 'theme/uielements/button';
import userManager from 'modules/userManager/UserManager';

const LogoutButton = () => {
  return (
    <Button type="primary" onClick={() => {userManager.logout(); mixpanel.reset()}}>
      Logout
    </Button>
  );
};

export default LogoutButton;
