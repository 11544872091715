import { createAction } from 'redux-actions';
import { createNotification, createDeployNotification } from 'components/notificationsContainer/helpers';

export const MODULE_NAME = 'GLOBAL';

export default {
  MODULE_NAME,
};

export const createPrefixedAction = (type) => createAction(`${MODULE_NAME}/${type}`);

export const actionTypes = {
  SHOW_DIALOG: 'SHOW_DIALOG',
  HIDE_DIALOG: 'HIDE_DIALOG',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  HIDE_SIDEBAR_TOPBAR: 'HIDE_SIDEBAR_TOPBAR',
  SHOW_REFRESH_LOADER: 'SHOW_REFRESH_LOADER',
  UPDATE_BREADCRUMB: 'UPDATE_BREADCRUMB',
  UPDATE_PROJECT_RESOURCE: 'UPDATE_PROJECT_RESOURCE',
  UPDATE_ENV_RESOURCE: 'UPDATE_ENV_RESOURCE',
  UPDATE_CLUSTER_RESOURCE: 'UPDATE_CLUSTER_RESOURCE',
  UPDATE_INTEGRATION_RESOURCE: 'UPDATE_INTEGRATION_RESOURCE',
  UPDATE_ORGANIZATION_RESOURCE: 'UPDATE_ORGANIZATION_RESOURCE',
  UPDATE_COMPONENTS_RESOURCE: 'UPDATE_COMPONENTS_RESOURCE',
  UPDATE_PIPELINE_RESOURCE: 'UPDATE_PIPELINE_RESOURCE',
  UPDATE_COMPONENT_PIPE_TOPIC: 'UPDATE_COMPONENT_PIPE_TOPIC',
  UPDATE_TEMPLATES_REPO_RESOURCE: 'UPDATE_TEMPLATES_REPO_RESOURCE',
  TOGGLE_CRITICAL_ERROR: 'TOGGLE_CRITICAL_ERROR',
  TOGGLE_MODE: 'TOGGLE_MODE',
  TOGGLE_UITHEME: 'TOGGLE_UITEHEME',
  HAS_UNSAVED_CHANGES: 'HAS_UNSAVED_CHANGES',
  UPDATE_CONFIG_INTEREST: 'UPDATE_CONFIG_INTEREST',
};

export const showDialog = createPrefixedAction(actionTypes.SHOW_DIALOG);
export const hideDialog = createPrefixedAction(actionTypes.HIDE_DIALOG);
export const addNotification = createPrefixedAction(actionTypes.ADD_NOTIFICATION);
export const hideSidebarTopbar = createPrefixedAction(actionTypes.HIDE_SIDEBAR_TOPBAR);
export const toggleCriticalErrorState = createPrefixedAction(actionTypes.TOGGLE_CRITICAL_ERROR);

export const updateBreadcrumb = createPrefixedAction(actionTypes.UPDATE_BREADCRUMB);
export const updateProjectResource = createPrefixedAction(actionTypes.UPDATE_PROJECT_RESOURCE);
export const updateEnvironmentResource = createPrefixedAction(actionTypes.UPDATE_ENV_RESOURCE);
export const updateOrganizationResource = createPrefixedAction(actionTypes.UPDATE_ORGANIZATION_RESOURCE);
export const updateIntegrationResource = createPrefixedAction(actionTypes.UPDATE_INTEGRATION_RESOURCE);
export const updateClusterResource = createPrefixedAction(actionTypes.UPDATE_CLUSTER_RESOURCE);
export const manuallyUpdateComponentPipeTopic = createPrefixedAction(actionTypes.UPDATE_COMPONENT_PIPE_TOPIC);
export const updateComponentsResource = createPrefixedAction(actionTypes.UPDATE_COMPONENTS_RESOURCE);
export const updatePipelineResource = createPrefixedAction(actionTypes.UPDATE_PIPELINE_RESOURCE);
export const updateTemplatesRepoResource = createPrefixedAction(actionTypes.UPDATE_TEMPLATES_REPO_RESOURCE);
export const toggleMode = createPrefixedAction(actionTypes.TOGGLE_MODE);
export const showPageRefreshLoader = createPrefixedAction(actionTypes.SHOW_REFRESH_LOADER);
export const hasUnsavedChanges = createPrefixedAction(actionTypes.HAS_UNSAVED_CHANGES);
export const toggleUiTheme = createPrefixedAction(actionTypes.TOGGLE_UITHEME);
export const updateConfigInterest = createPrefixedAction(actionTypes.UPDATE_CONFIG_INTEREST);

export const handleHideSidebarTopbar = (value) => (dispatch) => {
  return dispatch(hideSidebarTopbar(value));
};

export const showNotification = (type, title, content, source) => (dispatch) => {
  createNotification(type, title, content, source);
  return dispatch(addNotification(type, content));
};

export const showDeployNotification = (type, content, opts) => (dispatch) => {
  createDeployNotification(content, opts);
  return dispatch(addNotification(type, content));
};

export const handleUpdateProjectResource = (id) => async (dispatch) => {
  return dispatch(updateProjectResource(id));
};

export const handleUpdateEnvironmentResource = (id) => async (dispatch) => {
  return dispatch(updateEnvironmentResource(id));
};

export const handleUpdateIntegrationResource = (id) => async (dispatch) => {
  return dispatch(updateIntegrationResource(id));
};

export const handleUpdateClusterResource = (id) => async (dispatch) => {
  return dispatch(updateClusterResource(id));
};

export const handleUpdateOrganizationResource = (id) => async (dispatch) => {
  return dispatch(updateOrganizationResource(id));
};
export const handleUpdateComponentsResource = (id) => async (dispatch) => {
  return dispatch(updateComponentsResource(id));
};
export const handleUpdateComponentPipelineTopic = (id) => async (dispatch) => {
  return dispatch(manuallyUpdateComponentPipeTopic(id));
};
export const handleUpdatePipelineResource = (id) => async (dispatch) => {
  return dispatch(updatePipelineResource(id));
};
export const handleUpdateTemplatesRepoResource = (id) => async (dispatch) => {
  return dispatch(updateTemplatesRepoResource(id));
};

export const handleCriticalErrorState = (value) => (dispatch) => {
  if (value?.isActive) {
    dispatch(updateBreadcrumb(null));
  }
  return dispatch(toggleCriticalErrorState(value));
};

export const handleToggleMode = (value) => async (dispatch) => {
  document.querySelector('body').setAttribute('id', value);

  localStorage.setItem('themeMode', value);
  return dispatch(toggleMode(value));
};

export const handleUiTheme = (value) => async (dispatch) => {
  if (value === 'auto') {
    const mediaTheme = window.matchMedia('(prefers-color-scheme: dark)');
    if (mediaTheme.matches) dispatch(handleToggleMode('dark'));
    else dispatch(handleToggleMode('light'));
  } else {
    dispatch(handleToggleMode(value));
  }
  return dispatch(toggleUiTheme(value));
};
export const handleHasUnSavedChanges = (value) => (dispatch) => {
  return dispatch(hasUnsavedChanges(value));
};

export const handleConfigInterest = (value) => (dispatch) => {
  return dispatch(updateConfigInterest(value));
}
