import { createBrowserHistory } from 'history';

const createAppHistory = () => {
  let history = null;
  return {
    init() {
      history = createBrowserHistory();
      window.rrHistory = history;
      return history;
    },
    pushToAppHistory(path, data = {}) {
      if (!path || !history) return;
      history.push(path, data);
    },
  };
};

const appHistory = createAppHistory();

export default appHistory;
