import { API, GET, POST} from 'config';
import { log } from 'utils';
import {hideDialog, showNotification} from "actions/global";
import mixpanel from "mixpanel-browser"
import { createPrefixedAction } from '../config';
import actionTypes from './actionTypes';
import {TOAST_TYPES} from "../../../components";


export const getBillingData = createPrefixedAction(actionTypes.GET_BILLING_DATA);
export const cancelSubscription = createPrefixedAction(actionTypes.CANCEL_SUBSCRIPTION);
export const changeSubscription = createPrefixedAction(actionTypes.CHANGE_SUBSCRIPTION);
export const activateSubscription = createPrefixedAction(actionTypes.ACTIVATE_SUBSCRIPTION);
export const addCard = createPrefixedAction(actionTypes.ADD_CARD);
export const editCard = createPrefixedAction(actionTypes.EDIT_CARD);
export const removeCard = createPrefixedAction(actionTypes.REMOVE_CARD);
export const makeCardPrimary = createPrefixedAction(actionTypes.MAKE_CARD_PRIMARY);
export const editBillingAddress = createPrefixedAction(actionTypes.EDIT_BILLING_ADDRESS);
export const payInvoice = createPrefixedAction(actionTypes.PAY_INVOICE);

export const handleGetBillingData = (organizationId) => async (dispatch) => {
  try {
    await dispatch(
      getBillingData(GET(API.billing.billingData(organizationId)).then(({ data }) => {
        return data
      }))
    );
  } catch (exception) {
    dispatch(showNotification(TOAST_TYPES.error, 'Error', 'Something went wrong'));
    log.error('Error', exception);
  }
};

export const handleCancelSubscription = (organizationId) => async (dispatch) => {
  try {
    await dispatch(
      cancelSubscription(POST(API.billing.cancelSubscription(organizationId), {}, {Accept: 'application/json',}).then(({ data }) => {
        dispatch(hideDialog())
        return data
      }))
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};

export const handleActivateSubscription = (organizationId) => async (dispatch) => {
  try {
    await dispatch(
      activateSubscription(POST(
        API.billing.activateSubscription(organizationId),
        {},
        {Accept: 'application/json',}
      ).then(({ data }) => {
        return data
      }))
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};

export const handleChangeSubscription = (organizationId) => async (dispatch) => {
  try {
    await dispatch(
      changeSubscription(GET(API.billing.billingData(organizationId)).then(({ data }) => {
        return data
      }))
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};

export const handleAddCard = 
(organizationId, paymentMethodId, handleSetupIntent) => async (dispatch) => {
  try {
    await dispatch(
      addCard(POST(API.billing.addCard(organizationId), {paymentMethodId}).then(({ data }) => {
        mixpanel.track('Add Payment Method', { 'source': "Add Payment Method Modal"});
        dispatch(
          showNotification(TOAST_TYPES.success, 'Payment method saved', 'The payment method has been saved successfully.')
        );
        dispatch(hideDialog());
        handleSetupIntent();

        return data;
      }))
    );
  } catch (exception) {
    dispatch(
      showNotification(TOAST_TYPES.error, 'Error adding the new payment method', `${exception}`)
    );
    log.error('Error', exception);
  }
};

export const handleMakePrimary = (organizationId, paymentMethodId) => async (dispatch) => {
  try {
    await dispatch(
      makeCardPrimary(POST(API.billing.makeCardPrimary(organizationId), {paymentMethodId})
        .then(({ data }) => {
          dispatch(
            showNotification(TOAST_TYPES.success, ' Payment Method Updated', 'The credit card will be now used for bunnyshell payments.')
          );
          return data
        }))
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};
export const handleRemoveCard = (organizationId, paymentMethodId) => async (dispatch) => {
  try {
    await dispatch(
      removeCard(POST(API.billing.removeCard(organizationId), {paymentMethodId}).then(
        ({ data }) => {
          dispatch(
            showNotification(TOAST_TYPES.success, 'Payment method removed', 'The payment method has been removed.')
          );
          return data
        }))
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};

export const handlePayInvoice = (organizationId, stripeId) => async (dispatch) => {
  try {
    await dispatch(
      payInvoice(POST(API.billing.payInvoice(organizationId), {invoiceStripeId: stripeId}).then(
        ({ data }) => {
          dispatch(
            showNotification(TOAST_TYPES.success, 'Payment successful', 'The payment has been processed. ')
          );
          return data
        }))
    );
  } catch (exception) {
    log.error('Error', exception);
    dispatch(
      showNotification(TOAST_TYPES.error, 'Payment failed', ` Reason: ${exception}`)
    );
  }
};

export const handleEditBillingAddress = (payload, organizationId, isEdit) => async (dispatch) => {
  try {
    await dispatch(
      editBillingAddress(POST(API.billing.editBilling(organizationId), payload).then(
        ({ data }) => {
          dispatch(hideDialog())
          if(isEdit){
            mixpanel.track('Billing Address Update', { 'source': "Edit Billing Address Modal"});
            dispatch(
              showNotification(TOAST_TYPES.success, 'Billing address updated successfully', 'Your company billing address has been updated successfully.')
            );
          } else {
            mixpanel.track('Add Billing Address', { 'source': "Add Billing Address Modal"});
            dispatch(
              showNotification(TOAST_TYPES.success, 'Billing address added successfully', 'Your company billing address has been saved successfully.')
            );
          }
          return data
        }))
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};