import type { HydraListResponse, ListParams as BaseListParams } from 'types';
import type { InferEndpointResult } from 'app/services/types';

import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { mapListToTags, defaultBaseQuery } from 'app/services/api';

export type GitProviderType = 'github' | 'gitlab' | 'bitbucket' | 'azure';
export type GitIntegrationStatus = 'new' | 'request_access' | 'disconnected' | 'suspended' | 'connected' | 'detached';

type ID = string|number;

export interface EphemeralOptions {
    whitelistEnabled: boolean;

    branchWhitelist: string|null;
}

export interface GitProvider {
    id: number;
    type: GitProviderType;

    providerName: string|null;
    oauthClientId: string|null;
    oauthClientSecret: string|null;
    oauthBaseUrl: string|null;
    apiBaseUrl: string|null;
    organization: string|null;
}

export interface GitIntegration {
    id: number;

    organization: string|null;
    slug: string|null;
    installationId: string|null;
    accountType: string|null;
    githubConfigUrl: string|null;

    provider: GitProvider;
    envsUsage: number;

    accessToken: string|null;
    installationToken: string|null;
    refreshToken: string|null;

    status: GitIntegrationStatus;
    stateCode: string;

    ephemeralOptions: EphemeralOptions;
}

export interface Edit {
    ephemeralOptions: Partial<EphemeralOptions>;
}

export interface Create extends Edit {
    /**
     * Organization IRI
     */
    organization: string;
}

export interface ListParams extends BaseListParams {
    organization: number;
}

export interface ListItem extends GitIntegration {
};

export interface Item extends GitIntegration {
};

export const TAG = 'git_integrations';

export const gitIntegrationsApi = createApi({
    reducerPath: `api:neo:${TAG}`,

    baseQuery: defaultBaseQuery,

    tagTypes: [TAG],

    endpoints: (build) => ({
        list: build.query<HydraListResponse<ListItem>, ListParams>({
            query: (params) => {
                const {organization, ...queryParams} = params;
                return {
                    url: `/organizations/${organization}/git_integrations`,
                    queryParams,
                };
            },
            providesTags: (result) => {
                return mapListToTags({
                    list: result ? result['hydra:member'] : [],
                    idKey: 'id',
                    tag: TAG,
                    includeTag: true,
                    includePartialId: true,
                });
            },
        }),

        get: build.query<Item, ID>({
            query: (id) => `/git_integrations/${id}`,
            providesTags: (result) => {
                return mapListToTags({
                    list: result ? [result] : [],
                    idKey: 'id',
                    tag: TAG,
                });
            },
        }),

        create: build.mutation<Item, Create>({
            query: (data) => {
                return {
                    url: '/git_integrations',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: (result) => {
                return mapListToTags({
                    list: result ? [{id: result.id}] : [],
                    idKey: 'id',
                    tag: TAG,
                    includePartialId: true,
                });
            },
        }),

        edit: build.mutation<Item, {id: ID, data: Edit}>({
            query: ({id, data}) => {
                return {
                    url: `/git_integrations/${id}`,
                    method: 'PUT',
                    body: data,
                };
            },
            invalidatesTags: (result, error, args) => {
                return mapListToTags({
                    list: result ? [{id: args.id}] : [],
                    idKey: 'id',
                    tag: TAG,
                });
            },
        }),
    }),
});

export type AsyncListResult = InferEndpointResult<typeof gitIntegrationsApi.endpoints.list>;
