import * as R from 'ramda';
import { hideDialog, showNotification, handleCriticalErrorState } from 'actions/global';
import { TOAST_TYPES } from 'components';
import { deniedError } from 'config/http/helpers';
import {API, DELETE, GET, POST, PUT} from 'config';

import {log, getErrorMessage} from 'utils';
import {
  addProjectToList,
  deleteProjectFromList,
  updateProjectFromList,
} from 'modules/userManager/actions'
import { createPrefixedAction } from '../config';
import actionTypes from './actionTypes';
import { getTimeframesToAdd, getTimeframesToDelete } from './helpers';

export const getProjects = createPrefixedAction(actionTypes.GET_PROJECTS);
export const addProject = createPrefixedAction(actionTypes.ADD_PROJECT);
export const getProjectDetails = createPrefixedAction(actionTypes.GET_PROJECT_DETAILS);
export const getProjectVariables = createPrefixedAction(actionTypes.GET_PRJ_VARIABLES);
export const resetPrjErrors = createPrefixedAction(actionTypes.RESET_PRJ_ERRORS);
export const updateProject = createPrefixedAction(actionTypes.UPDATE_PROJECT);
export const getProjectTimeframes = createPrefixedAction(actionTypes.GET_PROJECT_TIMEFRAMES);
export const updateProjectTimeframes = createPrefixedAction(actionTypes.UPDATE_PROJECT_TIMEFRAMES);
export const deleteProject = createPrefixedAction(actionTypes.DELETE_PROJECT);
export const createTimeframe = createPrefixedAction(actionTypes.CREATE_TIMEFRAME);
export const editTimeframe = createPrefixedAction(actionTypes.EDIT_TIMEFRAME);
export const removeTimeframe = createPrefixedAction(actionTypes.REMOVE_TIMEFRAME);
export const revertTimeframes = createPrefixedAction(actionTypes.REVERT_TIMEFRAME);
export const resetState = createPrefixedAction(actionTypes.RESET_STATE);
export const createVariable = createPrefixedAction(actionTypes.CREATE_VARIABLE);
export const deleteVariable = createPrefixedAction(actionTypes.DELETE_VARIABLE);
export const updateVariable = createPrefixedAction(actionTypes.UPDATE_VARIABLE);
export const setProjectName = createPrefixedAction(actionTypes.SET_PROJECT_NAME);
export const saveBuildSettings = createPrefixedAction(actionTypes.SAVE_BUILD_SETTINGS);
export const saveMetricsSettings = createPrefixedAction(actionTypes.SAVE_METRICS_SETTINGS);
export const metricsHistoryRecompute = createPrefixedAction(actionTypes.SAVE_METRICS_HISTORY_RECOMPUTE);
export const getSecretValue = createPrefixedAction(actionTypes.GET_SECRET_VALUE);
export const hideSecretValue = createPrefixedAction(actionTypes.HIDE_SECRET_VALUE);
export const resetSecretValues = createPrefixedAction(actionTypes.RESET_SECRET_VALUES);
export const resetSecretValue = createPrefixedAction(actionTypes.RESET_SECRET_VALUE);
export const resetResourceId = createPrefixedAction(actionTypes.RESET_SECRET_VALUE);
export const getMetricsCycleTime = createPrefixedAction(actionTypes.GET_METRICS_CYCLE_TIME);
export const getMetricsDeploymentFrequency = createPrefixedAction(actionTypes.GET_METRICS_DEPLOYMENT_FREQUENCY);
export const getAllRepositories = createPrefixedAction(actionTypes.GET_ALL_REPOSITORIES);
export const storeRepositories = createPrefixedAction(actionTypes.STORE_REPOSITORIES);

const ldJsonHeader = { 'Content-Type': 'application/ld+json', Accept: 'application/ld+json' };

export const handleGetProjects = ({organizationId}, history) => async (dispatch) => {
  const extraDataObject = { alwaysShowCriticalErrors: true };

  let requestURL = API.organizations.projects;
  const queryParams = window.location?.href.split('?')[1] || '';
  const isProjectsPage = window.location?.pathname.endsWith('projects');

  /** true if has search / filters active */
  if (queryParams && isProjectsPage) {
    requestURL = `${requestURL}?${queryParams}&organization=${organizationId}`;
  } else {
    requestURL = `${requestURL}?organization=${organizationId}`;
  }

  try {
    await dispatch(
      getProjects(GET(requestURL, ldJsonHeader, extraDataObject).then(({ data }) => data))
    );
  } catch (exception) {
    log.error('Projects', exception);
    const errorCode = R.path(['response', 'status'], exception);
    
    if(errorCode === 403) {
      dispatch(handleCriticalErrorState({ isActive: true, details: deniedError }));
    }
    
    if(errorCode === 401)
      history.push('/login')
  }
};

export const handleAddProject =
  ({ projectName, preventNotification }, organizationId) =>
  async (dispatch) => {
    try {
      const body = {
        name: projectName,
        organization: `/api/organizations/${organizationId}`,
      };
      await dispatch(
        addProject(
          POST(API.projects.base, body).then(({ data }) => {
            dispatch(addProjectToList(data))
            if (!preventNotification) {
              dispatch(
                showNotification(TOAST_TYPES.success, 'Add Project', 'Projects added successfully!')
              );
            }
            return data;
          })
        )
      );
    } catch (exception) {
      log.error('Add Project', exception);
      dispatch(showNotification(TOAST_TYPES.error, 'Add Project', getErrorMessage(exception)));
    }
  };

export const handleGetProjectTimeframes = (projectId) => async (dispatch) => {
      try {
        await dispatch(
          getProjectTimeframes(
            GET(API.organizations.timeframes(projectId)).then(({ data }) => {
              return data;
            })
          )
        );
      } catch (exception) {
        log.error('Project timeframes', exception);
      }
    };
export const handleGetProjectDetails = (organizationId, projectId, history) => async (dispatch) => {
  try {
    await dispatch(
      getProjectDetails(
        Promise.all([
          GET(`${API.projects.base}/${projectId}`),
        ]).then((body) => {
          const projectSettings = R.path([0, 'data'], body);
          return {
            projectSettings,
            projectId,
            organizationId
          };
        })
      )
    );
  } catch (exception) {
    const errorCode = R.path(['response', 'status'], exception);
    if(errorCode === 404)
      history.push(`/${organizationId}/projects`)
    log.error('Project Details', exception);
    // dispatch(showNotification(TOAST_TYPES.error, 'Project Details', getErrorMessage(exception)));
  }
};

export const handleUpdateProject =
  ({name, organizationId, projectId,  autoUpdate }) =>
    async (dispatch) => {
      let promise;
      try {
        const body = {
          name,
          autoUpdate,
          organization: `/api/organizations/${organizationId}`,
        };
        promise = await dispatch(
          updateProject(PUT(`${API.projects.base}/${projectId}`, body).then(({ data }) => {
            dispatch(updateProjectFromList(data));
            return data
          }))
        );
      } catch (exception) {
        log.error(`Project ${name}`, exception);
        dispatch(showNotification(TOAST_TYPES.error, `Project ${name}`, getErrorMessage(exception)));
        return Promise.reject(exception);
      }
      return promise;
    };

  export const handleUpdateProjectLabels = ({ projectId, payload }) => async (dispatch) => {
      try {
        await dispatch(
          updateProject(
            PUT(`${API.projects.base}/${projectId}`, payload).then(({ data }) => {
              dispatch(updateProjectFromList(data));
              dispatch(showNotification(TOAST_TYPES.success, 'Success', 'Labels updated successfully.'));
              return data;
            })
          )
        );
      } catch (exception) {
        dispatch(showNotification(TOAST_TYPES.error, 'Error', getErrorMessage(exception)));
      }
    };

export const handleUpdateProjectTimeframes = (organizationId,projectId, history) =>
  async (dispatch, getState) => {
  const { addedTimeframes, markedToDeleteTimeframes } = getState().projects;
  const addPromiseArray = getTimeframesToAdd(projectId, addedTimeframes);
  const deletePromiseArray = getTimeframesToDelete(markedToDeleteTimeframes);
  const promiseArray = R.concat(deletePromiseArray, addPromiseArray);

  try {
    await dispatch(
      updateProjectTimeframes(
        Promise.all(promiseArray).then(() => {
          dispatch(
            showNotification(
              TOAST_TYPES.success,
              'Changes saved.',
              'The changes made on the availability timeframes have been successfully updated.'
            )
          );
          dispatch(handleGetProjectTimeframes(projectId));
          dispatch(handleGetProjectDetails(organizationId, projectId, history));
        })
      )
    );
  } catch (exception) {
    log.error(`Timeframes.`, exception);
    dispatch(showNotification(
      TOAST_TYPES.error,
      'Cannot save availability timeframes',
      'Some of the timeframes are overlapping. Review timeframes and try again.'
    ));
    dispatch(handleGetProjectDetails(organizationId, projectId, history));
  }
};

export const handleDeleteProject =
  ({ projectId, projectName, organizationId },  history) =>
    async (dispatch) => {
      try {
        await dispatch(
          deleteProject(
            DELETE(`${API.projects.base}/${projectId}`).then(() => {
              dispatch(deleteProjectFromList(projectId))
              dispatch(
                showNotification(
                  TOAST_TYPES.success,
                  'Project deleted',
                  `${projectName} has been deleted.`
                )
              );
              history.push(`/${organizationId}/projects`);
            })
          )
        );
      } catch (exception) {
        log.error(`Project ${projectName}`, exception);
        dispatch(
          showNotification(TOAST_TYPES.error, `Project ${projectName}`, getErrorMessage(exception))
        );
      }
    };

export const handleGetProjectVariables = (projectId, searchTerm = '') => async (dispatch) => {
    const url = `${API.projects.projectVariables(projectId)}${searchTerm ? `?name=${searchTerm}` : ''}`;

    try {
      await dispatch(
        getProjectVariables(
          GET(url).then(({ data }) => {
            return { data, isSearch: searchTerm !== '' };
          })
        )
      );
    } catch (exception) {
      log.error(`Project variables unavailable.`, exception);
    }
  };

export const handleCreateVariable =
  (varName, varValue, secret, organizationId, projectId) =>
    async (dispatch) => {
    try {
      await dispatch(
        createVariable(
          POST(API.projects.manageProjectVariable, {
              name: varName,
              value: varValue,
              project: `/api/projects/${projectId}`,
              isSecret: secret,
          }).then(({ data }) => {
            dispatch(hideDialog())
            dispatch(handleGetProjectVariables(projectId)).then(()=>{
              dispatch(
                showNotification(
                  TOAST_TYPES.success,
                  'Variable created successfully ',
                  `The ${data.name} variable has been created.`
                )
              );
            });

            return data;
          })
        )
      );
    } catch (exception) {
      log.error(`Error.`, exception);
    }
  };

export const handleUpdateVariable =
  // eslint-disable-next-line max-params
  (varName, varValue, secret, organizationId, projectId, variableId) => 
  async (dispatch) => {
    try {
      await dispatch(
        updateVariable(
          PUT(`${API.projects.manageProjectVariable}/${variableId}`, {
            name: varName,
            value: varValue,
            project: `/api/projects/${projectId}`,
            isSecret: secret,
          }).then(({ data }) => {
            dispatch(hideDialog());
            dispatch(handleGetProjectVariables(projectId)).then(() => {
              dispatch(
                showNotification(
                  TOAST_TYPES.success,
                  'Variable saved successfully ',
                  `The  ${data.name} variable has been updated.`
                )
              );
            });

            return data;
          })
        )
      );
    } catch (exception) {
      log.error(`Error.`, exception);
    }
  };

  
export const handleDeleteVariable =
  (key, organizationId, projectId, variableId) => async (dispatch) => {
    try {
      await dispatch(
        deleteVariable(
          DELETE(`${API.projects.manageProjectVariable}/${variableId}`).then(() => {
            
            dispatch(handleGetProjectVariables(projectId));
            dispatch(
              showNotification(
                TOAST_TYPES.success,
                'Variable deleted',
                'The variable has been deleted.'
              )
            );
            return key;
          })
        )
      );
    } catch (exception) {
      log.error(`Error.`, exception);
    }
  };
/** save build settings for project */
export const handleSaveBuildSettings = (projectId, payload) => async (dispatch) => {
  try {
    await dispatch(
      saveBuildSettings(
        POST(API.projects.saveBuildSettings(projectId), payload, {Accept: 'application/json',}).then((data) => {
          const settings = data?.data?.buildSettings;
          
          /** managed builder does not have to wait for validation */
          if (settings?.lastStatus === 'success') {
            dispatch(showNotification(TOAST_TYPES.success, 'Success', 'Build settings has been saved successfully.'));
          }
          if (settings?.lastStatus === 'error') {
            dispatch(showNotification(TOAST_TYPES.error, 'Error', settings?.lastError));
          }

          return data;
        })
      )
    );
  } catch (exception) {
    dispatch(showNotification(TOAST_TYPES.error, 'Error', 'Could not save the build settings.'));
    log.error(`Error.`, exception);
  }
};

export const handleGetSecretValue = (id , isEdit) => async (dispatch) => {
  let secret = null;
  try {
    await dispatch(
      getSecretValue(GET(`${API.projects.manageProjectVariable}/${id}?realValues=1`).then((response) => {
        secret = response.data;
        
        if(isEdit) {
          return {data: response.data, isEdit}
        }
      return response.data
    })))
  } catch (exception) {
    log.error('Error.', exception)
  }

  return secret;
}
export const handleGetAllRepositories = (organizationId) => async (dispatch) => {

  try {
    await dispatch(
      getAllRepositories(GET(API.organizations.gitIntegrations(organizationId)).then(({data}) => {
        const connectedIntegrations = data.filter((item)=>{return item.status === 'connected'});
        const promiseArray = connectedIntegrations.map((item) => GET(API.gitIntegrations.getRepositories(item.id)));

        Promise.all(promiseArray).then((response) => {
          dispatch(storeRepositories(response))
        });

      })));

  } catch (exception) {
    log.error('Error.', exception)
  }
}
export const handleGetMetricsCycleTime = (projectId, startDate, endDate, repositoryId) => async (dispatch) => {
  const createdAtAfter = startDate === undefined ? null : startDate;
  const createdAtBefore = endDate === undefined ? null : endDate;
  try {
    await dispatch(
      getMetricsCycleTime(GET(`${API.projects.cycleTime(projectId)}?startDate=${createdAtAfter}&endDate=${createdAtBefore}&repositoryId=${repositoryId}`).then((response) => {
        return response.data
      })))
  } catch (exception) {
    log.error('Error.', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Error', 'Could not get average cycle time.'));
  }
}

// eslint-disable-next-line max-len
export const handleGetMetricsDeploymentFrequency = (projectId, startDate, endDate, repositoryId) => async (dispatch) => {
  const createdAtAfter = startDate === undefined ? null : startDate;
  const createdAtBefore = endDate === undefined ? null : endDate;
  try {
    await dispatch(
      getMetricsDeploymentFrequency(
        GET(`${API.projects.deploymentFrequency(projectId)}?startDate=${createdAtAfter}&endDate=${createdAtBefore}&repositoryId=${repositoryId}`)
          .then((response) => {
            return response.data
          })
      ))
  } catch (exception) {
    log.error('Error.', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Error', 'Could not get deployment frequency.'));
  }
}

export const handleHideProjectVarSecret = (id) => async(dispatch) => {
  dispatch(hideSecretValue(id))
}

/** save build settings for project */
export const handleMetricsHistoryRecompute = (projectId, payload) => async (dispatch) => {
  try {
    await dispatch(
      metricsHistoryRecompute(
        PUT(API.projects.metricsHistoryRecompute(projectId), payload).then((data) => {
          return data;
        })
      )
    );
  } catch (exception) {
    log.error(`Error.`, exception);
  }
};

/** save build settings for project */
export const handleSaveMetricsSettings = (projectId, payload, hasRecomputeFlag) => async (dispatch) => {
  try {
    await dispatch(
      saveMetricsSettings(
        PUT(API.projects.saveMetricsSettings(projectId), payload).then((data) => {
          dispatch(showNotification(TOAST_TYPES.success, 'Success', 'Engineering metrics settings has been saved successfully.'));

          if (hasRecomputeFlag === true) {
            metricsHistoryRecompute(POST(API.projects.metricsHistoryRecompute(projectId), { "recompute": true }).then((computeResponse) => {
                return computeResponse;
              }));
          }
          return data;
        })
      )
    );
  } catch (exception) {
    log.error(`Error.`, exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Error', 'Could not save engineering metrics settings.'));
  }
};