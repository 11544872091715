import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import type { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';

import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from 'config/api';
import userManager from 'modules/userManager';

export type BaseError = SerializedError | FetchBaseQueryError;
export type PrepareHeaders = NonNullable<FetchBaseQueryArgs['prepareHeaders']>;

export const defaultPrepareHeaders: PrepareHeaders = (headers) => {
    // @review remove this in favor of auth store slice
    const userManagerToken = userManager.getAccessToken();
    if (userManagerToken) {
        headers.set('Authorization', `Bearer ${userManagerToken}`);
    }
    headers.set('Accept', 'application/ld+json');
    headers.set('Content-Type', 'application/json');

    return headers;
}

export const defaultBaseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,

    prepareHeaders: defaultPrepareHeaders,
});

export function isFetchError(error: BaseError): error is FetchBaseQueryError {
    return 'status' in error;
}

export function normalizeError(error: BaseError): unknown {
    return isFetchError(error)
        ? error.data
        : error.message
    ;
}

export const mapListToTags: <T extends object, B extends string>(options: {
    list: T[],
    idKey: keyof T,
    tag: B,
    includeTag?: boolean,
    includePartialId?: boolean,
}) => (B|{type:B,id?: string|number})[] = ({
    list,
    idKey,
    tag,
    includeTag = false,
    includePartialId = false,
}) => {
    const results: (typeof tag|{type:typeof tag,id?: string|number})[] = [];

    if (includeTag) {
        results.push(tag);
    }

    if (includePartialId) {
        results.push({
            type: tag,
            id: 'PARTIAL-LIST',
        });
    }

    for (let i = 0; i < list.length; i += 1) {
        const id = list[i][idKey];
        if (typeof id === 'string' || typeof id === 'number') {
            results.push({
                type: tag,
                id,
            });
        }
    }

    return results;
};
