// @review This is to support the current legacy async action codebase.
// Alternatively use redux-thunk or redux-toolkit-query
import type { Action, AnyAction, Middleware, MiddlewareAPI } from 'redux';

type PromiseDispatch = <T extends Action>(promise: Promise<T>) => Promise<T>;
type PromisedPayload<T = unknown> = {
    payload?: Promise<T>;
};
type PromisedAction<T = unknown> = AnyAction & PromisedPayload<T>;

type IData = {
    error?: true;

    payload: unknown;
};

function makeSyncAction(action: PromisedAction, ready: boolean, data: IData): AnyAction {
    return {
        ...action,
        ready,
        ...data
    };
}

/**
 * Example: store.dispatch(Promise.resolve({ type: 'GET_USERS' }));
 */
export const promiseActionMiddleware: Middleware<PromiseDispatch> = ({
    dispatch
}: MiddlewareAPI) => next => <T extends Action>(
    action: AnyAction | Promise<T>
) => {
    if (!(action instanceof Promise)) {
        return next(action);
    }

    action
        .then(dispatch)
        .catch((error: unknown) => {
            dispatch({
                type: 'middleware/error',
                data: error
            });
        })
    ;

    return action;
}

/**
 * Example: store.dispatch({ type: 'GET_USERS', payload: Promise.resolve({ page: 2, }) });
 */
export const promisePayloadMiddleware: Middleware = () => next => <T extends PromisedAction>(
    action: PromisedAction<T>
) => {
    if (!action.payload) {
        return next(action);
    }

    if (!(action.payload instanceof Promise)) {
        return next(action);
    }

    next(makeSyncAction(action, false, {
        payload: null,
    }));

    return action.payload
        .then(result => {
            const nextAction = makeSyncAction(action, true, {
                payload: result
            });

            return next(nextAction);
        })
        .catch((exception: unknown) => {
            const error = exception instanceof Error ? exception : new Error('Oops! Something went wrong.');

            const nextAction = makeSyncAction(action, true, {
                error: true,
                payload: {
                    message: error,
                    response: 'response' in error ? error.response : error
                }
            });

            next(nextAction);

            return Promise.reject(exception)
        })
    ;
}
