import type { OrganizationPermission } from 'rbac/types';
import type { Result, Options as BaseOptions, OrganizationContext } from 'rbac/hooks/useACL';

import { useACL } from 'rbac/hooks/useACL';

export type Options = Pick<BaseOptions, 'allowFetching'|'skip'>;

export type Context = OrganizationContext;

export function useOrganizationACL(permission: OrganizationPermission, context: Context, options: Options): Result {
    return useACL(permission, context, {
        allowFetching: options.allowFetching,
        skip: options.skip,

        matcher: {
            environmentAnyParent: false, // @todo unused, need to make typings stricter for non-environment
        },
    });
}
