/* eslint-disable max-params */
import axios from 'axios';
import userManager from 'modules/userManager';
import { isBlank } from 'utils';
import { errorHandler } from './refreshInterceptor';
import httpIndexer from './http_indexer';

function getAPIPath(uri) {
  if (!uri.startsWith(process.env.REACT_APP_BASE_API)) {
    return false;
  }

  return uri.replace(process.env.REACT_APP_BASE_API, '').split('?')[0];
}

// stores the cancelable requests data
const storedCancelData = {};

const tryToCancelRequest = ({ method, apiPath, controller }) => {
  // [!] do not forget to check and handle the use of .then() on cancelable requests
  // enabled for method: GET
  const includedByPath = [
    /api\/projects\/\d+\/environments$/,
    /api\/organizations\/\d+\/templates$/,
    /api\/organizations\/\d+\/cost-tracking\/report$/,
    /api\/templates\/public$/,
    /api\/organizations\/\d+\/kubernetes_integrations$/,
    /api\/organizations\/\d+\/pipelines$/,
    /api\/kubernetes_integrations\/\d+$/,
    /api\/kubernetes_integrations\/\d+\/addons$/,
  ];

  const storedData = storedCancelData[apiPath] || null;
  const isGetRequest = method === 'get';
  const requestIsPending = storedData?.status === 'pending';
  const isExcludedByPath = (() => {
    for (let i = 0; i < includedByPath.length; i += 1) {
      if (includedByPath[i].test(apiPath)) {
        return true;
      }
    }

    return false;
  })();

  const readyToCancel = isGetRequest && requestIsPending && isExcludedByPath;

  if (readyToCancel) {
    // [i] the message argument is displayed in the error object on the request
    storedData.controller.abort('canceled');
  }

  storedCancelData[apiPath] = { controller, status: 'pending' };
};

export const headersWithToken = (headers) => {
  const accessToken = userManager.getAccessToken();
  if (isBlank(accessToken)) return headers;

  return {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
    ...headers,
  };
};

export const RequestFactory = (method, url, headers, data, options) => {
  const controller = new AbortController();
  const { signal } = controller;

  const apiPath = getAPIPath(url);
  const augmentedHeaders = headersWithToken(headers || {});

  tryToCancelRequest({ method, apiPath, controller });

  const ax = axios({
    method,
    url,
    data,
    headers: augmentedHeaders,
    crossDomain: true,
    responseType: null,
    alwaysShowCriticalErrors: !!options?.alwaysShowCriticalErrors,
    signal,
    ...options,
  }).then((response) => {
    /**
     * in case of a 50x error, the promise chain execution will be interrupted
     * as the promise will be rejected */

    /* update pending status */
    storedCancelData[apiPath].status = 'finished';

    const isNetworkError = response?.message === 'Network Error';

    if (isNetworkError) {
      /* "manually" break the chain */
      return Promise.reject(response.message);
    }

    return response;
  });
  
  return ax;
};

export const GET = (url, headers = {}, ...options) => RequestFactory('get', url, headers, null, ...options);
export const HEAD = (url, headers = {}, ...options) => RequestFactory('head', url, headers, null, ...options);
export const POST = (url, data, headers = {}, ...options) => RequestFactory('post', url, headers, data, ...options);
export const PUT = (url, data, headers = {}, ...options) => RequestFactory('put', url, headers, data, ...options);
export const PATCH = (url, data, headers = {}, ...options) => RequestFactory('patch', url, headers, data, ...options);
export const DELETE = (url, headers = {}, data, ...options) => RequestFactory('delete', url, headers, data, ...options);

export const configureRefreshInterceptor = (logOut, handleErrorScreen) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => errorHandler(error, logOut, handleErrorScreen)
  );

  axios.interceptors.request.use(
    (config) => {
      // Do something before request is sent
      if (httpIndexer.makePageVisible) {
        return config;
      }
      httpIndexer.increment();
      httpIndexer.log();

      return config;
    },
    (error) => {
      if (httpIndexer.makePageVisible) {
        return error;
      }
      // Do something with request error
      httpIndexer.decrement();
      httpIndexer.log();
      httpIndexer.delayTryToUnlock();

      return error;
    }
  );

  axios.interceptors.response.use(
    (response) => {
      if (httpIndexer.makePageVisible) {
        return response;
      }
      httpIndexer.decrement();
      httpIndexer.delayTryToUnlock();
      httpIndexer.log();

      return response;
    },
    (error) => {
      if (httpIndexer.makePageVisible) {
        return errorHandler(error, () => {}, handleErrorScreen);
      }
      window.currentRequestCount -= 1;
      httpIndexer.decrement();
      httpIndexer.log();
      httpIndexer.delayTryToUnlock();

      return errorHandler(error, () => {}, handleErrorScreen);
    }
  );

  //hard timeout for global loader, show page after 30s no matter what. Otherwise make best effort
  setTimeout(() => httpIndexer.makeVisible(), 30000);
};

export default {
  RequestFactory,
  GET,
  POST,
  PUT,
  PATCH,
  DELETE,
};
