import { combineReducers } from 'redux';
import sidebar from 'modules/sidebar/reducer';
import projects from 'modules/projects/reducer';
import environments from 'modules/environments/reducer';
import logs from 'modules/environments/components/eventLogs/reducer';
import applications from 'modules/applications/reducer';
import clusters from 'modules/clusters/reducer';
import integrations from 'modules/integrations/reducer';
import userManager from 'modules/userManager/reducer';
import admin from 'modules/admin/reducer';
import billing from 'modules/billing/reducer';
import organization from 'modules/settings/reducer';
import templates from 'modules/templates/reducer';
import global from './globalReducer';

export const rootReducers = {
  global,
  sidebar,
  projects,
  environments,
  logs,
  applications,
  clusters,
  integrations,
  userManager,
  admin,
  billing,
  organization,
  templates,
};

const createReducer = (asyncReducers) =>
  combineReducers({
    ...rootReducers,
    ...asyncReducers,
  });

export default createReducer;
