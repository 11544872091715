import React from "react";
import { Menu } from "antd";
import { LinkItem, SubMenuTitle } from "modules/sidebar/items/utils";
import { useOrganizationACL } from "rbac/hooks/useOrganizationACL";

import type { MenuTheme } from "modules/sidebar/items/menu";

export interface ITitleProps {
    theme: MenuTheme;
}

export interface IProps {
    theme: MenuTheme;

    organizationId: number;
}

export function SettingsTitle(props: ITitleProps): React.ReactElement {
    return <SubMenuTitle
        icon="bicon-settings"
        name="Settings"
        theme={props.theme}
    />
}

export function isSettingsActive(pathname: string, organizationId: number): boolean {
    if (pathname.startsWith(`/${organizationId}/settings`)) {
        return true;
    }

    if (pathname.startsWith(`/${organizationId}/billing`)) {
        return true;
    }

    if (pathname.startsWith(`/${organizationId}/security`)) {
        return true;
    }

    return false;
}

export function SettingsSubMenu(props: IProps): React.ReactElement {
    const { theme, organizationId } = props;

    // we don't have an activeKey for submenu
    // we don't show the highlighted submenu tree
    const activeKey = "";

    // RBAC checks
    const organizationManage = useOrganizationACL('organization:manage', {
        organization: {
            id: organizationId,
        },
    }, {
        allowFetching: true,
    });
    const organizationSecurity = useOrganizationACL('organization:security', {
        organization: {
            id: organizationId,
        },
    }, {
        allowFetching: true,
    });
    const organizationBilling = useOrganizationACL('organization:billing', {
        organization: {
            id: organizationId,
        },
    }, {
        allowFetching: true,
    });

    return <Menu.SubMenu
        title={<SettingsTitle theme={theme} />}
        key="settings-submenu"
        className="hasSubmenu"
    >
        {
            organizationManage.type === 'denied'
            ? <></>
            : <LinkItem
                currentKey={activeKey}
                key="settings-general"
                title="General Settings"
                icon="bicon-settings"
                linkTo={{
                    pathname: `/${organizationId}/settings`
                }}
            />
        }
        {
            organizationBilling.type === 'denied'
            ? <></>
            : <LinkItem
                currentKey={activeKey}
                key="settings-billing"
                title="Billing"
                icon="bicon-billing"
                linkTo={{
                    pathname: `/${organizationId}/billing`
                }}
            />
        }
        {
            organizationSecurity.type === 'denied'
            ? <></>
            : <LinkItem
                currentKey={activeKey}
                key="settings-users"
                title="Users"
                icon="bicon-user"
                linkTo={{
                    pathname: `/${organizationId}/settings/users`
                }}
            />
        }

        {
            organizationSecurity.type === 'denied'
            ? <></>
            : <>
                <LinkItem
                    currentKey={activeKey}
                    key="settings-rbac-policies"
                    title="RBAC Policies"
                    icon="bicon-security"
                    linkTo={{
                        pathname: `/${organizationId}/security/policies`
                    }}
                />
                <LinkItem
                    currentKey={activeKey}
                    key="settings-rbac-resource-selectors"
                    title="RBAC Resource Selectors"
                    icon="bicon-security"
                    linkTo={{
                        pathname: `/${organizationId}/security/resource-selectors`
                    }}
                />
                <LinkItem
                    currentKey={activeKey}
                    key="settings-rbac-teams"
                    title="RBAC Teams"
                    icon="bicon-security"
                    linkTo={{
                        pathname: `/${organizationId}/security/teams`
                    }}
                />
            </>
        }
    </Menu.SubMenu>
}
