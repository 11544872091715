import React, { useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Link, withRouter, useHistory } from 'react-router-dom';
import compose from 'hocs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCurrentEnvironmentsNo } from 'modules/environments/actions';
import { Layout } from 'antd';
import { InputSearch } from 'theme/uielements/input';
import useWindowSize from 'theme/utility/useWindowSize';
import Breadcrumb from 'components/breadcrumb/Breadcrumb';
import { DropdownMenu, MenuItem } from 'theme/uielements/dropdown';
import Button from 'theme/uielements/button';
import userManager from 'modules/userManager';
import NotificationCenter from 'components/notificationsContainer/NotificationCenter';
import { sidebarFullWidthBreakpoint } from 'components/layout/Layout';
import { filterTable } from './components/helpers';
import SVG from '../svg';
import ProfileDropdown from './components/profileDropdown';
import StyledHeader from './Header.style';
import ProjectSelector from './components/projectSelector/ProjectSelector';
import { TrialRibbon } from './components/billing/TrialRibbon';

const Header = ({
  breadcrumb,
  actions,
  fullPage,
  email,
  projects,
  projectsList,
  currentOrganizationId,
  currentProject,
  firstName,
  lastName,
  isLoadingProjects,
  view,
  userId,
}) => {
  const history = useHistory();

  const { width } = useWindowSize();
  const intervalCount = useRef(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentProjectName, setCurrentProjectName] = useState(
    R.filter(R.propEq('id', currentProject))(projects || [])[0]?.name
  );
  const [paddingLeft, setPaddingLeft] = useState(document.querySelector('.isomorphicSidebar')?.offsetWidth || 104);

  const forceSidebarFullWidth = width > sidebarFullWidthBreakpoint;

  useEffect(() => {
    const projName = R.filter(R.propEq('id', currentProject))(projects || []);
    if (projName.length > 0) setCurrentProjectName(projName[0] && projName[0].name);
    else {
      setCurrentProjectName(null);
    }
  }, [projects, projectsList, currentProject, firstName]);

  useEffect(() => {
    // sets padding for top bar based on sidebar width
    const interval = setInterval(() => {
      const target = document.querySelector('.isomorphicSidebar');

      if (target) {
        intervalCount.current += 1;
        setPaddingLeft(forceSidebarFullWidth ? target.offsetWidth + 32 : 104);
      }

      if (intervalCount.current >= 5) {
        clearInterval(interval);
      }
    }, 100);

    // set padding base on sidebar width
    const sideMenuWidth = document.querySelector('.isomorphicSidebar')?.offsetWidth || 104;
    setPaddingLeft(forceSidebarFullWidth ? sideMenuWidth + 32 : 104);

    return () => {
      clearInterval(interval);
    };
  }, [width, forceSidebarFullWidth, breadcrumb]);

  const addMenu = () => {
    const projectsNo = projects.length;
    const sortedProjects = R.sortBy(R.compose(R.toLower, R.prop('name')))(projects || []);
    return (
      <DropdownMenu
        className={projectsNo > 10 ? 'projects-dropdown-selector with-search' : 'projects-dropdown-selector'}
      >
        {projectsNo > 10 && (
          <div className="search-projects-container">
            <InputSearch
              className="input-search mr-2"
              addonAfter={<SVG name="bicon-search" noHover defaultColor="#808080" />}
              size="small"
              placeholder="Search project"
              onChange={({ target }) => setSearchTerm(target.value)}
            />
            <MenuItem key="view-all-projects-top" className="view-all-projects-top">
              <Button
                type="primary"
                size=""
                onClick={() => {
                  history.push(`/${currentOrganizationId}/projects`);
                }}
                data-value="view-all-projects-link"
              >
                View all projects
              </Button>
            </MenuItem>
          </div>
        )}
        <div className="project-list-container">
          {R.compose(
            R.map((project) => {
              const { accessibleEnvironmentsCount } = project;
              return (
                <MenuItem key={project.id}>
                  <div
                    role="button"
                    tabIndex="0"
                    onClick={() => {
                      actions.setCurrentEnvironmentsNo(accessibleEnvironmentsCount);
                      history.push(`/${currentOrganizationId}/projects/${project.id}/environments`, {
                        envCount: accessibleEnvironmentsCount,
                      });
                    }}
                    onKeyDown={() => {
                      actions.setCurrentEnvironmentsNo(accessibleEnvironmentsCount);
                      history.push(`/${currentOrganizationId}/projects/${project.id}/environments`, {
                        envCount: accessibleEnvironmentsCount,
                      });
                    }}
                    data-value="select-current-project-option"
                    className={project.id === currentProject ? 'active' : ''}
                  >
                    {project.name}
                    <SVG name="bicon-check" width={15} height={15} defaultColor="#1F2ECF" />
                  </div>
                </MenuItem>
              );
            }),
            R.filter(filterTable(searchTerm))
          )(sortedProjects)}
        </div>
        {projectsNo < 11 && (
          <MenuItem key="view-all-projects" className="mt-1">
            <Link to={`/${currentOrganizationId}/projects`} className="" data-value="view-all-projects-link">
              View all projects
            </Link>
          </MenuItem>
        )}
      </DropdownMenu>
    );
  };

  if (!userManager.isLoggedIn() || fullPage) return null;
  return (
      <StyledHeader>
        <Layout.Header
          style={{
            position: 'fixed',
            width: '100%',
            height: 64,
            paddingLeft,
          }}
          className="isomorphicTopbar collapsed"
        >
          <div className="isoLeft d-flex">{breadcrumb && <Breadcrumb />}</div>
          <TrialRibbon organizationId={currentOrganizationId} />
          <div className="topbar-actions">
            {userId && currentOrganizationId && (
              <NotificationCenter userId={userId} currentOrganizationId={currentOrganizationId} />
            )}
            <ProjectSelector
              view={view}
              isLoadingProjects={isLoadingProjects}
              addMenu={addMenu}
              currentProjectName={currentProjectName}
            />
            <ul className="isoRight">
              <li className="isoUser">
                <div className="profile-button" role="button" tabIndex={0}>
                  <ProfileDropdown
                    userId={userId}
                    currentOrganizationId={currentOrganizationId}
                    email={email}
                    firstName={firstName}
                    lastName={lastName}
                  />
                </div>
              </li>
            </ul>
          </div>
        </Layout.Header>
      </StyledHeader>
  );
};

Header.defaultProps = {
  currentOrganizationId: '',
  currentProject: null,
  userId: null,
  firstName: '',
  lastName: '',
  breadcrumb: null,
};

Header.propTypes = {
  view: PropTypes.string.isRequired,
  userId: PropTypes.number,
  firstName: PropTypes.string,
  isLoadingProjects: PropTypes.bool.isRequired,
  lastName: PropTypes.string,
  currentOrganizationId: PropTypes.number,
  currentProject: PropTypes.number,
  projectsList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  projects: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  fullPage: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  breadcrumb: PropTypes.shape({}),
  actions: PropTypes.shape({
    setCurrentEnvironmentsNo: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = (state) => {
  const {
    userId,
    isLoading,
    email,
    currentOrganization,
    lastUsedOrganization,
    currentProject,
    lastUsedProject,
    projects,
    firstName,
    lastName,
  } = state.userManager;
  const { list, isLoadingProjects } = state.projects;
  const { fullPage, breadcrumb } = state.global;
  const { view } = state.sidebar;
  const lastUsedProjectId = lastUsedProject ? lastUsedProject.id : null;
  return {
    userId,
    isLoading,
    breadcrumb,
    firstName,
    lastName,
    fullPage,
    view,
    projectsList: list,
    isLoadingProjects,
    currentOrganizationId:
      parseInt(currentOrganization, 10) || (lastUsedOrganization && parseInt(lastUsedOrganization.id, 10)),
    lastUsedProjectId,
    currentProject:
      (currentProject && parseInt(currentProject, 10)) || (lastUsedProjectId && parseInt(lastUsedProjectId, 10)),
    email,
    projects,
    userManager: state.userManager,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setCurrentEnvironmentsNo,
    },
    dispatch
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Header);
