import React from 'react';
import PropTypes from 'prop-types';
import Spin from 'theme/uielements/spin';

const WithLoader = ({ children, className, showLoader, size }) => {
  return showLoader ? <Spin className={className} size={size} /> : children;
};

WithLoader.defaultProps = {
  children: null,
  className: '',
  showLoader: true,
  size: 'default',
};

WithLoader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  showLoader: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'default', 'large']),
};

export default WithLoader;
