import styled, { css } from 'styled-components';

const StyledNotificationBody = styled.div`
  ${({ theme }) => {
    const { palette} = theme;

    return css`
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0;
      line-height: 22px;
      overflow: hidden;
      border-radius: 4px;
      &.deploy-notification {
        border-radius: 0;
        .notification-title {
          color: ${palette.primary[0]};
          #dark & {
            color: ${palette.grays[10]};
          }
        }
        .notification-description {
          margin-bottom: 20px;
          color: #737376;
          line-height: 17.7px;
          #dark & {
            color: ${palette.grays[7]};
          }
        }
        .ant-btn-primary {
          font-size: 12px;
          font-weight: 700;
          background-color: ${palette.primary[0]};
          border-color: ${palette.primary[0]};
          line-height: 15px;
          border-radius: 4px;
          &:hover {
            background-color: ${palette.primary[1]};
            border-color: ${palette.primary[1]};
          }
        }
      }

      .notification-title {
        margin-bottom: 12px;
        color: ${palette.text[0]};
        font-size: ${palette.fontSize[1]};
        font-family: ${palette.fonts[1]};
        line-height: 20px;
        margin-top: 0px; 
        letter-spacing: -0.02em;
        #dark & {
          color: ${palette.grays[10]};
        }
      }

      .notification-content {
        color: ${palette.grays[4]};
        font-size: ${palette.fontSize[1]};
        line-height: 18px;
        #dark & {
          color: ${palette.grays[6]};
        }
      }
    `;
  }}
`;

StyledNotificationBody.displayName = 'StyledNotificationBody';
export default StyledNotificationBody;
