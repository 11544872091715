import React from 'react';
import Button from 'theme/uielements/button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const HomeButton = ({ lastUsedOrganizationId }) => (
  <Button
    type="primary"
    onClick={() => {
      window.location.href = lastUsedOrganizationId ? `/${lastUsedOrganizationId}/projects` : '/';
    }}
  >
    Home
  </Button>
);

HomeButton.propTypes = {
  lastUsedOrganizationId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const { lastUsedOrganization } = state.userManager;
  return {
    lastUsedOrganizationId: lastUsedOrganization?.id,
  };
};

export default connect(mapStateToProps, null)(HomeButton);
