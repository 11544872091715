import React from 'react';
import { EventSourceContext } from '../helpers';

const withEventSourceProvider = (Component) => {
  return (props) => {
    return (
      <EventSourceContext.Consumer>
        {({ eventSource }) => <Component {...props} eventSource={eventSource} />}
      </EventSourceContext.Consumer>
    );
  };
};

export default withEventSourceProvider;
