import type { HydraListResponse, ListParams as BaseListParams } from 'types';
import type { InferEndpointResult } from 'app/services/types';

import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { mapListToTags } from 'app/services/api';
import { defaultBaseQuery } from 'app/services/admin/api';

export const BillingModes = [
    'developers',
    'usage'
] as const;

export type BillingMode = typeof BillingModes[number];

export const InvoiceTypes = [
    'manual',
    'card'
] as const;

export type InvoiceType = typeof InvoiceTypes[number];

export interface OrganizationCount {
    activeOrganizationUsersCount: number;
}

export interface Account {
    firstName: null|string;

    lastName: null|string;

    email: string;
}

export interface ListParams extends BaseListParams {
    search?: string;
}

export interface ListItem extends OrganizationCount {
    id: string;

    // @review used for admin billing links, remove after moving to hashids for organization
    realId: number;

    name: string;

    accountOwner: Account;

    billingMode: BillingMode;

    invoiceType: InvoiceType;

    isGated: boolean;
};

export interface Item extends OrganizationCount {
    id: string;

    name: string;

    accountOwner: Account;

    billingMode: BillingMode;

    invoiceType: InvoiceType;

    isGated: boolean;
};

export type Edit = Partial<Omit<Item, 'id'|'accountOwner'>>;

export interface Create {
    name: string;

    accountOwner?: number;

    billingMode: BillingMode;

    invoiceType: InvoiceType;

    isGated: boolean;
};

export const NewOrganization: Create = {
    name: '',

    isGated: false,

    billingMode: 'usage',

    invoiceType: 'card',
};

export const TAG = 'organizations';

export const adminOrganizationsApi = createApi({
    reducerPath: `api:admin:${TAG}`,

    baseQuery: defaultBaseQuery,

    tagTypes: [TAG],

    endpoints: (build) => ({
        list: build.query<HydraListResponse<ListItem>, ListParams>({
            query: (params) => {
                return {
                    url: '/organizations',
                    params,
                };
            },
            providesTags: (result) => {
                return mapListToTags({
                    list: result ? result['hydra:member'] : [],
                    idKey: 'id',
                    tag: TAG,
                    includeTag: true,
                    includePartialId: true,
                });
            },
        }),

        create: build.mutation<Item, Create>({
            query: (data) => {
                return {
                    url: `/organizations`,
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: (result) => {
                return mapListToTags({
                    list: result ? [{id: result.id}] : [],
                    idKey: 'id',
                    tag: TAG,
                    includePartialId: true,
                });
            },
        }),

        get: build.query<Item, string>({
            query: (id) => `/organizations/${id}`,
            providesTags: (result) => {
                return mapListToTags({
                    list: result ? [result] : [],
                    idKey: 'id',
                    tag: TAG,
                });
            },
        }),

        edit: build.mutation<Item, {id: string, data: Edit}>({
            query: ({id, data}) => {
                return {
                    url: `/organizations/${id}`,
                    method: 'PUT',
                    body: data,
                };
            },
            invalidatesTags: (result, error, args) => {
                return mapListToTags({
                    list: result ? [{id: args.id}] : [],
                    idKey: 'id',
                    tag: TAG,
                });
            },
        }),

        delete: build.mutation<null, string>({
            query: (id) => {
              return {
                url: `organizations/${id}`,
                method: 'DELETE',
              }
            },
            invalidatesTags: (result, error, id) => {
                return mapListToTags({
                    list: result ? [{id}] : [],
                    idKey: 'id',
                    tag: TAG,
                    includePartialId: true,
                });
            },
        }),
    }),
});

export type AsyncListResult = InferEndpointResult<typeof adminOrganizationsApi.endpoints.list>;
