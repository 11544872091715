import styled, { css } from 'styled-components';

const StyledDialogCreateProject = styled.div`
  ${({ theme }) => {
    const { palette } = theme;

    return css`
      .error-container {
        .ant-typography {
          color: ${palette.error[0]};
        }
      }
      p{
        line-height: 14px;
      }
      .form-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .cancel-btn {
          margin-right: 22px;
        }
      }
    `;
  }}
`;

StyledDialogCreateProject.displayName = 'StyledDialogCreateProject';
export default StyledDialogCreateProject;
