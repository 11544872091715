import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isBlank } from 'utils';

const WithLogin = (ChildComponent) => {
  if (isBlank(ChildComponent)) {
    throw new Error('No component provided to HOC');
  }

  class LoginWrapper extends PureComponent {
    render() {
      const { isLoggedIn } = this.props;
      return isLoggedIn ? <ChildComponent {...this.props} /> : null;
    }
  }

  LoginWrapper.defaultProps = {};

  LoginWrapper.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
  };

  const mapStateToProps = ({ userManager }) => ({
    isLoggedIn: userManager.isLoggedIn,
  });

  return connect(mapStateToProps)(LoginWrapper);
};

export default WithLogin;
