import styled, { css } from 'styled-components';
import { Grid } from 'react-flexbox-grid';

const StyledProjectListing = styled(Grid)`
  ${({ theme }) => {
    const { palette } = theme;

    return css`
      &&& {
        padding: 0;
      }
      .project-block {
        margin-bottom: 24px;
        border: 1px solid ${palette.border[0]};
        border-radius: 4px;
        #dark & {
          background-color: ${palette.background[0]};
          border: 1px solid ${palette.border[4]};
          &:hover{
            background-color: ${palette.background[1]};
          }
        }
        &.ant-card-hoverable {
          &:hover {
            border-color: #220596;
            box-shadow: none;
            .block-header {
              .block-logo {
                .default{
                  display: none;
                }
                .active{
                  display: block;
                }
                #dark & {
                  .active{
                    display: none;
                  }
                  .default{
                    display: none;
                  }
                  .dark{
                    display: block
                  }
                }
              }
              
            }
          }
        }
        .block-header {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          .block-logo {
            width: 34px;
            height: 34px;
            .icon{
              width: 34px;
              height: 34px;
            }
            .default{
              display: block;
            }
            .active{
              display: none;
            }
            .dark {
              display: none;
            }
            #dark & {
              .default{
                display: none;
              }
              .active{
                display: none;
              }
              .dark {
                display: block;
              }
            }
          }
          .block-settings {
            padding: 0;
            border: none;

            &[disabled] {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
        }
        .securityLink {
          &[disabled] {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
        .block-body {
          .block-name {
            color: ${palette.grays[1]};
            line-height: 14px;
            margin-bottom: 24px;
            
            display: flex;
            width: calc(100% - 30px);

            #dark & {
              color: ${palette.grays[10]};
            }
          }
        }
        .block-footer {
          display: flex;
          align-items: center;
          .block-stacks {
            margin-right: 24px;
            display: flex;
            align-items: center;
            color: ${palette.grays[1]};
            line-height: 16px;
            #dark & {
              color: ${palette.grays[10]};
            }
          }
          .block-clock {
            display: flex;
            align-items: center;
            color: ${palette.grays[1]};
            line-height: 16px;
            #dark & {
              color: ${palette.grays[10]};
            }
          }
        }
      }

      .row {
        margin-bottom: 32px;
      }
    `;
  }}
`;

StyledProjectListing.displayName = 'StyledProjectListing';
export default StyledProjectListing;
