import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Layout as LayoutANTD } from 'antd';
import { handleHideSidebarTopbar } from 'actions/global';
import { handleToggleAll } from 'modules/sidebar/actions';
import useWindowSize from 'theme/utility/useWindowSize';
import StyledLayout from './Layout.styles';

export const sidebarFullWidthBreakpoint = 1800;

const { Content } = LayoutANTD;
const styles = {
  layout: { flexDirection: 'row', overflowX: 'hidden' },
  content: {
    padding: '64px 0 0',
    flexShrink: '0',
    background: '#FFFFFF',
    position: 'relative',
  },
};

const loginPath = ['login'];
const registerBillingPath = ['billing-registration'];
const registerPath = ['register'];
const emailConfirmationPath = ['register-confirm-email'];
const inviteAcceptPath = ['invite-accept'];
const githubInstallID = ['github-installation-id'];
const recoverPasswordPath = ['reset-password'];
const appCreatePath = ['projects', 'environments', 'applications', 'create'];
const envConfiguration = ['projects', 'environments', 'configuration'];
const templateConfiguration = ['templates', 'configuration'];

const Layout = ({ children, appHeight, toolbar, sidebar, fullPage, actions }) => {
  const { width, height } = useWindowSize();
  const { pathname } = useLocation();

  const intervalCount = useRef(0);
  const forceSidebarFullWidth = width > sidebarFullWidthBreakpoint;

  const [mainLayoutPadding, setMainLayoutPadding] = useState(
    document.querySelector('.isomorphicSidebar')?.offsetWidth || 74
  );

  useEffect(() => {
    // sets padding for main layout based on sidebar width 
    const interval = setInterval(() => {
      const target = document.querySelector('.isomorphicSidebar');

      if (target) {
        intervalCount.current += 1;
        setMainLayoutPadding(forceSidebarFullWidth ? target.offsetWidth : 74);
      }

      if (intervalCount.current >= 5) {
        clearInterval(interval);
      }
    }, 100);

    setMainLayoutPadding(forceSidebarFullWidth ? document.querySelector('.isomorphicSidebar')?.offsetWidth || 74 : 74);

    return () => {
      clearInterval(interval);
    };
  }, [width, forceSidebarFullWidth]);

  useEffect(() => {
    actions.handleToggleAll(width, height);
  }, [width, height, actions]);

  useEffect(() => {
    /**
     * @TODO
     * this pathname matching is dangerous
     */
    // used to set fullscreen pages (hides navbar)
    const hideSidebarTopBar =
      loginPath.every((term) => pathname.includes(term)) ||
      registerPath.every((term) => pathname.includes(term)) ||
      registerBillingPath.every((term) => pathname.includes(term)) ||
      emailConfirmationPath.every((term) => pathname.includes(term)) ||
      inviteAcceptPath.every((term) => pathname.includes(term)) ||
      recoverPasswordPath.every((term) => pathname.includes(term)) ||
      envConfiguration.every((term) => pathname.includes(term)) ||
      templateConfiguration.every((term) => pathname.includes(term)) ||
      appCreatePath.every((term) => pathname.includes(term)) ||
      githubInstallID.every((term) => pathname.includes(term));
    if (fullPage !== hideSidebarTopBar) {
      actions.handleHideSidebarTopbar(hideSidebarTopBar);
    }
  }, [pathname, fullPage, actions]);

  return (
    <StyledLayout className={forceSidebarFullWidth ? 'static-sidebar' : ''}>
      {/*<WithLoader className="layout-loader" showLoader={isLoading} size="large">*/}
      <LayoutANTD>
        {toolbar}
        <LayoutANTD>
          {sidebar}
          <LayoutANTD className="layout-content" style={{ height: appHeight, paddingLeft: mainLayoutPadding }}>
            <Content style={styles.content}>
              <div className="content">{children}</div>
            </Content>
          </LayoutANTD>
        </LayoutANTD>
      </LayoutANTD>
      {/*</WithLoader>*/}
    </StyledLayout>
  );
};

Layout.defaultProps = {
  children: null,
  toolbar: null,
  sidebar: null,
};

Layout.propTypes = {
  children: PropTypes.node,
  toolbar: PropTypes.node,
  sidebar: PropTypes.node,
  appHeight: PropTypes.number.isRequired,
  fullPage: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    handleToggleAll: PropTypes.func,
    handleHideSidebarTopbar: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = ({ userManager, sidebar, global }) => {
  return {
    isLoading: userManager.isLoading,
    isLoggedIn: userManager.isLoggedIn,
    appHeight: sidebar.height,
    fullPage: global.fullPage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      handleToggleAll,
      handleHideSidebarTopbar,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
