import styled, { css } from 'styled-components';

const StyledErrorPage = styled.div`
  ${({theme}) => {
    const {palette} = theme;
    return css`
    padding-top: 136px;
    #dark & {
      background-color: ${palette.background[0]};
      height: 100vh;
    }
      .error-page-wrapper {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        
        .page-error-img {
          position: relative;
          margin-bottom: 30px;
          width: 100px;
          height: 100px;
          background: #ece7fa;
          border-radius: 50%;
          .page-svg {
            position: absolute;
            top: 50%;
            transform: translateY(-55%);
            left: 0;
            right: 0;
            margin: 0 auto;
          }
        }

        .page-error-title {
          margin-bottom: 12px;
          color: #222222;
          font-family: 'Circular-Bold';
          font-size: 20px;
          line-height: 20px;
          letter-spacing: -0.02em;
        }

        .page-error-description {
          color: #8e8e90;
          margin-bottom: 30px;
          max-width: 380px;
          font-weight: 450;
          font-size: 16px;
          line-height: 22.4px;
          text-align: center;
        }

        .page-error-body button {
          font-weight: 700;
          font-size: 14px;
          height: 34px;
          line-height: 34px;
          min-width: 116px;
          &:nth-child(2) {
            margin-left: 15px;
          }
        }

        .ant-btn.ant-btn-primary {
          > span {
            font-family: "Circular-Bold",sans-serif !important;
          }
        }
      }
    `;
  }}
`;

StyledErrorPage.displayName = 'StyledErrorPage';
export default StyledErrorPage;
