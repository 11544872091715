import type { HydraListResponse, ListParams as BaseListParams } from 'types';
import type { InferEndpointResult } from 'app/services/types';

import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { mapListToTags } from 'app/services/api';
import { defaultBaseQuery } from 'app/services/admin/api';

export const RegistrationSteps = [
    'confirm_email',
    'build_trial',
    'done',
] as const;

export type RegistrationStep = typeof RegistrationSteps[number];

export interface ListParams extends BaseListParams {
    search?: string;
    organization?: string;
}

export interface ListItem {
    id: string;

    firstName: null|string;

    lastName: null|string;

    email: string;

    registrationStep: RegistrationStep;
};

export interface Item {
    id: string;

    firstName: null|string;

    lastName: null|string;

    email: string;

    registrationStep: RegistrationStep;
};

export type Edit = Partial<Item> & {
    password?: string;
};

export interface Create {
    email: string;

    password: string;

    firstName?: string;

    lastName?: string;

    registrationStep?: RegistrationStep;
};

export const NewUser: Create = {
    email: '',

    password: '',

    firstName: '',

    lastName: '',

    registrationStep: 'confirm_email',
};

export const TAG = 'users';

export const adminUsersApi = createApi({
    reducerPath: `api:admin:${TAG}`,

    baseQuery: defaultBaseQuery,

    tagTypes: [TAG],

    endpoints: (build) => ({
        list: build.query<HydraListResponse<ListItem>, ListParams>({
            query: (params) => {
                return {
                    url: 'users',
                    params,
                };
            },
            providesTags: (result) => {
                return mapListToTags({
                    list: result ? result['hydra:member'] : [],
                    idKey: 'id',
                    tag: TAG,
                    includeTag: true,
                    includePartialId: true,
                });
            }
        }),

        create: build.mutation<Item, Create>({
            query(data) {
                return {
                    url: `/users`,
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: (result) => {
                return mapListToTags({
                    list: result ? [{id: result.id}] : [],
                    idKey: 'id',
                    tag: TAG,
                    includePartialId: true,
                });
            },
        }),

        get: build.query<Item, string>({
            query: (id) => `/users/${id}`,
            providesTags: (result) => {
                return mapListToTags({
                    list: result ? [result] : [],
                    idKey: 'id',
                    tag: TAG,
                    includeTag: true,
                });
            },
        }),

        edit: build.mutation<Item, {id: string, data: Partial<Item>}>({
            query({id, data}) {
                return {
                    url: `/users/${id}`,
                    method: 'PUT',
                    body: data,
                };
            },
            invalidatesTags: (result, error, args) => {
                return mapListToTags({
                    list: result ? [{id: args.id}] : [],
                    idKey: 'id',
                    tag: TAG,
                });
            },
        }),

        delete: build.mutation<null, string>({
            query(id) {
              return {
                url: `/users/${id}`,
                method: 'DELETE',
              }
            },
            invalidatesTags: (result, error, id) => {
                return mapListToTags({
                    list: result ? [{id}] : [],
                    idKey: 'id',
                    tag: TAG,
                    includePartialId: true,
                });
            },
        }),
    }),
});

export type AsyncListResult = InferEndpointResult<typeof adminUsersApi.endpoints.list>;
