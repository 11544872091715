import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BillingPage } from 'modules/meeting/BillingPage';

export const routes = {
    billing: '/:organizationId/meeting/billing',
};

export const routing = {
    billing: (organizationId: number) => `/${organizationId}/meeting/billing`,
}

export function Router(): React.ReactElement {
    return <Switch>
        <Route
            key={routes.billing}
            path={routes.billing}
            component={BillingPage}
            exact
        />
    </Switch>;
}
