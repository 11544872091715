import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from 'theme/globalStyle';
import GlobalBorderLoaderStyles from 'theme/globalBorderLoaderStyle';
import themes from 'theme/uielements/config/theme.config';

const ThemeManager = ({ children, mode }) => (
  <ThemeProvider theme={themes.defaultTheme}>
    <GlobalStyles mode={mode} />
    <GlobalBorderLoaderStyles mode={mode} />
    {children}
  </ThemeProvider>
);

ThemeManager.propTypes = {
  mode: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
const mapStateToProps = ( state ) => {

  const { mode } = state.global;
  return {
    mode
  };
};
export default connect(mapStateToProps, null)(ThemeManager);
