import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bunnyshellLogoFull, bunnyshellLogoFullWhite } from 'assets/images';

const Logo = ({
  mode,
  collapsed,
  organizationId,
  lastUsedProjectId,
  closeOpenMenu,
}) => {
  const getLogoURL = () =>{
    if (lastUsedProjectId) {
      return `/${organizationId}/projects/${lastUsedProjectId}`
    }
    
    if (organizationId) {
      return `/${organizationId}/projects`
    }

    return '/';
  }

  return (
    <div className={`isoLogoWrapper ${collapsed ? 'collapsed' : ''}`}>
      <h3>
        <Link
          onClick={closeOpenMenu}
          to={getLogoURL()}
        >
          <div className="imageSizeWrapper">
            <img
              src={mode === 'light' ? bunnyshellLogoFull : bunnyshellLogoFullWhite}
              alt="Logo bunnyshell"
              height="32"
            />
          </div>
        </Link>
      </h3>
    </div>
  );
};

Logo.defaultProps = {
  mode: 'light',
  collapsed: true,
  organizationId: null,
  lastUsedProjectId: null,
  closeOpenMenu: () => {},
};

Logo.propTypes = {
  mode: PropTypes.string,
  collapsed: PropTypes.bool,
  organizationId: PropTypes.number,
  lastUsedProjectId: PropTypes.number,
  closeOpenMenu: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { currentOrganization, lastUsedOrganization, lastUsedProject } = state.userManager;
  const orgId = parseInt(lastUsedOrganization?.id || currentOrganization, 10);

  return {
    mode: state.global.mode,
    organizationId: orgId,
    lastUsedProjectId: lastUsedProject?.id || null,
  };
};

export default connect(mapStateToProps, null)(Logo);
