import { createPrefixedAction } from '../config';
import actionTypes from './actionTypes';

export const toggleAll = createPrefixedAction(actionTypes.TOGGLE_ALL);
export const toggleOpenDrawer = createPrefixedAction(actionTypes.COLLPSE_OPEN_DRAWER);

export const getView = (width) => {
  let newView = 'MobileView';
  if (width > 1200) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
};

export const handleToggleAll = (width, height) => (dispatch) => {
  const view = getView(width);
  const collapsed = view !== 'DesktopView';
  dispatch(toggleAll({ collapsed, view, height }));
};
