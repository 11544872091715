const initialState = {
  isLoadingPipeline: false,
  isLoadingPipelineJob: true,
  isLoadingEventDetails: true,
  pipelineData: {
    jobs: [],
  },
  pipelineJobData: {},
  pipeJobTopic: '',
  updatePipelineResourceId: '',
  updatePipelineJobResourceId: '',
  event: {},
};

export default initialState;
