const actionTypes = {
  GET_GIT_INTEGRATIONS: 'GET_GIT_INTEGRATIONS',
  CONNECT_GIT: 'CONNECT_GIT',
  CONNECT_AWS_ECR: 'CONNECT_AWS_ECR',
  CONNECT_TEAMS_INTEGRATION: 'CONNECT_TEAMS_INTEGRATION',
  EDIT_AWS_ECR: 'EDIT_AWS_ECR',
  EDIT_TEAMS_INTEGRATION: 'EDIT_TEAMS_INTEGRATION',
  GIT_RESPONSE: 'GIT_RESPONSE',
  GIT_DISCONNECT: 'GIT_DISCONNECT',
  GET_CLOUD_PROVIDERS: 'GET_CLOUD_PROVIDERS',
  ORG_REGISTRY_INTEGRATIONS: 'ORG_REGISTRY_INTEGRATIONS',
  ORG_TEAMS_INTEGRATIONS: 'ORG_TEAMS_INTEGRATIONS',
  GET_ORGANIZATION_GIT_INTEGRATIONS: 'GET_ORGANIZATION_GIT_INTEGRATIONS',
  GET_INTEGRATION_REPOSITORIES: 'GET_INTEGRATION_REPOSITORIES',
  CONNECT_KUBERNETES: 'CONNECT_KUBERNETES',
  VERIFY_KUBERNETES: 'VERIFY_KUBERNETES',
  VERIFY_REGISTRY: 'VERIFY_REGISTRY',
  DETACH_KUBERNETES: 'DETACH_KUBERNETES',
  DETACH_ECR_REGISTRY: 'DETACH_ECR_REGISTRY',
  REMOVE_TEAMS_INTEGRATION: 'REMOVE_TEAMS_INTEGRATION',
  DEFAULT_ECR_REGISTRY: 'DEFAULT_ECR_REGISTRY',
  CONNECT_PRIVATE_GIT: 'CONNECT_PRIVATE_GIT',
  SYNC_REPOS: 'SYNC_REPOS',
  GET_GIT_ENV_USAGE: 'GET_GIT_ENV_USAGE',
  RESET_ERROR_MESSAGE: 'RESET_ERROR_MESSAGE',
  SET_PRIVATE_GIT_ERROR_MESSAGE: 'SET_PRIVATE_GIT_ERROR_MESSAGE',
  EDIT_CLUSTER: 'EDIT_CLUSTER',
  GET_GIT_INTEGRATION: 'GET_GIT_INTEGRATION',
  GET_REPOSITORY: 'GET_REPOSITORY',
  GET_SLACK_INTEGRATIONS: 'GET_SLACK_INTEGRATIONS',
  CONNECT_SLACK_INTEGRATION: 'CONNECT_SLACK_INTEGRATION',
  SLACK_RESPONSE: 'SLACK_RESPONSE',
  GET_SLACK_CHANNELS: 'GET_SLACK_CHANNELS',
  SAVE_CHANNEL: 'SAVE_CHANNEL',
  DELETE_SLACK_INTEGRATION: 'DELETE_SLACK_INTEGRATION',
  GET_KUBERNETES_CLUSTER: 'GET_KUBERNETES_CLUSTER',
  ADD_WORKSHOP_CLUSTER: 'ADD_WORKSHOP_CLUSTER',
};

export default actionTypes;
