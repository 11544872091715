import { Input } from 'antd';
import {
  InputWrapper,
  InputGroupWrapper,
  InputSearchWrapper,
  TextAreaWrapper,
} from './styles/input.style';
import WithDirection from '../helpers/rtl';

const { Search, TextArea, Group, Password: PassWord } = Input;

const WDStyledInput = InputWrapper(Input);
const StyledInput = WithDirection(WDStyledInput);

const WDInputGroup = InputGroupWrapper(Group);
const InputGroup = WithDirection(WDInputGroup);

const WDInputSearch = InputSearchWrapper(Search);
const InputSearch = WithDirection(WDInputSearch);

const WDTextarea = TextAreaWrapper(TextArea);
const Textarea = WithDirection(WDTextarea);

const WDPassword = TextAreaWrapper(PassWord);
const Password = WithDirection(WDPassword);

// @deprecated Use named export instead
export default StyledInput;
export {
  StyledInput,
  InputSearch,
  InputGroup,
  Textarea,
  Password,
};
