/* eslint-disable max-lines */
import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import {
  getProjects
} from 'modules/projects/actions';
import {
  createAccessToken,
  getAccountDetails,
  getAccountOwnerOrganizations,
  updateUserPreferences,
  getCurrentOrganization,
  createOrganization,
  setUserOrganizations,
  logOut,
  setCurrentProject,
  addProjectToList,
  deleteProjectFromList,
  updateProjectFromList,
  createAccount,
  createBillingProfile,
  confirmEmail,
  getUnregisterUser,
  profileCreate,
  setupIntent,
  getBillingPlans,
  updateUserOrganizations,
  resendConfirmEmail,
  resetState,
  recoverPassword,
  resetPassword,
  checkRecoverToken,
  setIsLogging,
  gitSignOnResponse, gitSignOn,
  setInvalidPasswordError, setLoginErrorMessage, setRegisterErrorMessage
} from '../actions';

import { getLastUsedOrganization, getLastUsedProject} from '../helpers';
import initialState from './initialState';

const getProjectsHandler = [
  getProjects, (state, action) => {
    const { ready, error, payload } = action;
    
    if (!ready) {
      return {
        ...state,
      };
    }

    if (error) {
      return state;
    }

    return {
      ...state,
      projects: payload['hydra:member'],
    };
  },
];
const addProjectHandler = [
  addProjectToList, (state, action) => {
    const {payload} = action;
    const newProjectList = R.append(payload)(state.projects)
    return {
      ...state,
      projectsNo: newProjectList.length,
      projects: newProjectList,
    }
  }
];
const deleteProjectHandler = [
  deleteProjectFromList, (state, action) => {
    const {payload} = action;
    const newProjectsList = state.projects.filter((item)=>{return item.id !== parseFloat(payload)})

    return {
      ...state,
      projectsNo: newProjectsList.length,
      projects: newProjectsList,
    }
  }
];
const updateProjectHandler = [
  updateProjectFromList, (state, action) => {
    const {payload} = action;
    const index = R.findIndex(R.propEq('id', payload.id))(state.projects);
    let update;
    if(index > -1){
      update = R.map(R.ifElse(R.propEq('id', state.projects[index].id), R.assoc('name', payload.name), (item) => item))
    }

    return {
      ...state,
      projects: update(state.projects),
    }
  }
];


const createAccessTokenHandler = [
  createAccessToken, (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoginLoading: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoginLoading: false,
        hasAccessToken: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }
    if(payload){
      return {
        ...state,
        isLoginLoading: true,
        hasAccessToken: true,
        errorMessage: '',
      };
    }
    return {
      ...state,
      isLoginLoading: false,
      hasAccessToken: false,
      errorMessage: ''
    }
  },
];
const setIsLoggingHandler = [
  setIsLogging, (state, action) => {
    const {payload} = action;
    return {
      ...state,
      isLoginLoading: payload
    }
  }
]
const getAccountDetailsHandler = [
  getAccountDetails, (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
        isLoginLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false, isLoggedIn: false, isLoginLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }
    const { userData, lastUsedOrganization, lastUsedProject, environmentColumn , deploymentColumn} = payload;
    const organizations = R.prop('organizations', userData);

    return {
      ...state,
      isLoading: false,
      isLoggedIn: true,
      isAdmin: R.prop('isAdmin', userData),
      userId: R.prop('id', userData),
      firstName: R.prop('firstName', userData),
      lastName: R.prop('lastName', userData),
      phoneNumber: R.prop('phoneNumber', userData),
      email: R.prop('email', userData),
      organizationUserIds: R.prop('organizationUserIds', userData),
      organizations,
      emptyOrganizationsList: R.isEmpty(organizations),
      organizationRoles: R.prop('organizationRoles', userData),
      environmentListingColumns: environmentColumn,
      deploymentListingColumns: deploymentColumn,
      lastUsedOrganization: lastUsedOrganization ? getLastUsedOrganization(lastUsedOrganization):null,
      lastUsedProject: lastUsedProject ? getLastUsedProject(lastUsedProject) : null,
      errorMessage: '',
      intercomExternalId: R.prop('intercomExternalId', userData),
      intercomUserHash: R.prop('intercomUserHash', userData),
    };
  },
];

const getAccountOwnerOrganizationsHandler = [
  getAccountOwnerOrganizations, (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
      };
    }

    if (error) {
      return {
        ...state,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }
    return {
      ...state,
      errorMessage: '',
    };
  },
];

const updateUserPreferencesHandler = [
  updateUserPreferences, (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
        isUpdatingPreferences: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        isUpdatingPreferences: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }
    const { userData, data } = payload;
    return {
      ...state,
      isLoginLoading: false,
      isLoading: false,
      isUpdatingPreferences: false,
      errorMessage: '',
      firstName: userData ? R.prop('firstName', userData) : state.firstName,
      lastName: userData ? R.prop('lastName', userData): state.lastName,
      email: userData ? R.prop('email', userData) : state.email,
      environmentListingColumns: data.environmentListingColumns,
      deploymentListingColumns: data.deploymentListingColumns,
      lastUsedOrganization: getLastUsedOrganization(data.lastUsedOrganization),
      lastUsedProject: data.lastUsedProject ? getLastUsedProject(data.lastUsedProject) : null
    };
  },
];

const logOutHandler = [
  logOut, (state) => {
    return {
      ...state,
      hasAccessToken: false, isLoading: false, isLoggedIn: false,
    };
  },
];
const getCurrentOrganizationHandler = [
  getCurrentOrganization, (state, action) => {
    const { payload } = action;
    return {
      ...state, currentOrganization: payload,
    };
  },
];
const setCurrentProjectHandler = [
  setCurrentProject, (state, action) => {
    const {payload} = action;
    return {
      ...state, currentProject: payload,
    }
  }
];

const setUserOrganizationsHandler = [
  setUserOrganizations, (state, action) => {
    const {payload} = action;

    /** concat arrays and deduplicate */
    const deduplicate = R.pipe(
      R.map(R.indexBy(R.prop('id'))),
      R.reduce(R.mergeWith(R.merge), {}),
      R.values
    );
    
    const organizations = deduplicate([state.organizations, payload]);
    
    return {
      ...state,
      organizations
    }
  }
];
const updateUserOrganizationsHandler = [
  updateUserOrganizations, (state, action) => {
    const {   payload } = action;
    return {
      ...state, isLoading: false, organizations: payload,
    };
  },
];
const getBillingPlansHandler = [
  getBillingPlans, (state, action) => {
    const { ready,  payload } = action;
    if (!ready) {
      return {
        ...state,
      };
    }
    return {
      ...state,
      isLoading: false,
      billingPlans: payload,
    };
  },
];
const createAccountHandler = [
  createAccount, (state, action) => {
    const { ready, error,  payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        hasAccount: false,
        errorMessage: R.path(['response', 'data'], payload),
      };
    }
    return {
      ...state, isLoading: false, errorMessage: null,
      hasAccount: payload
    };
  },
];
const createBillingProfileHandler = [
  createBillingProfile, (state, action) => {
    const { ready } = action;
    if (!ready) {
      return {
        ...state,
      };
    }
    return {
      ...state,
      isLoading: false,
    };
  },
];
const confirmEmailHandler = [
  confirmEmail, (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }
    return {
      ...state,
      isLoading: false,
    };
  },
];
const resendConfirmEmailHandler = [
  resendConfirmEmail, (state, action) => {
    const { ready, error } = action;
    if (!ready) {
      return {
        ...state,
      };
    }
    if (error) {
      return {
        ...state,
      };
    }
    return {
      ...state,
    };
  },
];
const getUnregisterUserHandler = [
  getUnregisterUser, (state, action) => {
    const {ready, payload } = action;
    if (!ready) {
      return {
        ...state,
        //isLoading: true,
      };
    }
    return {
      ...state,
      isLoggedIn: true,
      isAdmin: R.prop('isAdmin', payload),
      userId: R.prop('id', payload),
      firstName: R.prop('firstName', payload),
      lastName: R.prop('lastName', payload),
      email: R.prop('email', payload),
      initialBillingPlan: R.prop('initialBillingPlan', payload),
      organizations: R.prop('organizations', payload),
      organizationRoles: R.prop('organizationRoles', payload),
      errorMessage: '',
    };
  },
];
const setupIntentHandler = [
  setupIntent, (state, action) => {
    const { error, payload } = action;
    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }
    return {
      ...state,
      setupIntent: payload,
    };
  },
];
const profileCreateHandler = [
  profileCreate, (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }
    return {
      ...state,
      isLoading: false,
      lastUsedOrganization: getLastUsedOrganization(payload.data.organization),
      lastUsedProject: null
    };
  }
];
/** recover email handler */
const recoverPasswordHandler = [
  recoverPassword, (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
        emailErrorData: R.path(['response', 'data'], payload),
      };
    }
    return {
      ...state,
      isLoading: false, recoverPasswordSteps: 1, emailErrorData: {violations: []}
    };
  }
];
/** check recover token eligibility */
const recoverTokenHandler = [
  checkRecoverToken, (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
        isCheckingRecoverToken: true
      };
    }
    if (error) {
      return {
        ...state,isLoading: false,isCheckingRecoverToken: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
        recoverPasswordSteps: 3
      };
    }
    return {
      ...state,
      isLoading: false,
      isCheckingRecoverToken: false,
      errorMessage: '',
    };
  }
];
/** reset password */
const resetPasswordHandler = [
  resetPassword, (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        isWaitingForAutoLogin: false,
        errorMessage: R.path(['response', 'data'], payload)
      };
    }
    return {
      ...state,
      isLoading: false,
      isWaitingForAutoLogin: true,
    };
  }
];
const createOrganizationHandler = [
  createOrganization, (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isCreatingOrganization: true,
      };
    }
    if (error) {
      return {
        ...state,
        isCreatingOrganization: false,
      };
    }
    return {
      ...state,
      isCreatingOrganization: false,
      organizations: R.append(payload, state.organizations),
    };
  },
];

const gitSignOnHandler = [
  gitSignOn, (state, action) => {
    const { ready, error } = action;
    if (!ready) {
      return {
        ...state,
        isSSOLoading: true,
      };
    }
    if (error) {
      return {
        // eslint-disable-next-line max-lines
        ...state,
        isSSOLoading: false,
      };
    }
    return {
      ...state,
      isSSOLoading: false,
    };
  },
];

const gitSignOnResponseHandler = [
  gitSignOnResponse, (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
        ssoViolations: [],
        ssoErrorMessage: null,
      };
    }
    if (error) {
      return {
        // eslint-disable-next-line max-lines
        ...state,
        isLoading: false,
        ssoErrorCode: R.path(['response', 'status'], payload),
        ssoViolations: R.path(['response', 'data', 'violations'], payload) || []
      };
    }
    return {
      ...state,
      isLoading: false,
      ssoErrorCode: null,
      ssoViolations: [],
    };
  },
];

const setInvalidPasswordErrorHandler = [
  setInvalidPasswordError, (state) => {
    return {
      ...state,
      invalidPasswordError: true,
    }
  }
]
const setLoginErrorMessageHandler = [
  setLoginErrorMessage, (state, action) => {
    const { payload } = action;

    return {
      ...state,
      gitLoginErrorMessage: payload,
    }
  }
]
const setRegisterErrorMessageHandler = [
  setRegisterErrorMessage, (state, action) => {
    const { payload } = action;

    return {
      ...state,
      gitRegisterErrorMessage: payload,
    }
  }
]
const resetStateHandler = [
  resetState, (state) => {
    return{
      ...initialState,
      gitRegisterErrorMessage: state.gitRegisterErrorMessage,
      gitLoginErrorMessage: state.gitLoginErrorMessage,
    }
  }];
const reducer = handleActions(
  new Map([
    getProjectsHandler,
    addProjectHandler,
    createAccessTokenHandler,
    getAccountDetailsHandler,
    getAccountOwnerOrganizationsHandler,
    updateUserPreferencesHandler,
    getCurrentOrganizationHandler,
    logOutHandler,
    setCurrentProjectHandler,
    setUserOrganizationsHandler,
    deleteProjectHandler,
    updateProjectHandler,
    getBillingPlansHandler,
    gitSignOnHandler,
    gitSignOnResponseHandler,
    createAccountHandler,
    createBillingProfileHandler,
    confirmEmailHandler,
    getUnregisterUserHandler,
    profileCreateHandler,
    setupIntentHandler,
    resetStateHandler,
    recoverPasswordHandler,
    resetPasswordHandler,
    recoverTokenHandler,
    createOrganizationHandler,
    updateUserOrganizationsHandler,
    resendConfirmEmailHandler,
    setIsLoggingHandler,
    setInvalidPasswordErrorHandler,
    setLoginErrorMessageHandler,
    setRegisterErrorMessageHandler
  ]),
  R.clone(initialState)
);

export default reducer;
