const initialState = {
  isLoadingRepositoriesList: true,
  isLoadingPublicTemplates: true,
  isLoadingCustomTemplates: false,
  isLoadingDefinition: true,
  isLoadingTemplateDetails: true,
  isAddingRepository: false,
  isDeletingRepository: false,
  templateDetails: {},
  templateDefinition: ``,
  repositories: [],
  templatesObjectList: {},
  templateRepositoryTopics: [],
  customRepositoriesCount: 0,
  updateTemplatesRepoResourceId: -1,
  publicTemplates: {
    totalItems: 0,
    templates: [],
  },
  violations: [],
};

export default initialState;
