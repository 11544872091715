/* eslint-disable max-lines,max-params */
import * as R from 'ramda';
import { hideDialog, showNotification } from 'actions/global';
import { TOAST_TYPES } from 'components';
import { API, DELETE, GET, POST, PUT } from 'config';
import {setOpenWindowLocation, log, getErrorMessage, refreshWindowLocation} from 'utils';
import {handleGetOrganizationOwner} from 'modules/settings/actions'
import {handleGetOrganizationDetails} from 'modules/admin/actions'
// import {handleGetOrganizations} from "modules/userManager/actions";
import qs from "qs";
import { createPrefixedAction } from '../config';
import actionTypes from './actionTypes';


const header = {
  'Content-Type': 'application/ld+json',
  Accept: 'application/ld+json',
};

export const getGitIntegrations = createPrefixedAction(actionTypes.GET_GIT_INTEGRATIONS);
export const connectGit = createPrefixedAction(actionTypes.CONNECT_GIT);
export const connectPrivateGit = createPrefixedAction(actionTypes.CONNECT_PRIVATE_GIT);
export const gitResponse = createPrefixedAction(actionTypes.GIT_RESPONSE);
export const gitDisconnect = createPrefixedAction(actionTypes.GIT_DISCONNECT);
export const slackResponse = createPrefixedAction(actionTypes.SLACK_RESPONSE);
export const getCloudProviders = createPrefixedAction(actionTypes.GET_CLOUD_PROVIDERS);
export const getOrganizationGitIntegrations = createPrefixedAction(
  actionTypes.GET_ORGANIZATION_GIT_INTEGRATIONS
);
export const getIntegrationRepositories = createPrefixedAction(
  actionTypes.GET_INTEGRATION_REPOSITORIES
);
export const connectKubernetes = createPrefixedAction(actionTypes.CONNECT_KUBERNETES);
export const connectAmazonEcr = createPrefixedAction(actionTypes.CONNECT_AWS_ECR);
export const editAmazonEcr = createPrefixedAction(actionTypes.EDIT_AWS_ECR);
export const detachEcrRegistry = createPrefixedAction(actionTypes.DETACH_ECR_REGISTRY);
export const defaultEcrRegistry = createPrefixedAction(actionTypes.DEFAULT_ECR_REGISTRY);
export const getOrgRegistryIntegrations = createPrefixedAction(
  actionTypes.ORG_REGISTRY_INTEGRATIONS);
export const connectTeamsIntegration = createPrefixedAction(actionTypes.CONNECT_TEAMS_INTEGRATION);
export const editTeamsIntegration = createPrefixedAction(actionTypes.EDIT_TEAMS_INTEGRATION);
export const removeTeamsIntegration = createPrefixedAction(actionTypes.REMOVE_TEAMS_INTEGRATION);
export const getOrgTeamsIntegrations = createPrefixedAction(actionTypes.ORG_TEAMS_INTEGRATIONS);
export const verifyKubernetes = createPrefixedAction(actionTypes.VERIFY_KUBERNETES);
export const verifyRegistry = createPrefixedAction(actionTypes.VERIFY_REGISTRY);
export const detachKubernetes = createPrefixedAction(actionTypes.DETACH_KUBERNETES);
export const syncRepos = createPrefixedAction(actionTypes.SYNC_REPOS);
export const getEnvUsage = createPrefixedAction(actionTypes.GET_GIT_ENV_USAGE);
export const resetErrorMessage = createPrefixedAction(actionTypes.RESET_ERROR_MESSAGE);
export const setPrivateErrorMessage = createPrefixedAction(actionTypes.SET_PRIVATE_GIT_ERROR_MESSAGE);
export const editCluster = createPrefixedAction(actionTypes.EDIT_CLUSTER);
export const getGitIntegration = createPrefixedAction(actionTypes.GET_GIT_INTEGRATION);
export const getRepository = createPrefixedAction(actionTypes.GET_REPOSITORY);
export const getSlackIntegrations = createPrefixedAction(actionTypes.GET_SLACK_INTEGRATIONS);
export const connectSlackIntegration = createPrefixedAction(actionTypes.CONNECT_SLACK_INTEGRATION);
export const getSlackChannels = createPrefixedAction(actionTypes.GET_SLACK_CHANNELS);
export const saveChannel = createPrefixedAction(actionTypes.SAVE_CHANNEL);
export const deleteSlackIntegration = createPrefixedAction(actionTypes.SAVE_CHANNEL);
export const getKubernetesCluster = createPrefixedAction(actionTypes.GET_KUBERNETES_CLUSTER);
export const addWorkshopCluster = createPrefixedAction(actionTypes.ADD_WORKSHOP_CLUSTER);

export const handleGetGitIntegrations = (organizationId) => async (dispatch) => {
  try {
    await dispatch(
      getGitIntegrations(
        GET(API.organizations.gitIntegrations(organizationId)).then(({ data }) => data)
      )
    );
  } catch (exception) {
    log.error('Git Integration', exception);
  }
};

export const handleGetSlackChannels = (slackId) => async (dispatch) => {
  try {
    await dispatch(
      getSlackChannels(
        GET(API.organizations.getSlackChannels(slackId)).then(({ data }) => {
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Save Slack Integration', exception);
  }
}
export const handleSaveChannel = (slackId, channelId) => async (dispatch) => {
  try {
    await dispatch(
      saveChannel(
        PUT(API.organizations.slackBase(slackId), {channelId}).then(({ data }) => {
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Save Slack channel', exception);
    dispatch(
      showNotification(TOAST_TYPES.error, `Error`, exception)
    );
  }
}
export const handleDeleteSlackIntegration = (slackId, organizationId) => async (dispatch) => {
  try {
    await dispatch(
      deleteSlackIntegration(
        DELETE(API.organizations.slackBase(slackId)).then(({ data }) => {
          dispatch(handleGetOrganizationOwner(organizationId))
          dispatch(hideDialog())
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Delete Slack Integration', exception);
    dispatch(
      showNotification(TOAST_TYPES.error, `Error`, exception)
    );
  }
}

export const handleGetSlackIntegrations = (organizationId) => async (dispatch) => {
  try {
    await dispatch(
      getSlackIntegrations(
        GET(API.organizations.getSlackIntegrations(organizationId)).then(({ data }) => {
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Get Slack Integrations', exception);
  }
};

export const handleConnectSlackIntegration = (organizationId) => async (dispatch) => {
  try {
    await dispatch(
      connectSlackIntegration(
        POST(API.organizations.connectSlack, {organizationId}).then(({ data }) => {
          const { slackConnectUrl } = data;
          setOpenWindowLocation(slackConnectUrl, true);
        })
      )
    );
  } catch (exception) {
    log.error('Connect Slack Integration', exception);
  }
};
export const handleSlackResponse =
  (code, state , organizationId) =>
    async (dispatch) => {
      try {
        const body = {
          exchangeCode: code,
          stateCode: state,
          organizationId: parseInt(organizationId, 10),
        };
        const response = await POST(API.organizations.slackResponse, body, header);
        await dispatch(handleGetOrganizationOwner(organizationId, null, 0));
        return dispatch(slackResponse(response));
      } catch (exception) {
        log.error('Get Slack response', exception);
        throw exception;
      }
    };

export const handleGetOrganizationGitIntegrations = (organizationId) => async (dispatch) => {
  try {
    await dispatch(
      getOrganizationGitIntegrations(
        GET(API.organizations.gitIntegrations(organizationId)).then((data) => data)
      )
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};

export const handleGetIntegrationRepositories = (integrationId) => async (dispatch) => {

  try {
    await dispatch(
      getIntegrationRepositories(
        GET(API.gitIntegrations.getRepositories(integrationId)).then((data) => data)
      )
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};

export const handleGetIntegrationByInstallationId = (installationId) => async (dispatch) => {
    let response = null;

    try {
        await dispatch(
            getIntegrationRepositories(
                GET(API.gitIntegrations.getByInstallationId(installationId)).then((data) => {
                    response = data;
                    return data
                })
            )
        );
    } catch (exception) {
        log.error('Error', exception);
        throw exception;
    }

    return response;
};

export const handleSyncRepos = (integration) => async (dispatch) => {
  try {
    await dispatch(
      syncRepos(
        POST(API.gitIntegrations.syncRepos(integration), {}, {
      'Content-Type': 'application/ld+json',
      Accept: 'application/ld+json',
    }).then((data) => {
          dispatch(handleGetIntegrationRepositories(integration))
          return data
        })
      )
    );
  } catch (exception) {
    log.error('Error', exception);
  }
};

export const handleConnectGit = (organizationId, providerType, reconnectGithubOauth) => async (dispatch, getState) => {

  const { usesGithubApp } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  let { usesGithubApp:usesGithubAppValue } = getState().organization;

  if (R.is(String, usesGithubApp)) {
    if (usesGithubApp === 'false') {
      usesGithubAppValue = false;
    }
    if (usesGithubApp === 'true') {
      usesGithubAppValue = true;
    }
  }
  if (reconnectGithubOauth) {
    usesGithubAppValue = false;
  }

  try {
    const body = {
      organizationId: parseInt(organizationId, 10),
      type: providerType,
      isGithubApp: usesGithubAppValue,
    };
    await dispatch(
      connectGit(
        POST(API.gitIntegrations.connect, body, header).then(({ data }) => {
          const { gitConnectUrl } = data;
          // dispatch(
          //   showNotification(TOAST_TYPES.success, `${providerName} successfully connected`, 'Redirecting to git provider!')
          // );
          setOpenWindowLocation(gitConnectUrl, true);
        })
      )
    );
  } catch (exception) {
    log.error('Git Connect', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Git connection error', getErrorMessage(exception)));
  }
};

export const handleConnectPrivateGit =
  (organizationId, providerType, apiBaseUrl, oauthBaseUrl, oauthClientId, oauthClientSecret) =>
    async (dispatch) => {
  try {
    const body = {
        organization: `/api/organizations/${parseInt(organizationId, 10)}`,
        type: providerType,
        apiBaseUrl,
        oauthBaseUrl,
        oauthClientId,
        oauthClientSecret,
        providerName: ""
    };
    const isAzureOnPremise = providerType === 'azure';

    await dispatch(
      connectPrivateGit(
        POST(API.gitIntegrations.connectPrivate, body, header).then(({ data }) => {
          const { gitConnectUrl } = data;
            dispatch(setPrivateErrorMessage({message: null}))
          // dispatch(
          //   showNotification(TOAST_TYPES.success, `${providerName} successfully connected`, 'Redirecting to git provider!')
          // );
            // Azure DevOps On-Prem is just PAT edit, no Oauth flow to start
            if (isAzureOnPremise) {
                // didn't know a better way just to reload the gitIntegrations list
                refreshWindowLocation();
            } else {
                setOpenWindowLocation(gitConnectUrl, true);
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.data.violations) {
                    dispatch(setPrivateErrorMessage({message: error.response.data}))
                }
            }
            return error;
        })
      )
    );
  } catch (exception) {
    log.error('Private Git Connect', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Git connection error', getErrorMessage(exception)));
  }
};


export const handleEditPrivateGit = (id, apiBaseUrl, oauthBaseUrl, oauthClientId, oauthClientSecret) => async (dispatch) => {
    try {
        const body = {
            apiBaseUrl,
            oauthBaseUrl,
            oauthClientId,
            oauthClientSecret,
        };

        // didn't find a better way to detect :(
        const isAzureOnPremise = !!apiBaseUrl && !!oauthClientSecret && !oauthBaseUrl && !oauthClientId;

        await dispatch(
            connectPrivateGit(
                PUT(API.gitIntegrations.editPrivate(id), body, header).then(({ data }) => {
                    const { gitConnectUrl } = data;
                    dispatch(setPrivateErrorMessage({message: null}))
                    // dispatch(
                    //   showNotification(TOAST_TYPES.success, `${providerName} successfully connected`, 'Redirecting to git provider!')
                    // );
                    // Azure DevOps On-Prem is just PAT edit, no Oauth flow to start
                    if (isAzureOnPremise) {
                        // didn't know a better way just to reload the gitIntegrations list
                        refreshWindowLocation();
                    } else {
                        setOpenWindowLocation(gitConnectUrl, true);
                    }
                }).catch(error => {
                    if (error.response) {
                        if (error.response.data.violations) {
                            dispatch(setPrivateErrorMessage({message: error.response.data}))
                        }
                    }
                    return error;
                })
            )
        );
    } catch (exception) {
        log.error('Private Git reconnect', exception);
        dispatch(showNotification(TOAST_TYPES.error, 'Git connection error', getErrorMessage(exception)));
    }
};

// eslint-disable-next-line max-params
export const handleGitResponse = (code, state, installationId, organizationId, providerId, providerType) =>
  async (dispatch) => {
    try {
      const body = {
        exchangeCode: code,
        stateCode: state,
        installationId,
        organizationId: parseInt(organizationId, 10),
        providerId: parseInt(providerId, 10),
        type: providerType
      };
      const response = await POST(API.gitIntegrations.response, body, header);
      await dispatch(handleGetOrganizationOwner(organizationId, null, 0));
      return dispatch(gitResponse(response));
    } catch (exception) {
      log.error('Git Response', exception);
      throw exception;
    }
  };

export const handleGithubResponseMarketplace = (payload) => {
  return POST(API.gitIntegrations.githubResponseMarketplace, payload, header);
};

export const handleGetEnvUsage = (id) => async (dispatch) => {
  try {
    await dispatch(
      getEnvUsage(
        GET(API.gitIntegrations.remove(id)).then((response) => {
          const envCount = R.path(["data", "envsUsage"], response)
          return envCount;
        })
      )
    );
  } catch (exception) {
    log.error('Git Integration', exception);
  }
};

/** verify ecr */
export const handleVerifyRegistry = (id) => async (dispatch) => {
  try {
    await dispatch(
      verifyRegistry(PUT(API.registryIntegrations.verify(id)).then(({ data }) => data))
    );
  } catch (exception) {
    log.error('Verify Registry', exception);
  }
};

/**  get ecr list */
 export const handleGetOrgRegistryIntegrations = (id) => async (dispatch) => {
  try {
    await dispatch(
      getOrgRegistryIntegrations(
        GET(API.registryIntegrations.organizations(id)).then((response) => {
          return response;
        })
      )
    );
  } catch (exception) {
    log.error('Registry Integration', exception);
  }
};

/** connect ecr */
export const handleConnectRegistry = (organizationId, payload, history) => async (dispatch) => {
  try {
    await dispatch(
      connectAmazonEcr(
        POST(API.registryIntegrations.base, payload).then(({ data }) => {
          dispatch(handleGetOrganizationDetails(organizationId, history));
          dispatch(
            showNotification(
              TOAST_TYPES.success,
              'Container Registry connected successfully',
              `${data.name}  has been successfully connected to your organization.`
            )
          );
          dispatch(hideDialog());
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Connect Registry', exception);
  }
};

/** edit ecr */
export const handleEditRegistry = (id, payload) => async (dispatch) => {
  try {
    await dispatch(
      editAmazonEcr(
        PUT(API.registryIntegrations.edit(id), payload).then((response) => {
          dispatch(hideDialog());
          return response;
        })
      )
    );
  } catch (exception) {
    log.error('Registry Integration', exception);
  }
};

/** remove ecr */
export const handleRemoveRegistry = (id) => async (dispatch) => {
  try {
    await dispatch(
      detachEcrRegistry(
        PUT(API.registryIntegrations.detach(id)).then((response) => {
          return response;
        })
      )
    );
  } catch (exception) {
    log.error('Registry Integration', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Error', getErrorMessage(exception)));
  }
};

/**  get Teams integrations list */
 export const handleGetOrgTeamsIntegrations = (id) => async (dispatch) => {
  try {
    await dispatch(
      getOrgTeamsIntegrations(
        GET(API.teamsIntegrations.organizations(id)).then((response) => {
          return response;
        })
      )
    );
  } catch (exception) {
    log.error('Teams Integration', exception);
  }
};

/** connect Teams webhook */
export const handleConnectTeamsIntegration = (organizationId, payload, history) => async (dispatch) => {
  try {
    await dispatch(
      connectTeamsIntegration(
        POST(API.teamsIntegrations.base, payload).then(({ data }) => {
          dispatch(handleGetOrganizationDetails(organizationId, history));
          dispatch(
            showNotification(
              TOAST_TYPES.success,
              'Teams webhook connected successfully',
              `${data.name} has been successfully connected to your organization.`
            )
          );
          dispatch(hideDialog());
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Connect Teams webhook', exception);
  }
};

/** edit Teams integration */
export const handleEditTeamsIntegration = (id, payload) => async (dispatch) => {
  try {
    await dispatch(
      editTeamsIntegration(
        PUT(API.teamsIntegrations.edit(id), payload).then(({ data }) => {
          dispatch(hideDialog());
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Teams Integration', exception);
  }
};

/** remove Teams integration */
export const handleRemoveTeamsIntegration = (id) => async (dispatch) => {
  try {
    await dispatch(
      removeTeamsIntegration(
        DELETE(API.teamsIntegrations.remove(id)).then((response) => {
          return response;
        })
      )
    );
  } catch (exception) {
    log.error('Teams Integration', exception);
    dispatch(showNotification(TOAST_TYPES.error, 'Error', getErrorMessage(exception)));
  }
};

export const handleGitDisconnect = (id, userId, organizationId) => async (dispatch) => {
  try {
    await dispatch(
      gitDisconnect(
        DELETE(API.gitIntegrations.remove(id), {}, header).then(({data}) => {
            const { uninstallUrl } = data;
            if (uninstallUrl) {
                setOpenWindowLocation(uninstallUrl, true);
            } else {
                dispatch(handleGetOrganizationOwner(organizationId, null, 0))
            }
            return id;
        })
      )
    );
  } catch (exception) {
    log.error('Git Disconnect', exception);
  }
};
export const handleVerifyKubernetes =
  ( id ) =>
    async (dispatch) => {
      try {
        await dispatch(
          verifyKubernetes(PUT(API.kubernetesIntegration.verify(id)).then(({ data }) => data))
        );
      } catch (exception) {
        log.error('Verify Kubernetes', exception);
      }
    };
export const handleGetCloudProviders = (organizationId) => async (dispatch) => {
  try {
    await dispatch(
      getCloudProviders(
        Promise.all([
          GET(API.cloudProvider.base),
          GET(API.organizations.kubernetesIntegrations(organizationId)),
        ]).then((body) => {
          const cloudProvidersData = R.path([0, 'data'], body) || {};
          const kubernetesData = R.path([1, 'data'], body) || [];
          return {
            cloudProviders: cloudProvidersData,
            kubernetes: kubernetesData,
          };
        })
      )
    );
  } catch (exception) {
    log.error('Cloud Providers', exception);
  }
};

export const handleConnectKubernetes = (
  organizationId,
  slug,
  payload,
  userId,
  history
) => async (dispatch, getState) => {
  try {
    const {
      integrations: { cloudProviders },
    } = getState();
    const index = R.findIndex(R.propEq('slug', slug))(cloudProviders);
    const cloudProviderId = R.path([index, 'id'], cloudProviders);

    const promise = await dispatch(
      connectKubernetes(
        POST(API.kubernetesIntegration.base, {
          ...payload,
          cloud: `/api/cloud_providers/${cloudProviderId}`,
          organization: `/api/organizations/${organizationId}`,
        }).then(({ data }) => {
          dispatch(handleGetOrganizationDetails(organizationId, history))
          dispatch(
            showNotification(
              TOAST_TYPES.success,
              'Cluster connected successfully',
              `${data.clusterName}  has been successfully connected to your organization.`
            )
          );
          dispatch(hideDialog());
          return data;
        })
      )
    );
    dispatch(handleVerifyKubernetes(promise.payload.id));
  } catch (exception) {
    log.error('Connect Kubernetes X', exception);
    if (exception.response.status === 422) {
      return;
    }  
    dispatch(showNotification(TOAST_TYPES.error, 'Error', getErrorMessage(exception)));
  }
};
export const handleEditCluster = (
  organizationId,
  slug,
  payload,
  clusterId
) => async (dispatch, getState) => {
  try {
    const {
      integrations: { cloudProviders },
    } = getState();
    const index = R.findIndex(R.propEq('slug', slug))(cloudProviders);
    const cloudProviderId = R.path([index, 'id'], cloudProviders);
    await dispatch(
      editCluster(
        PUT(API.kubernetesIntegration.integration(clusterId), {
          ...payload,
          cloud: `/api/cloud_providers/${cloudProviderId}`,
          organization: `/api/organizations/${organizationId}`,
        }).then(({ data }) => {
          dispatch(hideDialog());
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Kubernetes Detach', exception);
  }
};
export const handleDetachKubernetes = (
  organizationId, clusterId, userId, history
) => async (dispatch) => {
  try {
    await dispatch(
      detachKubernetes(
        PUT(API.kubernetesIntegration.detach(clusterId)).then(({ data }) => {
          dispatch(handleGetOrganizationDetails(organizationId, history));
          dispatch(
            showNotification(
              TOAST_TYPES.success,
              `${data.clusterName} successfully removed`,
              `${data.clusterName}  has been successfully removed from your organization.`
            )
          );
          dispatch(hideDialog());
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Kubernetes Detach', exception);
    // eslint-disable-next-line max-lines
    dispatch(showNotification(TOAST_TYPES.error, 'Kubernetes Detach', getErrorMessage(exception)));
  }
};

export const handleGetGitIntegration = (integrationId) => async (dispatch) => {
  try{
    await dispatch(
      getGitIntegration(GET(`${API.gitIntegrations.base}/${integrationId}`).then(({data}) => {
        return data;
      }))
    )
  } catch (exception) {
    log.error('Git Integration', exception)
  }
};

export const handleAddWorkshopCluster = (organizationId, history) => async (dispatch) => {
  try{
    await dispatch(
      addWorkshopCluster(POST(`${API.kubernetesIntegration.workshop}`, {organizationId}).then(({data}) => {
        history.push(`/${organizationId}/clusters`)
        dispatch(
          showNotification(
            TOAST_TYPES.success,
            'Cluster added',
            'Workshop cluster has been added to your organization'
          )
        );
        return data;
      }))
    )
  } catch (exception) {
    log.error('Git Integration', exception)
    dispatch(
      showNotification(
        TOAST_TYPES.error,
        'Error',
        getErrorMessage(exception)
      )
    );
    history.push(`/${organizationId}/clusters`)
  }
};