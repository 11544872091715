/* eslint-disable max-lines */
import styled from 'styled-components';
import { palette } from 'styled-theme';
import { transition, borderRadius } from 'theme/helpers/style_utils';
import WithDirection from 'theme/helpers/rtl';

const SidebarWrapper = styled.div`
  .menu-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .isomorphicSidebar .ant-menu .ant-menu-item.hasSubMenu {
    padding: 0 !important;

    span.ant-menu-title-content {
      height: 100%;
    }

    /** color from :hover logic... */
    &.ant-menu-item-active .isoMenuHolder {
      background-color: ${palette('primary', 4)};
      svg {
        use {
          stroke: ${palette('primary', 0)};
        }
      }

      #dark & {
        background-color: ${palette('background', 4)};
        svg {
          use {
            stroke: ${palette('grays', 11)};
          }
        }
      }
    }
  }

  .org-menu {
    width: 100%;
    background-color: transparent;
    border: 0px;
    .support-text {
      font-size: 12px;
      line-height: 1;
      color: ${palette('text', 1)};
      margin-left: 34px;
      margin-top: 2px;
    }
    > li.ant-menu-submenu {
      padding: 0px !important;
      background-color: transparent;
      border: 0px;
      transition: none;

      .ant-menu-submenu-title {
        padding: 0;
        height: 40px;
        line-height: 1;
        margin: 0px;
        transition: none;
        @media (max-width: 768px) {
          padding: 0;
        }
        &:hover {
          .nav-text,
          .nav-text span {
            color: ${palette('primary', 0)};
          }
        }
        .ant-menu-title-content {
          transition: none;
        }
      }
      .isoMenuHolder {
        .nav-text {
          max-width: 140px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .org-name {
          min-width: 100px;
          max-width: 130px;

          #dark & {
            color: ${palette('grays', 7)};
          }
        }

        .arrow-right {
          position: absolute;
          right: 0px;
          top: 17px;
          .icon {
            transform: rotate(90deg);
            width: 8px;
            height: 8px;
          }
        }
        flex-wrap: wrap;
        .default {
          display: block;
        }
        .active {
          display: none;
        }
        &:hover {
          .org-name {
            #dark & {
              color: ${palette('grays', 11)};
            }
          }

          .default {
            display: none;
          }
          .active {
            display: block;
          }
        }
      }
      .ant-menu-submenu-arrow {
        display: none;
      }
    }
  }
  .isomorphicSidebar {
    z-index: 1000;
    height: 100vh;
    background: ${palette('background', 3)};
    transition: all 0.1s;

    &:not(.ant-layout-sider-collapsed) {
      box-shadow: 0 0 5px 1px #00000021;
    }

    .scrollarea {
      height: calc(100vh - 70px);
    }

    #dark & {
      background-color: ${palette('background', 1)};
    }

    &.admin.admin.admin {
      background-color: #009854;
      .blue-logo {
        filter: brightness(10);
      }
      .icon:not(.admin-icon) {
        use {
          filter: brightness(10);
        }
      }

      .nav-text {
        color: ${palette('grays', 11)} !important;
      }

      #dark & {
        background-color: #009854;
      }

      .ant-menu-item {
        a {
          &:hover {
            background-color: ${palette('success', 1)};
          }
        }
      }

      .isoMenuHolder {
        &:hover {
          background-color: ${palette('success', 1)};
        }
      }
    }

    @media only screen and (max-width: 767px) {
      width: 240px !important;
      flex: 0 0 240px !important;
    }

    &.ant-layout-sider-collapsed {
      @media only screen and (max-width: 767px) {
        width: 0;
        min-width: 0 !important;
        max-width: 0 !important;
        flex: 0 0 0 !important;
      }
    }

    .isoLogoWrapper {
      height: 64px;
      margin: 0;
      padding: 0 24px;
      text-align: left;
      overflow: hidden;
      ${borderRadius()};

      h3 {
        a {
          font-size: 21px;
          font-weight: 300;
          letter-spacing: 3px;
          text-transform: uppercase;
          color: ${palette('grayscale', 6)};
          display: inline-flex;
          text-decoration: none;
        }
      }
    }

    .isoLogoWrapper {
      &.collapsed .imageSizeWrapper {
        width: 32px;
      }
      .imageSizeWrapper {
        width: 100%;
        transition: all 0.2s;
        overflow: hidden;
      }

      padding: 16px 20px;
      transition: all 0.1s;

      @media (max-width: 768px) {
        padding: 16px 8px;
      }
      .blue-logo {
        display: inline-block;
      }
      .white-logo {
        display: none;
      }
      #dark & {
        .blue-logo {
          display: none;
        }
        .white-logo {
          display: inline-block;
        }
      }
      h3 {
        a {
          font-size: 21px;
          font-weight: 500;
          letter-spacing: 0;
        }
      }
    }

    .isoDashboardMenu {
      padding-top: 14px;
      padding-bottom: 40px;
      background: transparent;

      a {
        text-decoration: none;
        font-weight: 400;
      }

      .ant-menu-item {
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;

        margin: 0;

        a {
          color: ${palette('color', 1)};
          display: block;
          border-radius: 6px;

          &.admin-item {
            &:hover {
              background-color: ${palette('success', 2)};
              .nav-text {
                color: ${palette('color', 1)};
              }
            }
          }
          &.side-item {
            svg {
              &.dark,
              &.dark-active {
                display: none;
              }
              #dark & {
                &.default,
                &.active {
                  display: none;
                }
                &.dark {
                  display: block;
                }
                &.dark-active {
                  display: none;
                }
              }
              use {
                stroke: none !important;
              }
            }
            &:hover {
              svg {
                #dark & {
                  &.dark {
                    display: none;
                  }
                  &.dark-active {
                    display: block;
                  }
                }
              }
            }
          }
          &.active {
            background-color: ${palette('primary', 4)};
            #dark & {
              background-color: ${palette('background', 4)};
              svg {
                use {
                  stroke: ${palette('grays', 11)};
                }
              }
            }
            &.admin-item {
              background-color: ${palette('success', 1)};
            }
            svg {
              use {
                stroke: ${palette('primary', 0)};
              }
            }
          }
          &:hover {
            background-color: ${palette('primary', 4)};
            #dark & {
              background-color: ${palette('background', 4)};
              svg {
                use {
                  stroke: ${palette('grays', 11)};
                }
              }
            }
            svg {
              use {
                stroke: ${palette('primary', 0)};
              }
            }
          }
          svg {
            use {
              stroke: ${palette('grays', 2)};
            }
          }
        }
      }
      .ant-menu-item-active,
      .ant-menu-item-selected {
        .isoMenuHolder {
          .default {
            display: none;
          }
          .active {
            display: block;
          }
        }
      }
      .ant-menu-submenu {
        color: ${palette('color', 1)};
      }
      .isoMenuHolder {
        display: flex;
        align-items: center;
        padding: 8px;
        border-radius: 4px;
        width: 100%;
        svg {
          use {
            stroke: ${palette('grays', 2)};
          }
        }
        &:hover {
          background-color: ${palette('primary', 4)};
          svg {
            use {
              stroke: ${palette('primary', 0)};
            }
          }
          #dark & {
            background-color: ${palette('background', 4)};
            svg {
              use {
                stroke: ${palette('grays', 11)};
              }
            }
          }
        }

        .default {
          display: block;
        }
        .active {
          display: none;
        }
        svg {
          width: 24px;
          height: 24px;
          margin-right: 18px;
          ${transition()};
        }
      }

      .anticon {
        font-size: 18px;
        margin-right: 30px;
        color: inherit;
        ${transition()};
      }

      .nav-text {
        font-size: 14px;
        line-height: 15px;
        color: inherit;
        font-weight: 400;
        font-family: ${palette('fonts', 2)};
        ${transition()};
        &.admin {
          color: ${palette('success', 0)};
        }
      }

      .ant-menu-item-selected {
        background-color: transparent;
        a {
          .icon {
            &.active {
              display: none;
            }
            &.default {
              display: block;
            }
          }
        }
        a.active {
          .anticon {
            color: ${palette('primary', 0)};
          }

          .icon {
            &.active {
              display: block;
            }
            &.default {
              display: none;
            }
          }

          .nav-text {
            color: ${palette('primary', 0)};
          }
        }
      }
      a.active {
        .anticon {
          color: ${palette('primary', 0)};
        }

        .icon {
          &.active {
            display: block;
          }
          &.default {
            display: none;
          }
        }

        .nav-text {
          color: ${palette('primary', 0)};
          #dark & {
            color: ${palette('grays', 7)};
          }
        }
      }
      > li {
        &:hover {
          i,
          .nav-text {
            color: ${palette('primary', 0)};
            #dark & {
              color: ${palette('grays', 11)};
            }
          }
        }
      }
    }

    .ant-menu-submenu-inline,
    .ant-menu-submenu-vertical {
      > .ant-menu-submenu-title {
        width: 100%;
        display: flex;
        align-items: center;

        > span {
          display: flex;
          align-items: center;
        }

        .ant-menu-submenu-arrow {
          left: auto;
          right: 25px;
          color: ${palette('color', 1)};
          &:before,
          &:after {
            width: 8px;
            ${transition()};
          }

          &:before {
            transform: rotate(-45deg) translateX(3px);
          }

          &:after {
            transform: rotate(45deg) translateX(-3px);
          }
        }

        &:hover {
          .ant-menu-submenu-arrow {
            &:before,
            &:after {
              color: ${palette('primary', 0)};
            }
          }
        }
      }

      .ant-menu-inline,
      .ant-menu-submenu-vertical {
        > li:not(.ant-menu-item-group) {
          padding-left: 74px !important;
          padding-right: 0px !important;
          font-size: 13px;
          font-weight: 400;
          margin: 0;
          color: inherit;
          ${transition()};

          &:hover {
            a {
              color: #ffffff !important;
            }
          }
        }

        .ant-menu-item-group {
          padding-left: 0;

          .ant-menu-item-group-title {
            padding-left: 100px !important;
          }
          .ant-menu-item-group-list {
            .ant-menu-item {
              padding-left: 125px !important;
            }
          }
        }
      }

      .ant-menu-sub {
        box-shadow: none;
        background-color: transparent !important;
      }
    }

    & :not(.ant-layout-sider-collapsed) {
      .ant-menu-item,
      .ant-menu-submenu {
        height: 40px;
        padding: 0px 16px !important;
        margin-bottom: 12px;
      }
      .ant-menu-submenu-vertical {
        // padding: 0 !important;
      }
    }

    &.ant-layout-sider-collapsed {
      flex: 0 0 72px !important;
      max-width: 72px !important;
      min-width: 72px !important;
      width: 72px !important;
      @media (max-width: 768px) {
        flex: 0 0 48px !important;
        max-width: 48px !important;
        min-width: 48px !important;
        width: 48px !important;
      }
      .nav-text {
        display: none !important;
      }
      .ant-menu-item,
      .ant-menu-submenu {
        height: 40px;
        padding: 0px 16px;
        margin-bottom: 12px;
        @media (max-width: 768px) {
          padding: 0 4px !important;
        }
        .isoMenuHolder {
          svg {
            margin-right: 0px;
          }
        }
      }
      .ant-menu-submenu-inline > {
        .ant-menu-submenu-title:after {
          display: none;
        }
      }

      .ant-menu-submenu-vertical {
        > .ant-menu-submenu-title:after {
          display: none;
        }

        .ant-menu-sub {
          background-color: transparent !important;

          .ant-menu-item {
            height: 40px;
            padding: 0px 16px;
            @media (max-width: 768px) {
              padding: 0 4px;
            }
          }
        }
      }
    }
  }
`;

export default WithDirection(SidebarWrapper);
