import { useAppSelector } from 'app/hooks';

export type ThemeMode = 'light'|'dark';
export type UITheme = ThemeMode|'auto';

export interface ThemeData {
    mode: ThemeMode;
    uiTheme: UITheme;
}

export function useThemeDataSelector(): ThemeData {
    return useAppSelector(state => state.global as ThemeData);
}

export function useUIThemeSelector(): UITheme {
    const data = useThemeDataSelector();

    return data.uiTheme;
}

export function useThemeModeSelector(): ThemeMode {
    const data = useThemeDataSelector();

    return data.mode;
}
