import { Dropdown, Menu } from 'antd';
import WithDirection from 'theme/helpers/rtl';
import DropdownWrapper, { DropdownMenus } from './styles/dropdown.style';

const WDDropdowns = DropdownWrapper(Dropdown);
const Dropdowns = WithDirection(WDDropdowns);

const WDDropdownButtons = DropdownWrapper(Dropdown.Button);
const DropdownButtons = WithDirection(WDDropdownButtons);
const DropdownMenu = DropdownMenus(Menu);
const MenuItem = DropdownMenus(Menu.Item);
const SubMenu = DropdownMenus(Menu.SubMenu);

const StyledDropdown = Dropdowns;

export default Dropdowns;
export {
    StyledDropdown,
    DropdownButtons,
    DropdownMenu,
    MenuItem,
    SubMenu,
};
