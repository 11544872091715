/* eslint-disable max-lines */
import styled from 'styled-components';
import { palette } from 'styled-theme';
import { transition, borderRadius, boxShadow } from 'theme/helpers/style_utils';
import WithDirection from 'theme/helpers/rtl';

const StyledHeader = styled.div`
  .isomorphicTopbar {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    border-bottom: 1px solid ${palette('border', 0)};
    padding: ${(props) => (props['data-rtl'] === 'rtl' ? '0 269px 0 32px' : '0 32px 0 269px')};
    z-index: 10;
    ${transition()};
    transition: all .2s;
    #dark &{
      background-color: ${palette('background', 0)};
      border-bottom: 1px solid ${palette('border', 4)}; 
      transition: all .5s;
    }
    &.collapsed {
      padding: ${(props) => (props['data-rtl'] === 'rtl' ? '0 94px 0 32px' : '0 32px 0 104px')};
      @media (max-width: 768px){
        padding: 0 16px 0 64px;
      }
    }

    /** why are these in the header? */
    .ribbon {
      text-overflow: ellipsis;
      overflow: hidden;

      .ant-btn {
        font-weight: 700;
        border: none;
        border-radius: 5px;

        &.primary {
          background-color: ${palette('primary', 4)};
          color: ${palette('primary', 0)};
        }
        &.secondary {
          background-color: ${palette('secondary', 11)};
          color: ${palette('text', 0)};
        }
      }

      #dark & {
        color: #CCCCCC;
      }
    }
    .theme-menu-item {
      .ant-dropdown-menu-submenu-title {
        padding: 0;

        .ant-dropdown-menu-title-content {
          div {
            line-height: 20px;
            padding: 8px 16px;
            color: ${palette('text', 1)};

            #dark & {
              color: ${palette('text', 3)};
            }
          }
        }

        .ant-dropdown-menu-submenu-expand-icon {
          top: 7px;
          svg {
            color: ${palette('text', 1)};

            #dark & {
              color: ${palette('text', 3)};
            }
          }
        }
      }
    }
    #theme-dropdown{
      .theme-dropdown{
        @media (max-width: 768px){
          margin-right: 16px;
          padding-right: 8px;
          padding-left: 16px;
          span{
            display: none;
          }
        }
      }
    }
    #chat-button{
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      padding: 0;
      padding-top: 0 !important;
      border: 0;
      border-right: 1px solid ${palette('grays',5)};
      margin-right: 0;
      cursor: pointer;
      max-height: 32px;
      padding-right: 24px;
      @media (max-width: 768px){
        padding-right: 8px;
        height: 32px;
        span{
          display: none;
        }
      }
      #dark & {
        color: #FFF;
      }
      svg{
        margin-right: 8px;
      }
    }
    .topbar-actions{
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .projects-dropdown-selector{
      min-width: 210px;
      margin-top: 27px;
      padding: 16px 0px !important;
      @media (max-width: 576px){
        margin-left: 48px;
      }
      &.with-search{
        width: 100%;
        height: calc(100vh - 100px);
        @media (max-width: 576px){
          max-width: calc(100% - 56px);
        }
      }
      .search-projects-container{
        display: flex;
        padding: 8px 16px;
        flex-wrap: wrap;
      }
      .project-list-container{
        overflow: scroll;
        height: calc(100% - 58px);
        scrollbar-width: none;
        &::-webkit-scrollbar{display: none;}
        &::-webkit-scrollbar-track-piece{display: none;}
        &::-webkit-scrollbar-thumb:vertical{display: none;}
      }
      .ant-dropdown-menu-item {
        &.ant-dropdown-menu-item-active {
          background-color: #fff;
        }
        margin: 0;
        padding: 0;
        a, div[role="button"]{
          font-size: ${palette('fontSize', 1)};
          line-height: 20px;
          padding: 8px 16px;
          color: ${palette('text', 1)};
          display: flex;
          justify-content: space-between;
          align-items: center;
          svg{display: none;}
          #dark & {
            color: ${palette('grays', 8)}
          }
          &:hover{
            color: ${palette('text',0)};
            #dark & {
              color: ${palette('grays', 11)}
            }
          }
          &.active{
            color: ${palette('primary', 0)};
            #dark & {
              color: ${palette('grays', 11)} !important;
            }
            svg{
              display: block;
            }
          }
          
        }
      }
    }
    .project-dropdown{
      cursor: pointer;
      .name{
        font-family: ${palette('fonts',1)};
        font-size: ${palette('fontSize', 1)};
        color: ${palette('text',0)};
        line-height: 16px;
        letter-spacing: -0.232px;

        #dark & { 
          color: ${palette('grays', 11)}
        }
      }
      .description{
        font-size: ${palette('fontSize', 2)};
        line-height: 12px;
        color: ${palette('text',1)};
        #dark & {
          color: ${palette('grays', 8)}; 
        }
      }
      svg{
        transform: rotate(180deg);
        #dark & {
          use{
            stroke: ${palette('grays',8)}
          }
        }
      }
      &:hover{
        svg{
          use{
            stroke: ${palette('primary',0)}
          }
        }
        .name{color: ${palette('primary', 0)};}
        .description{color: ${palette('text', 0)};}
      }

    }
    .isoLeft {
      display: flex;
      align-items: center;
      max-width: calc(100% - 520px);
      overflow: hidden;
      .triggerBtn {
        width: 24px;
        height: 100%;
        display: -webkit-inline-flex;
        display: -ms-inline-flex;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 0;
        outline: 0;
        position: relative;
        cursor: pointer;
        padding: 0px;
      }
    }

    .navDivider {
      margin: 0 24px;
      height: 32px;
      width: 1px;
      background-color: #999999;
      @media (max-width: 768px){
        margin: 0 16px 0 8px;
      }
    }

    .isoRight {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      padding: 0;

      li {
        margin-left: ${(props) => (props['data-rtl'] === 'rtl' ? '35px' : '0')};
        margin-right: ${(props) => (props['data-rtl'] === 'rtl' ? '0' : '35px')};
        cursor: pointer;
        line-height: normal;
        position: relative;
        display: inline-block;

        @media only screen and (max-width: 360px) {
          margin-left: ${(props) => (props['data-rtl'] === 'rtl' ? '25px' : '0')};
          margin-right: ${(props) => (props['data-rtl'] === 'rtl' ? '0' : '25px')};
        }

        &:last-child {
          margin: 0;
        }

        .profile-button {
          border: none;
          &[ant-click-animating-without-extra-node]:after {
            animation: 0s !important;
          }
        }

        i {
          font-size: 24px;
          color: ${palette('text', 0)};
          line-height: 1;
        }

        .isoIconWrapper {
          position: relative;
          line-height: normal;

          span {
            font-size: 12px;
            color: #fff;
            background-color: ${palette('secondary', 1)};
            width: 20px;
            height: 20px;
            display: -webkit-inline-flex;
            display: -ms-inline-flex;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            line-height: 20px;
            position: absolute;
            top: -8px;
            left: ${(props) => (props['data-rtl'] === 'rtl' ? 'inherit' : '10px')};
            right: ${(props) => (props['data-rtl'] === 'rtl' ? '10px' : 'inherit')};
            ${borderRadius('50%')};
          }
        }

        &.isoMail {
          .isoIconWrapper {
            span {
              background-color: ${palette('color', 0)};
            }
          }
        }

        &.isoNotify {
          .isoIconWrapper {
            span {
              background-color: ${palette('primary', 3)};
            }
          }
        }

        &.isoMsg {
          .isoIconWrapper {
            span {
              background-color: ${palette('color', 1)};
            }
          }
        }

        &.isoCart {
          .isoIconWrapper {
            span {
              background-color: ${palette('color', 2)};
            }
          }
        }

        &.isoUser {
          .isoImgWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            background-color: ${palette('grayscale', 9)};
            ${borderRadius('50%')};

            img {
              height: 100%;
              object-fit: cover;
              border-radius: 100%;
            }

            .userActivity {
              width: 10px;
              height: 10px;
              display: block;
              background-color: ${palette('success', 0)};
              position: absolute;
              bottom: 0;
              right: 3px;
              border: 1px solid #ffffff;
              ${borderRadius('50%')};
              
            }

            .userImage {
              position: relative;
              width: 32px;
              height: 32px;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              border-radius: 100%;

              .userInitials {
                position: absolute;
                top: 0;
                left: 0;
                color: #1F2ECF;
                font-family: "Circular-Bold",sans-serif;
                display: flex;
                width: 32px;
                height: 32px;
                background-color: #999999;
                // border: 1px solid #999999;
                box-shadow: 0 0 0 1px #999999;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                text-transform: uppercase;  
                transition: box-shadow 0.2s ease;
                #dark & {
                  color: ${palette('grays',8)};
                }
              }

              &:hover {
                .userInitials {
                  // border: 1px solid ${palette('border', 5)};
                  box-shadow: 0 0 0px 1px ${palette('border', 5)};
                }
              }

              img {
                position: absolute;
                top: 0;
                left: 0;
                width: 32px;
                height: 32px;
                border-radius: 100%;
              }
            }
          }
          #profile-dropdown{
            .ant-dropdown-menu-item-group {
              width: 100%;

              .ant-dropdown-menu-item-group-title {
                padding: 0;
              }
              .ant-dropdown-menu-item-group-list {
                margin: 0;
              }
            }

            .ant-dropdown-menu.isoUserDropdown{
              padding: 16px 0 !important;
              .ant-dropdown-menu-item{
                display: block;
                margin-bottom: 0;
                margin-right: 0 !important;
                padding: 0 !important;
                .profile-dropdown-action{
                  padding: 8px 16px;
                  line-height: 20px;
                  #dark & {
                    color: ${palette("grays", 11)} !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .isoUserDropdown {
    .ant-popover-inner {
      .ant-popover-inner-content {
        .isoUserDropdownContent {
          padding: 7px 0;
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 0;
          right: 0;
          background-color: #ffffff;
          width: 220px;
          min-width: 160px;
          flex-shrink: 0;
          ${borderRadius('5px')};
          ${boxShadow('0 2px 10px rgba(0,0,0,0.2)')};
          ${transition()};

          .isoDropdownLink {
            font-size: 13px;
            color: ${palette('text', 1)};
            line-height: 1.1;
            padding: 7px 15px;
            background-color: transparent;
            text-decoration: none;
            display: flex;
            justify-content: flex-start;
            ${transition()};

            &:hover {
              background-color: ${palette('secondary', 6)};
            }
          }
        }
      }
    }
  }

  // Dropdown
  .ant-popover {
    .ant-popover-inner {
      .ant-popover-inner-content {
        .isoDropdownContent {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 0;
          right: 0;
          background-color: #ffffff;
          width: 360px;
          min-width: 160px;
          flex-shrink: 0;
          ${borderRadius('5px')};
          ${boxShadow('0 2px 10px rgba(0,0,0,0.2)')};
          ${transition()};

          @media only screen and (max-width: 767px) {
            width: 310px;
          }

          .isoDropdownHeader {
            border-bottom: 1px solid #f1f1f1;
            margin-bottom: 0px;
            padding: 15px 30px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            h3 {
              font-size: 14px;
              font-weight: 500;
              color: ${palette('text', 0)};
              text-align: center;
              text-transform: uppercase;
              margin: 0;
            }
          }

          .isoDropdownBody {
            width: 100%;
            height: 300px;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            background-color: ${palette('grayscale', 6)};

            .isoDropdownListItem {
              padding: 15px 30px;
              flex-shrink: 0;
              text-decoration: none;
              display: flex;
              flex-direction: column;
              text-decoration: none;
              width: 100%;
              ${transition()};

              &:hover {
                background-color: ${palette('grayscale', 3)};
              }

              .isoListHead {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;
              }

              h5 {
                font-size: 13px;
                font-weight: 500;
                color: ${palette('text', 0)};
                margin-top: 0;
              }

              p {
                font-size: 12px;
                font-weight: 400;
                color: ${palette('text', 2)};
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              .isoDate {
                font-size: 11px;
                color: ${palette('grayscale', 1)};
                flex-shrink: 0;
              }
            }
          }

          .isoViewAllBtn {
            font-size: 13px;
            font-weight: 500;
            color: ${palette('text', 2)};
            padding: 10px 15px 20px;
            display: flex;
            text-decoration: none;
            align-items: center;
            justify-content: center;
            text-align: center;
            ${transition()};

            &:hover {
              color: ${palette('primary', 0)};
            }
          }

          .isoDropdownFooterLinks {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 30px 20px;

            a {
              font-size: 13px;
              font-weight: 500;
              color: ${palette('text', 0)};
              text-decoration: none;
              padding: 10px 20px;
              line-height: 1;
              border: 1px solid ${palette('border', 1)};
              display: flex;
              align-items: center;
              justify-content: center;
              ${transition()};

              &:hover {
                background-color: ${palette('primary', 0)};
                border-color: ${palette('primary', 0)};
                color: #ffffff;
              }
            }

            h3 {
              font-size: 14px;
              font-weight: 500;
              color: ${palette('text', 0)};
              line-height: 1.3;
            }
          }
        }
      }
    }
  }
`;

StyledHeader.displayName = 'StyledHeader';
export default WithDirection(StyledHeader);
