import { getView } from 'modules/sidebar/actions';
import isServer from 'theme/helpers/isServer';
import { getDefaultPath } from 'theme/helpers/url_sync';

const preKeys = getDefaultPath();

const initialState = {
  collapsed: true, // !(!isServer && window.innerWidth > 220),
  view: !isServer && getView(window.innerWidth),
  height: !isServer && window.innerHeight,
  openDrawer: false,
  openKeys: preKeys,
  current: preKeys,
};

export default initialState;
