import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { API, POST } from 'config';
import { hideDialog } from 'actions/global';
import { bindActionCreators } from 'redux';
import { capitalize } from 'utils/string';
import { useEnvironmentACL } from 'rbac/hooks/useEnvironmentACL';
import Tooltip from 'theme/uielements/tooltip';
import Button from 'theme/uielements/button';
import CodeMirrorError from 'components/cmEditorTypes/CodeMirrorError';

const PublishErrorModal = ({ exception, environmentId, organizationId, projectId, actions, environment, project }) => {
  const history = useHistory();

  // RBAC
  const fetchingOption = { allowFetching: true };
  const options = {
    organization: { id: parseFloat(organizationId) },
    project: { id: parseFloat(projectId), labels: project?.labels },
    environment: { id: parseFloat(environmentId), labels: environment?.labels, },
  };
  const aclEnvManageData = useEnvironmentACL('environment:manage', options, fetchingOption);
  const aclEnvManageDenied = aclEnvManageData.type === 'denied';
  // End of RBAC

  const error = R.path(['response', 'data', 'detail'], exception);
  const errorStatus = R.path(['response', 'status'], exception);
  const isEditConflict = errorStatus === 423;

  const takeover = async () => {
    const requestURL = API.environments.takeover(environmentId);
    return POST(requestURL);
  };

  return (
    <>
      <p className="mb-0">
        The environment configuration contains errors and cannot be deployed at this time.
        <br />
        Please review and fix the errors listed below before trying again.
      </p>
      <div className="mt-3 mb-4 publishError">
        <CodeMirrorError value={error} />
      </div>
      <div className="d-flex justify-content-end">
        {organizationId && projectId && environmentId && (
          <Tooltip
            className="d-flex"
            placement="top"
            title={capitalize((aclEnvManageData.reason) || '')}
            mouseEnterDelay={0.4}
            mouseLeaveDelay={0.4}
          >
            <span>
              <Button
                type="primary"
                disabled={aclEnvManageDenied}
                onClick={async () => {
                  actions.hideDialog();

                  if (isEditConflict) {
                    // takeover
                    try {
                      await takeover();
                      history.push(
                        `/${organizationId}/projects/${projectId}/environments/${environmentId}/configuration/editDiff`
                      );
                    } catch (err) {
                      history.push(
                        `/${organizationId}/projects/${projectId}/environments/${environmentId}/configuration/editDiff`
                      );
                    }
                  } else {
                    history.push(
                      `/${organizationId}/projects/${projectId}/environments/${environmentId}/configuration`
                    );
                  }
                }}
              >
                {isEditConflict ? 'Takeover' : 'Fix errors'}
              </Button>
            </span>
          </Tooltip>
        )}
      </div>
    </>
  );
};

PublishErrorModal.propTypes = {
  project: PropTypes.shape({
    labels: PropTypes.shape({}),
  }).isRequired,
  environment: PropTypes.shape({
    labels: PropTypes.shape({}),
  }).isRequired,
  exception: PropTypes.shape({}).isRequired,
  environmentId: PropTypes.number.isRequired,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    hideDialog: PropTypes.func,
  }).isRequired,
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      hideDialog,
    },
    dispatch
  ),
});

const mapStateToProps = ({ environments, projects }) => {
  const { environment } = environments;
  const { project } = projects;

  return {
    environment,
    project,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublishErrorModal);
