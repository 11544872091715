const routePaths = {
  LOGIN: '/login',
  REGISTER: '/register',
  RECOVER_PASSWORD: '/reset-password',
  REGISTER_CONFIRM_EMAIL: '/register-confirm-email',
  // REGISTER_GIT: '/register-git',
  // REGISTER_EMAIL: '/register-email',
  REGISTER_EMAIL: '/login',
  BILLING_REGISTRATION: '/billing-registration',
  INVITE_ACCEPT: '/invite-accept',
  GITHUB_INSTALL_ID: '/github-installation-id',
  LANDING: '/',
  ORGANIZATION: '/:organizationId',
  PROJECTS: '/:organizationId/projects',
  PROJECT_SECURITY: '/:organizationId/projects/:projectId/security',
  PROJECT_SETTINGS: '/:organizationId/projects/:projectId/settings',
  PROJECT_METRICS: '/:organizationId/projects/:projectId/metrics',
  CLUSTERS: '/:organizationId/clusters',
  CLUSTERS_DETAILS: '/:organizationId/clusters/:clusterId',
  CLUSTERS_PIPELINE: '/:organizationId/clusters/:clusterId/pipelines/:pipelineId',
  CLOUD_COSTS: '/:organizationId/cloud-costs',
  DEPLOYS: '/:organizationId/deploys',
  ENVIRONMENTS: '/:organizationId/projects/:projectId/environments',
  ENVIRONMENT_SETTINGS: '/:organizationId/projects/:projectId/environments/:environmentId/settings',
  ENVIRONMENT_DETAILS: '/:organizationId/projects/:projectId/environments/:environmentId',
  ENVIRONMENT_CONFIGURATION: '/:organizationId/projects/:projectId/environments/:environmentId/configuration',
  ENVIRONMENT_CONFIGURATION_DIFF: '/:organizationId/projects/:projectId/environments/:environmentId/configuration/templateDiff',
  ENVIRONMENT_CONFIGURATION_EDIT_DIFF: '/:organizationId/projects/:projectId/environments/:environmentId/configuration/editDiff',
  ENVIRONMENT_EVENTS: '/:organizationId/projects/:projectId/environments/:environmentId/events',
  ENVIRONMENT_PIPELINES: '/:organizationId/projects/:projectId/environments/:environmentId/pipelines',
  ENVIRONMENT_PIPELINE_DETAILS:
    '/:organizationId/projects/:projectId/environments/:environmentId/pipelines/:pipelineId',
  PIPELINE_BRIDGE: '/b/pipeline/:pipeHashID',
  ENVIRONMENT_BRIDGE: '/b/environment/:pipeHashID',
  ENVIRONMENT_EVENTS_LOGS: '/:organizationId/projects/:projectId/environments/:environmentId/events/:eventId',
  REPOSITORY_PAGE: '/:organizationId/repositories/:repositoryId',
  INTEGRATIONS: '/:organizationId/integrations',
  APPLICATIONS: '/:organizationId/projects/:projectId/environments/:environmentId/applications',
  APPLICATIONS_SETTINGS:
    '/:organizationId/projects/:projectId/environments/:environmentId/applications/:applicationId/settings',
  ADD_WORKSHOP_CLUSTER: '/add-workshop-cluster',
  GITHUB_RESPONSE_PROXY: '/github-response',
  GITHUB_RESPONSE: '/github-connect-response',
  BITBUCKET_RESPONSE_PROXY: '/bitbucket-response',
  BITBUCKET_RESPONSE: '/bitbucket-connect-response',
  GITLAB_RESPONSE_PROXY: '/gitlab-response',
  GITLAB_RESPONSE: '/gitlab-connect-response',
  MICROSOFT_RESPONSE_PROXY: '/microsoft-response',
  AZURE_RESPONSE_PROXY: '/azure-response',
  AZURE_RESPONSE: '/azure-connect-response',
  GOOGLE_RESPONSE_PROXY: '/google-response',
  SLACK_RESPONSE: '/slack-response',
  SSO_RESPONSE: '/sso-response',
  SETTINGS: '/:organizationId/settings',
  SECURITY: '/:organizationId/security',
  BILLING: '/:organizationId/billing',
  ADMIN: '/:organizationId/admin',
  TEMPLATES: '/:organizationId/templates',
  MEETINGS: '/:organizationId/meeting',
  TEMPLATE_DETAILS: '/:organizationId/templates/:templateId',
  TEMPLATE_CONFIGURATION: '/:organizationId/templates/:templateId/configuration',
  TEMPLATE_WEBSITE_CREATE: '/templates/create/:templateId/:templateName',
  ACCESS_TOKEN: '/access-token',
  ERROR: '/error',
  ERROR_404: '/404',
};

export default routePaths;
