import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import { updatePipelineResource } from 'actions/global';
import { getEnvironmentEventDetail, getPipelineData, getPipelineJobData, resetLoadedState } from '../actions';
import initialState from './initialState';

const updatePipelineResourceHandler = [
  updatePipelineResource,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      updatePipelineResourceId: payload,
    };
  },
];

const getPipelineDataHandler = [
  getPipelineData,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        updatePipelineResourceId: '',
        isLoadingPipeline: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoadingPipeline: false,
        errorMessage: payload.response.message,
      };
    }

    return {
      ...state,
      isLoadingPipeline: false,
      pipelineData: payload,
    };
  },
];

const getPipelineJobDataHandler = [
  getPipelineJobData,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        pipeJobTopic: '',
        updatePipelineJobResourceId: '',
        isLoadingPipelineJob: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoadingPipelineJob: false,
        errorMessage: payload.response.message,
      };
    }

    const jobID = payload.id;
    const pipeJobTopic = `/api/workflow_jobs/${jobID}`;

    return {
      ...state,
      isLoadingPipelineJob: false,
      pipelineJobData: payload,
      pipeJobTopic,
    };
  },
];

const resetLoadingStatesHandler = [
  resetLoadedState, (state) => {
    return {
      ...state,
      event: {},
      isLoadingPipeline: true,
      isLoadingPipelineJob: true,
      isLoadingEventDetails: true,
      pipelineData: {
        stages: [],
      },
      pipelineJobData: {},
      pipeJobTopic: '',
      updatePipelineResourceId: '',
      updatePipelineJobResourceId: '',
    }
  }
];

const getEnvironmentEventDetailHandler = [
  getEnvironmentEventDetail,
  (state, action) => {
    const {payload, ready, error} = action;

    if (!ready) {
      return {
        ...state,
      };
    }

    if (error) {
      return {
        ...state,
        isLoadingEventDetails: false,
      };
    }

    return {
      ...state,
      isLoadingEventDetails: false,
      event: payload
    }
  }
];

const reducer = handleActions(
  new Map([
    updatePipelineResourceHandler,
    getPipelineDataHandler,
    getPipelineJobDataHandler,
    resetLoadingStatesHandler,
    getEnvironmentEventDetailHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
