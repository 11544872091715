import * as R from 'ramda';
import { getErrorCode, getErrorMessage } from './log';

export { default as log } from './log';
export { default as withCache } from './withCache';
export { default as clearInternalCache } from './withCache';

export const STORE_KEYS = {
  ACCESS_TOKEN: 'accessToken',
  EXPIRE_TIMESTAMP: 'expire_timestamp',
  USER_ID: 'user_id',
};

export const isStatus = (status, error) => R.path(['response', 'status'], error) === status;

export const noop = () => {};

export const isBlank = (x) => R.isNil(x) || R.isEmpty(x);

export const toLower = (string = '') => {
  return string ? String(string).toLowerCase() : '';
};

export const capitalize = (str = '') => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const includes = (string, substring) => (typeof string === 'string' ? string.includes(substring) : false);

export const safeTrim = (text) => (!isBlank(text) ? R.trim(text) : '');

export const cleanString = (string = '') => R.trim(String(string)).replace(/\s\s+/g, ' ');

export const formatTimeDistance = (date) => {
  const timeDiff = new Date() - new Date(date);
  if (timeDiff / 1000 <= 0) {
    return `1 second ago`;
  }
  if (timeDiff / 1000 <= 59) {
    return `${Math.round(timeDiff / 1000)} seconds ago`;
  }
  if (timeDiff / 1000 > 59 && timeDiff / 1000 < 120) {
    return `1 minute ago`;
  }
  if (timeDiff / 1000 >= 120 && timeDiff / 1000 < 3600) {
    return `${Math.round(timeDiff / 1000 / 60)} minutes ago`;
  }
  if (timeDiff / 1000 >= 3600 && timeDiff / 1000 < 7200) {
    return `1 hour ago`;
  }
  if (timeDiff / 1000 >= 7200 && timeDiff / 1000 < 86400) {
    return `${Math.round(timeDiff / 1000 / 3600)} hours ago`;
  }
  if (timeDiff / 1000 >= 86400 && timeDiff / 1000 < 172800) {
    return `1 day ago`;
  }
  return `${Math.round(timeDiff / 1000 / 86400)} days ago`;
};
const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const formatToDate = (date) => {
  const d = new Date(date);
  const today = new Date();
  const hour = d.getHours() > 12 ? d.getHours() - 12 : d.getHours();
  const minutes = d.getMinutes();
  const meridian = d.getHours() > 12 ? 'PM' : 'AM';
  if (today.getFullYear() === d.getFullYear()) {
    return `${monthNames[d.getMonth()]} ${d.getDate()}, ${hour < 10 ? `0${hour}` : hour}:${
      minutes < 10 ? `0${minutes}` : minutes
    } ${meridian}`;
  }

  return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}, ${hour}:${minutes} ${meridian}`;
};
export const formatExpirationDate = (date) => {
  const timeDiff = new Date(date) - new Date();
  const days = Math.floor(timeDiff / (1000 * 3600 * 24));
  const hours = Math.floor(timeDiff / (1000 * 3600) - days * 24);
  const minutes = Math.floor(timeDiff / (1000 * 60) - hours * 60 - days * 24 * 60);
  if (hours < 1) return `${minutes}m`;
  if (days < 1) return `${hours}h ${minutes}m`;
  return `${days}d ${hours}h ${minutes}m`;
};
export const formatToDateAndTime = (date) => {
  const d = new Date(date);
  const m = d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`;
  const s = d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`;

  return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}  ${d.getHours()}:${m}:${s}`;
};
export const formatEventDate = (date, timezone) => {
  const newDate = new Date(date).toLocaleString('en-US', { timeZone: timezone });
  const d = new Date(newDate);
  const meridian = d.getHours() / 12 >= 1 ? 'PM' : 'AM';
  const hours = d.getHours() % 12 === 0 ? '12' : d.getHours() % 12;
  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}, 
  ${(hours < 10 ? '0' : '') + hours}:${(d.getMinutes() < 10 ? '0' : '') + d.getMinutes()}:${
    (d.getSeconds() < 10 ? '0' : '') + d.getSeconds()
  } ${meridian}`;
};

export const getEventStateColor = (status) => {
  if (status && ['fail'].includes(status.toLowerCase())) {
    return 'red';
  }
  if (status && ['in_progress'].includes(status.toLowerCase())) {
    return 'gray';
  }
  return 'green';
};

export const getEventStateText = (status) => {
  if (status && ['fail'].includes(status.toLowerCase())) {
    return 'Failed';
  }
  if (status && ['in_progress'].includes(status.toLowerCase())) {
    return 'In progress';
  }
  return 'Successful';
};
export const getLogTypeColor = (type) => {
  if (!type) return '';

  if (['emergency', 'alert', 'critical', 'error'].includes(type.toLowerCase())) {
    return 'red';
  }
  if (['warning'].includes(type.toLowerCase())) {
    return 'yellow';
  }
  if (['notice', 'info', 'debug'].includes(type.toLowerCase())) {
    return 'gray';
  }
  return 'green';
};

export const getModuleStatusColor = (moduleStatus) => {
  if (['ready', 'applied', 'valid'].includes(moduleStatus)) {
    return 'success';
  }
  if (['invalid', 'git_conn_failed', 'deleting_failed'].includes(moduleStatus)) {
    return 'error';
  }
  if (['draft', 'syncing', 'applying'].includes(moduleStatus)) {
    return 'pending';
  }
  // 'created' left
  return 'pending';
};

export const getStatusColor = (envStatus) => {
  if (['deployed', 'running', 'built', 'applied'].includes(envStatus)) {
    return 'success';
  }
  if (
    [
      'queued',
      'building',
      'deploying',
      'updating',
      'stopping',
      'starting',
      'restarting',
      'destroying',
      'deleting',
      'aborting',
      'updating_environment',
    ].includes(envStatus)
  ) {
    return 'pending';
  }
  if (
    [
      'stopping_failed',
      'starting_failed',
      'running_issues',
      'deleting_failed',
      'restarting_failed',
      'updating_failed',
      'destroying_failed',
      'deploying_failed',
      'deployment_failed',
      'building_failed',
    ].includes(envStatus)
  ) {
    return 'error';
  }
  if (['aborted'].includes(envStatus)) {
    return 'aborted';
  }
  // 'created' left
  return 'warning';
};

export const getTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

const COLOR_MAP = {
  dark: {
    '#cccccc': '#cccccc',
    '#222222': '#f9f9f9',
    '#333333': '#f9f9f9',
    '#808080': '#cccccc',
  },
  light: {
    '#cccccc': '#cccccc',
    '#222222': '#222222',
    '#333333': '#333333',
    '#808080': '#808080',
  },
};

const nameDisplayNameMap = {
  genericcomponent: 'Generic Component',
  helmcomponent: 'Helm',
  kubernetesmanifestcomponent: 'KubernetesManifest',
  terraformcomponent: 'Terraform',
};

export const getModeColor = (color, mode) => {
  return COLOR_MAP[mode][color];
};

export const getTimezoneOffset = () => {
  const offset = new Date().getTimezoneOffset() / 60;
  const value = offset < 0 ? `+${offset * -1}` : `${Math.abs(offset) * -1}`;
  return `GMT ${value}`;
};

export const setOpenWindowLocation = (url, same) => {
  if (typeof window === 'undefined') return false;
  if (same) {
    window.location.href = url;
    return false;
  }
  return window.open(url);
};

export const refreshWindowLocation = () => {
    window.location.reload();
};

export const getEnvVariable = (envKey) => {
  /* eslint no-underscore-dangle: 0 */
  const ENV = (typeof window !== 'undefined' && window._env_) || process.env;
  if (typeof window === 'undefined') return false;
  return R.prop(envKey, ENV);
};

export const getComponentDisplayKind = (componentKind) => {
  const kindDisplayNameMap = {
    staticapplication: 'Application',
    genericcomponent: 'Application',
  };
  const lcKind = componentKind?.toLowerCase();

  return kindDisplayNameMap[lcKind] ?? componentKind;
};

export const getComponentAcceptsVariables = (componentKind) => {
  const componentKindTypes = ['staticapplication', 'dockerimage'];
  return !componentKindTypes.includes(componentKind?.toLowerCase());
};

export const getScopeDisplayName = (scopeName, group) => {
  if (scopeName === 'environmentgroup') {
    if (group) {
      return `Group: ${group}`;
    }

    return 'Environment Group';
  }

  if (scopeName in nameDisplayNameMap) {
    return nameDisplayNameMap[scopeName];
  }

  return scopeName;
};

export { getErrorCode, getErrorMessage };

export function objectsHaveIdenticalContent(objA, objB) {
  const sortedA = JSON.stringify(objA, Object.keys(objA).sort());
  const sortedB = JSON.stringify(objB, Object.keys(objB).sort());

  return sortedA === sortedB;
}

export function arraysAreIdentical(arr1, arr2) {
  if (!arr1?.push || !arr2?.push) {
    return false;
  }

  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.sort();
  const sortedArr2 = arr2.sort();

  return sortedArr1.every((element, index) => element === sortedArr2[index]);
}

export function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }
  return text;
}
