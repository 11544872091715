import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import {
  showDialog,
  hideDialog,
  addNotification,
  hideSidebarTopbar,
  showPageRefreshLoader,
  toggleCriticalErrorState,
  updateBreadcrumb,
  toggleUiTheme,
  toggleMode,
  hasUnsavedChanges,
} from 'actions/global';

const initialState = {
  dialog: {
    visible: false,
  },
  criticalError: {
    isActive: false,
    details: {
      title: '',
      details: '',
    },
  },
  fullPage: false,
  breadcrumb: null,
  shouldShowRefreshLoading: false,
  mode: localStorage.getItem('themeMode') || 'light',
  uiTheme: localStorage.getItem('themeMode') || 'auto',
  statuses: [
    {
      aborting: 'Aborting action',
      aborted: 'Aborted',
      applied: 'Applied',
      applying: 'Applying',
      applying_failed: 'Applying failed',
      attached: 'Attached',
      building_failed: 'Building failed',
      building: 'Building',
      built: 'Built',
      created: 'Ready',
      connected: 'Connected',
      connection_failed: 'Connection failed',
      draft: 'Draft',
      detaching: 'Detaching',
      detaching_failed: 'Detaching failed',
      deploying: 'Deploying',
      deployment_failed: 'Operation failed',
      deployed: 'Deployed',
      deleted: 'Deleted',
      deleting: 'Deleting',
      deleting_failed: 'Deleting failed',
      destroying_failed: 'Deleting failed',
      error: 'Error',
      fail: 'Failed',
      failed: 'Failed',
      failing: 'Failing',
      git_conn_failed: 'Git connection failed',
      invalid: 'Invalid',
      in_progress: 'In Progress',
      pending: 'Pending',
      impaired: 'Impaired',
      queued: 'Queued',
      ready: 'Ready',
      restarting: 'Restarting',
      restarting_failed: 'Restarting failed',
      waiting: 'Waiting',
      not_available: 'Not available',
      exited: 'Exited',
      running: 'Deployed',
      running_with_errors: 'Deployed with errors',
      deployed_with_errors: 'Deployed with errors',
      partially_running_with_errors: 'Partially deployed with errors',
      partially_deployed_with_errors: 'Partially deployed with errors',
      partially_running: 'Partially deployed',
      partially_deployed: 'Partially deployed',
      running_issues: 'Deployed with issues',
      stopping: 'Stopping',
      stopping_failed: 'Stopping failed',
      stopped: 'Stopped',
      starting: 'Starting',
      starting_failed: 'Starting failed',
      success: 'Successful',
      syncing: 'Syncing',
      syncing_failed: 'Syncing failed',
      updating: 'Updating',
      updating_failed: 'Updating failed',
      updating_environment: 'Updating environment',
      valid: 'Valid',
    },
  ],
  hasUnsavedChanges: false,
};

const showDialogHandler = [
  showDialog,
  (state, action) => {
    return {
      ...state,
      dialog: {
        ...state.dialog,
        ...action.payload,
        visible: true,
      },
    };
  },
];

const hideDialogHandler = [
  hideDialog,
  (state) => {
    return {
      ...state,
      dialog: {
        visible: false,
      },
    };
  },
];

const showNotificationHandler = [
  addNotification,
  (state) => {
    return {
      ...state,
    };
  },
];
const hideSidebarTopBarHandler = [
  hideSidebarTopbar,
  (state, action) => {
    return {
      ...state,
      fullPage: action.payload,
    };
  },
];

const updateBreadcrumbHandler = [
  updateBreadcrumb,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      breadcrumb: payload,
    };
  },
];
const toggleCriticalErrorStateHandler = [
  toggleCriticalErrorState,
  (state, action) => {
    const screenAlreadyActive = state?.criticalError?.isActive === true && action?.payload?.isActive === true;

    // prevents error screen to change if already active
    if (screenAlreadyActive) {
      return state;
    }

    return {
      ...state,
      criticalError: action.payload,
    };
  },
];

const hasUnsavedChangesHandler = [
  hasUnsavedChanges,
  (state, action) => {
    return {
      ...state,
      hasUnsavedChanges: action.payload,
    };
  },
];
const showPageRefreshLoaderHandler = [
  showPageRefreshLoader,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      shouldShowRefreshLoading: payload,
    };
  },
];
const toggleModeHandler = [
  toggleMode,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      mode: payload,
    };
  },
];

const toggleUiThemeHandler = [
  toggleUiTheme,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      uiTheme: payload,
    };
  },
];

const globalReducer = handleActions(
  new Map([
    showDialogHandler,
    hideDialogHandler,
    showNotificationHandler,
    hideSidebarTopBarHandler,
    toggleCriticalErrorStateHandler,
    hasUnsavedChangesHandler,
    showPageRefreshLoaderHandler,
    updateBreadcrumbHandler,
    toggleModeHandler,
    toggleUiThemeHandler,
  ]),
  R.clone(initialState)
);

export default globalReducer;
