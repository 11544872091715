import React, { memo } from 'react';
import PropTypes from 'prop-types';
import StyledSwitch from './Switch.style';

const Switch = ({
  title,
  className,
  checkedChildren,
  unCheckedChildren,
  checked,
  defaultChecked,
  disabled,
  onChange,
}) => (
  <StyledSwitch
    title={title}
    className={className}
    checkedChildren={checkedChildren}
    unCheckedChildren={unCheckedChildren}
    checked={checked}
    defaultChecked={defaultChecked}
    onChange={onChange}
    disabled={disabled}
  />
);

Switch.defaultProps = {
  title: null,
  className: '',
  checkedChildren: 'On',
  unCheckedChildren: 'Off',
  checked: false,
  defaultChecked: false,
  disabled: false,
  onChange: () => {},
};

Switch.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  checkedChildren: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  unCheckedChildren: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default memo(Switch);
