import styled, { css } from 'styled-components';

const StyledTimeframes = styled.div`
  ${({ theme }) => {
    const { palette } = theme;

    return css`
      border-bottom: 1px solid ${palette.border[2]};
      padding-bottom: 32px;
      #dark & {
        border-bottom: 1px solid ${palette.border[4]};
      }
      .timeframe-list {
        .timeframe-item {
          margin-bottom: 18px;
          position: relative;
          @media (max-width: 767px){
            margin-bottom: 24px;
          }
          .timeframe-item-inputs,
          .timeframe-select{ 
            display: flex;
            @media (max-width: 767px){
              flex-wrap: wrap;
              width: 100%;
            }
            &.ant-select-disabled{
              opacity: 0.4;
            }
            .timeframe-select-wrapper {
              margin-right: 18px;
              @media (max-width: 767px){
                width: 100%;
                margin-right: 0;
                #frequency-container{
                  width: 100%;
                  margin-bottom: 8px;
                }
                
              }
              @media (max-width: 402px){
                #hour-container{
                  max-width: 124px;
                }
              }
              .ant-select-open{
                .ant-select-selector{
                  border-radius: 4px;
                }
               .ant-select-arrow{
                  transform: translateY(-50%) rotate(180deg) !important;
                  transform-origin: 50% 50%;
                  transition: all 0.5s;
                }
              }
              .ant-select-selector{
                height: 36px;
                padding: 9px 13px;
                @media (max-width: 767px){
                  width: 100%;
                }
                #dark & {
                  background-color: ${palette.background[0]};
                }
                .ant-select-selection-item{
                  line-height: 15px ;
                  color: ${palette.text[0]};
                  font-size: ${palette.fontSize[2]};
                  #dark & {
                    color: ${palette.grays[10]};
                  }
                }
                .ant-select-selection-placeholder{
                  line-height: 15px ;
                  color: ${palette.text[1]};
                  font-size: ${palette.fontSize[2]};
                }
              }
              .ant-select-arrow{
                transition: all 0.5s;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            .remove-timeframe-btn {
              display: flex;
              align-items: center;
              height: auto;
              padding: 0 8px;
              color: #737376;
              >div{
                display: inline-block;
              }
            }
          }
          .timeframe-item-error {
            min-height: 10px;
            color: #ff4d4f;
            font-size: 9px;
            line-height: 10px;
            position: absolute;
            left: 0px;
            top: 37px;
          }
        }
        .info {
          font-size: ${palette.fontSize[2]};
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: ${palette.grays[2]};
          #dark & {
            color: ${palette.grays[9]};
          }
          .info-icon{
            width: 16px;
            height: 16px;
            background-color: ${palette.color[4]};
            border-radius: 100%;
            color: ${palette.text[3]};
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-right: 6px;
          }
        }
      }

      .add-timeframe-btn {
        height: auto;
        padding: 0;
        color: ${palette.primary[0]};
        border: none;
        &:hover {
          color: ${palette.primary[1]};
        }
      }
    `;
  }}
`;

StyledTimeframes.displayName = 'StyledTimeframes';
export default StyledTimeframes;
