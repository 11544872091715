import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import type { ScrollbarProps } from 'react-custom-scrollbars';

type autoHideProps = 'autoHide'|'autoHideTimeout'|'autoHideDuration';
type autoSizeProps = 'autoHeightMin'|'autoHeightMax';
type extraProps = 'universal'|'thumbMinSize';

export type HardcodedProps = autoHideProps|autoSizeProps|extraProps;

export interface IProps extends Omit<ScrollbarProps, HardcodedProps> {
}

export default function customScrollBar(props: IProps): React.ReactElement {
  return <Scrollbars
    autoHide
    autoHideTimeout={1000}
    autoHideDuration={200}

    // autoHeight
    autoHeightMin={0}
    autoHeightMax={200}

    thumbMinSize={30}

    universal

    {...props}
  />;
}
