// @review should move this as part of the /me response typings
export interface MeData {
    isLoading: boolean;

    intercomExternalId: string;
    intercomUserHash: string;

    firstName: string;
    lastName: string|undefined;

    email: string;
    phoneNumber: string|undefined;
}

export function isFullyLoaded(data: MeData): boolean {
    if (data.isLoading) {
        return false;
    }

    return !!((data.firstName || data.lastName || data.email));
}

export function getFullName(data: MeData): string {
    if (data.firstName && data.lastName) {
      return `${data.firstName} ${data.lastName}`;
    }

    return data.firstName || data.lastName || '';
}
