import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import SVG from 'components/svg';
import 'react-loading-skeleton/dist/skeleton.css';
import StyledBreadcrumb from './Breadcrumb.style';

const BreadcrumbSkeleton = ({ itemsNo }) => {
  return (
    <StyledBreadcrumb>
      <>
        <div className="breadcrumb-item-wrapper">
          <div className="breadcrumb-item">
            <div className="d-flex">
              <SVG name="bicon-home" width={16} height={16} />
            </div>
            <span className="breadcrumb-divider">
              <SVG name="bicon-chevron-right" width={16} height={16} />
            </span>
            <Skeleton width={80} height={14} containerClassName="d-flex" />
            {[...Array(itemsNo)].map(() => (
              <>
                <span className="breadcrumb-divider">
                  <SVG name="bicon-chevron-right" width={16} height={16} />
                </span>
                <Skeleton width={80} height={14} containerClassName="d-flex" />
              </>
            ))}
          </div>
        </div>
      </>
    </StyledBreadcrumb>
  );
};

BreadcrumbSkeleton.defaultProps = {};

BreadcrumbSkeleton.propTypes = {
  itemsNo: PropTypes.number.isRequired,
};

export default BreadcrumbSkeleton;
