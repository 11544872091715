const initialState = {
  isLoading: false,
  isVerifying: false,
  isDefaulting: false,
  isLoadingRegistries: true,
  isConnecting: false,
  isConnectingEcr: false,
  isLoadingRepositories: false,
  isGitResponseLoading: false,
  integrations: [],
  repositories: [],
  registries: [],
  clusterDetails: null,
  kubernetes: [],
  errorMessage: '',
  updateResourceId: '',
  envUsage: 0,
  isSyncing: false,
  currentIntegration: null,
  privateGitErrorMessage: '',
  slackIntegrations: null,
  channels: null,
  isAddingCluster: true,
};

export default initialState;
