import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { SVG } from 'components';
import Button from 'theme/uielements/button';
import StyledBackButton from './BackButton.style';

const BackButton = ({ history, url, componentCount, hasList }) => {
  return (
    <StyledBackButton>
      <Button
        className="back-button"
        type="link"
        onClick={() => {
          if(url){
            history.push(url, {hasList, count: componentCount})
          }else {
            history.goBack();
          }
        }}
      >
        <SVG name="bicon-chevron-left" className="breadcrumb-divider" />
        <span className="copy">Back</span>
      </Button>
    </StyledBackButton>
  );
};
BackButton.defaultProps = {
  hasList: null,
  url: null,
  componentCount: null
};
BackButton.propTypes = {
  hasList: PropTypes.bool,
  url: PropTypes.string,
  componentCount: PropTypes.number,
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(BackButton);
