/* eslint-disable max-lines */
import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import {updateProjectResource} from 'actions/global'
import {
  getProjects,
  addProject,
  getProjectDetails,
  updateProject,
  updateProjectTimeframes,
  deleteProject,
  createTimeframe,
  editTimeframe,
  removeTimeframe,
  revertTimeframes,
  resetState,
  getProjectVariables,
  createVariable,
  updateVariable,
  deleteVariable,
  resetPrjErrors,
  setProjectName,
  saveBuildSettings,
  saveMetricsSettings,
  getSecretValue,
  hideSecretValue,
  resetSecretValues,
  resetSecretValue,
  resetResourceId,
  getMetricsCycleTime,
  getMetricsDeploymentFrequency,
  storeRepositories, getProjectTimeframes,

} from '../actions';
import initialState from './initialState';

const getProjectsHandler = [
  getProjects, (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
        isLoadingProjects: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        isLoadingProjects: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isLoading: false,
      isLoadingProjects: false,
      errorMessage: '',
      updateResourceId: '',
      projectsNo: payload['hydra:totalItems'],
      list: payload['hydra:member'],
    };
  },
];

const addProjectHandler = [
  addProject, (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }
    const updatedList = R.append(payload, state.list);
    return {
      ...state,
      isLoading: false,
      projectsNo: state.projectsNo + 1,
      errorMessage: '',
      list: updatedList,
      updateResourceId: '',
    };
  },
];

const getProjectTimeframesHandler = [
  getProjectTimeframes, (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isConnecting: true,
        isLoading: true,
      };
    }
    if (error) {
      return {
        ...state,
        isConnecting: false,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }
    return {
      ...state,
      isLoading: false,
      isConnecting: false,
      timeframes: payload.reduce((acc, item) => {
        const { id, timeframe, startHour, endHour } = item;
        acc.push({
          ...item,
          id,
          timeframe: timeframe.id,
          startHour, endHour,
          isReadOnly: true,
        });
        return acc;
      }, []),
      addedTimeframes: [],
      markedToDeleteTimeframes: [],
      errorMessage: '',
      updateResourceId: '',
    };
  }
]

const getProjectDetailsHandler = [
  getProjectDetails, (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isConnecting: true,
        isLoading: true,
      };
    }
    if (error) {
      return {
        ...state,
        isConnecting: false,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }
    const { projectSettings, organizationId } = payload;
    return {
      ...state,
      isLoading: false,
      isConnecting: false,
      projectId: projectSettings.id,
      project: projectSettings,
      organizationId,
      ...R.omit(['id'], projectSettings),
      errorMessage: '',
      updateResourceId: '',
    };
  },
];

const updateProjectHandler = [
  updateProject, (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isSaving: true,
      };
    }

    if (error) {
      return {
        ...state,
        isSaving: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }

    return {
      ...state,
      isSaving: false,
      errorMessage: '',
      ...payload,
    };
  },
];

const updateProjectTimeframesHandler = [
  updateProjectTimeframes, (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
        isUpdatingTimeframes: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        isUpdatingTimeframes: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }
    return {
      ...state,
      isLoading: false,
      isUpdatingTimeframes: false,
      errorMessage: '',
    };
  },
];

const deleteProjectHandler = [
  deleteProject, (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isDeleting: true,
      };
    }
    if (error) {
      return {
        ...state,
        isDeleting: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }
    return {
      ...state,
      isDeleting: false,
      projectsNo: (state.projectsNo - 1),
      errorMessage: '',
    };
  },
];

const createTimeframeHandler = [
  createTimeframe, (state) => {
    const tempTimeframes = state.addedTimeframes;
    let newTimeframe;
    if(tempTimeframes.length > 0 && tempTimeframes[tempTimeframes.length - 1].endHour === null){
      newTimeframe = {
        id: Date.now(),
        timeframe: '',
        endHour: null,
        startHour: null,
        disableStart: true,
      };
    } else {
      newTimeframe = {
        id: Date.now(),
        timeframe: '',
        endHour: null,
        startHour: null,
        disableStart: false,
      };
    }
    return {
      ...state,
      addedTimeframes: R.append(newTimeframe, state.addedTimeframes),
    };
  },
];

const editTimeframeHandler = [
  editTimeframe, (state, action) => {
    const { payload } = action;
    let adjustedTimeframes = state.addedTimeframes;
    let index = R.findIndex(R.propEq('id', payload.id))(state.addedTimeframes);
    if(state.addedTimeframes[index+1] && state.addedTimeframes[index+1].disableStart === true){
      const nextTimeframe = state.addedTimeframes[index+1];
      nextTimeframe.disableStart = false;
      adjustedTimeframes = R.adjust(
        (index+1),
        () => ({...nextTimeframe}),
        state.addedTimeframes
      );
    }
    if(payload.timeframe !== state.addedTimeframes[index].timeframe){
      payload.startHour = null
      payload.endHour = null
    }
    if(index < 0){
      index = R.findIndex(R.propEq('id', payload.id))(state.timeframes);
      return {
        ...state,
        timeframes: R.adjust(
          index,
          (timeframe) => ({...timeframe, ...payload,}),
          state.timeframes
        ),
      };
    }
    return {
      ...state,
      addedTimeframes: R.adjust(
        index,
        (timeframe) => ({...timeframe, ...payload,}),
        adjustedTimeframes
      ),
    };
  },
];

const removeTimeframeHandler = [
  removeTimeframe, (state, action) => {
    const { payload } = action;
    let adjustedTimeframes = state.addedTimeframes;
    const addedIndex = R.findIndex(R.propEq('id', payload))(state.addedTimeframes);
    if(state.addedTimeframes[addedIndex+1] &&
      state.addedTimeframes[addedIndex+1].disableStart === true){
      const nextTimeframe = state.addedTimeframes[addedIndex+1];
      nextTimeframe.disableStart = false;
      adjustedTimeframes = R.adjust(
        (addedIndex+1),
        () => ({
          ...nextTimeframe
        }),
        state.addedTimeframes
      );
    }
    if (addedIndex >= 0) {
      return {
        ...state,
        addedTimeframes: R.remove(addedIndex, 1, adjustedTimeframes),
      };
    }
    const index = R.findIndex(R.propEq('id', payload))(state.timeframes);
    const timeframeToDelete = state.timeframes[index];
    return {
      ...state,
      timeframes: R.remove(index, 1, state.timeframes),
      markedToDeleteTimeframes: R.append(timeframeToDelete, state.markedToDeleteTimeframes),
    };
  },
];

const revertTimeframesHandler = [
  revertTimeframes, (state) => {
    return {
      ...state,
      timeframes: R.concat(state.timeframes, state.markedToDeleteTimeframes),
      addedTimeframes: [],
      markedToDeleteTimeframes: [],
    };
  },
];

const resetStateHandler = [resetState, (state) => {
  const {projectsNo} = state;
  return{
    ...initialState,
    projectsNo
  }
}];
const updateProjectResourceHandler = [
  updateProjectResource, (state, action) => {
    const { payload } = action;
    return {
      ...state,
      updateResourceId: payload,
    };
  },
];
const getProjectVariablesHandler = [
  getProjectVariables, (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
      };
    }
    return {
      ...state,
      isLoading: false,
      variables: payload.data,
      isSearch: payload.isSearch,
      updateResourceId: ''
    };
  },
];
const createVariableHandler = [
  createVariable, (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.data,
      };
    }
    const index = R.findIndex(R.propEq('name', payload.name))(state.variables);
    if(index > -1){
      const variables = R.adjust(
        index,
        () => ({...payload,}),
        state.variables
      );
      return {
        ...state,
        isLoading: false,
        variables
      }
    }
    return {
      ...state,
      isLoading: false,
      errorMessage: null,
      variables: R.prepend(payload, state.variables),
    };
  },
];
const updateVariableHandler = [
  updateVariable, (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.data,
      };
    }
    const index = R.findIndex(R.propEq('id', payload.id))(state.variables);
    const variables = R.adjust(
      index,
      () => ({
        ...payload,
      }),
      state.variables
    );
    return {
      ...state,
      isLoading: false,
      errorMessage: null,
      variables,
    };
  },
];
const deleteVariableHandler = [
  deleteVariable, (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: payload.response.message,
      };
    }
    const index = R.findIndex(R.propEq('id', payload))(state.variables);
    return {
      ...state,
      isLoading: false,
      variables: R.remove(index, 1, state.variables),
    };
  },
];
/** save project build settings */
const saveBuildSettingsHandler = [
  saveBuildSettings, (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isSavingBuildSettings: true,
        updateResourceId: '',
      };
    }

    if (error) {
      return {
        ...state,
        isSavingBuildSettings: false,
        violations: payload?.response?.data?.violations || [],
        errorMessage: payload.response.message,
      };
    }

    return {
      ...state,
      isSavingBuildSettings: false,
      project: {...payload.data},
    };
  },
];
const setProjectNameHandler = [
  setProjectName, (state, action) => {
  const {payload} = action;
    return {
      ...state,
      name: payload,
    }
  }
]
const resetPrjErrorsHandler = [
  resetPrjErrors, (state) => {
    return {
      ...state,
      errorMessage: null
    }
  }
]
const getSecretValueHandler = [
  getSecretValue, (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
      };
    }
    if (error) {
      return {
        ...state,
        errorMessage: payload.response.message,
      };
    }
    if(payload.isEdit){
      return {
        ...state,
        secretValue: payload.data.value,
      }
    }
    const secret = {
      name: payload.name,
      value: payload.value
    }
    return {
      ...state,
      secretValues: R.append(secret, state.secretValues),
    }
  }
]

const hideSecretValueHandler = [
  hideSecretValue, (state, action) => {
    const {payload} = action;

    const index = R.findIndex(R.propEq('name', payload))(state.secretValues);
    return {
      ...state,
      secretValues: R.remove(index, 1, state.secretValues),
    }
  }
]

const resetSecretValuesHandler = [
  resetSecretValues, (state) => {
    return {
      ...state,
      secretValues: [],
      secretValue: null,
    }
  }
]
const resetSecretValueHandler = [
  resetSecretValue, (state) => {
    return {
      ...state,
      secretValue: null,
    }
  }
]

const resetResourceIdHandler = [
  resetResourceId, (state) => {
    return {
      ...state,
      updateResourceId: '',
    }
  }
];

const getMetricsCycleTimeHandler = [
  getMetricsCycleTime, (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        violations: payload?.response?.data?.violations || [],
        errorMessage: payload.response.message,
      };
    }

    return {
      ...state,
      isLoading: false,
      cycleTimeMetrics: payload
    };
  },
];

const getMetricsDeploymentFrequencyHandler = [
  getMetricsDeploymentFrequency, (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        violations: payload?.response?.data?.violations || [],
        errorMessage: payload.response.message,
      };
    }

    return {
      ...state,
      isLoading: false,
      deploymentFrequencyMetrics: payload
    };
  },
];
const storeRepositoriesHandler = [
  storeRepositories, (state, action) => {
  const { payload} = action;
    return {
      ...state,
      isLoading: false,
      isDoneLoadingRepos: true,
      allRepos: payload,
    };
  }
];

/** save project build settings */
const saveMetricsSettingsHandler = [
  saveMetricsSettings, (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isSavingMetricsSettings: true,
      };
    }

    if (error) {
      return {
        ...state,
        isSavingMetricsSettings: false,
        violations: payload?.response?.data?.violations || [],
        errorMessage: payload.response.message,
      };
    }

    return {
      ...state,
      isSavingMetricsSettings: false,
      project: {...payload.data},
    };
  },
];
const reducer = handleActions(
  new Map([
    getProjectsHandler,
    addProjectHandler,
    getProjectDetailsHandler,
    updateProjectHandler,
    getProjectTimeframesHandler,
    updateProjectTimeframesHandler,
    deleteProjectHandler,
    createTimeframeHandler,
    editTimeframeHandler,
    removeTimeframeHandler,
    revertTimeframesHandler,
    resetStateHandler,
    updateProjectResourceHandler,
    getProjectVariablesHandler,
    createVariableHandler,
    updateVariableHandler,
    deleteVariableHandler,
    resetPrjErrorsHandler,
    setProjectNameHandler,
    saveBuildSettingsHandler,
    saveMetricsSettingsHandler,
    getSecretValueHandler,
    hideSecretValueHandler,
    resetSecretValuesHandler,
    resetSecretValueHandler,
    resetResourceIdHandler,
    getMetricsCycleTimeHandler,
    getMetricsDeploymentFrequencyHandler,
    storeRepositoriesHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
