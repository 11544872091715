const initialState = {
  list: [],
  projectsNo: null,
  isSaving: false,
  isSavingBuildSettings: false,
  isSavingMetricsSettings: false,
  isLoading: false,
  isLoadingProjects: true,
  isDeleting: false,
  projectId: null,
  organizationId: null,
  name: null,
  autoUpdate: false,
  organization: '',
  environments: [],
  projectVariables: [],
  createdAt: '',
  updatedAt: '',
  deletedAt: '',
  accessibleEnvironmentsCount: null,
  timeframes: [],
  addedTimeframes: [],
  markedToDeleteTimeframes: [],
  errorMessage: null,
  updateResourceId: '',
  variables: [],
  isConnecting: false,
  project: null,
  environment: {
    id: null,
    unique: '',
    name: '',
    type: '',
    autoUpdate: false,
    project: '',
    parentEnvironment: null,
    status: '',
    createdAt: '',
    updatedAt: '',
    hasDraft: true,
    organization: '',
    timeframes: [],
    addedTimeframes: [],
    markedToDeleteTimeframes: [],
    createEphemeralOnPrCreate: false,
    destroyEphemeralOnPrClose: false,
    isSavingUrlHandle: false,
    draftComponentsResponse: {
      urlHandle: '',
    }
  },
  sources: [],
  isSearch: false,
  secretValues: [],
  secretValue: null,
  violations: [],
  allRepos: [],
  isDoneLoadingRepos: false,
  cycleTimeMetrics: null,
  deploymentFrequencyMetrics: null,
  isUpdatingTimeframes: false,
};

export default initialState;
