import React from 'react';
import PropTypes from 'prop-types';
import { Spin as SpinComponent } from 'antd';

const Spin = ({ className, ...otherProps }) => (
  <div className={`spin-wrapper ${className}`}>
    <SpinComponent {...otherProps} />
  </div>
);

Spin.defaultProps = {
  className: '',
};

Spin.propTypes = {
  className: PropTypes.string,
};

export default Spin;
