import React from 'react';
import ReloadButton from 'components/reloadButton';
import StyledChunkErrorPage from './ChunkError.style';

const ChunkError = () => {
  return (
    <StyledChunkErrorPage className="hasCustomPadding">
      <div className="chunk-error-img">
        <svg
          className="chunk-svg"
          width="51"
          height="42"
          viewBox="0 0 51 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.0485 0.18455C21.8806 0.0803835 24.6998 0.619341 27.2937 1.76142C29.8877 2.9033 32.1889 4.61851 34.0246 6.77788C35.5069 8.52142 36.6544 10.5181 37.4146 12.6668H38.5C41.2771 12.6687 43.9758 13.5952 46.1679 15.3004C48.36 17.0056 49.9218 19.3923 50.6068 22.0835C51.2918 24.775 51.061 27.6177 49.951 30.1633C48.8408 32.7089 46.9146 34.8125 44.4762 36.1418C43.466 36.6925 42.2006 36.3202 41.65 35.3098C41.0991 34.2995 41.4716 33.0341 42.4819 32.4835C44.1075 31.5973 45.3916 30.195 46.1316 28.4979C46.8716 26.8008 47.0256 24.9056 46.5689 23.1112C46.1123 21.317 45.071 19.726 43.6096 18.5891C42.1483 17.4525 40.35 16.8348 38.4985 16.8335H35.875C34.9252 16.8335 34.0956 16.1912 33.8579 15.2716C33.306 13.1375 32.2777 11.156 30.85 9.47663C29.4223 7.79705 27.6323 6.46309 25.6148 5.57476C23.5973 4.68663 21.4048 4.26726 19.2019 4.34851C16.9989 4.42955 14.8431 5.00913 12.8964 6.0433C10.9498 7.07767 9.2629 8.53976 7.96269 10.3198C6.66248 12.0998 5.78248 14.1514 5.38936 16.3206C4.99623 18.4895 5.09977 20.7195 5.69248 22.8427C6.28498 24.9658 7.35123 26.927 8.81102 28.5789C9.5729 29.441 9.49165 30.7577 8.62956 31.5195C7.76727 32.2814 6.4506 32.2002 5.68873 31.3381C3.81206 29.2143 2.44102 26.6927 1.67915 23.9629C0.917064 21.2331 0.783938 18.366 1.28956 15.5773C1.79519 12.7885 2.92623 10.1506 4.59811 7.86205C6.26977 5.57351 8.43873 3.69351 10.9416 2.36392C13.4444 1.03413 16.2162 0.288925 19.0485 0.18455ZM24.5269 19.5271C25.3404 18.7135 26.6594 18.7135 27.4731 19.5271L35.8064 27.8604C36.62 28.6739 36.62 29.9931 35.8064 30.8066C34.9927 31.6202 33.6737 31.6202 32.8602 30.8066L28.0833 26.0298V39.7502C28.0833 40.9008 27.1504 41.8335 26 41.8335C24.8494 41.8335 23.9166 40.9008 23.9166 39.7502V26.0298L19.1398 30.8066C18.326 31.6202 17.0071 31.6202 16.1935 30.8066C15.3798 29.9931 15.3798 28.6739 16.1935 27.8604L24.5269 19.5271Z"
            fill="#1F2DCF"
          />
        </svg>
      </div>
      <div className="chunk-error-title mb-1-5">Bunnyshell application update available</div>
      <div className="chunk-error-description">
        A new version of the Bunnyshell application is now available. Reload the page to use it.
      </div>
      <ReloadButton>Reload now</ReloadButton>
    </StyledChunkErrorPage>
  );
};

export default ChunkError;
