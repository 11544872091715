import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import { updateTemplatesRepoResource } from 'actions/global';
import initialState from './initialState';
import {
  getPublicTemplates,
  getCustomTemplates,
  getTemplatesRepositories,
  addTemplatesRepository,
  syncTemplateRepository,
  deleteTemplateRepository,
  getTemplatesForRepository,
  getTemplateDetails,
  getTemplateDefinition,
  resetViolations,
  resetUpdatedResource,
  resetTemplatesState,
  updateCustomRepositoriesNumber,
} from '../actions';

// get public list
const getPublicTemplatesHandler = [
  getPublicTemplates,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoadingPublicTemplates: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoadingPublicTemplates: false,
      };
    }

    return {
      ...state,
      isLoadingPublicTemplates: false,
      publicTemplates: {
        totalItems: payload['hydra:totalItems'],
        templates: payload['hydra:member'],
      },
    };
  },
];

// get custom templates
const getCustomTemplatesHandler = [
  getCustomTemplates,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoadingCustomTemplates: true,
      };
    }

    if (error && payload.response?.code === 'ERR_CANCELED') {
      return state;
    }

    if (error) {
      return {
        ...state,
        isLoadingCustomTemplates: false,
      };
    }

    const newTemplatesObjectList = {};
    const arrOfTemplates = payload['hydra:member'];

    arrOfTemplates.forEach((template) => {
      const repoID = template.templatesRepository.split('/api/templates_repositories/')[1];
      const targetedRepositoryTemplates = newTemplatesObjectList[repoID] || [];
      newTemplatesObjectList[repoID] = [...targetedRepositoryTemplates, template];
    });

    return {
      ...state,
      isLoadingCustomTemplates: false,
      templatesObjectList: newTemplatesObjectList,
    };
  },
];

// get custom repos list
const getTemplatesRepositoriesHandler = [
  getTemplatesRepositories,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoadingRepositoriesList: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoadingRepositoriesList: false,
      };
    }

    const repositories = [...payload['hydra:member']];

    return {
      ...state,
      isLoadingRepositoriesList: false,

      templateRepositoryTopics: repositories.map((repository) => `/api/templates_repositories/${repository.id}`),
      customRepositoriesCount: repositories.length,
      repositories,
    };
  },
];

// add
const addTemplatesRepositoryHandler = [
  addTemplatesRepository,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isAddingRepository: true,
        violations: [],
      };
    }

    if (error) {
      return {
        ...state,
        isAddingRepository: false,
        violations: payload.response?.data?.violations || [],
      };
    }

    const repositories = [...state.repositories, payload];

    return {
      ...state,
      isAddingRepository: false,
      customRepositoriesCount: repositories.length,
      repositories,
    };
  },
];

// sync
const syncTemplateRepositoryHandler = [
  syncTemplateRepository,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
      };
    }

    if (error) {
      return {
        ...state,
      };
    }

    const repositories = [...state.repositories].map((item) => {
      return item.id === payload.id ? { ...item, payload } : item;
    });

    return {
      ...state,
      repositories,
    };
  },
];

// delete
const deleteTemplateRepositoryHandler = [
  deleteTemplateRepository,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isDeletingRepository: true,
      };
    }

    if (error) {
      return {
        ...state,
        isDeletingRepository: false,
      };
    }

    const repositories = [...state.repositories].filter((repository) => repository.id !== payload.deletedId);

    return {
      ...state,
      isDeletingRepository: false,
      customRepositoriesCount: repositories.length,
      repositories,
    };
  },
];

// get custom templates
const getTemplatesForRepositoryHandler = [
  getTemplatesForRepository,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
      };
    }

    if (error) {
      return {
        ...state,
      };
    }

    const newTemplatesObjectList = {...state.templatesObjectList};
    // add id as key and templates as value
    newTemplatesObjectList[payload.repId] = [...payload.templates];

    return {
      ...state,
      templatesObjectList: newTemplatesObjectList,
    };
  },
];

// get template details
const getTemplateDetailsHandler = [
  getTemplateDetails,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoadingTemplateDetails: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoadingTemplateDetails: false,
      };
    }

    return {
      ...state,
      isLoadingTemplateDetails: false,
      templateDetails: payload,
    };
  },
];

// get template definition
const getTemplateDefinitionHandler = [
  getTemplateDefinition,
  (state, action) => {
    const { ready, error, payload } = action;
    if (!ready) {
      return {
        ...state,
        isLoadingDefinition: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoadingDefinition: false,
      };
    }

    return {
      ...state,
      isLoadingDefinition: false,
      templateDefinition: payload,
    };
  },
];

// mercure
const updateTemplatesRepoResourceHandler = [
  updateTemplatesRepoResource,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      updateTemplatesRepoResourceId: payload,
    };
  },
];

const resetViolationsHandler = [
  resetViolations,
  (state) => {
    return {
      ...state,
      violations: [],
    };
  },
];

const resetUpdatedResourceHandler = [
  resetUpdatedResource,
  (state) => {
    return {
      ...state,
      updateTemplatesRepoResourceId: -1,
    };
  },
];

const updateCustomRepositoriesNumberHandler = [
  updateCustomRepositoriesNumber,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      customRepositoriesCount: payload,
    };
  }
]; 

const resetTemplatesStateHandler = [
  resetTemplatesState,
  (state) => {
    return {
      ...state,
      templateDetails: {},
      templateDefinition: ``,
      repositories: [],
      templatesObjectList: {},
      templateRepositoryTopics: [],
      updateTemplatesRepoResourceId: -1,
      publicTemplates: {
        totalItems: 0,
        templates: [],
      },
      customRepositoriesCount: 0,
      violations: [],
    };
  },
];

const reducer = handleActions(
  new Map([
    getPublicTemplatesHandler,
    getCustomTemplatesHandler,
    getTemplatesRepositoriesHandler,
    addTemplatesRepositoryHandler,
    syncTemplateRepositoryHandler,
    deleteTemplateRepositoryHandler,
    getTemplatesForRepositoryHandler,
    getTemplateDetailsHandler,
    getTemplateDefinitionHandler,
    updateTemplatesRepoResourceHandler,
    resetViolationsHandler,
    resetUpdatedResourceHandler,
    resetTemplatesStateHandler,
    updateCustomRepositoriesNumberHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
