import styled, { css } from 'styled-components';
import { ToastContainer } from 'react-toastify';

const StyledNotificationsContainer = styled(ToastContainer)`
  ${({ theme }) => {
    const { palette } = theme;

    return css`
      &.Toastify__toast-container {
        min-width: 380px;
        left: unset;
        padding: 0;
        top: 72px;
        max-width: 80vw;
        &.Toastify__toast-container--bottom-right {
          top: auto;
          bottom: 72px;
          border-radius: 4px;
          .Toastify__toast-icon {
            display: none;
          }
          .Toastify__toast-body.Toastify__toast-body {
            background: #F4F5FD;
            padding: 30px 17px 30px 30px;
          }
          .Toastify__toast {
            cursor: initial;
          }
        }

        .Toastify__toast {
          padding: 0;
          margin-bottom: 12px;
          color: ${palette.color[1]};
          overflow: visible;
          border-radius: 6px;
          box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
          background-color: ${palette.color[7]};
          #dark & {
            background-color: ${palette.background[1]};
          }
          .Toastify__toast-body {
            align-items: flex-start;
            padding: 30px 18px;
            #dark & {
              background-color: ${palette.background[1]};
            }
            .Toastify__toast-icon {
              margin-top: 0;
              margin-right: 12px;
            }
          }
        }

        .Toastify__progress-bar {
          width: 0;
          height: 0;
        }

        .close-icon {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          margin: 0;
          padding: 12px;
        }
      }
    `;
  }}
`;

StyledNotificationsContainer.displayName = 'StyledNotificationsContainer';
export default StyledNotificationsContainer;
