import { toLower, includes } from 'utils';

const filterTable = (searchTerm) => (project) => {
  if (searchTerm === '') return project;
  const { name } = project;
  if (includes(toLower(name), toLower(searchTerm))) return project;
  return null;
};

export default filterTable;
