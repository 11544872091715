/* eslint-disable max-params */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showNotification } from 'actions/global';
import compose, { WithLogin } from 'hocs';
import { createSelector } from '@reduxjs/toolkit';
import { log, isBlank, getEnvVariable } from 'utils';
import userManager from 'modules/userManager';
import { EventSourceContext, isESConnectionOpen, getTopics } from './helpers';

const HUB_URL = getEnvVariable('REACT_APP_EVENT_SOURCE_HUB');
const BEARER_AUTH_TOKEN = getEnvVariable('REACT_APP_EVENT_SOURCE_SUBSCRIBER_AUTH_BEARER');

let lastEventId = null;

class EventSourceProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventSource: null,
    };
  }

  componentDidMount() {
    const isLoggedIn = userManager.isLoggedIn();
    if (isLoggedIn) {
      this.connectEventSource();
    }
  }

  componentDidUpdate(prevProps) {
    const { topics } = this.props;
    if (!R.equals(topics, prevProps.topics)) {
      log.info('EventSource: Updating topics..');
      this.disconnectEventSource();
      this.connectEventSource();
    }
  }

  componentWillUnmount() {
    this.disconnectEventSource();
  }

  connectEventSource = () => {
    const { topics } = this.props;
    const internalEventSource = this.state.eventSource;
    const isLoggedIn = userManager.isLoggedIn();

    /* we already have an EventSource connection OR not logged */
    if (isESConnectionOpen(internalEventSource) || !isLoggedIn) {
      return null;
    }

    /* no topics */
    if (isBlank(topics)) {
      log.info('EventSource: No topics to subscribe to.');
      return null;
    }

    const eventSourceURL = new URL(HUB_URL);

    const headers = {
      Authorization: `Bearer ${BEARER_AUTH_TOKEN}`,
    };

    if (lastEventId !== null) {
      // @see https://mercure.rocks/spec#reconciliation
      headers['Last-Event-ID'] = lastEventId;
    }

    /* add topics */
    topics.forEach((topic) => eventSourceURL.searchParams.append('topic', topic));

    const eventSource = new EventSourcePolyfill(eventSourceURL, {
      headers,
      heartbeatTimeout: 30 * 60 * 1000,
    });

    // just for debug
    eventSource.id = Math.random();

    // if (isBlank(eventSource)) {return null;}

    eventSource.addEventListener('open', () => {
      log.info('EventSource connection opened', eventSource);
    });

    eventSource.addEventListener('message', (message) => {
      log.info('A message has been received', message);
      lastEventId = message.lastEventId;
    });

    this.setState({ eventSource });

    return null;
  };

  disconnectEventSource = () => {
    const { eventSource } = this.state;
    if (!isBlank(eventSource)) {
      log.info('EventSource connection closed');
      eventSource.close();
    }
  };

  render() {
    const { children } = this.props;
    const { eventSource } = this.state;
    return <EventSourceContext.Provider value={{ eventSource }}>{children}</EventSourceContext.Provider>;
  }
}

EventSourceProvider.defaultProps = {
  children: '',
  topics: [],
};

EventSourceProvider.propTypes = {
  children: PropTypes.node,
  topics: PropTypes.arrayOf(PropTypes.string),
  actions: PropTypes.shape({
    showNotification: PropTypes.func,
  }).isRequired,
};

const selectUserId = (state) => state.userManager.userId;
const selectOrganizationId = (state) => {
  const { lastUsedOrganization, currentOrganization } = state.userManager;
  return currentOrganization || (lastUsedOrganization && lastUsedOrganization.id);
};
const selectProjectId = (state) => state.projects.projectId;
const selectEnvironmentId = (state) => state.environments.environment.id;
const selectEnvironmentComponentTopics = (state) => state.environments?.environment?.componentTopics;
const selectPipeTopic = (state) => state.logs?.event?.pipeline;
const selectComponentPipeTopic = (state) => state.logs?.event?.pipeline;
const selectPipeJobTopic = (state) => state.environments.environment.pipelineCompTopic;
const selectClusterTopics = (state) => state.clusters.clusterTopics;
const selectPipeCompJobTopics = (state) => state.environments.environment.pipelineCompJobTopics;
const selectTemplateRepositoryTopic = (state) => state.templates.templateRepositoryTopics;

const topicsSelector = createSelector(
  [
    /* [!!] don't change the order; keep it as bellow */
    selectUserId,
    selectOrganizationId,
    selectProjectId,
    selectEnvironmentId,
    selectEnvironmentComponentTopics,
    selectPipeTopic,
    selectComponentPipeTopic,
    selectPipeJobTopic,
    selectClusterTopics,
    selectPipeCompJobTopics,
    selectTemplateRepositoryTopic,
  ],
  (
    /* [!!] don't change the order; keep it as above */
    userId,
    organizationId,
    projectId,
    environmentId,
    componentTopics,
    pipeTopic,
    pipeCompTopic,
    pipeJobTopic,
    clusterTopics,
    pipelineCompTopics,
    templateRepositoryTopic

    // eslint-disable-next-line max-params
  ) =>
    getTopics({
      userId,
      organizationId,
      projectId,
      environmentId,
      pipeTopic,
      pipeCompTopic,
      pipeJobTopic,
      componentTopics,
      clusterTopics,
      pipelineCompTopics,
      templateRepositoryTopic,
    })
);

const mapStateToProps = createSelector(topicsSelector, (topics) => ({ topics }));

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ showNotification }, dispatch),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), WithLogin)(EventSourceProvider);
