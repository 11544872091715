import * as R from 'ramda';
import { hideDialog, showNotification } from 'actions/global';
import { TOAST_TYPES } from 'components';
import { API, GET, POST, PUT } from 'config';
import { log, getErrorMessage } from 'utils';
import handleRequestDuplication from 'config/http/handleRequestDuplication';
import { handleGetOrganizationDetails } from 'modules/admin/actions';
import { createPrefixedAction } from '../config';
import actionTypes from './actionTypes';

/* actions */
export const connectKubernetes = createPrefixedAction(actionTypes.CONNECT_KUBERNETES);
export const detachKubernetes = createPrefixedAction(actionTypes.DETACH_KUBERNETES);
export const editCluster = createPrefixedAction(actionTypes.EDIT_CLUSTER);
export const resetClustersState = createPrefixedAction(actionTypes.RESET_STATE);
export const getCloudProviders = createPrefixedAction(actionTypes.GET_CLOUD_PROVIDERS);
export const getKubernetesCluster = createPrefixedAction(actionTypes.GET_KUBERNETES_CLUSTER);
export const resetErrorMessage = createPrefixedAction(actionTypes.RESET_ERROR_MESSAGE);
export const verifyKubernetes = createPrefixedAction(actionTypes.VERIFY_KUBERNETES);

/* methods */
export const handleVerifyKubernetes = (id) => async (dispatch) => {
  try {
    await dispatch(verifyKubernetes(PUT(API.kubernetesIntegration.verify(id)).then(({ data }) => data)));
  } catch (exception) {
    log.error('Verify Kubernetes', exception);
  }
};

export const handleGetCloudProviders = (organizationId) => async (dispatch) => {
  const queryParams = window.location?.href.split('?')[1] || '';
  let clustersURL = API.organizations.kubernetesIntegrations(organizationId);

  if (queryParams) {
    clustersURL = `${clustersURL}?${queryParams}`;
  }

  try {
    await dispatch(
      getCloudProviders(
        Promise.all([GET(API.cloudProvider.base), GET(clustersURL)]).then(
          (body) => {
            const cloudProvidersData = R.path([0, 'data'], body) || {};
            const kubernetesData = R.path([1, 'data'], body) || [];
            return {
              cloudProviders: cloudProvidersData,
              kubernetes: kubernetesData,
            };
          }
        )
      )
    );
  } catch (exception) {
    log.error('Cloud Providers', exception);
  }
};

export const handleConnectKubernetes =
  (organizationId, slug, payload, userId, history) => async (dispatch, getState) => {
    try {
      const {
        clusters: { cloudProviders },
      } = getState();
      const index = R.findIndex(R.propEq('slug', slug))(cloudProviders);
      const cloudProviderId = R.path([index, 'id'], cloudProviders);

      const promise = await dispatch(
        connectKubernetes(
          POST(API.kubernetesIntegration.base, {
            ...payload,
            cloud: `/api/cloud_providers/${cloudProviderId}`,
            organization: `/api/organizations/${organizationId}`,
          }).then(({ data }) => {
            dispatch(handleGetOrganizationDetails(organizationId, history));
            dispatch(
              showNotification(
                TOAST_TYPES.success,
                'Cluster connected successfully',
                `${data.clusterName}  has been successfully connected to your organization.`
              )
            );
            dispatch(hideDialog());
            return data;
          })
        )
      );
      dispatch(handleVerifyKubernetes(promise.payload.id));
    } catch (exception) {
      log.error('Connect Kubernetes', exception);

      if (exception.response?.status >= 500) {
        dispatch(showNotification(TOAST_TYPES.error, 'Error', getErrorMessage(exception)));
      }
    }
  };

export const handleEditCluster = (organizationId, slug, payload, clusterId) => async (dispatch, getState) => {
  try {
    const {
      clusters: { cloudProviders },
    } = getState();

    const index = R.findIndex(R.propEq('slug', slug))(cloudProviders);
    const cloudProviderId = R.path([index, 'id'], cloudProviders);

    await dispatch(
      editCluster(
        PUT(API.kubernetesIntegration.integration(clusterId), {
          ...payload,
          cloud: `/api/cloud_providers/${cloudProviderId}`,
          organization: `/api/organizations/${organizationId}`,
        }).then(({ data }) => {
          dispatch(hideDialog());
          dispatch(showNotification(TOAST_TYPES.success, 'Success', 'Cluster updated successfully.'));
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Kubernetes Edit', exception);

    if (exception.response?.status >= 500) {
      dispatch(showNotification(TOAST_TYPES.error, 'Error', 'Something went wrong. Please try again later.'));
    }  
  }
};
export const handleDetachKubernetes = (organizationId, clusterId, history) => async (dispatch) => {
  try {
    await dispatch(
      detachKubernetes(
        PUT(API.kubernetesIntegration.detach(clusterId)).then(({ data }) => {
          dispatch(handleGetOrganizationDetails(organizationId, history));
          dispatch(
            showNotification(
              TOAST_TYPES.success,
              `${data.clusterName} successfully removed`,
              `${data.clusterName}  has been successfully removed from your organization.`
            )
          );
          dispatch(hideDialog());
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Kubernetes Detach', exception);
    if (exception.response.status === 422) {
      return;
    }  
    dispatch(showNotification(TOAST_TYPES.error, 'Kubernetes Detach', getErrorMessage(exception)));
  }
};

// eslint-disable-next-line consistent-return
export const handleGetKubernetesCluster = (id) => async (dispatch) => {
  const requestURL = API.kubernetesIntegration.integration(id);

  if (handleRequestDuplication.isPending(requestURL)) {
    handleRequestDuplication.callAgainHolder[requestURL] = {
      dispatchFn: dispatch,
      callbackFn: handleGetKubernetesCluster.bind(null, id),
    };
    return 'prevent_then';
  }

  handleRequestDuplication.addToPending(requestURL);
  
  try {
    await dispatch(
      getKubernetesCluster(
        GET(requestURL).then(({ data }) => {
          handleRequestDuplication.removeFromPending(requestURL);
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Get cluster details', exception);
    handleRequestDuplication.removeFromPending(requestURL);
  }
};
