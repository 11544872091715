import * as R from 'ramda';
import { API, POST, DELETE } from 'config';

export const getTimeframesToAdd = (environmentId, addedTimeframes) => {
  const body = R.reduce(
    (acc, { timeframe, endHour, startHour }) => {
      // if(startHour === ''){
      //   acc.push({
      //     environment: `/api/environments/${environmentId}`,
      //     timeframe: `/api/timeframes/${timeframe}`,
      //     endHour,
      //   });
      // } else if( endHour === ''){
      //   acc.push({
      //     environment: `/api/environments/${environmentId}`,
      //     timeframe: `/api/timeframes/${timeframe}`,
      //     startHour,
      //   });
      // } else {
        acc.push({
          environment: `/api/environments/${environmentId}`,
          timeframe: `/api/timeframes/${timeframe}`,
          endHour,
          startHour,
        });
      // }

      return acc;
    },
    [],
    addedTimeframes
  );
  const promiseArray = body.map((item) => POST(API.environmentTimeframes.base, item));
  return promiseArray;
};

export const getTimeframesToDelete = (markedToDeleteTimeframes) => {
  const body = R.reduce(
    (acc, { id }) => {
      acc.push(id);
      return acc;
    },
    [],
    markedToDeleteTimeframes
  );
  const promiseArray = body.map((item) => DELETE(`${API.environmentTimeframes.base}/${item}`));
  return promiseArray;
};
