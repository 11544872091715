const STATUS_TYPES = {
  applied: 'applied',
  applying_failed: 'applying_failed',
  aborted: 'aborted',
  active: 'active',
  connected: 'connected',
  connection_failed: 'connection_failed',
  detached: 'detached',
  draft: 'draft',
  disconnected: 'disconnected',
  deleting: 'deleting',
  deleting_failed: 'deleting_failed',
  error: 'error',
  fail: 'fail',
  failed: 'failed',
  failing: 'failing',
  impaired: 'impaired',
  invalid: 'invalid',
  out_of_sync: 'out_of_sync',
  in_progress: 'in_progress',
  running: 'running',
  deployed: 'deployed',
  partially_running: 'partially_running',
  partially_deployed: 'partially_deployed',
  running_with_errors: 'running_with_errors',
  deployed_with_errors: 'deployed_with_errors',
  partially_running_with_errors: 'partially_running_with_errors',
  partially_deployed_with_errors: 'partially_deployed_with_errors',
  stopped: 'stopped',
  success: 'success',
  ready: 'ready',
  synced: 'synced',
  disabled: 'disabled',
};

export default STATUS_TYPES;