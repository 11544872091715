import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SVG from 'components/svg/SVG';
import Dropdown from 'theme/uielements/dropdown';
import SkeletonsProjectSelector from './SkeletonsProjectSelector';

const ProjectSelector = ({ addMenu, currentProjectName, isLoadingProjects, view }) => {
  const [skeletonsReady, setSkeletonsReady] = useState(true);

  useEffect(() => {
    if (!isLoadingProjects) {
      setSkeletonsReady(false);
    }
  }, [isLoadingProjects]);

  return skeletonsReady ? (
    <SkeletonsProjectSelector />
  ) : (
    currentProjectName && (
      <>
        <div id="project-selector" className="project-selector">
          <Dropdown
            overlay={addMenu}
            trigger={['click']}
            placement={`${view === 'MobileView' || view === 'TabView' ? 'bottomLeft' : 'bottomRight'}`}
            className="project-dropdown flex-nowrap"
            getPopupContainer={() => document.getElementById('project-selector')}
          >
            <div className="d-flex justify-content-start align-items-center">
              <div className="mr-1">
                <p className="name mb-05">{currentProjectName}</p>
                <p className="description m-0">Switch or view all</p>
              </div>
              <SVG name="bicon-chevron-up" width={20} height={20} />
            </div>
          </Dropdown>
        </div>
        <div className="navDivider" />
      </>
    )
  );
};
ProjectSelector.defaultProps = {
  currentProjectName: '',
};

ProjectSelector.propTypes = {
  view: PropTypes.string.isRequired,
  currentProjectName: PropTypes.string,
  addMenu: PropTypes.func.isRequired,
  isLoadingProjects: PropTypes.bool.isRequired,
};

export default ProjectSelector;
