import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SVG } from 'components';
import cls from 'classnames';
import StyledDialog from './Dialog.style';

const Dialog = ({
  children,
  className,
  size,
  header,
  onClose,
  onCancel,
  actions,
  isDrawer,
  open,
  closable,
  maskClosable,
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return function cleanup() {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const getCloseButton = (value) => {
    if (className === 'handleLeaveWithoutSaving' || className === 'isVolumeCapInvalid' || className === 'handleCannotDeployUrlHandle') {
      return (
        <>
          <SVG name="bicon-close" width={20} height={20} defaultColor="#808080" />
        </>
      );
    }

    if (value === 'noClose') {
      return <></>;
    }
    return (
      <>
        <SVG name="bicon-close" width={24} height={24} className="f24" />
      </>
    );
  };

  return (
    <StyledDialog
      open={open}
      className={cls(className, 'dialog-parent', size, {isDrawer})}
      title={header}
      direction="none"
      isDrawer={isDrawer}
      transitionName=""
      closable={closable}
      onClose={onClose}
      maskClosable={maskClosable}
      closeIcon={isDrawer ? getCloseButton('noClose') : getCloseButton()}
      onCancel={onCancel}
      footer={
        actions && <div className="dialog-footer">{typeof actions === 'function' ? actions(onClose) : actions}</div>
      }
    >
      <div className="dialog-body">{children}</div>
    </StyledDialog>
  );
};

Dialog.defaultProps = {
  children: '',
  className: '',
  size: 'small',
  header: '',
  onClose: null,
  closable: true,
  maskClosable: false,
  isDrawer: false,
  open: true,
  actions: '',
};

Dialog.propTypes = {
  closable: PropTypes.bool,
  isDrawer: PropTypes.bool,
  open: PropTypes.bool,
  maskClosable: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.string,
  header: PropTypes.node,
  onClose: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  actions: PropTypes.node,
};

export default Dialog;
