import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import { updateCustomRepositoriesNumber } from 'modules/templates/actions';
import {
  acceptInvitation,
  inviteUser,
  resetState,
  revokeUser,
  updateUsersList,
  registerInvited,
  getPersonalAccessToken,
  generatePersonalAccessToken,
  revokePersonalAccessToken,
  getOwner,
  resetErrors,
  defaultEcrRegistry,
  resendInvitation,
} from '../actions';
import initialState from './initialState';
import {getLastUsedOrganization} from "../../userManager/helpers";
import {updateOrganizationResource} from "../../../actions/global";

const getPersonalAccessTokenHandler = [
  getPersonalAccessToken,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoadingToken: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoadingToken: false,
      };
    }

    return {
      ...state,
      isLoadingToken: false,
      accessToken: payload?.accessToken || '',
    };
  },
];
const generatePersonalAccessTokenHandler = [
  generatePersonalAccessToken,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isGeneratingToken: true,
      };
    }

    if (error) {
      return {
        ...state,
        isGeneratingToken: false,
      };
    }

    return {
      ...state,
      isGeneratingToken: false,
      accessToken: payload?.accessToken || '',
    };
  },
];
const revokePersonalAccessTokenHandler = [
  revokePersonalAccessToken,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoadingToken: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoadingToken: false,
      };
    }

    return {
      ...state,
      isLoadingToken: false,
      accessToken: payload?.data?.accessToken || '',
    };
  },
];

const updateUsersListHandler = [
  updateUsersList,
  (state, action) => {
    const {ready, payload} = action;
    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    return {
      ...state,
      isLoading: false,
      users: R.concat(state.users, payload),
    }
  }
]
const inviteUserHandler = [
  inviteUser,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }
    const newUserList = R.insert(0,payload,state.users)
    return {
      ...state,
      isLoading: false,
      users: newUserList,
    };
  }
];
const revokeUserHandler = [
  revokeUser,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }
    const index = R.findIndex(R.propEq('id', payload))(state.users);
    return {
      ...state,
      isLoading: false,
      users: R.remove(index, 1, state.users),
    };
  }
];

const resendInvitationHandler = [
  resendInvitation,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }

    if (error) {
      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'data', 'detail'], payload),
      };
    }
    const index = R.findIndex(R.propEq('id', payload.id))(state.users);
    const update = R.map(R.ifElse(R.propEq('id', state.users[index].id), R.assoc('status', payload.status), (item) => item))
    return {
      ...state,
      isLoading: false,
      users: update(state.users),
    };
  }
];

const acceptInvitationHandler = [
  acceptInvitation,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
        violations: [],
      };
    }
    if (error) {
      return {
        ...state,
        isLoading: false,
        inviteOrg: R.path(['response', 'data', 'organizationId'], payload),
        errorCode: R.path(['response', 'status'], payload),
        errorMessage: R.path(['response', 'data', 'detail'], payload),
        violations: R.path(['response', 'data', 'violations'], payload),
      };
    }
    return {
      ...state,
      isLoading: false,
      inviteEmail: payload.email,
      inviteOrganization: payload.organization && getLastUsedOrganization(payload.organization).id,
    };
  }
];

const registerInvitedHandler = [
  registerInvited,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
      };
    }
    if (error) {

      return {
        ...state,
        isLoading: false,
        errorMessage: R.path(['response', 'detail'], payload),
      };
    }
    return {
      ...state,
      isLoading: true,
    };
  }
];

const updateCustomRepositoriesNumberHandler = [
  updateCustomRepositoriesNumber,
  (state, action) => {
    const { payload } = action;
    return {
      ...state,
      customTemplatesRepositoriesCount: payload,
    };
  }
]; 

const getOwnerHandler = [
  getOwner,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isLoading: true,
        isGettingOrganization: true,
      };
    }
    if (error) {

      return {
        ...state,
        isLoading: false,
        isGettingOrganization: false,
        errorMessage: R.path(['response', 'detail'], payload),
      };
    }
    return {
      ...state,
      ...payload,
      isLoading: false,
      isGettingOrganization: false,
      gitIntegrationsCount: payload.gitIntegrationsCount,
      kubernetesIntegrationsCount: payload.kubernetesIntegrationsCount,
      customTemplatesRepositoriesCount: payload.customTemplatesRepositoriesCount,
      registryIntegration: payload.registryIntegration,
      slackIntegrationsCount: payload.slackIntegrationsCount,
      isGated: payload.isGated,
      publicId: payload.publicId,
      accountOwner: payload.accountOwner,

      // billing info
      billingMode: payload.billingMode,
      billingPlanType: payload.billingPlanType,
      billingShowTrialBanner: payload.billingShowTrialBanner,
      billingSubscriptionEnd: payload.billingSubscriptionEnd,
    };
  }
];
const updateOrganizationResourceHandler = [
  updateOrganizationResource, (state, action) => {
    const { payload } = action;
    return {
      ...state,
      updateResourceId: payload,
    };
  }
];
const resetStateHandler = [
  resetState, () => {
  return{
    ...initialState,
  }
}];
const resetErrorsHandler = [
  resetErrors, (state) => {
    return {
      ...state,
      errorMessage: null
    }
  }
];
/** default ECR */
const defaultEcrRegistryHandler = [
  defaultEcrRegistry,
  (state, action) => {
    const { ready, error, payload } = action;

    if (!ready) {
      return {
        ...state,
        isDefaulting: true,
      };
    }

    if (error) {
      return {
        ...state,
        isDefaulting: false,
      };
    }

    return {
      ...state,
      isDefaulting: false,
      registryIntegration: R.path(['data', 'registryIntegration'], payload),
    };
  },
];

const reducer = handleActions(
  new Map([
    resetStateHandler,
    inviteUserHandler,
    revokeUserHandler,
    updateUsersListHandler,
    acceptInvitationHandler,
    registerInvitedHandler,
    getOwnerHandler,
    resetErrorsHandler,
    updateOrganizationResourceHandler,
    getPersonalAccessTokenHandler,
    generatePersonalAccessTokenHandler,
    revokePersonalAccessTokenHandler,
    defaultEcrRegistryHandler,
    resendInvitationHandler,
    updateCustomRepositoriesNumberHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
