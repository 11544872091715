import React from 'react';
import styled from 'styled-components';
import { SVG } from 'components';

const Wrapper = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

const Button = styled.div`
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
`;

export default function ItemActions({
  notification,
  markAsRead,
  remove,
}: {
  notification: {
    id: string;
    read: boolean;
  };
  markAsRead: { (id: string): void };
  remove: { (id: string): void };
}) {
  return (
    <Wrapper>
      {notification.read ? (
        <div title="Seen">
          <SVG name="bicon-check-double" className="f18"  />
        </div>
      ) : (
        <Button onClick={() => markAsRead(notification.id)} title="Mark as read">
          <SVG name="bicon-check-square" className="f18"/>
        </Button>
      )}
      <Button onClick={() => remove(notification.id)} title="Remove" className="ml-1">
        <SVG name="bicon-delete" className="f18"/>
      </Button>
    </Wrapper>
  );
}
