const DAYS_FREQUENCY_TYPES = {
  1: 'Every day',
  2: 'Weekdays',
  3: 'Weekends',
  4: 'Monday',
  5: 'Tuesday',
  6: 'Wednesday',
  7: 'Thursday',
  8: 'Friday',
  9: 'Saturday',
  10: 'Sunday',

};

export default DAYS_FREQUENCY_TYPES;
