import React, { memo } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import SelectArrow from "components/selectArrow";
import Select, { SelectOption } from 'theme/uielements/select';
import { noop } from 'utils';
import DAYS_FREQUENCY_TYPES from './constants';
import StyledGeneric from '../Generic.style';

const Option = SelectOption;

const Frequency = ({ isReadOnly, timeframe, timeframes, showSearch, onChange }) => {
  const selectedFrequencies = R.pipe(
      R.map(R.pipe(R.prop('timeframe'), Number)),
  )(timeframes);

  const getFrequencyKeys = (selected) => {
    let results = DAYS_FREQUENCY_TYPES;
    // Weekdays
    if(selected.includes(2) && selected.length > 1){
      results = R.omit([4,5,6,7,8,1], results);
    }
    // Everyday
    if(selected.includes(1) && selected.length > 1){
      results = R.omit([2,3,4,5,6,7,8,9,10], results);
    }
    // Weekends
    if(selected.includes(3) && selected.length > 1){
      results = R.omit([9, 10,1], results);
    }
    if((selected.includes(9) || selected.includes(10)) && selected.length > 1){
      results = R.omit([3, 1], results);
    }
    if(
        (selected.includes(4)
          || selected.includes(5)
          || selected.includes(6)
          || selected.includes(7)
          || selected.includes(8)
        )
      && selected.length > 1){
      results = R.omit([1, 2], results);
    }
    if(timeframes[timeframes.length - 1].timeframe === timeframe
      && timeframes[timeframes.length - 1].disableStart === true){
      const daysKeys = Object.keys(results).map(key => {
        if (Number(key) <= timeframes[timeframes.length - 2].timeframe){
          return Number(key)
        }
        return null
      });
        results = R.omit(daysKeys, results)
    }
    return results;
  }
  getFrequencyKeys(selectedFrequencies)
  return (
    <StyledGeneric className="timeframe-select-wrapper">
      <div id='frequency-container'>
        <Select
          showSearch={showSearch}
          dropdownAlign={{ offset: [0, 4] }}
          suffixIcon={<SelectArrow designType="new" />}
          className="timeframe-select"
          value={DAYS_FREQUENCY_TYPES[timeframe] || null}
          optionFilterProp="children"
          placeholder="Select day"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          disabled={isReadOnly}
          onChange={onChange}
          getPopupContainer={() => document.getElementById('frequency-container')}
        >
          {R.map(
            (key) => (
              <Option key={key} value={key}>
                {DAYS_FREQUENCY_TYPES[key]}
              </Option>
            ),
            R.keys(getFrequencyKeys(selectedFrequencies))
          )}
        </Select>
      </div>
    </StyledGeneric>
  );
};

Frequency.defaultProps = {
  isReadOnly: false,
  timeframe: null,
  timeframes: [],
  showSearch: false,
  onChange: noop,
};

Frequency.propTypes = {
  timeframes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      timeframe: PropTypes.number,
      startHour: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
      endHour: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
      disableStart: PropTypes.bool,
    })
  ),
  isReadOnly: PropTypes.bool,
  timeframe: PropTypes.number,
  showSearch: PropTypes.bool,
  onChange: PropTypes.func,
};

export default memo(Frequency);
