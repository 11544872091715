import React from 'react';
import PropTypes from 'prop-types';
import StyledLabelText from './LabelText.style';

const LabelText = ({ color, className, text }) => (
  <StyledLabelText className={className}>
    <span className={` ${color}`}>{text}</span>
  </StyledLabelText>
);

LabelText.defaultProps = {
  className: null,
  color: null
};

LabelText.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export default LabelText;

