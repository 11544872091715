import styled from 'styled-components';
import { createTheme } from '@uiw/codemirror-themes';
import { tags as t } from '@lezer/highlight';

// main editor light theme
export const lightTheme = (source) => {
  return createTheme({
    theme: 'light',
    settings: {
      background: '#f9f9f9',
      foreground: '#1f2dcf',
      caret: '#4c4c4c',
      selection: 'transparent !important',
      gutterBackground: '#f2f2f2',
      gutterForeground: '#999999',
      gutterBorder: '#ddd',
      gutterActiveForeground: '',
      lineHighlight: '#eaeaea',
      fontFamily: 'Menlo-Regular !important',
    },
    styles: [
      { tag: t.comment, color: '#787b80' },
      { tag: t.definition(t.typeName), color: '#194a7b' },
      { tag: t.typeName, color: '#194a7b' },
      { tag: t.tagName, color: '#00914d' },
      { tag: t.variableName, color: '#1f2dcf' },
      { tag: t.string, color: '#00914d' },
      { tag: t.number, color: '#1f2dcf' },
      { tag: t.atom, color: source !== 'cmError' ? '#4c4c4c' : '#1f2ecf' },
      { tag: t.meta, color: source !== 'cmError' ? '#4c4c4c' : '#1f2ecf' },
    ],
  });
};

// main editor dark theme
export const darkTheme = (source) => {
  return createTheme({
    theme: 'dark',
    settings: {
      background: '#222222',
      foreground: '#cec4f4',
      caret: '#fff',
      selection: 'transparent !important',
      gutterBackground: '#222222',
      gutterForeground: '#999999',
      gutterBorder: '#666666',
      gutterActiveForeground: '',
      lineHighlight: '#303030',
      fontFamily: 'Menlo-Regular !important',
    },
    styles: [
      { tag: t.comment, color: '#787b80' },
      { tag: t.definition(t.typeName), color: '#194a7b' },
      { tag: t.typeName, color: '#194a7b' },
      { tag: t.tagName, color: '#00914d' },
      { tag: t.variableName, color: '#cec4f4' },
      { tag: t.string, color: '#00914d' },
      { tag: t.number, color: '#cec4f4' },
      { tag: t.atom, color: source !== 'cmError' ? '#f4f4f4' : '#cec4f4' },
      { tag: t.meta, color: source !== 'cmError' ? '#f4f4f4' : '#cec4f4' },
    ],
  });
};

// [i] diff editor does not support theme creator, so we have to override the styles
// diff light theme
export const diffLight = {
  '&': {
    color: '#1f2dcf',
    backgroundColor: '#f9f9f9 !important',
  },
  '.cm-editor': {
    backgroundColor: '#222222',
  },
  '.cm-content': {
    caretColor: '#4c4c4c',
  },
  '.cm-activeLine': {
    background: '#eaeaea',
  },
  '.cm-activeLineGutter': {
    background: '#eaeaea',
  },
  '&.cm-focused .cm-cursor': {
    borderLeftColor: '#4c4c4c',
  },
  '&.cm-focused .cm-selectionBackground, ::selection': {
    backgroundColor: '#074',
  },
  '.cm-gutters': {
    backgroundColor: '#f2f2f2',
    color: '#ddd',
  },
  '.cm-gutterElement': {
    color: '#999999',
  },
  '.ͼc, .ͼ5': {
    color: '#4c4c4c',
  },
  '.ͼe': {
    color: '#00914d',
  },
};

// diff dark theme
export const diffDark = {
  '&': {
    color: '#cec4f4',
    backgroundColor: '#222222 !important',
  },
  '.cm-editor': {
    backgroundColor: '#222222',
  },
  '.cm-content': {
    caretColor: '#f4f4f4',
  },
  '.cm-activeLine': {
    background: '#303030',
  },
  '.cm-activeLineGutter': {
    background: '#303030',
  },
  '&.cm-focused .cm-cursor': {
    borderLeftColor: '#f4f4f4',
  },
  '&.cm-focused .cm-selectionBackground, ::selection': {
    backgroundColor: '#074',
  },
  '.cm-gutters': {
    backgroundColor: '#222222',
    color: '#ddd',
  },
  '.cm-gutterElement': {
    color: '#999999',
  },
  '.ͼc, .ͼ5': {
    color: '#f4f4f4',
  },
  '.ͼe': {
    color: '#00914d',
  },
};

// #Style Wrapper; other styles for all editors
export const StyledCmExtraCss = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  /** 
   * This applies to all editors 
   **/
  .cm-theme,
  .cm-merge-theme {
    width: 100%;

    .cm-merge-a .cm-changedText,
    .cm-deletedChunk .cm-deletedText {
      background: #ffc2b4;
      #dark & {
        background: #ff300078;
      }
    }

    .cm-collapsedLines {
      color: initial;
      background: #00e3ff69;
      font-family: 'Menlo-Regular';
    }

    .cm-editor .cm-focused .cm-selectionBackground,
    .cm-editor .cm-selectionLayer,
    .cm-editor .cm-selectionBackground,
    .cm-editor .cm-content ::selection {
      color: #ccc;
      color: currentColor;
      background-color: #d2e1ff !important;

      #dark & {
        background-color: #364e87 !important;
      }
    }

    .cm-editor {
      height: 100%;
      width: 100%;
      outline: none !important;

      --indent-marker-active-bg-color: #e4e4e4;

      #dark & {
        --indent-marker-bg-color: #353535;
        --indent-marker-active-bg-color: #525252;
      }

      /* minimap */
      .cm-gutters.cm-minimap-gutter {
        border-right: 0 !important;
        border-left: 1px solid #e8e8e8;
        background: #f9f9f9;
        overflow: hidden;

        @media (max-width: 420px) {
          display: none;
        }
      }

      .cm-minimap-overlay-container {
        .cm-minimap-overlay {
          background: rgb(202 202 202);
        }
      }

      #dark & {
        .cm-gutters.cm-minimap-gutter {
          border-left: 1px solid #3c3c3c;
          background: #222222;
        }

        .cm-panel.cm-panel-lint ul:focus [aria-selected],
        .cm-panel.cm-panel-lint ul [aria-selected] {
          color: #fff;
          background: #414141;
        }
      }

      .cm-searchMatch,
      .cm-searchMatch-selected {
        color: #000 !important;
        background-color: #ffff00 !important;

        span {
          color: #000 !important;
          background-color: #ffff00 !important;
        }

        &.cm-searchMatch-selected {
          color: #000 !important;
          background-color: #ff9632 !important;
          span {
            color: #000 !important;
            background-color: #ff9632 !important;
          }
        }
      }
    }

    .cm-tooltip {
      max-width: calc(100vw - 580px);
      min-width: 320px;
    }

    .cm-mergeSpacer {
      background: repeating-linear-gradient(45deg, #f9f9f9, #f9f9f9 10px, #e1e1e1 10px, #e1e1e1 11px);

      #dark & {
        background: repeating-linear-gradient(45deg, #222222, #222222 10px, #3c3c3c 10px, #3c3c3c 11px);
      }
    }

    .cm-changeGutter {
      padding: 0;
    }

    .cm-search {
      font-size: 17px;
    }

    .cm-scroller {
      #dark & {
        ::-webkit-scrollbar {
          width: 8px;
        }
        ::-webkit-scrollbar-track {
          background: #222222;
        }
        ::-webkit-scrollbar-thumb {
          background: #808080;
          border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: #8f8f8f;
        }
      }
    }

    .cm-content {
      padding-top: 24px;
      flex-shrink: initial;
      width: 100%;

      &[aria-readonly='true'] {
        padding-bottom: 24px;
      }
    }

    .cm-gutters {
      border-right: 1px solid #ddd;
      #dark & {
        border-right: 1px solid #666;
      }
    }

    .cm-gutter {
      &.cm-gutter-lint {
        position: absolute;
      }
      &.cm-foldGutter {
        #dark & {
          background: #282828;
        }

        .cm-gutterElement {
          padding-right: 5px;
        }
      }

      &.cm-lineNumbers {
        #dark & {
          background: #282828;
        }
        
        .cm-foldGutter span {
          padding: 0 1px;
          cursor: pointer;
        }
        .cm-gutterElement {
          padding-left: 24px;
          text-align: left;
        }
      }
    }

    .cm-line {
      padding-left: 24px;
      padding-right: 24px;
      &.cm-indent-markers {
        &::before {
          left: 20px;
          z-index: 1;
        }
      }
      font-size: 14px;
      font-family: Menlo-Regular;
      overflow-wrap: break-word;
      white-space: pre-wrap;
      word-break: break-all;
      line-height: 22px;

      * {
        font-size: 14px;
        font-family: Menlo-Regular;
        word-break: break-word;
      }

      .cm-foldWidgetText {
        color: #00f;
        -webkit-text-stroke-width: 0.3px;
        -webkit-text-stroke-color: #00f;
        #dark & {
          color: #cec4f4;
          -webkit-text-stroke-width: 0.3px;
          -webkit-text-stroke-color: #cec4f4;
        }
        cursor: pointer;
      }
    }
  }

  .cm-theme {
    &.readOnly {
      .cm-panels.cm-panels-bottom {
        min-height: 70px;
        right: 0;
      }
    }

    .cm-search.cm-panel label {
      position: absolute;
    }

    .cm-search.cm-panel label:nth-child(1) {
      top: 32px;
    }

    .cm-search.cm-panel label:nth-child(6) {
      top: 22px;
    }

    .cm-search.cm-panel label:nth-child(7) {
      top: 42px;
    }
  }

  /** Yaml diff only */
  .cm-merge-theme {
    .cm-mergeView {
      height: calc(100vh - 270px);
    }

    &.aclDenied {
      .cm-merge-revert { visibility: hidden; }
    }

    .cm-merge-revert {
      button {
        margin-top: 24px;
        padding-top: 0;
        padding-bottom: 0;
        border: 1px solid #dcdddd;
        border-left: 0;
        border-right: 0;
        line-height: 20px;
        background: #f9f9f9;

        #dark & {
          background: #333333;
          border: 1px solid #666;
          border-left: 0;
          border-right: 0;
        }
      }
      background: #fff;
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      #dark & {
        background: #333333;
        border-left: 1px solid #666;
        border-right: 1px solid #666;
      }
    }

    .cm-merge-a {
      // start of search
      .cm-panels.cm-panels-bottom {
        position: fixed;
        left: auto;
        bottom: initial !important;
        width: 460px;
        top: 159px;
        right: 636px;
        height: 67px;
        border: 1px solid #ccc;
        #dark & {
          right: 79px;
          border: 1px solid #666666;
        }

        @media (max-width: 900px) {
          right: calc(100vw - 820px);
          #dark & {
            right: calc(100vw - 820px);
          }
        }
      }

      .cm-search.cm-panel label {
        position: absolute;
      }

      .cm-search.cm-panel label:nth-child(1) {
        top: 32px;
      }

      .cm-search.cm-panel label:nth-child(6) {
        top: 22px;
      }

      .cm-search.cm-panel label:nth-child(7) {
        top: 42px;
      }
      // end of search

      .cm-foldGutter:not(.cm-gutter-lint):not(:nth-child(4)) {
        display: none !important;
      }
    }
    .cm-merge-b {
      .cm-changedText {
        background: #8ee0b7;
        #dark & {
          background: #426754;
        }
      }

      // start of search
      .cm-panels.cm-panels-bottom {
        z-index: 1;
        .cm-panel {
          position: fixed;
          left: auto;
          width: 460px;
          top: 159px;
          right: 79px;
          background-color: #f5f5f5;
          border: 1px solid #ccc;

          #dark & {
            right: 79px;
            background-color: #333338;
            border: 1px solid #666666;
          }
     
          &.cm-panel-lint {
            display: none !important;
          }


          @media (max-width: 900px) {
            right: calc(100vw - 820px);
            #dark & {
              right: calc(100vw - 820px);
            }
          }
        }
      }

      .cm-search.cm-panel label {
        position: absolute;
      }

      .cm-search.cm-panel label:nth-child(1) {
        top: 32px;
      }

      .cm-search.cm-panel label:nth-child(6) {
        top: 22px;
      }

      .cm-search.cm-panel label:nth-child(7) {
        top: 42px;
      }

      .cm-foldGutter:not(.cm-gutter-lint):not(:nth-child(4)) {
        display: none !important;
      }
    }

    .cm-gutters {
      height: calc(100vh - 217px) !important;
    }

    .cm-changeGutter {
      padding-left: 0;
    }

    .cm-merge-a .cm-changedLine {
      background-color: rgb(255 90 90 / 21%);
    }

    .cm-merge-b .cm-changedLine {
      background-color: rgb(0 255 119 / 16%);
    }
  }

  /* hides blinking cursor in readonly mode */
  .cm-focused [aria-readonly='true'] + .cm-cursorLayer .cm-cursor { display: none !important; }

  /** cm ERROR */
  .cm-theme.cmError {
    .cm-gutter {
      &.cm-lineNumbers { .cm-gutterElement { padding-left: 18px; }
      }
    }
  }

  /** cm JSON */
  .cm-theme.cmJson {
    .cm-gutterElement {
      padding-right: 26px;
    }
  }

  /** Yaml config only */
  .cm-theme:not(.cmError) {
    // @TODO: bug fix; start of hotfix -> hide duplicate fold gutters..
    .cm-foldGutter:nth-child(2) { display: none !important; }
  }
`;
