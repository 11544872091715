import { Button } from 'antd';
import React from 'react';
import { toast } from 'react-toastify';
import StyledNotificationBody from './components/notificationBody';

const activeToasts = [];
const AUTOCLOSE_TIMEOUT = 8000;

export const DEFAULT_PROPS = {
  // position: 'top-right',
  hideProgressBar: false,
  pauseOnHover: false,
  newestOnTop: true,
  closeButton: false,
};

export const TOAST_TYPES = {
  error: 'error',
  success: 'success',
  warning: 'warning',
  info: 'info',
};

export const TOAST_BODY = {
  Text: 'Text',
  Component: 'Component',
};

export const closeNotification = (toastId = '') => {
  if (toast.isActive(toastId)) toast.dismiss(toastId);
};

export const closeAllNotifications = toast.dismiss;

export const createNotification = (type = '', title = '', content = '', source = 'self') => {
  const now = new Date(Date.now()).getTime();
  const toastId = now.toString();
  const toastType = TOAST_BODY.Text;
  const { isWindowFocused } = window;

  const exceptions = ['maintenance notice'];
  const isException = exceptions.some((exception) => title.toLowerCase().includes(exception));
  const shouldHide = !isException && !isWindowFocused && source === 'fromMercure';

  const options = {
    toastId,
    toastType,
    onOpen: () => {
      activeToasts.push(toastId);

      if (activeToasts.length > 3) {
        closeNotification(activeToasts[0]);
        activeToasts.shift();
      }
    },
    onClose: (data) => {
      const index = activeToasts.indexOf(data.toastProps.toastId);

      if (index !== -1) {
        activeToasts.splice(index, 1);
      }
    },
    pauseOnHover: false,
    autoClose: AUTOCLOSE_TIMEOUT,
    ...(shouldHide && {style: {display: 'none'}}),
  };

  const notificationBody =
    toastType === TOAST_BODY.Component ? (
      content
    ) : (
      <StyledNotificationBody className={type}>
        <div>
          <div className="notification-title">{title}</div>
          <div className="notification-content">{content}</div>
        </div>
      </StyledNotificationBody>
    );

  if (toast.isActive(toastId)) {
    toast.update(toastId, { render: notificationBody, ...options });
  } else {
    switch (type) {
      case TOAST_TYPES.success:
        toast.success(notificationBody, {
          icon: (
            <svg className="icon f20">
              <use xlinkHref="#icon-toast-success" />
            </svg>
          ),
          data: { title, content, source },
          ...options,
        });
        break;
      case TOAST_TYPES.error:
        toast.error(notificationBody, {
          icon: (
            <svg className="icon f20">
              <use xlinkHref="#icon-toast-error" />
            </svg>
          ),
          data: { title, content, source },
          ...options,
        });
        break;
      case TOAST_TYPES.warning:
        toast.warning(notificationBody, {
          icon: (
            <svg className="icon f20">
              <use xlinkHref="#icon-toast-warning" />
            </svg>
          ),
          data: { title, content, source },
          ...options,
        });
        break;
      case TOAST_TYPES.info:
      default:
        toast.info(notificationBody, {
          icon: false,
          data: { title, content, source },
          ...options,
        });
        break;
    }

    if (shouldHide) {
      // prevent build up of notifications when tab is inactive
      toast.dismiss(toastId);
    }

    setTimeout(()=>{
      closeNotification(toastId);
    }, 8010)
  }
};

/* deploy notification */
export const createDeployNotification = (title, content, opts = {}) => {
  const { toastId = new Date(Date.now()).getTime().toString() } = opts;
  const options = {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: false,
    closeOnClick: false,
    isPersistent: true,
    toastId,
    ...opts,
  };

  const onReload = () => {
    toast.dismiss();
    window.location.reload();
  };

  const notificationBody = (
    <StyledNotificationBody className="deploy-notification">
      <div className="notification-title">{title}</div>
      <div className="notification-description">{content}</div>
      <div className="notification-action">
        <Button className="ant-btn-primary" type="button" onClick={onReload}>
          Reload now
        </Button>
      </div>
    </StyledNotificationBody>
  );

  if (toast.isActive(toastId)) {
    toast.update(toastId, { render: notificationBody, ...options, data: { title, content } });
  } else {
    toast.info(notificationBody, { ...options,  data: { title, content } });
  }
};