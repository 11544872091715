import React from 'react';
import Button from 'theme/uielements/button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const ReloadButton = ({ children, lastUsedOrganizationId }) => (
  <Button
    type="primary"
    onClick={() => {
      if (window.location.href.indexOf('error') !== -1) {
        window.location.replace(`/${lastUsedOrganizationId}/projects`);
      } else {
        window.location.reload();
      }
    }}
  >
    {children}
  </Button>
);

ReloadButton.propTypes = {
  lastUsedOrganizationId: PropTypes.number,
  children: PropTypes.node,
};

ReloadButton.defaultProps = {
  children: 'Reload now',
  lastUsedOrganizationId: null,
};

const mapStateToProps = (state) => {
  const { lastUsedOrganization } = state.userManager;
  return {
    lastUsedOrganizationId: lastUsedOrganization && lastUsedOrganization.id,
  };
};

export default connect(mapStateToProps, null)(ReloadButton);
