import ROUTE_PATHS from 'routes/routePaths';
import {getEnvVariable} from "../../utils";

// eslint-disable-next-line no-underscore-dangle
const ENV = window._env_ || process.env;

const ERROR_MESSAGES = {
  REQUEST_REJECTED: 'The resource server rejected the request.',
};

const ERROR_MAP = {
  [ERROR_MESSAGES.REQUEST_REJECTED]: `${ROUTE_PATHS.LOGIN}`,
};

const authAuthorization = {
  OAUTH2_CLIENT_ID: getEnvVariable("REACT_APP_OAUTH2_CLIENT_ID"),
  OAUTH2_CLIENT_SECRET: getEnvVariable("REACT_APP_OAUTH2_CLIENT_SECRET"),
  OAUTH2_ACCESS_TOKEN_URI: `${ENV.REACT_APP_BASE_API}/token`,
};

export { ERROR_MESSAGES, ERROR_MAP, authAuthorization };
