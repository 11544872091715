import React from 'react';
import { Col, Row, Grid } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Typography } from 'antd';
import Card from 'theme/uielements/card';
import StyledProjectListing from './ProjectListing.style';

const { Paragraph } = Typography;

const ListingSkeleton = ({ projectsNo }) => {
  return (
    <StyledProjectListing fluid>
      <Grid>
        <Row className="mb-5">
          <Col xs={12} lg={6}>
            <Skeleton height={36} width={240} containerClassName="d-flex" />
          </Col>
          <Col xs={12} lg={6} className="text-right">
            <Skeleton height={36} width={124} containerClassName="d-flex" />
          </Col>
        </Row>
        <Row>
          {[...Array(projectsNo)].map((value, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Col xs={12} lg={3} key={`${index}`}>
                <Card className="project-block">
                  <div className="mb-2 block-header">
                    <div className="block-logo">
                      <Skeleton height={34} width={34} containerClassName="d-flex" />
                    </div>
                  </div>
                  <div className="block-body">
                    <Paragraph className="block-name" strong>
                      <Skeleton height={14} width={75} containerClassName="d-flex" />
                    </Paragraph>
                  </div>
                  <div className="block-footer">
                    <div className="block-stacks">
                      <Skeleton height={14} width={30} containerClassName="d-flex" />
                    </div>
                    <div className="block-clock">
                      <Skeleton height={14} width={75} containerClassName="d-flex" />
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Grid>
    </StyledProjectListing>
  );
};

ListingSkeleton.propTypes = {
  projectsNo: PropTypes.number.isRequired,
};

export default ListingSkeleton;
