import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { defaultBaseQuery } from 'app/services/admin/api';

export const ItemTypes = [
    // neo
    'user',

    // organization & org level resources
    'organization',
    'project',
    'event',
    'kubernetes_integration',

    // environment
    'environment',
    'service_component',
    'environment_variable',

    // templates
    'templates_repository',
    'template',

    // workflows
    'workflow',

    // rbac
    'policy',
    'resource_matcher',
    'organization_member',
    'team',
    'team_member',
] as const;

export type ItemType = typeof ItemTypes[number];

export interface Item {
    type: ItemType;

    id: string;

    internalId: number;
};

export interface Encode {
    type: ItemType;

    internalId: number;
};

export interface Decode {
    type: ItemType;

    hashId: string;
};

export const TAG = 'hashids';

export const adminHashIDsApi = createApi({
    reducerPath: `api:admin:${TAG}`,

    baseQuery: defaultBaseQuery,

    tagTypes: [TAG],

    endpoints: (build) => ({
        encode: build.mutation<Item, Encode>({
            query(data) {
                return {
                    url: '/hashids/encode',
                    method: 'POST',
                    body: data,
                };
            },
        }),

        decode: build.mutation<Item, Decode>({
            query(data) {
                return {
                    url: `/hashids/decode`,
                    method: 'POST',
                    body: data,
                };
            },
        }),
    })
});
