const STATUS_TYPES = {
  pending: 'pending',
  success: 'success',
  warning: 'warning',
  aborted: 'aborted',
  error: 'error',
  detached: 'detached',
  disconnected: 'disconnected',
  locked: 'locked',
  invalid: 'invalid',
  draft: 'draft'
};

export default STATUS_TYPES;
