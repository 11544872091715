import { registryIntegrationsApi } from 'app/services/neo/registryIntegrations';
import { gitIntegrationsApi } from 'app/services/neo/gitIntegrations';
import { environGroupsApi } from 'app/services/neo/environ/groups';
import { environItemsApi } from 'app/services/neo/environ/items';


export const neoApi = {
    registryIntegrationsApi,
    gitIntegrationsApi,
    environGroupsApi,
    environItemsApi,
};

export const neoApiReducers = {
    [registryIntegrationsApi.reducerPath]: registryIntegrationsApi.reducer,
    [gitIntegrationsApi.reducerPath]: gitIntegrationsApi.reducer,
    [environGroupsApi.reducerPath]: environGroupsApi.reducer,
    [environItemsApi.reducerPath]: environItemsApi.reducer,
}

export const neoApiMiddlewares = [
    registryIntegrationsApi.middleware,
    gitIntegrationsApi.middleware,
    environGroupsApi.middleware,
    environItemsApi.middleware,
];
