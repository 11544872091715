import React from 'react';
import PropTypes from 'prop-types';
import compose from 'hocs';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { showDialog } from 'actions/global';
import AccessTokenForm from './AccessTokenForm';

const AccessTokenButton = ({ actions }) => {
  const onAccessTokenClick = () => {
    actions.showDialog({
      options: {
        size: 'small',
      },
      header: 'Personal Access Token',
      body: <AccessTokenForm actions={actions} />,
    });
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onAccessTokenClick}
      onKeyDown={() => {}}
      className="profile-dropdown-action"
      data-value="access-token-link"
    >
      Access token
    </div>
  );
};

AccessTokenButton.propTypes = {
  actions: PropTypes.shape({
    showDialog: PropTypes.func,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showDialog,
    },
    dispatch
  ),
});

export default compose(connect(null, mapDispatchToProps), withRouter)(AccessTokenButton);
