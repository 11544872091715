import { rbacRolesApi } from 'app/services/rbac/roles';

export const rbacApi = {
    rbacRolesApi,
};

export const rbacApiReducers = {
    [rbacRolesApi.reducerPath]: rbacRolesApi.reducer,
}

export const rbacApiMiddlewares = [
    rbacRolesApi.middleware,
];
