import { log } from 'utils';
import handleRequestDuplication from 'config/http/handleRequestDuplication';
import { API, GET } from 'config';
import { createPrefixedAction } from '../config';
import actionTypes from './actionTypes';

export const getEnvironmentEventDetail = createPrefixedAction(actionTypes.GET_ENVIRONMENT_EVENT_DETAIL);
export const getPipelineData = createPrefixedAction(actionTypes.GET_PIPELINE_DATA);
export const getPipelineJobData = createPrefixedAction(actionTypes.GET_PIPELINE_JOB_DATA);
export const resetLoadedState = createPrefixedAction(actionTypes.RESET_LOADED_STATE);

export const handleGetEnvironmentEventDetail =
  (organizationId, projectId, environmentId, eventId, history) => async (dispatch) => {
    try {
      await dispatch(
        getEnvironmentEventDetail(
          GET(`${API.environments.event(eventId)}`).then((response) => {
            return response.data;
          })
        )
      );
    } catch (exception) {
      history.push(`/${organizationId}/projects/${projectId}/environments/${environmentId}`);
      log.error('Error', exception);
    }
  };

// eslint-disable-next-line consistent-return
export const handleGetPipelineData = (id) => async (dispatch) => {
  const requestURL = API.environments.pipeline(id);

  if (handleRequestDuplication.isPending(requestURL)) {
    handleRequestDuplication.callAgainHolder[requestURL] = {
      dispatchFn: dispatch,
      callbackFn: handleGetPipelineData.bind(null, id),
    };
    return 'prevent_then';
  }

  handleRequestDuplication.addToPending(requestURL);

  try {
    await dispatch(
      getPipelineData(
        GET(requestURL).then(({ data }) => {
          handleRequestDuplication.removeFromPending(requestURL);
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Get pipeline data', exception);
    handleRequestDuplication.removeFromPending(requestURL);
  }
};

// eslint-disable-next-line consistent-return
export const handleGetPipelineJobData = (id) => async (dispatch) => {
  const requestURL = API.environments.pipelineJob(id);

  if (handleRequestDuplication.isPending(requestURL)) {
    handleRequestDuplication.callAgainHolder[requestURL] = {
      dispatchFn: dispatch,
      callbackFn: handleGetPipelineJobData.bind(null, id),
    };

    return 'prevent_then';
  }

  handleRequestDuplication.addToPending(requestURL);

  try {
    await dispatch(
      getPipelineJobData(
        GET(requestURL).then(({ data }) => {
          handleRequestDuplication.removeFromPending(requestURL);
          return data;
        })
      )
    );
  } catch (exception) {
    log.error('Get pipeline job data', exception);
    handleRequestDuplication.removeFromPending(requestURL);
  }
};
