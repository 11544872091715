/* eslint-disable */
import { createGlobalStyle, css } from 'styled-components';

const GlobalBorderLoaderStyles = createGlobalStyle`
  ${({ theme, mode }) => {
    const { palette } = theme;
    return css``;
  }}
`;
export default GlobalBorderLoaderStyles;
