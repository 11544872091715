import React from "react";
import { Menu } from "antd";
import { LinkItem } from "modules/sidebar/items/utils";
import { useLocation } from "react-router";

import type {
    MenuMode,
    MenuOnClick,
} from "modules/sidebar/items/menu";

export interface IProps {
    organizationId: number;
    currentProjectId: number | false;

    isAdmin: boolean;

    // should we handle all props ?
    onItemSelected: MenuOnClick;
    mode: MenuMode;
}

function getActiveKey(pathname: string, organizationId: number) {
    if (pathname === `/${organizationId}/projects`) {
        return 'projects';
    }

    if (pathname.startsWith(`/${organizationId}/templates`)) {
        return 'templates';
    }

    if (pathname.startsWith(`/${organizationId}/admin`)) {
        return 'admin'
    }

    return '';
}

export function TopMenu(props: IProps): React.ReactElement {
    const { organizationId, currentProjectId, isAdmin } = props;

    const activeKey = getActiveKey(useLocation().pathname, organizationId);

    // organization not yet loaded
    if (!organizationId) {
        // keep bottom menu at the bottom
        return <Menu
            className="isoDashboardMenu"
            theme="dark"
            mode={props.mode}
            style={{
                visibility: "hidden",
            }}
        />
    }

    return <Menu
        className="isoDashboardMenu"
        onClick={props.onItemSelected}
        theme="dark"
        mode={props.mode}
    >
        {LinkItem({
            currentKey: activeKey,
            key: 'templates',
            title: 'Templates',
            icon: 'bicon-templates',
            linkTo: {
                pathname: `/${organizationId}/templates`,
            },
        })}
        {LinkItem({
            currentKey: activeKey,
            key: 'projects',
            title: 'Projects',
            icon: 'icon-sidebar-projects',
            linkTo: {
                pathname: `/${organizationId}/projects`,
            },
        })}
        {currentProjectId && LinkItem({
            currentKey: activeKey,
            key: 'environments',
            title: 'Environments',
            icon: 'bicon-environments',
            linkTo: {
                pathname: `/${organizationId}/projects/${currentProjectId}/environments`,
            },
        })}
        {isAdmin && LinkItem({
            currentKey: activeKey,
            key: 'admin',
            title: 'Admin',
            icon: 'bicon-admin',
            linkTo: {
                pathname: `/${organizationId}/admin`
            },
        })}
    </Menu>;
}
