import * as R from 'ramda';

const getErrorCode = R.path(['response', 'data', 'code']);
const getErrorMessage = R.path(['response', 'data', 'detail']);

export const LOG_LEVELS = {
  enabled: 'enabled',
};

const getLogLevel = () => typeof window !== 'undefined' && window.logLevel;
// eslint-disable-next-line no-underscore-dangle
const ENV = (typeof window !== 'undefined' && window._env_) || process.env;

const enableLogging = R.prop('REACT_APP_ENV', ENV) === 'local' || getLogLevel() === LOG_LEVELS.enabled;

const appendDotIfNeeded = (message = '') => (message.substr(-1) === '.' ? message : `${message}.`);

const format = (message, error) => {
  const errorCode = getErrorCode(error);
  const errorMessage = getErrorMessage(error);
  let formattedMessage = `${appendDotIfNeeded(message)}\n ${error}.`;

  if (errorCode) formattedMessage += `\n Error code: ${errorCode}.`;
  if (errorMessage) formattedMessage += `\n Error message: ${errorMessage}.`;

  return formattedMessage;
};

const log = (...args) => enableLogging && typeof window !== 'undefined' && window.console.info(...args);

log.info = log;

log.error = (message, error) => {
  const formattedMessage = format(message, error);
  if (enableLogging) return typeof window !== 'undefined' && window.console.error(formattedMessage);

  return error;
};

export default log;
export { getErrorCode, getErrorMessage };
