/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import styled from 'styled-components';
import { SVG } from 'components';
import type { InputRef } from 'antd';
import Button from 'theme/uielements/button';
import Tooltip from 'theme/uielements/tooltip';
import { useEnvironmentACL } from 'rbac/hooks/useEnvironmentACL';
import { useProjectACL } from 'rbac/hooks/useProjectACL';
import { Input, Space, Tag } from 'antd';
import { KeyString } from 'utils/globalInterfaces';
import { objectsHaveIdenticalContent } from 'utils';
import { showNotification } from 'actions/global';
import { handleUpdateProjectLabels } from 'modules/projects/actions';
import { handleGetEnvironments, manuallyUpdateEnvironment } from 'modules/environments/actions';
import { AxiosResponse, isAxiosError } from 'axios';
import { API, PUT } from 'config';
import { buttonLoaderWhite, pencil, pencilWhite } from 'assets/images';
import { capitalize } from 'utils/string';

type RootState = {
  projects: {
    project: {
      labels: KeyString;
    };
  };
};

interface Actions {
  manuallyUpdateEnvironment: (payload: AxiosResponse) => void;
  handleUpdateProjectLabels: (params: { projectId: number; payload: unknown }) => void;
  showNotification: (type: string, title: string, message: string) => void;
  handleGetEnvironments: (params: {
    organizationId: string;
    projectId: string;
    history: unknown;
    extraDataObject: unknown;
  }) => void;
}

type Props = {
  source: 'fromEnvDetails' | 'fromEnvListing' | 'project';
  actions: Actions;
  labels: KeyString;
  resourceId: number;
  mode: string;
  className?: string;
  project: {
    labels: KeyString;
  };
};

const makeObjectFromLabel = (inputValue: string) => {
  if (inputValue.includes('=')) {
    const splitArray = inputValue.split('=');
    const key = splitArray.shift() || '';
    const value = splitArray.join('');

    return { key: key.replace(/ /g, ''), value: value.trim() };
  }

  return { key: inputValue, value: '' };
};

const TagsWrap = styled.div`
  padding: 0 0 24px 0;

  &.fromEnvListing {
    padding: 0 0 16px 12px;
  }

  .ant-tag {
    padding: 0;
    font-size: 12px;
    background: transparent;
    border: 0;

    // add label
    &.site-tag-plus {
      background: #f2f2f2;
      padding: 0 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      cursor: pointer;

      #dark & {
        color: #ccc;
        background: #333;
        border-color: #666;
      }
    }

    .key,
    .value {
      color: #333;
      border: 1px solid #ccc;

      #dark & {
        color: #ccc;
        border-color: #666;
        cursor: url(${pencilWhite}) 0 21, auto;
      }

      cursor: url(${pencil}) 0 21, auto;
    }

    .key {
      background: #f2f2f2;
      padding: 0 6px 0 8px;
      border-radius: 4px 0 0 4px;

      &.selfClosing {
        border-radius: 4px;
        border-right: 1px solid #ccc;
      }

      #dark & {
        background: #333;
        border-color: #666;
      }
    }

    .value {
      padding: 0px 5px 0px 8px;
      border-left: 0;
      border-radius: 0 4px 4px 0;
    }

    &.denied {
      opacity: 0.5;
      cursor: not-allowed;

      .key,
      .value {
        cursor: not-allowed;
        #dark & {
          cursor: not-allowed;
        }
      }
    }
  }

  .tag-input {
    color: #333;
    width: 120px;
    font-size: 12px;
    margin-right: 8px;
    vertical-align: top;
    line-height: 16px;
    padding: 2px 8px;
    border-radius: 4px;
    border-color: #ccc;

    #dark & {
      color: #fff;
      background: transparent;
      border-color: #666;
    }
  }

  .addInput {
    .ant-input {
      color: #333;
      font-size: 12px;
      margin-right: 8px;
      vertical-align: top;
      line-height: 16px;
      padding: 2px 8px;
      border-color: #ccc;
      border-left: 0;
      background: transparent;
      border-radius: 0 4px 4px 0;

      #dark & {
        color: #fff;
        background: transparent;
        border-color: #666;
      }
    }

    .ant-input-group-addon {
      padding: 0 5px 0 5px;
      background: transparent;
      border-radius: 4px 0 0 4px;
      border-right: 1px solid;
      border-color: #ccc;

      #dark & {
        border-right: 1px solid;
        border-color: #666;
      }
    }
  }

  .actions {
    .separator {
      color: #ccc;

      #dark & {
        color: #666;
      }
    }

    button {
      font-size: 12px;
      padding: 0 8px !important;
      line-height: 1;
      border-radius: 4px;
      height: 22px !important;

      span {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
`;

const ResourceLabels = ({ source, actions, resourceId, labels, mode, className, project }: Props) => {
  // [i] resourceId is environmentId or projectId

  const { organizationId, projectId } = useParams<{
    organizationId: string;
    projectId: string;
  }>();

  // RBAC
  const fetchingOption = { allowFetching: true };
  const projectContext = {
    organization: { id: parseFloat(organizationId) },
    project: { id: parseFloat(projectId), labels: project?.labels },
  };
  const aclProjectData = useProjectACL('project:manage', projectContext, fetchingOption);
  const aclEnvironmentData = useEnvironmentACL(
    'environment:manage',
    { ...projectContext, environment: { id: resourceId, labels } },
    fetchingOption
  );
  const aclManageData = source === 'project' ? aclProjectData : aclEnvironmentData;
  const aclManageDenied = aclManageData.type === 'denied';
  // End of RBAC

  const history = useHistory();
  const tagRef = useRef<HTMLSpanElement>(null);

  const [labelWidth, setLabelWidth] = useState(100);
  const [localLabels, setLocalLabels] = useState<KeyString>(labels);

  const hasChanges = useMemo(() => {
    return !objectsHaveIdenticalContent(labels, localLabels);
  }, [labels, localLabels]);

  const arrOfKeyValue = Object.keys(localLabels).map(
    (key) => `${key} ${localLabels[key] ? `=${localLabels[key]}` : ''}`
  );

  const [inputVisible, setInputVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [editKey, setEditInputIndex] = useState('');
  const [editInputValue, setEditInputValue] = useState('');

  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);

  const updateLabels = (payload: { labels: KeyString }): Promise<AxiosResponse<unknown>> => {
    return PUT(`${API.environments.base}/${resourceId}`, payload);
  };

  const handleClose = (key: string) => {
    if (aclManageDenied) {
      return;
    }

    setLocalLabels((prev: KeyString) => {
      const prevLabels = { ...prev };

      delete prevLabels[key];

      return prevLabels;
    });
  };

  const showInput = () => {
    if (aclManageDenied) {
      return;
    }
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && arrOfKeyValue.indexOf(inputValue) === -1) {
      setLocalLabels((prev: KeyString) => {
        const { key, value } = makeObjectFromLabel(inputValue);

        return {
          ...prev,
          [key]: value,
        };
      });
    }
    setInputVisible(false);
    setInputValue('');
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = (originalKey: string) => {
    setLocalLabels((prev: KeyString) => {
      const prevLabels = { ...prev };
      const { key, value } = makeObjectFromLabel(editInputValue);

      // Create a new key-value pair
      prevLabels[key] = value;

      if (key !== originalKey) {
        // Delete the old key
        delete prevLabels[originalKey];
      }

      return prevLabels;
    });
    setEditInputIndex('');
    setInputValue('');
  };

  const saveChanges = async () => {
    setIsSaving(true);
    try {
      const data = await updateLabels({ labels: localLabels });
      const { data: responseData }: AxiosResponse = data;

      actions.showNotification('success', 'Success', 'Labels updated successfully.');
      actions.manuallyUpdateEnvironment(responseData);

      if (source === 'fromEnvListing') {
        // Update the environments list
        actions.handleGetEnvironments({ organizationId, projectId, history, extraDataObject: {} });
      }
    } catch (e) {
      const data = isAxiosError(e) ? e.response?.data : null;

      actions.showNotification('error', 'Error', data?.detail ?? 'Oops! Something went wrong.');
    }
    setIsSaving(false);
  };

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  useEffect(() => {
    setLocalLabels(labels);
  }, [labels]);

  return (
    <TagsWrap className={`${source} ${className}`}>
      <Space size={[0, 8]} wrap className="">
        {Object.keys(localLabels)
          .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
          .map((key: string) => {
            const valueOfProperty = localLabels[key] || '';
            const labelFullText = `${key} ${valueOfProperty.length > 0 ? '=' : ''} ${valueOfProperty}`;
            const maxTextLength = 20;

            if (editKey === key) {
              return (
                <Input
                  ref={editInputRef}
                  style={{ width: labelWidth }}
                  key={key}
                  size="small"
                  placeholder="key = value"
                  className="tag-input"
                  autoFocus
                  value={editInputValue}
                  onChange={handleEditInputChange}
                  onBlur={() => {
                    handleEditInputConfirm(key);
                  }}
                  onPressEnter={() => {
                    handleEditInputConfirm(key);
                  }}
                />
              );
            }

            const tagElem = (
              <Tooltip
                key={key}
                className="d-flex"
                placement="top"
                title={aclManageDenied && capitalize(aclManageData.reason || '')}
                mouseEnterDelay={0.4}
                mouseLeaveDelay={0.4}
              >
                <Tag className={`edit-tag ${aclManageDenied ? 'denied' : ''}`} key={key} ref={tagRef}>
                  <span
                    className="d-flex align-items-center flex-nowrap"
                    role="button"
                    tabIndex={0}
                    onClick={(e) => {
                      e.preventDefault();

                      if (aclManageDenied) {
                        return;
                      }
                      setEditInputIndex(key);
                      setEditInputValue(valueOfProperty ? `${key}=${valueOfProperty}` : key);
                      setLabelWidth(e.currentTarget?.offsetWidth);
                    }}
                  >
                    <span className={`key d-flex align-items-center ${!valueOfProperty ? 'selfClosing' : ''}`}>
                      {key.length > maxTextLength ? `${key.slice(0, maxTextLength)}...` : key}
                      {!valueOfProperty && !aclManageDenied && (
                        <span
                          role="button"
                          tabIndex={0}
                          className="ml-1 link"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleClose(key);
                          }}
                        >
                          <SVG
                            name="bicon-close-thick"
                            width={13}
                            height={13}
                            defaultColor={mode === 'light' ? '#333' : '#000'}
                          />
                        </span>
                      )}
                    </span>
                    {!!valueOfProperty && (
                      <span className="value d-flex align-items-center flex-nowrap">
                        {valueOfProperty.length > maxTextLength
                          ? `${valueOfProperty.slice(0, maxTextLength)}...`
                          : valueOfProperty}
                        {!aclManageDenied && (
                          <span
                            role="button"
                            tabIndex={0}
                            className="ml-1 link"
                            onClick={(e) => {
                              handleClose(key);
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <SVG
                              name="bicon-close-thick"
                              width={13}
                              height={13}
                              defaultColor={mode === 'light' ? '#333' : '#000'}
                            />
                          </span>
                        )}
                      </span>
                    )}
                  </span>
                </Tag>
              </Tooltip>
            );

            return key.length > maxTextLength || valueOfProperty.length > maxTextLength ? (
              <Tooltip title={labelFullText} key={labelFullText}>
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            );
          })}

        {Object.keys(localLabels).length === 0 && <p className="mb-0 mr-2 gray d-flex">No labels</p>}

        {inputVisible && (
          <Tooltip
            className="d-flex"
            placement="top"
            title={aclManageDenied && capitalize(aclManageData.reason || '')}
            mouseEnterDelay={0.4}
            mouseLeaveDelay={0.4}
          >
            <span>
              <Input
                ref={inputRef}
                disabled={aclManageDenied}
                style={{ width: 120 }}
                type="text"
                size="small"
                className="addInput"
                addonBefore={<SVG className="f12" name="bicon-tag" noHover defaultColor="#ccc" />}
                placeholder="key = value"
                autoFocus
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
              />
            </span>
          </Tooltip>
        )}

        <div className="d-flex">
          {!inputVisible && (
            <Tooltip
              className="d-flex"
              placement="top"
              title={aclManageDenied && capitalize(aclManageData.reason || '')}
              mouseEnterDelay={0.4}
              mouseLeaveDelay={0.4}
            >
              <Tag className={`site-tag-plus ${aclManageDenied ? 'denied' : ''}`} onClick={showInput}>
                + Add Label
              </Tag>
            </Tooltip>
          )}

          {hasChanges && (
            <div className="d-flex actions">
              <div className="mr-1 separator">|</div>
              <Tooltip
                className="d-flex"
                placement="top"
                title={aclManageDenied && capitalize(aclManageData.reason || '')}
                mouseEnterDelay={0.4}
                mouseLeaveDelay={0.4}
              >
                <span>
                  <Button
                    className={`mr-1${isSaving ? ' loading-anim' : ''}`}
                    disabled={isSaving || aclManageDenied}
                    type="primary"
                    size="small"
                    onClick={() => {
                      if (source === 'project') {
                        actions.handleUpdateProjectLabels({ projectId: resourceId, payload: { labels: localLabels } });
                        return;
                      }
                      saveChanges();
                    }}
                  >
                    {isSaving && (
                      <object className="button-loader" type="image/svg+xml" data={buttonLoaderWhite}>
                        svg-animation
                      </object>
                    )}
                    <span>Save Changes</span>
                  </Button>
                </span>
              </Tooltip>
              <Button
                size="small"
                type="info"
                onClick={() => {
                  setLocalLabels(labels);
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
      </Space>
    </TagsWrap>
  );
};

const mapStateToProps = ({ projects }: RootState) => {
  const { project } = projects;

  return {
    project,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      showNotification,
      manuallyUpdateEnvironment,
      handleUpdateProjectLabels,
      handleGetEnvironments,
    },
    dispatch
  ),
});

ResourceLabels.defaultProps = {
  className: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourceLabels);
