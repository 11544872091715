import React, { memo } from 'react';
import { ErrorPage } from 'components';
import { notFoundError } from 'modules/error/components/helpers';

const NotFoundPage = () => {
  const { title, description, children } = notFoundError;

  return (
    <ErrorPage title={title} description={description}>
      {children}
    </ErrorPage>
  );
};

export default memo(NotFoundPage);
