import React from 'react';
import PropTypes from 'prop-types';
import Button from 'theme/uielements/button';
import { SVG } from 'components'
import CreateOrganizationForm from './CreateOrganizationForm';
import StyledCreateOrganizationButton from './CreateOrganizationButton.style';

const CreateOrganizationButton = ({ actions, changeOrganization, isLinkType, closeMenu }) => {
  const handleOnOrganizationCreate = () => {
    closeMenu();

    actions.showDialog({
      options: {
        size: 'small',
      },
      header: 'Create Organization',
      body: <CreateOrganizationForm actions={actions} changeOrganization={changeOrganization} />,
    });
  };

  const regularButton = (
    <Button
      type="primary"
      className="ml-1"
      onClick={handleOnOrganizationCreate}
      data-value="create-new-organization-link"
    >
      Create organization
    </Button>
  );

  const textButton = (
    <StyledCreateOrganizationButton
      onClick={handleOnOrganizationCreate}
      onKeyDown={() => {}}
      role="button"
      tabIndex={0}
      data-value="create-new-organization-link"
    >
      <SVG name="bicon-plus" noHover defaultColor="#1F2ECF" className="mr-1" /> Create organization
    </StyledCreateOrganizationButton>
  );

  return isLinkType ? textButton : regularButton;
};

CreateOrganizationButton.defaultProps = {
  isLinkType: false,
  closeMenu: () => {},
  changeOrganization: () => {},
};

CreateOrganizationButton.propTypes = {
  changeOrganization: PropTypes.func,
  isLinkType: PropTypes.bool,
  closeMenu: PropTypes.func,
  actions: PropTypes.shape({
    showDialog: PropTypes.func,
    resetPrjErrors: PropTypes.func,
  }).isRequired,
};

export default CreateOrganizationButton;
