import styled, { css } from 'styled-components';

const StyledProjectsEmptyState = styled.div`
  ${({ theme }) => {
    const { palette } = theme;

    return css`
      &&& {
        .chat-button {
          color: ${palette.text[0]};
          border-color: ${palette.color[6]};
          background-color: ${palette.color[6]};
        }
      }

      .title {
        color: ${palette.color[1]};
      }
      .description {
        max-width: 500px;
        margin-bottom: 24px;
        color: ${palette.text[2]};
        font-size: ${palette.fontSize[1]};
        line-height: 18px;
      }
      .links-container {
        margin-bottom: 30px;
        .link {
          line-height: 16px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .support-section {
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid ${palette.border[0]};
        #dark & {
          border-top: 1px solid ${palette.border[4]};
        }
      }
      .ant-divider {
        width: auto;
        max-width: 952px;
        min-width: auto;
      }
    `;
  }}
`;

StyledProjectsEmptyState.displayName = 'StyledProjectsEmptyState';
export default StyledProjectsEmptyState;
