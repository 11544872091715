import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorPage from 'components/errorPage';
import ChunkError from './modules/chunkError/ChunkError';
import PermissionDeniedPage from './modules/permissionDenied/PermissionDeniedPage';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, isChunkLoadError: false, isPermissionDeniedError: false };
    this.prevUrl = window.location.href;
  }

  componentDidCatch(error) {
    const self = this;
    const isChunkLoadError = error?.name === 'ChunkLoadError';
    const isPermissionDeniedError = error?.name === 'PermissionDeniedError';
    const isReferenceError = error?.name === 'ReferenceError';
    const isTypeError = error?.name === 'TypeError';
    const isCodeMirrorError = error?.message ? error.message.indexOf('EditorView.update') !== -1 : false;

    this.setState({
      hasError: true,
      isChunkLoadError,
      isPermissionDeniedError,
      isCodeMirrorError,
    });

    /**
     * start of "hotfix" for ErrorBoundary state reset on route change
     */
    // @TODO: we should use route change event to reset state
    if (isChunkLoadError || isPermissionDeniedError || isReferenceError || isTypeError ) {
      document.querySelectorAll('a, button, [role="button"]').forEach((selector) => {
        selector.addEventListener('click', function handleLocationCheck() {

          setTimeout(() => {
            if (window.location.href !== self.prevUrl) {
              //self.prevUrl = window.location.href;
              /** clear listener */
              document.removeEventListener('click', handleLocationCheck);

              self.setState({
                hasError: false,
                isChunkLoadError: false,
                isPermissionDeniedError: false,
                isCodeMirrorError: false,
              });
            }
          }, 100);
        });
      })
    }
    /**
     * end of hotfix
     */
  }

  render() {
    const { children } = this.props;

    if (this.state.isCodeMirrorError) {
      return children;
    }

    /* show chunk error */
    if (this.state.isChunkLoadError) {
      return <ChunkError />;
    }

    /* show generic error */
    if (this.state.isPermissionDeniedError) {
      return <PermissionDeniedPage />;
    }

    /* show generic error */
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return children;
  }
}

ErrorBoundary.defaultProps = {
  children: '',
};

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
