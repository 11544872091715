const initialState = {
  buttonShouldLoad: false,
  isLoading: false,
  subscription: null,
  billingData: null,
  invoices: null,
  isInvoicePaying: false,
};

export default initialState;
