import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { Button, Pagination } from 'antd';
import navBlackArrow from 'assets/images/nav-arrow-black.svg';
import navWhiteArrow from 'assets/images/nav-arrow-white.svg';
import StyledApiPagination from './ApiPagination.style';

const ApiPagination = ({ total = 1, maxItemsPerPage = 30, callback, mode, pageKey = '' }) => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(new URLSearchParams(history.location.search).get(pageKey || 'page') || 1);

  const renderNextOrPrev = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <Button>
          <img src={mode === 'dark' ? navWhiteArrow : navBlackArrow} alt="prev-arrow" />
          Previous
        </Button>
      );
    }

    if (type === 'next') {
      return (
        <Button>
          Next
          <img src={mode === 'dark' ? navWhiteArrow : navBlackArrow} alt="next-arrow" />
        </Button>
      );
    }

    return originalElement;
  };

  const handlePageChange = (number) => {
    if (callback) {
      callback({ page: number });
    }
    setCurrentPage(parseFloat(number));
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);

    /** handle page number greater than available number */
    const maxPages = Math.ceil(total / maxItemsPerPage);

    if (parseFloat(currentPage) > maxPages) {
      const { pathname } = history.location;

      setCurrentPage(maxPages);
      queryParams.set(pageKey || 'page', maxPages);

      history.replace({ pathname, search: queryParams.toString() });
    } else {
      setCurrentPage(parseFloat(queryParams.get(pageKey || 'page') || 1));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, total, maxItemsPerPage, new URLSearchParams(history.location.search)]);

  return (
    <StyledApiPagination id="pagination">
      <div className="total-items">
        <span>{total} results</span>
      </div>
      <Pagination
        current={currentPage}
        total={total}
        defaultPageSize={maxItemsPerPage}
        itemRender={renderNextOrPrev}
        onChange={handlePageChange}
        showSizeChanger={false}
        showLessItems
        responsive
      />
    </StyledApiPagination>
  );
};

ApiPagination.defaultProps = {
  maxItemsPerPage: 30,
  pageKey: '',
  mode: 'light',
  callback: null,
};

ApiPagination.propTypes = {
  pageKey: PropTypes.string,
  mode: PropTypes.string,
  maxItemsPerPage: PropTypes.number,
  total: PropTypes.number.isRequired,
  callback: PropTypes.func,
};

const mapStateToProps = ({ global }) => {
  const { mode } = global;

  return { mode };
};

export default connect(mapStateToProps, null)(ApiPagination);
