import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Col } from 'react-flexbox-grid';
import { Typography } from 'antd';
import Tooltip from 'theme/uielements/tooltip';
import Card from 'theme/uielements/card';
import { useProjectACL } from 'rbac/hooks/useProjectACL';
import { setProjectName } from 'modules/projects/actions';
import { showDialog, hideDialog } from 'actions/global';
import { setCurrentProject } from 'modules/userManager/actions';
import { setCurrentEnvironmentsNo } from 'modules/environments/actions';
import { formatTimeDistance } from 'utils';
import { SVG } from '../../../../components';

const { Paragraph } = Typography;

const ProjectCard = ({ actions, project }) => {
  const { organizationId } = useParams();
  const { id, name, createdAt, accessibleEnvironmentsCount, labels } = project;

  // RBAC
  const fetchingOption = { allowFetching: true };
  const aclContext = {
    organization: { id: parseFloat(organizationId) },
    project: { id: project.id, labels },
  };
  const aclProjectSecurityData = useProjectACL('project:security', aclContext, fetchingOption);
  const aclProjectSecurityDisabled = aclProjectSecurityData.type === 'denied';
  // End of RBAC

  const history = useHistory();

  return (
    <Col key={id} xs={12} lg={3}>
      <Card
        className="project-block"
        hoverable
        onClick={() => {
          actions.setCurrentEnvironmentsNo(accessibleEnvironmentsCount);
          actions.setCurrentProject(id);
          history.push(`/${organizationId}/projects/${id}/environments`, { envCount: accessibleEnvironmentsCount });
        }}
      >
        <div className="mb-2 block-header">
          <div className="block-logo">
            <svg className="icon default">
              <use xlinkHref="#icon-project" />
            </svg>
            <svg className="icon active">
              <use xlinkHref="#icon-project-active" />
            </svg>
            <svg className="icon dark">
              <use xlinkHref="#icon-project-dark" />
            </svg>
          </div>
          <div
            className="block-settings"
            role="button"
            tabIndex={0}
            onClick={(ev) => {
              ev.stopPropagation();

              actions.setProjectName(name);
              history.push(`/${organizationId}/projects/${id}/settings/general`);
            }}
          >
            <div className="block-settings-img">
              <SVG name="bicon-settings" width={20} height={20} defaultColor="#333" />
            </div>
          </div>
        </div>
        <div className="block-body">
          <div className="d-flex justify-content-between">
            <Paragraph className="block-name ellipsis" title={name} strong>
              {name}
            </Paragraph>
            {!aclProjectSecurityDisabled && (
              <Link
                className="securityLink"
                to={`/${organizationId}/projects/${id}/security`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Tooltip placement="top" title="Security">
                  <SVG name="bicon-security" width={20} height={20} defaultColor="#333" />
                </Tooltip>
              </Link>
            )}
          </div>
        </div>
        <div className="block-footer">
          <div className="block-stacks">
            <svg className="icon mr-1">
              <use xlinkHref="#icon-environment-gray" />
            </svg>
            {accessibleEnvironmentsCount}
          </div>
          <div className="block-clock">
            <SVG name="bicon-clock" noHover className="mr-1" />
            {formatTimeDistance(createdAt)}
          </div>
        </div>
      </Card>
    </Col>
  );
};

ProjectCard.propTypes = {
  project: PropTypes.shape({
    labels: PropTypes.shape({}),
    id: PropTypes.string,
    name: PropTypes.string,
    createdAt: PropTypes.string,
    accessibleEnvironmentsCount: PropTypes.number,
  }).isRequired,
  actions: PropTypes.shape({
    showDialog: PropTypes.func,
    hideDialog: PropTypes.func,
    setCurrentEnvironmentsNo: PropTypes.func,
    setCurrentProject: PropTypes.func,
    setProjectName: PropTypes.func,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showDialog,
      hideDialog,
      setCurrentEnvironmentsNo,
      setCurrentProject,
      setProjectName,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(ProjectCard);
