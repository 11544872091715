import { cleanString } from 'utils';

export const getOrganizationPayload = ({ name, timezone }, isGated, billingMode) => ({
  name: cleanString(name),
  isGated,
  timezone,
  billingMode,
});

export const getUserPayload = ({ firstName, lastName, email, password , registrationStep}) => ({
  firstName: cleanString(firstName),
  lastName: cleanString(lastName),
  email: cleanString(email),
  password: cleanString(password),
  registrationStep: cleanString(registrationStep),
});
