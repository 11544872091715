const actionTypes = {
  CREATE_ACCESS_TOKEN: 'CREATE_ACCESS_TOKEN',
  RENEW_ACCESS_TOKEN: 'RENEW_ACCESS_TOKEN',
  GET_ACCOUNT_DETAILS: 'GET_ACCOUNT_DETAILS',
  GET_USER_PREFERENCES: 'GET_USER_PREFERENCES',
  GET_CURRENT_ORGANIZATION: 'GET_CURRENT_ORGANIZATION',
  SET_CURRENT_PROJECT: 'SET_CURRENT_PROJECT',
  UPDATE_USER_PREFERENCES: 'UPDATE_USER_PREFERENCES',
  GET_ACCOUNT_OWNER_ORGANIZATIONS: 'GET_ACCOUNT_OWNER_ORGANIZATIONS',
  CREATE_ORGANIZATION: 'CREATE_ORGANIZATION',
  SET_USER_ORGANIZATIONS: 'SET_USER_ORGANIZATIONS',
  ADD_PROJECT_TO_LIST: 'ADD_PROJECT_TO_LIST',
  DELETE_PROJECT_FROM_LIST: 'DELETE_PROJECT_FROM_LIST',
  UPDATE_PROJECT_FROM_LIST: 'UPDATE_PROJECT_FROM_LIST',
  GET_BILLING_PLANS: 'GET_BILLING_PLANS',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  CREATE_BILLING_PROFILE: 'CREATE_BILLING_PROFILE',
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  RESEND_CONFIRM_EMAIL: 'RESEND_CONFIRM_EMAIL',
  GET_UNREGISTER_USER: 'GET_UNREGISTER_USER',
  PROFILE_CREATE: 'PROFILE_CREATE',
  SETUP_INTENT: 'SETUP_INTENT',
  REGISTER_INVITE: 'REGISTER_INVITE',
  LOG_OUT: 'LOG_OUT',
  RESET_STATE: 'RESET_STATE',
  RECOVER_PASSWORD: 'RECOVER_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
  UPDATE_USER_ORGANIZATIONS: 'UPDATE_USER_ORGANIZATION',
  SET_IS_LOGGING: 'SET_IS_LOGGING',
  GIT_SIGN_ON: 'GIT_SIGN_ON',
  GIT_SIGN_ON_RESPONSE: 'GIT_SIGN_ON_RESPONSE',
  SET_INVALID_PASSWORD_ERROR: 'SET_INVALID_PASSWORD_ERROR',
  SET_LOGIN_ERROR_MESSAGE: 'SET_LOGIN_ERROR_MESSAGE',
  SET_REGISTER_ERROR_MESSAGE: 'SET_REGISTER_ERROR_MESSAGE',
};

export default actionTypes;
