import React from 'react';
import { connect } from 'react-redux';
import ResourceLabels from 'modules/environments/components/details/components/ResourceLabels';
import { KeyString } from 'utils/globalInterfaces';
import { Helmet } from 'react-helmet';
import StyledGeneralSettings from 'modules/projects/components/settings/settings/GeneralSettings.style';

type RootState = {
  global: {
    mode: string;
  };
  projects: {
    project: {
      id: number;
    };
    labels: KeyString;
  };
};

const LabelsPage = ({ projectId, labels, mode }: { projectId: number; labels: KeyString; mode: string }) => {
  return (
    <StyledGeneralSettings>
      <Helmet>
        <title>Project Labels</title>
        <meta name="description" content="Project Labels" />
      </Helmet>

      <div className="card-header">
        <h4 className="mb-0">Project Labels</h4>
      </div>
      <ResourceLabels resourceId={projectId} labels={labels} source="project" mode={mode} />
    </StyledGeneralSettings>
  );
};

const mapStateToProps = ({ projects, global }: RootState) => {
  const { mode } = global;
  const { labels, project } = projects;

  return {
    mode,
    labels,
    projectId: project.id,
  };
};

export default connect(mapStateToProps, null)(LabelsPage);
