import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Typography } from 'antd';
import { noop } from 'utils';
import HourPicker from './hourPicker';
import StyledHourRange from './HourRange.style';
import { HOURS, generateHours } from './hourPicker/helpers';

const { Text } = Typography;

const HourRange = (
  {
    isReadOnly,
    startHour,
    endHour,
    disableStart,
    showSearch,
    onChange,
    timeframes,
    timeframe
  }) => {
  const [from, setFrom] = useState(startHour);
  const [to, setTo] = useState(endHour);

  
  useEffect(() => {
    setFrom(startHour);
    setTo(endHour)
  }, [timeframe, endHour, startHour])
  
  const handleFromChange = (value) => {
    setFrom(value);
    setTo(null);
    onChange({
      startHour: value,
      endHour: to,
    });
  };

  const handleToChange = (value) => {
    setTo(value);
    onChange({
      startHour: from,
      endHour: value,
    });
  };

  const filteredTimeframes = R.filter(R.propEq('timeframe', timeframe))(timeframes)
  let availableHours = generateHours();
  if(filteredTimeframes.length > 1){
    for(let i = 0; i<(HOURS.length); i+=1)
    {
      for(let j = 0; j < (filteredTimeframes.length-1); j+=1){
        if( i <= HOURS.indexOf(filteredTimeframes[j].endHour)
          || i <= HOURS.indexOf(filteredTimeframes[j].startHour)
        ){
          if(availableHours.indexOf(HOURS[i]) > -1)
            availableHours.splice(availableHours.indexOf(HOURS[i]), 1);
        }
      }
    }
  } else {
    availableHours = HOURS;
  }
  return (
    <StyledHourRange>
      <HourPicker
        isReadOnly={isReadOnly}
        value={from}
        availableHours={availableHours}
        disableStart={disableStart}
        placeholder="Select start hour"
        showSearch={showSearch}
        dayNumber={timeframe}
        timeframes={timeframes}
        onChange={handleFromChange}
      />
      <Text className="range-label">to</Text>
      <HourPicker
        isReadOnly={isReadOnly}
        value={to}
        startAt={from}
        availableHours={availableHours}
        dayNumber={timeframe}
        placeholder="Select stop hour"
        showSearch={showSearch}
        onChange={handleToChange}
      />
    </StyledHourRange>
  );
};

HourRange.defaultProps = {
  isReadOnly: false,
  startHour: null,
  endHour: null,
  showSearch: false,
  //timeframes: [],
  timeframe: null,
  onChange: noop,
  disableStart: null,
};

HourRange.propTypes = {
  timeframes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      timeframe: PropTypes.number,
      startHour: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
      endHour: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    })
  ).isRequired,
  timeframe: PropTypes.number,
  isReadOnly: PropTypes.bool,
  startHour: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  endHour: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  showSearch: PropTypes.bool,
  onChange: PropTypes.func,
  disableStart: PropTypes.bool,
};

export default memo(HourRange);
