import * as R from 'ramda';
import ROUTE_PATHS from 'routes/routePaths';
import { isBlank, clearInternalCache, STORE_KEYS } from 'utils';
import OAuth2Client from './OAuth2Client';

class UserManager {
  constructor() {
    this.refreshAccessTokenPromise = null;
  }

  getOAuthClient = () => {
    if (typeof this.oauthClient !== 'undefined') {
      return this.oauthClient;
    }
    this.oauthClient = new OAuth2Client();
    return this.oauthClient;
  };

  setOAuthToken = (oauthToken) => {
    const now = Date.now() + oauthToken.data.expires_in*1000
    localStorage.setItem(STORE_KEYS.EXPIRE_TIMESTAMP, now)
    localStorage.setItem(STORE_KEYS.ACCESS_TOKEN, JSON.stringify({ ...oauthToken.data }));
  }

  /**
   * @review this method is called way too many times, refactoring is recommended
   */
  getOAuthToken = () => {
    let oauthToken = localStorage.getItem(STORE_KEYS.ACCESS_TOKEN);
    if (oauthToken) {
      oauthToken = JSON.parse(oauthToken);
    }
    return oauthToken ?? null;
  };

  oauthTokenIsExpired = (oauthToken) => {
    if (oauthToken === null) {
      return true;
    }
    // prevent possible expiration while doing request
    // take in consideration a delta of four seconds to request a new token
    const currentDeltaTime = Date.now() + 5000;
    return currentDeltaTime > R.prop('expires_in', oauthToken);
  };

  refreshAccessToken = (oauthToken) => {
    if (!this.refreshAccessTokenPromise) {
      this.refreshAccessTokenPromise = new Promise((resolve, reject) => {
        this.getOAuthClient()
          .refreshAccessToken(oauthToken)
          .then((token) => {
            resolve(token);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.refreshAccessTokenPromise = null;
          });
      });
    }

    return this.refreshAccessTokenPromise;
  };

  setUserId = (data) => localStorage.setItem(STORE_KEYS.USER_ID, data);

  getUserId = () => localStorage.getItem(STORE_KEYS.USER_ID);

  getExpireTimestamp = () => localStorage.getItem(STORE_KEYS.EXPIRE_TIMESTAMP)

  hasUserId = () => !isBlank(this.getUserId());

  getAccessToken = () => R.prop('access_token', this.getOAuthToken());

  hasAccessToken = () => !isBlank(this.getAccessToken());

  isLoggedIn = () => this.hasUserId() && this.hasAccessToken();

  isTokenValid = () => {
    const now = Date.now();
    // prevent possible expiration while doing request
    // take in consideration a delta of one second to request
    return now < (this.getExpireTimestamp() - 1000)
  }

  redirectToErrorPage = (errorCode = '') => {
    window.rrHistory.push(`${ROUTE_PATHS.ERROR}/${errorCode}`);
  };

  logout = (callback, url) => {
    clearInternalCache();
    if (callback) callback();
    this.clearData();
    if(url)
      window.location.replace(url);
    else
      this.reloadToHome();
  };

  clearData = () => {
    localStorage.removeItem(STORE_KEYS.ACCESS_TOKEN);
    localStorage.removeItem(STORE_KEYS.EXPIRE_TIMESTAMP);
    localStorage.removeItem(STORE_KEYS.USER_ID);
    // localStorage.removeItem('themeMode')
  };

  reloadToHome = () => window.location.replace(window.location.origin);
}

const userManager = new UserManager();

export default userManager;
