import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import userManager from 'modules/userManager';
import compose from 'hocs';
import Scrollbars from 'theme/utility/customScrollBar';
import Logo from 'theme/utility/logo';
import useWindowSize from 'theme/utility/useWindowSize';
import { sidebarFullWidthBreakpoint } from 'components/layout/Layout';
import SidebarWrapper from './Sidebar.styles';
import { TopMenu } from './items/TopMenu';
import { BottomMenu } from './items/BottomMenu';

const { Sider } = Layout;

const collapsedWidth = 72;
const expandedWidth = 240;

const Sidebar = ({
  location,
  sidebar,
  isAdmin,
  fullPage,
  organizationId,
  currentProjectId,
  hasGitIntegrations,
}) => {
  // [i] USE title="" on Menu.Item to prevent tooltip in collapsed mode
  const { width: resizeWidth } = useWindowSize();
  const { pathname } = location;
  const { height } = sidebar; // useWindowSize().height ?

  const [ isExpanded, setIsExpanded ] = useState(false);

  const isCollapsed = resizeWidth > sidebarFullWidthBreakpoint ? false : !isExpanded;
  const mode = isCollapsed === true ? 'vertical' : 'inline';

  const onMouseEnter = () => {
    if (isExpanded) {
      return;
    }

    setIsExpanded(true);
  };
  const onMouseLeave = (e) => {
    if (!isExpanded) {
      return;
    }

    // org submenu has additional functionality
    if (e.target.closest('.inSubMenu') !== null) {
      return;
    }

    setIsExpanded(false);
  };

  const handleClick = (e) => {
    // submenus are opened via click
    if (e.domEvent.currentTarget.classList.contains('hasSubMenu')) {
      return;
    }

    // collapse sidebar if click originated outside collapsed width
    if (e.domEvent.clientX > collapsedWidth) {
      setIsExpanded(false);
    }
  };

  const adminClassName = isAdmin && pathname.indexOf(`/${organizationId}/admin`) !== -1 ? ' admin' : '';

  if (!userManager.isLoggedIn() || fullPage) {
    return <></>;
  }

  return (
    <SidebarWrapper>
      <Sider
        collapsible={!isCollapsed}
        trigger={null}
        collapsed={isCollapsed}
        width={isCollapsed ? collapsedWidth : expandedWidth}
        className={`isomorphicSidebar ${adminClassName}`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{
          position: 'absolute',
        }}
      >
        <Logo closeOpenMenu={onMouseLeave} collapsed={isCollapsed} />
        <Scrollbars style={{ height: height - 70 }}>
          <div className="menu-wrapper">
            <TopMenu
              organizationId={organizationId}
              currentProjectId={currentProjectId}
              onItemSelected={handleClick}
              mode={mode}

              isAdmin={isAdmin}
            />
            <BottomMenu
              organizationId={organizationId}
              currentProjectId={currentProjectId}
              onItemSelected={handleClick}
              mode={mode}

              hasGitIntegrations={hasGitIntegrations}
            />
          </div>
        </Scrollbars>
      </Sider>
    </SidebarWrapper>
  );
};

Sidebar.defaultProps = {
  organizationId: null,
  currentProjectId: null,
};

Sidebar.propTypes = {
  hasGitIntegrations: PropTypes.bool.isRequired,
  organizationId: PropTypes.number,
  currentProjectId: PropTypes.number,
  fullPage: PropTypes.bool.isRequired,
  sidebar: PropTypes.shape({
    height: PropTypes.number,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};
const mapStateToProps = (state) => {
  const { currentOrganization, lastUsedOrganization, lastUsedProject, currentProject } = state.userManager;
  const currentProjectId =
    (currentProject && parseInt(currentProject, 10)) || (lastUsedProject && parseInt(lastUsedProject.id, 10));

  const orgId = parseInt(lastUsedOrganization?.id || currentOrganization, 10);

  return {
    sidebar: state.sidebar,
    isAdmin: state.userManager.isAdmin,
    fullPage: state.global.fullPage,
    currentProjectId,
    hasGitIntegrations: state.organization.gitIntegrationsCount > 0,
    organizationId: orgId,
  };
};

export default compose(connect(mapStateToProps), withRouter)(Sidebar);
