import ClientOAuth2 from 'client-oauth2';
import { authAuthorization } from './constants';

class OAuth2Client {
  constructor() {
    const oauthClientOptions = {
      clientId: authAuthorization.OAUTH2_CLIENT_ID,
      clientSecret: authAuthorization.OAUTH2_CLIENT_SECRET,
      accessTokenUri: authAuthorization.OAUTH2_ACCESS_TOKEN_URI,
    };

    this.oauthClient = new ClientOAuth2(oauthClientOptions);
  }

  getOAuthClient() {
    return this.oauthClient;
  }

  createAccessToken(userCredentials, options) {
    return this.oauthClient.owner
      .getToken(userCredentials.username, userCredentials.password, options)
      .then((accessToken) => {
        return accessToken;
      })
      .catch((error) => {
        return Promise.reject(new Error(error));
      });
  }

  refreshAccessToken(OAuthTokenData) {
    const oauthToken = this.oauthClient.createToken(OAuthTokenData);
    return oauthToken
      .refresh()
      .then((refreshedOAuthToken) => {
        return refreshedOAuthToken;
      })
      .catch(() => {
        return Promise.reject(new Error("Couldn't refresh OAuth token"));
      });
  }
}

export default OAuth2Client;
