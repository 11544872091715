import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// import ROUTE_PATHS from 'routes/routePaths';
import { isBlank } from 'utils';
import { ErrorPage } from 'components';

const WithIsAdmin = (ChildComponent) => {
  if (isBlank(ChildComponent)) {
    throw new Error('No component provided to HOC');
  }

  class IsAdminWrapper extends PureComponent {
    render() {
      const { isLoggedIn, isAdmin, userId } = this.props;

      if (!userId && isLoggedIn) return null;

      return isAdmin ? (
        <ChildComponent {...this.props} />
      ) : (
        <ErrorPage
          title="404 - Not Found"
          description="The resource you are looking for doesn't exist or you don't have access to it."
          useOnlyReloadButton="true"
        />
      );
    }
  }

  IsAdminWrapper.defaultProps = {
    userId: null,
  };

  IsAdminWrapper.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    userId: PropTypes.number,
  };

  const mapStateToProps = ({ userManager }) => ({
    isLoggedIn: userManager.isLoggedIn,
    isAdmin: userManager.isAdmin,
    userId: userManager.userId,
  });

  return connect(mapStateToProps)(IsAdminWrapper);
};

export default WithIsAdmin;
