import styled from 'styled-components';

const StyledActionButton = styled.div`
  border: 0px;
  padding: 5px 0 30px 0;
  text-align: right;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-transition: 0 0 4px 4px;
  -o-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
`;

StyledActionButton.displayName = 'StyledActionButton';
export default StyledActionButton;
