import type { InferEndpointResult } from 'app/services/types';
import type { DraftableRoute, HydraListResponse } from 'types';

import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { defaultBaseQuery } from 'app/services/api';

type ID = string|number;

export interface SubresourceParams {
    environment: ID;
    group: string;
}

export interface GroupedEnvironItem {
    environment: number;
    group: string;

    name: string;
    value: string;
    isSecret: boolean;
}

export interface ListParams extends SubresourceParams, DraftableRoute {
}

export interface ListItem {
    items: HydraListResponse<GroupedEnvironItem>;
};

export interface GetItemParams extends SubresourceParams, DraftableRoute {
    name: string;
}

export interface Delete {
    type: 'delete';

    name: string;
}

export interface Insert {
    type: 'insert';

    name: string;
    value: string;
    isSecret: boolean;
}

export interface Update {
    type: 'update';

    name: string;
    value: string;
    isSecret: boolean;
}

export type Operation = Insert|Delete|Update;

export interface OperationsParams extends SubresourceParams {
    operations: Operation[]
}

export const TAG = 'environItems';

export const environItemsApi = createApi({
    reducerPath: `api:neo:${TAG}`,

    baseQuery: defaultBaseQuery,

    tagTypes: [TAG],

    endpoints: (build) => ({
        list: build.query<ListItem, ListParams>({
            query: (params) => {
                const {environment, group, draft, ...queryParams} = params;
                return {
                    url: draft
                        ? `/environments/${environment}/draft/environment_variables/groups/${group}`
                        : `/environments/${environment}/environment_variables/groups/${group}`,
                    queryParams,
                };
            },
            providesTags: (result, error, data) => {
                return error ? [] : [{
                    type: TAG,
                    id: data.group,
                }];
            },
        }),

        revealSecret: build.query<GroupedEnvironItem, GetItemParams>({
            query: (params) => {
                const {environment, group, name, draft, ...queryParams} = params;

                return {
                    url: draft
                        ? `/environments/${environment}/draft/environment_variables/groups/${group}/item/${name}`
                        : `/environments/${environment}/environment_variables/groups/${group}/item/${name}`,
                    queryParams,
                };
            },
            providesTags: (result, error, data) => {
                return error ? [] : [{
                    type: TAG,
                    id: `${data.group}_${data.name}`,
                }];
            },
        }),

        operations: build.mutation<null, OperationsParams>({
            query: (data) => {
                const {environment, group, ...bodyData} = data;
                return {
                    url: `/environments/${environment}/draft/environment_variables/groups/${group}`,
                    method: 'POST',
                    body: bodyData,
                };
            },

            invalidatesTags: (result, error, data) => {
                return error ? [] : [{
                    type: TAG,
                    id: data.group,
                }];
            },
        }),

    }),
});

export type AsyncListResult = InferEndpointResult<typeof environItemsApi.endpoints.list>;
