const routePaths = {
  LANDING: '/',
  PROJECTS_LANDING: '/projects',
  PROJECTS: '/:organizationId/projects',
  SETTINGS: '/:organizationId/projects/:projectId/settings',
  SETTINGS_GENERAL: '/:organizationId/projects/:projectId/settings/general',
  BUILD_SETTINGS: '/:organizationId/projects/:projectId/settings/build-settings',
  METRICS_SETTINGS: '/:organizationId/projects/:projectId/settings/metrics-settings',
  PROJECT_VARIABLES: '/:organizationId/projects/:projectId/settings/project-variables',
  PROJECT_LABELS: '/:organizationId/projects/:projectId/settings/labels',
  SETTINGS_ENVIRONMENT_RULES: '/:organizationId/projects/:projectId/settings/environment-rules',
};

export default routePaths;
