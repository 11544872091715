import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  ${() => css`
    display: block;
    cursor: pointer;
    text-decoration: none;
  `}
`;

StyledLink.displayName = 'StyledLink';
export default StyledLink;
