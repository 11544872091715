/* eslint-disable jsx-a11y/label-has-for,react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import mixpanel from 'mixpanel-browser';
import compose from 'hocs';
import { Typography } from 'antd';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { withRouter, useLocation } from 'react-router-dom';
import { showDialog, hideDialog, updateBreadcrumb } from 'actions/global';
import { getCurrentOrganization } from 'modules/userManager/actions';
import { handleGetProjects, handleAddProject, resetState } from 'modules/projects/actions';
import { handleGetOrganizationOwner } from 'modules/settings/actions';
import { useOrganizationACL } from 'rbac/hooks/useOrganizationACL';
import Loader from 'components/loader';
import { StyledWelcomeBanner } from 'components/banner/WelcomeBanner';
import ListingSkeleton from './listing/ListingSkeleton';
import ProjectsEmptyState from './emptyState';
import ProjectListing from './listing';
import StyledProjects from './Projects.style';
import usePageLoaderVisibility from '../../../components/customHook/usePageLoaderVisibility';

const { Title } = Typography;

const Projects = ({
  isLoading,
  isLoadingProjects,
  isUpdatingPreferences,
  list,
  actions,
  updateResourceId,
  updateEnvResourceId,
  match,
  projectsNo,
  history,
  emptyOrganizationsList,
  email,
  mode,
}) => {
  const { organizationId } = match.params;

  // RBAC
  const fetchingOption = { allowFetching: true };
  const aclContext = { organization: { id: parseFloat(organizationId) } };
  const aclProjectCreateData = useOrganizationACL('organization:create:project', aclContext, fetchingOption);
  const aclProjectCreateDisabled = aclProjectCreateData.type === 'denied';
  // End of RBAC

  const location = useLocation();
  const isPageVisible = usePageLoaderVisibility();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const pageQuery = queryParams.get('page');

  const isMounted = useRef(true);
  const [skeleton, setSkeleton] = useState(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (location.state && location.state.register) {
      mixpanel.track('New Subscription');
    }
  }, [location.state]);

  useEffect(() => {
    /** prevent requests that will fail when no organizations found */
    const hasOrganizations = isMounted.current && emptyOrganizationsList === false;

    if (hasOrganizations) {
      actions.handleGetProjects({ organizationId }, history);
      actions.getCurrentOrganization(organizationId);
      actions.handleGetOrganizationOwner(organizationId, null, 0);
    }
  }, [organizationId, emptyOrganizationsList]);

  useEffect(() => {
    // isPageVisible is used to be sure that this get won't be triggered on load
    if (projectsNo !== null && isPageVisible) {
      actions.handleGetProjects({ organizationId }, history);
    }
  }, [projectsNo, pageQuery]);

  useEffect(() => {
    if (updateResourceId !== '' || updateEnvResourceId !== '') {
      actions.handleGetProjects({ organizationId }, history);
    }
  }, [organizationId, actions, updateResourceId, updateEnvResourceId, history]);

  useEffect(() => {
    setSkeleton(isLoadingProjects || isUpdatingPreferences);
  }, [isLoadingProjects, isUpdatingPreferences]);

  /* breadcrumbs */
  useEffect(() => {
    actions.updateBreadcrumb(null);
  }, []);

  return (
    <StyledProjects>
      <Grid>
        <Row>
          <Col xs={12}>
            <StyledWelcomeBanner />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={12}>
            <Title className="page-title" level={4}>
              Projects
            </Title>
          </Col>
        </Row>
      </Grid>
      {!isPageVisible ? (
        <Loader />
      ) : (
        <>
          {projectsNo > 0 ? (
            <>
              {skeleton ? (
                <ListingSkeleton
                  projectsNo={projectsNo}
                  actions={actions}
                  organizationId={parseInt(organizationId, 10)}
                />
              ) : (
                <ProjectListing
                  isLoading={isLoading}
                  list={list}
                  projectsNo={projectsNo}
                  actions={actions}
                  email={email}
                  mode={mode}
                  aclProjectCreateDisabled={aclProjectCreateDisabled}
                />
              )}
            </>
          ) : (
            <ProjectsEmptyState
              actions={actions}
              organizationId={organizationId}
              aclProjectCreateDisabled={aclProjectCreateDisabled}
            />
          )}
        </>
      )}
    </StyledProjects>
  );
};

Projects.defaultProps = {
  projectsNo: null,
  emptyOrganizationsList: null,
};

Projects.propTypes = {
  mode: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingProjects: PropTypes.bool.isRequired,
  isUpdatingPreferences: PropTypes.bool.isRequired,
  updateResourceId: PropTypes.string.isRequired,
  updateEnvResourceId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  projectsNo: PropTypes.number,
  emptyOrganizationsList: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      organizationId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  actions: PropTypes.shape({
    handleGetProjects: PropTypes.func,
    handleAddProject: PropTypes.func,
    resetState: PropTypes.func,
    getCurrentOrganization: PropTypes.func,
    handleGetOrganizationOwner: PropTypes.func,
    updateBreadcrumb: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = ({ projects, environments, userManager, global }) => {
  const { isLoading, isLoadingProjects, list, updateResourceId, projectsNo } = projects;
  const { updateResourceId: updateEnvResourceId } = environments;
  const { isUpdatingPreferences, emptyOrganizationsList, email } = userManager;
  const { mode } = global;

  return {
    isLoading,
    isLoadingProjects,
    isUpdatingPreferences,
    list,
    updateResourceId,
    updateEnvResourceId,
    projectsNo,
    emptyOrganizationsList,
    mode,
    email: email || '',
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      showDialog,
      hideDialog,
      handleGetProjects,
      handleAddProject,
      getCurrentOrganization,
      resetState,
      handleGetOrganizationOwner,
      updateBreadcrumb,
    },
    dispatch
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Projects);
