import * as R from 'ramda';
import { API, POST, DELETE } from 'config';

export const getTimeframesToAdd = (projectId, addedTimeframes) => {
  const body = R.reduce(
    (acc, { timeframe, endHour, startHour }) => {
      acc.push({
        project: `/api/projects/${projectId}`,
        timeframe: `/api/timeframes/${timeframe}`,
        endHour,
        startHour,
      });
      return acc;
    },
    [],
    addedTimeframes
  );

  const promiseArray = body.map((item) => POST(API.projectTimeframes.base, item));
  return promiseArray;
};

export const getTimeframesToDelete = (markedToDeleteTimeframes) => {
  const body = R.reduce(
    (acc, { id }) => {
      acc.push(id);
      return acc;
    },
    [],
    markedToDeleteTimeframes
  );
  const promiseArray = body.map((item) => DELETE(`${API.projectTimeframes.base}/${item}`));
  return promiseArray;
};
