//https://developer.mozilla.org/en-US/docs/Web/API/EventSource/readyState
export const EVENTSOURCE_READY_STATES = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSED: 2,
};

export const EVENT_TYPE = {
  NOTIFICATION: 'NOTIFICATION',
};

export const MESSAGE_TYPES = {
  NOTIFICATION: EVENT_TYPE.NOTIFICATION,
};
