import { fetchBaseQuery, retry } from '@reduxjs/toolkit/dist/query/react';

import { NAKED_BASE_URL } from 'config/api';
import { defaultPrepareHeaders } from 'app/services/api';

export const BASE_URL = `${NAKED_BASE_URL}/api/rbac`;

export const defaultBaseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,

    prepareHeaders: defaultPrepareHeaders,
});

// @review we're a couple of versions behind having access to retryCondition also
export const defaultBaseQueryWithRetry = retry(defaultBaseQuery, {
    maxRetries: 1,
});
