import React from "react";
import { Menu } from "antd";
import { useLocation } from "react-router";
import { LinkItem } from "modules/sidebar/items/utils";
import SidebarMenu from "modules/sidebar/components/sidebarMenu/SidebarMenu";
import { IntegrationsSubMenu, isIntegrationsActive } from "modules/sidebar/items/IntegrationsMenu";
import { ReportsSubMenu, isReportsActive } from "modules/sidebar/items/ReportsSubMenu";
import { SettingsSubMenu, isSettingsActive } from "modules/sidebar/items/SettingsSubMenu";
import { store } from "app/store";
import { IntercomItem } from "modules/sidebar/items/IntercomItem";
import { useOrganizationACL } from "rbac/hooks/useOrganizationACL";

import type {
    MenuMode,
    MenuTheme,
    MenuOnClick,
} from "modules/sidebar/items/menu";
import type { Result } from "rbac/hooks/useACL";

export interface IProps {
    // @review should we just pluck this from the current state ?
    organizationId: number;
    currentProjectId: number;

    hasGitIntegrations: boolean;

    // should we handle all props ?
    onItemSelected: MenuOnClick;
    mode: MenuMode;
}

function getTheme(): MenuTheme {
    const global = store.getState().global as {
        mode: MenuTheme
    };

    return global.mode;
}

function getActiveKey(pathname: string, organizationId: number, currentProjectId: number): string {
    if (isIntegrationsActive(pathname, organizationId)) {
        return 'integrations';
    }

    if (isReportsActive(pathname, organizationId, currentProjectId)) {
        return 'reports';
    }

    if (isSettingsActive(pathname, organizationId)) {
        return 'settings';
    }

    return '';
}

function hasAllowed(
    ...acls: Result[]
): boolean {
    return acls.some(acl => acl.type === 'allowed');
}

export function BottomMenu(props: IProps): React.ReactElement {
    const { organizationId, currentProjectId } = props;
    const theme = getTheme();

    const activeKey = getActiveKey(useLocation().pathname, props.organizationId, props.currentProjectId);
    const organizationManage = useOrganizationACL('organization:manage', {
        organization: {
            id: organizationId,
        },
    }, {
        allowFetching: true,
        skip: !organizationId,
    });
    const organizationSecurity = useOrganizationACL('organization:security', {
        organization: {
            id: organizationId,
        },
    }, {
        allowFetching: true,
        skip: !organizationId,
    });
    const organizationBilling = useOrganizationACL('organization:billing', {
        organization: {
            id: organizationId,
        },
    }, {
        allowFetching: true,
        skip: !organizationId,
    });
    const organizationIntegrations = useOrganizationACL('organization:integrations', {
        organization: {
            id: organizationId,
        },
    }, {
        allowFetching: true,
        skip: !organizationId,
    });

    /**
     * Submenus should be used directly, until then the selectedKeys need to be empty
     * Otherwise they get selected in the state
     */
    return <Menu
        className="isoDashboardMenu"
        onClick={props.onItemSelected}
        theme={theme}
        mode={props.mode}
        selectedKeys={[]}
        activeKey={activeKey}
    >
        <SidebarMenu item={{
            key: 'organization',
            label: 'Organization',
            leftIcon: 'icon-organization',
        }} />

        {
            organizationIntegrations.type === 'denied'
            ? <></>
            : <Menu.Item key="integrations" title="" className="hasSubMenu">
                <Menu triggerSubMenuAction="hover" className="org-menu" selectedKeys={[]}>
                    <IntegrationsSubMenu
                        organizationId={organizationId}
                        theme={theme}
                    />
                </Menu>
            </Menu.Item>
        }

        {
            organizationManage.type === 'denied'
            ? <></>
            : <Menu.Item key="reports" title="" className="hasSubMenu">
                <Menu triggerSubMenuAction="hover" className="org-menu" selectedKeys={[]}>
                    <ReportsSubMenu
                        organizationId={organizationId}
                        currentProjectId={currentProjectId}
                        hasGitIntegrations={props.hasGitIntegrations}
                        theme={theme}
                    />
                </Menu>
            </Menu.Item>
        }

        {
            !hasAllowed(organizationManage, organizationSecurity, organizationBilling)
            ? <></>
            : <Menu.Item key="settings" title="" className="hasSubMenu">
                <Menu triggerSubMenuAction="hover" className="org-menu" selectedKeys={[]}>
                    <SettingsSubMenu
                        organizationId={organizationId}
                        theme={theme}
                    />
                </Menu>
            </Menu.Item>
        }

        <IntercomItem key="intercom" />

        <LinkItem
            currentKey={activeKey}
            key="documentation"
            title="Documentation"
            icon="bicon-documentation"
            linkTo="/external/help/introduction"
        />
    </Menu>;
}
