import React from 'react';
import PropTypes from "prop-types";
import StyledProgressLoader from './ProgressLoader.style'


const ProgressLoader = ({size}) => {

  return (
    <StyledProgressLoader size={size}>
      <div className="progress-loader">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 8">
          <path d="M3.92914 4.07086L4 4M4 4L1 1M4 4L1 7" strokeMiterlimit="15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" transform="translate(0, 0)">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.1"/>
          </path>
          <path d="M3.92914 4.07086L4 4M4 4L1 1M4 4L1 7" strokeMiterlimit="15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" transform="translate(6, 0)">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.2"/>
          </path>
          <path d="M3.92914 4.07086L4 4M4 4L1 1M4 4L1 7" strokeMiterlimit="15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" transform="translate(12,0)">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.3"/>
          </path>
        </svg>
      </div>
    </StyledProgressLoader>
  );
};
ProgressLoader.defaultProps = {
  size: 'default'
};

ProgressLoader.propTypes = {
  size: PropTypes.string,
}
export default ProgressLoader;
